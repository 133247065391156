import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Segment, Tab } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { uiActions } from '../store/ui-slice'
import { formData } from '../shared/formDataCreator'

const NewTimesheet = (props) => {
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [filesData, setFilesData] = useState([])
    const fileRef = useRef(undefined)
    const [date, setDate] = useState()
    const handleAddFileClick = useCallback(() => {
        fileRef.current.click()
    }, [])
    // const [values, setValues] = useState({

    // })
    const values = useRef({ text: false, time1: false, time2: false })
    const dispatch = useDispatch();
    const data = props.data; console.log("the data:", data)
    const showDate = data.payment_schedule != "monthly"
    const showMonth = data.payment_schedule == "monthly"
    const showTime = data.payment_schedule == "hourly"
    let datefrom, dateto;
    if (date) {
        datefrom = new Date(date)
        datefrom.setTime( datefrom.getTime() + datefrom.getTimezoneOffset()*60*1000 );
        datefrom=datefrom.toLocaleDateString()
        dateto = new Date(date)
        dateto.setTime( dateto.getTime() + dateto.getTimezoneOffset()*60*1000 );
        dateto.setDate(dateto.getDate() + 6)
        dateto = dateto.toLocaleDateString()
    }
    const { userInfo } = useSelector(state => state.user)
    const loadData = async () => {
        setLoading(true)
        try {


        } catch (e) { alert(e) }
        setLoading(false)
    }
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleFileChange = useCallback((e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])

    }, [])
    const handleSubmit = async (e) => {
        const { text, time_from, time_to, date } = values.current
        if (!showMonth) if ((new Date(date)) < new Date(data.contract_date)) {
            alert(t("contractmgmt.date_greater_today")); return;
        }
        if (!text || (showTime && (!time_from || !time_to)) || !date) { alert("Please fill all fields!"); return; }
        if (showTime) if (time_from > time_to) { alert("Please input correct time!"); return; }
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        e.target.disabled = true
        setLoading(true)
        const datex = new Date(values.current.date)
        datex.setTime(datex.getTime() + datex.getTimezoneOffset() * 60 * 1000);
        try {
            await axios.post("/api/proposal/timesheet", formData({
                id: props.id,
                date: datex,
                time_from: values.current.time_from || "00:00",
                time_to: values.current.time_to || "00:00",
                text: values.current.text,
                ...getFilesObject(files),
            }), config)
        } catch (e) { alert(e) }
        e.target.disabled = false
        setLoading(false)
        props.close()
        props.update()
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        values.current = { ...values.current, [name]: value }
        if (name == "date") setDate(value)
    }
    useEffect(() => {
        if (!props.open) return;
        loadData()
    }, [props.open])
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{t("contractmgmt.new_timesheet")}</Modal.Header>
                <Modal.Content >
                    <h5 css="text-align:center;">{t("contractmgmt.write_your_hours_para")}</h5>
                    <h5 css="text-align:center;">{t("contractmgmt.please_note_example_1")}</h5>
                    <h5 css="text-align:center; max-width:50rem; margin:0 auto; margin-bottom:1rem:">{t("contractmgmt.please_note_example_2")}</h5><br />
                    <h5 css="text-align:center; max-width:50rem; margin:0 auto;">{t("contractmgmt.please_note_example_3")}</h5>
                    <div className="ui form">
                        {showDate && <div className="ui field" style={{ display: "flex", alignItems: "end" }}>
                            <div>
                                <label>{t("contractmgmt.date")}</label>
                                <div css="display:flex;align-items:center;">
                                    <input name="date" onChange={handleInputChange} style={{ display: "inline", width: "auto" }} type="date" />
                                </div>
                            </div>
                            {date && data.payment_schedule == "weekly" && <div css="height:39px;display:flex;align-items:center;margin-left:0.4rem;">
                                <span css="padding:0.4rem;">{t("contractmgmt.from")}</span>
                                <span css="padding:0.4rem;">{datefrom}</span>
                                <span css="padding:0.4rem;">{t("contractmgmt.to")}</span>
                                <span css="padding:0.4rem;">{dateto}</span>
                            </div>}
                        </div>}
                            {/* {date ? <div css="display:flex;align-items:center;margin-bottom:0.4rem;">
                                <span css="padding:0.4rem;">{t("contractmgmt.from")}</span>
                                <span css="padding:0.4rem;">{datefrom}</span>
                                <span css="padding:0.4rem;">{t("contractmgmt.to")}</span>
                                <span css="padding:0.4rem;">{dateto}</span>
                            </div>:<div css="margin-bottom:0.4rem;">&nbsp;</div>} */}
                        {showMonth && <div className="ui field">
                            <label>{t("contractmgmt.date")}</label>
                            <div css="display:flex;align-items:center;">
                                <input className='helper-input-semantic-ui' name="date" onChange={handleInputChange} style={{ display: "inline", width: "auto" }} type="month" />
                            </div>
                        </div>}
                        {showTime && <div className="ui field">
                            <label>{t("contractmgmt.time")}</label>
                            <div css="display:flex;align-items:center;">
                                <input name="time_from" onChange={handleInputChange} style={{ display: "inline", width: "auto" }} type="time" />
                                <span css="margin: 0 15px;">{t("contractmgmt.to")}</span>
                                <input name="time_to" onChange={handleInputChange} style={{ display: "inline", width: "auto" }} type="time" />
                            </div>
                        </div>}
                        <div className="ui field">
                            <label>{t("contractmgmt.text")}</label>
                            <textarea name="text" onChange={handleInputChange} style={{ width: "100%" }} rows="10"></textarea>
                        </div>
                    </div>
                    <h5 css="text-align:center;">{t("contractmgmt.upload_screenshots_para")} (jpg, png, pdf)</h5>
                    <div>

                        <input onChange={handleFileChange} type="file" accept="image/*,application/pdf" hidden ref={fileRef} />
                        <div css="display:flex;align-items:center;justify-content:center;">
                            <div>
                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                    <div onClick={handleAddFileClick} css="cursor:pointer;flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file</p>
                                                    <i className="ui big file icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filesData.map((item) => {
                                        return <>
                                            <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                    <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                        {!item.type.startsWith("image") && <div css="display:flex;flex-direction:column;width:100%;">
                                                            <div>
                                                                <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                            </div>
                                                            <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                        </div>}
                                                        {item.type.startsWith("image") && <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                            <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button css="background:rgb(2,183,178) !important;" loading={loading} onClick={handleSubmit} color='teal' circular>
                        {t("contractmgmt.submit")}
                    </Button>
                    <Button css="background:rgb(0,128,129) !important;" color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default NewTimesheet