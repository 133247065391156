import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import axios from "../../axios";
import { emailRegex } from "../../config";
import { error_message } from "../verify";

const UpdateEmailAdmin = (props) => {
    const { userInfo } = useSelector(state => state.user)
    const [t,] = useTranslation("global")
    const [confirm, setConfirm] = useState(false)
    const [email, setEmail] = useState(props.email)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({ header: "", content: "", color: "red" })
    const checksEmail = () => {
        if (email == props.email) {
            setError({ color: "red", header: t("email_exists"), content: "" })
            return false
        }
        if (!email) {
            setError({ color: "red", header: t("enter_valid_email"), content: "" })
            return false
        }
        if (!(new RegExp(emailRegex, "gi").test(email))) {
            setError({ color: "red", header: t("enter_valid_email"), content: "" })
            return false
        }
        return true
    }
    const handleClick = async () => {

        setLoading(true)
        setConfirm(false)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.put("/api/user/update-email-admin", {
                email,
                id_user: props.id_user
            }, config)
            setError({ color: "green", header: t("email_verification_sent_2") + email })
        } catch (e) {
            // alert(e); return;
            let message = error_message(e)
            setError({ header: t(message), content: "", color: "red" })
        }
        setLoading(false)
    }
    return <>
        <div>
            <Message
                attached
                header={t("update_email")}
            />
            <div className='ui form attached fluid segment'>
                <div className="ui form" >
                    <Form.Input
                        // error={error.length > 0}
                        required
                        type="email"
                        label={t("profile.Email")}
                        onChange={({ target: { value } }) => setEmail(value)}
                        value={email}
                    />
                    <Button disabled={loading} loading={loading} type="button" onClick={()=>{if(checksEmail()){setConfirm(true);setError({ header: "", content: "", color: "red" })}}} color="teal">{t("update")}</Button>
                </div>
                {
                    error.header && (
                        <Message
                            color={error.color}
                            header={t(error.header)}
                            content={t(error.content)}
                        />
                    )
                }
            </div>
        </div>
        <div style={{ marginBottom: "1rem" }}></div>
        {confirm && <ConfirmUpdate onClose={() => setConfirm(false)} onClick={handleClick} />}
    </>
}

export default UpdateEmailAdmin

const ConfirmUpdate = (props) => {
    const [t,] = useTranslation("global")
    const [btnLoading, setBtnLoading] = useState(false)
    const { userInfo } = useSelector(state => state.user)

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="small"
            >
                <Modal.Header>{t("are_you_sure_update_admin")}</Modal.Header>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;max-width:400px;margin:0 auto;">
                        <Button circular color="teal" css="background-color:rgb(0,128,129) !important;" onClick={props.onClose}>
                            {t("profile.cancel")}
                        </Button>
                        <Button loading={btnLoading} color="teal" css="background-color:rgb(2,183,178) !important;" circular onClick={props.onClick}>
                            {t("update")}
                        </Button>
                    </div>

                </Modal.Actions>
            </Modal>
        </>
    )
}