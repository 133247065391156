import {createSlice} from '@reduxjs/toolkit'

const ordersSlice=createSlice({
    name:"orders",
    initialState:{
        successOrders:[],
        failedOrders:[]
    },//:{loading:false,userInfo:false,error:false},
    reducers:{
        addOrders(state,action){
            const success=action.payload.success
            const failed=action.payload.failed
            state.successOrders=success
            state.failedOrders=failed
        },
        payoutDelivered(state,action){
            const index=action.payload
            state.successOrders[index].payout_delivered=true;
        },
        payoutDeliveredByID(state,action){
            const paypal_order_id=action.payload
            const index=state.successOrders.findIndex(x=>x.order_id===paypal_order_id)
            state.successOrders[index].payout_delivered=true;
        },
    }
})

export const ordersActions=ordersSlice.actions

export default ordersSlice