import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../shared/footer/footer'
import Navbar from '../../shared/navbar/navbar'
import 'styled-components/macro'
import { Button, Checkbox, Dimmer, Header, Icon, Input, Label, Loader, Menu, Pagination, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../axios'
import OrderDetails from './orderDetails'
import { ordersActions } from '../../store/orders-slice'
import ProgressBar from './progressbar-freelancer'
import { useTranslation } from 'react-i18next'
import { footButtons } from '../styles'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const FreelancerPurchasedOrders = () => {
    const [loading, setLoading] = useState(true)
    const [paylo, setpaylo] = useState(false)
    const [orderModal, setOrderModal] = useState({})
    const [payoutD, setPayoutD] = useState(false)
    const [pages, setPages] = useState({})
    const { userInfo } = useSelector(state => state.user)
    const { successOrders } = useSelector(state => state.orders)
    const { failedOrders } = useSelector(state => state.orders)
    const dispatch = useDispatch()
    const history = useHistory()

    const [t, i18n] = useTranslation("global")
    const loadData = async (cp = 1) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: { orders, page, pages } } = await axios.get(`/api/user/orders?pageNumber=${cp}&pd=${payoutD}&foi=${RfilterOrderID.current.trim()}&fvn=${RfilterVendorName.current.trim()}&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}`, config)//&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}
            console.log(orders)
            setPages({ current: page, total: pages })
            const success = orders.filter(x => x.order_pass)
            const failed = orders.filter(x => !x.order_pass)
            dispatch(ordersActions.addOrders({ success, failed }))
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const RfilterOrderID = useRef("")
    const RfilterVendorName = useRef("")

    const RfilterDateS = useRef("")
    const RfilterDateE = useRef("")

    useEffect(() => { loadData() }, [payoutD])

    const handlePageClick = async (page) => {
        loadData(page)
    }

    const handleShowDetails = (e) => {
        const index = parseInt(e.target.name)
        setOrderModal(successOrders[index])
    }

    const handleDateFromChange = (e) => {
        RfilterDateS.current = e.target.value
    }
    const handleDateToChange = (e) => {
        RfilterDateE.current = e.target.value
    }

    const handleOrderChange = (e) => {
        RfilterOrderID.current = e.target.value
    }
    const handleVendorNameChange = (e) => {
        RfilterVendorName.current = e.target.value
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading)
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>

    return (
        <>
            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={4} />
                </div>

                <h2 css="text-align:center;">{t("profile.Orders")}</h2>
                <main css="max-width:1200px;margin:0 auto; padding:0 36px;">
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='7'>
                                    <div css="display:flex;align-items:center;flex-wrap:wrap;">
                                        {/* <Checkbox checked={payoutD} onChange={() => setPayoutD(p => !p)} label='Payout delivered' /> */}
                                        <div css="padding:5px;"><Input defaultValue={RfilterOrderID.current} placeholder={t("orders.order_id")} onChange={handleOrderChange} /></div>
                                        <div css="padding:5px;"><Input defaultValue={RfilterVendorName.current} placeholder={t("orders.vendor_name")} onChange={handleVendorNameChange} /></div>
                                        <div css="padding:5px;">
                                            <Input defaultValue={RfilterDateS.current} type="date" onChange={handleDateFromChange} />
                                            <span css="margin: 0 1rem;">-</span>
                                            <Input defaultValue={RfilterDateE.current} type="date" onChange={handleDateToChange} />
                                        </div>
                                        <div css="padding:5px;"><Button onClick={() => { loadData() }}>{t("orders.apply_filter")}</Button></div>
                                        <div css="padding:5px;"><Button onClick={() => {
                                            RfilterOrderID.current = "";
                                            RfilterVendorName.current = "";
                                            RfilterDateS.current = "";
                                            RfilterDateE.current = "";
                                            loadData()
                                        }}>{t("orders.clear_filter")}</Button></div>
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("orders.paypal_order_id")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.date")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.vendor_names")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.total")}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {successOrders.length > 0 && successOrders.map((x, c) => {
                                const uniques = (arr) => arr.filter((v, i) => arr.indexOf(v) === i)
                                let vendor_names = x.order_items.map(y => { return y.vendor_name })
                                vendor_names = [...new Set(vendor_names)]
                                vendor_names = vendor_names.map(y => { return <div>{y}</div> })
                                return (
                                    <Table.Row key={x.order_id}>
                                        <Table.Cell>{x.order_id}</Table.Cell>
                                        <Table.Cell>{(new Date(x.order_date)).toLocaleDateString()}</Table.Cell>
                                        <Table.Cell>{vendor_names}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.order_amount).toFixed(2)}</Table.Cell>
                                        <Table.Cell collapsing>
                                            <Button name={c} onClick={handleShowDetails}>{t("orders.details")}</Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}

                            {
                                successOrders.length < 1 &&
                                (
                                    <Table.Row>
                                        <Table.Cell colSpan='7'>
                                            <div css="display:flex;align-items:center;justify-content:center;">
                                                <Header as='h2' icon>
                                                    <Icon name='ban' />
                                                    {t("orders.nothing")}
                                                </Header>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='7'>
                                    <div css="display:flex;align-items:center;justify-content:flex-end;">
                                        {/* {pages.total > 1 && <Menu pagination>

                                            {[...Array(pages.total).keys()].map((x, c) => {
                                                return <Menu.Item key={c} active={pages.current == c + 1} onClick={() => handlePageClick(c + 1)} as='a'>{c + 1}</Menu.Item>
                                            })}
                                        </Menu>} */}
                                        <Pagination
                                            boundaryRange={0}
                                            defaultActivePage={1}
                                            ellipsisItem={null}
                                            firstItem={null}
                                            lastItem={null}
                                            siblingRange={4}
                                            totalPages={pages.total}
                                            activePage={pages.current}
                                            onPageChange={(e,{activePage})=>handlePageClick(activePage)}
                                        />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </main>
                <div css={footButtons}>

                    <Link to="/customer-profile" className="ui circular primary button" css="background:#02b7b2 !important;">
                        {t("profile.Back")}</Link>
                    <Link to="/customer-settings" className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</Link>
                </div>
            </main>

            <Footer />
            <OrderDetails setClose={() => setOrderModal({})} open={Object.keys(orderModal).length > 0} data={orderModal} />

        </>
    )
}
export default FreelancerPurchasedOrders