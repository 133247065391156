import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';
import countries from '../../Profile/countries';

const ShippingModal = (props) => {
    const [values, setValues] = useState({
        country: "US",
        zip: ""
    })
    const valuesx=useRef({
        country:props.data.client_country,
        id_country:props.data.client_id_country,
        address:props.data.client_address,
        zip:props.data.client_zip,
    })
    const [loading, setLoading] = useState(false)
    useEffect(() => { if (props.open) { console.log("shipping modal open") } }, [props.open])
    const resetState = () => { }
    const handleInputChange = (e) => setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    const handleInputChangex = (e) => valuesx.current={ ...valuesx.current, [e.target.name]: e.target.value }
    const handleInputChangeSelectx = (e) => valuesx.current={ ...valuesx.current, [e.target.name]: e.target.value,country:countries.find(x=>x.value===e.target.value).text }
    const handleUpdate = async () => {
        console.log(valuesx)
        await props.update(valuesx.current)
        props.setClose()
    }
    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={() => { resetState(); props.setClose() }}
                open={props.open}
                size="tiny"
            >
                <Modal.Header css="text-align:center;">Add a product</Modal.Header>
                <Modal.Content >
                    <div className="ui form">
                        <Form.Select
                            name="id_country"
                            label='Select country'
                            fluid
                            search
                            options={countries}
                            defaultValue={props.data.client_id_country}
                            onChange={(a, e) => handleInputChangeSelectx({ target: { name: e.name, value: e.value } })}
                        />
                        <Form.Input
                            onChange={handleInputChangex}
                            name="address"
                            label='Address'
                            defaultValue={props.data.client_address}
                            
                        />
                        <Form.Input
                            onChange={handleInputChangex}
                            name="zip"
                            label='Zip code'
                            defaultValue={props.data.client_zip}
                            
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;">
                        <Button css="background-color:#af111c !important;" circular color='black' onClick={props.setClose}>
                            Cancel
                        </Button>
                        <Button loading={loading} css="background-color:#af111c !important;" circular color='black' onClick={handleUpdate}>
                            Update
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ShippingModal