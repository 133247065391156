import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
// import styles from '../Profile/progressbar.styles'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProgressBar = ({ active }) => {
    const [t, i18n] = useTranslation("global")
    const {userInfo} =useSelector(state=>state.user)
    /*return (
        <>
            <div css="display:flex;align-items:center;justify-content:space-between;position:relative;">
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="position:absolute;width:100%;height:5px;top:50%;left:0;background:black;"></div>
            </div>
        </>
    )*/


    return (
        <>
            <div css="height:5px;background:teal;display:flex;align-items:center;justify-content:space-between;margin-bottom:70px;margin-top:30px;">
                {userInfo.id_role!="3"&&<Link to="sent-proposals" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 1 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="@media(max-width:550px){display:none;} position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("contractmgmt.draft_sent_proposals")}</div>
                </Link>}
                {userInfo.id_role=="3"&&<Link to="received-proposals" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 2 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="@media(max-width:550px){display:none;} position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("contractmgmt.received_proposals")}</div>
                </Link>}
                <Link to="current-contracts" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 3 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="@media(max-width:550px){display:none;} position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("contractmgmt.current_contracts")}</div>
                </Link>
                <Link to="timesheets" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 4 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="@media(max-width:550px){display:none;} position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("contractmgmt.timesheets")}</div>
                </Link>
                <Link to="closed-contracts" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 5 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="@media(max-width:550px){display:none;} position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;"><div>{t("contractmgmt.closed_contracts")}</div></div>
                </Link>
            </div>
            {/*<div css={styles}>
                <div className="stepper-wrapper">
                    <div className={`stepper-item ${active>=1 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">First first first first</div>
                    </div>
                    <div className={`stepper-item ${active>=2 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">Second</div>
                    </div>
                    <div className={`stepper-item ${active>=3 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">Third</div>
                    </div>
                </div>
            </div>*/}
        </>
    )
}

export default ProgressBar