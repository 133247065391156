import { Button, Image, Modal } from "semantic-ui-react"
import { useTranslation } from 'react-i18next'
import 'styled-components/macro'

const ImageModal = (props) => {
    const [t, i18n] = useTranslation("global")
    return <>
        <Modal
closeIcon
closeOnDimmerClick={false}
            onClose={props.close}
            open={props.open}
            closeIcon
            size="fullscreen"
        >
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Content image>
            {/* <Image size='massive' src={props.src} wrapped /> */}
                <img css="width:100%;max-width:100%;display:block;height:auto;" src={props.src} alt="" />
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={props.close}>
                    {t("wiki.close")}
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}

export default ImageModal