import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProgressBar from './progressbar-customer'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { footButtons } from '../styles'
import countries from '../countries'
import Footer from '../../shared/footer/footer'
import Navbar from '../../shared/navbar/navbar'
import { useDispatch, useSelector } from 'react-redux'
import { formData } from '../../shared/formDataCreator'
import { userActions } from '../../store/user-slice'
import { Link } from 'react-router-dom'
import { url_environment } from '../../config'
import UpdateEmail from '../../shared/signInModal/updateEmail'

const CustomerProfile = () => {

    const [loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        country: "",
        id_country: "",
        country_options: "",
        default_country_option: "",
        city: "",
        address: "",
        zip: "",
        customer_picture: "",
        customer_picture_link: "",

    })
    const dispatch = useDispatch()
    const btnSubmit = useRef()
    const [t, i18n] = useTranslation("global")
    const handleInputChange = (e) => {
        setValues((prev) => { return { ...prev, [e.target.name]: e.target.value } })
    }
    const loadData = async () => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: customerData } = await axios.get("/api/user/profile", config)
            console.log(customerData)
            setValues({
                name: customerData.name,
                email: customerData.email,
                password: "",
                phone: customerData.phone,
                country: customerData.country,
                id_country: customerData.id_country,
                country_options: countries.map(x => { return { key: x.key, text: x.text, value: x } }),
                default_country_option: countries.find(x => { return (x.key === customerData.id_country && x.text === customerData.country) }),
                city: customerData.city,
                address: customerData.address,
                zip: customerData.zip,
                customer_picture_link: `${url_environment}${customerData.customer_picture}`
            })
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const temp = { ...values }
            delete temp.country_options
            delete temp.customer_picture_link
            delete temp.default_country_option
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.put("/api/user/profile", formData(temp), config)
            setValues(p => { return { ...p, customer_picture_link: `${url_environment}${data.customer_picture}` } })
            dispatch(userActions.updateImageProfile(data.customer_picture.substring(1)))
            dispatch(userActions.updateValues({ name: "name", value: temp.name }))
            dispatch(userActions.updateValues({ name: "address", value: temp.address }))
            dispatch(userActions.updateValues({ name: "zip", value: temp.zip }))
            dispatch(userActions.updateValues({ name: "id_country", value: temp.id_country }))
            dispatch(userActions.updateValues({ name: "country", value: temp.country }))
            console.log(data)
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleCountrySelect = (e, { value }) => {
        setValues(p => { return { ...p, id_country: value.value, country: value.text, default_country_option: value } })
    }
    const history = useHistory()
    const handleImageLoad = () => URL.revokeObjectURL(values.customer_picture_link)
    const imageRef = useRef()
    const handleImageButtonClick = () => imageRef.current.click();
    const handleImageChange = (e) => {
        if (!e.target.files[0]) return
        setValues(p => { return { ...p, customer_picture: e.target.files[0] || "" } })
        setValues(p => { return { ...p, customer_picture_link: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" } })
    }

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <div>

                <div className="ui container">
                    <ProgressBar active={1} />
                </div>

                <h2 css="text-align:center;">{t("profile.Customer_profile")}</h2>


                <form onSubmit={handleSubmit} className="ui grid container" css="justify-content:space-between">
                    <div className="seven wide computer sixteen wide tablet sixteen wide mobile column">
                        <div className="ui form"><br />
                            <div className="field">
                                <label>{t("profile.Customer_name")}</label>
                                <input required value={values.name} onChange={handleInputChange} type="text" name="name" />
                            </div>
                            {/* <div className="field">
                                <label>{t("profile.Email")}</label>
                                <input required value={values.email} onChange={handleInputChange} type="text" name="email" />
                            </div> */}
                            <div className="field">
                                <label>{t("profile.Password")}</label>
                                <input value={values.password} onChange={handleInputChange} type="password" name="password" />
                            </div>

                            <div className="field">
                                <label>{t("profile.phone")}</label>
                                <input required value={values.phone} onChange={handleInputChange} type="text" name="phone" />
                            </div>
                            <UpdateEmail />
                        </div>
                    </div>
                    <div className="seven wide computer sixteen wide tablet sixteen wide mobile column">
                        <div className="ui form">
                            <div css="display:flex;align-items:center;justify-content:flex-end;">
                                <input hidden type="file" ref={imageRef} onChange={handleImageChange} accept="image/jpeg, image/png" />
                                <p>{t('profile.Customer_Cover_Photo')}</p>
                                <div action="button" onClick={handleImageButtonClick} css="border:0;background:#fff;cursor:pointer;padding:0;width:120px;height:120px;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);margin-left:3.5rem;">
                                    <div css="width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:relative;">
                                        {values.customer_picture_link === "" && <div css="display:flex;flex-direction:column;align-items:center;">
                                            <p css="text-align:center;margin:0;margin-bottom:5px;">{t('profile.Add_a_photo')}</p>
                                            <i className="ui big camera icon"></i>
                                        </div>}
                                        {values.customer_picture_link !== "" && <img src={values.customer_picture_link} css="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" onLoad={handleImageLoad} alt="" />}
                                    </div>
                                </div>
                            </div>
                            <Form.Select
                                search
                                fluid
                                placeholder={t("profile.select_country")}
                                label={t('profile.Country')}
                                options={values.country_options}
                                defaultValue={values.default_country_option}
                                onChange={handleCountrySelect}
                            />
                            <div className="field">
                                <label>{t("profile.City")}</label>
                                <input required value={values.city} onChange={handleInputChange} type="text" name="city" />
                            </div>

                            <div className="field">
                                <label>{t("profile.address")}</label>
                                <input required value={values.address} onChange={handleInputChange} type="text" name="address" />
                            </div>
                            <div className="field">
                                <label>{t("profile.zip")}</label>
                                <input required value={values.zip} onChange={handleInputChange} type="text" name="zip" />
                            </div>

                        </div>
                    </div>
                    <button type="submit" hidden ref={btnSubmit}></button>
                </form>

                <div css={footButtons}>

                    <button onClick={() => { btnSubmit.current.click() }} className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Save")}</button>
                    <Link to="/customer-orders" className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</Link>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default CustomerProfile