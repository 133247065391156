import Footer from "./footer/footer"
import Navbar from "./navbar/navbar"
import PrivacyPolicyEnglish from "./privacy-policy-english"
import PrivacyPolicySpanish from "./privacy-policy-spanish"
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
    const [t, i18n] = useTranslation("global")
    return <>
        <Navbar />
        <div css="max-width:1200px;margin:0 auto;padding:0 36px;">
            {i18n.language==="en"&&<PrivacyPolicyEnglish/>}
            {i18n.language==="es"&&<PrivacyPolicySpanish/>}
        </div>
        <Footer />
    </>
}

export default PrivacyPolicy