import 'styled-components/macro'

const PrivacyPolicyEnglish = () => {
    return <>
        <div css="font-size:16px;">
            <h1 css="text-align:center; font-weight:bold;">
                <span className="c10">www.interpowered.com</span>
                <span className="c10">&nbsp; Privacy Policy</span>
            </h1>
            <p className="c6">
                <span className="c0">Last modified: 11/26/2021</span>
            </p>
            <a id="id.30j0zll">

            </a>
            <p css="font-weight:bold;text-decoration:underline;">
                <span className="c2">Introduction</span>
            </p>
            <p className="c6">
                <span className="c0">Interpowered, LLC (</span>
                <span css="font-weight:bold;">"Company"</span>
                <span className="c0">&nbsp;or</span>
                <span css="font-weight:bold;">&nbsp;"We"</span>
                <span className="c0">) respect your privacy and are
            committed to protecting it through our compliance with this policy.</span>
            </p>
            <p className="c6">
                <span className="c0">This policy describes the types of information We may collect from you or that you
            may provide when you visit the website Interpowered.com (our "</span>
                <span css="font-weight:bold;">Website</span>
                <span className="c0">") and our practices for collecting, using, maintaining, protecting, and disclosing that
            information.</span>
            </p>
            <p className="c6">
                <span className="c0">This policy applies to information We collect:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0 start">
                <li className="c1 li-bullet-0">
                    <span className="c0">On this Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">In email, text, and other electronic messages between you and this
                Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">When you interact with our advertising and applications on
                    third-party websites and services, if those applications or advertising include links to this
                policy.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">It does not apply to information collected by:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">Us offline or through any other means, including on any other
                    website operated by the Company or any third party (including our affiliates and subsidiaries); or
            </span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Any third party (including our affiliates and subsidiaries),
                    including through any application or content (including advertising) that may link to or be accessible
                from or on the Website.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">Please read this policy carefully to understand our policies and practices regarding
                your information and how We will treat it. If you do not agree with our policies and practices, your choice
                is to not to use our Website. By accessing or using this Website, you agree to this privacy policy. This
            policy may change from time to time (see </span>
                <span className="c0">
                    <a className="c4" href="#id.3rdcrjn">Changes to
                Our Privacy Policy</a>
                </span>
                <span className="c0">). Your continued use of this Website after We make
                changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
        </span>
            </p>
            <a id="id.1fob9te">

            </a>
            <p className="c6">
                <span css="font-weight:bold; text-decoration:underline;">Children Under the Age of 18</span>
            </p>
            <p className="c6">
                <span className="c0">Our Website is not intended for children under 18 years of age. No one under age 18
                may provide any personal information to or on the Website. We do not knowingly collect personal information
                from children under 18. If you are under 18, do not use or provide any information on this Website or
                register on the Website, make any purchases through the Website , or provide any information about yourself
                to us, including your name, address, telephone number, email address, or any screen name or user name you
                may use. If We learn We have collected or received personal information from a child under 18 without
                verification of parental consent, We will delete that information. If you believe We might have any
            information from or about a child under 18, please contact us at </span>
                <span css="background-color:#c0c0c0;">legal@Interpowered.com</span>
                <span className="c0">.</span>
            </p>
            <a id="id.3znysh7">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Information We Collect About You and How We Collect It</span>
            </p>
            <p className="c6">
                <span className="c0">We collect several types of information from and about users of our Website,
            including information:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">By which you may be personally identified, such as name, postal
                address, e-mail address, telephone number, ("</span>
                    <span css="font-weight:bold;">personal information</span>
                    <span className="c0">");</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">That is about you but individually does not identify you, such as
                    storefront names, items for sale, or reviews posted to the site regarding items previously purchased or
                inquiries regarding items currently for sale; and/or</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">About your internet connection, the equipment you use to access our
                Website, and usage details.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">We collect this information:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">Directly from you when you provide it to us.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Automatically as you navigate through the site. Information
                    collected automatically may include usage details, IP addresses, and information collected through
                cookies.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">From third parties, for example, our business partners.</span>
                </li>
            </ul>
            <a id="id.2et92p0">

            </a>
            <p className="c6">
                <span css="font-style:italic;">Information You Provide to Us</span>
                <span className="c5">&nbsp; </span>
            </p>
            <p className="c6">
                <span className="c0">The information We collect on or through our Website may include:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">Information that you provide by filling in forms on our Website.
                    This includes information provided at the time of registering to use our Website, subscribing to our
                    service, posting material, or requesting further services. We may also ask you for information when you
                enter a contest or promotion sponsored by us, and when you report a problem with our Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Records and copies of your correspondence (including email
                addresses), if you contact us.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Your responses to surveys that We might ask you to complete.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Details of transactions you carry out through our Website and of the
                    fulfillment of your orders. You may be required to provide financial information before placing an order
                through our Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Your search queries on the Website.</span>
                </li>
            </ul>
            <p className="c6 c16">
                <span className="c0">

                </span>
            </p>
            <p className="c6">
                <span className="c0">You also may provide information to be published or displayed (hereinafter,
            "</span>
                <span css="font-weight:bold;">posted</span>
                <span className="c0">") on public areas of the Website, or transmitted to
            other users of the Website or third parties (collectively, "</span>
                <span css="font-weight:bold;">User
            Contributions</span>
                <span className="c0">"). Your User Contributions are posted on and transmitted to others at
                your own risk. Although We limit access to certain pages/you may set certain privacy settings for such
                information by logging into your account profile, please be aware that no security measures are perfect or
                impenetrable. Additionally, We cannot control the actions of other users of the Website with whom you may
                choose to share your User Contributions. Therefore, We cannot and do not guarantee that your User
            Contributions will not be viewed by unauthorized persons.</span>
            </p>
            <a id="id.tyjcwt">

            </a>
            <p className="c6">
                <span css="font-style:italic;">Information We Collect Through Automatic Data Collection Technologies</span>
                <span className="c7">&nbsp; </span>
            </p>
            <p className="c6">
                <span className="c0">As you navigate through and interact with our Website, We may use automatic data
                collection technologies to collect certain information about your equipment, browsing actions, and patterns,
            including:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">&nbsp;Details of your visits to our Website, including traffic data,
                    location data, logs, and other communication data and the resources that you access and use on the
                Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Information about your computer and internet connection, including
                your IP address, operating system, and browser type.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">The information We collect automatically may include personal information, or We may
                maintain it or associate it with personal information We collect in other ways or receive from third
                parties. It helps us to improve our Website and to deliver a better and more personalized service, including
            by enabling us to:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">Estimate our audience size and usage patterns.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Store information about your preferences, allowing us to customize
                our Website according to your individual interests.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Speed up your searches.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">Recognize you when you return to our Website.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">The technologies We use for this automatic data collection may include:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span css="font-weight:bold;">Cookies (or browser cookies).</span>
                    <span className="c0">&nbsp;A cookie
                    is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by
                    activating the appropriate setting on your browser. However, if you select this setting you may be
                    unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it
                will refuse cookies, our system will issue cookies when you direct your browser to our Website. </span>
                </li>
                <li className="c1 li-bullet-0">
                    <span css="font-weight:bold;">Flash Cookies.</span>
                    <span className="c0">&nbsp;Certain features of our
                    Website may use local stored objects (or Flash cookies) to collect and store information about your
                    preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same
                    browser settings as are used for browser cookies. For information about managing your privacy and
                security settings for Flash cookies, see </span>
                    <span className="c0">
                        <a className="c4" href="#id.lnxbz9">Choices
                    About How We Use and Disclose Your Information</a>
                    </span>
                    <span className="c0">.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span css="font-weight:bold;">Web Beacons.</span>
                    <span className="c0">&nbsp;Pages of our Website and
                    our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs,
                    pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited
                    those pages or opened an email and for other related website statistics (for example, recording the
                popularity of certain website content and verifying system and server integrity). </span>
                </li>
            </ul>
            <a id="id.3dy6vkm">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Third-Party Use of Cookies and Other Tracking Technologies</span>
            </p>
            <p className="c6">
                <span className="c0">Some content or applications, including advertisements, on the Website are served by
                third-parties, including advertisers, ad networks and servers, content providers, and application providers.
                These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies
                to collect information about you when you use our website. The information they collect may be associated
                with your personal information or they may collect information, including personal information, about your
                online activities over time and across different websites and other online services. They may use this
            information to provide you with interest-based (behavioral) advertising or other targeted content. </span>
            </p>
            <p className="c6">
                <span className="c0">We do not control these third parties' tracking technologies or how they may be used.
                If you have any questions about an advertisement or other targeted content, you should contact the
                responsible provider directly. For information about how you can opt out of receiving targeted advertising
            from many providers, see </span>
                <span className="c0">
                    <a className="c4" href="#id.lnxbz9">Choices About How We Use
                and Disclose Your Information</a>
                </span>
                <span className="c0">.</span>
            </p>
            <a id="id.1t3h5sf">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">How We Use Your Information</span>
            </p>
            <p className="c6">
                <span className="c0">We use information that We collect about you or that you provide to us, including any
            personal information:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">To present our Website and its contents to you.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To provide you with information, products, or services that you
                request from us.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To fulfill any other purpose for which you provide it.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To provide you with notices about your account, including expiration
                and renewal notices.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To carry out our obligations and enforce our rights arising from any
                contracts entered into between you and us, including for billing and collection.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To notify you about changes to our Website or any products or
                services We offer or provide though it.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To allow you to participate in interactive features on our
                Website.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">In any other way We may describe when you provide the
                information.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">For any other purpose with your consent.</span>
                </li>
            </ul>
            <a id="id.4d34og8">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Disclosure of Your Information</span>
            </p>
            <p className="c6">
                <span className="c0">We may disclose aggregated information about our users, and information that does not
            identify any individual, without restriction. </span>
            </p>
            <p className="c6">
                <span className="c0">We may disclose personal information that We collect or you provide as described in
            this privacy policy:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">To our subsidiaries and affiliates.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To contractors, service providers, and other third parties We use to
                    support our business and who are bound by contractual obligations to keep personal information
                confidential and use it only for the purposes for which We disclose it to them.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To a buyer or other successor in the event of a merger, divestiture,
                    restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s
                    assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, or
                    divestiture in which personal information held by the Company about our Website users is among the
                assets transferred.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To fulfill the purpose for which you provide it. For example, if you
                    give us an email address to use the "email a friend" feature of our Website, We will transmit the
                contents of that email and your email address to the recipients.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">For any other purpose disclosed by us when you provide the
                information.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">With your consent.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">We may also disclose your personal information:</span>
            </p>
            <ul className="c3 lst-kix_list_19-0">
                <li className="c1 li-bullet-0">
                    <span className="c0">To comply with any court order, law, or legal process, including to
                respond to any government or regulatory request.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">To enforce or apply our Terms of Use and other agreements, including
                for billing and collection purposes.</span>
                </li>
                <li className="c1 li-bullet-0">
                    <span className="c0">If We believe disclosure is necessary or appropriate to protect the
                    rights, property, or safety of the Company, our customers, or others. This includes exchanging
                    information with other companies and organizations for the purposes of fraud protection and credit risk
                reduction.</span>
                </li>
            </ul>
            <p className="c6">
                <span className="c0">We do not control third parties' collection or use of your information to serve
                interest-based advertising. However these third parties may provide you with ways to choose not to have your
                information collected or used in this way. You can opt out of receiving targeted ads from members of the
            Network Advertising Initiative ("</span>
                <span css="font-weight:bold;">NAI</span>
                <span className="c0">") on the NAI's
            website.</span>
            </p>
            <a id="id.2s8eyo1">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Accessing and Correcting Your Information</span>
            </p>
            <p className="c6">
                <span className="c0">You can review and change your personal information by logging into the Website and
            visiting your account profile page.</span>
            </p>
            <p className="c6">
                <span className="c0">If you delete your User Contributions from the Website, copies of your User
                Contributions may remain viewable in cached and archived pages or might have been copied or stored by other
            Website users. </span>
            </p>
            <a id="id.17dp8vu">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Data Security</span>
            </p>
            <p className="c6">
                <span className="c0">We have implemented measures designed to secure your personal information from
                accidental loss and from unauthorized access, use, alteration, and disclosure. Any payment transactions will
            be secured via PayPal. For more information, see </span>
                <span className="c0">
                    <a className="c4"
                        href="https://www.google.com/url?q=https://www.paypal.com/re/webapps/mpp/paypal-safety-and-security&amp;sa=D&amp;source=editors&amp;ust=1638453359495000&amp;usg=AOvVaw35Pv1YOmnrib5OzPZ5p-yv">PayPal’s
                Safety and Security Website</a>
                </span>
                <span className="c0">. </span>
            </p>
            <p className="c6">
                <span className="c0">The safety and security of your information also depends on you. Where We have given
                you (or where you have chosen) a password for access to certain parts of our Website, you are responsible
                for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be
                careful about giving out information in public areas of the Website like message boards. The information you
            share in public areas may be viewed by any user of the Website.</span>
            </p>
            <p className="c6">
                <span className="c0">Unfortunately, the transmission of information via the internet is not completely
                secure. Although We do our best to protect your personal information, We cannot guarantee the security of
                your personal information transmitted to our Website. Any transmission of personal information is at your
                own risk. We are not responsible for circumvention of any privacy settings or security measures contained on
            the Website.</span>
            </p>
            <a id="id.3rdcrjn">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Changes to Our Privacy Policy</span>
            </p>
            <p className="c6">
                <span className="c0">It is our policy to post any changes We make to our privacy policy on this page. If
                We make material changes to how We treat our users' personal information, We will notify you by email to the
                primary email address specified in your account or through a notice on the Website home page. The date the
                privacy policy was last revised is identified at the top of the page. You are responsible for ensuring We
                have an up-to-date active and deliverable email address for you, and for periodically visiting our Website
            and this privacy policy to check for any changes.</span>
            </p>
            <a id="id.26in1rg">

            </a>
            <p className="c6">
                <span css="font-weight:bold;text-decoration:underline;">Contact Information</span>
            </p>
            <p className="c6">
                <span className="c0">To ask questions or comment about this privacy policy and our privacy practices,
            contact us at: </span>
                <span css="background-color:#c0c0c0;">info@Interpowered.com</span>
                <span className="c0">.</span>
            </p>
        </div>
    </>
}

export default PrivacyPolicyEnglish