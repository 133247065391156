import React, { useEffect, useState } from 'react'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import ProgressBar from './progressbar'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import axios from '../axios'
import TimesheetModal from './timesheetModal.js'
import { getImage1, getTimex, url_environment } from '../config'
import { useHistory } from 'react-router'
import { useQuery } from '../Home/search'

const Timesheets = () => {
    const [t, i18n] = useTranslation("global")
    const [currentContracts, setCurrentContracts] = useState([])
    const [showTimesheetModal, setShowTimesheetModal] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [loading, setLoading] = useState(false)
    const history=useHistory()
    const query=useQuery()
    const { userInfo } = useSelector(state => state.user)
    const loadData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            setLoading(true)
            const { data } = await axios.get("/api/proposal/contracts", config)
            setCurrentContracts(data)
            if (query.get("id") != null) {
                const proposal=data.find(x => x._id == query.get("id"))
                if(!proposal){
                    history.goBack()
                    // history.push("/closed-contracts?id="+query.get("id"))
                    return
                }
                handleContractClick(proposal,"unpaid")
                query.delete("id")
                history.replace({
                    search: query.toString(),
                })
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleContractClick = (data,type) => {
        setShowTimesheetModal(true)
        setModalData({...data,type})
    }
    useEffect(() => {
        loadData()
    }, [])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Navbar />
            <main>

            <div css="max-width:1200px;margin:0 auto; padding:0 36px;">
                <h1 css="text-align:center;">{t("contractmgmt.contract_management")}</h1>
                <ProgressBar active={4} />
                <h2 css="text-align:center;margin:2rem 0;">{t("contractmgmt.timesheets")}</h2>
                <div css="max-width:1000px;margin:0 auto;">
                    <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                        {currentContracts.map((item) => {
                            return (
                                <div onClick={() => handleContractClick(item)} css="cursor:pointer;flex:0 0 auto;width:20%;padding:9px; @media(max-width:960px){width:25%;}@media(max-width:700px){width:33.33333%;}@media(max-width:520px){width:50%;}@media(max-width:320px){width:100%;}">
                                    <div className="wrapper link">
                                        <div css="padding-bottom:80%;position:relative;">
                                            <img css="border-radius:8px;position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" src={getImage1(item.service_image)} alt="" />
                                        </div>
                                        <div>
                                            <h3 css="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;min-width: 0;margin:0;">{item.service_name}</h3>
                                            <p css="margin:0;word-break: break-all;"><b>{t("contractmgmt.last_modify")}:</b>{getTimex(item.updated_at)}</p>
                                            <p css="margin:0;word-break: break-all;"><b>{t("contractmgmt.contract_id")}:</b>{item._id}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            </main>

            <Footer />
            <TimesheetModal update={loadData} data={modalData} open={showTimesheetModal} close={()=>setShowTimesheetModal(false)}/>
        </>
    )
}

export default Timesheets