import { useEffect } from "react"
import Footer from "./footer/footer"
import Navbar from "./navbar/navbar"

const InterestBasedAdds = () => {
    const loadData = () => {
        // const head = document.getElementsByTagName("head")[0]
        // const script = document.createElement("script")
        // script.async = true
        // script.crossOrigin = "anonymous"
        // script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8368551961708577"
        // head.append(script)
    }
    useEffect(() => { loadData() }, [])
    return <>
        <Navbar />
        <div css="max-width:1200px;margin:0 auto; padding:0 36px;">
        <div css="height:400px;border:1px solid black;"></div>
        </div>
        <Footer />
    </>
}

export default InterestBasedAdds