import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dimmer, Icon, Loader, Rating } from 'semantic-ui-react'
import 'styled-components/macro'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import category_products_styles from './category-products.style'
import placeholder from '../img/photo_placeholder.svg'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { userActions } from '../store/user-slice'
import { useTranslation } from 'react-i18next'
import { getAllImages1, url_environment } from '../config'

const ViewFavorites = () => {
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [t,]=useTranslation("global")
    const dispatch = useDispatch()
    const loadData = async () => {
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            const { data } = await axios.get("/api/user/favorite", config)
            setProducts(data.filter(x => x.favorites_details).map(x => x.favorites_details))
            const df = data.filter(x => !x.favorites_details)
            // if (df.length > 0) {
            //     await axios.post("/api/user/favorite/del", {
            //         arrf: df
            //     }, config)
            // }
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const handleFavoriteClick = async (e) => {
        const id = e.target.getAttribute("data-product-id")
        const type = e.target.getAttribute("data-type")
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (id.endsWith(" remove")) {
                const rid = id.split(" ")[0]
                await axios.delete("/api/user/favorite/" + rid, config)
            } else {
                await axios.post("/api/user/favorite", { id, type }, config)
            }
            dispatch(userActions.updateFavorites({ id, type }))
        } catch (e) { alert(e) }
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading) return <>
        <Dimmer active inverted>
            <Loader />
        </Dimmer>
    </>

    return (
        <>
            <Navbar />
            <main>

            <div css={category_products_styles}>
                <div className="dailyWrapper">
                    {
                        products && products.length > 0 && products.map((i) => {
                            return <div key={i._id} className="imgWrapper">
                                {
                                    (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                        <div className="heartFillClass">
                                            <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                        </div>
                                    ) : (userInfo &&
                                        <div className="heartOutlineClass heartBtn">
                                            <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                        </div>
                                    )
                                }
                                {/* <Link to={`${i.service_name ? "/service/" + i._id : (i.freelancer_picture ? "/freelancer-cv2/"+i._id : (i.business_picture ? "/business/"+i._id : "/product/" + i._id))}`} css="color:#000; &:hover{color:#000;}" href=""> */}
                                <Link to={`${i.service_name ? "/service/" + i._id : (i.freelancer_picture ? "/freelancer-cv2/" + i._id : (i.business_picture ? "/business/" + i._id : "/product/" + i._id))}`} css="color:#000; &:hover{color:#000;}" href="">
                                    <div css="padding-bottom:80%;position:relative;">
                                        {/* <img className="bigImg" src={i.images[0] ? ("https://www.interpowered.com:3001" + (i.images[0] || "")) : (placeholder)} alt="" /> */}
                                        <img className="bigImg" src={getAllImages1(i)/*((i.images && i.images[0]) || i.business_picture || i.freelancer_picture) ? (url_environment + ((i.images && i.images[0]) || i.business_picture || i.freelancer_picture)) : (placeholder)*/} alt="" />
                                    </div>
                                    <div>
                                        <h3 className="h3Text">{i.service_name || i.name || i.business_name || i.freelancer_name}</h3>
                                        {i.vendor_name && <p css="margin:0;">{i.vendor_name}</p>}
                                        <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                        <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                                <p>USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length>0 && <><br/>({t("cart.discount")}: {(i.volume_pricing.length<2?i.volume_pricing[0][2]+"%":t("homeScreen.up_to")+" "+i.volume_pricing.reduce((acc,curr)=>{return curr[2]>acc?curr[2]:acc},0)+"%")})</>}</p>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            </div>
            </main>

            <Footer />
        </>
    )
}

export default ViewFavorites