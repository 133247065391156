import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, List, Loader, Modal, Segment, Tab } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { uiActions } from '../store/ui-slice'
import NewTimesheet from './newTimesheet.js'
import { getTimex, url_environment } from '../config'
import ViewTimesheet from './viewTimesheet'
import CloseContract from './closeContractModal'
import { Link } from 'react-router-dom'

const TimesheetModal = (props) => {
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const [timesheets, setTimesheets] = useState([])
    const [showNewTimesheet, setShowNewTimesheet] = useState(false)
    const [showCloseContract, setShowCloseContract] = useState(false)
    const panes = [
        {
            menuItem: t("contractmgmt.paid"), render: () =>
                <Tab.Pane>
                    <Paid contract={props.data} clientx={values.clientx} contra={values.contra} data={props.data} timesheets={timesheets.filter(x => (x.paid && !x.rejected))} />
                </Tab.Pane>,
        },
        {
            menuItem: t("contractmgmt.unpaid"),
            render: () =>
                <Tab.Pane>
                    <Unpaid contract={props.data} update={() => loadData()} contra={values.contra} data={props.data} clientx={values.clientx} timesheets={timesheets.filter(x => (!x.paid && !x.rejected))} />
                </Tab.Pane>,
        },
        {
            menuItem: t("contractmgmt.rejected"),
            render: () =>
                <Tab.Pane>
                    <Rejected contract={props.data} update={() => loadData()} contra={values.contra} data={props.data} clientx={values.clientx} timesheets={timesheets.filter(x => x.rejected)} />
                </Tab.Pane>,
        }
    ]
    const [values, setValues] = useState({

    })
    const handleNewTimesheet = () => {
        setShowNewTimesheet(true)
    }
    const dispatch = useDispatch();

    const data = props.data
    const { userInfo } = useSelector(state => state.user)
    const loadData = async () => {
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            let contra
            let clientx = true;
            if (userInfo.id == data.sender) {
                const { data: datax } = await axios.get("/api/user/info/" + data.receiver)
                contra = datax
            } else {
                const { data: datax } = await axios.get("/api/user/info/" + data.sender)
                contra = datax
                clientx = false
            }
            setValues(p => {
                return {
                    ...p,
                    ...data,
                    contra,
                    clientx
                }
            })
            await loadData2()
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleCloseContract = async () => {
        setShowCloseContract(true)
    }
    const loadData2 = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data: timesheets } = await axios.get("/api/proposal/timesheet/" + data._id, config)
        setTimesheets(timesheets)
    }
    useEffect(() => {
        if (!props.open) return;
        if (Object.keys(props.data).length == 0) return
        loadData()
    }, [props.open])
    const handleMessageClick = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...values.contra }, product: { service_name: data.service_name, images: [data.service_image] }, subject: "About service", link: `/service/${data.service}` } }))
    }
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    if (Object.keys(props.data).length == 0) return <></>
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{t("contractmgmt.timesheet")}</Modal.Header>
                <Modal.Content >
                    <div css="display:flex;justify-content:space-between;@media(max-width:660px){flex-direction:column;align-items:center;}">
                        <div css="display:flex;">
                            <div css="font-size:25px;font-weight:700;display:flex;align-items:center;color:teal;margin-right:35px;@media(max-width:500px){display:none;}">
                                <div>
                                    <div>{data.service_name}</div>
                                    {userInfo.id == data.receiver && <div>{t("contractmgmt.timesheet_for")}:</div>}
                                    {userInfo.id == data.sender && <div>{t("contractmgmt.timesheet_from")}:</div>}
                                </div>
                            </div>
                            <div css="display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                <img css="width:150px;height:150px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src={`${url_environment}${values?.contra?.customer_picture}`} alt="" />
                                <div>
                                    <div css="margin:10px 0;">{values?.contra?.store_name || values?.contra?.name}</div>
                                </div>
                            </div>
                        </div>
                        <div css="display:flex;align-items:flex-end;">
                            <Button onClick={handleMessageClick} css="margin-top:20px !important;" circular color="teal">{t("contractmgmt.direct_message")}</Button>
                        </div>
                    </div>
                    <div css="height:2px;background-color:rgb(2,183,178);margin:20px 0;"></div>
                    <h2 css="text-align:center;color:rgb(2,183,178);margin:2rem 0;">{t("contractmgmt.timesheets")}</h2>
                    <div css="margin-bottom:1rem;">
                        {userInfo.id == data.receiver && <Button circular color="teal" onClick={handleNewTimesheet}>{t("contractmgmt.new_timesheet")}</Button>}
                        {userInfo.id == data.sender && <Button disabled={timesheets.filter(x => !x.paid).length > 0} circular color="red" onClick={handleCloseContract}>{t("contractmgmt.close_contract")}</Button>}
                        <div css="margin-top:9px"><Link target="_blank" rel="noopener noreferrer" to={`/current-contracts?id=${data._id}`} className="ui circular teal button">{t("contractmgmt.view_contract")}</Link></div>
                    </div>
                    <Tab panes={panes} defaultActiveIndex={props.data.type == "unpaid" ? 1 : 0} />
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button color='red' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions>
            </Modal>
            <CloseContract data={props.data} id={props.data._id} open={showCloseContract} close={() => setShowCloseContract(false)} close_update={() => { setShowCloseContract(false); props.close(); props.update() }} />
            <NewTimesheet data={props.data} id={data._id} open={showNewTimesheet} close={() => setShowNewTimesheet(false)} update={() => loadData()} />
        </>
    )
}


const Paid = (props) => {
    const [timesheetData, setTimesheetData] = useState({})
    const handleClick = (i) => {
        setTimesheetData(i)
    }
    return <>
        <List divided relaxed>
            {
                props.timesheets.map(item => {
                    return <List.Item>
                        <List.Content>
                            <List.Header onClick={() => handleClick(item)} as='a'>{item.text}</List.Header>
                            <List.Description>{getTimex(item.created_at)}</List.Description>
                        </List.Content>
                    </List.Item>
                })
            }

        </List>
        <ViewTimesheet contract={props.contract} contra={props.contra} clientx={props.clientx} price={props.data.proposed_hourly_rate} paid={true} close={() => setTimesheetData({})} open={Object.keys(timesheetData).length > 0} data={timesheetData} />
    </>
}
const Unpaid = (props) => {
    const [timesheetData, setTimesheetData] = useState({})
    const handleClick = (i) => {
        setTimesheetData(i)
    }
    return <>
        <List divided relaxed>
            {
                props.timesheets.map(item => {
                    return <List.Item>
                        <List.Content>
                            <List.Header onClick={() => handleClick(item)} as='a'>{item.text}</List.Header>
                            <List.Description>{getTimex(item.created_at)}</List.Description>
                        </List.Content>
                    </List.Item>
                })
            }

        </List>
        <ViewTimesheet contract={props.contract} update={props.update} price={props.data.proposed_hourly_rate} contra={props.contra} clientx={props.clientx} close={() => setTimesheetData({})} open={Object.keys(timesheetData).length > 0} data={timesheetData} />
    </>
}
const Rejected = (props) => {
    const [timesheetData, setTimesheetData] = useState({})
    const handleClick = (i) => {
        setTimesheetData(i)
    }
    return <>
        <List divided relaxed>
            {
                props.timesheets.map(item => {
                    return <List.Item>
                        <List.Content>
                            <List.Header onClick={() => handleClick(item)} as='a'>{item.text}</List.Header>
                            <List.Description>{getTimex(item.created_at)}</List.Description>
                        </List.Content>
                    </List.Item>
                })
            }

        </List>
        <ViewTimesheet contract={props.contract} update={props.update} price={props.data.proposed_hourly_rate} contra={props.contra} clientx={props.clientx} close={() => setTimesheetData({})} open={Object.keys(timesheetData).length > 0} data={timesheetData} />
    </>
}

export default TimesheetModal