import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Dimmer, Header, Icon, Input, Label, Loader, Menu, Pagination, Table } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import Footer from '../../shared/footer/footer'
import Navbar from '../../shared/navbar/navbar'
import { ordersActions } from '../../store/orders-slice'
import BusinessOrderDetails from './business-order-details'
import { footButtons } from '../styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import ProgressBar from './progressbar'
import { useQuery } from '../../Home/search'

const BusinessOrders = () => {
    const [loading, setLoading] = useState(true)
    const [orderModal, setOrderModal] = useState({})
    const [trackO, setTrackO] = useState(false)
    const trackOx = useRef(false)
    const [pages, setPages] = useState({})
    const { userInfo } = useSelector(state => state.user)
    const { successOrders } = useSelector(state => state.orders)
    const dispatch = useDispatch()
    const history = useHistory()
    const query = useQuery()
    const [t, i18n] = useTranslation("global")
    const loadData = async (cp = 1) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: { orders, page, pages } } = await axios.get(`/api/business/orders?pageNumber=${cp}&ftd=${trackOx.current || ""}&foi=${RfilterOrderID.current.trim()}&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}`, config)//&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}
            console.log(orders)
            setPages({ current: page, total: pages })
            const success = orders.filter(x => x.order_pass)
            const failed = orders.filter(x => !x.order_pass)
            dispatch(ordersActions.addOrders({ success, failed }))
            if (query.get("id") != null) {
                // const order=success.find(x => x._id == query.get("id"))
                const { data } = await axios.get("/api/business/single_order?id=" + query.get("id"), config)
                setLoading(false)
                if (!data._id) return;
                setOrderModal(data)
                query.delete("id")
                history.replace({
                    search: query.toString(),
                })
            }
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const RfilterOrderID = useRef("")
    const RfilterDateS = useRef("")
    const RfilterDateE = useRef("")

    const handleTrackOChange = () => {
        // setTrackO(p => !p)
        trackOx.current = !trackOx.current
        loadData()
    }
    // useEffect(() => { loadData() }, [trackO])

    const handlePageClick = async (page) => {
        loadData(page)
    }

    const handleShowDetails = (e) => {
        const index = parseInt(e.target.name)
        setOrderModal(successOrders[index])
    }

    const handleDateFromChange = (e) => {
        RfilterDateS.current = e.target.value
    }
    const handleDateToChange = (e) => {
        RfilterDateE.current = e.target.value
    }



    const handleOrderChange = (e) => {
        /*if (e.key === "Enter") {
            setFilterOrderID(e.target.value)
        }*/
        RfilterOrderID.current = e.target.value
    }

    useEffect(() => {
        loadData()
    }, [])

    if (loading)
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    return (
        <>
            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={5} />
                </div>
                <main css="max-width:1200px;margin:0 auto; padding:0 36px;">
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='7'>
                                    <div css="display:flex;align-items:center;flex-wrap:wrap;">
                                        <div css="padding:5px;"><Checkbox checked={trackOx.current} onChange={handleTrackOChange} label={t("orders.order_tracked")} /></div>
                                        <div css="padding:5px;"><Input defaultValue={RfilterOrderID.current} placeholder={t("orders.order_id")} onChange={handleOrderChange} /></div>
                                        <div css="padding:5px;">
                                            <Input defaultValue={RfilterDateS.current} type="date" onChange={handleDateFromChange} />
                                            <span css="margin: 0 1rem;">-</span>
                                            <Input defaultValue={RfilterDateE.current} type="date" onChange={handleDateToChange} />
                                        </div>
                                        <div css="padding:5px;"><Button onClick={() => { loadData() }}>{t("orders.apply_filter")}</Button></div>
                                        <div css="padding:5px;"><Button onClick={() => {
                                            RfilterOrderID.current = "";
                                            RfilterDateS.current = "";
                                            RfilterDateE.current = "";
                                            loadData()
                                        }}>{t("orders.clear_filter")}</Button></div>
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("orders.paypal_order_id")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.date")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.total")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.payout_delivered")}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {successOrders.length > 0 && successOrders.map((x, c) => {
                                return (
                                    <Table.Row key={x.order_id}>
                                        <Table.Cell>{x.order_id}</Table.Cell>
                                        <Table.Cell>{(new Date(x.order_date)).toLocaleDateString()}</Table.Cell>
                                        <Table.Cell>USD ${x.order_items.reduce((a, x) => { return a + x.total_price }, 0).toFixed(2)}</Table.Cell>
                                        <Table.Cell collapsing>
                                            {x.payout_delivered && <Label color="green">YES</Label>}
                                            {!x.payout_delivered && <Label color="red">NO</Label>}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            {/*<Button name={c} onClick={handleDetailsClick} circular>Details</Button>*/}
                                            <Button name={c} onClick={handleShowDetails}>{t("orders.details")}</Button>
                                            {/*<Button loading={paylo} name={c} onClick={() => handleSendMoney(x.order_id, x._id, c)}>pay</Button>*/}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}

                            {
                                successOrders.length < 1 &&
                                (
                                    <Table.Row>
                                        <Table.Cell colSpan='7'>
                                            <div css="display:flex;align-items:center;justify-content:center;">
                                                <Header as='h2' icon>
                                                    <Icon name='ban' />
                                                    {t("orders.nothing")}
                                                </Header>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='7'>
                                    <div css="display:flex;align-items:center;justify-content:flex-end;">
                                        {/* {pages.total > 1 && <Menu pagination>

                                            {[...Array(pages.total).keys()].map((x, c) => {
                                                return <Menu.Item key={c} active={pages.current == c + 1} onClick={() => handlePageClick(c + 1)} as='a'>{c + 1}</Menu.Item>
                                            })}
                                        </Menu>} */}
                                        <Pagination
                                            boundaryRange={0}
                                            defaultActivePage={1}
                                            ellipsisItem={null}
                                            firstItem={null}
                                            lastItem={null}
                                            siblingRange={4}
                                            totalPages={pages.total}
                                            activePage={pages.current}
                                            onPageChange={(e,{activePage})=>handlePageClick(activePage)}
                                        />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </main>
                <div css={footButtons} style={{ marginBottom: "10px" }}>
                    <button onClick={() => history.push("/business-profile-2")} className="ui circular primary button" css="background:#02b7b2 !important;">
                        {t("profile.Back")}</button>
                    <button onClick={() => history.push("/business-settings")} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</button>
                </div>
            </main>

            <Footer />
            <BusinessOrderDetails update={loadData} setClose={() => setOrderModal({})} open={Object.keys(orderModal).length > 0} data={orderModal} />
        </>
    )
}

export default BusinessOrders