import { Link } from 'react-router-dom'
import 'styled-components/macro'

const TermsAndConditionsEnglish = () => {
    return <>
        <div css="font-size:16px;">
            <div>
                <p class="c22 c25"><span class="c5"></span></p>
            </div><a id="id.gjdgxs"></a>
            <p class="c17"><span class="c13"></span></p>
            <p css="text-align:center;font-weight:bold;" class="c18"><span class="c13">TÉRMINOS Y CONDICIONES DE INTERPOWERED.COM</span></p>
            <p class="c22"><span class="c5">Última actualización: 17 de noviembre del, 2021</span></p><a id="id.30j0zll"></a>
            <ol css="font-weight:bold;" class="c2 lst-kix_list_1-0 start" start="1">
                <li class="c1"><span class="c11">ESTE DOCUMENTO CONTIENE INFORMACIÓN MUY IMPORTANTE SOBRE SUS DERECHOS Y
                OBLIGACIONES, ASÍ COMO LAS CONDICIONES, LIMITACIONES Y EXCLUSIONES QUE PODRÍAN APLICARSE A
                USTED.&nbsp;POR FAVOR, LÉALO DETENIDAMENTE.</span><a id="id.1fob9te"></a></li>
            </ol>
            <p css="font-weight:bold;" class="c23"><span class="c11">ESTOS TÉRMINOS REQUIEREN EL USO DEL ARBITRAJE DE FORMA INDIVIDUAL PARA RESOLVER
            DISPUTAS, EN LUGAR DE JUICIOS CON JURADO O DEMANDAS COLECTIVAS.</span><a id="id.3znysh7"></a></p>
            <p css="font-weight:bold;" class="c23"><span class="c11">AL REALIZAR UN PEDIDO DE PRODUCTOS O SERVICIOS DE ESTE SITIO WEB, USTED AFIRMA QUE
            ES MAYOR DE EDAD PARA CELEBRAR ESTE ACUERDO, Y ACEPTA Y ESTÁ OBLIGADO POR ESTOS TÉRMINOS Y CONDICIONES.
            USTED AFIRMA QUE, SI REALIZA UN PEDIDO EN NOMBRE DE UNA ORGANIZACIÓN O EMPRESA, TIENE LA AUTORIDAD LEGAL
            PARA VINCULAR A DICHA ORGANIZACIÓN O EMPRESA A ESTOS TÉRMINOS Y CONDICIONES. </span><a id="id.2et92p0"></a>
            </p>
            <p css="font-weight:bold;" class="c23"><span class="c11">NO PUEDE ORDENAR NI OBTENER PRODUCTOS O SERVICIOS DE ESTE SITIO WEB &nbsp;(A) SI NO
            ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, (B) SI NO ES &nbsp;MAYOR DE EDAD (i) AL MENOS 18 AÑOS DE EDAD O (ii) LA
            EDAD LEGAL PARA FORMAR UN CONTRATO VINCULANTE CON INTERPOWERED, LLC, O (C) SE LE PROHÍBE ACCEDER O USAR ESTE
            SITIO WEB O CUALQUIERA DE LOS CONTENIDOS DE ESTE SITIO WEB, &nbsp;BIENES O SERVICIOS POR LEY
            APLICABLE.</span></p><a id="id.tyjcwt"></a>
            <p class="c12"><span class="c0">Estos términos y condiciones</span><span css="font-weight:bold;" class="c11">&nbsp;(estos "Términos")
        </span><span class="c0">se aplican a la compra y venta de productos y servicios ofrecidos por vendedores
            externos (denominados </span><span css="font-weight:bold;" class="c11">"Vendedor "</span><span class="c0">o</span><span css="font-weight:bold;"
                    class="c11">&nbsp;“Vendedores") </span><span class="c0">o compradores externos ("</span><span css="font-weight:bold;"
                        class="c11">Comprador</span><span class="c0">" o "</span><span css="font-weight:bold;" class="c11">Comprador</span><span
                            class="c0">")a &nbsp;través </span><span class="c11">del</span><span class="c0">&nbsp;mercado en línea
            Interpowered.com ( en este &nbsp;"</span><span css="font-weight:bold;" class="c11">Sitio</span><span class="c0">"). Estos Términos
            están sujetos a cambios por parte de Interpowered, LLC (referidos como "nosotros," "</span><span css="font-weight:bold;"
                    class="c11">nosotros</span><span class="c0">", o "</span><span css="font-weight:bold;" class="c11">nuestro</span><span class="c0">"
                    según lo requiera el contexto) sin previo aviso por escrito en cualquier momento, a nuestra entera
                    discreción. Cualquier cambio en los Términos estará vigente a partir de la "Fecha de última actualización" a
                    la que se hace referencia en el Sitio. Debe revisar estos Términos antes de comprar cualquier producto o
                    servicio que esté disponible a través de este Sitio. Su uso continuado de este Sitio después de la "Fecha de
            la última actualización" constituirá su aceptación y acuerdo con dichos cambios.</span></p><a
                id="id.3dy6vkm"></a>
            <p class="c12"><span class="c0">Estos Términos son una parte integral de los Términos de uso del sitio web que se
            aplican generalmente al uso de nuestro sitio. También debe revisar cuidadosamente nuestra &nbsp;</span><span
                    class="c31"><Link css="font-style:italic;" to="/privacy-policy">Política de privacidad</Link></span><span class="c0">&nbsp;antes de realizar un pedido de productos o servicios a
            través de este Sitio (consulte &nbsp;9).</span></p><a id="id.1t3h5sf"></a>
            <ol class="c2 lst-kix_list_1-0" start="2">
                <li class="c1"><span css="text-decoration:underline;"><span class="c4 c16">Aceptación y Cancelación del</span><span class="c4">&nbsp;Pedido</span></span><span
                    class="c0">. Usted acepta que su pedido es una oferta para comprar, bajo estos Términos, todos los
                    productos y servicios enumerados en su pedido. Todos los pedidos deben ser aceptados por nosotros o no
                    estaremos obligados a aprobar la venta de productos o servicios de terceros Vendedores a usted. Podemos
                    optar por no aceptar pedidos a nuestra entera discreción, incluso después de enviarle un correo
                    electrónico de confirmación con su número de pedido y los detalles de los artículos que ha pedido.
                &nbsp;Los compradores </span><span class="c4">NO</span><span class="c0">&nbsp;pueden cancelar los
                pedidos una vez que el pedido es recibido por nosotros.</span></li>
            </ol><a id="id.4d34og8"></a>
            <ol css="text-decoration:underline;" class="c2 lst-kix_list_1-0" start="3">
                <li class="c1"><span class="c4 c16">Términos de </span><span class="c4">Pago</span><span class="c0">.</span>
                </li>
            </ol><a id="id.2s8eyo1"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1 start" start="1">
                <li class="c8"><span class="c0">(a) Todos los precios, descuentos y promociones publicados en este Sitio están
                sujetos a cambios sin previo aviso. El precio cobrado por un producto o servicio será el precio vigente
                en el momento en que se realice el pedido y se establecerá en su correo electrónico de confirmación de
                pedido. Los aumentos de precios solo se aplicarán a los pedidos realizados después de dichos cambios.
                Los precios publicados no incluyen impuestos ni cargos por envío y manipulación. Todos estos impuestos y
                cargos se agregarán al total de su mercancía y se detallarán en su carrito de compras y en su correo
                electrónico de confirmación de pedido. El Vendedor se esfuerza por mostrar información precisa sobre los
                precios, sin embargo, el Vendedor puede, en ocasiones, cometer errores tipográficos inadvertidos,
                inexactitudes u omisiones relacionadas con los precios y la disponibilidad. Nos reservamos el derecho de
                corregir cualquier error, inexactitud u omisión en cualquier momento y de cancelar cualquier pedido que
                surja de tales ocurrencias.</span></li>
            </ol><a id="id.17dp8vu"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="2">
                <li class="c8"><span class="c0">(b) Podemos ofrecer de vez en cuando promociones en el Sitio que pueden afectar los
                precios y que se rigen por términos y condiciones separados de estos Términos. Si existe un conflicto
                entre los términos de una promoción y estos Términos, regirán los términos de la promoción. </span></li>
            </ol><a id="id.3rdcrjn"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="3">
                <li class="c8"><span class="c0">(c) Los términos de pago están a nuestra entera discreción y, a menos que acordemos
                lo contrario por escrito, el pago debe ser recibido por nosotros antes de nuestra aceptación de un
                pedido. Los métodos de pago actualmente aceptados para todas las compras son los identificados en el
                sitioweb. Usted declara y garantiza que (i) la información de la tarjeta de crédito que nos proporciona
                es verdadera, correcta y completa, (ii) está debidamente autorizado para usar dicha tarjeta de crédito
                para la compra, (iii) los cargos incurridos por usted serán honrados por la compañía de su tarjeta de
                crédito, y (iv) pagará los cargos incurridos por usted a los precios publicados &nbsp;en dólares
                estadounidenses, &nbsp;incluidos los gastos de envío y manipulación y todos los impuestos aplicables, si
                los hub hubiera, independientemente de la cantidad cotizada en el Sitio en el momento de su pedido.
            </span></li>
                <li class="c8"><span class="c0">(d) Los Vendedores son responsables de recaudar y pagar cualquier impuesto asociado
                con el uso y la realización de ventas a través de los servicios del Sitio. Si vendes en el Sitio y
                resides fuera de los EE. UU. o Canadá, debes incluir los impuestos aplicables en los precios de tu
                anuncio. Recuerde que las tarifas del Sitio no incluyen ninguna retención de impuestos que pueda
                aplicarse en su país de origen. Los vendedoresestán obligados a pagar a la Compañía el monto total de
                nuestras tarifas y no pueden deducir ningún impuesto de retención aplicable de esa cantidad. </span>
                </li>
            </ol><a id="id.26in1rg"></a>
            <ol class="c2 lst-kix_list_1-0" start="4">
                <li css="text-decoration:underline;" class="c1"><span class="c4 c16">Envíos; Entrega; Título y Riesgo de Pérdida</span><span class="c0">. </span>
                </li>
            </ol><a id="id.lnxbz9"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="5">
                <li class="c8"><span class="c0">(a) El Vendedor organizará el envío a usted. Consulte la página del producto
                individual para conocer las opciones de entrega específicas. Usted pagará todos los gastos de envío y
                manipulación especificados durante el proceso de pedido. Los gastos de envío y manipulación son el
                reembolso de los costes en los que incurre el Vendedor en el procesamiento, manipulación, embalaje,
                envío y entrega de su pedido.</span></li>
            </ol><a id="id.35nkun2"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="6">
                <li class="c8"><span class="c0">(b) El título y el riesgo de pérdida pasan a usted en el momento de nuestra
                transferencia de los productos al transportista / entrega. Las fechas de envío y entrega son solo
                estimaciones y no se pueden garantizar. No somos responsables de ningún retraso en los envíos.</span>
                </li>
            </ol><a id="id.1ksv4uv"></a>
            <ol class="c2 lst-kix_list_1-0" start="5">
                <li class="c1"><span  css="text-decoration:underline;"><span class="c4 c16">Devoluciones y</span><span class="c4">&nbsp;Reembolsos</span></span><span
                    class="c0">. Proporcionamos un mercado para conectar vendedores &nbsp;y &nbsp;compradores. No vendemos
                    productos en nuestro &nbsp;Sitio. &nbsp; &nbsp;Los vendedores, no nosotros, venden bienes y / o
                servicios directamente a los Compradores en el Sitio, por lo tanto, </span><span
                        class="c4">NO</span><span class="c0">&nbsp;emitimos un reembolso por ninguna compra ni aceptamos cambios
                        por ninguna compra realizada en el Sitio. Todas las ventas son definitivas y cualquier reclamación de un
                        Comprador, que no esté satisfecho con el producto comprado, puede ponerse en contacto directamente con
                el Vendedor del producto. &nbsp;</span></li>
            </ol><a id="id.44sinio"></a>
            <ol class="c2 lst-kix_list_1-0" start="6">
                <li class="c32"><span css="text-decoration:underline;"><span class="c4 c16">Garantía del Fabricante y Descargos de</span><span
                    class="c4">&nbsp;Responsabilidad</span></span><span class="c0">. No fabricamos ni controlamos ninguno de los
                    productos o servicios ofrecidos en nuestro Sitio. La disponibilidad de productos o servicios a través de
                    nuestro Sitio no indica una afiliación o respaldo de ningún producto, servicio o fabricante. En
                    consecuencia, no proporcionamos ninguna garantía con respecto a los productos o servicios ofrecidos en
                nuestro Sitio. </span><a id="id.2jxsxqh"></a><span class="c0">Todos los productos y servicios ofrecidos
                    en este Sitio se proporcionan "tal cual" sin garantía alguna, incluyendo, sin limitación, cualquier (a)
                    garantía de comerciabilidad; (b) garantía de idoneidad para un propósito particular; o (c) garantía
                    contra la infracción de los derechos de propiedad intelectual de un tercero; ya sea expreso o implícito
                por ley, curso de negociación, curso de cumplimiento, uso del comercio o de otra manera. </span><a
                        id="id.z337ya"></a></li>
            </ol>
            <p class="c23"><span class="c0">Algunas jurisdicciones limitan o no permiten el descargo de responsabilidad de
            garantías implícitas o de otro tipo, por lo que el descargo de responsabilidad anterior puede no aplicarse a
            usted. </span><a id="id.3j2qqm3"></a></p>
            <p class="c23"><span class="c0">Y afirmamos que no seremos responsables, en ninguna circunstancia, por cualquier
            incumplimiento de las reclamaciones de garantía o por cualquier daño que surja del incumplimiento por parte
            del fabricante de sus obligaciones de garantía con usted.</span></p><a id="id.1y810tw"></a>
            <ol class="c2 lst-kix_list_1-0" start="7">
                <li class="c1"><span css="text-decoration:underline;"><span class="c4 c16">Limitación de</span><span class="c4">&nbsp;Responsabilidad</span></span><span
                    class="c0">. En ningún caso seremos responsables ante usted o cualquier tercero por daños consecuentes,
                    indirectos, incidentales, especiales, ejemplares, punitivos o mejorados, pérdida de ganancias o ingresos
                    o disminución de valor, que surjan de, o estén relacionados con, y / o en relación con cualquier
                    incumplimiento de estos términos, independientemente de (a) si dichos daños eran previsibles, &nbsp;(b)
                    o si se nos advirtió o no de la posibilidad de tales daños y (c) la teoría legal o equitativa (contrato,
                agravio o de otro tipo) en la que se basa la reclamación.</span><a id="id.4i7ojhp"></a></li>
            </ol>
            <p class="c23"><span class="c0">Nuestra única y total responsabilidad máxima, por cualquier motivo, y su único y
            exclusivo recurso por cualquier causa, se limitará a la cantidad real pagada por usted por los productos y
            servicios que ha pedido a través de nuestro Sitio.</span><a id="id.2xcytpi"></a></p>
            <p class="c23"><span class="c0">La limitación de responsabilidad establecida anteriormente: (i) solo se aplicará en
            la medida permitida por la ley y (ii) no se aplicará a (A) la responsabilidad resultante de nuestra
            negligencia grave o mala conducta intencional y (B) la muerte o lesiones corporales resultantes de nuestros
            actos u omisiones.</span></p><a id="id.1ci93xb"></a>
            <ol class="c2 lst-kix_list_1-0" start="8">
                <li class="c1"><span css="text-decoration:underline;" class="c4">Mercancías No Destinadas a la Reventa o Exportación</span><span class="c0">.
                Usted declara y garantiza que está comprando productos o servicios del &nbsp;Sitio solo para su uso
                personal o doméstico, y no para reventa o exportación. Además, declara y garantiza que todas las compras
                cumplen con la Oficina de Control de Activos Extranjeros ("OFAC") del Departamento del Tesoro de los
                Estados Unidos. &nbsp;Nosotros y cualquier persona que use este Sitio o servicios no podemos participar
                en transacciones que involucren personas, lugares o artículos designados que se originen en esos
                lugares, según lo determinen agencias como (“OFAC”). Es responsabilidad del &nbsp;Vendedor &nbsp;y del
                Comprador &nbsp;familiarizarse con estas restricciones. Podemos cancelar transacciones que presenten un
                riesgo de violar esta política.</span></li>
            </ol><a id="id.3whwml4"></a>
            <ol class="c2 lst-kix_list_1-0" start="9">
                <li class="c1"><span css="text-decoration:underline;" class="c4">Privacidad</span><span class="c0">. Respetamos su privacidad y nos comprometemos
                a protegerla. Nuestra Privacidad, rige el procesamiento de todos los datos personales recopilados de
                usted en relación con su compra de productos o servicios a través del Sitio.</span></li>
                <li class="c1"><span css="text-decoration:underline;" class="c4">Terminación. </span><span class="c0">Podemos cancelar o terminar una cuenta si
                tenemos razones para creer que el usuario ha infringido los derechos de propiedad intelectual de otro,
                ha utilizado nuestro Sitio para infringir la ley de cualquier estado o nación, o de otra manera no ha
                cumplido con estos Términos.</span></li>
            </ol>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1 start" start="1">
                <li class="c8"><span class="c0">(a) Puede cancelar su cuenta en cualquier momento. Usted está obligado a pagar
                cualquier factura pendiente al momento de la terminación. </span></li>
                <li class="c8"><span class="c0">(b) Nos reservamos el derecho de cambiar, suspender o interrumpir cualquiera de los
                Servicios para usted, cualquiera o todos los usuarios, en cualquier momento y por cualquier motivo. No
                seremos responsables ante usted por el efecto que cualquier cambio en el Sitio pueda tener en usted,
                incluidos sus ingresos o su capacidad para generar ingresos a través del &nbsp;Sitio.</span></li>
            </ol><a id="id.2bn6wsx"></a>
            <ol class="c2 lst-kix_list_1-0" start="11">
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">Fuerza Mayor</span><span class="c0">. No seremos responsables ante usted, ni
                se considerará que hemos incumplido o incumplido estos Términos, por cualquier falla o retraso en
                nuestro desempeño bajo estos Términos cuando y en la medida en que dicha falla o demora sea causada por
                o resulte de actos o circunstancias fuera de nuestro control razonable, incluidos, entre otros, actos de
                Dios, &nbsp;inundación, incendio, terremoto, explosión, acciones gubernamentales, guerra, invasión u
                hostilidades (ya sea que se declare o no la guerra), amenazas o actos terroristas, disturbios u otros
                disturbios civiles, emergencia nacional, revolución, insurrección, epidemia, cierre patronal, huelgas u
                otras disputas laborales (ya sea relacionada o no con nuestra fuerza laboral), o restricciones o
                retrasos que afecten a los transportistas o incapacidad o demora en la obtención de suministros de
                materiales adecuados o adecuados, &nbsp;materiales o avería de telecomunicaciones o corte de
                energía.</span></li>
            </ol><a id="id.qsh70q"></a>
            <ol class="c2 lst-kix_list_1-0" start="12">
                <li class="c1"><span css="text-decoration:underline;"><span class="c4 c16">Ley Aplicable y</span><span class="c4">&nbsp;Jurisdicción</span></span><span
                    class="c0">. Este Sitio es operado desde los Estados Unidos. Todos los asuntos que surjan de o estén
                    relacionados con estos Términos se rigen e interpretan de acuerdo con las leyes internas del Estado de
                    Florida sin dar efecto a ninguna disposición o regla de elección o conflicto de leyes (ya sea del Estado
                    de Florida o de cualquier otra jurisdicción) que cause la aplicación de las leyes de cualquier
                jurisdicción que no sea la del Estado de Florida.</span></li>
            </ol><a id="id.3as4poj"></a>
            <ol class="c2 lst-kix_list_1-0" start="13">
                <li css="text-decoration:underline;" class="c1"><span class="c4 c16">Resolución de Disputas y Arbitraje</span><span
                    class="c4">&nbsp;Vinculante</span><span class="c0">.</span></li>
            </ol><a id="id.1pxezwc"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="3">
                <li class="c8"><span class="c0">(a) Y están de acuerdo en renunciar a cualquier derecho a litigar reclamos en un
                tribunal o ante un jurado. Los derechos que tendría si fuera a la corte también pueden no estar
                disponibles o pueden estar limitados en el arbitraje.</span></li>
            </ol><a id="id.49x2ik5"></a>
            <p class="c10"><span class="c0">Una reclamación, disputa o controversia (ya sea por contrato, agravio o de otro
            modo, ya sea preexistente, presente o futura, e incluyendo reclamaciones legales, de protección al
            consumidor, de derecho consuetudinario, agravio intencional, cautelares y equitativas) entre usted y
            nosotros que surjan de o se relacionen de alguna manera con su compra de productos o servicios a través del
            &nbsp;Sitio,se resolverá exclusiva y finalmente mediante arbitraje vinculante.</span></p><a
                id="id.2p2csry"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="4">
                <li class="c8"><span class="c0">(b) El arbitraje será administrado por la Asociación Americana de Arbitraje
                ("</span><span css="font-weight:bold;" class="c11">AAA</span><span class="c0">") de acuerdo con las Reglas de Arbitraje del
                Consumidor (las "Reglas de la </span><span css="font-weight:bold;" class="c11">AAA</span><span class="c0">") vigentes en ese
                    momento, excepto según lo modificado por esta &nbsp;13. (Las Reglas de la AAA están disponibles en
                    www.adr.org/arb_med o llamando a la AAA al 1-800-778-7879). La Ley Federal de Arbitraje regirá la
                interpretación y aplicación de esta sección.</span></li>
            </ol><a id="id.147n2zr"></a>
            <p class="c10"><span class="c0">El árbitro tendrá autoridad exclusiva para resolver cualquier disputa relacionada
            con la arbitrabilidad y / o aplicabilidad de esta disposición de arbitraje, incluida cualquier impugnación
            de desmesurada o cualquier otra impugnación de que la disposición de arbitraje o el acuerdo sean nulos,
            anulables o inválidos. El árbitro estará facultado para otorgar cualquier reparación que esté disponible en
            el tribunal bajo la ley o en equidad. Cualquier laudo del árbitro (s) será definitivo y vinculante para cada
            una de las partes, y puede ser ingresado como una sentencia en cualquier tribunal de jurisdicción
            competente.</span></p><a id="id.3o7alnk"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="5">
                <li class="c8"><span class="c0">(c) Usted acepta un arbitraje de forma individual. en cualquier disputa, ni usted ni
                nosotros tendremos &nbsp;derecho a unirse o consolidar reclamos de o contra otros clientes en un
                tribunal o en arbitraje o participar de otra manera en cualquier reclamo como representante de la clase,
                miembro de la clase o en calidad de abogado general privado. el tribunal arbitral no puede consolidar
                las reclamaciones de más de una persona, y no puede presidir ninguna forma de procedimiento
                representativo o colectivo. El tribunal arbitral no tiene poder para considerar la aplicabilidad de esta
                renuncia al arbitraje colectivo y cualquier impugnación de la renuncia al arbitraje colectivo solo puede
                plantearse en un tribunal de jurisdicción competente.</span></li>
            </ol><a id="id.23ckvvd"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="6">
                <li class="c8"><span class="c0">(d) Si alguna disposición de este acuerdo de arbitraje se considera inaplicable, la
                disposición inaplicable se eliminará y se aplicarán los términos de arbitraje restantes.</span></li>
            </ol><a id="id.ihv636"></a>
            <ol class="c2 lst-kix_list_1-0" start="14">
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">Indemnización</span><a id="id.32hioqz"></a><span class="c0">. Usted acepta
                &nbsp;defendernos, indemnizarnos y eximirnos de responsabilidad a nosotros &nbsp;y a nuestros
                &nbsp;afiliados, y a sus funcionarios, directores, empleados, agentes, sucesores y cesionarios contra
                todas las pérdidas, daños, responsabilidades, deficiencias, acciones, juicios, intereses, premios,
                multas, costos o gastos de cualquier tipo (incluidos los honorarios razonables de abogados) que surjan
                de o resulten de:</span></li>
            </ol>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1 start" start="1">
                <li class="c8"><span class="c0">(a) Lesiones corporales, muerte de cualquier persona o daños a bienes personales
                reales o tangibles como resultado de sus actos u omisiones; o</span></li>
                <li class="c8"><span class="c0">(b) Su tergiversación o incumplimiento de cualquier garantía o obligación en virtud
                de este Acuerdo.</span></li>
            </ol><a id="id.1hmsyys"></a>
            <ol class="c2 lst-kix_list_1-0" start="15">
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">Asignación</span><span class="c0">. Usted no cederá ninguno de sus derechos
                ni delegará ninguna de sus obligaciones en virtud de estos Términos sin nuestro consentimiento previo
                por escrito. Cualquier supuesta asignación o delegación en violación de esta Sección es nula y sin
                efecto. Ninguna asignación o delegación lo exime de ninguna de sus obligaciones en virtud de estos
                Términos.</span></li>
            </ol><a id="id.41mghml"></a>
            <ol class="c2 lst-kix_list_1-0" start="16">
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">No Exenciones</span><span class="c0">. El hecho de que no hagamos cumplir
                cualquier derecho o disposición de estos Términos no constituirá una renuncia a la aplicación futura de
                ese derecho o disposición. La renuncia a cualquier derecho o disposición será efectiva solo si se hace
                por escrito y está firmada por un representante debidamente autorizado de nosotros.</span></li>
                <li class="c7"><span css="text-decoration:underline;" class="c4">Propiedad Intelectual. </span><span class="c0">Proporcionamos un lugar donde los
                Vendedores pueden crear sus propias tiendas y son responsables del inventario, las compras y el
                cumplimiento de la ley. El contenido cargado en el Sitio es generado por vendedores que no son agentes,
                empleados o representantes de nosotros. Los vendedores son responsables de garantizar que tienen todos
                los derechos necesarios sobre su contenido y que no están infringiendo o violando los derechos de
                terceros al publicarlo. Podemos deshabilitar y / o eliminar cualquier contenido cargado en el Sitio que
                nosotros creamos que, o por un tercero que nos notifica, está violando los derechos de Propiedad
                Intelectual. No pretendemos ofrecer ningún asesoramiento legal ni hacer ninguna determinación legal
                sobre si el contenido está infringiendo los derechos de propiedad intelectual de otra persona.</span>
                </li>
                <li class="c7"><span css="text-decoration:underline;" class="c4">Política de Derechos de Autor</span><span class="c0">. Nos tomamos en serio las
                reclamaciones de infracción de derechos de autor. Responderemos a los avisos de presunta infracción de
                derechos de autor que cumplan con la ley aplicable. Si cree que cualquier material accesible en o desde
                este Sitio infringe sus derechos de autor, puede solicitar la eliminación de esos materiales (o el
                acceso a ellos) del Sitio enviando una solicitud a &nbsp;</span><span class="c0 c30"><a class="c6" css="background-color:#c0c0c0;"
                        href="mailto:Legal@interpowered.com">Legal@interpowered.com.</a></span></li>
            </ol>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1 start" start="1">
                <li class="c8"><span css="text-decoration:underline;" class="c4">(a) Denuncia de Reclamaciones por Infracción de Derechos de Autor. </span><span
                    class="c0">De acuerdo con la Ley de Limitación de Responsabilidad por Infracción de Derechos de Autor en
                    Línea de la Ley de Derechos de Autor del Milenio Digital (17 U.S.C. § 512 ("DMCA"), el aviso por escrito
                (el "Aviso dmca") debe incluir sustancialmente lo siguiente: </span></li>
            </ol>
            <ul class="c2 lst-kix_list_44-0 start">
                <li class="c19 li-bullet-0"><span class="c0">Su firma física o electrónica.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Identificación del trabajo protegido por derechos de autor que cree
                que ha sido infringido o, si el reclamo involucra múltiples trabajos en el sitio web, una lista
                representativa de dichos trabajos.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Identificación del material que usted cree que está infringiendo de
                una manera lo suficientemente precisa como para permitirnos localizar ese material.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Información adecuada mediante la cual podemos comunicarnos con
                usted (incluido su nombre, dirección postal, número de teléfono y, si está disponible, dirección de
                correo electrónico).</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Una declaración de que usted cree de buena fe que el uso del
                material protegido por derechos de autor no está autorizado por el propietario de los derechos de autor,
                su agente o la ley.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Una declaración de que la información en el aviso por escrito es
                precisa.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Una declaración, bajo pena de perjurio, de que usted está
                autorizado a actuar en nombre del propietario de los derechos de autor.</span></li>
                <li class="c19 li-bullet-0"><span class="c0">Nuestro agente de derechos de autor designado para recibir avisos
                de DMCA es:</span></li>
            </ul><a id="t.a5157698def29c785ed355d0b02e79959524b3e1"></a><a id="t.0"></a>
            <table class="c33">
                <tbody>
                    <tr class="c26">
                        <td class="c20" colspan="1" rowspan="1">
                            <p class="c22"><span>Dana Terry Interpowered, LLC.3122 MAHAN DR. SUITE 801-265 TALLAHASSEE, FLORIDA,
                            32308<br /><br /><br /></span><span class="c27"><a class="c6" css="background-color:#c0c0c0;"
                                    href="mailto:Legal@interpowered.com">Legal@interpowered.com</a></span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="c22 c28"><span>Si no cumple con todos los requisitos de la Sección 512 (c) (3) de la DMCA, su Aviso de
            DMCA puede no ser efectivo.</span></p>
            <p class="c22 c28"><span>Tenga en cuenta que si a sabiendas tergiversa materialmente que el material o la actividad
            en el sitio web está infringiendo sus derechos de autor, puede ser considerado responsable de los daños
            (incluidos los costos y los honorarios de abogados) en virtud de la Sección 512 (f) de la DMCA.</span></p>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="2">
                <li class="c8"><span css="text-decoration:underline;" class="c4">(b) Procedimientos de Contranotificación. &nbsp;</span><span class="c0">Si cree que
                el material que publicó en el sitio web fue eliminado o el acceso a él fue deshabilitado por error o
                identificación errónea, puede presentar una contranotificación con nosotros (un</span><span css="font-weight:bold;"
                        class="c11">"Contraaviso") </span><span class="c0">enviando una notificación por escrito a nuestro
                        agente de derechos de autor designado [arriba / abajo]. De conformidad con la DMCA, la
                Contranotificación debe incluir sustancialmente lo siguiente:</span></li>
            </ol>
            <ul class="c2 lst-kix_list_19-0 start">
                <li class="c3 li-bullet-0"><span class="c0">Su firma física o electrónica.</span></li>
                <li class="c3 li-bullet-0"><span class="c0">Una identificación del material que se ha eliminado o al que se ha
                deshabilitado el acceso y la ubicación en la que apareció el material antes de que se eliminara o se
                deshabilitara el acceso. </span></li>
                <li class="c3 li-bullet-0"><span class="c0">Información adecuada mediante la cual podemos comunicarnos con usted
                (incluido su nombre, dirección postal, número de teléfono y, si está disponible, dirección de correo
                electrónico).</span></li>
                <li class="c3 li-bullet-0"><span class="c0">Una declaración bajo pena de perjurio por su parte de que cree de
                buena fe que el material identificado anteriormente fue eliminado o deshabilitado como resultado de un
                error o identificación errónea del material que se eliminará o deshabilitará.</span></li>
                <li class="c3 li-bullet-0"><span class="c0">Una declaración de que usted aceptará la jurisdicción del Tribunal
                Federal de Distrito para el distrito judicial en el que se encuentra su dirección (o si reside fuera de
                los Estados Unidos para cualquier distrito judicial en el que se pueda encontrar el sitio web) y que
                aceptará la notificación de la persona (o un agente de esa persona) que proporcionó el sitio web con la
                queja en cuestión.</span></li>
            </ul><a id="t.08160ee46d585d2a024c2d098cb523d892616e5c"></a><a id="t.1"></a>
            <table class="c9">
                <tbody>
                    <tr class="c26">
                        <td class="c21" colspan="1" rowspan="1">
                            <p class="c22"><span>Dana Terry Interpowered, LLC. <br /><br /></span><span css="background-color:#c0c0c0;" class="c30">Interpowered,
                            LLC</span><span>&nbsp; +1 (850)938-2788<br />3122 MAHAN DR. SUITE 801-265 TALLAHASSEE,
                            FLORIDA, 32308<br /></span><span class="c27"><a class="c6" css="background-color:#c0c0c0;"
                                    href="mailto:Legal@interpowered.com">Legal@interpowered.com</a></span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="c29"><span class="c0">La DMCA nos permite restaurar el contenido eliminado si la parte que presenta el
            Aviso original de la DMCA no presenta una acción judicial contra usted dentro de los diez días hábiles
            posteriores a la recepción de la copia de su Contranotificación.</span></p>
            <p class="c29"><span class="c0">Tenga en cuenta que si a sabiendas tergiversa materialmente que el material o la
            actividad en el sitio web se eliminó o desactivó por error o identificación errónea, puede ser considerado
            responsable de los daños (incluidos los costos y los honorarios de abogados) según la Sección 512 (f) de la
            DMCA.]</span></p><a id="id.2grqrue"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="3">
                <li class="c8"><span css="text-decoration:underline;" class="c4">(c) Infractores reincidentes. </span><span class="c0">&nbsp;Es nuestra política en
                circunstancias apropiadas deshabilitar y / o cancelar las cuentas de los usuarios que son infractores
                reincidentes.</span></li>
            </ol><a id="id.vx1227"></a>
            <ol class="c2 lst-kix_list_1-0" start="19">
                <li class="c7"><span css="text-decoration:underline;" class="c4 c16">No Terceros Beneficiarios.</span><span class="c0">&nbsp;Estos Términos no
                confieren ni pretenden conferir ningún derecho o recurso a ninguna persona que no sea usted.</span></li>
            </ol><a id="id.3fwokq0"></a>
            <ol class="c2 lst-kix_list_1-0" start="20">
                <li class="c7"><span css="text-decoration:underline;" class="c4 c16">Avisos</span><span class="c0">.</span></li>
            </ol><a id="id.1v1yuxt"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="4">
                <li class="c8"><span css="text-decoration:underline;" class="c4">(a) Para Usted.</span><span class="c0">&nbsp;Podemos proporcionarle cualquier
                notificación en virtud de estos Términos mediante:(i)enviando un mensaje a la dirección de correo
                electrónico que proporcione o (ii) publicando en el Sitio. Los avisos enviados por correo electrónico
                serán efectivos cuando enviemos el correo electrónico y los avisos que proporcionemos mediante
                publicación serán efectivos al momento de la publicación. Es su responsabilidad mantener su dirección de
                correo electrónico actualizada.</span></li>
            </ol><a id="id.4f1mdlm"></a>
            <ol css="list-style:none;" class="c2 lst-kix_list_1-1" start="5">
                <li class="c8"><span css="text-decoration:underline;" class="c4">(b) A Nosotros.</span><span class="c0">&nbsp;Para notificarnos bajo estos Términos,
                debe comunicarse con nosotros de la siguiente manera:(i)por entrega personal, mensajería nocturna o
                correo certificado o certificado a &nbsp;INTERPOWERED, LLC, 3122 MAHAN DR. SUITE 801-265 TALLAHASSEE,
                FLORIDA, 32308. Podemos actualizar la dirección de los avisos para nosotros mediante la publicación de
                un aviso en el &nbsp;Sitio. Los avisos proporcionados por entrega personal serán efectivos de inmediato.
                Los avisos proporcionados por mensajería nocturna serán efectivos un día hábil después de su envío. Los
                avisos proporcionados por correo certificado o certificado serán efectivos tres días hábiles después de
                que se envíen; (ii) por correo electrónico enviado a &nbsp;</span><span class="c0 c30"><a class="c6" css="background-color:#c0c0c0;"
                        href="mailto:info@interpowered.com">info@interpowered.com.</a></span></li>
            </ol><a id="id.2u6wntf"></a>
            <ol class="c2 lst-kix_list_1-0" start="21">
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">Divisibilidad</span><span class="c0">. Si alguna disposición de estos
                Términos es inválida, ilegal, nula </span><a id="id.19c6y18"></a><span class="c0">&nbsp;o inaplicable,
                    entonces esa disposición se considerará separada de estos Términos y no afectará la validez o
                aplicabilidad de las disposiciones restantes de estos Términos.</span></li>
                <li class="c1"><span css="text-decoration:underline;" class="c4 c16">Acuerdo completo</span><span class="c0">. Estos Términos, el acuerdo de
                licencia relacionado con cualquier producto o servicio que obtenga en o a través de este Sitio,
            </span><span class="c0"><a class="c6"
                        href="https://www.google.com/url?q=https://interpowered.com/termsofservice&amp;sa=D&amp;source=editors&amp;ust=1639774074675000&amp;usg=AOvVaw2JAv52TEjE-U42z87OG4oX">nuestros
                    Términos de uso del</a></span><span class="c0">&nbsp;sitio web &nbsp;y nuestra Política de
            </span><span class="c31"><a class="c6"
                        href="https://www.google.com/url?q=https://interpowered.com/privacypolicy&amp;sa=D&amp;source=editors&amp;ust=1639774074676000&amp;usg=AOvVaw3bMljXUChvt95aI_2zraWU">privacidad</a></span><span
                            class="c0">&nbsp; se considerarán el acuerdo final e integrado entre usted y nosotros sobre los asuntos
                &nbsp;contenidos en estos Términos.</span></li>
            </ol>
            <div>
                <p class="c15"><span class="c5"></span></p>
                <p class="c22"><span
                    class="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </p>
            </div>
        </div>
    </>
}
export default TermsAndConditionsEnglish