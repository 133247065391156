import axios from "../axios"
import { useSelector } from "react-redux"
import { Button, Dimmer, Header, Icon, Input, Loader, Pagination, Segment, Table } from "semantic-ui-react"
import { useEffect, useRef, useState } from "react"
import 'styled-components/macro'
import CoursesModalEditAdmin from "../Profile/business/edit_courses_modal_admin"
import { getDates } from "./approvals_products"
import {useTranslation} from 'react-i18next'

const ApprovalsCourses = () => {
    const [t,]=useTranslation("global")
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [courseData, setCourseData] = useState({})
    const [pages, setPages] = useState({ current: 1, total: 0 })
    const filter_business_name = useRef("")
    const filter_product_name = useRef("")
    const filter_created_at_from = useRef("")
    const filter_created_at_to = useRef("")
    const { userInfo } = useSelector(state => state.user)
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const loadData = async (cp = 1) => {
        setLoading(true)
        try {
            const { data: { products, page, pages:pagesx } } = await axios.get(`/api/product/approvals/courses?page_number=${cp}&business_name=${filter_business_name.current}&product_name=${filter_product_name.current}&created_at_from=${getDates(filter_created_at_from.current, "ini")}&created_at_to=${getDates(filter_created_at_to.current, "fin")}`, config)
            setProducts(products)
            setPages({ current: page, total: pagesx })
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])
    const handleShowDetails = (data) => {
        setCourseData(data)
    }
    const handleApprove = async (id) => {
        setLoading(true)
        try {
            await axios.post("/api/product/approvals/courses/approve", {
                id
            }, config)
            setCourseData({})
            await loadData()
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleReject = async (id, reason) => {
        setLoading(true)
        try {
            await axios.post("/api/product/approvals/courses/reject", {
                id,
                reason
            }, config)
            setCourseData({})
            await loadData()
        } catch (e) { alert(e) }
        setLoading(false)
    }

    const handlePageClick = () => {
    }
    if (loading) {
        return <Segment style={{ height: "300px" }}>
            <Dimmer active inverted>
                <Loader inverted />
            </Dimmer>
        </Segment>
    }
    return <>
        <Table celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                        <h2 css="text-align:center;">Courses</h2>
                        <div css="display:flex;align-items:center;flex-wrap:wrap;">
                            <div css="padding:5px;"><Input defaultValue={filter_product_name.current} placeholder={t("administration.product_name")} onChange={({ target: { value } }) => filter_product_name.current = value} /></div>
                            <div css="padding:5px;"><Input defaultValue={filter_business_name.current} placeholder={t("administration.business_name")} onChange={({ target: { value } }) => filter_business_name.current = value} /></div>
                            <div css="padding:5px;">
                                <Input defaultValue={filter_created_at_from.current} type="date" onChange={({ target: { value } }) => filter_created_at_from.current = value} />
                                <span css="margin: 0 1rem;">-</span>
                                <Input defaultValue={filter_created_at_to.current} type="date" onChange={({ target: { value } }) => filter_created_at_to.current = value} />
                            </div>
                            <div css="padding:5px;"><Button onClick={() => { loadData() }}>Apply filter</Button></div>
                            <div css="padding:5px;"><Button onClick={() => {
                                filter_business_name.current = "";
                                filter_product_name.current = "";
                                filter_created_at_from.current = "";
                                filter_created_at_to.current = "";
                                loadData()
                            }}>Clear filter</Button></div>
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Business Name</Table.HeaderCell>
                    <Table.HeaderCell>Created at</Table.HeaderCell>
                    <Table.HeaderCell>Updated at</Table.HeaderCell>
                    <Table.HeaderCell>Details</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    products.map(product => <>
                        <Table.Row key={product._id}>
                            <Table.Cell>{product.name}</Table.Cell>
                            <Table.Cell>{product.vendor_name}</Table.Cell>
                            <Table.Cell>{(new Date(product.created_at)).toLocaleDateString()}</Table.Cell>
                            <Table.Cell>{(new Date(product.updated_at)).toLocaleDateString()}</Table.Cell>
                            <Table.Cell collapsing>
                                <Button onClick={() => handleShowDetails(product)}>Details</Button>
                            </Table.Cell>
                        </Table.Row>
                    </>)
                }
                {
                    products.length < 1 &&
                    (
                        <Table.Row>
                            <Table.Cell colSpan='5'>
                                <div css="display:flex;align-items:center;justify-content:center;">
                                    <Header as='h2' icon>
                                        <Icon name='ban' />
                                                Nothing
                                            </Header>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                        <div css="display:flex;align-items:center;justify-content:end;">
                            <Pagination
                                boundaryRange={0}
                                defaultActivePage={1}
                                ellipsisItem={null}
                                firstItem={null}
                                lastItem={null}
                                siblingRange={4}
                                totalPages={pages.total}
                                activePage={pages.current}
                                onPageChange={(e, { activePage }) => loadData(activePage)}
                            />
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
        {
            (Object.keys(courseData).length > 0) && <CoursesModalEditAdmin data={courseData} testv={[]} onApprove={handleApprove} onReject={handleReject} update={() => { }} onClose={() => setCourseData({})} />
        }
    </>
}

export default ApprovalsCourses



{/* <div css="display:flex;align-items:center;flex-wrap:wrap;">
                                <div css="padding:5px;"><Checkbox checked={payoutD} onChange={() => setPayoutD(p => !p)} label='Payout delivered' /></div>
                                <div css="padding:5px;"><Input defaultValue={RfilterOrderID.current} placeholder='Order ID' onChange={handleOrderChange} /></div>
                                <div css="padding:5px;"><Input defaultValue={RfilterVendorName.current} placeholder='Vendor name' onChange={handleVendorNameChange} /></div>
                                <div css="padding:5px;">
                                    <Input defaultValue={RfilterDateS.current} type="date" onChange={handleDateFromChange} />
                                    <span css="margin: 0 1rem;">-</span>
                                    <Input defaultValue={RfilterDateE.current} type="date" onChange={handleDateToChange} />
                                </div>
                                <div css="padding:5px;"><Button onClick={() => { loadData() }}>Apply filter</Button></div>
                                <div css="padding:5px;"><Button onClick={() => {
                                    RfilterOrderID.current = "";
                                    RfilterVendorName.current = "";
                                    RfilterDateS.current = "";
                                    RfilterDateE.current = "";
                                    loadData()
                                }}>Clear filter</Button></div>
                            </div> */}


