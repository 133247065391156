import Footer from "./footer/footer"
import Navbar from "./navbar/navbar"
import 'styled-components/macro'
import TermsAndConditionsEnglish from "./terms-and-conditions-english"
import TermsAndConditionsSpanish from "./terms-and-conditions-spanish"
import { useTranslation } from "react-i18next"

const TermsOfService = () => {
    const [t,i18n]=useTranslation("global")
    return <>
        <Navbar />
        <div css="max-width:1200px;margin:0 auto;padding:0 36px;">
            {i18n.language==="en"&&<TermsAndConditionsEnglish/>}
            {i18n.language==="es"&&<TermsAndConditionsSpanish/>}
        </div>
        <Footer />
    </>
}

export default TermsOfService