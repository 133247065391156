import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import axios from '../../axios'
import { uiActions } from '../../store/ui-slice'
import { useTranslation } from 'react-i18next'
import "styled-components/macro"
import { formatSubject, getTimex, url_environment } from '../../config'

const ViewMessage = (props) => {
    const [t, i18n] = useTranslation("global")
    const dispatch = useDispatch()
    const data={...props.data}
    // const [data,setData]=useState({})
    const [data_replies,setDataReplies]=useState([])
    // const data_replies = [...data.replies].reverse()
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const loadData = async () => {
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            if (props.data.return == "unread") {
                await axios.put("/api/user/message", { id: data._id }, config)
            }
            const {data:mdata}=await axios.post("/api/user/message/get",{id:props.data._id},config)
            
            setDataReplies([...mdata.replies].reverse())
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const messageRef = useRef()
    const handleReplayClick = () => {
        let replies = []
        if (("replies" in data) && data.replies.length > 0) {
            replies = [...data.replies]
        }
        replies.push(data._id)
        // if (props.data.return == "sent")
        //     dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...data.receiver }, subject: data.subject, editable_subject: true, custom_text: `<br/><br/><i>${data.sender.store_name || data.sender.name}: ${html_message}</i>` } }))
        // else
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...data.sender }, subject: data.subject, editable_subject: true, reply_message: replies } }))
    }
    // let html_message = data.message.replace(/&lt;script|<script/gi, "")
    // if (html_message.includes("{[(image)]}")) {
    //     html_message = html_message.replace("{[(image)]}", `<br/><img alt="" src="https://interpowered.com:3001${data.file}" style="width:150px;height:150px;object-fit:cover;object:position:center;" />`)
    // }
    // // html_message = html_message.replace("{[(date)]}", " | " + getTimex(data.created_at, true))
    // let pre_html_message = html_message
    // let idx = 0, ini = 0, fin = 0, sdate;
    // while (true) {
    //     idx = pre_html_message.indexOf("{[(date:", idx); if (idx == -1) break;
    //     ini = idx + 8
    //     idx = pre_html_message.indexOf(")]}", idx)
    //     fin = idx
    //     sdate = pre_html_message.substring(ini, fin)
    //     console.log("date: ", sdate)
    //     pre_html_message = pre_html_message.replace("{[(date:", " | ")
    //     pre_html_message = pre_html_message.replace(")]}", "")
    //     pre_html_message = pre_html_message.replace(sdate, getTimex(sdate))
    // }

    useEffect(() => { loadData() }, [])
    const getNameSender = (id) => {
        if (id == userInfo.id) {
            return userInfo.store_name || userInfo.name
        }
        if (props.data.return == "sent") {
            return data.receiver.store_name || data.receiver.name
        }
        return data.sender.store_name || data.sender.name
    }
    if (loading || Object.keys(data).length<1) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <h3 css="text-align:center;">{t("messages.messages")}</h3>
            <div css="height:2px;background:teal;margin:7px 0;"></div>
            <Button color="teal" circular onClick={() => dispatch(uiActions.change_action({ open: true, action: "messages", return: props.data.return }))}>Back</Button>
            {props.data.return == "sent" || <Button color="teal" css="background:rgb(0,128,129) !important;" circular onClick={handleReplayClick} floated="right">{t("messages.replay")}</Button>}
            <div css="margin-top:20px;">
                <div css="box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;padding:5px 7px;margin:10px 0;">
                    {props.data.return != "sent" && <p><span css="font-weight:700;">{t("messages.from")}:</span> {props.data.sender.store_name || props.data.sender.name}</p>}
                    {props.data.return == "sent" && <p><span css="font-weight:700;">{t("messages.to")}:</span> {props.data.receiver.store_name || props.data.receiver.name}</p>}
                </div>
                {/* <hr /> */}
                <div css="box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;padding:5px 7px;margin:10px 0;">
                    <p><span css="font-weight:700;">Subject: </span><span>{formatSubject(data.subject)}</span></p>
                </div>
                {/* <hr /> */}
                <div css="box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;padding:5px 7px;margin:10px 0;">
                    <p>
                        <b>Message: </b><span>{data.message}</span><span css="float:right;">{getTimex(data.created_at)}</span><br />
                        {data.file && <img css="width:150px;height:150px;object-fit:cover;object:position:center;" src={`${url_environment}${data.file}`} alt="" />}
                    </p>
                    {
                        data.replies && data.replies.length > 0 && data_replies.map((item) => {
                            return (
                                <p>
                                    <hr />
                                    <i><b>{getNameSender(item.sender)}</b></i>: <i>{item.message}</i><span css="float:right;">{getTimex(item.created_at)}</span><br />
                                    {item.file && <img css="width:150px;height:150px;object-fit:cover;object:position:center;" src={`${url_environment}${item.file}`} alt="" />}
                                </p>
                            )
                        })

                    }
                    {/* <p><span css="font-weight:700;">Message:</span> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pre_html_message) }} /></p> */}
                </div>
                {/* <div css="box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;padding:5px 7px;margin:10px 0;">
                    <p><span css="font-weight:700;">Message:</span> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pre_html_message) }} /></p>
                </div> */}
                {/* {data.file && <img src={`https://interpowered.com:3001${data.file}`} css="width:150px;height:150px;object-fit:cover;object:position:center;" alt="" />} */}
            </div>
        </>
    )
}

export default ViewMessage