import React, { useEffect, useMemo, useState } from 'react'
import Navbar from '../../shared/navbar/navbar'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import 'styled-components/macro'
import { Button, Dimmer, Icon, Label, Loader, Popup, Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DetailsTab from './details.product.tab';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import axios from '../../axios';
import Footer from '../../shared/footer/footer';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/cart-slice';
import ReviewsTab from './reviews.tab';
import { uiActions } from '../../store/ui-slice';
import { Link } from 'react-router-dom';
import { userActions } from '../../store/user-slice';
import { getServiceName } from '../../cart/cart/utils';
import countries, { languages, parseXML } from '../countries';
import { url_environment } from '../../config';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ServicePage = () => {
    const match = useRouteMatch()
    const productId = match.params.id
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({})
    const [wishlist, setWishlist] = useState([])
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)
    const [courseBuy, setCourseBuy] = useState(false)
    const query = useQuery()
    const dispatch = useDispatch()
    const history = useHistory()
    const { userInfo } = useSelector(state => state.user)
    const panes = [
        {
            menuItem: t("profile.Details"), render: () => <Tab.Pane>
                <DetailsTab data={values} />
            </Tab.Pane>
        },
        {
            menuItem: t("profile.Reviews"), render: () => <Tab.Pane>
                <ReviewsTab id={productId} />
            </Tab.Pane>
        }
    ]

    useEffect(() => {
        loadData()
    }, [])


    const checkShipping = (p) => {
        if (p.shipper == "dhl") {
            if (userInfo)
                if (!p.dhl.find(c => c.country == userInfo.id_country)) {
                    return false
                }
        }
        if (p.shipper == "other") {
            if (userInfo)
                if (!p.other.find(c => c.country == userInfo.id_country)) {
                    return false
                }
        }
        return true
    }

    const handleFavoriteClick = async (e) => {
        const id = e.target.getAttribute("data-product-id")
        const type = e.target.getAttribute("data-type")
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (id.endsWith(" remove")) {
                const rid = id.split(" ")[0]
                await axios.delete("/api/user/favorite/" + rid, config)
            } else {
                await axios.post("/api/user/favorite", { id, type }, config)
            }
            dispatch(userActions.updateFavorites({ id, type }))
        } catch (e) { alert(e) }
    }

    const renderFavoritesIcon = () => {
        return <>
            {(userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == values.product._id) != -1) ? (
                // <div css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                <Icon size="large" circular css="margin:0 !important;cursor:pointer;position:absolute;top:15px;right:15px;z-index:9;background:#fff;" data-type={"product"} data-product-id={values.product._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                // </div>
            ) : (userInfo &&
                // <div className="heartBtn" css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:${"none"};align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                <Icon size="large" circular css="margin:0 !important;cursor:pointer;position:absolute;top:15px;right:15px;z-index:9;background:#fff;" data-type={"product"} data-product-id={values.product._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                // </div>
            )}
        </>
    }

    const handleMessageSeller = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { ...values, subject: "About product", receiver_type: "business", link: `/product/${productId}` } }))
    }

    const loadData = async () => {
        try {
            const { data } = await axios.post(`/api/product/select/${productId}`, { type: "business" })
            setValues(data)
            setWishlist(data.wishlist)
            let imgs = data.product.images.filter(x => {
                return !x.match(new RegExp(`.pdf$`))
            }).filter(x => x)
            imgs = imgs.map(x => { return { original: url_environment + x, originalClass: "customReactGalleryClass", thumbnail: url_environment + x, thumbnailClass: "customThumbnailReactGalleryClass" } })
            setImages(imgs)
            //console.log("imgs: ",imgs)
            if (userInfo) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const { data } = await axios.get("/api/user/wishlist", config)
                setWishlist(data)
                const { data: data2 } = await axios.get("/api/product/course_buy?product_id=" + productId, config)
                setCourseBuy(data2.pass)
            }
            setLoading(false)
        } catch (e) {
            alert(e)
        }
    }

    const handleAddToCart = async () => {
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }))
            return;
        }
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/cart", {
                //id: userInfo.id,
                product: values.product
            }, config)
            dispatch(addToCart(values.product))
            setLoading(false)
        } catch (e) { console.log(e) }
    }

    const handleAddToWishList = async (id) => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/wishlist/product", {
                product: values.product,
                wishlist: id
            }, config)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    

    const getShippingOptions = async (obj) => {
        try {
            let resp;
            if (obj.shipper === "ups") {
                let res;
                try {
                    res = await axios.post("https://interpowered.com:3001/calculator", {
                        "mvalue": obj.price.toString(),
                        "addressto": "",
                        "addressfrom": obj.vendor_address,
                        "cityfrom": obj.vendor_city,
                        "dimensionMeasurement": obj.weight_dimension.split("/")[1],
                        "weightMeasurement": obj.weight_dimension.split("/")[0],
                        "zipfrom": obj.vendor_zip,
                        "zipto": obj.client_zip,
                        "countryfrom": obj.vendor_id_country,
                        "countryto": obj.client_id_country,
                        "width": obj.width.toString(),
                        "height": obj.height.toString(),
                        "length": obj.length.toString(),
                        "weight": obj.weight.toString()
                    })
                    res = res.data
                    let resArr = Array.isArray(res.RateResponse.RatedShipment) ? res.RateResponse.RatedShipment.map((i) => { return { code: i.Service.Code, name: getServiceName(i.Service.Code), total: `${i.TotalCharges.CurrencyCode} ${i.TotalCharges.MonetaryValue}` } }) : [{ code: res.RateResponse.RatedShipment.Service.Code, name: getServiceName(res.RateResponse.RatedShipment.Service.Code), total: `${res.RateResponse.RatedShipment.TotalCharges.CurrencyCode} ${res.RateResponse.RatedShipment.TotalCharges.MonetaryValue}` }]
                    resArr = resArr.map((x, c) => {
                        return { code: c.code, key: c, text: `${x.name} ${x.total}`, value: x.total.split("USD ")[1] }
                        // return { key: c, text: `${x.name} ${x.total}`, value: {amount:x.total.split("USD ")[1],code:} }
                    })
                    resp = [...resArr]
                } catch (e) {
                    if (typeof e.response.data === "string") {
                        resp = (e.response.data)
                    } else {
                        resp = (e.response.data[0].message)
                    }
                }
                //console.log(`number: ${i}: `, resArr)
            } else if (obj.shipper === "usps") {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                try {
                    const res = await axios.post("/api/calculator/usps", {
                        "price": obj.price,
                        "zipfrom": obj.vendor_zip,
                        "zipto": obj.client_zip,
                        "width": obj.width,
                        "height": obj.height,
                        "length": obj.length,
                        "pounds": obj.weight,
                        "ounces": "0",
                        "countrytousps": obj.client_country,
                    }, config)
                    let [error, resArr] = parseXML(obj.client_country, res)
                    if (error) {
                        //resp = error
                        return error
                    }
                    resArr = resArr.map((x, c) => {
                        return { code: c.code, key: c, text: `${x.name} ${x.total}`, value: x.total.split("USD ")[1] }
                    })
                    resp = [...resArr]
                } catch (e) { alert(e) }


                //console.log(`number: ${i}: `, res)
            } else if (obj.shipper === "dhl") {
                const exist = obj.dhl.find(x => {
                    return obj.client_id_country == x.country
                })
                if (exist) {
                    //sdebugger
                    if (obj.quantity > 1) {
                        const q = obj.quantity - 1;
                        let total = parseFloat(exist.one_item);
                        total += (parseFloat(exist.additional_item) * q)
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: "DHL", value: total }]//`DHL $${total}`
                    } else {
                        let total = parseFloat(exist.one_item);
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: "DHL", value: total }]//`DHL $${total}`
                    }
                } else {
                    resp = `DHL: ${t("cart.unsupported_country")}`
                }
            } else if (obj.shipper === "notapply") {
                resp = [{ key: "1", text: "", value: "00.00" }]
            }
            return resp


        } catch (e) {
            alert(e)
        }
    }

    const vendor_languages=useMemo(()=>{
        const languages_array=languages
        return values?.languages?.map((language)=>{
            const language_name=languages_array.find(l=>l.key==language)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {t("profile."+language_name.toLowerCase())}
            </Label>
        </div>
        })
    },[values])

    const vendor_locations=useMemo(()=>{
        const locations_array=[{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
        return values?.locations?.map((location)=>{
            const location_name=locations_array.find(l=>l.key==location)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {location_name.trim()}
            </Label>
        </div>
        })
    },[values])

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }

    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1400px;padding-left:36px;padding-right:36px;margin-left:auto;margin-right:auto">
                    <div css="display:flex;flex-wrap:wrap;">
                        <div css="padding-right:20px;flex-shrink:0;flex:0 0 auto;width:65%;@media(max-width:768px){width:100%;}">
                            {images.length > 0 && <ImageGallery renderCustomControls={renderFavoritesIcon} showPlayButton={false} showBullets={false} autoPlay={false} showFullscreenButton={false} thumbnailPosition="left" items={images} />}
                            <Tab css="margin-top:2rem;" panes={panes} defaultActiveIndex={query.get("review") == "true" ? 1 : 0} />
                        </div>
                        <div css="padding-left:20px;flex-shrink:0;flex:0 0 auto;width:35%;@media(max-width:768px){width:100%;margin-top:20px;}">
                            <Link to={`/business/${values.vendor._id}`}><img src={`${url_environment}${values.vendor.business_picture}`} css="margin-bottom:1.5rem;width:150px;height:150px;border-radius:100px;display:block;object-fit:cover;object-position:center;" alt="Profile Image" /></Link>
                            <p><b>{t("product.name")}: <Link style={{ textDecoration: "underline", color: "rgba(0,0,0,.87)" }} to={`/business/${values.vendor._id}`}>{values.vendor.business_name}</Link></b></p>
                            <p><b>{t("product.category")}: </b></p>
                            <p css="white-space:pre-wrap;">{values.product.category_name}</p>
                            <p><b>{t("product.services")}: </b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                {values.vendor.service_offered.split(",").map(x => {
                                    return <div css="padding:0.3rem;">
                                        <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                                            {x.trim()}
                                        </Label>
                                    </div>
                                })}
                            </div>
                            <p><b>{t("product.languages")}:</b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_languages}
                            </div>
                            <p><b>{vendor_locations?.length>1?t("product.locations"):t("product.location")}: </b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_locations}
                            </div>
                            <h3><b>{t("product.product_name")}: {values.product.name}</b></h3>
                            {!courseBuy && <>{values.product.category_id != "60a672fa4efcc56a787c7f22" && <h3><b css={`color:${(values.product.stock < 10 && values.product.stock > 0) ? "#db2828" : "#000"};`}>{((values.product.stock > 9) || ((values.product.category_id == "60b5264c40b9d41dae531f96") ||(values.product.category_id == "60a672fa4efcc56a787c7f1e") || (values.product.category_id == "6182f72014e83501939ba8ca"))) ? t("product.in_stock") : (values.product.stock === 0 ? t("product.out_of_stock") : t("product.only_x_in_stock", { num: values.product.stock }))}</b></h3>}
                                <p><b>{t("product.price")}: USD ${values.product.sale_price}</b></p>
                                {values.product.materials && <><p><b>{t("product.materials")}:</b></p>
                                    <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                        {values.product.materials.split(",").filter(x => x).map(x => {
                                            return <div css="padding:0.3rem;">
                                                <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                                                    {x.trim()}
                                                </Label>
                                            </div>
                                        })}
                                    </div></>}
                                {values.product.publish && <div css="display:flex;flex-direction:column;justify-content:space-between;max-width:100%;margin-top:1rem;margin-bottom:1rem;margin:25px auto;">
                                    {
                                        checkShipping(values.product) ? <>
                                            {((values.product.stock > 0) || ((values.product.category_id == "60b5264c40b9d41dae531f96") ||(values.product.category_id == "60a672fa4efcc56a787c7f1e") || (values.product.category_id == "6182f72014e83501939ba8ca"))) && <Button css="background: rgb(0,128,129) !important;margin-bottom:10px !important;" onClick={async () => { if (!userInfo) { dispatch(uiActions.change_action_sign_in({ open: true, action: "login" })); return; } await handleAddToCart(); history.push("/cart") }} circular color="teal">{t("product.buy_it_now")}</Button>}
                                            {((values.product.stock < 1) && (values.product.category_id != "60b5264c40b9d41dae531f96") &&(values.product.category_id != "60a672fa4efcc56a787c7f1e") && (values.product.category_id != "6182f72014e83501939ba8ca")) && <p css="margin-bottom:10px !important;font-weight:700;text-decoration:underline;">{t("product.if_you_are_interested_para")}</p>}
                                            <Button disabled={values.product.stock < 1} css="background: rgb(2,183,178) !important;margin-bottom:10px !important;" circular color="teal" onClick={handleAddToCart}>{values.product.stock < 1 ? t("product.out_of_stock") : t("product.add_to_cart")}</Button>
                                        </>
                                            : <>
                                                <div css="display:flex;align-items:center;justify-content:center;height:300px;">
                                                    <h3>{t("product.this_product_not_shipping")}</h3>
                                                </div>
                                            </>
                                    }
                                    {userInfo && wishlist && wishlist.length > 0 && <Popup
                                        trigger={<Button style={{ border: "2px solid rgb(0,128,129)", marginBottom: "10px" }} basic circular color="teal" ><span style={{ color: "#008081", fontWeight: "bold" }}>{t("product.add_to_wishlist")}</span></Button>}
                                        hoverable
                                        content={<>
                                            {wishlist.map(list => {
                                                return <Button onClick={() => handleAddToWishList(list._id)} fluid style={{ marginBottom: "5px" }}>{list.name}</Button>
                                            })}
                                        </>}
                                        position='bottom center'
                                    />}
                                    {/* <Button css="border:2px solid rgb(0,128,129) !important;background:transparent !important;color:rgb(0,128,129) !important;" circular color="teal">{t("product.make_an_offer")}</Button> */}
                                </div>}
                            </>}
                            {courseBuy && <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
                                    <div>
                                        <p style={{ textAlign: "center", fontWeight:"bold" }}>{t("product.already_bought_course")}:</p>
                                        <p style={{ textAlign: "center" }}><Button circular color="teal" onClick={() => { history.push(`/course?id=${productId}`) }}>{t("product.got_to_course")}</Button></p>
                                    </div>
                                </div>
                            </>}
                            <p css="text-align:center;"><b>{t("product.have_a_question")}</b></p>
                            <Button onClick={handleMessageSeller} css="margin:0 auto !important;display:block !important;" circular color="teal">{t("product.message_seller")}</Button>
                            <p><b>{t("product.shipping")}</b></p>
                            <div>
                                <p>{t("product.shipping_carrier")}: {((sh) => { if (sh == "dhl") { return "DHL" }if (sh == "other") { return "Other" } if (sh == "ups") { return "UPS" } if (sh == "usps") { return "USPS" } if (sh == "notapply") { return "Does not apply" } })(values.product.shipper)}</p>
                                {/* <div>
                                    {userInfo &&(async()=>{
                                        const o = {
                                            width: values.product.width,
                                            height: values.product.height,
                                            length: values.product.length,
                                            weight: values.product.weight,
                                            price: values.product.sale_price,
                                            shipper: values.product.shipper,
                                            weight_dimension: values.product.weight_dimension,
                                            quantity: 1,
                                            dhl: values.product.dhl,
                                            vendor_country: values.product.vendor_id_2.country,
                                            vendor_id_country: values.product.vendor_id_2.id_country,
                                            vendor_address: values.product.vendor_id_2.address,
                                            vendor_city: values.product.vendor_id_2.city,
                                            vendor_zip: values.product.vendor_id_2.zip,
                                            client_country: userInfo.country,
                                            client_id_country: userInfo.id_country,
                                            client_zip: userInfo.zip,
                                        }
                                        const options= await getShippingOptions(o)
                                        return <ul>
                                            {Array.isArray(options) ? options.map(opt=><li><b>{opt.text} USD ${opt.value}</b></li>): options}
                                        </ul>
                                    })()}
                                </div> */}
                            </div>
                            <div>
                                <p><b></b></p>
                                <p><b></b></p>
                                <p><b></b></p>
                            </div>

                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )

}

export default ServicePage

