import { Link } from 'react-router-dom'
import 'styled-components/macro'

const TermsAndConditionsEnglish = () => {
    return <>
        <div css="font-size:16px;">
            <h1 css="font-weight:bold;text-align:center;">
                <span className="c31">TERMS AND CONDITIONS FOR INTERPOWERED.COM</span>
            </h1>
            <p className="c20">
                <span className="c4">Last Updated: &nbsp;November 17, 2021</span>
            </p>
            <a id="id.30j0zll">

            </a>
            <ol css="font-weight:bold;" start="1">
                <li className="c0">
                    <span className="c2">THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS
                    WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU.&nbsp;PLEASE READ IT
                CAREFULLY.&nbsp;</span>
                    <a id="id.1fob9te">

                    </a>
                </li>
            </ol>
            <p css="font-weight:bold;">
                <span className="c2">THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER
            THAN JURY TRIALS OR CLASS ACTIONS.&nbsp;</span>
                <a id="id.3znysh7">

                </a>
            </p>
            <p css="font-weight:bold;">
                <span className="c2">BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THIS WEBSITE, YOU AFFIRM THAT YOU ARE OF
                LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOU
                AFFIRM THAT IF YOU PLACE AN ORDER ON BEHALF OF AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO
            BIND ANY SUCH ORGANIZATION OR COMPANY TO THESE TERMS AND CONDITIONS. </span>
                <a id="id.2et92p0">

                </a>
            </p>
            <p css="font-weight:bold;">
                <span className="c2">YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM THIS WEBSITE IF YOU (A) DO NOT AGREE TO
                THESE TERMS, (B) ARE NOT THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING
                CONTRACT WITH INTERPOWERED, LLC, OR (C) ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE OR ANY OF THIS
            WEBSITE'S CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.</span>
            </p>
            <a id="id.tyjcwt">

            </a>
            <p className="c29">
                <span className="c3">These terms and conditions (these "</span>
                <span css="font-weight:bold;">Terms</span>
                <span className="c3">") apply to the purchase and sale of products and services offered by third-party Sellers
            (referred to as “</span>
                <span css="font-weight:bold;">Seller</span>
                <span className="c3">”, or “</span>
                <span css="font-weight:bold;">Sellers”</span>
                <span className="c3">) to third-party &nbsp;purchasers (“</span>
                <span css="font-weight:bold;">Buyer</span>
                <span className="c3">”, or “</span>
                <span css="font-weight:bold;">Buyers</span>
                <span className="c3">”) through the online marketplace Interpowered.com (the "</span>
                <span css="font-weight:bold;">Site</span>
                <span className="c3">"). These Terms are subject to change by Interpowered, LLC (referred to as "</span>
                <span css="font-weight:bold;">us</span>
                <span className="c3">", "</span>
                <span css="font-weight:bold;">we</span>
                <span className="c3">", or "</span>
                <span css="font-weight:bold;">our</span>
                <span className="c3">" as the context may require) without prior written notice at any time, in our sole discretion.
                Any changes to the Terms will be in effect as of the "Last Updated Date" referenced on the Site. You should
                review these Terms prior to purchasing any product or services that are available through this Site. Your
                continued use of this Site after the "Last Updated Date" will constitute your acceptance of and agreement to
            such changes.</span>
            </p>
            <a id="id.3dy6vkm">

            </a>
            <p className="c29">
                <span className="c3">These Terms are an integral part of the Website Terms of Use that apply generally to the use of
            our Site. You should also carefully review our </span>
                <span className="c13">
                    <Link css="font-style:italic;"
                        to="/privacy-policy">Privacy
                Policy</Link>
                </span>
                <span className="c3">&nbsp;before placing an order for products or services through this Site (see 9).</span>
            </p>
            <a id="id.1t3h5sf">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="2">
                <li className="c0">
                    <span css="text-decoration:underline;">Order Acceptance and Cancellation</span>
                    <span className="c3">. You agree that your order is an offer to buy, under these Terms, all products and
                    services listed in your order. All orders must be accepted by us or we will not be obligated to approve
                    the sale of products or services from third party Sellers to you. We may choose not to accept orders at
                    our sole discretion, even after we send you a confirmation email with your order number and details of
                the items you have ordered. Buyers may </span>
                    <span css="text-decoration:underline;">NOT</span>
                    <span className="c3">&nbsp;cancel orders once the order is received by us.</span>
                </li>
            </ol>
            <a id="id.4d34og8">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="3">
                <li css="text-decoration:underline;">
                    <span className="c7 c9">Payment Terms</span>
                    <span className="c3">. </span>
                </li>
            </ol>
            <a id="id.2s8eyo1">

            </a>
            <ol css="list-style:none;" start="1">
                <li className="c1">
                    <span className="c3">&nbsp;(a) All prices, discounts, and promotions posted on this Site are subject to change
                    without notice. The price charged for a product or service will be the price in effect at the time the
                    order is placed and will be set out in your order confirmation email. Price increases will only apply to
                    orders placed after such changes. Posted prices do not include taxes or charges for shipping and
                    handling. All such taxes and charges will be added to your merchandise total, and will be itemized in
                    your shopping cart and in your order confirmation email. Sellers strive to display accurate price
                    information, however Sellers may, on occasion, make inadvertent typographical errors, inaccuracies or
                    omissions related to pricing and availability. We reserve the right to correct any errors, inaccuracies,
                or omissions at any time and to cancel any orders arising from such occurrences.</span>
                </li>
            </ol>
            <a id="id.17dp8vu">

            </a>
            <ol css="list-style:none;" start="2">
                <li className="c1">
                    <span className="c3">(b) We may offer from time to time promotions on the Site that may affect pricing and that are
                    governed by terms and conditions separate from these Terms. If there is a conflict between the terms for
                a promotion and these Terms, the promotion terms will govern. </span>
                </li>
            </ol>
            <a id="id.3rdcrjn">

            </a>
            <ol css="list-style:none;" start="3">
                <li className="c1">
                    <span className="c3">(c) Terms of payment are within our sole discretion and, unless otherwise agreed by us in
                    writing, payment must be received by us before our acceptance of an order. The currently accepted
                    payment methods for all purchases are those identified on the website. You represent and warrant that
                    (i) the credit card information you supply to us is true, correct and complete, (ii) you are duly
                    authorized to use such credit card for the purchase, (iii) charges incurred by you will be honored by
                    your credit card company, and (iv) you will pay charges incurred by you at the posted prices in U.S.
                    Dollars, including shipping and handling charges and all applicable taxes, if any, regardless of the
                amount quoted on the Site at the time of your order. </span>
                </li>
                <li >
                    <span className="c3">(d) Sellers are responsible for collecting and paying any taxes associated with using and
                    making sales through the Site’s services. If you sell on the Site and reside outside the US or Canada,
                    you need to include applicable taxes in your listing prices. Remember that the Site’s fees do not
                    include any withholding taxes that might apply in your home country. Sellers are required to pay the
                    Company the full amount of our fees and may not deduct any applicable withholding taxes from that
                amount. </span>
                </li>
            </ol>
            <a id="id.26in1rg">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="4">
                <li className="c0">
                    <span css="text-decoration:underline;">Shipments; Delivery; Title and Risk of Loss</span>
                    <span className="c3">. </span>
                </li>
            </ol>
            <a id="id.lnxbz9">

            </a>
            <ol css="list-style:none;" start="5">
                <li className="c1">
                    <span className="c3">(a) The Seller will arrange for shipment to you. Please check the individual product page for
                    specific delivery options. You will pay all shipping and handling charges specified during the ordering
                    process. Shipping and handling charges are reimbursement for the costs the Seller incurs in the
                processing, handling, packing, shipping, and delivery of your order. </span>
                </li>
            </ol>
            <a id="id.35nkun2">

            </a>
            <ol css="list-style:none;" start="6">
                <li className="c1">
                    <span className="c3">(b) Title and risk of loss pass to you upon our transfer of the products to the
                    carrier/delivery. Shipping and delivery dates are estimates only and cannot be guaranteed. We are not
                liable for any delays in shipments.</span>
                </li>
            </ol>
            <a id="id.1ksv4uv">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="5">
                <li className="c0">
                    <span css="text-decoration:underline;">Returns and Refunds</span>
                    <span className="c3">. We provide a marketplace to connect Sellers and Buyers. We do not sell goods on our Site.
                Sellers, not us, sell goods and/or services directly to Buyers on the Site, therefore, we do </span>
                    <span className="c7">NOT</span>
                    <span className="c3">&nbsp;issue a refund for any purchase or accept exchanges for any purchase made on the
                    Site. All sales are final and any claim from a Buyer, who is not satisfied with the purchased product,
                may contact the Seller of the product directly. </span>
                </li>
            </ol>
            <a id="id.44sinio">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="6">
                <li className="c27">
                    <span css="text-decoration:underline;">Manufacturer's Warranty and Disclaimers</span>
                    <span className="c3">. We do not manufacture or control any of the products or services offered on our Site. The
                    availability of products or services through our Site does not indicate an affiliation with or
                    endorsement of any product, service or manufacturer. Accordingly, we do not provide any warranties with
                respect to the products or services offered on our Site. </span>
                    <a id="id.2jxsxqh">

                    </a>
                    <span className="c3">All products and services offered on this Site are provided “as is” without any warranty
                    whatsoever, including, without limitation, any (a) warranty of merchantability; (b) warranty of fitness
                    for a particular purpose; or (c) warranty against infringement of intellectual property rights of a
                    third party; whether express or implied by law, course of dealing, course of performance, usage of
                trade, or otherwise. </span>
                    <a id="id.z337ya">

                    </a>
                </li>
            </ol>
            <p className="c11">
                <span className="c3">Some jurisdictions limit or do not allow the disclaimer of implied or other warranties so the
            above disclaimer may not apply to you. </span>
                <a id="id.3j2qqm3">

                </a>
            </p>
            <p className="c11">
                <span className="c3">You affirm that we shall not be liable, under any circumstances, for any breach of warranty
                claims or for any damages arising out of the manufacturer's failure to honor its warranty obligations to
            you.</span>
            </p>
            <a id="id.1y810tw">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="7">
                <li className="c0">
                    <span css="text-decoration:underline;">Limitation of Liability</span>
                    <span className="c3">. In no event shall we be liable to you or any third party for consequential, indirect,
                    incidental, special, exemplary, punitive or enhanced damages, lost profits or revenues or diminution in
                    value, arising out of, or relating to, and/or in connection with any breach of these terms, regardless
                    of (a) whether such damages were foreseeable, (b) whether or not we were advised of the possibility of
                    such damages and (c) the legal or equitable theory (contract, tort or otherwise) upon which the claim is
                based.</span>
                    <a id="id.4i7ojhp">

                    </a>
                </li>
            </ol>
            <p className="c11">
                <span className="c3">Our sole and entire maximum liability , for any reason, and your sole and exclusive remedy for
                any cause whatsoever, shall be limited to the actual amount paid by you for the products and services you
            have ordered through our Site. </span>
                <a id="id.2xcytpi">

                </a>
            </p>
            <p className="c11">
                <span className="c3">The limitation of liability set forth above shall : (i) only apply to the extent permitted by
                law and (ii) not apply to (A) liability resulting from our gross negligence or willful misconduct and (B)
            death or bodily injury resulting from our acts or omissions.</span>
            </p>
            <a id="id.1ci93xb">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="8">
                <li className="c0">
                    <span css="text-decoration:underline;">Goods Not for Resale or Export</span>
                    <span className="c3">. You represent and warrant that you are buying products or services from the Site for your
                    own personal or household use only, and not for resale or export. You further represent and warrant that
                    all purchases are Office of Foreign Assets Control (“OFAC”) of the United States Department of Treasury
                    compliant. We and anyone using this Site or services cannot take part in transactions that involve
                    designated people, places, or items that originate from those places, as determined by agencies like
                    OFAC. It is the responsibility of the Seller and Buyer to familiarize themselves with these
                restrictions. We may cancel transactions that present a risk of violating this policy.</span>
                </li>
            </ol>
            <a id="id.3whwml4">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="9">
                <li className="c0">
                    <span css="text-decoration:underline;">Privacy</span>
                    <span className="c3">. We respect your privacy and are committed to protecting it. Our </span>
                    <span className="c13">
                        <a className="c15"
                            href="https://www.google.com/url?q=https://interpowered.com/privacypolicy&amp;sa=D&amp;source=editors&amp;ust=1638456891711000&amp;usg=AOvVaw1lI1tkgMu5CPfcUnAXs5io">Privacy
                    Policy</a>
                    </span>
                    <span className="c3">, governs the processing of all personal data collected from you in connection with your
                purchase of products or services through the Site.</span>
                </li>
                <li className="c0">
                    <span css="text-decoration:underline;">Termination. </span>
                    <span className="c3">We may cancel or terminate an account if we have reason to believe that the user has
                    infringed on the intellectual property rights of another, has used our Site to break the law of any
                state, or nation, or otherwise failed to comply with these Terms.</span>
                </li>
            </ol>
            <ol css="list-style:none;" start="1">
                <li className="c1">
                    <span className="c3">(a) You may terminate your account at any time. You are required to pay any outstanding bills
                upon termination. </span>
                </li>
                <li className="c1">
                    <span className="c3">(b) We reserve the right to change, suspend, or discontinue any of the Services for you, any or
                    all users, at any time, for any reason. We will not be liable to you for the effect that any changes to
                    the Site may have on you, including your income or your ability to generate revenue through the
                Site.</span>
                </li>
            </ol>
            <a id="id.2bn6wsx">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="11">
                <li className="c0">
                    <span css="text-decoration:underline;">Force Majeure</span>
                    <span className="c3">. We will not be liable or responsible to you, nor be deemed to have defaulted or breached
                    these Terms, for any failure or delay in our performance under these Terms when and to the extent such
                    failure or delay is caused by or results from acts or circumstances beyond our reasonable control,
                    including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions,
                    war, invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or other
                    civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor
                    disputes (whether or not relating to our workforce), or restraints or delays affecting carriers or
                    inability or delay in obtaining supplies of adequate or suitable materials, materials or
                telecommunication breakdown or power outage.</span>
                </li>
            </ol>
            <a id="id.qsh70q">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="12">
                <li className="c0">
                    <span css="text-decoration:underline;">Governing Law and Jurisdiction</span>
                    <span className="c3">. This Site is operated from the US. All matters arising out of or relating to these Terms
                    are governed by and construed in accordance with the internal laws of the State of Florida without
                    giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any
                    other jurisdiction) that would cause the application of the laws of any jurisdiction other than those of
                the State of Florida. </span>
                </li>
            </ol>
            <a id="id.3as4poj">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="13">
                <li className="c0">
                    <span css="text-decoration:underline;">Dispute Resolution and Binding Arbitration</span>
                    <span className="c3">.</span>
                </li>
            </ol>
            <a id="id.1pxezwc">

            </a>
            <ol css="list-style:none;" start="3">
                <li className="c1">
                    <span className="c3">(a) You are agreeing to give up any rights to litigate claims in a court or before a jury.
                    Other rights that you would have if you went to court may also be unavailable or may be limited in
                arbitration.</span>
                </li>
            </ol>
            <a id="id.49x2ik5">

            </a>
            <p className="c16">
                <span className="c3">Any claim, dispute or controversy (whether in contract, tort or otherwise, whether
                pre-existing, present or future, and including statutory, consumer protection, common law, intentional tort,
                injunctive and equitable claims) between you and us arising from or relating in any way to your purchase of
                products or services through the Site, will be resolved exclusively and finally by binding
            arbitration.</span>
            </p>
            <a id="id.2p2csry">

            </a>
            <ol css="list-style:none;" start="4">
                <li className="c1">
                    <span className="c3">(b) The arbitration will be administered by the American Arbitration Association ("</span>
                    <span css="font-weight:bold;">AAA</span>
                    <span className="c3">") in accordance with the Consumer Arbitration Rules (the "</span>
                    <span css="font-weight:bold;">AAA Rules</span>
                    <span className="c3">") then in effect, except as modified by this 13. (The AAA Rules are available at
                    www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern
                the interpretation and enforcement of this section.</span>
                </li>
            </ol>
            <a id="id.147n2zr">

            </a>
            <p className="c16">
                <span className="c3">The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability
                and/or enforceability of this arbitration provision, including any unconscionability challenge or any other
                challenge that the arbitration provision or the agreement is void, voidable, or otherwise invalid. The
                arbitrator will be empowered to grant whatever relief would be available in court under law or in equity.
                Any award of the arbitrator(s) will be final and binding on each of the parties, and may be entered as a
            judgment in any court of competent jurisdiction.</span>
            </p>
            <a id="id.3o7alnk">

            </a>
            <ol css="list-style:none;" start="5">
                <li className="c1">
                    <span className="c3">(c) You agree to an arbitration on an individual basis. in any dispute, neither you nor we will
                    be entitled to join or consolidate claims by or against other customers in court or in arbitration or
                    otherwise participate in any claim as a class representative, class member or in a private attorney
                    general capacity. the arbitral tribunal may not consolidate more than one person's claims, and may not
                    otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no
                    power to consider the enforceability of this class arbitration waiver and any challenge to the class
                arbitration waiver may only be raised in a court of competent jurisdiction.</span>
                </li>
            </ol>
            <a id="id.23ckvvd">

            </a>
            <ol css="list-style:none;" start="6">
                <li className="c1">
                    <span className="c3">(d) If any provision of this arbitration agreement is found unenforceable, the unenforceable
                provision will be severed and the remaining arbitration terms will be enforced.</span>
                </li>
            </ol>
            <a id="id.ihv636">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="14">
                <li className="c0">
                    <span css="text-decoration:underline;">Indemnification</span>
                    <span className="c3">.</span>
                    <a id="id.32hioqz">

                    </a>
                    <span className="c3">&nbsp;You agree to defend, indemnify, and hold harmless us and our affiliates, and their
                    officers, directors, employees, agents, successors, and assigns against all losses, damages,
                    liabilities, deficiencies, actions, judgements, interest, awards, penalties, fines, costs, or expenses
                of whatever kind (including reasonable attorneys’ fees) arising out of or resulting from:</span>
                </li>
            </ol>
            <ol css="list-style:none;" start="1">
                <li className="c1">
                    <span className="c3">(a) Bodily injury, death of any person, or damage to real or tangible personal property
                resulting from your acts or omissions; or</span>
                </li>
                <li className="c1">
                    <span className="c3">(b) Your misrepresentation or breach of any warranty or obligation under this Agreement.</span>
                </li>
            </ol>
            <a id="id.1hmsyys">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="15">
                <li className="c0">
                    <span css="text-decoration:underline;">Assignment</span>
                    <span className="c3">. You will not assign any of your rights or delegate any of your obligations under these
                    Terms without our prior written consent. Any purported assignment or delegation in violation of this
                    Section is null and void. No assignment or delegation relieves you of any of your obligations under
                these Terms.</span>
                </li>
            </ol>
            <a id="id.41mghml">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="16">
                <li className="c0">
                    <span css="text-decoration:underline;">No Waivers</span>
                    <span className="c3">. The failure by us to enforce any right or provision of these Terms will not constitute a
                    waiver of future enforcement of that right or provision. The waiver of any right or provision will be
                effective only if in writing and signed by a duly authorized representative of us.</span>
                </li>
                <li className="c5">
                    <span css="text-decoration:underline;">Intellectual Property.</span>
                    <span className="c3">&nbsp;We provide a venue where Sellers may create their own shops, and are responsible for
                    inventory, purchases, and complying with the law. Content uploaded on the Site is generated by Sellers
                    who are not agents, employees, or representatives of us. Sellers are responsible for ensuring they have
                    all necessary rights to their content and that they are not infringing or violating any third party’s
                    rights by posting it. We may disable and/or delete any content uploaded to the Site that is believed by
                    us, or by a third party providing notice to us, to be violating Intellectual Property rights. We do not
                    purport to offer any legal advice or make any legal determinations as to whether content is infringing
                someone else’s intellectual property rights. </span>
                </li>
                <li className="c5">
                    <span css="text-decoration:underline;">Copyright Policy</span>
                    <span className="c3">. We take claims of copyright infringement seriously. We will respond to notices of alleged
                    copyright infringement that comply with applicable law. If you believe any materials accessible on or
                    from this Site infringe your copyright, you may request remove of those materials (or access to them)
                from the Site by submitting a request to </span>
                    <span className="c3 c14">
                        <a css="background-color:#c0c0c0;" href="mailto:Legal@interpowered.com">Legal@interpowered.com</a>
                    </span>
                    <span className="c3">. </span>
                </li>
            </ol>
            <ol css="list-style:none;" start="1">
                <li className="c1">
                    <span css="text-decoration:underline;">(a) Reporting Claims of Copyright Infringement. </span>
                    <span className="c3">In accordance with the Online Copyright Infringement Liability Limitation Act of the
                    Digital Millennium Copyright Act (17 U.S.C. § 512 (“DMCA”), the written notice (the “DMCA Notice”) must
                include substantially the following: </span>
                </li>
            </ol>
            <ul css="padding-left:4rem;">
                <li className="c12 li-bullet-0">
                    <span className="c3">Your physical or electronic signature.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">Identification of the copyrighted work you believe to have been infringed or, if the claim
                involves multiple works on the Website, a representative list of such works.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">Identification of the material you believe to be infringing in a sufficiently precise
                manner to allow us to locate that material.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">Adequate information by which we can contact you (including your name, postal address,
                telephone number, and, if available, email address).</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">A statement that you have a good faith belief that use of the copyrighted material is not
                authorized by the copyright owner, its agent, or the law.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">A statement that the information in the written notice is accurate.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">A statement, under penalty of perjury, that you are authorized to act on behalf of the
                copyright owner.</span>
                </li>
                <li className="c12 li-bullet-0">
                    <span className="c3">Our designated copyright agent to receive DMCA Notices is:</span>
                </li>
            </ul>
            <a id="t.2b60fbd92a117a5753b993bfed59c9c2530f7799">

            </a>
            <a id="t.0">

            </a>
            <table css="padding-left:3rem;">
                <tbody>
                    <tr className="c24">
                        <td className="c28" colspan="1" rowspan="1">
                            <p className="c20">
                                <span className="c19">Dana Terry<br />Interpowered, LLC.<br />3122 MAHAN DR. SUITE 801-265 TALLAHASSEE,
                            FLORIDA, 32308<br />
                                </span>
                                <span css="background-color:#c0c0c0;">Legal@interpowered.com</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="c20 c32">
                <span className="c22 c19">If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your
            DMCA Notice may not be effective.</span>
            </p>
            <p className="c20 c32">
                <span className="c19">Please be aware that if you knowingly materially misrepresent that material or activity on the
                Website is infringing your copyright, you may be held liable for damages (including costs and attorneys'
            fees) under Section 512(f) of the DMCA.</span>
            </p>
            <ol css="list-style:none;" start="2">
                <li className="c1">
                    <span css="text-decoration:underline;">(b) Counter Notification Procedures.</span>
                    <span className="c3">&nbsp;If you believe that material you posted on the Website was removed or access to it
                was disabled by mistake or misidentification, you may file a counter notification with us (a "</span>
                    <span css="font-weight:bold;">Counter Notice</span>
                    <span className="c3">") by submitting written notification to our copyright agent designated [above/below].
                Pursuant to the DMCA, the Counter Notice must include substantially the following:</span>
                </li>
            </ol>
            <ul css="padding-left:4rem;">
                <li className="c10 li-bullet-0">
                    <span className="c3">Your physical or electronic signature.</span>
                </li>
                <li className="c10 li-bullet-0">
                    <span className="c3">An identification of the material that has been removed or to which access has been
                    disabled and the location at which the material appeared before it was removed or access disabled.
            </span>
                </li>
                <li className="c10 li-bullet-0">
                    <span className="c3">Adequate information by which we can contact you (including your name, postal address,
                telephone number, and, if available, email address).</span>
                </li>
                <li className="c10 li-bullet-0">
                    <span className="c3">A statement under penalty of perjury by you that you have a good faith belief that the
                    material identified above was removed or disabled as a result of a mistake or misidentification of the
                material to be removed or disabled.</span>
                </li>
                <li className="c10 li-bullet-0">
                    <span className="c3">A statement that you will consent to the jurisdiction of the Federal District Court for the
                    judicial district in which your address is located (or if you reside outside the United States for any
                    judicial district in which the Website may be found) and that you will accept service from the person
                (or an agent of that person) who provided the Website with the complaint at issue.</span>
                </li>
            </ul>
            <a id="t.d6408adc8e3b183e8402f4e25b52d94054e92dac">

            </a>
            <a id="t.1">

            </a>
            <table css="padding-left:3rem;">
                <tbody>
                    <tr className="c24">
                        <td className="c23" colspan="1" rowspan="1">
                            <p className="c20">
                                <span className="c19">Dana Terry<br />Interpowered, LLC. <br />
                                </span>
                                <span css="background-color:#c0c0c0;">Interpowered, LLC &nbsp;</span>
                                <span className="c19">+1 (850)938-2788<br />3122 MAHAN DR. SUITE 801-265 TALLAHASSEE, FLORIDA,
                            32308<br />
                                </span>
                                <span css="background-color:#c0c0c0;">Legal@interpowered.com</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p css="padding-left:3rem;">
                <span className="c3">The DMCA allows us to restore the removed content if the party filing the original DMCA Notice
                does not file a court action against you within ten business days of receiving the copy of your Counter
            Notice.</span>
            </p>
            <p css="padding-left:3rem;">
                <span className="c3">Please be aware that if you knowingly materially misrepresent that material or activity on the
                Website was removed or disabled by mistake or misidentification, you may be held liable for damages
            (including costs and attorneys' fees) under Section 512(f) of the DMCA.]</span>
            </p>
            <a id="id.2grqrue">

            </a>
            <ol css="list-style:none;" start="3">
                <li className="c1">
                    <span css="text-decoration:underline;">(c) Repeat Infringers.</span>
                    <span className="c3">&nbsp;It is our policy in appropriate circumstances to disable and/or terminate the
                accounts of users who are repeat infringers.</span>
                </li>
            </ol>
            <a id="id.vx1227">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="19">
                <li className="c5">
                    <span css="text-decoration:underline;">No Third-Party Beneficiaries</span>
                    <span className="c3">. These Terms do not and are not intended to confer any rights or remedies upon any person
                other than you.</span>
                </li>
            </ol>
            <a id="id.3fwokq0">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="20">
                <li className="c5">
                    <span css="text-decoration:underline;">Notices</span>
                    <span className="c3">.</span>
                </li>
            </ol>
            <a id="id.1v1yuxt">

            </a>
            <ol css="list-style:none;" start="4">
                <li className="c1">
                    <span css="text-decoration:underline;">(a) To You</span>
                    <span className="c3">. We may provide any notice to you under these Terms by: (i) sending a message to the email
                    address you provide or (ii) by posting to the Site. Notices sent by email will be effective when we send
                    the email and notices we provide by posting will be effective upon posting. It is your responsibility to
                keep your email address current.</span>
                </li>
            </ol>
            <a id="id.4f1mdlm">

            </a>
            <ol css="list-style:none;" start="5">
                <li className="c1">
                    <span css="text-decoration:underline;">(b) To Us</span>
                    <span className="c3">. To give us notice under these Terms, you must contact us as follows: (i) by personal
                    delivery, overnight courier, or registered or certified mail to INTERPOWERED, LLC, 3122 MAHAN DR. SUITE
                    801-265 TALLAHASSEE, FLORIDA, 32308. We may update the address for notices to us by posting a notice on
                    the Site. Notices provided by personal delivery will be effective immediately. Notices provided by
                    overnight courier will be effective one business day after they are sent. Notices provided by registered
                    or certified mail will be effective three business days after they are sent; (ii) by email sent to
            </span>
                    <span className="c3 c14">
                        <a className="c15" href="mailto:info@interpowered.com"> info@interpowered.com</a>
                    </span>
                    <span className="c3">. </span>
                </li>
            </ol>
            <a id="id.2u6wntf">

            </a>
            <ol className="c8 lst-kix_list_1-0" start="21">
                <li className="c0">
                    <span css="text-decoration:underline;">Severability</span>
                    <span className="c3">. If any provision of these Terms is invalid, illegal, void or unenforceable, then that
                    provision will be deemed severed from these Terms and will not affect the validity or enforceability of
                the remaining provisions of these Terms</span>
                    <a id="id.19c6y18">

                    </a>
                    <span className="c3">.</span>
                </li>
                <li className="c0">
                    <span css="text-decoration:underline;">Entire Agreement</span>
                    <span className="c3">. These Terms, the license agreement relating to any product or service you obtain on or
                through this Site, our Website </span>
                    <span className="c3">
                    <Link css="font-style:italic;"
                            to="/terms-of-service">Terms
                    of Use</Link>
                    </span>
                    <span className="c3">&nbsp;and our </span>
                    <span className="c13">
                        <Link css="font-style:italic;"
                            to="/privacy-policy">Privacy
                    Policy</Link>
                    </span>
                    <span className="c3">&nbsp;will be deemed the final and integrated agreement between you and us on the matters
                contained in these Terms.</span>
                    <span className="c7">&nbsp;</span>
                </li>
            </ol>
        </div>
    </>
}
export default TermsAndConditionsEnglish