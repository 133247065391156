import React, { useRef } from 'react'
import { Button, Modal, Table } from 'semantic-ui-react'
import axios from '../../axios'

const OrderDetails = (props) => {
    const order = props.data
    const order_date = (new Date(order.order_date)).toLocaleString()
    const br = useRef()
    if (Object.keys(order).length == 0) {
        return <></>
    }

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={() => props.setClose()}
                open={props.open}
                size="large"
            >
                <Modal.Header>Order {order.order_id}</Modal.Header>
                <Modal.Content>
                    <div><b>Paypal order ID:</b> {order.order_id}</div>
                    <div><b>User ID:</b> {order.id_user._id}</div>
                    <div><b>User name:</b> {order.id_user.name}</div>
                    <div><b>Paypal email:</b> {order.payer_email}</div>
                    <div><b>Paypal payer name:</b> {order.payer_name}</div>
                    <div><b>Total:</b>USD ${order.order_amount}</div>
                    <div><b>Date:</b> {order_date}</div>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product name</Table.HeaderCell>
                                <Table.HeaderCell>Vendor name</Table.HeaderCell>
                                <Table.HeaderCell>Price</Table.HeaderCell>
                                <Table.HeaderCell>Taxes</Table.HeaderCell>
                                <Table.HeaderCell>Shipping</Table.HeaderCell>
                                <Table.HeaderCell>Rate</Table.HeaderCell>
                                <Table.HeaderCell>Vendor's commission</Table.HeaderCell>
                                <Table.HeaderCell>Interpowered's commission</Table.HeaderCell>
                                <Table.HeaderCell>Discount</Table.HeaderCell>
                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {order.order_items.map((x, c) => {
                                const f=parseFloat
                                let vendor_commission = (f(x.current_price) - f(x.discount || 0))
                                vendor_commission *= f(x.quantity)
                                vendor_commission -= (vendor_commission * (f(x.category_rate) / 100))
                                vendor_commission -= (1.4 * f(x.quantity))
                                let rest=(f(x.shipping) * f(x.quantity)) + (f(x.total_tax) || 0)
                                let interpowered_commission=x.total_price-vendor_commission-rest
                                return (
                                    <Table.Row key={x._id}>
                                        <Table.Cell>{x.product_name}</Table.Cell>
                                        <Table.Cell>{x.vendor_name}</Table.Cell>
                                        <Table.Cell>USD ${f(x.current_price).toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${f(x.total_tax).toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${x.shipping}</Table.Cell>

                                        <Table.Cell>{x.category_rate}%</Table.Cell>
                                        <Table.Cell>USD ${vendor_commission.toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${interpowered_commission.toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${x.discount}</Table.Cell>

                                        <Table.Cell>{x.quantity}</Table.Cell>
                                        <Table.Cell>USD ${f(x.total_price).toFixed(2)}</Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                    <div><span></span></div>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default OrderDetails