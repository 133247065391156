import { React, useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from '../axios'

const TokenValidation = () => {
    const [t, i18n] = useTranslation("global")
    const [Email, setEmail] = useState("")
    const [Wrong, setWrong] = useState(false)
    const [message, setMessage] = useState(false)
    const [Password, setPassword] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [pass, setpass] = useState(false)
    let { token } = useParams();

    const handleSubmit = () => {
        axios.post("/api/user/validateToken", {
            token,
            password:Password
        }).then((res) => {
            if ("error" in res.data) {
               // setWrong(res.data.error)
            } else {
                //setWrong(false)
                setPassword("")
                setpass(true)
                setMessage("Your password has been reset")
            }
        }).catch(() => {
            //setWrong("Server Error")
        })
    }
    return (
        <>
            <Grid style={{ height: '100vh', background: "#f9f9f9" }} centered verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Segment raised padded style={{ borderRadius: "20px" }}>
                        <Header style={{ textAlign: "center" }}>
                            Enter your new password
                        </Header>
                        <Form onSubmit={handleSubmit} loading={Loading}>
                            <Form.Input onChange={(e) => { setPassword(e.target.value) }} required fluid label={t("login.password")} type="password" />
                            <Form.Button style={{ borderRadius: "200px", background: "rgb(0,128,129)" }} fluid type="submit" secondary content={t("forgot.send")} />
                            {
                                Wrong && (
                                    <Message color="red" visible>
                                        {Wrong}
                                    </Message>
                                )
                            }
                            {
                                message && (
                                    <Message visible>
                                        <span style={{marginRight:"5px"}}>{message}</span>
                                        <Link to="/">Go to login page</Link>
                                    </Message>
                                )
                            }
                                  
                                
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    )
}

export default TokenValidation