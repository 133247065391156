export default [{ key: "AF", text: "Afghanistan", value: "AF" },
{ key: "AX", text: "Aland Islands", value: "AX" },
{ key: "AL", text: "Albania", value: "AL" },
{ key: "DZ", text: "Algeria", value: "DZ" },
{ key: "AS", text: "American Samoa", value: "AS" },
{ key: "AD", text: "Andorra", value: "AD" },
{ key: "AO", text: "Angola", value: "AO" },
{ key: "AI", text: "Anguilla", value: "AI" },
{ key: "AQ", text: "Antarctica", value: "AQ" },
{ key: "AG", text: "Antigua and Barbuda", value: "AG" },
{ key: "AR", text: "Argentina", value: "AR" },
{ key: "AM", text: "Armenia", value: "AM" },
{ key: "AW", text: "Aruba", value: "AW" },
{ key: "AU", text: "Australia", value: "AU" },
{ key: "AT", text: "Austria", value: "AT" },
{ key: "AZ", text: "Azerbaijan", value: "AZ" },
{ key: "A2", text: "Azores", value: "A2" },
{ key: "BS", text: "Bahamas", value: "BS" },
{ key: "BH", text: "Bahrain", value: "BH" },
{ key: "BD", text: "Bangladesh", value: "BD" },
{ key: "BB", text: "Barbados", value: "BB" },
{ key: "BY", text: "Belarus", value: "BY" },
{ key: "BE", text: "Belgium", value: "BE" },
{ key: "BZ", text: "Belize", value: "BZ" },
{ key: "BJ", text: "Benin", value: "BJ" },
{ key: "BM", text: "Bermuda", value: "BM" },
{ key: "BT", text: "Bhutan", value: "BT" },
{ key: "BO", text: "Bolivia", value: "BO" },
{ key: "BQ", text: "Bonaire, St. Eustatius, Saba", value: "BQ" },
{ key: "BA", text: "Bosnia and Herzegovina", value: "BA" },
{ key: "BW", text: "Botswana", value: "BW" },
{ key: "BV", text: "Bouvet Island", value: "BV" },
{ key: "BR", text: "Brazil", value: "BR" },
{ key: "IO", text: "British Indian Ocean Territory", value: "IO" },
{ key: "BN", text: "Brunei Darussalam", value: "BN" },
{ key: "BG", text: "Bulgaria", value: "BG" },
{ key: "BF", text: "Burkina Faso", value: "BF" },
{ key: "BI", text: "Burundi", value: "BI" },
{ key: "KH", text: "Cambodia", value: "KH" },
{ key: "CM", text: "Cameroon", value: "CM" },
{ key: "CA", text: "Canada", value: "CA" },
{ key: "IC", text: "Canary Islands", value: "IC" },
{ key: "CV", text: "Cabo Verde", value: "CV" },
{ key: "KY", text: "Cayman Islands", value: "KY" },
{ key: "CF", text: "Central African Republic", value: "CF" },
{ key: "XC", text: "Ceuta", value: "XC" },
{ key: "TD", text: "Chad", value: "TD" },
{ key: "CL", text: "Chile", value: "CL" },
{ key: "CN", text: "China Mainland", value: "CN" },
{ key: "CX", text: "Christmas Island", value: "CX" },
{ key: "CC", text: "Cocos Islands", value: "CC" },
{ key: "CO", text: "Colombia", value: "CO" },
{ key: "KM", text: "Comoros", value: "KM" },
{ key: "CG", text: "Congo", value: "CG" },
{ key: "CD", text: "Congo, The Democratic Republic of", value: "CD" },
{ key: "CK", text: "Cook Islands", value: "CK" },
{ key: "CR", text: "Costa Rica", value: "CR" },
{ key: "CI", text: "Cote d' Ivoire (Ivory Coast)", value: "CI" },
{ key: "HR", text: "Croatia", value: "HR" },
{ key: "CU", text: "Cuba", value: "CU" },
{ key: "CW", text: "Curacao", value: "CW" },
{ key: "CY", text: "Cyprus", value: "CY" },
{ key: "CZ", text: "Czech Republic", value: "CZ" },
{ key: "DK", text: "Denmark", value: "DK" },
{ key: "DJ", text: "Djibouti", value: "DJ" },
{ key: "DM", text: "Dominica", value: "DM" },
{ key: "DO", text: "Dominican Republic", value: "DO" },
{ key: "EC", text: "Ecuador", value: "EC" },
{ key: "EG", text: "Egypt", value: "EG" },
{ key: "SV", text: "El Salvador", value: "SV" },
{ key: "EN", text: "England", value: "EN" },
{ key: "GQ", text: "Equatorial Guinea", value: "GQ" },
{ key: "ER", text: "Eritrea", value: "ER" },
{ key: "EE", text: "Estonia", value: "EE" },
{ key: "ET", text: "Ethiopia", value: "ET" },
{ key: "FK", text: "Falkland Islands", value: "FK" },
{ key: "FO", text: "Faroe Islands", value: "FO" },
{ key: "FJ", text: "Fiji", value: "FJ" },
{ key: "FI", text: "Finland", value: "FI" },
{ key: "FR", text: "France", value: "FR" },
{ key: "GF", text: "French Guiana", value: "GF" },
{ key: "PF", text: "French Polynesia", value: "PF" },
{ key: "TF", text: "French Southern Territories", value: "TF" },
{ key: "GA", text: "Gabon", value: "GA" },
{ key: "GM", text: "Gambia", value: "GM" },
{ key: "GE", text: "Georgia", value: "GE" },
{ key: "DE", text: "Germany", value: "DE" },
{ key: "GH", text: "Ghana", value: "GH" },
{ key: "GI", text: "Gibraltar", value: "GI" },
{ key: "GR", text: "Greece", value: "GR" },
{ key: "GL", text: "Greenland", value: "GL" },
{ key: "GD", text: "Grenada", value: "GD" },
{ key: "GP", text: "Guadeloupe", value: "GP" },
{ key: "GU", text: "Guam", value: "GU" },
{ key: "GT", text: "Guatemala", value: "GT" },
{ key: "GG", text: "Guernsey", value: "GG" },
{ key: "GN", text: "Guinea", value: "GN" },
{ key: "GW", text: "Guinea-Bissau", value: "GW" },
{ key: "GY", text: "Guyana", value: "GY" },
{ key: "HT", text: "Haiti", value: "HT" },
{ key: "HM", text: "Heard Island and McDonald Islands", value: "HM" },
{ key: "HO", text: "Holland", value: "HO" },
{ key: "HN", text: "Honduras", value: "HN" },
{ key: "HK", text: "Hong Kong SAR, China", value: "HK" },
{ key: "HU", text: "Hungary", value: "HU" },
{ key: "IS", text: "Iceland", value: "IS" },
{ key: "IN", text: "India", value: "IN" },
{ key: "ID", text: "Indonesia", value: "ID" },
{ key: "IR", text: "Iran", value: "IR" },
{ key: "IQ", text: "Iraq", value: "IQ" },
{ key: "IE", text: "Ireland", value: "IE" },
{ key: "IM", text: "Isle of Man", value: "IM" },
{ key: "IL", text: "Israel", value: "IL" },
{ key: "IT", text: "Italy", value: "IT" },
{ key: "JM", text: "Jamaica", value: "JM" },
{ key: "JP", text: "Japan", value: "JP" },
{ key: "JE", text: "Jersey", value: "JE" },
{ key: "JO", text: "Jordan", value: "JO" },
{ key: "KZ", text: "Kazakhstan", value: "KZ" },
{ key: "KE", text: "Kenya", value: "KE" },
{ key: "KI", text: "Kiribati", value: "KI" },
{ key: "KR", text: "Korea, South", value: "KR" },
{ key: "KV", text: "Kosovo", value: "KV" },
{ key: "KO", text: "Kosrae", value: "KO" },
{ key: "KW", text: "Kuwait", value: "KW" },
{ key: "KG", text: "Kyrgyzstan", value: "KG" },
{ key: "LV", text: "Latvia", value: "LV" },
{ key: "LB", text: "Lebanon", value: "LB" },
{ key: "LS", text: "Lesotho", value: "LS" },
{ key: "LR", text: "Liberia", value: "LR" },
{ key: "LY", text: "Libya", value: "LY" },
{ key: "LI", text: "Liechtenstein", value: "LI" },
{ key: "LT", text: "Lithuania", value: "LT" },
{ key: "LU", text: "Luxembourg", value: "LU" },
{ key: "MO", text: "Macau SAR, China", value: "MO" },
{ key: "MK", text: "Macedonia", value: "MK" },
{ key: "MG", text: "Madagascar", value: "MG" },
{ key: "A3", text: "Madeira", value: "A3" },
{ key: "MW", text: "Malawi", value: "MW" },
{ key: "MY", text: "Malaysia", value: "MY" },
{ key: "MV", text: "Maldives", value: "MV" },
{ key: "ML", text: "Mali", value: "ML" },
{ key: "MT", text: "Malta", value: "MT" },
{ key: "MH", text: "Marshall Islands", value: "MH" },
{ key: "MQ", text: "Martinique", value: "MQ" },
{ key: "MR", text: "Mauritania", value: "MR" },
{ key: "MU", text: "Mauritius", value: "MU" },
{ key: "YT", text: "Mayotte", value: "YT" },
{ key: "XL", text: "Melilla", value: "XL" },
{ key: "MX", text: "Mexico", value: "MX" },
{ key: "FM", text: "Micronesia", value: "FM" },
{ key: "MD", text: "Moldova", value: "MD" },
{ key: "MC", text: "Monaco", value: "MC" },
{ key: "MN", text: "Mongolia", value: "MN" },
{ key: "ME", text: "Montenegro", value: "ME" },
{ key: "MS", text: "Montserrat", value: "MS" },
{ key: "MA", text: "Morocco", value: "MA" },
{ key: "MZ", text: "Mozambique", value: "MZ" },
{ key: "MM", text: "Myanmar", value: "MM" },
{ key: "NA", text: "Namibia", value: "NA" },
{ key: "NR", text: "Nauru", value: "NR" },
{ key: "NP", text: "Nepal", value: "NP" },
{ key: "NL", text: "Netherlands", value: "NL" },
{ key: "NC", text: "New Caledonia", value: "NC" },
{ key: "NZ", text: "New Zealand", value: "NZ" },
{ key: "NI", text: "Nicaragua", value: "NI" },
{ key: "NE", text: "Niger", value: "NE" },
{ key: "NG", text: "Nigeria", value: "NG" },
{ key: "NF", text: "Norfolk Island", value: "NF" },
{ key: "NB", text: "Northern Ireland", value: "NB" },
{ key: "MP", text: "Northern Mariana Islands", value: "MP" },
{ key: "NO", text: "Norway", value: "NO" },
{ key: "OM", text: "Oman", value: "OM" },
{ key: "PK", text: "Pakistan", value: "PK" },
{ key: "PW", text: "Palau", value: "PW" },
{ key: "PS", text: "Palestine", value: "PS" },
{ key: "PA", text: "Panama", value: "PA" },
{ key: "PG", text: "Papua New Guinea", value: "PG" },
{ key: "PY", text: "Paraguay", value: "PY" },
{ key: "PE", text: "Peru", value: "PE" },
{ key: "PH", text: "Philippines", value: "PH" },
{ key: "PN", text: "Pitcairn", value: "PN" },
{ key: "PL", text: "Poland", value: "PL" },
{ key: "PO", text: "Ponape", value: "PO" },
{ key: "PT", text: "Portugal", value: "PT" },
{ key: "PR", text: "Puerto Rico", value: "PR" },
{ key: "QA", text: "Qatar", value: "QA" },
{ key: "RE", text: "Reunion", value: "RE" },
{ key: "RO", text: "Romania", value: "RO" },
{ key: "RU", text: "Russia", value: "RU" },
{ key: "RW", text: "Rwanda", value: "RW" },
{ key: "BL", text: "Saint Barthelemy", value: "BL" },
{ key: "SW", text: "Saint Christopher", value: "SW" },
{ key: "C3", text: "Saint Croix", value: "C3" },
{ key: "UV", text: "Saint John", value: "UV" },
{ key: "KN", text: "Saint Kitts and Nevis", value: "KN" },
{ key: "LC", text: "Saint Lucia", value: "LC" },
{ key: "SX", text: "Saint Maarten and St. Martin", value: "SX" },
{ key: "VL", text: "Saint Thomas", value: "VL" },
{ key: "VC", text: "Saint Vincent and the Grenadines", value: "VC" },
{ key: "SP", text: "Saipan", value: "SP" },
{ key: "WS", text: "Samoa", value: "WS" },
{ key: "SM", text: "San Marino", value: "SM" },
{ key: "ST", text: "Sao Tome and Principe", value: "ST" },
{ key: "SA", text: "Saudi Arabia", value: "SA" },
{ key: "SF", text: "Scotland", value: "SF" },
{ key: "SN", text: "Senegal", value: "SN" },
{ key: "RS", text: "Serbia", value: "RS" },
{ key: "SC", text: "Seychelles", value: "SC" },
{ key: "SL", text: "Sierra Leone", value: "SL" },
{ key: "SG", text: "Singapore", value: "SG" },
{ key: "SK", text: "Slovakia", value: "SK" },
{ key: "SI", text: "Slovenia", value: "SI" },
{ key: "SB", text: "Solomon Islands", value: "SB" },
{ key: "ZA", text: "South Africa", value: "ZA" },
{ key: "ES", text: "Spain", value: "ES" },
{ key: "LK", text: "Sri Lanka", value: "LK" },
{ key: "SR", text: "Suriname", value: "SR" },
{ key: "SZ", text: "Swaziland", value: "SZ" },
{ key: "SE", text: "Sweden", value: "SE" },
{ key: "CH", text: "Switzerland", value: "CH" },
{ key: "TA", text: "Tahiti", value: "TA" },
{ key: "TW", text: "Taiwan, China", value: "TW" },
{ key: "TJ", text: "Tajikistan", value: "TJ" },
{ key: "TZ", text: "Tanzania", value: "TZ" },
{ key: "TH", text: "Thailand", value: "TH" },
{ key: "TL", text: "Timor-Leste", value: "TL" },
{ key: "TI", text: "Tinian", value: "TI" },
{ key: "TG", text: "Togo", value: "TG" },
{ key: "TO", text: "Tonga", value: "TO" },
{ key: "ZZ", text: "Tortola", value: "ZZ" },
{ key: "TT", text: "Trinidad and Tobago", value: "TT" },
{ key: "TU", text: "Truk", value: "TU" },
{ key: "TN", text: "Tunisia", value: "TN" },
{ key: "TR", text: "Turkey", value: "TR" },
{ key: "TM", text: "Turkmenistan", value: "TM" },
{ key: "TC", text: "Turks and Caicos Islands", value: "TC" },
{ key: "TV", text: "Tuvalu", value: "TV" },
{ key: "UG", text: "Uganda", value: "UG" },
{ key: "UA", text: "Ukraine", value: "UA" },
{ key: "UI", text: "Union Island", value: "UI" },
{ key: "AE", text: "United Arab Emirates", value: "AE" },
{ key: "GB", text: "United Kingdom", value: "GB" },
{ key: "US", text: "United States", value: "US" },
{ key: "UY", text: "Uruguay", value: "UY" },
{ key: "UZ", text: "Uzbekistan", value: "UZ" },
{ key: "VU", text: "Vanuatu", value: "VU" },
{ key: "VA", text: "Vatican City State", value: "VA" },
{ key: "VE", text: "Venezuela", value: "VE" },
{ key: "VN", text: "Vietnam", value: "VN" },
{ key: "VG", text: "Virgin Islands, British", value: "VG" },
{ key: "VI", text: "Virgin Islands, US", value: "VI" },
{ key: "WL", text: "Wales", value: "WL" },
{ key: "WF", text: "Wallis and Futuna Islands", value: "WF" },
{ key: "YA", text: "Yap", value: "YA" },
{ key: "YE", text: "Yemen", value: "YE" },
{ key: "ZM", text: "Zambia", value: "ZM" }]