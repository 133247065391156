import React, { useEffect, useRef, useState } from 'react'
import { Button, Search } from 'semantic-ui-react'
import 'styled-components/macro'

import cart from '../../img/cart4.svg'
import logo from '../../img/logo_inverted.jpg'
import miniLogo from '../../img/logo_mini.jpg'
//import cart from '../img/cart.jpg'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import axios from '../../axios'
import ProfilePopup from './profilePopup'
import { useSelector } from 'react-redux'
import SignInModal from '../signInModal/signInModal'
import { navbarStyles } from './navbar.styles'
import NotificationsPopup from './notificationsPopup'
import MessagesNotificationsPopup from './messagesNotificationsPopup'
import CategoriesPopup from './categoriesPopup'
import { useTranslation } from 'react-i18next'

const Navbar = (props) => {
  const history = useHistory()
  const [products, setProducts] = useState([])
  const { userInfo } = useSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const { cartItems } = useSelector(state => state.cart)
  const [t, i18n] = useTranslation("global")
  const search = async (term) => {
    try {
      setLoading(true)
      const res = await axios.put("/api/product/filter", {
        term
      })
      setProducts(res.data.map(x => { return { title: x } }))
      setLoading(false)
    } catch (e) {
      alert(e)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value !== "") {
      history.push("/search/" + (e.target.value) + (props.qp || ""))
    }
    if (e.key === "Enter" && e.target.value == "") {
      history.push("/")
    }
  }

  return (
    <>
      <TopMessage />
      <header className="navbar_sticky" css={navbarStyles} style={props.invisible ? { display: "none" } : {}} >
        <div className="headerContainer" >

          <div className="logoWrapper" title={t("navbar.home")}>
            <Link to="/">
              <img className="logo1" src={logo} alt={t("navbar.home")} />
              <img className="logo2" src={miniLogo} alt={t("navbar.home")} />
            </Link>
          </div>
          <CategoriesPopup/>
          <div className="searchStyles">
            <div css="width:100%;">
              <Search
                fluid
                className="customSearchInputClass"
                loading={loading}
                onResultSelect={(e, data) => {
                  history.push("/search/" + (data.result.title) + (props.qp || ""))
                }}
                onSearchChange={(e, data) => {
                  search(data.value)
                }}
                results={products}
                defaultValue={props.term || ""}
                showNoResults={false}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <div className="rightButtonsWrapper">
            {userInfo ? (
              <>
                <ProfilePopup />
                <NotificationsPopup />
                <MessagesNotificationsPopup />
                <Link title={t("navbar.shopping_cart")} to="/cart" css="flex-shrink:0;cursor:pointer;background:#02b7b2;border-radius:50%;width:50px;height:50px;display:flex;align-items:center;justify-content:center;position:relative;">
                  <img src={cart} css="width:35px;" alt="cart" />
                  {cartItems.length > 0 && <div css="position:absolute;top:0;right:0;padding:0px 6px;border-radius:100px;background-color:#db2828;color:white;font-size:13px;"><span>{cartItems.length}</span></div>}
                </Link>
              </>
            ) : (
              <SignInModal />
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar

const TopMessage = () => {
  const [t,] = useTranslation("global")
  const [open, setOpen] = useState(true)
  const location=useLocation()
  return <>
    {
      open && location.pathname=="/" &&
      <div css="background-color:#02b7b2;color:white;width:100%;">
        <div css="max-width:1200px;margin:0 auto;padding:14px;display:flex;align-items:center;">

          <div css="flex-grow:1;">
            {t("homeScreen.thank_you_visiting")}
          </div>
          <div css="flex-grow:0;margin-left:15px;cursor:pointer;font-size:20px" onClick={() => { setOpen(false) }}>
            X
                  </div>
        </div>
      </div>
    }
  </>
}