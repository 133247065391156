import React from 'react'
import { useTranslation } from 'react-i18next';

const DetailsTab = (props) => {
    const [t, i18n] = useTranslation("global")
    const pro = props.data.product
    const nodigital = () => {
        return pro.category_id != "60b5264c40b9d41dae531f96" && pro.category_id != "6182f72014e83501939ba8ca" && pro.category_id != "60a672fa4efcc56a787c7f1e" && pro.category_id != "60a672fa4efcc56a787c7f22" && pro.category_id != "60a672fa4efcc56a787c7f27"
    }
    return (
        <div className="ui form">
            <h2>{props?.data?.product?.category_id == "60a672fa4efcc56a787c7f22" ? t("product.course_description") : t("product.product_description")}</h2>
            <div className="ui field">
                <textarea readOnly>{props?.data?.product?.description}</textarea>
            </div>
            <h2>{t("product.instructions")}</h2>
            <div className="ui field">
                <textarea readOnly>{props?.data?.product?.instructions}</textarea>
            </div>
            {props?.data?.product?.category_id != "60a672fa4efcc56a787c7f22" && <><h2>{t("product.shipping_policies")}</h2>
                <div className="ui field">
                    <textarea readOnly>{props?.data?.vendor?.shipping_policy}</textarea>
                </div></>}
            {nodigital() && <>
                <h2>{t("profile.WeightDimension")}</h2>
                <div className="ui field">
                    <div className="ui segment">
                        <div>{t("width")}: {pro.width} {pro.weight_dimension.split("/")[1]}</div>
                        <div>{t("height")}: {pro.height} {pro.weight_dimension.split("/")[1]}</div>
                        <div style={{ marginTop: "0.5rem" }}></div>
                        <div>{t("length")}: {!['dhl', 'other', 'notapply'].includes(pro.shipper) && pro.length ? <>{pro.length} {pro.weight_dimension.split("/")[1]}</> : t("not_specified_text")}</div>
                        <div>{t("weight")}: {!['dhl', 'other', 'notapply'].includes(pro.shipper) && pro.length ? <>{pro.weight} {pro.weight_dimension.split("/")[0]}</> : t("not_specified_text")}</div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default DetailsTab