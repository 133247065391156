import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dimmer, Embed, Header, Icon, List, Loader, Menu, Rating, Segment, Sidebar } from 'semantic-ui-react'
import { useEffect, useMemo, useState } from 'react'
import CustomList from './customList'
import axios from '../axios'
import { useHistory } from 'react-router'
import placeholder from '../img/photo_placeholder.svg'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { url_environment } from '../config'

const Course = () => {
    const [t, i18n] = useTranslation("global")
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { userInfo } = useSelector(state => state.user)
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const loadData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get("/api/user/courses", config)
            setCourses(data)
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return <>

        <Navbar />
        <main>
            <div css="max-width:1300px; padding:0 36px; margin:0 auto;">
                <div css="display:flex;justify-content:flex-start;flex-wrap:wrap;margin-top:20px;">
                    {courses.map(product => <div css="padding:8px;width:20%;@media(max-width:950px){width:33.333%;}@media(max-width:600px){width:50%;}@media(max-width:400px){width:100%;}">
                        <a css="color:#000; &:hover{color:#000;}" href="#" onClick={(e) => { e.preventDefault(); history.push("/course?id=" + product._id) }} >
                            <div css="padding-bottom:80%;position:relative;">
                                <img css="border-radius:8px;position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" src={((product.business_picture ? url_environment + product.business_picture : "") || (product.freelancer_picture ? url_environment + product.freelancer_picture : "")) || ((product.images && product.images[0]) ? (url_environment + (product.images[0] || "")) : (placeholder))} alt="" />
                            </div>
                            <div>
                                <h3 css="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;min-width: 0;margin:0;">{product.service_name || product.name || product.freelancer_name || product.business_name}</h3>
                                <p css="margin:0;">{product.vendor_name || product.freelancer_name}</p>
                                <Rating size='mini' disabled defaultRating={product.avg_reviews} maxRating={5} /> ({product.total_sales})
                                        {!(product.freelancer_picture || product.business_picture) && <p>USD ${product.sale_price || product.hourly_rate || "0"}</p>}
                            </div>
                        </a>
                    </div>)}
                </div>
                {courses.length < 1 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
                    <h3 style={{ maxWidth: "700px", textAlign: "center" }}>{t("product.not_purchased_courses")}: <Link to="/category-products?cat_name=Courses&id_cat=60a672fa4efcc56a787c7f22&vendor_type=business">link</Link></h3>
                </div>}
            </div>
        </main>

        <Footer />
    </>
}

export default Course







// import Navbar from './navbar/navbar'
// import Footer from './footer/footer'
// import 'styled-components/macro'
// import { useTranslation } from 'react-i18next'
// import { Checkbox, Embed, Header, Icon, List, Menu, Segment, Sidebar } from 'semantic-ui-react'
// import { useMemo, useState } from 'react'
// import CustomList from './customList'

// const Course = () => {
//     const [t, i18n] = useTranslation("global")
//     const [visible, setVisible] = useState(false)
//     const data = useMemo(() => ([
//         {
//             title: "section 1",
//             children: [
//                 {
//                     title: "child 1"
//                 },
//                 {
//                     title: "child 2"
//                 },
//                 {
//                     title: "child 3"
//                 },
//                 {
//                     title: "child 4"
//                 },
//             ]
//         },
//         {
//             title: "section 2",
//             children: [
//                 {
//                     title: "child 1"
//                 },
//                 {
//                     title: "child 2"
//                 },
//                 {
//                     title: "child 3"
//                 },
//                 {
//                     title: "child 4"
//                 },
//             ]
//         },
//         {
//             title: "section 3",
//             children: [
//                 {
//                     title: "child 1"
//                 },
//                 {
//                     title: "child 2"
//                 },
//                 {
//                     title: "child 3"
//                 },
//                 {
//                     title: "child 4"
//                 },
//             ]
//         },
//     ]), [])
//     return <>

//         <Navbar />
//         <main>

//             <div css="max-width:1300px; padding:0 36px; margin:0 auto;">
//                 <Sidebar.Pushable css="height:500px;">
//                     <Sidebar
//                         // as={Segment}
//                         animation='overlay'
//                         icon='labeled'
//                         onHide={() => setVisible(false)}
//                         vertical
//                         visible={visible}
//                         width='wide'
//                         style={{ background: "white", padding: "1.5rem" }}
//                     >
//                         <List selection verticalAlign='middle'>
//                             {data.map((x, c) => {
//                                 return !x.children ? <List.Item onClick={() => { }}>
//                                     <List.Content>
//                                         <List.Header>{x.title}</List.Header>
//                                     </List.Content>
//                                 </List.Item> : <CustomList data={x} onChildrenClick={(item) => { }} />
//                             })}
//                         </List>
//                     </Sidebar>

//                     <Sidebar.Pusher>
//                         <div css="display:flex;flex-wrap:wrap;">
//                             <div css="width:25%;flex:0 0 auto;overflow-y:scroll;height:100%;@media(max-width:700px){width:100%; padding-bottom:1rem; margin-bottom:1rem; border-bottom:2px solid #dadada;}">
//                                 <List selection verticalAlign='middle'>
//                                     {data.map((x, c) => {
//                                         return !x.children ? <List.Item onClick={() => { }}>
//                                             <List.Content>
//                                                 <List.Header>{x.title}</List.Header>
//                                             </List.Content>
//                                         </List.Item> : <CustomList data={x} onChildrenClick={(item) => { }} />
//                                     })}
//                                 </List>
//                             </div>
//                             <div css="width:75%;flex:0 0 auto;min-height:100%;padding-left:1rem;@media(max-width:500px){width:100%;}">
//                                 <Embed
//                                     id='125292332'
//                                     placeholder='/images/vimeo-example.jpg'
//                                     source='vimeo'
//                                 />
//                             </div>
//                         </div>
//                     </Sidebar.Pusher>
//                 </Sidebar.Pushable>
//             </div>
//         </main>

//         <Footer />
//     </>
// }

// export default Course