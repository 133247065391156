import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Button, Form, Label, List, Message, Segment } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
const VolumePricing = (props) => {
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({ number1: "", number2: "", percentage: "" })
    const [volumePricing, setVolumePricing] = useState([])

    const [error, setError] = useState("")
    const handleInputChange = (e) => {
        setValues(p => ({ ...p, [e.target.name]: e.target.value }))
    }
    const handleAddVolumePricing = (e) => {
        const number1 = parseInt(values.number1)
        const number2 = parseInt(values.number2)
        const percentage = parseInt(values.percentage)
        setError("")
        if (isNaN(number1) || isNaN(percentage)) {
            setError(t("profile.fill_required_fields"))
            return
        }
        if ((number2 < number1) || (number1 < 1) || (number2 < 1) || (percentage < 1)) {
            setError(t("profile.correct_info"))
            return
        }
        const number_exist = volumePricing.find(item => {
            if ((number1 >= item[0]) && (number1 <= item[1])) {
                return true
            }
            if ((number2 >= item[0]) && (number2 <= item[1])) {
                return true
            }
        })
        if (number_exist) {
            setError(t("profile.num_range_error"))
            return
        }
        const arr = [...volumePricing, [number1, isNaN(number2)?"Infinity":number2, percentage]].sort((a, b) => a[0] - b[0])
        setVolumePricing(arr)
        props.onChange({ target: { name: "volume_pricing", value: arr } })
        setValues({ number1: "", number2: "", percentage: "" })
    }
    const handleDeleteVolumePricing = (index) => {
        const cp = [...volumePricing]
        cp.splice(index, 1)
        setVolumePricing(cp)
        props.onChange({ target: { name: "volume_pricing", value: cp } })
    }
    useEffect(()=>{setVolumePricing(props.values)},[])
    return <>
        <div>
            <Message
                attached
                header={t("profile.volume_pricing")}
                content={<><p>{t("profile.offer_discount")}</p><p>{t("profile.please_note_volume_pricing")}</p></>}
            />
            <div className='ui form attached fluid segment'>
                <div className="ui form" >
                    <Form.Group widths='equal'>
                        <Form.Input
                            // error={error.length > 0}
                            required
                            name="number1"
                            type="number"
                            label={t("profile.num_items_from")}
                            onChange={handleInputChange}
                            value={values.number1}
                        />
                        <Form.Input

                            name="number2"
                            type="number"
                            label={t("profile.num_items_to")}
                            onChange={handleInputChange}
                            value={values.number2}
                        />
                    </Form.Group>
                    <Form.Input
                        // error={error.length > 0}
                        required
                        name="percentage"
                        type="number"
                        label={t("profile.percentage_discount")}
                        onChange={handleInputChange}
                        value={values.percentage}
                    />
                    <Button onClick={handleAddVolumePricing} color="teal">{t("profile.add")}</Button>
                </div>
                <List divided relaxed>
                    {volumePricing.map((item, c) => (
                        <List.Item>
                            <List.Content>
                                <List.Header>{t("profile.buy_more_off", { number1: item[0], number2: item[1]=="Infinity"?(i18n.language=="en"?"more":"mas"):item[1], percentage: item[2] })}</List.Header>
                                <List.Content><Label as="button" color="red" onClick={(e) => { e.preventDefault(); handleDeleteVolumePricing(c) }} href="#">{t("profile.delete")}</Label></List.Content>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </div>
            {
                error.length > 0 && (
                    <Message
                        color="red"
                        header={error}
                    />
                )
            }
        </div>
    </>
}

export default VolumePricing