import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { Button, Dimmer, Loader } from "semantic-ui-react"
import axios from "../axios"
import { useQuery } from "../Home/search"
import { uiActions } from "../store/ui-slice"
import Navbar from "./navbar/navbar"
import { useTranslation } from 'react-i18next'

const GetFile = () => {
    const [t, i18n] = useTranslation("global")
    const { userInfo } = useSelector(state => state.user)
    const history = useHistory()
    const query = useQuery()
    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [home, setHome] = useState(false)
    const loadData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.get(`/api/product/getPurchasedFile?id=${query.get("id")}`, config)
            if (data.url == "https://interpowered.com") {
                setMessage(t("profile.download_exceeded"))
            } else {
                window.location = data.url
            }
            setHome(true)
        } catch (e) { alert(e) }
    }
    useEffect(() => {
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }));
        } else {
            loadData()
        }
    }, [])
    if (home) {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <div>
                <h2 style={{ marginBottom: "15px" }}>{message}</h2>
                <p style={{textAlign:"center"}}><Link className="ui teal button" to="/">{t("profile.return_to_home")}</Link></p>
            </div>
        </div>
    }
    return <>
        <Navbar invisible={true} />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        </div>
    </>
}

export default GetFile