import Axios from 'axios'
const axios = Axios.create({
    //baseURL:"http://localhost:3000",
    baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.hostname === "localhost" ? "500" : (window.location.hostname === "testing.interpowered.com" ? "400" : (window.location.hostname === "test.interpowered.com" ? "410" : "300"))}${window.location.protocol == "http:" ? "0" : "1"}`,
    // baseURL: `${window.location.protocol}//oie.pqa.mybluehost.me:300${window.location.protocol == "http:" ? "0" : "1"}`,

})
let errorresponsemessageaxios=true
axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (error?.response?.status == 401) {
        localStorage.clear()
        window.location = `http://${window.location.hostname}${window.location.hostname === "localhost" ? ":3000" : ""}`
        await new Promise((resolve) => { })
    } else if (error?.response?.status == 503) {
        window.location = `http://${window.location.hostname}${window.location.hostname === "localhost" ? ":3000" : ""}/maintenance`
        // window.location="https://interpowered.com/maintenance"
        await new Promise((resolve) => { })
    } else if (error?.response.status == 500) {
        if (
            error?.response?.data?.message == "Cannot read properties of null (reading 'following')" ||
            error?.response?.data?.message == "Cannot read properties of null (reading 'messages_notifications')"
        ) {
            if(errorresponsemessageaxios){
                errorresponsemessageaxios=false
                alert("Your account has been removed from the system by the administrator.")
                localStorage.clear()
                window.location = `http://${window.location.hostname}${window.location.hostname === "localhost" ? ":3000" : ""}`
                await new Promise((resolve) => { }) 
            }else{
                localStorage.clear()
                window.location = `http://${window.location.hostname}${window.location.hostname === "localhost" ? ":3000" : ""}`
                await new Promise((resolve) => { }) 
            }
        }
    }
    return Promise.reject(error);
});

export default axios
