import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Segment } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { url_environment } from '../config'

let env_port;
switch (window.location.hostname) {
    case "localhost":
        env_port = 5000
        break;
    case "interpowered.com":
        env_port = 3001
        break;
    case "test.interpowered.com":
        env_port = 4101
        break;
    case "testing.interpowered.com":
        env_port = 4001
        break;
}

const ContractModal = (props) => {
    const [t, i18n] = useTranslation("global")
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [generatingReport, setgeneratingReport] = useState(false)
    const [values, setValues] = useState({
        requested_availability: [],
        requested_available_days: [],
        requested_available_times: [],
    })
    const dispatch = useDispatch();
    const data = props.data
    const { userInfo } = useSelector(state => state.user)
    const loadData = async () => {
        setLoading(true)
        try {

            let client, freelancer
            if (userInfo.id == data.sender) {
                client = { ...userInfo, customer_picture: "/" + userInfo.image }
                const { data: datax } = await axios.get("/api/user/info/" + data.receiver)
                freelancer = datax
            } else {
                freelancer = { ...userInfo, freelancer_picture: "/" + userInfo.image }
                const { data: datax } = await axios.get("/api/user/info/" + data.sender)
                client = datax
            }
            console.log("datax: ", client, freelancer)
            if (data.payment_schedule)
                data.select_payment_schedule = data.payment_schedule
            setValues({
                ...data,
                client,
                freelancer
            })
            setFiles(data.files.map(x => { const ss = x.substring(x.lastIndexOf(".") + 1); const t = ss != "pdf" ? "image" : "pdf"; return { name: x.substring(x.lastIndexOf("/") + 1), type: t, image_link: url_environment + x } }))
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleGeneratePDF = async () => {
        setgeneratingReport(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            responseType: "arraybuffer"
        }
        try {
            const { data: pdf } = await axios.get(`https://${window.location.hostname}:${env_port}/api/proposal/report?contract_id=` + data._id, config)
            const b = new Blob([pdf], { type: "application/pdf" })
            window.open(URL.createObjectURL(b), "_blank")
        } catch (e) { alert(e) }
        setgeneratingReport(false)
    }
    useEffect(() => {
        if (!props.open) return;
        if (Object.keys(props.data).length == 0) return
        loadData()
    }, [props.open])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    if (Object.keys(props.data).length == 0) return <></>
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{data._id}</Modal.Header>
                <Modal.Content >
                    <div css="display:flex;justify-content:space-between;@media(max-width:660px){flex-direction:column;align-items:center;}">
                        <div css="display:flex;flex-grow:1;">
                            <div css="width: 160px;font-size:25px;font-weight:700;display:flex;align-items:center;color:teal;margin-right:35px;@media(max-width:500px){display:none;}">{t("contractmgmt.contractual_parties")}:</div>
                            <div css="display:flex;flex-wrap:wrap;flex-grow:1;">
                                <div css="margin:15px;width:200px;flex:0 0 auto;display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                    <img css="width:100px;height:100px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src={`${url_environment}${values?.client?.customer_picture}`} alt="" />
                                    <div>
                                        <div css="margin:10px 0;">{values?.client?.store_name || values?.client?.name}</div>
                                        {/* <div css="margin:10px 0;">{data.receiver.category_name}</div> */}
                                    </div>
                                </div>
                                <div css="margin:15px;width:200px;flex:0 0 auto;display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                    <img css="width:100px;height:100px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src={`${url_environment}${values?.freelancer?.freelancer_picture || values?.freelancer?.customer_picture}`} alt="" />
                                    <div>
                                        <div css="margin:10px 0;">{values?.freelancer?.store_name || values?.freelancer?.name}</div>
                                        {/* <div css="margin:10px 0;">{data.receiver.category_name}</div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div css="display:flex;align-items:flex-end;">
                            {/* <Button onClick={handleMessageClick} css="margin-top:20px !important;" circular color="teal">{t("contractmgmt.direct_message")}</Button> */}
                        </div>
                    </div>
                    <div css="height:2px;background-color:rgb(2,183,178);margin:20px 0;"></div>
                    <h2 css="text-align:center;color:rgb(2,183,178);margin:2rem 0;">{t("contractmgmt.contract")}: #{data._id}</h2>

                    <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                        <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-right:50px;@media(max-width:768px){width:100%;padding:0;}">
                            <div className="ui form">

                                {values.link_to_website && <div className="ui required field">
                                    <label>{t("contractmgmt.link_to_website")}</label>
                                    <Segment>{values.link_to_website}</Segment>
                                </div>}

                                <div className="ui required field">
                                    <label>{t("contractmgmt.title_of_service")}</label>
                                    <Segment>{values.service_name}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.job_scope_work")}</label>
                                    {/* <Segment>{values.job_scope_work}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.job_scope_work}</textarea>
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.agreed_payment_schedule")}</label>
                                    <Segment>{t("contractmgmt." + values.select_payment_schedule)}</Segment>
                                </div>
                                <div className="ui required field">
                                    <label>{t("contractmgmt.agreed_" + values.select_payment_schedule + (values.select_payment_schedule == "one_time" ? "_payment_amount" : "_rate"))}</label>
                                    <Segment>USD ${values.proposed_hourly_rate}</Segment>
                                </div>
                                {values.select_payment_schedule == "monthly" && <>
                                    <div className="ui field">
                                        <label>{t("contractmgmt.agreed_payment_date")}</label>
                                        <Segment>{values.payment_date}</Segment>
                                    </div>
                                </>}
                                <div className="ui required field">
                                    <label>{t("contractmgmt.business_requirements")}</label>
                                    {/* <Segment>{values.requirements}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.requirements}</textarea>

                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.freelancer_requirements")}</label>
                                    {/* <Segment>{values.freelancer_requirements}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.freelancer_requirements}</textarea>

                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.timeline")}</label>
                                    <label>{t("contractmgmt.meeting_schedule_paragraph")}</label>
                                    <textarea cols="30" rows="10" readOnly>{values.timeline}</textarea>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.meeting_schedule")}</label>
                                    {/* <label>{t("contractmgmt.meeting_schedule_paragraph")}</label> */}
                                    {/* <Segment>{values.meeting_schedule}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.meeting_schedule}</textarea>
                                </div>



                            </div>

                            {/* {values.sender}
                            {values.receiver}
                            {values.service}
                            {values.service_image}
                            {values.service_name}
                            {values.link_to_website}
                            {values.job_scope_work}
                            {values.proposed_hourly_rate}
                            {values.requirements}
                            {values.timeline}
                            {values.meeting_schedule}
                            {values.timezone}
                            {values.requested_availability}
                            {values.requested_available_days}
                            {values.requested_available_times}
                            {values.additional_information}
                            {values.signature_sender}
                            {values.signature_receiver}
                            {values.files}
                            {values.freelancer_requirements}
                            {values.freelancer_timezone}
                            {values.freelancer_additional_information} */}
                        </div>
                        <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-left:50px;@media(max-width:768px){width:100%;padding:0;}">
                            <div className="ui form">
                                <div className="ui required field">
                                    <label>{t("contractmgmt.business_timezone")}</label>
                                    <Segment>{values.timezone}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.freelancer_timezone")}</label>
                                    <Segment>{values.freelancer_timezone}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.availability")}</label>
                                    <Segment>{values.requested_availability.join(", ")}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.available_days")}</label>
                                    <Segment>{values.requested_available_days.join(", ")}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.available_times")}</label>
                                    <Segment>{values.requested_available_times.join(", ")}</Segment>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.additional_information_business")}</label>
                                    {/* <Segment>{values.additional_information}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.additional_information}</textarea>
                                </div>

                                <div className="ui required field">
                                    <label>{t("contractmgmt.freelancer_additional_information_freelancer")}</label>
                                    {/* <Segment>{values.freelancer_additional_information}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.freelancer_additional_information}</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 style={{ textAlign: "center" }}>{t("contractmgmt.digital_signatures")}</h3>
                        <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                            {values.signature_sender &&
                                <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-right:50px;@media(max-width:768px){width:100%;padding:0;}">
                                    <div css="padding-bottom:75%;position:relative;">
                                        <img css="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dededf;border-radius:.28571429rem;" src={url_environment + values.signature_sender} alt="" />
                                    </div>
                                </div>
                            }
                            {values.signature_receiver &&
                                <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-left:50px;@media(max-width:768px){width:100%;padding:0;}">
                                    <div css="padding-bottom:75%;position:relative;">
                                        <img css="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dededf;border-radius:.28571429rem;" src={url_environment + values.signature_receiver} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {files.length > 0 && <div>
                        <h3>{t("contractmgmt.sample_desired_work")}</h3>
                        <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                            <div>
                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                    {files.map((item) => {
                                        return <>
                                            <a href={item.image_link} target="_blank" css="display:block;flex:0 0 auto;width:112px;height:104px;padding:6px;">
                                                <div css="height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                    <div css="width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                        {!item.type.startsWith("image") && <div css="display:flex;flex-direction:column;width:100%;">
                                                            <div>
                                                                <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                            </div>
                                                            <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                        </div>}
                                                        {item.type.startsWith("image") && <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                            <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </a>
                                        </>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div style={{ margin: "1rem 0" }}>
                        <Button onClick={handleGeneratePDF} loading={generatingReport} primary>{t("generate_pdf")}</Button>
                    </div>
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ContractModal