import 'styled-components/macro'
import { url_environment } from '../../config'

const ImageGallery = (props) => {
    return <>
        <div css="display:flex;align-items:center;justify-content:flex-start;">
            {props.images && props.images.map((item) => {
                return <>
                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                    <img src={url_environment + item} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })}
        </div>
    </>
}

export default ImageGallery