import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Divider, Form, Icon, Message } from 'semantic-ui-react'
import 'styled-components/macro'
import { googleClientID } from '../../config'
import { login } from '../../store/user-slice'
import GoogleLogin from 'react-google-login'
import { uiActions } from '../../store/ui-slice'
import axios from '../../axios'

const LoginForm = (props) => {
    const handleFormSubmit = () => {
        dispatch(login(values.email, values.password))
    }
    const [t, i18n] = useTranslation("global")
    const dispatch = useDispatch()
    const history = useHistory()
    const [google_loading,set_google_loading]=useState(false)
    const { loading, userInfo, error, hasToken } = useSelector(state => state.user)
    const [values, setValues] = useState({ email: "", password: "", token: "" })
    const handleInputChange = (e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }
    const responseGoogle = async (e) => {
        console.log(e.profileObj.email)
        set_google_loading(true)
        try {
            await axios.post("/api/user/checkemail", {
                email: e.profileObj.email
            })
            dispatch(uiActions.change_action_sign_in({ open: true, action: "register",google_data:{...e.profileObj} }))
        } catch (ev) {
            setValues(p => { return { ...p, email: e.profileObj.email } })
        }
        set_google_loading(false)
        //e.profileObj.email .name
    };
    const errorResponseGoogle = (e) => {
    
    };
    return (
        <>
            <Form loading={loading} onSubmit={() => { dispatch(login(values.email, values.password, values.token)); props.setState({ ...values }) }}>
                <div css="display:flex;justify-content:space-between;">
                    <h2>{t('navbar.login')}</h2>
                    <Button type="button" circular color="teal" className="dark-outline-teal" basic onClick={() => props.changeAction("register")}>{t('navbar.register')}</Button>
                </div>
                <Form.Input required onChange={handleInputChange} value={values.email} icon='mail' iconPosition='left' name="email" fluid label={t('navbar.email')} type="email" placeholder='user@example.com' />
                <Form.Input required onChange={handleInputChange} icon='lock' iconPosition='left' name="password" fluid label={t('navbar.password')} type="password" />
                {/*<Form.Input onChange={handleInputChange} icon='key' iconPosition='left' name="token" fluid label='Authentication token' type="text" />*/}
                <Button fluid circular color="teal" style={{ background: "rgb(0,128,129)" }} /*onClick={dispatch(login())}*/>{t('navbar.login')}</Button>
                {
                    error && (
                        <Message color="red">
                            {t(error)}
                        </Message>

                    )
                }
                <Message>
                    <Link to='/forgot-password' css="text-align:center;display:block;">{t('navbar.forgot_your_password')}</Link>
                </Message>
                <Divider horizontal>{t('navbar.or')}</Divider>
                <GoogleLogin
                    clientId={googleClientID}
                    buttonText="Login"
                    render={(renderProps) => (
                        <Button loading={google_loading} onClick={renderProps.onClick} disabled={renderProps.disabled} color="teal" style={{ background: "rgb(2,183,178)", margin: "10px 0" }} circular fluid><Icon name='google' />{t('navbar.google_button')}</Button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={errorResponseGoogle}
                    cookiePolicy={"single_host_origin"}
                />

                {/* <Button color="teal" style={{ background: "rgb(82,219,212)", margin: "10px 0" }} circular fluid><Icon name='apple' />{t('navbar.apple_button')}</Button> */}
            </Form>
            {hasToken && ((() => { console.log("change!"); props.changeAction("token") })())}
        </>
    )
}

export default LoginForm