import React from 'react'
import { Tab } from 'semantic-ui-react'
import "styled-components/macro"
import { useTranslation } from 'react-i18next'
import UnreadMessages from './unread_messages'
import ArchivedMessages from './archived_messages'
import SentMessages from './sent_messages'

const Messages = (props) => {
    const [t, i18n] = useTranslation("global")
    const panes = [
        {
            menuItem: t("messages.unread"), render: () =>
                <Tab.Pane>
                    <UnreadMessages />
                </Tab.Pane>,
        },
        {
            menuItem: t("messages.archived"),
            render: () =>
                <Tab.Pane>
                    <ArchivedMessages />
                </Tab.Pane>,
        },
        {
            menuItem: t("messages.sent"),
            render: () =>
                <Tab.Pane >
                    <SentMessages />
                </Tab.Pane>,
        },
    ]

    const tabIndex=props.return ? (props.return=="unread" ? 0 : (props.return=="archived" ? 1 : 2)) : 0


    return (
        <>
            <h3 css="text-align:center;">{t("messages.messages")}</h3>
            <div css="height:2px;background:teal;margin:7px 0;"></div>
            <Tab panes={panes} defaultActiveIndex={tabIndex} />

        </>
    )
}

export default Messages