import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../axios'
import { useTranslation } from 'react-i18next'

const ConfirmDelete = (props) => {

    const [btnLoading,setBtnLoading]=useState(false)
    const {userInfo}=useSelector(state=>state.user)
    const [t, i18n] = useTranslation("global")
    const handleDelete = async () => {
        setBtnLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res=await axios.delete("/api/product/delete/"+props.id,config)
            if(res.data=="course not deleted"){
                alert(t("product.cannot_delete_course"))
                props.onClose()
                return;
            }
            props.update()
            props.onClose()
        } catch (e) {
            alert(e.response?.data?.message || e.message)
        }
        setBtnLoading(false)
    }

    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="small"
            >
                <Modal.Content>
                    Are you sure you want to delete this product listing?
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;max-width:400px;margin:0 auto;">
                        <Button circular color="teal" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button loading={btnLoading} circular color="red" onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>

                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ConfirmDelete