import Footer from "./footer/footer"
import Navbar from "./navbar/navbar"
import "styled-components/macro"
import { Dimmer, List, Loader } from "semantic-ui-react"
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from "react"
import ImageModal from './imageModal.js'
import CustomList from './customList.js'
import { useQuery } from "../Home/search"
import { url_environment } from '../config'

const Wiki = () => {
    const [t, i18n] = useTranslation("global")
    const [item, setItem] = useState({ content: <></>, title: "" })
    const [imgSrc, setImgSrc] = useState("")
    const [loading, setLoading] = useState(true)
    const query = useQuery()
    // const [lang,setLang]=useState("")
    // useEffect(()=>{setLang(i18n.language == "en" ? "eng" : "spa")},[i18n.language])
    const lang = i18n.language == "en" ? "eng" : "spa"
    const data = useMemo(() => {
        return [
            {
                title: "Interpowered",
                content: <>
                    <h2>{t("wiki.interpowered_how_to_get")}</h2>
                    <p>{t("wiki.interpowered_welcome_to_interpowered")}</p>
                    <p>{t("wiki.interpowered_are_you_a")}</p>
                    <p>{t("wiki.interpowered_do_you_have_business")}</p>
                    <p>{t("wiki.interpowered_the_submit_a")}</p>
                    <p>{t("wiki.interpowered_dont_know_where")}</p>
                    <p>{t("wiki.interpowered_want_to_broaden")}</p>
                    <p>{t("wiki.interpowered_do_you_have_idea")}</p>
                    <p>{t("wiki.interpowered_are_you_in")}</p>
                    {/* <p>{t("wiki.interpowered_looking_to_offer")}</p> */}
                    <p>{t("wiki.interpowered_made_for_you")}</p>
                    <p>{t("wiki.interpowered_you_get_the")}</p>
                    <p>{t("wiki.interpowered_the_creative_possibilities")}</p>
                </>
            },
            {
                title: t("wiki.user_control"),
                children: [
                    {
                        title: t("wiki.how_to_register"),
                        content: <>
                            <h2>{t("wiki.how_to_register")}</h2>
                            <p>{t("wiki.to_register")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_register_1.jpg`} alt="" />
                            <p>{t("wiki.then_the_login")}</p>
                            <p>{t("wiki.click_the_button")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_register_2.jpg`} alt="" />
                            <p>{t("wiki.fill_in_your")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_register_3.jpg`} alt="" />
                            <p>{t("wiki.fill_in_the")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_register_4.jpg`} alt="" />
                            <p>{t("wiki.congratulations")}</p>
                        </>
                    },
                    {
                        title: t("wiki.how_to_login"),
                        content: <>
                            <h2>{t("wiki.how_to_login")}</h2>
                            <p>{t("wiki.to_login")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_register_1.jpg`} alt="" />
                            <p>{t("wiki.the_window_appear")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_login_1.jpg`} alt="" />
                            <p>{t("wiki.if_you_have")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_to_login_2.jpg`} alt="" />
                        </>
                    }, {
                        title: t("wiki.how_config_profile"),
                        content: <>
                            <h2>{t("wiki.how_config_profile")}</h2>
                            <p>{t("wiki.to_config_profile")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_1.jpg`} alt="" />
                            <p>{t("wiki.depending_on_if")}</p>
                            <h3 css="text-align:center;">{t("wiki.client")}</h3>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_2.jpg`} alt="" />
                            <h3 css="text-align:center;">{t("wiki.business")}</h3>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_3.jpg`} alt="" />
                            <h3 css="text-align:center;">{t("wiki.freelancer")}</h3>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_4.jpg`} alt="" />
                            <p>{t("wiki.link_storefront_service")}</p>
                        </>
                    },
                    {
                        title: t("wiki.how_activate_2fa"),
                        content: <>
                            <h2>{t("wiki.how_activate_2fa")}</h2>
                            <p>{t("wiki.to_activate_2fa")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_1.jpg`} alt="" />
                            <p>{t("wiki.then_click_settings")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_activate_2fa_1.jpg`} alt="" />
                            <p>{t("wiki.click_generate_token")} <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a></p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_activate_2fa_2.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_change_language"),
                        content: <>
                            <h2>{t("wiki.how_change_language")}</h2>
                            <p>{t("wiki.click_profile_picture_navbar")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_change_language_1.jpg`} alt="" />
                            <p>{t("wiki.click_settings_to_language")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_change_language_2.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_add_paypal_account"),
                        content: <>
                            <h2>{t("wiki.how_add_paypal_account")}</h2>
                            <p>{t("wiki.click_profile_picture_navbar")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_change_language_1.jpg`} alt="" />
                            <p>{t("wiki.click_settings_to_paypal")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_add_paypal_account_1.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_report_problem"),
                        content: <>
                            <h2>{t("wiki.how_report_problem")}</h2>
                            <p>{t("wiki.click_profile_picture_navbar")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_change_language_1.jpg`} alt="" />
                            <p>{t("wiki.click_settings_to_report_problem")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_report_problem_1.jpg`} alt="" />
                            <p>{t("wiki.window_report_problem_appear")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_report_problem_2.jpg`} alt="" />
                        </>
                    },
                ]
            },
            {
                title: t("wiki.products"),
                children: [
                    {
                        title: t("wiki.how_sell_products"),
                        content: <>
                            <h2>{t("wiki.how_sell_products")}</h2>
                            <p>{t("wiki.if_at_the")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_1.jpg`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_2.jpg`} alt="" />
                            <p>{t("wiki.a_list_appear2")}</p>
                            <p>{t("wiki.once_save_product")}</p>
                            <p>{t("wiki.when_approval_process")}</p>
                            {/* <p>{t("wiki.a_list_appear")}</p>
                            <p>{t("wiki.the_window_appear_product")}</p>
                            <p>{t("wiki.your_new_product")}</p> */}
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_3.jpg`} alt="" />
                            <p>{t("wiki.default_product_name")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_4.jpg`} alt="" />
                            <p>{t("wiki.volume_pricing_tax")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_7.jpg`} alt="" />
                            <p>{t("wiki.select_tax_code")}</p>
                            <p>{t("wiki.select_tax_code_2")}<a target="_blank" href="https://knowledge.avalara.com/bundle/qvv1656594440497/page/Supported_regions.html">https://knowledge.avalara.com/bundle/qvv1656594440497/page/Supported_regions.html</a></p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_8.jpg`} alt="" />
                            <p>{t("wiki.if_see_tax_code")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_tax_code.png`} alt="" />
                            <p>{t("wiki.use_shipping_calculator")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_5.jpg`} alt="" />
                            <p>{t("wiki.select_any_carrier")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_products_6.jpg`} alt="" />
                        </>
                    }, {
                        title: t("wiki.how_buy_products"),
                        content: <>
                            <h2>{t("wiki.how_buy_products")}</h2>
                            <p>{t("wiki.to_buy_product")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_1.jpg`} alt="" />
                            <p>{t("wiki.also_click_category")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_2.jpg`} alt="" />
                            <p>{t("wiki.also_search_product")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_3.jpg`} alt="" />
                            <p>{t("wiki.after_clicking_product")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_4.jpg`} alt="" />
                            <p>{t("wiki.also_access_cart")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_5.jpg`} alt="" />
                            <p>{t("wiki.once_shopping_cart")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_6.jpg`} alt="" />
                            <p>{t("wiki.once_transaction_made")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_products_7.jpg`} alt="" />

                        </>
                    }, {
                        title: t("wiki.how_view_buy_orders"),
                        content: <>
                            <h2>{t("wiki.how_view_buy_orders")}</h2>
                            <p>{t("wiki.click_button_profile")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_1.jpg`} alt="" />
                            <p>{t("wiki.click_orders_section")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_buy_orders_1.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_view_sell_orders"),
                        content: <>
                            <h2>{t("wiki.how_view_sell_orders")}</h2>
                            <p>{t("wiki.click_button_profile")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_config_profile_1.jpg`} alt="" />
                            <p>{t("wiki.click_orders_section_sell")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_sell_orders_1.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.upload_sell_courses"),
                        content: <>
                            <h2>{t("wiki.upload_sell_courses")}</h2>
                            <p>{t("wiki.if_creating_account")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_1.jpg`} alt="" />
                            <p>{t("wiki.a_list_all_courses")}</p>
                            <h3>{t("wiki.add_new_course")}</h3>
                            <p>{t("wiki.click_add_course")}</p>
                            <p>{t("wiki.add_course_window")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_2.jpg`} alt="" />
                            <p>{t("wiki.once_save_course")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_3.jpg`} alt="" />
                            <p>{t("wiki.add_section_window")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_4.jpg`} alt="" />
                            <p>{t("wiki.add_lectures_section")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_5.jpg`} alt="" />
                            <p>{t("wiki.next_info_lecture")}</p>
                            <p>{t("wiki.note_lecture_add")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_6.jpg`} alt="" />
                            <p>{t("wiki.progressbar_shown")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_7.jpg`} alt="" />
                            <p>{t("wiki.after_adding_lecture")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_8.jpg`} alt="" />
                            <p>{t("wiki.once_finish_lecture")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_9.jpg`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_upload_sell_courses_10.jpg`} alt="" />
                            <p>{t("wiki.note_add_content")}</p>
                            <p>{t("wiki.course_successfully_approved")}</p>
                            <p>{t("wiki.repeat_process_information")}</p>

                        </>
                    },
                    {
                        title: t("wiki.how_buy_courses"),
                        content: <>
                            {/* <h3>{t("wiki.add_new_course")}</h3> */}
                            <h2>{t("wiki.how_buy_courses")}</h2>
                            <p>{t("wiki.to_buy_course")}</p>
                            <p>{t("wiki.clicking_courses_bubbles")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_courses_1.jpg`} alt="" />
                            <p>{t("wiki.using_search_bar_top")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_courses_2.jpg`} alt="" />
                            <p>{t("wiki.see_info_course")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_courses_3.jpg`} alt="" />
                            <p>{t("wiki.once_in_shopping_cart")}</p>
                            <p>{t("wiki.once_transaction_completed")}</p>
                        </>
                    },
                    {
                        title: t("wiki.how_view_courses"),
                        content: <>
                            {/* <h3>{t("wiki.add_new_course")}</h3> */}
                            <h2>{t("wiki.how_view_courses")}</h2>
                            <p>{t("wiki.to_see_courses")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_courses_1.jpg`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_courses_2.jpg`} alt="" />
                            <p>{t("wiki.see_content_course")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_courses_3.jpg`} alt="" />
                        </>
                    },
                ]
            },
            {
                title: t("wiki.services"),
                children: [
                    {
                        title: t("wiki.how_sell_services"),
                        content: <>
                            <h2>{t("wiki.how_sell_services")}</h2>
                            <p>{t("wiki.if_at_the_services")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_services_1.jpg`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_services_2.jpg`} alt="" />
                            <p>{t("wiki.a_list_appear_services2")}</p>
                            {/* <p>{t("wiki.a_list_appear_services")}</p>
                            <p>{t("wiki.the_window_appear_services")}</p> */}
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_services_3.jpg`} alt="" />
                            <p>{t("wiki.your_new_service")}</p>
                            <p>{t("wiki.once_published_service")}</p>
                            <p>{t("wiki.admin_approve_service")}</p>
                            <p>{t("wiki.select_tax_code")}</p>
                            <p>{t("wiki.select_tax_code_2")}<a target="_blank" href="https://knowledge.avalara.com/bundle/qvv1656594440497/page/Supported_regions.html">https://knowledge.avalara.com/bundle/qvv1656594440497/page/Supported_regions.html</a></p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_services_4.jpg`} alt="" />
                            <p>{t("wiki.if_see_tax_code")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_sell_services_tax_code.png`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_send_proposal"),
                        content: <>
                            <h2>{t("wiki.how_send_proposal")}</h2>
                            <p>{t("wiki.to_send_proposal")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_send_proposal_1.jpg`} alt="" />
                            <p>{t("wiki.once_inside_page")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_send_proposal_2.jpg`} alt="" />
                            <p>{t("wiki.form_will_appear")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_send_proposal_3.jpg`} alt="" />
                            <p>{t("wiki.can_save_proposal")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_send_proposal_4.jpg`} alt="" />
                            <p>{t("wiki.to_complete_process")}</p>
                            <p>{t("wiki.can_generate_pdf")}</p>
                            <p>{t("wiki.view_sent_proposals")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_send_proposal_5.jpg`} alt="" />
                        </>
                    }
                ]
            }
            ,
            {
                title: t("wiki.wishlist"),
                children: [
                    {
                        title: t("wiki.how_create_wishlist"),
                        content: <>
                            <h2>{t("wiki.how_create_wishlist")}</h2>
                            <p>{t("wiki.wishlist_link")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_create_wishlist_1.jpg`} alt="" />
                            <p>{t("wiki.click_profile_image")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_create_wishlist_2.jpg`} alt="" />
                            <p>{t("wiki.redirect_wishlist_page")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_create_wishlist_3.jpg`} alt="" />
                            <p>{t("wiki.name_wishlist_window_appear")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_create_wishlist_4.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.how_edit_wishlist_name"),
                        content: <>
                            <h2>{t("wiki.how_edit_wishlist_name")}</h2>
                            <p>{t("wiki.to_edit_wishlist_name")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_edit_wishlist_name_1.jpg`} alt="" />
                            <p>{t("wiki.window_change_name_wishlist")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_edit_wishlist_name_2.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.add_product_wishlist"),
                        content: <>
                            <h2>{t("wiki.add_product_wishlist")}</h2>
                            <p>{t("wiki.add_select_product")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_add_product_wishlist_1.jpg`} alt="" />
                            <p>{t("wiki.click_add_to_wishlist")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_add_product_wishlist_2.jpg`} alt="" />
                        </>
                    },
                    {
                        title: t("wiki.delete_product_wishlist"),
                        content: <>
                            <h2>{t("wiki.delete_product_wishlist")}</h2>
                            <p>{t("wiki.to_delete_product_wishlist")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_delete_product_wishlist_1.jpg`} alt="" />
                        </>
                    }
                ]
            },
            {
                title: t("wiki.marketing_packages"),
                children: [
                    {
                        title: t("wiki.marketing_packages"),
                        content: <>
                            <h2>{t("wiki.marketing_packages")}</h2>
                            <p>{t("wiki.sellers_and_freelancers")}</p>
                            <p css="font-weight:bold;">{t("wiki.in_app_notification_packages")}</p>
                            <ol>
                                <li>{t("wiki.one_in_app_notification")}</li>
                                <li>{t("wiki.five_in_app_notifications")}</li>
                                <li>{t("wiki.eight_in_app_notifications")}</li>
                            </ol>
                            <div css="margin:7px 0;"></div>
                            <p css="font-weight:bold;">{t("wiki.social_media_packages")}</p>
                            <ol>
                                <li>{t("wiki.your_product_will_be")}</li>
                            </ol>
                            <div css="margin:7px 0;"></div>
                            <p css="font-weight:bold;">{t("wiki.interpowered_homepage_package")}</p>
                            <ol>
                                <li>{t("wiki.the_1_99_option")}</li>
                                <li>{t("wiki.the_2_99_option")}</li>
                                <li>{t("wiki.the_4_99_option")}</li>
                                <li>{t("wiki.the_7_99_option")}</li>
                            </ol>
                        </>
                    },
                    {
                        title: t("wiki.how_buy_marketing_package"),
                        content: <>
                            <h2>{t("wiki.how_buy_marketing_package")}</h2>
                            <p>{t("wiki.login_interpowered_once")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_1.png`} alt="" />
                            <p>{t("wiki.click_settings_option")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_2.png`} alt="" />
                            <p>{t("wiki.click_marketing_button")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_3.png`} alt="" />
                            <p>{t("wiki.choose_package")}</p>
                            <p css="font-weight:bold;">{t("wiki.in_app_notification_packages")}</p>
                            <p>{t("wiki.after_you_choose")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_4.png`} alt="" />
                            <p>{t("wiki.complete_purchase_paypal")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_5.png`} alt="" />
                            <p>{t("wiki.once_transaction_completed_inapp")}</p>
                            {/* <p>{t("wiki.here_you_need")}</p> */}
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_6.png`} alt="" />
                            <p>{t("wiki.click_send_button")}</p>
                            <p>{t("wiki.notification_ready")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_7.png`} alt="" />
                            <div css="margin:7px 0;"></div>
                            <p css="font-weight:bold;">{t("wiki.social_media_packages")}</p>
                            <p>{t("wiki.for_social_media")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_8.png`} alt="" />
                            <p>{t("wiki.after_submit_you")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_9.png`} alt="" />
                            <p>{t("wiki.you_receive_email")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_10.png`} alt="" />
                            <div css="margin:7px 0;"></div>
                            <p css="font-weight:bold;">{t("wiki.interpowered_homepage_package")}</p>
                            <p>{t("wiki.you_select_packages")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_11.png`} alt="" />
                            <p>{t("wiki.once_clicked_buy")}</p>
                            <p>{t("wiki.once_clicked_buy2")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_12.png`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_13.png`} alt="" />
                            <p>{t("wiki.complete_purchase_paypal_2")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_14.png`} alt="" />
                            <p>{t("wiki.you_can_see_active")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_15.png`} alt="" />
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_16.png`} alt="" />
                            <p>{t("wiki.to_see_deactivated")}</p>
                            <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_buy_marketing_package_17.png`} alt="" />

                        </>
                    },
                ]
            },
            {
                title: t("wiki.how_view_messages"),
                content: <>
                    <h2>{t("wiki.how_view_messages")}</h2>
                    <p>{t("wiki.to_see_messages")}</p>
                    <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_messages_1.jpg`} alt="" />
                    <p>{t("wiki.also_see_messages")}</p>
                    <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_messages_2.jpg`} alt="" />
                    <p>{t("wiki.window_tabs_appear")}</p>
                    <img onClick={(e) => setImgSrc(e.target.src)} css="width:100%;display:block;max-width:400px;margin:2rem auto;cursor:pointer;" src={`${url_environment}/uploads/${lang}_how_view_messages_3.jpg`} alt="" />
                </>
            }
        ]
    }, [lang])
    useEffect(() => { setItem(data[0]); setTimeout(() => setLoading(false), 300); }, [])
    useEffect(() => {
        if (query.get("opt") == "how_to_register") { setItem(data[1].children[0]) }
        if (query.get("opt") == "new_product") { setItem(data[2].children[0]) }
        if (query.get("opt") == "new_service") { setItem(data[3].children[0]) }
        if (query.get("opt") == "new_course") { setItem(data[2].children[4]) }
    }, [lang])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return <>
        <Navbar />
        <main>

            <div css="max-width:1400px;margin:0 auto;padding:0 36px;display:flex;flex-wrap:wrap;">
                <div css="width:25%;flex:0 0 auto;overflow-y:scroll;height:100%;@media(max-width:700px){width:100%; padding-bottom:1rem; margin-bottom:1rem; border-bottom:2px solid #dadada;}">
                    <List selection verticalAlign='middle'>
                        {data.map((x, c) => {
                            return !x.children ? <List.Item onClick={() => setItem(x)}>
                                <List.Content>
                                    <List.Header>{x.title}</List.Header>
                                </List.Content>
                            </List.Item> : <CustomList data={x} onChildrenClick={(item) => setItem(item)} />
                        })}
                    </List>

                </div>
                <div css="width:75%;flex:0 0 auto;min-height:100%;padding-left:1rem;@media(max-width:500px){width:100%;}">
                    {item.content}
                </div>
            </div>
        </main>

        <Footer />
        <ImageModal src={imgSrc} open={imgSrc != ""} close={() => setImgSrc("")} title={item.title} />
    </>
}

export default Wiki