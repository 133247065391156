import React from 'react'
import { useTranslation } from 'react-i18next';
const DetailsTab = (props) => {
    const [t, i18n] = useTranslation("global")
    return (
        <div className="ui form">
            <h2>{t('profile.Service_description')}</h2>
            <div className="ui field">
                <textarea readOnly>{props.data.product.service_description}</textarea>
            </div>
            <h2>{t('profile.About_services_offered')}</h2>
            <div className="ui field">
                <textarea readOnly>{props.data.product.about_service}</textarea>
            </div>
            <h2>{t('profile.Requirements')}</h2>
            <div className="ui field">
                <textarea readOnly>{props.data.product.requirements}</textarea>
            </div>
        </div>
    )
}

export default DetailsTab