import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../axios'

const ConfirmDelete = (props) => {
    const [t,] = useTranslation("global")
    const [btnLoading, setBtnLoading] = useState(false)
    const { userInfo } = useSelector(state => state.user)

    const handlePublish = async () => {
        try {
            setBtnLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (props.save)
                await props.save()
            await axios.put(`/api/product/publish/${props.id}`, {}, config)
            setBtnLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setBtnLoading(false)
            props.update()
            props.onClose()
        }
    }

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="small"
            >
                <Modal.Header>{t("profile.are_you_sure_publish")}</Modal.Header>
                <Modal.Content>
                    {t("profile.by_selecting_publish")}
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;max-width:400px;margin:0 auto;">
                        <Button circular color="teal" css="background-color:rgb(0,128,129) !important;" onClick={props.onClose}>
                            {t("profile.cancel")}
                        </Button>
                        <Button loading={btnLoading} color="teal" css="background-color:rgb(2,183,178) !important;" circular onClick={handlePublish}>
                            {t("profile.Publish")}
                        </Button>
                    </div>

                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ConfirmDelete