import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dimmer, Header, Icon, Input, Label, Loader, Menu, Select, Table } from 'semantic-ui-react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import 'styled-components/macro'
import { Link } from 'react-router-dom';
import useLogic from './my-purchases.hook'

const MyPurchases = () => {
    const { loading, deactivated_purchases, handleDeactivatedPurchasesChange, t, RfilterDateSv, handleDateFromChange, RfilterDateEv, handleDateToChange, RfilterType, handleTypeChange,RfilterDateS,RfilterDateE,loadData,purchases,pages,handlePageClick} = useLogic()


    if (loading)
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>

    return (
        <>
            <Navbar />
            <main>

            <div css="max-width:1200px;margin:0 auto;padding:0 36px;">
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='8'>
                                <div css="display:flex;align-items:center;justify-content:space-between;">
                                    <Checkbox checked={deactivated_purchases.current} onChange={handleDeactivatedPurchasesChange} label={t("marketing_packages.deactivated_purchases")} />
                                    <div>
                                        <Input defaultValue={RfilterDateSv.current} type="date" onChange={handleDateFromChange} />
                                        <span css="margin: 0 1rem;">-</span>
                                        <Input defaultValue={RfilterDateEv.current} type="date" onChange={handleDateToChange} />
                                    </div>
                                    <Select
                                        options={[{ key: 0, text: "All", value: "all" }, { key: 1, text: "In-app notifications", value: "inappnotifications" }, { key: 2, text: "Social media", value: "socialmedia" }, { key: 3, text: "Marketing homepage", value: "marketinghomepage" }]}
                                        defaultValue={RfilterType.current}
                                        onChange={handleTypeChange}
                                    />
                                    <Button onClick={() => { loadData() }}>{t("marketing_packages.apply_filter")}</Button>
                                    <Button onClick={() => {
                                        RfilterType.current = "all"
                                        RfilterDateS.current = "";
                                        RfilterDateE.current = "";
                                        RfilterDateSv.current = "";
                                        RfilterDateEv.current = "";
                                        loadData()
                                    }}>Clear filter</Button>
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing>{t("marketing_packages.purchase_type")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("marketing_packages.product")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("marketing_packages.start_date")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("marketing_packages.end_date")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{t("marketing_packages.notifications")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{t("marketing_packages.days_remaining")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{t("marketing_packages.active")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {purchases.length > 0 && purchases.map((x, c) => {
                            const one_day = 1000 * 60 * 60 * 24
                            const drem = (x.start_date && x.end_date) ? ((new Date(x.start_date).getTime() > new Date().getTime()) ? ((((Math.round((new Date(x.end_date).getTime()) - (new Date(x.start_date)).getTime()) / one_day) + 1).toFixed(0))) : (((Math.round((new Date(x.end_date).getTime()) - (new Date()).getTime()) / one_day) + 1).toFixed(0))) : undefined
                            let renew_link = x.purchase_type == "In-app notification" ? (
                                x.type == "one" ? ("/marketing-packages/in-app-notifications?opt=1") :
                                    x.type == "five" ? ("/marketing-packages/in-app-notifications?opt=2") :
                                        x.type == "eight" ? ("/marketing-packages/in-app-notifications?opt=3") : ""
                            ) : (
                                x.purchase_type == "Social media" ? "/marketing-packages/social-media?product=" + x.product_id._id + "&platform=" + x.platform : (
                                    x.purchase_type == "Interpowered marketing homepage" && (
                                        x.type == "day" ? (
                                            x.option == "1" ? "/marketing-packages/homepage?type=day&opt=1&product=" + x.product_id._id :
                                                x.option == "2" ? "/marketing-packages/homepage?type=day&opt=2&product=" + x.product_id._id : ""
                                        ) :
                                            x.type == "week" ? (
                                                x.option == "1" ? "/marketing-packages/homepage?type=week&opt=1&product=" + x.product_id._id :
                                                    x.option == "2" ? "/marketing-packages/homepage?type=week&opt=2&product=" + x.product_id._id : ""
                                            ) : ""
                                    )
                                )
                            )
                            return (
                                <Table.Row key={x.order_id}>
                                    <Table.Cell>{x.purchase_type}</Table.Cell>
                                    <Table.Cell>{x.product_id ? (x.product_id.name) : "-"}</Table.Cell>
                                    <Table.Cell>{x.start_date ? (new Date(x.start_date)).toLocaleDateString() : ""}</Table.Cell>
                                    <Table.Cell>{x.end_date ? (new Date(x.end_date)).toLocaleDateString() : "-"}</Table.Cell>
                                    <Table.Cell>{x.notifications || "-"}</Table.Cell>
                                    <Table.Cell>{drem || "-"}</Table.Cell>
                                    <Table.Cell collapsing>
                                        {x.active && <Label color="green">YES</Label>}
                                        {!x.active && <Label color="red">NO</Label>}
                                    </Table.Cell>
                                    <Table.Cell><Link to={renew_link} className="ui circular button" >{t("marketing_packages.renew")}</Link></Table.Cell>
                                </Table.Row>
                            )
                        })}

                        {
                            purchases.length < 1 &&
                            (
                                <Table.Row>
                                    <Table.Cell colSpan='8'>
                                        <div css="display:flex;align-items:center;justify-content:center;">
                                            <Header as='h2' icon>
                                                <Icon name='ban' />
                                                {t("marketing_packages.nothing")}
                                            </Header>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='8'>
                                <div css="display:flex;align-items:center;justify-content:flex-end;">
                                    {pages.total > 1 && <Menu pagination>

                                        {[...Array(pages.total).keys()].map((x, c) => {
                                            return <Menu.Item key={c} active={pages.current == c + 1} onClick={() => handlePageClick(c + 1)} as='a'>{c + 1}</Menu.Item>
                                        })}
                                    </Menu>}
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
            </main>

            <Footer />

        </>
    )
}

export default MyPurchases