import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal } from 'semantic-ui-react'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import 'styled-components/macro'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import SignatureModal from './signatureModal'
import { timezones } from './countries'
import axios from '../axios'
import { useSelector } from 'react-redux'
import { formData } from '../shared/formDataCreator'
import { url_environment } from '../config'

export const payment_date_options = [{key:1,text:1,value:1}, {key:2,text:2,value:2}, {key:3,text:3,value:3}, {key:4,text:4,value:4}, {key:5,text:5,value:5}, {key:6,text:6,value:6}, {key:7,text:7,value:7}, {key:8,text:8,value:8}, {key:9,text:9,value:9}, {key:10,text:10,value:10}, {key:11,text:11,value:11}, {key:12,text:12,value:12}, {key:13,text:13,value:13}, {key:14,text:14,value:14}, {key:15,text:15,value:15}, {key:16,text:16,value:16}, {key:17,text:17,value:17}, {key:18,text:18,value:18}, {key:19,text:19,value:19}, {key:20,text:20,value:20}, {key:21,text:21,value:21}, {key:22,text:22,value:22}, {key:23,text:23,value:23}, {key:24,text:24,value:24}, {key:25,text:25,value:25}, {key:26,text:26,value:26}, {key:27,text:27,value:27}, {key:28,text:28,value:28}, {key:29,text:29,value:29}, {key:30,text:30,value:30}]

const Proposal = () => {
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({
        link_to_website: "", searching_for: "", job_scope_work: "", payment_date: 1, select_payment_schedule: "hourly", proposed_hourly_rate: "", requirements: "", timeline: "", meeting_schedule: "", timezone: "", requested_availability: [], requested_available_days: [], requested_available_times: [], additional_information: ""
    })
    const location = useLocation()
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [sigImage, setSigImage] = useState(undefined)
    const [showSignatureModal, setShowSignatureModal] = useState(false)
    const [filesData, setFilesData] = useState([])
    const [data,] = useState(location.state)
    const [vendorServices, setVendorServices] = useState([])
    const { userInfo } = useSelector(state => state.user)
    const fileRef = useRef(undefined)
    const history = useHistory()
    
    const loadData = async () => {
        try {
            setLoading(true)
            const { data: services } = await axios.post("/api/product/readAllOpen", {
                vendor_id: data.vendor._id
            })
            setVendorServices(services.map(x => { return { key: x._id, text: x.service_name, value: x._id } }))
            if (data.service) setValues(p => { return { ...p, searching_for: data.service._id, requested_availability: [...data.service.availability], requested_available_days: [...data.service.available_days], requested_available_times: [...data.service.available_times] } })
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])

    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const pass = useCallback((data) => {
        if (
            data.searching_for == "" ||
            data.job_scope_work == "" ||
            data.proposed_hourly_rate == "" ||
            data.select_payment_schedule == "" ||
            (data.select_payment_schedule == "monthly" && data.payment_date == "") ||
            data.requirements == "" ||
            data.timeline == "" ||
            data.meeting_schedule == "" ||
            data.timezone == "" ||
            data.requested_availability.length == 0 ||
            data.requested_available_days.length == 0 ||
            data.requested_available_times.length == 0 ||
            data.additional_information == "" ||
            !data.sigImage
        ) return false
        return true
    }, [])
    const handleSaveClick = async (submit) => {
        if (!pass({ ...values, sigImage })) { alert("Please fill all required fields"); return; }
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            setLoading(true)
            const temp = { ...values }
            temp.requested_availability = JSON.stringify(temp.requested_availability)
            temp.requested_available_days = JSON.stringify(temp.requested_available_days)
            temp.requested_available_times = JSON.stringify(temp.requested_available_times)
            await axios.post("/api/proposal/create", formData({
                ...temp,
                ...getFilesObject(files),
                sigImage,
                receiver: data.vendor.id_user,
                sent: submit ? true : false
            }), config)
            history.goBack()
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const handleCheckboxChange = useCallback((e, a) => {
        const { name, checked, value } = a
        // debugger;
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, [name]: [...prev[name], value] }
            } else {
                arr = prev[name].filter((i) => { return i !== value });
                return { ...prev, [name]: arr }
            }
        })
    }, [])
    const handleRadioChange = useCallback((e, a) => {
        const { name, checked, value } = a
        // debugger;
        setValues(prev => {
            return { ...prev, [name]: value }
        })
    }, [])

    const handleSignatureSubmit = useCallback((image) => {
        setSigImage(image)
    }, [])
    const handleSignatureModalClose = useCallback(() => {
        setShowSignatureModal(false)
    }, [])
    const handleAddFileClick = useCallback(() => {
        fileRef.current.click()
    }, [])
    const handleSignatureClick = useCallback(() => {
        setShowSignatureModal(true)
    }, [])
    const handleSelectChange = useCallback((e, a) => {
        const { name, value } = a
        setValues(p => { return { ...p, [name]: value } })
    }, [])
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleFileChange = useCallback((e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])

    }, [])
    const handleInputChange = useCallback((e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }, [])
    useEffect(() => {
        if (!data) history.push("/")
    }, [])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1400px;margin:0 auto;padding:0 36px;">
                    <div css="max-width:1000px;margin:0 auto;">
                        <div css="display:flex;justify-content:space-between;@media(max-width:660px){flex-direction:column;align-items:center;}">
                            <div css="display:flex;">
                                <div css="font-size:25px;font-weight:700;display:flex;align-items:center;color:teal;margin-right:35px;@media(max-width:500px){display:none;}">To:</div>
                                <div css="display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                    <img css="width:150px;height:150px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src={`${url_environment}${data.vendor.freelancer_picture}`} alt="" />
                                    <div>
                                        <div css="margin:10px 0;">{data.vendor.freelancer_name}</div>
                                        <div css="margin:10px 0;">{data.vendor.category_name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div css="height:2px;background-color:rgb(2,183,178);margin:20px 0;"></div>
                        <h2 css="text-align:center;color:rgb(2,183,178);margin:2rem 0;">{t("contractmgmt.proposal")}</h2>
                        <p css="margin-top:0.7rem;margin-bottom:2rem;font-weight:bold;">
                            {t("contractmgmt.important_carefully_read_para")}
                        </p>
                        <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                            <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-right:50px;@media(max-width:768px){width:100%;padding:0;}">
                                <div className="ui form">
                                    <Form.Input
                                        label={t("contractmgmt.link_to_website")}
                                        value={values.link_to_website}
                                        name="link_to_website"
                                        onChange={handleInputChange}
                                    />
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.searching_for")}</label>
                                        {/* <label>{t("contractmgmt.write_the_job_para")}</label>
                                    <label>{t("contractmgmt.write_the_job_example")}</label> */}
                                        <Form.Select
                                            options={vendorServices}
                                            value={values.searching_for}
                                            name="searching_for"
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                    <Form.TextArea
                                        required
                                        label={t("contractmgmt.job_scope_work")}
                                        value={values.job_scope_work}
                                        name="job_scope_work"
                                        onChange={handleInputChange}
                                        rows={8}
                                    />
                                    <div className="ui field">
                                        <input onChange={handleFileChange} type="file" accept="image/*,application/pdf" hidden ref={fileRef} />
                                        <label>{t("contractmgmt.sample_desired_work")}</label>
                                        <div css="display:flex;align-items:center;justify-content:flex-start;">
                                            <div>
                                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                                    <div onClick={handleAddFileClick} css="cursor:pointer;flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file</p>
                                                                    <i className="ui big file icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {filesData.map((item) => {
                                                        return <>
                                                            <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                                <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                                    <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                                        {!item.type.startsWith("image") && <div css="display:flex;flex-direction:column;width:100%;">
                                                                            <div>
                                                                                <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                                            </div>
                                                                            <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                                        </div>}
                                                                        {item.type.startsWith("image") && <div css="display:flex;flex-direction:column;align-items:center;width:100%;height:100%;">
                                                                            <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.select_payment_schedule")}</label>
                                        <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox radio checked={values.select_payment_schedule === "hourly"} value="hourly" name="select_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.hourly")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox radio checked={values.select_payment_schedule === "weekly"} value="weekly" name="select_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.weekly")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox radio checked={values.select_payment_schedule === "monthly"} value="monthly" name="select_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.monthly")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox radio checked={values.select_payment_schedule === "one_time"} value="one_time" name="select_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.one_time")} />
                                            </div>
                                        </div>
                                    </div>
                                    <Form.Input
                                        required
                                        icon="dollar sign"
                                        iconPosition="left"
                                        type="number"
                                        label={t("contractmgmt.proposed_" + values.select_payment_schedule + (values.select_payment_schedule == "one_time" ? "_payment_amount" : "_rate"))}
                                        name="proposed_hourly_rate"
                                        value={values.proposed_hourly_rate}
                                        onChange={handleInputChange}
                                    />
                                    {values.select_payment_schedule=="monthly"&&<div className="ui required field">
                                        <label>{t("contractmgmt.payment_date")}</label>
                                        <Form.Select
                                            fluid
                                            options={payment_date_options}
                                            onChange={handleSelectChange}
                                            name="payment_date"
                                            value={values.payment_date}
                                        />
                                    </div>}
                                    <div className="ui required field">
                                        <label>
                                            {t("contractmgmt.requirements")}<br />
                                            {t("contractmgmt.what_do_you_require_para")}<br />
                                            {t("contractmgmt.what_do_you_require_example")}
                                        </label>
                                        <textarea
                                            name="requirements"
                                            value={values.requirements}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.timeline")}</label>
                                        <label>{t("contractmgmt.meeting_schedule_paragraph")}</label>
                                        <textarea
                                            name="timeline"
                                            value={values.timeline}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-left:50px;@media(max-width:768px){width:100%;padding:0;}">
                                <div className="ui form">
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.meeting_schedule")}</label>
                                        {/* <label>{t("contractmgmt.meeting_schedule_paragraph")}</label> */}
                                        <textarea
                                            name="meeting_schedule"
                                            value={values.meeting_schedule}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.timezone")}</label>
                                        <label>{t("contractmgmt.what_time_zone_para")}</label>
                                        <Form.Select
                                            fluid
                                            options={timezones}
                                            onChange={handleSelectChange}
                                            name="timezone"
                                            value={values.timezone}
                                        />
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.requested_availability")}</label>
                                        <div css="display:flex;flex-wrap:wrap;">
                                            <div css="padding:5px 0;width:100%;">
                                                <Checkbox label={t("contractmgmt.full_time")} checked={values.requested_availability.indexOf("Full time") !== -1} value="Full time" name="requested_availability" onChange={handleCheckboxChange} />
                                            </div>
                                            <div css="padding:5px 0;width:100%;">
                                                <Checkbox label={t("contractmgmt.part_time")} checked={values.requested_availability.indexOf("Part time") !== -1} value="Part time" name="requested_availability" onChange={handleCheckboxChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.requested_available_days")}</label>
                                        <label>{t("contractmgmt.what_days_and_times")}</label>
                                        <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Monday") !== -1} value="Monday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.monday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Tuesday") !== -1} value="Tuesday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.tuesday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Wednesday") !== -1} value="Wednesday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.wednesday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Thursday") !== -1} value="Thursday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.thursday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Friday") !== -1} value="Friday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.friday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Saturday") !== -1} value="Saturday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.saturday")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_days.indexOf("Sunday") !== -1} value="Sunday" name="requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.sunday")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.requested_available_times")}</label>
                                        <label>{t("contractmgmt.what_days_and_times")}</label>
                                        <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_times.indexOf("Early") !== -1} value="Early" name="requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.early_morning")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_times.indexOf("Morning") !== -1} value="Morning" name="requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.morning")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_times.indexOf("Afternoon") !== -1} value="Afternoon" name="requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.afternoon")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_times.indexOf("Evening") !== -1} value="Evening" name="requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.evening")} />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox checked={values.requested_available_times.indexOf("Night") !== -1} value="Night" name="requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.night")} />
                                            </div>
                                        </div>
                                    </div>
                                    <Form.TextArea
                                        required
                                        label={t("contractmgmt.additional_information")}
                                        name="additional_information"
                                        value={values.additional_information}
                                        onChange={handleInputChange}
                                        rows={8}
                                    />
                                    <div className="ui required field">
                                        <label>{t("contractmgmt.digital_signature")}</label>
                                        <input type="text" onClick={handleSignatureClick} />
                                    </div>
                                    {sigImage && <div className="ui field">
                                        <div css="padding-bottom:75%;position:relative;">
                                            <img css="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dededf;border-radius:.28571429rem;" src={sigImage} alt="" onLoad={handleImageLoad} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div css="display:flex;justify-content:space-between;max-width:1000px; margin:0 auto;">
                            <Button onClick={() => history.goBack()} color="teal" css="background:rgb(82,219,212) !important;" circular>{t("contractmgmt.back")}</Button>
                            <Button css="background:rgb(2,183,178) !important;" onClick={() => handleSaveClick()} /*style={{background:"#fff"}}*/ color="teal" circular>{t("contractmgmt.save")}</Button>
                            <Button css="background:rgb(0,128,129) !important;" onClick={() => handleSaveClick(true)} /*style={{background:"#fff"}}*/ color="teal" circular>{t("contractmgmt.submit")}</Button>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
            <SignatureModal open={showSignatureModal} close={handleSignatureModalClose} submit={handleSignatureSubmit} />
        </>
    )
}

export default Proposal