import React, { useState } from 'react'
import 'styled-components/macro'
import { Button, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { logout } from '../../store/user-slice'
import { useTranslation } from 'react-i18next'
import { resetCart } from '../../store/cart-slice'
import { uiActions } from '../../store/ui-slice'
import { url_environment } from '../../config'
import adminimage from '../../img/admin_image.png'

const ProfilePopup = () => {
    const history = useHistory()
    const { userInfo } = useSelector(state => state.user)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global")
    const handleMessagesClick = (e) => {
        e.preventDefault()
        dispatch(uiActions.change_action({ open: true, action: "messages" }))
        setOpen(false)
    }
    return (
        <Popup
            on="click"
            trigger={<button title={t("navbar.profile")} css={`cursor:pointer;background:transparent;border:0;width:50px;height:50px;background-position:center;background-size:cover;border-radius:50%;margin-left:10px;margin-right:10px;background-image:url('${userInfo.image != "null" ? `${url_environment}/${userInfo.image}` : adminimage}')`}></button>}
            flowing
            pinned
            position="bottom center"
            basic
            style={{ fontSize: "16px", borderRadius: "20px", padding: "30px 20px" }}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        //style={{borderRadius:"20px"}}
        >

            <img css="display:block;width:100px;height:100px;border-radius:50%;object-fit:cover;object-position:center;margin:0 auto;" src={userInfo.image != "null" ? `${url_environment}/${userInfo.image}` : adminimage} alt="" />
            <p css="text-align:center;margin:10px 0;">{t('navbar.welcome_name', { name: userInfo.name })}</p>
            <p css="color:#888;text-align:center;margin:10px 0;">{userInfo.email}</p>
            <p css="text-align:center;margin:20px 0;">
                {
                    (() => {
                        //console.log(userInfo)
                        if (userInfo.is_admin === true) {
                            return <>
                                <Link to="/administration" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.admin_panel')}</Link>
                            </>
                        } else if (userInfo.id_role == "2") {
                            return <Link to="/business-profile" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.profile')}</Link>
                        } else if (userInfo.id_role == "3") {
                            return <Link to="/freelancer-profile" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.profile')}</Link>
                        } else {
                            return <Link to="/customer-profile" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.profile')}</Link>
                        }
                    })()
                }
            </p>
            {userInfo.is_admin === true&&<p css="text-align:center;">
                <Link to="/approvals" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.approvals')}</Link>
            </p>}
            <p css="text-align:center;">
                <Link to="/wishlist" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.create_view_wishlist')}</Link>
            </p>
            <p css="text-align:center;">
                <Link to="/courses" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.courses')}</Link>
            </p>
            <p css="text-align:center;">
                <Link to={`/${userInfo.id_role=="3"?"received-proposals":"sent-proposals"}`} css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('profile.contract_management')}</Link>
            </p>
            <p css="text-align:center;">
                <a href="#" onClick={handleMessagesClick} css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}">{t('navbar.messages')}</a>
            </p>
            <p css="text-align:center;">
                <a href="#" css="padding:5px 10px;border:1px solid black;border-radius:100px;color:black;&:hover{color:black;}" onClick={(e) => { e.preventDefault(); dispatch(logout()); dispatch(resetCart()); history.push("/") }} >{t('navbar.logout')}</a>
            </p>
        </Popup>
    )
}

export default ProfilePopup