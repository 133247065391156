import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Comment, Dimmer, Form, Loader, Rating } from 'semantic-ui-react'
import axios from '../../axios'
import "styled-components/macro"
import { useTranslation } from 'react-i18next'
import ImageGallery from '../product page/imageGallery'
import { url_environment } from '../../config'
const ReviewsTab = (props) => {
    const [loading, setLoading] = useState(false)
    const [rating, setRating] = useState(false)
    const [allow_review, setAllow_review] = useState(false)
    const [reviews, setReviews] = useState([])
    const [text, setText] = useState("")
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    let loadData = () => { }
    useEffect(() => {
        loadData()
    }, [])
    if (!userInfo) {
        return <>
            <h2 css="margin-top:10px;text-align:center;margin:20px 0;">{t("profile.you_have_to_reviews")}</h2>
        </>
    }
    loadData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setLoading(true)
        try {
            const { data } = await axios.get(`/api/product/review/get/${props.id}`, config)
            setReviews(data)
            const { data: allow } = await axios.get("/api/product/review/allow/" + props.id, config)
            setAllow_review(allow === "ok")
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleRate = (e, { rating }) => {
        setRating(rating)
    }
    const handleTextChange = (e) => {
        setText(e.target.value)
    }
    const handleSubmit = async (e) => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setLoading(true)
        e.preventDefault()
        try {
            await axios.post("/api/product/review/create", {
                product_id: props.id,
                text,
                rating
            }, config)
            await loadData()
        } catch (e) { alert(e) }
        setLoading(false)
    }

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            {reviews.length === 0 && (
                <h2 style={{ textAlign: "center", margin: "20px 0" }}>No reviews</h2>
            )}
            <Comment.Group>
                {reviews.map((x) => {
                    return (
                        <>
                            <Comment>
                                {/* <Comment.Avatar as='a' src={`https://interpowered.com:3001${x.user.customer_picture}`} /> */}
                                <span style={{ marginRight: "10px" }} className="avatar" /*css="display: block;width:50px;height:auto;float:left;margin-top: .2em;margin-right:10px !important;"*/>
                                    <img css="margin-right:10px;width:50px !important;height:50px !important;object-fit:cover;object-position:center;" src={`${url_environment}${x.user.customer_picture}`} alt="" />
                                </span>
                                <Comment.Content style={{ paddingLeft: "10px" }}>
                                    <Comment.Author>{x.user.name}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{new Date(x.created_at).toLocaleDateString()}</div>
                                    </Comment.Metadata>
                                    <div css="margin-top:10px;"><Rating maxRating={5} rating={x.rating} /></div>
                                    <Comment.Text>
                                        {x.text}
                                    </Comment.Text>
                                    <ImageGallery images={x.images || []}/>
                                </Comment.Content>
                            </Comment>
                        </>
                    )
                })}

                {/* {allow_review && <Form style={{ paddingTop: "10px", borderTop: "1px solid #dfdfdf" }} onSubmit={handleSubmit} reply>
                    <div className="ui field">
                        <label>Rating: </label><Rating defaultRating={0} maxRating={5} onRate={handleRate} />
                    </div>
                    <Form.TextArea label="Text" required onChange={handleTextChange} />
                    <Button color="teal" circular content='Add Review' labelPosition='left' icon='edit' />
                </Form>} */}
            </Comment.Group>
        </>
    )
}

export default ReviewsTab