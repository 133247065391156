import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Divider, Form, Icon, Message, Modal } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { printIntrospectionSchema } from 'graphql'
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { login, logout, userActions,loginWithToken } from '../../store/user-slice'
import PasswordChecklist from "react-password-checklist"
import LoginForm from './loginForm'
import TokenForm from './tokenForm'
import RegisterForm from './registerForm'
import RegisterAsBusiness from './registerAsBusiness'
import RegisterAsFreelancer from './registerAsFreelancer'
import RegisterAsCustomer from './registerAsCustomer'
import { uiActions } from '../../store/ui-slice'


const SignInModal = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [t, i18n] = useTranslation("global")
    const [action, setAction] = useState("login")
    const [state,setState]=useState({email:"",password:""})
    const { userInfo } = useSelector(state => state.user)
    const {uiSignInModal}=useSelector(state=>state.ui)
    if (userInfo) setOpen(false)
    //useEffect(() => { console.log(state)}, [state])

    

    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={() => {setOpen(false);dispatch(uiActions.change_action_sign_in({open:false,action:""}));dispatch(userActions.clearError())}}
                onOpen={() => { setOpen(true); setAction("login");setState({email:"",password:""}) }}
                open={open || uiSignInModal.open}
                trigger={<Button basic color="teal" /*onClick={() => { history.push("/login") }}*/ css="flex-shrink:0;margin: 0 10px !important;" circular content={t('navbar.sign_in')} />}
                //closeIcon={true}
                size="tiny"
                className="customSignInModalClass"

            >
                <Modal.Content>
                    <div css="margin-top:40px;"></div>
                    {
                        (() => {
                            switch (uiSignInModal.action || action) {
                                case "login":
                                    return <LoginForm setState={setState} changeAction={(type) => setAction(type)} />
                                case "register":
                                    return <RegisterForm changeAction={(type) => setAction(type)} />
                                case "register_as_business":
                                    return <RegisterAsBusiness changeAction={(type) => setAction(type)} />
                                case "register_as_freelancer":
                                    return <RegisterAsFreelancer />
                                case "register_as_customer":
                                    return <RegisterAsCustomer />
                                case "token":
                                    return <TokenForm state={state} />
                            }
                        })()
                    }
                </Modal.Content>
            </Modal>
        </>
    )
}


export default SignInModal