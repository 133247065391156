import React, { useEffect, useState } from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { showProduct, showProductCard, showProductDiv, showProductOver, showProductWrap } from '../styles'
import { Dimmer, Form, Loader, Select, Tab, List, Rating, Icon, Image, Search } from 'semantic-ui-react'
import axios from '../../axios'
import { useHistory } from 'react-router'
import { useGetLocationName, useGetProductLanguages } from '../../shared/custom_hooks'


const TabCata = ({ active, values, images, businessD, tabimage2, id }) => {
    const [t, i18n] = useTranslation("global")
    const history = useHistory()
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadings, setLoadings] = useState(false)
    const [categories, setCategories] = useState([])
    const [catfilter, setcatfilter] = useState("all")
    const [txtfilter, settxtfilter] = useState("")
    const [opciones, setopciones] = useState([])
    const [results, setresults] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const getLocationName = useGetLocationName()
    const getProductLanguages = useGetProductLanguages()

    const loadData = async () => {
        try {
            let { data: businessProducts } = await axios.post("/api/product/readAllOpen", {
                vendor_id: id
            })
            let { data: categories } = await axios.get("/api/category/business/")
            setCategories(categories.filter(x => businessProducts.find(y => y.category_id == x._id)))
            setProducts(businessProducts)
            setFilteredProducts(businessProducts)
            let arr1 = businessProducts.map(x => [x.name, ...x.tags.split(",").filter(x => x)])
            let arr2 = []
            for (let i = 0; i < arr1.length; i++) {
                for (let j = 0; j < arr1[i].length; j++) {
                    arr2.push(arr1[i][j])
                }
            }

            setopciones([...new Set(arr2)])
            setLoading(false)
        } catch (e) {
            alert(e)
        }
    }
    useEffect(() => {
        let f = products.filter(x => {
            return x.name.match(new RegExp('^' + txtfilter, 'i')) || x.tags.includes(txtfilter)
        })
        if (catfilter != "all") {
            f = f.filter(x => {
                return x.category_id == catfilter
            })
        }
        setFilteredProducts(f)
    }, [txtfilter, catfilter])
    const handleChange = (e) => {
        settxtfilter(e)
        //setresults([... new Set(opciones.filter(x=>x.match(new RegExp('^' + e, 'i'))).map(x=>{return {title:x}}))])
        setresults(e != "" ? opciones.filter(x => x.match(new RegExp('^' + e, 'i'))).map(x => { return { title: x } }) : [])
    }
    useEffect(() => { console.log(results) }, [results])
    if (loading) {
        return <div className="py-5"><Dimmer active inverted>
            <Loader />
        </Dimmer></div>
    }
    return (
        <>

            <div className="ui grid container">

                <div class="four wide column"></div>

                <div className="twelve wide column">
                    {/*<div className="ui icon input" css="width:100%; margin:0 0.5rem;">
                        <input type="text" placeholder="Search..." css="border-radius:100px !important;" onChange={handleChange} />
                        <i className="search icon"></i>
                        </div>*/}
                    <Search
                        fluid
                        className="customSearchInputClass"
                        loading={loadings}
                        onResultSelect={(e, data) => {
                            //history.push("/search/" + (data.result.title) + (props.qp || ""))
                            //console.log(data.result.title)
                            handleChange(data.result.title)
                        }}
                        onSearchChange={(e, data) => {
                            handleChange(data.value)
                        }}
                        results={results}
                        //defaultValue={props.term || ""}
                        showNoResults={false}
                    //onKeyPress={handleKeyPress}
                    //value={value}
                    />
                </div>
            </div>

            <div class="ui internally celled grid">
                <div class="row">
                    <div class=" sixteen wide mobile four wide computer  column">
                        <div css="overflow-y:scroll;">
                            <div>
                                <a onClick={(e) => { e.preventDefault(); setcatfilter("all") }} href="#" css="padding:10px 0;display:block;color:black;&:hover{color:black;}">{t("profile.All_Products")}</a>
                            </div>
                            {categories.map((x) => {
                                return (
                                    <div>
                                        <a href="#" onClick={(e) => { e.preventDefault(); setcatfilter(x._id) }} css="padding:10px 0;display:block;color:black;&:hover{color:black;}">{x.name}</a>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div class=" sixteen wide mobile twelve wide computer column">

                        <div className="ui grid container">
                            <div className="sixteen wide column" css="box-shadow:none;">
                                <div className="ui four column grid container">
                                    {filteredProducts.map((item) => {
                                        return (
                                            <div className="sixteen wide mobile five wide tablet four wide computer column" css="cursor:pointer;" onClick={() => { history.push("/product/" + item._id) }}>
                                                <div css={showProduct}>
                                                    <div css={showProductWrap}>
                                                        <div css={showProductDiv}>
                                                            <div css={showProductCard(item.images[0])}>

                                                                {item.images[0] && <div css={showProductOver}></div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div css="font-size:16px;font-weight:bold;">{item.name}</div>
                                                <div >{item.vendor_name}</div>
                                                <div >${item.sale_price}</div>
                                                {item.location && <p css="margin:0;">{getLocationName(item.location)}</p>}
                                                {item.languages?.length > 0 && <p css="margin:0;">{getProductLanguages(item.languages)}</p>}
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="sixteen wide column"></div>



        </>

    )
}

export default TabCata




