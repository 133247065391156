import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Dimmer, Dropdown, Form, Icon, Input, Label, Loader, Message, Modal, Popup, Segment, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'

import country, { parseXML, uspscountry } from '../countries'
import { useSelector } from 'react-redux'
import VolumePricing from './volume_pricing'
import { url_environment } from '../../config'



const ProductModalAdmin = (props) => {
    const [t, i18n] = useTranslation("global")
    const [file, setFile] = useState(undefined)
    const [file_info, setFile_info] = useState("")
    const [taxcodes, settaxcodes] = useState([])
    const [taxLoading, setTaxLoading] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [dfloading, setdfloading] = useState(false)
    const handleDownloadFile = async () => {
        setdfloading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: url } = await axios.post("/api/product/downloadFileAdmin", {
                file: props.data.file
            }, config)
            window.open(url, '_blank');
        } catch (e) { alert(e) }
        setdfloading(false)
    }
    const taxCategories = useMemo(() => {
        return [
            { key: 1, text: t("profile.digital_goods"), value: 1 },
            { key: 2, text: t("profile.freight"), value: 2 },
            { key: 3, text: t("profile.other"), value: 3 },
            { key: 4, text: t("profile.tangible_personal_property"), value: 4 },
        ]
    }, [])
    const taxSubCategories = useMemo(() => {
        return [
            [
                { key: 1, value: 1, text: t("avalara_subcategories.Digital goods") },
                { key: 2, value: 2, text: t("avalara_subcategories.Digital audio works") },
                { key: 3, value: 3, text: t("avalara_subcategories.Digital books") },
                { key: 4, value: 4, text: t("avalara_subcategories.Computer software (business-to-business)") },
                { key: 5, value: 5, text: t("avalara_subcategories.Computer software (business-to-customer)") },
                { key: 6, value: 6, text: t("avalara_subcategories.Computer software - (prewritten/canned) physical media (business-to-customer)") },
                { key: 7, value: 7, text: t("avalara_subcategories.Computer software - (prewritten/canned) electronically downloaded (business-to-customer)") },
                { key: 8, value: 8, text: t("avalara_subcategories.Digital goods: games") },
                { key: 9, value: 9, text: t("avalara_subcategories.Digital images") },
                { key: 10, value: 10, text: t("avalara_subcategories.Digital goods: mailing lists") },
                { key: 11, value: 11, text: t("avalara_subcategories.Digital goods: movies") },
                { key: 12, value: 12, text: t("avalara_subcategories.Digital goods: music") },
                { key: 13, value: 13, text: t("avalara_subcategories.Digital goods: photographs") },
                { key: 14, value: 14, text: t("avalara_subcategories.Digital goods: videos") },
                { key: 15, value: 15, text: t("avalara_subcategories.Digital audio visual works") },
            ],
            [
                { key: 16, value: 16, text: t("avalara_subcategories.Delivery by company vehicle") },
                { key: 17, value: 17, text: t("avalara_subcategories.Shipping only (not paid directly to common carrier)") },
            ],
            [
                { key: 18, value: 18, text: t("avalara_subcategories.Other") },
                { key: 19, value: 19, text: t("avalara_subcategories.Admissions") },
                { key: 20, value: 20, text: t("avalara_subcategories.Dues/membership fee") },
            ],
            [
                { key: 21, value: 21, text: t("avalara_subcategories.Tangible personal property (tpp)") },
                { key: 22, value: 22, text: t("avalara_subcategories.Agricultural") },
                { key: 23, value: 23, text: t("avalara_subcategories.Livestock") },
                { key: 24, value: 24, text: t("avalara_subcategories.Accessories") },
                { key: 25, value: 25, text: t("avalara_subcategories.Automotive products and supplies") },
                { key: 26, value: 26, text: t("avalara_subcategories.Bicycles, tricycles, motorcycles and similar equipment, and parts") },
                { key: 27, value: 27, text: t("avalara_subcategories.Books / manuals") },
                { key: 28, value: 28, text: t("avalara_subcategories.Boats and boat accessories") },
                { key: 29, value: 29, text: t("avalara_subcategories.Checkout bags") },
                { key: 30, value: 30, text: t("avalara_subcategories.Clothing and related products (business-to-business)") },
                { key: 31, value: 31, text: t("avalara_subcategories.Clothing and related products (business-to-customer)") },
                { key: 32, value: 32, text: t("avalara_subcategories.Computer hardware (business-to-business)") },
                { key: 33, value: 33, text: t("avalara_subcategories.Computer hardware (business-to-customer)") },
                { key: 34, value: 34, text: t("avalara_subcategories.Cable head-end equipment") },
                { key: 35, value: 35, text: t("avalara_subcategories.Distribution equipment") },
                { key: 36, value: 36, text: t("avalara_subcategories.Energy efficient equipment") },
                { key: 37, value: 37, text: t("avalara_subcategories.Energy efficient supplies") },
                { key: 38, value: 38, text: t("avalara_subcategories.Restaurant industry - equipment") },
                { key: 39, value: 39, text: t("avalara_subcategories.Restaurant industry - smallwares") },
                { key: 40, value: 40, text: t("avalara_subcategories.Restaurant industry - kitchen supplies") },
                { key: 41, value: 41, text: t("avalara_subcategories.Firearms") },
                { key: 42, value: 42, text: t("avalara_subcategories.Flags") },
                { key: 43, value: 43, text: t("avalara_subcategories.Food and food ingredients (non-prepared foods) - sold by qualified food retailer") },
                { key: 44, value: 44, text: t("avalara_subcategories.Food combo packs") },
                { key: 45, value: 45, text: t("avalara_subcategories.Food and food ingredients-health food (specialty, nutritional supplement)") },
                { key: 46, value: 46, text: t("avalara_subcategories.Prepared foods") },
                { key: 47, value: 47, text: t("avalara_subcategories.Fuels") },
                { key: 48, value: 48, text: t("avalara_subcategories.Fireworks") },
                { key: 49, value: 49, text: t("avalara_subcategories.Gift baskets") },
                { key: 50, value: 50, text: t("avalara_subcategories.Apparel & linens") },
                { key: 51, value: 51, text: t("avalara_subcategories.Beds, furniture & accessories") },
                { key: 52, value: 52, text: t("avalara_subcategories.Blood, tissue & blood supplies") },
                { key: 53, value: 53, text: t("avalara_subcategories.Casting supplies") },
                { key: 54, value: 54, text: t("avalara_subcategories.Dental supplies and equipment") },
                { key: 55, value: 55, text: t("avalara_subcategories.Drugs & medicines") },
                { key: 56, value: 56, text: t("avalara_subcategories.Drugs & medicines - solutions & chemicals") },
                { key: 57, value: 57, text: t("avalara_subcategories.Food - supplements, nutrients, & supplies") },
                { key: 58, value: 58, text: t("avalara_subcategories.Kits, packs & trays") },
                { key: 59, value: 59, text: t("avalara_subcategories.Medical equipment - diagnostic") },
                { key: 60, value: 60, text: t("avalara_subcategories.Medical equipment - general") },
                { key: 61, value: 61, text: t("avalara_subcategories.Medical supplies - catheters") },
                { key: 62, value: 62, text: t("avalara_subcategories.Medical supplies - diabetic") },
                { key: 63, value: 63, text: t("avalara_subcategories.Medical supplies - dialysis") },
                { key: 64, value: 64, text: t("avalara_subcategories.Medical supplies - general") },
                { key: 65, value: 65, text: t("avalara_subcategories.Medical supplies - needles & syringes") },
                { key: 66, value: 66, text: t("avalara_subcategories.Medical supplies - respiratory") },
                { key: 67, value: 67, text: t("avalara_subcategories.Medical supplies - wound closure") },
                { key: 68, value: 68, text: t("avalara_subcategories.Oral care") },
                { key: 69, value: 69, text: t("avalara_subcategories.Prosthetics & orthotics - implanted") },
                { key: 70, value: 70, text: t("avalara_subcategories.Prosthetics & orthotics - non implanted") },
                { key: 71, value: 71, text: t("avalara_subcategories.Skin & hygiene") },
                { key: 72, value: 72, text: t("avalara_subcategories.Skin & wound care") },
                { key: 73, value: 73, text: t("avalara_subcategories.Supplies - non-medical") },
                { key: 74, value: 74, text: t("avalara_subcategories.Surgical equipment & supplies") },
                { key: 75, value: 75, text: t("avalara_subcategories.Health care products - drugs for human use") },
                { key: 76, value: 76, text: t("avalara_subcategories.Health care products-durable medical equipment") },
                { key: 77, value: 77, text: t("avalara_subcategories.Health care products-mobility enhancing equipment") },
                { key: 78, value: 78, text: t("avalara_subcategories.Health care products-prosthetic devices") },
                { key: 79, value: 79, text: t("avalara_subcategories.Health care products-grooming /other") },
                { key: 80, value: 80, text: t("avalara_subcategories.Health care products-over the counter-medicinal group 1 (drug facts and /or ingredients are included when deem to be a medicine, for home use)") },
                { key: 81, value: 81, text: t("avalara_subcategories.Health care products-over the counter-medicinal group 2- (drug facts and /or ingredients are included when deem to be a medicine, for home use)") },
                { key: 82, value: 82, text: t("avalara_subcategories.Other medical products") },
                { key: 83, value: 83, text: t("avalara_subcategories.Home items") },
                { key: 84, value: 84, text: t("avalara_subcategories.Hurricane preparedness supplies") },
                { key: 85, value: 85, text: t("avalara_subcategories.Ice") },
                { key: 86, value: 86, text: t("avalara_subcategories.Machinery") },
                { key: 87, value: 87, text: t("avalara_subcategories.Magazines (not second class mail matter / not controlled circulation publication)") },
                { key: 88, value: 88, text: t("avalara_subcategories.Paper products for household use") },
                { key: 89, value: 89, text: t("avalara_subcategories.Pet and pet supplies") },
                { key: 90, value: 90, text: t("avalara_subcategories.Veterinary products, pet - medical supplies") },
                { key: 91, value: 91, text: t("avalara_subcategories.Veterinary products, pet - drugs & medicine") },
                { key: 92, value: 92, text: t("avalara_subcategories.Capital lease") },
                { key: 93, value: 93, text: t("avalara_subcategories.School related products (business-to-business)") },
                { key: 94, value: 94, text: t("avalara_subcategories.School related products (business-to-customer)") },
                { key: 95, value: 95, text: t("avalara_subcategories.Sewing supplies (non-commercial use)") },
                { key: 96, value: 96, text: t("avalara_subcategories.Smoking products") },
                { key: 97, value: 97, text: t("avalara_subcategories.Telecommunication machinery & equipment") },
                { key: 98, value: 98, text: t("avalara_subcategories.Infant supplies") },
            ]
        ]
    })

    const { userInfo } = useSelector(state => state.user)
    const [values, setValues] = useState({
        product_name: "",
        product_description: "",
        product_category: "",
        id_category: "",
        default_category: "",
        price: "",
        quantity: "",
        instructions: "",
        shop_policies: "",
        tags: "",
        width: "",
        height: "",
        length: "",
        weight: "",
        materials: "",
        images: [],
        countryfrom: "US",
        countryto: "US",
        countrytousps: "US",
        cityfrom: "",
        cityto: "",
        addressfrom: "",
        addressto: "",
        weightdimension: "LBS/IN",
        declaredvalue: "",
        pounds: "",
        ounces: "",
        date: "",
        dhl: [],
        other: [],
        taxcode: "",
        tax_category: "",
        tax_sub_category: "",
        volume_pricing: [],
        location:"",
        languages:[]
    })

    const [inputList, setInputList] = useState([{

        country: "",
        d_time: "",
        d_time2: "",
        one_item: "",
        additional_item: ""

    }])
    const [inputListOther, setInputListOther] = useState([{
        shipping_company: "",
        country: "",
        d_time: "",
        d_time2: "",
        one_item: "",
        additional_item: ""

    }])

    const handleInputChange2 = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }
    const handleInputChange2Other = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputListOther];
        list[index][name] = value;
        setInputListOther(list);
    }

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    }
    const handleRemoveClickOther = index => {
        const list = [...inputListOther];
        list.splice(index, 1);
        setInputListOther(list);
    }

    const handleAddClick = () => {
        setInputList([...inputList, {
            country: "",
            d_time: "",
            d_time2: "",
            one_item: "",
            additional_item: ""
        }])
    }
    const handleAddClickOther = () => {
        setInputListOther((p) => [...p, {
            shipping_company: "",
            country: "",
            d_time: "",
            d_time2: "",
            one_item: "",
            additional_item: ""
        }])
    }

    const handleTaxCategoryChange = (e, { value }) => {
        setSelectedTaxSubCategory(taxSubCategories[value - 1])
    }
    const handleTaxSubCategoryChange = async (e, { value }) => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setTaxLoading(true)
        try {
            const { data } = await axios.get("/api/user/taxcodes?parent=" + value, config)
            settaxcodes([{ key: "P0000000", value: "P0000000", text: t("profile.other_product") }, ...data.map(x => ({ key: x.taxCode, text: i18n.language == "en" ? x.description : x.description_es, value: x.taxCode }))])
        } catch (e) { alert(e) }
        setTaxLoading(false)
    }

    const fileref1 = useRef()
    const fileref2 = useRef()
    const fileref3 = useRef()
    const fileref4 = useRef()
    const fileref5 = useRef()
    const fileref6 = useRef()

    const [ShowConfirmDelete, setShowConfirmDelete] = useState(false)
    const [ShowConfirmPublish, setShowConfirmPublish] = useState(false)
    const [RateError, setRateError] = useState(false)
    const [Loading, setLoading] = useState(true)
    const [rateLoading, setRateLoading] = useState(false)
    const [CategoryLoading, setCategoryLoading] = useState(false)
    const [CategoryData, setCategoryData] = useState([])
    const [ProductImage1, setProductImage1] = useState(false)
    const [ProductImage2, setProductImage2] = useState(false)
    const [ProductImage3, setProductImage3] = useState(false)
    const [ProductImage4, setProductImage4] = useState(false)
    const [ProductImage5, setProductImage5] = useState(false)
    const [ProductImage6, setProductImage6] = useState(false)
    const [selectedTaxSubCategory, setSelectedTaxSubCategory] = useState([])
    const [Shipper, setShipper] = useState("ups")
    const [totalRate, setTotalRate] = useState([])

    const handleInputChange = (e) => {
        // setValues(prev => { return { ...prev, [e.target.name]: e.target.value } })

    }
    const nodigital = () => {
        return values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca"
    }

    const handleCategoryChange = (e, { value }) => {
        setValues(prev => { return { ...prev, product_category: value.name, id_category: value._id } })
    }
    const pass = () => {
        if (values.product_name == "") return false;
        if (values.product_description == "") return false;
        if (values.product_category == "") return false;
        if (values.id_category == ("" || "undefined")) return false;
        if (values.instructions == "") return false;
        if (values.shop_policies == "") return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca" && values.materials == "") return false;
        if (values.tags == "") return false;
        if (values.price == "") return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca" && values.quantity == "") return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca" && values.width == "") return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca" && values.height == "") return false;
        if (values.taxcode == "") return false;
        if (values.id_category == "60b5264c40b9d41dae531f96" && (!file_info)) return false;
        if (values.id_category == "6182f72014e83501939ba8ca" && (!file_info)) return false;
        if (values.id_category == "60a672fa4efcc56a787c7f1e" && (!file_info)) return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca" && (Shipper == "ups" || Shipper == "usps") && values.length == "") return false;
        if (values.id_category != "60b5264c40b9d41dae531f96" && values.id_category != "60a672fa4efcc56a787c7f1e" && values.id_category != "6182f72014e83501939ba8ca") {
            if (Shipper == "ups" && values.weight == "") return false;
            if (Shipper == "usps" && values.pounds == "") return false;
            if (Shipper == "dhl" && !checkdhl()) return false;
        }
        let price = (parseFloat((+values.price) - ((+values.price) * (userInfo.seller.category_rate / 100))) - 1.40)
        if (price <= 0) {
            return false
        }
        return true
    }

    const checkdhl = () => {
        const d = [...inputList]
        console.log(inputList)
        for (let i = 0; i < d.length; i++) {
            if (d[i].country == "") return false;
            if (d[i].d_time == "") return false;
            if (d[i].d_time2 == "") return false;
            if (d[i].one_item == "") return false;
            if (d[i].additional_item == "") return false;
        }
        return true
    }

    const handleSubmit = async () => {
        if (!pass()) { alert("Please fill all required fields"); return }
        try {
            setLoading(true)
            let final_weight = 0
            if (Shipper === "ups") {
                final_weight = values.weight
                final_weight = !final_weight ? 0 : final_weight
                final_weight = parseFloat(final_weight)
            } else if (Shipper === "usps") {
                let p = parseFloat(values.pounds)
                let o = parseFloat(values.ounces)
                p = (p * 16) + o
                final_weight = parseFloat(p / 16)
            }
            let final_weight_dimension = "LBS/IN"
            if (Shipper === "ups") {
                final_weight_dimension = values.weightdimension
            } else if (Shipper === "usps") {
                final_weight_dimension = "LBS/IN"
            }


            let formData = new FormData();
            formData.append("name", values.product_name)
            formData.append("description", values.product_description)
            formData.append("category_name", values.product_category)
            formData.append("category_id", values.id_category)
            formData.append("sale_price", values.price)
            formData.append("volume_pricing", JSON.stringify(values.volume_pricing))
            // formData.append("stock", values.quantity)
            formData.append("instructions", values.instructions)
            formData.append("shop_policies", values.shop_policies)
            formData.append("tags", values.tags)
            formData.append("materials", values.materials)
            formData.append("width", values.width)
            formData.append("height", values.height)
            formData.append("length", values.length)
            formData.append("taxcode", values.taxcode)
            formData.append("weight", final_weight.toFixed(2))
            formData.append("vendor_name", userInfo.seller.business_name)
            formData.append("vendor_type", "Business")
            formData.append("file", file);
            formData.append("file1", ProductImage1);
            formData.append("file2", ProductImage2);
            formData.append("file3", ProductImage3);
            formData.append("file4", ProductImage4);
            formData.append("file5", ProductImage5);
            formData.append("file6", ProductImage6);
            if (values.id_category == "60b5264c40b9d41dae531f96" || values.id_category == "60a672fa4efcc56a787c7f1e" || values.id_category == "6182f72014e83501939ba8ca") {
                formData.append("shipper", "notapply");
                formData.append("stock", "100")
            } else {
                formData.append("shipper", Shipper);
                formData.append("stock", values.quantity)
            }
            formData.append("weight_dimension", final_weight_dimension);
            formData.append("dhl", JSON.stringify(inputList));
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/product", formData, config)
            setLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleAddCategory = async (e, { value }) => {
        try {
            setCategoryLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/product/category/add", {
                name: value
            }, config)
            setCategoryLoading(false)
            console.log(res)
            setCategoryData(prev => [...prev, { key: res.data._id, text: res.data.name, value: res.data }])
        } catch (e) {
            alert(e)
            setCategoryLoading(false)
        }
    }

    const handleSubmitUp = async () => {
        if (!pass()) { alert("Please fill all required fields"); return }
        try {
            setLoading(true)

            let final_weight = 0
            if (Shipper === "ups") {
                final_weight = values.weight
                final_weight = !final_weight ? 0 : final_weight
                final_weight = parseFloat(final_weight)
            } else if (Shipper === "usps") {
                let p = parseFloat(values.pounds || 0)
                let o = parseFloat(values.ounces || 0)
                p = (p * 16) + o
                final_weight = parseFloat(p / 16)
            }
            //final_weight=(""+final_weight).toFixed(2)
            //alert(final_weight)
            let final_weight_dimension = "LBS/IN"
            if (Shipper === "ups") {
                final_weight_dimension = values.weightdimension
            } else if (Shipper === "usps") {
                final_weight_dimension = "LBS/IN"
            }


            let formData = new FormData();
            formData.append("name", values.product_name)
            formData.append("description", values.product_description)
            formData.append("category_name", values.product_category)
            formData.append("category_id", values.id_category)
            formData.append("sale_price", values.price)
            formData.append("volume_pricing", JSON.stringify(values.volume_pricing))
            formData.append("instructions", values.instructions)
            formData.append("shop_policies", values.shop_policies)
            formData.append("tags", values.tags)
            formData.append("materials", values.materials)
            formData.append("width", values.width)
            formData.append("height", values.height)
            formData.append("length", values.length)
            formData.append("taxcode", values.taxcode)
            formData.append("weight", final_weight.toFixed(2))
            formData.append("file", file);
            formData.append("file1", ProductImage1 || props.data.images[0]);
            formData.append("file2", ProductImage2 || props.data.images[1]);
            formData.append("file3", ProductImage3 || props.data.images[2]);
            formData.append("file4", ProductImage4 || props.data.images[3]);
            formData.append("file5", ProductImage5 || props.data.images[4]);
            formData.append("file6", ProductImage6 || props.data.images[5]);
            if (values.id_category == "60b5264c40b9d41dae531f96" || values.id_category == "60a672fa4efcc56a787c7f1e" || values.id_category == "6182f72014e83501939ba8ca") {
                formData.append("shipper", "notapply");
                formData.append("stock", "100")
            } else {
                formData.append("shipper", Shipper);
                formData.append("stock", values.quantity)
            }
            formData.append("weight_dimension", final_weight_dimension);
            formData.append("dhl", JSON.stringify(inputList));
            formData.append("id", props.data._id)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.put("/api/product", formData, config)
            setLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const dimension_measurement = ((Shipper == "dhl" || Shipper == "other" || Shipper == "usps" || Shipper == "notapply") || (Shipper == "ups" && (values.weightdimension == "LBS/IN" || values.weightdimension == "OZS/IN"))) ? "IN" : "CM"
    const weight_measurement = ((Shipper == "dhl" || Shipper == "other" || Shipper == "usps" || Shipper == "notapply") || (Shipper == "ups" && (values.weightdimension == "LBS/IN"))) ? "LBS" : (values.weightdimension == "OZS/IN" ? "OZS" : (values.weightdimension == "KGS/CM" && "KGS"))

    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.get("/api/category/business", config)

            setCategoryData(res.data.filter(c => c._id != "60a672fa4efcc56a787c7f22").map(item => { return { key: item._id, text: item.name, value: item } }).sort((a, b) => (a.text[0] > b.text[0] ? 1 : -1)))

            let final_pounds = 0
            let wd = props.data.weight_dimension || "LBS/IN"
            if (wd === "LBS/IN") {
                final_pounds = props.data.weight
            } else if (wd === "KGS/CM") {
                let w = !props.data.weight ? 0 : props.data.weight
                final_pounds = w * 2.205
                final_pounds = final_pounds.toFixed(2)
            }


            if (props.data !== true) {
                setValues(p => ({
                    ...p,
                    product_name: props.data.name,
                    product_description: props.data.description,
                    product_category: props.data.category_name,
                    id_category: props.data.category_id,
                    default_category: res.data.filter((i) => { return i._id == props.data.category_id })[0],
                    price: props.data.sale_price,
                    quantity: props.data.stock,
                    instructions: props.data.instructions,
                    shop_policies: props.data.shop_policies,
                    tags: props.data.tags,
                    materials: props.data.materials,
                    width: props.data.width || "0",
                    height: props.data.height || "0",
                    length: props.data.length || "0",
                    weight: props.data.weight || "0",
                    images: props.data.images,
                    countryfrom: "US",
                    countryto: "US",
                    countrytousps: "US",
                    weightdimension: props.data.weight_dimension || "LBS/IN",
                    pounds: final_pounds,
                    ounces: "0",
                    date: "",
                    volume_pricing: props.data.volume_pricing,
                    tax_category: parseInt(props.data.tax_category) || "",
                    tax_sub_category: parseInt(props.data.tax_sub_category) || "",
                    taxcode: props.data.tax_code || "",
                    location: props.data.location || "",
                    languages:props.data.languages || []
                }))
                setFile_info(props.data.file ? props.data.file : "")
                setShipper(props.data.shipper || "ups")
                if (props.data.dhl.length > 0)
                    setInputList(props.data.dhl)
                if (props.data.other?.length > 0)
                    setInputListOther(props.data.other)
                if (props.data.tax_category) {
                    setSelectedTaxSubCategory(taxSubCategories[props.data.tax_category - 1])
                }
                if (props.data.tax_sub_category) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                    setTaxLoading(true) 
                    try {
                        const { data } = await axios.get("/api/user/taxcodes?parent=" + props.data.tax_sub_category, config)
                        settaxcodes([{ key: "P0000000", value: "P0000000", text: t("profile.other_product") }, ...data.map(x => ({ key: x.taxCode, text: i18n.language == "en" ? x.description : x.description_es, value: x.taxCode }))])
                    } catch (e) { alert(e) }
                    setTaxLoading(false)
                }
            }


            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }

    }

    const getServiceName = (num) => {
        if (num === "01") return "UPS Next Day Air"
        if (num === "02") return "UPS 2nd Day Air"
        if (num === "03") return "UPS Ground"
        if (num === "07") return "UPS Worldwide Express"
        if (num === "08") return "UPS Worldwide Expedited"
        if (num === "11") return "UPS Standard"
        if (num === "12") return "UPS 3 Day Select"
        if (num === "13") return "UPS Next Day Air Saver"
        if (num === "14") return "UPS Next Day Air Early AM"
        if (num === "54") return "UPS Worldwide Express Plus"
        if (num === "59") return "UPS Second Day Air AM"
        if (num === "65") return "UPS Worldwide Saver"
        if (num === "M2") return "UPS First-Class Mail"
        if (num === "M3") return "UPS Priority Mail"
        if (num === "M4") return "UPS Expedited Mail Innovations"
        if (num === "M5") return "UPS Priority Mail Innovations"
        if (num === "M6") return "UPS Economy Mail Innovations"
        return "Unknown"
    }

    const handleGetRate = async (e) => {
        e.preventDefault();
        if (values.width.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.height.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.length.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.weight.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.zipfrom.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.zipto.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.cityfrom.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.cityto.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.addressfrom.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.addressto.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.weightdimension.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        //if (values.declaredvalue.toString() === "") return
        try {
            setRateLoading(true)
            let res = await axios.post("/calculator", {
                mvalue: values.price ? values.price.toString() : "0",
                addressto: values.addressto,
                addressfrom: values.addressfrom,
                cityto: values.cityto,
                cityfrom: values.cityfrom,
                dimensionMeasurement: values.weightdimension.split("/")[1],
                weightMeasurement: values.weightdimension.split("/")[0],
                zipfrom: values.zipfrom,
                zipto: values.zipto,
                countryfrom: values.countryfrom,
                countryto: values.countryto,
                width: values.width.toString(),
                height: values.height.toString(),
                length: values.length.toString(),
                weight: values.weight.toString()
            })
            //console.log(res.data)
            setRateLoading(false)
            setRateError(false)
            setTotalRate(Array.isArray(res.data.RateResponse.RatedShipment) ? res.data.RateResponse.RatedShipment.map((i) => { return { name: getServiceName(i.Service.Code), total: `${i.TotalCharges.CurrencyCode} ${i.TotalCharges.MonetaryValue}` } }) : [{ name: getServiceName(res.data.RateResponse.RatedShipment.Service.Code), total: `${res.data.RateResponse.RatedShipment.TotalCharges.CurrencyCode} ${res.data.RateResponse.RatedShipment.TotalCharges.MonetaryValue}` }])
        } catch (e) {
            if (typeof e.response.data === "string") {
                setRateError(e.response.data)
            } else {
                setRateError(e.response.data[0].message)
            }
            setRateLoading(false)
            setTotalRate([])
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0] || undefined
        setFile(file)
        setFile_info(file.name)
    }

    const handleGetRateUSPS = async (e) => {
        e.preventDefault();
        if (values.width.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.height.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.length.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.pounds.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.ounces.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.zipfrom.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.zipto.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        if (values.date.toString() === "") { setRateError("Please fill required fields"); setTotalRate([]); return; }
        //if (values.declaredvalue.toString() === "") return
        try {
            setRateLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const res = await axios.post("/api/calculator/usps", {
                "price": values.price ? values.price.toString() : "0",
                "zipfrom": values.zipfrom.trim(),
                "zipto": values.zipto.trim(),
                "width": values.width.toString(),
                "height": values.height.toString(),
                "length": values.length.toString(),
                "pounds": values.pounds.toString(),
                "ounces": values.ounces.toString(),
                "countrytousps": values.countrytousps,
            }, config)
            //console.log(res.data)
            setRateLoading(false)
            setRateError(false)
            const [error, arr] = parseXML(values.countrytousps, res)
            setTotalRate(arr)
            if (error) setRateError(error)
            //console.log(res)
        } catch (e) {
            console.log(e)
            //setRateError(e.response.data[0].message)
            setRateLoading(false)
            setTotalRate([])
        }
    }

    const handleCheckboxChange = (name, checked, value) => {
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, [name]: [...prev[name], value] }
            } else {
                arr = prev[name].filter((i) => { return i !== value });
                return { ...prev, [name]: arr }
            }
        })
    }

    const product_location=useMemo(()=>{
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...country].find(c=>c.key===values.location)?.text||""
    },[values])

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="large"
            >
                <Modal.Header>Details</Modal.Header>
                <Modal.Content>
                    {
                        Loading ? <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                            : (<>
                                {/* <p>{t("profile.you_cannot_publish")}</p> */}
                                <div className="ui grid container" css="justify-content:space-between">
                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                        <div className="ui form">
                                            <div className="field">
                                                <label>{t("profile.Product_Name")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <input readOnly value={values.product_name} onChange={handleInputChange} type="text" name="product_name" />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Product_description")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <textarea readOnly value={values.product_description} onChange={handleInputChange} name="product_description"></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Product_category")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                {/*<input readOnly value={values.product_category} onChange={handleInputChange} type="text" name="product_category" />*/}
                                                <Dropdown
                                                    selection
                                                    search
                                                    fluid
                                                    loading={CategoryLoading}
                                                    //value={currentValue}
                                                    options={CategoryData}
                                                    defaultValue={values.default_category}
                                                    onChange={handleCategoryChange}
                                                />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Photos_of_product")}</label>
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref1} type="file" hidden onChange={(e) => { setProductImage1(e.target.files[0] || false); props.testv[0] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref2} type="file" hidden onChange={(e) => { setProductImage2(e.target.files[0] || false); props.testv[1] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref3} type="file" hidden onChange={(e) => { setProductImage3(e.target.files[0] || false); props.testv[2] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref4} type="file" hidden onChange={(e) => { setProductImage4(e.target.files[0] || false); props.testv[3] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref5} type="file" hidden onChange={(e) => { setProductImage5(e.target.files[0] || false); props.testv[4] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <input readOnly accept="image/jpeg, image/png" ref={fileref6} type="file" hidden onChange={(e) => { setProductImage6(e.target.files[0] || false); props.testv[5] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                                <div className="ui three column grid">
                                                    <div className="column">
                                                        <div onClick={() => fileref1.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage1 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[0]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[0] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[0].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref2.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage2 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[1]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[1] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[1].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref3.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage3 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[2]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[2] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[2].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref4.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage4 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[3]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[3] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[3].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref5.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage5 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[4]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[4] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[4].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref6.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_photo")}</span>
                                                            <i className="huge camera icon"></i>
                                                            {
                                                                ProductImage6 ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.testv[5]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : props.data.images && props.data.images[5] && (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[5].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                )

                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Price")}  </label>
                                                <div class="ui left labeled input">
                                                    <div class="ui basic label">
                                                        $
                                                    </div>
                                                    <input readOnly value={values.price} onChange={handleInputChange} type="number" name="price" />
                                                </div>
                                            </div>
                                            {nodigital() && <div className="field">
                                                <label>{t("profile.Quantity")} <span css="font-size:10px;color:#db2828;margin-left:6px;"></span> <span>({t("profile.how_many_item")})</span></label>
                                                <input readOnly value={values.quantity} onChange={handleInputChange} type="number" name="quantity" />
                                            </div>}

                                            {(values.id_category == "60b5264c40b9d41dae531f96" || values.id_category == "60a672fa4efcc56a787c7f1e" || values.id_category == "6182f72014e83501939ba8ca" || values.id_category == "60a672fa4efcc56a787c7f27") && <>
                                                <div className="ui required field">
                                                    <label>{t("profile.upload_file")}</label>
                                                    <label style={{ marginBottom: "20px" }} class="ui labeled icon button" htmlFor="custom_button_upload_image">
                                                        <i class="file icon"></i>
                                                        <input readOnly
                                                            id="custom_button_upload_image"
                                                            type="file"
                                                            accept={values.id_category == "60a672fa4efcc56a787c7f1e" ? "image/*" : (values.id_category == "60b5264c40b9d41dae531f96" ? "application/pdf" : "audio/*")}
                                                            onChange={handleFileChange}
                                                            css="display:none;"
                                                        />
                                                        <span style={{ wordBreak: "break-all" }}>{t("profile.file_name")}{file_info && ":"} {file_info && file_info}</span>
                                                    </label>
                                                </div>
                                                <Button loading={dfloading} onClick={handleDownloadFile} content="Download file" color="teal" style={{ display: "block", margin: "0 auto 15px auto" }} />
                                            </>}
                                            <VolumePricing onChange={handleInputChange} values={values.volume_pricing} />

                                        </div>
                                    </div>
                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                        <form className="ui form">
                                            <div className="field">
                                                <label>{t("profile.Instructions")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <textarea readOnly value={values.instructions} onChange={handleInputChange} name="instructions" placeholder={t("profile.explain_any_customization")}></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Shop_policies")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <textarea readOnly value={values.shop_policies} onChange={handleInputChange} name="shop_policies" placeholder={t("profile.shipping_information_returns")}></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Location")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <input readOnly value={product_location} onChange={handleInputChange} type="text" name="location" />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Languages")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <div css="display:flex;align-items:center;margin-top:20px;">
                                                    <input checked={values.languages.indexOf("English") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="English" type="checkbox" name="languages" id="english-language-radio" /><label css="margin-left:5px;" htmlFor="english-language-radio">{t("profile.english")}</label>
                                                </div>
                                                <div css="display:flex;align-items:center;">
                                                    <input checked={values.languages.indexOf("Spanish") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Spanish" type="checkbox" name="languages" id="spanish-language-radio" /><label css="margin-left:5px;" htmlFor="spanish-language-radio">{t("profile.spanish")}</label>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Tags")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <input readOnly value={values.tags} onChange={handleInputChange} type="text" name="tags" />
                                            </div>
                                            {nodigital() && <div className="field">
                                                <label>{t("profile.Materials")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                <input readOnly value={values.materials} onChange={handleInputChange} type="text" name="materials" />
                                            </div>}
                                            {nodigital() && <div className="two fields">
                                                <div className="required field">
                                                    <label>{t("profile.Width")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                    <div class="ui left labeled input">
                                                        <div class="ui basic label">
                                                            {dimension_measurement}
                                                        </div>
                                                        <input readOnly value={values.width} onChange={handleInputChange} type="number" name="width" />
                                                    </div>
                                                </div>
                                                <div className="required field">
                                                    <label>{t("profile.Height")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                    <div class="ui left labeled input">
                                                        <div class="ui basic label">
                                                            {dimension_measurement}
                                                        </div>
                                                        <input readOnly value={values.height} onChange={handleInputChange} type="number" name="height" />
                                                    </div>
                                                </div>
                                            </div>}
                                            <p>{t("profile.these_units_measurements")}</p>
                                            <div>
                                                <Message
                                                    attached
                                                    header={t("profile.select_tax_code")}
                                                    content={t("profile.select_correct_tax_code_pro")}
                                                />
                                                <div className='ui form attached fluid segment'>
                                                    <Form.Select
                                                        label={t("profile.category")}
                                                        options={taxCategories}
                                                        onChange={handleTaxCategoryChange}
                                                        value={values.tax_category}
                                                    />
                                                    <Form.Select
                                                        search
                                                        label={t("profile.sub_category")}
                                                        options={selectedTaxSubCategory}
                                                        onChange={handleTaxSubCategoryChange}
                                                        value={values.tax_sub_category}
                                                    />
                                                    <Form.Select
                                                        search
                                                        required
                                                        label={t("profile.product")}
                                                        options={taxcodes}
                                                        loading={taxLoading}
                                                        onChange={(e, { value }) => handleInputChange({ target: { name: "taxcode", value } })}
                                                        value={values.taxcode}
                                                    />
                                                    <div className="ui field">
                                                        <label >{t("profile.tax_code")}</label>
                                                        <div>{values.taxcode}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                                {nodigital() && <Card style={{ width: "100%" }}>
                                    <Card.Content header={t("profile.shipping_rates_calculator")} />
                                    <Card.Content extra>
                                        <Select
                                            label={t('profile.countryfrom')}
                                            //options={Categories.map((i, c) => { return { name: i.name, value: i.value, key: c } })}
                                            options={[{ key: "ups", text: "UPS", value: "ups" }, { key: "usps", text: "USPS", value: "usps" }, { key: "dhl", text: "DHL", value: "dhl" }, { key: "other", text: "Other", value: "other" }, { key: "notapply", text: t("profile.notapply"), value: "notapply" }]}
                                            defaultValue={Shipper}
                                            onChange={(e, { value }) => { setShipper(value); setRateError(false); setTotalRate([]) }}
                                        />
                                        {
                                            Shipper === "ups" ? (
                                                <div className="ui grid container" css="justify-content:space-between;margin-top:5px !important;">
                                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                                        <form className="ui form">
                                                            <Form.Select
                                                                required
                                                                fluid
                                                                search
                                                                label={t('profile.countryfrom')}
                                                                //options={Categories.map((i, c) => { return { name: i.name, value: i.value, key: c } })}
                                                                options={country}
                                                                defaultValue={values.countryfrom}
                                                                onChange={(e, { value }) => { handleInputChange({ target: { name: "countryfrom", value } }) }}
                                                            />
                                                            <div className="required field">
                                                                <label>{t("profile.Cityfrom")}</label>
                                                                <input readOnly required value={values.cityfrom} onChange={handleInputChange} type="text" name="cityfrom" />
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.zipfrom")}</label>
                                                                <input readOnly value={values.zipfrom} onChange={handleInputChange} type="text" name="zipfrom" />
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.Addressfrom")}</label>
                                                                <input readOnly value={values.addressfrom} onChange={handleInputChange} type="text" name="addressfrom" />
                                                            </div>
                                                            <div /*css="display:flex;align-items:flex-end;margin-top:100%;"*/>
                                                                <div style={{ color: "black" }}>
                                                                    <span style={{ color: "red" }}>*</span>
                                                                    {t("profile.required_fields")}
                                                                </div>
                                                            </div>

                                                            {
                                                                RateError && (
                                                                    <Message negative>
                                                                        <Message.Header>{RateError}</Message.Header>
                                                                    </Message>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                                        <form className="ui form">
                                                            <Form.Select
                                                                required
                                                                fluid
                                                                search
                                                                label={t('profile.countryto')}
                                                                //options={Categories.map((i, c) => { return { name: i.name, value: i.value, key: c } })}
                                                                options={country}
                                                                defaultValue={values.countryto}
                                                                onChange={(e, { value }) => { handleInputChange({ target: { name: "countryto", value } }) }}
                                                            />
                                                            <div className="required field">
                                                                <label>{t("profile.Cityto")}</label>
                                                                <input readOnly value={values.cityto} onChange={handleInputChange} type="text" name="cityto" />
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.zipto")}</label>
                                                                <input readOnly value={values.zipto} onChange={handleInputChange} type="text" name="zipto" />
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.Addressto")}</label>
                                                                <input readOnly value={values.addressto} onChange={handleInputChange} type="text" name="addressto" />
                                                            </div>
                                                            <Form.Select
                                                                fluid
                                                                label={t('profile.WeightDimension')}
                                                                defaultValue={values.weightdimension}
                                                                options={[{ key: 1, text: "LBS/IN", value: "LBS/IN" }, { key: 0, text: "KGS/CM", value: "KGS/CM" }, { key: 2, text: "OZS/IN", value: "OZS/IN" }]}
                                                                onChange={(e, { value }) => { handleInputChange({ target: { name: "weightdimension", value } }) }}
                                                            />

                                                            <div className="required field">
                                                                <label>{t("profile.Length")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                                <div class="ui left labeled input">
                                                                    <div class="ui basic label">
                                                                        {dimension_measurement}
                                                                    </div>
                                                                    <input readOnly value={values.length} onChange={handleInputChange} type="number" name="length" />
                                                                </div>
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.Weight")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                                <div class="ui left labeled input">
                                                                    <div class="ui basic label">
                                                                        {weight_measurement}
                                                                    </div>
                                                                    <input readOnly value={values.weight} onChange={handleInputChange} type="number" name="weight" />
                                                                </div>
                                                            </div>

                                                            <div css="display:flex;align-items:start;justify-content:space-between;max-width:900px;">
                                                                <div>
                                                                    {totalRate.map((i) => {
                                                                        return <div style={{ margin: "5px 0", color: "black" }}>
                                                                            <b>{i.name}</b>: {i.total}
                                                                        </div>
                                                                    })}
                                                                </div>
                                                                <Button color="teal" loading={rateLoading} circular onClick={handleGetRate}>{t("profile.get_estimated_rate")}</Button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            ) : Shipper === "usps" ? (
                                                <div className="ui grid container" css="justify-content:space-between;margin-top:5px !important;">
                                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                                        <form className="ui form">
                                                            <Form.Select
                                                                required
                                                                fluid
                                                                search
                                                                label={t('profile.countryto')}
                                                                options={uspscountry}
                                                                defaultValue={values.countrytousps}
                                                                onChange={(e, { value }) => { handleInputChange({ target: { name: "countrytousps", value } }) }}
                                                            />
                                                            <div className="two fields">
                                                                <div className="required field">
                                                                    <label>{t("profile.zipfrom")}</label>
                                                                    <input readOnly value={values.zipfrom} onChange={handleInputChange} type="text" name="zipfrom" />
                                                                </div>
                                                                <div className="required field">
                                                                    <label>{t("profile.zipto")}</label>
                                                                    <input readOnly value={values.zipto} onChange={handleInputChange} type="text" name="zipto" />
                                                                </div>
                                                            </div>
                                                            <div /*css="display:flex;align-items:flex-end;margin-top:100%;"*/>
                                                                <div style={{ color: "black" }}>
                                                                    <span style={{ color: "red" }}>*</span>
                                                                    Required fields
                                                                </div>
                                                            </div>

                                                            {
                                                                RateError && (
                                                                    <Message negative>
                                                                        <Message.Header>{RateError}</Message.Header>
                                                                    </Message>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                                        <form className="ui form">


                                                            <div className="required field">
                                                                <label>{t("profile.Length")}</label>
                                                                <div class="ui left labeled input">
                                                                    <div class="ui basic label">
                                                                        {dimension_measurement}
                                                                    </div>
                                                                    <input readOnly value={values.length} onChange={handleInputChange} type="number" name="length" />
                                                                </div>
                                                            </div>
                                                            <div className="two fields">
                                                                <div className="required field">
                                                                    <label>{t("profile.Pounds")}<span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>
                                                                    <input readOnly value={values.pounds} onChange={handleInputChange} type="number" name="pounds" />
                                                                </div>
                                                                <div className="required field">
                                                                    <label>{t("profile.Ounces")}</label>
                                                                    <input readOnly value={values.ounces} onChange={handleInputChange} type="number" name="ounces" />
                                                                </div>
                                                            </div>
                                                            <div className="required field">
                                                                <label>{t("profile.Date")}</label>
                                                                <input readOnly value={values.date} onChange={handleInputChange} type="date" name="date" />
                                                            </div>
                                                            <div css="display:flex;align-items:start;justify-content:space-between;max-width:900px;">
                                                                <div>
                                                                    {totalRate.map((i) => {
                                                                        return <div style={{ margin: "5px 0", color: "black" }}>
                                                                            <b>{i.name}</b>: {i.total}
                                                                        </div>
                                                                    })}
                                                                </div>
                                                                <Button color="teal" loading={rateLoading} circular onClick={handleGetRateUSPS}>Get estimated rate</Button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            ) : Shipper === "dhl" ? (
                                                <div className="ui grid container " style={{ marginTop: "10px", marginBottom: "6px" }} css="width: 100% !important;max-width: 100% !important; margin:10px;margin-top:15px; ">
                                                    {inputList.map((x, i) => {
                                                        return (

                                                            <Segment css="width:100%;">

                                                                <div className="ui grid container">
                                                                    <div className="twelve wide mobile six wide tablet four wide computer column">
                                                                        <Form.Select
                                                                            search
                                                                            fluid
                                                                            label={<label>{t("profile.Country")} <span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label>}
                                                                            placeholder={t("profile.select_country")}
                                                                            options={country}
                                                                            value={x.country}
                                                                            // onChange={(e, { value }) => { handleInputChange({ target: { name: "country", value } }); }}
                                                                            onChange={(a, e) => { console.log(e); handleInputChange2({ target: { name: "country", value: e.value } }, i) }}
                                                                        />
                                                                    </div>

                                                                    <div className=" two wide mobile  ten wide tablet  one wide computer column"> </div>

                                                                    <div className="fourteen wide mobile  six wide tablet  five wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.Delivery_time")} <span css="font-size:10px;color:#db2828;margin-left:6px;"></span><span css="font-size:10px;margin-left:6px;">{t("profile.business_days")}</span></label><br />
                                                                        <Input readOnly size='small' type="number" value={x.d_time} name="d_time" onChange={e => handleInputChange2(e, i)} />
                                                                    </div>

                                                                    <div className="two wide mobile  one wide tablet  one wide computer column"><br />
                                                                        <span><h2>-</h2></span>
                                                                    </div>

                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        {/* <label css="font-weight:700;">{t("profile.What_you")} </label><br />
                                                                        <Input readOnly size='small' type="text" value={x.charge} name="charge" onChange={e => handleInputChange2(e, i)} /> */}
                                                                        <label css="font-weight:700;"><span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label><br />
                                                                        <Input readOnly size='small' type="number" value={x.d_time2} name="d_time2" onChange={e => handleInputChange2(e, i)} />
                                                                    </div>


                                                                    <div className="  sixteen wide mobile three wide tablet five wide computer column"></div>


                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.One_item")} <span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label><br />
                                                                        {/* <Input readOnly size='small' type="text" value={x.one_item} name="one_item" onChange={e => handleInputChange2(e, i)} /> */}
                                                                        <Input readOnly iconPosition='left' size='small' type="text" value={x.one_item} name="one_item" onChange={e => handleInputChange2(e, i)}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}>
                                                                            <Icon name='dollar' />
                                                                            <input readOnly />
                                                                        </Input>
                                                                    </div>
                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.Additional_item")} <span css="font-size:10px;color:#db2828;margin-left:6px;"></span></label><br />
                                                                        {/* <Input readOnly size='small' type="text" value={x.additional_item} name="additional_item" onChange={e => handleInputChange2(e, i)} /> */}
                                                                        <Input readOnly iconPosition='left' size='small' type="text" value={x.additional_item} name="additional_item" onChange={e => handleInputChange2(e, i)}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}>
                                                                            <Icon name='dollar' />
                                                                            <input readOnly />
                                                                        </Input>
                                                                    </div>

                                                                    <div className="  sixteen wide mobile one wide tablet one wide computer column"></div>

                                                                    <div className="  sixteen wide mobile three wide tablet two wide computer column"><br />
                                                                        <Button.Group>
                                                                            {inputList.length !== 1 &&
                                                                                <Button icon onClick={() => handleRemoveClick(i)}>
                                                                                    <Icon name='trash' />
                                                                                </Button>}
                                                                        </Button.Group>{' '}
                                                                        <Button.Group>
                                                                            {inputList.length - 1 === i &&
                                                                                <Button icon onClick={handleAddClick}>
                                                                                    <Icon name='plus' />
                                                                                </Button>}
                                                                        </Button.Group>
                                                                    </div>
                                                                </div>

                                                            </Segment>

                                                        );
                                                    })}

                                                </div>
                                            ) : Shipper === "other" && (<>
                                                <div className="ui grid container " style={{ marginTop: "10px", marginBottom: "6px" }} css="width: 100% !important;max-width: 100% !important; margin:10px;margin-top:15px; ">
                                                    {inputListOther.map((x, i) => {
                                                        return (

                                                            <Segment css="width:100%;">

                                                                <div className="ui grid container">
                                                                    <div className="twelve wide mobile six wide tablet four wide computer column">
                                                                        <Form.Select
                                                                            search
                                                                            fluid
                                                                            label={<label>{t("profile.Country")} <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>}
                                                                            placeholder={t("profile.select_country")}
                                                                            options={country}
                                                                            value={x.country}
                                                                            // onChange={(e, { value }) => { handleInputChange({ target: { name: "country", value } }); }}
                                                                            onChange={(a, e) => { console.log(e); handleInputChange2Other({ target: { name: "country", value: e.value } }, i) }}
                                                                        />
                                                                    </div>

                                                                    <div className=" two wide mobile  ten wide tablet  one wide computer column"> </div>

                                                                    <div className="fourteen wide mobile  six wide tablet  five wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.Delivery_time")} <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span><span css="font-size:10px;margin-left:6px;">{t("profile.business_days")}</span></label><br />
                                                                        <Input size='small' type="number" value={x.d_time} name="d_time" onChange={e => handleInputChange2Other(e, i)} />
                                                                    </div>

                                                                    <div className="two wide mobile  one wide tablet  one wide computer column"><br />
                                                                        <span><h2>-</h2></span>
                                                                    </div>

                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        {/* <label css="font-weight:700;">{t("profile.What_you")} </label><br />
                                                                        <Input size='small' type="text" value={x.charge} name="charge" onChange={e => handleInputChange2Other(e, i)} /> */}
                                                                        <label css="font-weight:700;"><span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label><br />
                                                                        <Input size='small' type="number" value={x.d_time2} name="d_time2" onChange={e => handleInputChange2Other(e, i)} />
                                                                    </div>


                                                                    <div className="  sixteen wide mobile three wide tablet five wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.shipping_company")} <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label><br />
                                                                        {/* <Input size='small' type="text" value={x.additional_item} name="additional_item" onChange={e => handleInputChange2Other(e, i)} /> */}
                                                                        <Input type="text" value={x.shipping_company} name="shipping_company" onChange={e => handleInputChange2Other(e, i)}
                                                                        >

                                                                            <input />
                                                                        </Input>
                                                                    </div>


                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.One_item")} <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label><br />
                                                                        {/* <Input size='small' type="text" value={x.one_item} name="one_item" onChange={e => handleInputChange2Other(e, i)} /> */}
                                                                        <Input iconPosition='left' size='small' type="text" value={x.one_item} name="one_item" onChange={e => handleInputChange2Other(e, i)}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}>
                                                                            <Icon name='dollar' />
                                                                            <input />
                                                                        </Input>
                                                                    </div>
                                                                    <div className=" sixteen wide mobile  six wide tablet  four wide computer column">
                                                                        <label css="font-weight:700;">{t("profile.Additional_item")} <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label><br />
                                                                        {/* <Input size='small' type="text" value={x.additional_item} name="additional_item" onChange={e => handleInputChange2Other(e, i)} /> */}
                                                                        <Input iconPosition='left' size='small' type="text" value={x.additional_item} name="additional_item" onChange={e => handleInputChange2Other(e, i)}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}>
                                                                            <Icon name='dollar' />
                                                                            <input />
                                                                        </Input>
                                                                    </div>

                                                                    <div className="  sixteen wide mobile one wide tablet one wide computer column"></div>

                                                                    <div className="  sixteen wide mobile three wide tablet two wide computer column"><br />
                                                                        <Button.Group>
                                                                            {inputListOther.length !== 1 &&
                                                                                <Button icon onClick={() => handleRemoveClickOther(i)}>
                                                                                    <Icon name='trash' />
                                                                                </Button>}
                                                                        </Button.Group>{' '}
                                                                        <Button.Group>
                                                                            {inputListOther.length - 1 === i &&
                                                                                <Button icon onClick={handleAddClickOther}>
                                                                                    <Icon name='plus' />
                                                                                </Button>}
                                                                        </Button.Group>
                                                                    </div>
                                                                </div>

                                                            </Segment>

                                                        );
                                                    })}

                                                </div>
                                            </>)
                                        }


                                    </Card.Content>
                                </Card>}
                            </>
                            )
                    }

                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;">
                        <Button css="background:rgb(0,128,129) !important;" circular color="teal" onClick={() => props.onApprove(props.data._id)}>
                            Approve
                        </Button>
                        <Button circular color="red" onClick={() => setShowRejectModal(true)}>
                            Reject
                        </Button>
                    </div>

                </Modal.Actions>

            </Modal>
            {showRejectModal && <ConfirmReject onReject={(reason) => props.onReject(props.data._id, reason)} onClose={() => setShowRejectModal(false)} />}
        </>
    )
}

export default ProductModalAdmin



const ConfirmReject = (props) => {
    const [reason, setReason] = useState("")

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="small"
            >
                <Modal.Header>Reject</Modal.Header>
                <Modal.Content>
                    <div className="ui form">
                        <div className="ui field">
                            <label htmlFor="">Reason</label>
                            <input type="text" onChange={(e) => setReason(e.target.value)} />
                        </div>

                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;max-width:400px;margin:0 auto;">
                        <Button circular color="teal" onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button circular color="red" onClick={() => props.onReject(reason)}>
                            Reject
                        </Button>
                    </div>

                </Modal.Actions>
            </Modal>
        </>
    )
}