import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Form, Loader, Message } from 'semantic-ui-react'
import Footer from '../footer/footer'
import Navbar from '../navbar/navbar'
import "styled-components/macro"
import axios from '../../axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { getTimel } from '../../config'
import { useTranslation } from 'react-i18next';

const NotificationForm = () => {
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [palert, setPalert] = useState("")
    const [numNotif, setNumNotif] = useState(0)
    const [valid, setValid] = useState("")
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState("")
    const history = useHistory()
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    console.log("i18n: ",i18n.language)
    const alertOptions = useRef([
        { key: 0, text: "New product alert", value: "new-product" },
        { key: 1, text: "Product on-sale alert", value: "product-on-sale" },
        { key: 2, text: "Store-wide sale alert", value: "store-wide-sale" },
        { key: 3, text: "New service alert", value: "new-service" }
    ])
    const loadData = async () => {
        setLoading(true)
        try {

            let { data: products } = await axios.get("/api/product/my", config)
            setProducts(products.map(x => { return { key: x._id, text: (x.name || x.service_name), value: x._id } }))
            let { data: inapp } = await axios.get("/api/user/notification/count", config)
            setNumNotif(inapp.notifications)
            setValid(getTimel(inapp.end_date, true, i18n.language))

            if (inapp.notifications == 0) {
                history.push("/")
            }
            if (userInfo.id_role == "2") {
                alertOptions.current.splice(3, 1)
            } else if (userInfo.id_role == "3") {
                alertOptions.current.splice(0, 2)
            }
            setPalert(alertOptions.current[0].value)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])


    const handleNotificationChange = (e, { value }) => {
        setPalert(value)
        if (value == "store-wide-sale") {
            setSelectedProduct("none")
        }
    }
    const timeout = useRef()
    const handleProductChange = (e, { value }) => {
        setSelectedProduct(value)
    }
    const handleSend = async () => {
        if (palert == "" || (palert != "store-wide-sale" && (selectedProduct == "" || selectedProduct == "none"))) {
            alert("Please fill all fields!")
            return
        }
        setLoading(true)
        try {
            await axios.post("/api/user/notification", {
                product_id: selectedProduct == "none" ? undefined : selectedProduct,
                type: palert
            }, config)
            setSuccess(true)
            setNumNotif(p => p - 1)
            clearTimeout(timeout.current)
            timeout.current = setTimeout(() => { setSuccess(false) }, 5000)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <main>

            <div css="max-width:1200px;margin:0 auto;padding:0 36px;">
                <div css="max-width:400px;margin:0 auto;">
                    <h2 css="text-align:center;">In-app notifications form</h2>
                    <h3 css="text-align:center;">{t("marketing_packages.you_have_not_left",{num_notif:numNotif,valid_notif:valid})}</h3>
                    <div className="ui form">
                        <Form.Select
                            label="Select alert"
                            options={alertOptions.current}
                            value={palert}
                            onChange={handleNotificationChange}
                        />
                        {(palert == "new-product" || palert == "product-on-sale") && (
                            <Form.Select
                                label="Select product"
                                options={products}
                                value={selectedProduct}
                                onChange={handleProductChange}
                            />
                        )}
                        {(palert == "new-service") && (
                            <Form.Select
                                label="Select service"
                                options={products}
                                value={selectedProduct}
                                onChange={handleProductChange}
                            />
                        )}
                        <Button disabled={numNotif == 0} onClick={handleSend} circular color="teal" fluid>{t("marketing_packages.send")}</Button>
                        {numNotif == 0 && <Link className="ui circular fluid teal button" style={{ margin: "14px 0" }} to="/">{t("marketing_packages.return_to_home_page")}</Link>}
                        {numNotif == 0 && <Link className="ui circular fluid teal button" to="/marketing-packages">{t("marketing_packages.buy_new_marketing_package")}</Link>}
                    </div>
                    {success &&
                        <Message
                            success
                            header={t("marketing_packages.notification_sent_successful")}
                        />
                    }
                </div>
            </div>
            </main>

            <Footer />
        </>
    )
}

export default NotificationForm