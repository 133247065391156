import Navbar from '../navbar/navbar'
import Footer from '../footer/footer'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import 'styled-components/macro'
import { Link } from 'react-router-dom'
import styles from './marketing-packages.styles'
import useMyHook from './marketing-packages.hook.js'

const MarketingPackages = () => {
    const { t, loading, count } = useMyHook()
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Navbar />
            <main>

            <div css={styles}>
                <Link className="ui teal circular button" to="/marketing-packages/mypurchases">{t("marketing_packages.view_my_purchases")}</Link>
                {count > 0 && <Link className="ui teal circular button" style={{ marginLeft: "5px" }} to="/notification-form">{t("marketing_packages.inapp_notifications_form")}</Link>}
                <section css="margin:10px 0;">
                    <h2 className="my-heading2">{t("marketing_packages.inapp_notification")}</h2>
                    <div className="my-row">
                        <div className="my-column ">
                            <div className="my-inner-div">
                                <h2>US$0.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.one_inapp_notification_para")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/in-app-notifications?opt=1"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="my-column my-column-center">
                            <div className="my-inner-div">
                                <h2>US$2.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.five_inapp_notification_para")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/in-app-notifications?opt=2"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="my-column ">
                            <div className="my-inner-div">
                                <h2>US$4.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.eight_inapp_notification_para")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/in-app-notifications?opt=3"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section css="margin:10px 0;">
                    <h2 className="my-heading2">{t("marketing_packages.social_media")}</h2>
                    <div className="my-row">
                        <div className="my-column ">
                            <div className="my-inner-div">
                                <h2>US$2.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.social_media_para")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/social-media"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section css="margin:10px 0;">
                    <h2 className="my-heading2">{t("marketing_packages.interpowered_homepage")}</h2>
                    <h3 className="my-heading2" style={{ textDecoration: "underline" }}>{t("marketing_packages.pay_per_day")}.</h3>
                    <div className="my-row">
                        <div className="my-column ">
                            <div className="my-inner-div">
                                <h2>US$1.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.feature_your_product_one")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/homepage?type=day&opt=1"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="my-column border-left:none;">
                            <div className="my-inner-div">
                                <h2>US$2.99</h2>
                                <p className="my-inner-p"> {t("marketing_packages.your_product_only_day")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/homepage?type=day&opt=2"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="my-heading2" style={{ textDecoration: "underline" }}>{t("marketing_packages.pay_per_week")}.</h3>
                    <div className="my-row">
                        <div className="my-column ">
                            <div className="my-inner-div">
                                <h2>US$4.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.feature_your_product_four")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/homepage?type=week&opt=1"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="my-column border-left:none;">
                            <div className="my-inner-div">
                                <h2>US$7.99</h2>
                                <p className="my-inner-p">{t("marketing_packages.your_product_only_week")}.</p>
                                <div css="margin-top:auto;">
                                    <Link to="/marketing-packages/homepage?type=week&opt=2"><Button circular color="teal">{t("marketing_packages.buy_now")}</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            </main>

            <Footer />
        </>
    )
}

export default MarketingPackages
