export const homeStyles = `

    .findSection{
        background-color:rgb(0,128,129);
        padding:2rem 0;
        color:#fff;

        .sectionStyles{
            width:100%;
            max-width:1320px;
            margin:0 auto;

            .h2FindStyles{
                color:white;
                text-align:center;
                font-weight:500;
                margin-bottom:1rem;
            }

            .imgWrapper{
                display:flex;
                align-items:center;
                flex-wrap:no-wrap;
                justify-content:space-around;
            }
            .productSection-lg{
                display:flex;
                flex-direction:column;
                align-items:center;
                flex-basis:17%;
            }
            .productImg{
                border-radius:50%;
                width:160px;
                height:160px;
                object-fit:cover;
                object-position:center;
                background-color:rgb(179,248,245);
            }
        }
    }

    .dailySection{
        max-width:1400px;
        margin:0 auto;
        padding:2rem 36px;

        .dailyWrapper{
            display:flex;
            flex-wrap:wrap;
            justify-content:flex-start;

            .imgWrapper{
                flex:0 0 auto;
                width:16.66666%;
                padding:9px;
                position:relative;
                &:hover{
                    &  .heartBtn{
                        display:flex;
                    }
                }
                @media(max-width:960px){
                    width:25%;
                    &  .heartBtn{
                        display:flex; 
                    }
                } 
                @media(max-width:700px){
                    width:33.33333%;
                }
                @media(max-width:520px){
                    width:50%;
                }
                @media(max-width:320px){
                    width:100%;
                }
                .bigImg{
                    border-radius:8px;
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    object-fit:cover;
                    object-position:center;
                }

                .h3Text{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    min-width: 0;
                    margin:0;
                }
            }
            .imgStyles{
                border-radius:5px;
                width:160px;
                height:160px;
                object-fit:cover;
                object-position:center;
                background-color:rgb(179,248,245);
            }
        }
    }

    .container{
        width:100%;
        max-width:1320px;
        margin:0 auto;
    }

`

