import React from 'react'
import 'styled-components/macro'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import { useTranslation } from 'react-i18next'

const TabAbout = ({ values, images }) => {
    const [t, i18n] = useTranslation("global")
    const src = 'https://react.semantic-ui.com/images/wireframe/square-image.png'
    return (
        <>
            <div className="ui grid container">

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Business's_name")}: </label>
                    <span>{values.name_of_business}</span>
                </div>

                <div className="ten wide column">
                    <label css="font-weight:700;" >{t("profile.Business_sector")}: </label>
                    <span>{values.business_sector}</span>
                </div>

                <div className="six wide column">
                    <label css="font-weight:700;">{t("profile.Services")}: </label>
                    {/* <span>{values. service_offered}</span> */}

                    {values.service_offered && values.service_offered.split(",").map((se) => {
                        return <>
                            <span> / {se.trim()}</span>
                            {/* <div class="">{se.trim()}</div> */}
                        </>
                    })}

                </div>

                <div className="ten wide column">
                    <label css="font-weight:700;">{t("profile.Business_Category")}: </label>
                    <span>{values.category_name}</span>


                </div>
                <div className="six wide column">
                    <label css="font-weight:700;">{t("profile.Products_for_sale")}: </label>
                    <span>{values.products_for_sale}</span>

                </div>

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Business_description")}: </label>
                    {/* <span>{values.business_description}</span> */}

                </div>

                <div css="white-space:pre-wrap;">{values.business_description}</div>

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Business_website")}: </label>
                    <span>{values.link_to_website}</span>

                </div>

                <div className="sixteen wide column">
                    <ImageGallery items={images} showPlayButton={false} showBullets={false} autoPlay={false} showFullscreenButton={false}/>


                </div>



            </div>






        </>

    )
}

export default TabAbout




