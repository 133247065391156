import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Checkbox, Form } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { login } from '../../store/user-slice'
import countries from '../countries'
import { formData } from '../formDataCreator'
import TermsModal from './termsModal'



const RegisterAsCustomer = () => {
    const [t, i18n] = useTranslation("global")
    const [formLoading, setFormLoading] = useState(false)
    const [showAccept, setShowAccept] = useState(false)
    const { registerInfo } = useSelector(state => state.user)
    const [older, setOlder] = useState(false)
    const [TP, setTP] = useState(false)
    const [emailMessage, setEmailMessage] = useState(false)
    const [values, setValues] = useState({
        country: "United States",
        id_country: "US",
        city: "",
        address: "",
        phone: "",
        zip: "",
    })
    const dispatch = useDispatch()
    const handleChange = (e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }
    const handleSelectChange = (e, { value }) => {
        setValues(p => {
            return {
                ...p,
                id_country: value,
                country: countries.find(x => x.value === value).text
            }
        })

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleAccept()
        // setFormLoading(true)
        // const temp={...values,...registerInfo}
        // temp.image=imageName
        // if(!temp.image) delete temp.image
        // try{
        //     await axios.post("/api/user/register",formData(temp))
        //     dispatch(login(registerInfo.email, registerInfo.password, ""));
        // }catch(e){alert(e)}
        // setFormLoading(false)
    }
    const handleAccept = async () => {
        setFormLoading(true)
        setShowAccept(false)
        const temp = { ...values, ...registerInfo }
        temp.id_language = i18n.language
        temp.image = imageName
        if (!temp.image) delete temp.image
        try {
            await axios.post("/api/user/register", formData(temp))
            setEmailMessage(true)
            // dispatch(login(registerInfo.email, registerInfo.password, ""));
        } catch (e) { alert(e) }
        setFormLoading(false)
    }
    const [imageName, setImageName] = useState(undefined)

    if (emailMessage) {
        return <>
            <h3>{t("email_verification_sent_1")}</h3>
            <p>{t("email_verification_sent_2")}<b>{registerInfo.email}</b></p>
            <p>{t("email_verification_sent_3")}</p>
        </>
    }

    return (
        <>
            <Form loading={formLoading} onSubmit={handleSubmit} className="ui form" >
                <Form.Select
                    search
                    options={countries}
                    defaultValue={values.id_country}
                    fluid
                    selectOnBlur={false}
                    label={t('navbar.country')}
                    onChange={handleSelectChange}
                />
                <Form.Input onChange={handleChange} name="city"
                    required fluid label={t('navbar.city')} type="text" />
                {/* <Form.Input onChange={handleChange} name="address"
                    required fluid label={t('navbar.address')} type="text" /> */}
                <Form.Input onChange={handleChange} required fluid label={t('navbar.phone')} name="phone" type="text" />
                {/* <Form.Input onChange={handleChange} required fluid label={t('navbar.zip')} name="zip" type="text" /> */}
                <div className="ui required field">
                    <label htmlFor="">{t('navbar.address')}</label>
                    <input required onChange={handleChange} name="address" type="text" />
                    {/* <p >{t("navbar.please_enter_address")}</p> */}
                </div>
                <div className="ui required field">
                    <label htmlFor="">{t('navbar.zip')}</label>
                    <input required onChange={handleChange} name="zip" type="text" />
                    <p >{t("navbar.please_enter_zip")}</p>
                </div>
                <label style={{ marginBottom: "20px" }} class="ui labeled icon button" htmlFor="custom_button_upload_image">
                    <i class="image icon"></i>
                    <input
                        id="custom_button_upload_image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => { e.target.files[0] && setImageName(e.target.files[0]) }}
                        css="display:none;"
                    />
                    {t('navbar.cus_image')}{imageName && ":"} {imageName && imageName.name}
                </label>
                <div className="ui field">
                    <Checkbox
                        label={t("navbar.users_older_account")}
                        onChange={(e, { checked }) => { setOlder(checked) }}
                    />
                </div>
                <div className="ui field">
                    {i18n.language === "en" && <>
                        <Checkbox
                            label={<label>I accept the <a target="_blank" href="/terms-of-service">terms and conditions</a> and the <a target="_blank" href="/privacy-policy">privacy policy</a></label>}
                            onChange={(e, { checked }) => { setTP(checked) }}
                        />
                    </>}
                    {i18n.language === "es" && <>
                        <Checkbox
                            label={<label>Acepto los <a target="_blank" href="/terms-of-service">términos y condiciones</a> y la <a target="_blank" href="/privacy-policy">política de privacidad</a></label>}
                            onChange={(e, { checked }) => { setTP(checked) }}
                        />
                    </>}
                </div>
                <Form.Button disabled={!older || !TP} style={{ borderRadius: "200px", background: "rgb(0,128,129)" }} fluid type="submit" secondary content={t('navbar.continue')} />
            </Form>
            {/* <TermsModal accept={handleAccept} open={showAccept} close={() => setShowAccept(false)} /> */}
        </>
    )
}

export default RegisterAsCustomer