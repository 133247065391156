import React, { useEffect, useState } from 'react'
import { Button, Container, Dimmer, Divider, Dropdown, Form, Grid, Header, Icon, Input, Label, Loader, Menu, Modal, Pagination, Popup, Segment, Select, Table } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import axios from '../../axios'
import { useHistory } from 'react-router'
import FreelancerCategories from './freelancerCategoriesAdmin'
import BusinessCategories from './businessCategoriesAdmin'
import { categoryButtonStyles, containerDivStyles, containerStyle, emailTableStyle, inputFilterStyles, logoStyle, menuStyles, stConStyNo, stConStyYes, tabDropStyles } from './styles'
import Navbar from '../../shared/navbar/navbar'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import UpdateEmailAdmin from "../../shared/signInModal/updateEmailAdmin"

const Administration = () => {
    const [OpenModal, setOpenModal] = useState(false)
    const [OpenModalDelete, setOpenModalDelete] = useState(false)
    const [userdelete, setUserdelete] = useState(false)
    const [OpenFreelancerCategories, setOpenFreelancerCategories] = useState(false)
    const [OpenBusinessCategories, setOpenBusinessCategories] = useState(false)
    const [manage_account_segment, set_manage_account_segment] = useState(1)
    const [ModalData, setModalData] = useState({})
    const [Loading, setLoading] = useState(false)
    const [Loading2, setLoading2] = useState(false)
    const [LoadingModal, setLoadingModal] = useState(false)
    const [FilterSelect, setFilterSelect] = useState("all")
    const [FilterInput, setFilterInput] = useState("")
    const [Ch, setCh] = useState(0)
    const [MainData, setMainData] = useState([])
    const [StoreData, setStoreData] = useState([])
    const [UsersData, setUsersData] = useState([])
    const [index, setindex] = useState(0)
    const [con, setCon] = useState("all")
    const [del, setDel] = useState("not deleted")
    const [typ, setTyp] = useState("all")
    const { userInfo } = useSelector(state => state.user)
    let history = useHistory()
    const splitArray = (arr, n) => {
        let arr2 = [], arr3 = []
        let i = -1, j = -1;
        for (let i = 0; i < arr.length; i++) {
            j++
            arr2.push(arr[i])
            if (j === (n - 1)) {
                j = -1
                arr3.push(arr2)
                arr2 = []
            }
            if (i === arr.length - 1) {
                if (arr2.length != 0)
                    arr3.push(arr2)
            }
        }
        return arr3
    }
    const [t, i18n] = useTranslation("global")
    useEffect(() => {
        if (localStorage.getItem("loginState") == undefined) {
            history.push("/")
            return
        }
        if (JSON.parse(localStorage.getItem("loginState")).is_admin != true) {
            history.push("/login-info")
            return
        }
        setLoading2(true)
        updateUsers()
    }, [])

    const updateUsers = async () => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/user/readAll", {}, config)
            let arr = res.data
            setMainData(arr)

        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (MainData.length == 0) return
        if (con == "all" && del == "all" && typ == "all" && FilterSelect == "all") {
            setStoreData(splitArray(MainData, 8))
            console.log("here-" + MainData)
            setUsersData(splitArray(MainData, 8)[index])
            setLoading(false)
            setLoadingModal(false)
        } else {

            filterFn()
        }
    }, [MainData])

    const handleDelete = async (id, m) => {
        try {
            setOpenModalDelete(false); setUserdelete(false)
            m === undefined ? setLoading(true) : setLoadingModal(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            // let res = await axios.post("/api/user/adminUpdate", { id, deleted: true, blocked: true }, config)
            await axios.delete("/api/user/admin_delete?id=" + id, config)
            await updateUsers()
            setOpenModal(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const hfsc = (e, { value }) => {
        setFilterSelect(value)
        if (value == "all") {
            setFilterInput("")
            setCh(0)
        }
    }

    const handleConfirm = async (i) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/user/adminUpdate", { id: i._id, role: i.id_role == "2" ? "Business" : "Freelancer" }, config)
            await updateUsers()
            setLoading(false)
            setOpenModal(false)
        } catch (e) {
            alert(e)
            setLoading(false)
            setOpenModal(false)
        }
    }

    const handleFormSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoadingModal(true)
            let name = e.target["2"].value
            let password = e.target["3"].value
            let obj = {};
            obj.id = e.target["0"].value
            obj.name = name;
            if (password !== "") {
                obj.password = password
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/user/adminUpdate", obj, config)
            await updateUsers()
            setLoadingModal(false)
            setOpenModal(false)
            console.log(e)
        } catch (e) {
            alert(e)
            setLoading(false)
        }

    }

    const handleLinkStorefront = async (id_role, id_user) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.post("/api/user/readAll/storefront", {
                id_role,
                id_user
            }, config)
            if (id_role == "2") {
                history.push("/business/" + data.id)
            } else if (id_role == "3") {
                history.push("/freelancer-cv2/" + data.id)
            }
        } catch (e) { alert(e) }
    }

    const handleBlockClick = async (id, status, m) => {
        try {
            m === undefined ? setLoading(true) : setLoadingModal(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/user/adminUpdate", { id, blocked: status === "block" ? true : false }, config)
            await updateUsers()
            setOpenModal(false)
        } catch (e) {
            alert(e)
        }
    }

    const filterFn = () => {
        if (MainData.length == 0) return
        let arr = MainData.filter((item) => {
            if (con == "confirmed")
                return !("id_role" in item) || item.role != "Subscriber"
            if (con == "unconfirmed")
                return !(!("id_role" in item) || item.role != "Subscriber")
            return item
        })
        arr = arr.filter((item) => {
            if (del == "deleted")
                return !(!item.hasOwnProperty("deleted") || !item.deleted)
            if (del == "not deleted")
                return (!item.hasOwnProperty("deleted") || !item.deleted)
            return item
        })
        arr = arr.filter((item) => {

            if (typ == "customer") {
                return !("id_role" in item) || item.id_role == "1"
            }
            if (typ == "business") {
                return ("id_role" in item) && item.id_role == "2"
            }
            if (typ == "freelancer") {
                return ("id_role" in item) && item.id_role == "3"
            }
            return true
        })
        arr = arr.filter((item) => {

            if (Ch > 0) {
                if (FilterSelect == "name") {
                    return item.name.toLowerCase().includes(FilterInput.toLowerCase())
                }
                if (FilterSelect == "email") {
                    return item.email.toLowerCase().includes(FilterInput.toLowerCase())
                }
            }
            return true
        })
        //console.log(arr)
        let narr = splitArray(arr, 8)
        setStoreData(narr)
        if (narr.length == 0) {
            setUsersData([])
            console.log("n")
        } else if (index > narr.length - 1) {
            setindex(narr.length - 1)
            setUsersData(narr[narr.length - 1])
        } else if (index <= narr.length - 1) {
            setUsersData(narr[index])
        }

        setLoading(false)
        setLoadingModal(false)

    }

    useEffect(() => {
        filterFn()
    }, [con, del, typ, Ch])

    let loginState = () => JSON.parse(localStorage.getItem("loginState"))
    const handleRemoveTFA = async (id) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.delete("/api/user/remove-tfa-admin?id=" + id, config)
            await updateUsers()
            setOpenModal(false)
            setLoading(false)
        } catch (e) { alert(e) }
    }


    return (
        Loading2 && <>
            {/* <Menu inverted css={menuStyles}>
                <div css={`max-width:15rem;`}>
                    <img src={logo} alt="Interpowered logo" css={logoStyle} />
                </div>
                <Menu.Menu position='right'>
                    <Menu.Item
                        name='Dashboard'
                    />

                    <Menu.Item
                        name={t('administration.navbar.users')}
                    />
                    <Popup trigger={
                        <Menu.Item
                            name='Admin'
                        />
                    } flowing hoverable>
                        <div css="display:flex;align-items:center;flex-direction:column;text-align:center;a{white-space:nowrap;display:block;padding:10px 0;text-decoration:none;color:black;padding:15px;border-radius:100px;border: 1px solid #aaa;line-height:0;margin:10px 0;}">
                            {imgfn("70px")}
                            <p css="font-weight:bold;">{loginState() && loginState().name}</p>
                            <p css="color:#777;">{loginState() && loginState().email}</p>
                            {loginState() && loginState().is_admin == "true" ? (
                                <a onClick={(e) => { e.preventDefault(); history.push("administration") }} href="#">Admin Panel</a>
                            ) : loginState().id_role == "2" ? (
                                <a onClick={(e) => { e.preventDefault(); history.push("business-profile") }} href="#">Business profile</a>
                            ) : loginState().id_role == "3" ? (
                                <a onClick={(e) => { e.preventDefault(); history.push("freelancer-profile") }} href="#">Freelancer profile</a>
                            ) : <a href="#">User profile</a>}

                            <a href="#" onClick={() => { localStorage.clear(); history.push("/") }}>{t('loginfo.logout')}</a>
                        </div>
                    </Popup>

                </Menu.Menu>
            </Menu> */}
            <Navbar />
            <main>

                <Container style={containerStyle}>
                    <div css={containerDivStyles}>
                        <Header>
                            <h2>
                                {t('administration.header.users')}
                            </h2>
                            <p>
                                {t('administration.header.para')}
                            </p>
                        </Header>
                        <div>
                            <Button primary onClick={() => setOpenFreelancerCategories(true)} css={categoryButtonStyles}>Service Categories</Button>
                            <Button primary onClick={() => setOpenBusinessCategories(true)} css={categoryButtonStyles}>Product Categories</Button>
                            <Button primary onClick={() => history.push("/orders")} css={categoryButtonStyles}>View Orders</Button>
                            <Link to="/administration/marketing-packages" css={categoryButtonStyles} className="ui teal circular button" >Marketing packages</Link>
                        </div>
                    </div>
                    <div css={inputFilterStyles}>
                        <Input type='text' placeholder={t("administration.search") + "..."} action value={FilterInput} onChange={(e) => setFilterInput(e.target.value)}>
                            <input />
                            <Select onChange={hfsc} compact options={[{ key: "all", text: "All", value: "all" }, { key: "name", text: "Name", value: "name" }, { key: "email", text: "Email", value: "email" }]} defaultValue='all' />
                            <Button type='submit' disabled={FilterSelect == "all"} onClick={() => { setCh(prev => prev + 1) }}>Search</Button>
                        </Input>
                        <Select options={[{ key: "all", text: "All", value: "all" }, { key: "confirmed", text: "Confirmed", value: "confirmed" }, { key: "unconfirmed", text: "Unonfirmed", value: "unconfirmed" }]} defaultValue='all' onChange={(e, { value }) => { setCon(value) }} />
                        <Select placeholder='Deleted' options={[{ key: "all", text: "All", value: "all" }, { key: "deleted", text: "Deleted", value: "deleted" }, { key: "not deleted", text: "Not deleted", value: "not deleted" }]} defaultValue='not deleted' onChange={(e, { value }) => { setDel(value) }} />
                        <Select placeholder='Type' options={[{ key: "all", text: "All", value: "all" }, { key: "customer", text: "Customer", value: "customer" }, { key: "business", text: "Business", value: "business" }, { key: "freelancer", text: "Freelancer", value: "freelancer" }]} defaultValue='all' onChange={(e, { value }) => { setTyp(value) }} />
                    </div>
                    <Table singleLine striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('administration.table.name')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.email')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.registration_time')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.last_login')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.confirmation')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.block_status')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.actions')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {
                            Loading && (
                                <div css={`position:fixed;top:0;left:0;width:100%;height:100%;z-index:999999;`}>
                                    <Dimmer active inverted>
                                        <Loader />
                                    </Dimmer>
                                </div>
                            )
                        }

                        <Table.Body>
                            {
                                UsersData.map((i, c) => {
                                    return (
                                        <Table.Row key={c}>
                                            <Table.Cell>{i.name}</Table.Cell>
                                            <Table.Cell style={emailTableStyle}>{i.email}</Table.Cell>
                                            <Table.Cell>{new Date(i.created_at).toLocaleDateString()}</Table.Cell>
                                            <Table.Cell>{i.last_login ? (new Date(i.last_login).toLocaleDateString()) : ""}</Table.Cell>
                                            <Table.Cell style={stConStyYes}>
                                                {
                                                    !("id_role" in i) || i.role != "Subscriber" ? <span style={stConStyYes}>{t('administration.confirmed')}</span>
                                                        : <span style={stConStyNo}>{t('administration.unconfirmed')}</span>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {
                                                    (!("blocked" in i) || (!i.blocked)) ? (
                                                        <Button css="border-radius:100px !important;" onClick={() => handleBlockClick(i._id, "block")} color="red">
                                                            {t('administration.block')}
                                                        </Button>
                                                    ) : (
                                                        <Button css="border-radius:100px !important;" onClick={() => handleBlockClick(i._id, "unblock")} color="green">
                                                            {t('administration.unblock')}
                                                        </Button>
                                                    )
                                                }

                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown
                                                    text={t("administration.table.actions")}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                    style={tabDropStyles}
                                                >
                                                    <Dropdown.Menu>
                                                        {(i.id_role == "2" || i.id_role == "3") && <Dropdown.Item icon="building" onClick={(e) => { handleLinkStorefront(i.id_role, i._id) }} text="Storefront" />}
                                                        {!i.deleted && <>
                                                            {(i.secret) && <Dropdown.Item icon="key" onClick={(e) => { handleRemoveTFA(i._id) }} text={t("administration.remove_tfa")} />}
                                                            <Dropdown.Item onClick={() => { console.log(i); setModalData(i); setOpenModal(true) }} icon='edit' text={t('administration.update')} />
                                                            {("id_role" in i && "role" in i) && i.id_role != "1" && i.id_role != "4" && i.role == "Subscriber" && <Dropdown.Item onClick={() => handleConfirm(i)} icon='check' text={t('administration.confirm')} />}
                                                            <Dropdown.Item onClick={() => { setOpenModalDelete(true); setUserdelete(i._id) }} icon='delete' text={t('administration.delete')} />
                                                        </>}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }

                        </Table.Body>
                    </Table>
                    <div
                        css={`display:flex;justify-content:flex-end;`}
                    >
                        <Pagination
                            boundaryRange={3}
                            defaultActivePage={(index + 1)}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={StoreData.length}
                            onPageChange={(e, { activePage }) => { setindex(activePage - 1); setUsersData(StoreData[activePage - 1]) }}
                        />
                    </div>
                </Container>
            </main>

            {
                OpenFreelancerCategories && <FreelancerCategories users={MainData} onClose={() => setOpenFreelancerCategories(false)} />
            }
            {
                OpenBusinessCategories && <BusinessCategories users={MainData} onClose={() => setOpenBusinessCategories(false)} />
            }

            <Modal
                closeIcon
                closeOnDimmerClick={false}
                dimmer="inverted"
                open={OpenModal}
                onClose={() => setOpenModal(false)}
                size="large"
            >
                <Modal.Header>{t('administration.update_user')}</Modal.Header>
                <Modal.Content>
                    {
                        LoadingModal && (
                            <Dimmer active inverted>
                                <Loader />
                            </Dimmer>
                        )
                    }
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Header as='h4' attached='top' block>
                                    <Icon name="setting" size="tiny" />{t('administration.manage_account')}
                                </Header>
                                <Segment attached>
                                    <Segment.Group>
                                        <Segment><a href="#" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); set_manage_account_segment(1) }} >{t('administration.account_details')}</a></Segment>
                                        <Segment><a href="#" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); set_manage_account_segment(2) }} >{t('administration.profile_details')}</a></Segment>
                                        {ModalData.secret && <Segment><a href="#" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); handleRemoveTFA(ModalData._id) }} >{t('administration.remove_tfa')}</a></Segment>}
                                        {(ModalData.id_role == "2" || ModalData.id_role == "3") && <Segment><a href="#" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); handleLinkStorefront(ModalData.id_role, ModalData._id) }} >Storefront</a></Segment>}
                                    </Segment.Group>
                                    <Divider horizontal />
                                    <Segment.Group>
                                        {
                                            (!("blocked" in ModalData) || (!ModalData.blocked)) ? (
                                                <Segment><a href="#" onClick={(e) => { e.preventDefault(); handleBlockClick(ModalData._id, "block", "modal") }} style={{ color: "red" }}>{t('administration.block')}</a></Segment>
                                            ) : (
                                                <Segment><a href="#" onClick={(e) => { e.preventDefault(); handleBlockClick(ModalData._id, "unblock", "modal") }} style={{ color: "green" }}>{t('administration.unblock')}</a></Segment>
                                            )
                                        }

                                        <Segment><a href="#" onClick={(e) => { e.preventDefault(); handleDelete(ModalData._id) }} style={{ color: "red" }}>{t('administration.delete')}</a></Segment>
                                        {("id_role" in ModalData && "role" in ModalData) && ModalData.id_role != "1" && ModalData.id_role != "4" && ModalData.role == "Subscriber" && <Segment><a href="#" onClick={(e) => { e.preventDefault(); handleConfirm(ModalData) }} style={{ color: "green" }}>{t('administration.confirm')}</a></Segment>}
                                    </Segment.Group>
                                </Segment>
                            </Grid.Column>
                            {manage_account_segment == 1 && <Grid.Column width={11}>
                                <Header as='h4' attached='top' block>
                                    <Icon name="user" size="tiny" />{t('administration.account_details')}
                                </Header>
                                <Segment attached>
                                    <UpdateEmailAdmin id_user={ModalData._id} email={ModalData.email} />
                                    <Form onSubmit={handleFormSubmit}>
                                        <div className="ui segment">
                                            <input type="text" hidden value={ModalData._id} />
                                            <input value={ModalData.email} hidden disabled type="email" />
                                            {/* <Form.Field>
                                            <label>{t('administration.email')}</label>
                                            </Form.Field> */}
                                            <Form.Field>
                                                <label>{t('administration.name')}</label>
                                                <input required defaultValue={ModalData.name} type="text" />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>{t('administration.password')}</label>
                                                <input type="password" />
                                            </Form.Field>
                                            <Button color="blue" type='submit'><Icon name="check" />{t('administration.save')}</Button>
                                        </div>
                                    </Form>
                                </Segment>
                            </Grid.Column>}
                            {manage_account_segment == 2 && <Grid.Column width={11}>
                                <Header as='h4' attached='top' block>
                                    <Icon name="user" size="tiny" />{t('administration.profile_details')}
                                </Header>
                                <Segment attached>
                                    <Form onSubmit={handleFormSubmit}>
                                        <input type="text" hidden value={ModalData._id} />
                                        <Form.Field>
                                            <label>{t('administration.email')}</label>
                                            <Segment>{ModalData.email}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.name')}</label>
                                            <Segment>{ModalData.name}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.phone')}</label>
                                            <Segment>{ModalData.phone}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.country')}</label>
                                            <Segment>{ModalData.country}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.city')}</label>
                                            <Segment>{ModalData.city}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.address')}</label>
                                            <Segment>{ModalData.address}</Segment>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t('administration.zip_code')}</label>
                                            <Segment>{ModalData.zip}</Segment>
                                        </Form.Field>
                                    </Form>
                                </Segment>
                            </Grid.Column>}
                        </Grid.Row>
                    </Grid>



                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setOpenModal(false)}>
                        {t('administration.close')}
                    </Button>
                </Modal.Actions>

            </Modal>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                dimmer="inverted"
                open={OpenModalDelete}
                onClose={() => setOpenModalDelete(false)}
                size="small"
            >
                <Modal.Header>{t('administration.delete_user')}</Modal.Header>
                <Modal.Content>
                    {
                        LoadingModal && (
                            <Dimmer active inverted>
                                <Loader />
                            </Dimmer>
                        )
                    }
                    <div>
                        Are you sure you want to delete this user? If this user is a product or service seller, customers won’t see the products or services of theirs.
                    </div>



                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setOpenModalDelete(false)}>
                        {t('administration.close')}
                    </Button>
                    <Button positive onClick={() => handleDelete(userdelete)}>
                        {t('administration.delete')}
                    </Button>
                </Modal.Actions>

            </Modal>
        </>
    )


}

export default Administration