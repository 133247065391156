import { Link } from 'react-router-dom'
import { Dimmer, Icon, Loader, Rating, Segment } from 'semantic-ui-react'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from '../axios';
import qs from "qs"
import { useHistory, useLocation } from 'react-router-dom';
import countries from '../shared/countries';
import { useGetLocationName, useGetProductLanguages } from '../shared/custom_hooks';


const ProductCategories = ({ t, categories, data, handleFavoriteClick, getImage2 }) => {

    const [fillScreen, setFillScreen] = useState(null)
    const [showcat, setShowcat] = useQueryState("showcat")
    const productList = useMemo(() => {
        return data.map(item => {
            item.category_name = categories.find(i => i._id == item._id.category_id).name
            return item
        }).sort((a, b)=> {
              if (a.category_name<b.category_name) {
                return -1;
              }
              if (a.category_name>b.category_name) {
                return 1;
              }
              return 0;
            })
    }, [categories, data])
    useEffect(()=>{
        setFillScreen(productList.find(i=>i._id.category_id==showcat))
    },[showcat])

    return <>
        {!fillScreen && productList.map(item => <PList t={t} handleFavoriteClick={handleFavoriteClick} getImage2={getImage2} item={item} fillScreen={(opc) => setShowcat(opc ? item._id.category_id : null)} />)}
        {fillScreen && <PList t={t} handleFavoriteClick={handleFavoriteClick} getImage2={getImage2} item={fillScreen} fill={true} fillScreen={(opc) => setShowcat(null)} />}
        
    </>
}


export default ProductCategories



const PList = ({ item, fillScreen, fill, handleFavoriteClick, getImage2, t }) => {
    const [max, setMax] = useState(6)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const { userInfo } = useSelector(state => state.user)

    const getLocationName=useGetLocationName()
    const getProductLanguages=useGetProductLanguages()

    const handleSeeMore = () => {
        if (item.count <= 12)
            setMax(12)
        else
            fillScreen(true)
    }
    const handleSeeLess = () => {
        if (item.count <= 12)
            setMax(6)
        else
            fillScreen(false)
    }
    const r=useRef();
    const load=async()=>{
        setLoading(true)
        try{
            const { data: products } = await axios.get("/api/category/products-category?id="+item._id.category_id)
            setProducts(products)
            setMax(item.count)
            r.current.scrollIntoView();
        }catch(e){
            alert(e)
        }
        setLoading(false)
    }
    useEffect(() => {
        setProducts(item.elements)
        if (fill) {
            load()
        }
    }, [])

    return <>
        <h3 ref={r} css="margin-top:1rem;white-space:pre-wrap;">{item.category_name}</h3>
        <div className="dailyWrapper">
            {
                products.slice(0, max).map((i) => {
                    return <div key={i._id} className="imgWrapper">
                        {
                            (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                <div css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                </div>
                            ) : (userInfo &&
                                <div className="heartBtn" css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:${"none"};align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                </div>
                            )
                        }
                        <Link to={`${i.service_name ? "/service/" + i._id : "/product/" + i._id}`} css="color:#000; &:hover{color:#000;}" href="">
                            <div css="padding-bottom:80%;position:relative;">
                                <img className="bigImg" src={getImage2(i.images[0])} alt="" />
                            </div>
                            <div>
                                <h3 className="h3Text">{i.service_name || i.name}</h3>
                                <p css="margin:0;">{i.vendor_name}</p>
                                <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                <p css="margin:0;">USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(i.volume_pricing.length < 2 ? i.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + i.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>} </p>
                                {i.location && <p css="margin:0;">{getLocationName(i.location)}</p>}
                                {i.languages?.length>0 && <p css="margin:0;">{getProductLanguages(i.languages)}</p>}
                            </div>
                        </Link>
                    </div>
                })
            }
            
        </div>
        {loading && <div css="position:relative;margin-top:1rem;margin-bottom:1rem;">
              <Dimmer active inverted>
                <Loader inverted></Loader>
              </Dimmer>
            </div>}
        {item.count > 6 && max <= 6 && <div css="text-align:right;">
            <button onClick={handleSeeMore} css="color:teal;font-size:1.2rem;background:transparent;border:0;cursor:pointer;">See more</button>
        </div>}
        {item.count > 6 && max > 6 && <div css="text-align:right;">
            <button onClick={handleSeeLess} css="color:teal;font-size:1.2rem;background:transparent;border:0;cursor:pointer;">See less</button>
        </div>}
    </>
}


/*

const PList = ({ item, fillScreen, fill, handleFavoriteClick, getImage2, t }) => {
    const [max, setMax] = useState(6)
    const { userInfo } = useSelector(state => state.user)
    const handleSeeMore = () => {
        if (item.count <= 12)
            setMax(12)
        else
            fillScreen(true)
    }
    const handleSeeLess = () => {
        if (item.count <= 12)
            setMax(6)
        else
            fillScreen(false)
    }
    useEffect(() => {
        if (fill) {
            setMax(item.count)
        }
    }, [])
    return <>
        <h3 css="margin-top:1rem;">{item.category_name}</h3>
        <div className="dailyWrapper">
            {
                item.elements.slice(0, max).map((i) => {
                    return <div key={i._id} className="imgWrapper">
                        {
                            (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                <div css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                </div>
                            ) : (userInfo &&
                                <div className="heartBtn" css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:${"none"};align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                </div>
                            )
                        }
                        <Link to={`${i.service_name ? "/service/" + i._id : "/product/" + i._id}`} css="color:#000; &:hover{color:#000;}" href="">
                            <div css="padding-bottom:80%;position:relative;">
                                <img className="bigImg" src={getImage2(i.images[0])} alt="" />
                            </div>
                            <div>
                                <h3 className="h3Text">{i.service_name || i.name}</h3>
                                <p css="margin:0;">{i.vendor_name}</p>
                                <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                <p>USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(i.volume_pricing.length < 2 ? i.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + i.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>} </p>
                            </div>
                        </Link>
                    </div>
                })
            }
        </div>
        {item.count > 6 && max <= 6 && <div css="text-align:right;">
            <button onClick={handleSeeMore} css="color:teal;font-size:1.2rem;background:transparent;border:0;cursor:pointer;">See more</button>
        </div>}
        {item.count > 6 && max > 6 && <div css="text-align:right;">
            <button onClick={handleSeeLess} css="color:teal;font-size:1.2rem;background:transparent;border:0;cursor:pointer;">See less</button>
        </div>}
    </>
}


*/

const useQueryState = query => {
  const location = useLocation()
  const history = useHistory()

  const setQuery = useCallback(
    value => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      const queryString = qs.stringify(
        { ...existingQueries, [query]: value },
        { skipNulls: true }
      )

      history.push(`${location.pathname}?${queryString}`)
    },
    [history, location, query]
  )

  return [
    qs.parse(location.search, { ignoreQueryPrefix: true })[query],
    setQuery,
  ]
}