import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Dimmer, Loader, Search } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { showProduct, showProductCard, showProductDiv, showProductOver, showProductWrap } from '../styles'
import { useGetLocationName, useGetProductLanguages } from '../../shared/custom_hooks'

const TabCatalog = (props) => {

    const [products, setProducts] = useState([])
    const [preProducts, setPreProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadings, setLoadings] = useState(false)
    const [results, setresults] = useState([])
    const [opciones, setopciones] = useState([])

    const history = useHistory()

    useEffect(() => {
        loadData()
    }, [])

    const getLocationName = useGetLocationName()
    const getProductLanguages = useGetProductLanguages()

    const loadData = async () => {
        try {
            setLoading(true)
            let res = await axios.post("/api/product/readAllOpen", {
                vendor_id: props.freelancer.freelancerId
            })
            //console.log(res)
            setProducts(res.data)
            setPreProducts(res.data)
            let arr1 = res.data.map(x => [x.service_name])
            let arr2 = []
            for (let i = 0; i < arr1.length; i++) {
                for (let j = 0; j < arr1[i].length; j++) {
                    arr2.push(arr1[i][j])
                }
            }

            setopciones([...new Set(arr2)]);
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    if (loading) {
        return <div className="py-5"><Dimmer active inverted>
            <Loader />
        </Dimmer></div>
    }

    const handleChange = (e) => {
        let f = products.filter(x => {
            return x.service_name.match(new RegExp('^' + e, 'i'))
        })
        setPreProducts(f)
        setresults(e != "" ? opciones.filter(x => x.match(new RegExp('^' + e, 'i'))).map(x => { return { title: x } }) : [])

    }

    return (
        <>
            <div>
                <Search
                    fluid
                    className="customSearchInputClass"
                    loading={loadings}
                    onResultSelect={(e, data) => {
                        //history.push("/search/" + (data.result.title) + (props.qp || ""))
                        handleChange(data.result.title)
                    }}
                    onSearchChange={(e, data) => {
                        handleChange(data.value)
                    }}
                    results={results}
                    //defaultValue={props.term || ""}
                    showNoResults={false}
                //onKeyPress={handleKeyPress}
                //value={value}
                />
            </div>
            <div className="ui four column grid container bx-mt-3">

                {
                    preProducts.map((item) => {
                        return (
                            <div className="sixteen wide mobile five wide tablet four wide computer column" css="cursor:pointer;" onClick={() => { history.push("/service/" + item._id) }}>
                                <div tabIndex="-1" css={showProduct}>
                                    <div css={showProductWrap}>
                                        <div css={showProductDiv}>
                                            <div css={showProductCard(item.images[0])}>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div css="font-size:16px;font-weight:bold;">{item.name || item.service_name}</div>
                                <div >{props.freelancer.freelancers_name}</div>
                                <div >USD ${item.sale_price || item.hourly_rate}</div>
                                {item.location && <p css="margin:0;">{getLocationName(item.location)}</p>}
                                {item.languages?.length > 0 && <p css="margin:0;">{getProductLanguages(item.languages)}</p>}
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

export default TabCatalog