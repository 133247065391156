export const astyles = `
background-image: -webkit-linear-gradient(#0070BA 20%, #0070BA) !important;
background-image: linear-gradient(#0070BA 20%, #0070BA) !important;
background-repeat: no-repeat !important;
border-color: #0070BA;
margin: 0;
position: relative;
white-space: nowrap;
line-height: 1.5;
border-radius: 50px;
cursor: pointer;
display: inline-block;
color:#fff;
display:flex;
align-items:center;
padding:7px 14px;
text-decoration:none;
font-size:20px;
display:flex;align-items:center;justify-content:center;
&:hover{
    color:#fff;
}
`