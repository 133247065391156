import React, { useState } from 'react'
import Navbar from '../navbar/navbar'
import Footer from '../footer/footer'
import { useLocation } from 'react-router';
import 'styled-components/macro'
import { PayPalButton } from 'react-paypal-button-v2'
import { useSelector } from 'react-redux';
import axios from '../../axios';
import { Link } from 'react-router-dom';
import env from '../../config.env'
const {paypal_client_id}=env

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const InAppNotification = () => {
    const [successful, setSuccessful] = useState(false)
    const { userInfo } = useSelector(state => state.user)
    let query = useQuery()
    const opt = query.get("opt")
    let price = opt;
    price = price == "1" ? "0.99" : (price == "2" ? "2.99" : "4.99")
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    return (
        <>
            <Navbar />
            <main>

            <div css="max-width:1200px;margin:0 auto; padding: 0 36px;">
                {!successful && <div css="max-width:300px;margin:0 auto;">
                    <PayPalButton
                        createOrder={async (data, actions) => {
                            const { data: oid } = await axios.get(`/api/paypal/inappnotification/create_order?opt=${query.get("opt")}`, config)
                            return oid
                        }}
                        onApprove={async (data, actions) => {
                            try {
                                await axios.post("/api/paypal/inappnotification/capture", {
                                    orderID: data.orderID,
                                    offset:(new Date()).getTimezoneOffset()
                                }, config)
                                setSuccessful(true)
                                return
                            } catch (e) {
                                console.log(e)
                                alert("There was an error, please contact the administrator. PAYPAL_ORDER_ID:" + data.orderID)
                            }
                        }}
                        onError={(e) => alert(e)}
                        options={{
                            clientId: paypal_client_id
                          }}
                    />
                    {/* <PayPalButton
                        amount={price}
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={async (details, data) => {
                            const { data: result } = await axios.post("/api/paypal/inappnotification", { order_id: data.orderID, opt,offset:(new Date()).getTimezoneOffset() }, config)
                            setSuccessful(true)
                        }}
                    /> */}
                </div>}
                {successful && 
                    <div css="max-width:300px;margin:20px auto;">
                        <h3 css="text-align:center;">Transaction completed</h3>
                        <Link to="/notification-form" style={{marginBottom:"10px"}} className="ui teal circular fluid button">Go to in-app notifications form</Link>
                        <Link to="/" className="ui teal circular fluid button">Return to home page</Link>
                    </div>
                }
            </div>
            </main>

            <Footer />
        </>
    )
}

export default InAppNotification