import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dimmer, Icon, Image, List, Loader, Popup } from 'semantic-ui-react'
import "styled-components/macro"
import axios from '../../axios'
import { getTimes, url_environment } from '../../config'
import { uiActions } from '../../store/ui-slice'

const MessagesNotificationsPopup = () => {
    const [loading, setLoading] = useState(false)
    const [oldMessages, setListOldMessages] = useState([])
    const [newMessages, setListNewMessages] = useState([])
    const [listCount, setListCount] = useState(0)
    const [open, setOpen] = useState(false)
    const [more_loading, set_more_loading] = useState(false)
    const [show_more, set_show_more] = useState(true)
    const dispatch = useDispatch()
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const handleMoreClick = async() => {
        set_more_loading(true)
        try{
            const {data}=await axios.get(`/api/user/message/notifications/pagination?skip=${oldMessages.length}`,config)
            setListOldMessages(p=>{return [...p,...data]})
            if(data.length==0)
                set_show_more(false)
        }catch(e){alert(e)}
        set_more_loading(false)
    }
    const handleOpen = async () => {
        setOpen(true)
        setLoading(true)
        set_show_more(true)
        try {
            // if (listCount == 0) return
            const { data } = await axios.get("/api/user/message/notifications/popup", config)
            // console.log(data)
            setListNewMessages(data.new_messages)
            setListOldMessages(data.old_messages)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const loadData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get("/api/user/message/count_notifications", config)
            setListCount(data.count)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleMessageClick = (message, return_value) => {
        dispatch(uiActions.change_action({ open: true, action: "view message", view_message_data: { ...message, return: return_value } }))
        setOpen(false)
        setListCount(p => p - 1)
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <Popup
            on="click"
            trigger={<button title={t("navbar.view_messages")} css={`i{margin:0 !important;} position:relative;cursor:pointer;background:#f4f4f4;border:0;width:50px;height:50px;border-radius:50%;margin-right:10px;`}>
                <Icon name="envelope outline" size="big" />
                {listCount > 0 && <div css="position:absolute;top:0;right:0;padding:0px 6px;border-radius:100px;background-color:#db2828;color:white;font-size:13px;"><span>{listCount > 9 ? "9+" : listCount}</span></div>}
            </button>}
            pinned
            position="bottom right"
            offset={[1, 0]}
            flowing
            basic
            style={{ fontSize: "16px", borderRadius: "20px", padding: "30px 20px" }}
            open={open}
            onOpen={handleOpen}
            onClose={() => setOpen(false)}

        //style={{borderRadius:"20px"}}
        >
            {loading && (
                <Loader active />
            )}

            {!loading && (
                <>
                    {/*  */}
                    <div css="max-width:300px;max-height:55vh;overflow-y:scroll;">
                        <div>
                            {newMessages.length>0&&<div css="margin:15px 0;font-weight:700;text-align:center;">{t("navbar.new_messages")}</div>}
                            {newMessages.map(x => {
                                let link_image
                                let text = <span> {t("navbar.you_have_message")} <b>{x.sender.store_name || x.sender.name}</b></span>
                                return <div onClick={() => handleMessageClick(x, "unread")} tabIndex={-1} css="padding-bottom:5px;border-bottom:1px solid #dadada;cursor:pointer;">
                                    <div css="display:flex;margin:15px 0;">
                                        <img css="flex:0 0 auto;width:50px;height:50px;border-radius:3px;object-fit:cover;object-position:center;" src={`${url_environment}${x.sender.customer_picture}`} />
                                        <div css="flex-grow:2;margin-left:5px;margin-right:10px;">{text}</div>
                                        <div css="flex-grow:1;text-align:right;">
                                            <span>{getTimes(x.created_at)}</span>
                                        </div>
                                    </div>
                                    {/* <Link to={link_image}>
                                        <img css="width:120px;height:120px;object-fit:cover;object-position:center;border-radius:6px;" src={`https://interpowered.com:3001${x.product_image || x.vendor_image}`} alt="" />
                                    </Link> */}
                                </div>
                            })}
                            {oldMessages.length>0&&<div css="margin:15px 0;font-weight:700;text-align:center;">{t("navbar.old_messages")}</div>}
                            {oldMessages.map(x => {
                                let link_image
                                let text = <span> {t("navbar.you_have_message")} <b>{x.sender.store_name || x.sender.name}</b></span>
                                return <div onClick={() => handleMessageClick(x, "unread")} tabIndex={-1} css="padding-bottom:5px;border-bottom:1px solid #dadada;cursor:pointer;">
                                    <div css="display:flex;margin:15px 0;">
                                        <img css="flex:0 0 auto;width:50px;height:50px;border-radius:3px;object-fit:cover;object-position:center;" src={`${url_environment}${x.sender.customer_picture}`} />
                                        <div css="flex-grow:2;margin-left:5px;margin-right:10px;">{text}</div>
                                        <div css="flex-grow:1;text-align:right;">
                                            <span>{getTimes(x.created_at)}</span>
                                        </div>
                                    </div>
                                    {/* <Link to={link_image}>
                                        <img css="width:120px;height:120px;object-fit:cover;object-position:center;border-radius:6px;" src={`https://interpowered.com:3001${x.product_image || x.vendor_image}`} alt="" />
                                    </Link> */}
                                </div>
                            })}
                            {(show_more) && <div css="text-align:center;margin-top:15px;"><Button loading={more_loading} onClick={handleMoreClick} circular color="teal">{t("navbar.show_more")}</Button></div>}
                        </div>

                    </div>
                </>
            )}

        </Popup>
    )
}

export default MessagesNotificationsPopup