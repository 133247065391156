import React from 'react'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import 'styled-components/macro'
import { useLocation } from 'react-router'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './tempstyles.css'



const OrderDetails = () => {
    const location = useLocation()
    const items = location.state.items
    const discounts = location.state.discounts
    console.log(JSON.stringify({ details: location.state.details, data: location.state.data }))
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Navbar />
            <div>

                <main css="max-width:1000px;margin:0 auto; padding:0 36px;">
                    <div css="display:flex;flex-wrap:wrap;">
                        <div css="flex:0 0 auto;width:50%;padding:9px;">
                            <div className="ui form">
                                <div className="field">
                                    <label>
                                        {t("cart.paid_with")}
                                    </label>
                                    <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                        <label css="font-weight:400;">
                                            Balance
                            </label>
                                        <label css="font-weight:400;">USD ${location.state.details.purchase_units[0].payments.captures[0].amount.value}</label>
                                    </label>
                                </div>
                                <div className="field">
                                    <label>{t("cart.ship_to")}</label>
                                    <label css="font-weight:400 !important;">{location.state.details.purchase_units[0].shipping.name.full_name}</label>
                                    <label css="font-weight:400 !important;">{location.state.details.purchase_units[0].shipping.address.address_line_1}</label>
                                    <label css="font-weight:400 !important;">
                                        {location.state.details.purchase_units[0].shipping.address.admin_area_2} {location.state.details.purchase_units[0].shipping.address.admin_area_1} {location.state.details.purchase_units[0].shipping.address.postal_code}
                                    </label>
                                    <label css="font-weight:400 !important;">
                                        {location.state.details.purchase_units[0].shipping.address.country_code}
                                    </label>
                                </div>
                                <div className="field">
                                    <label>{t("cart.order_id")}</label>
                                    <label css="font-weight:400 !important;">{location.state.data.orderID}</label>
                                </div>
                            </div>
                        </div>
                        <div css="flex:0 0 auto;width:50%;padding:9px;">
                            <div className="ui form">
                                <div className="field">
                                    <label>{t("cart.seller_info")}</label>
                                    <label css="font-weight:400 !important;">{location.state.details.payer.email_address}</label>
                                </div>
                                <div className="field">
                                    <label>{t("cart.purchase_details")}</label>
                                    <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                        <label css="font-weight:400;">{t("cart.purchase_amount")}</label>
                                        <label css="font-weight:400;">USD ${location.state.details.purchase_units[0].payments.captures[0].amount.value}</label>
                                    </label>
                                    <hr />
                                    <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                        <label css="font-weight:400;">Total</label>
                                        <label css="font-weight:400;">USD ${location.state.details.purchase_units[0].payments.captures[0].amount.value}</label>
                                    </label>
                                </div>
                                <Button circular color="teal" floated="right" onClick={() => window.print()} css="@media print{display:none !important;}">{t("cart.print")}</Button>
                            </div>
                        </div>
                    </div>
                    <table id="customers">
                        <tr>
                            <th>{t("cart.product_id")}</th>
                            <th>{t("cart.product_name")}</th>
                            <th>{t("cart.price")}</th>
                            <th>{t("cart.discount")}</th>
                            <th>{t("cart.taxes")}</th>
                            <th>{t("cart.shipping")}</th>
                            <th>{t("cart.quantity")}</th>
                            <th>Total</th>
                        </tr>
                        {items.map(x => {
                            return <tr>
                                <td>{x.product}</td>
                                <td>{x.product_name}</td>
                                <td>USD ${x.current_price}</td>
                                <td>USD ${parseFloat(discounts.find(d => d.id == x.product).discount).toFixed(2)}</td>
                                <td>USD ${x.total_tax}</td>
                                <td>USD ${x.shipping}</td>
                                <td>{x.quantity}</td>
                                <td>USD ${(parseFloat(x.total_price)+parseFloat(x.total_tax)).toFixed(2)}</td>
                            </tr>

                        })}

                    </table>
                </main>
            </div>

            <Footer />
        </>
    )
}

export default OrderDetails