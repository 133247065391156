import React, { useRef, useState } from 'react'
import { Dimmer, Icon, Input, Loader, TextArea } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import "styled-components/macro"
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../axios'
import { uiActions } from '../../store/ui-slice'
import { formData } from '../formDataCreator'
import { cleanSubject, url_environment } from '../../config'
import { getImage } from '../../Profile/service page/service.page'

const NewMessage = (props) => {
    const [t, i18n] = useTranslation("global")
    const [file, setFile] = useState(undefined)
    const { userInfo } = useSelector(state => state.user)
    const fileRef = useRef()
    const [loading, setLoading] = useState(false)
    const textRef = useRef("")
    const subjectRef = useRef("")
    const dispatch = useDispatch()
    const handleSendMessage = async () => {
        if (textRef.current.trim() == "") {
            alert("Please write a message!")
            return
        }
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            const o = {}
            let iduser
            if (typeof props.data.vendor.id_user == "string")
                iduser = props.data.vendor.id_user
            else
                iduser = props.data.vendor.id_user._id
            o.receiver = iduser
            o.subject = subjectRef.current || (`${props.data.subject} ${props.data.product ? `: {[(link:${props.data.link}:::${(props.data.product.name || props.data.product.service_name || "")})]}` : ""}`)
            o.message = textRef.current
            if (file) o.image = file
            if (props.data.reply_message) o.replies = JSON.stringify(props.data.reply_message)
            await axios.post("/api/user/message", formData({
                ...o
            }), config)
            // await axios.post("/api/user/message",formData({
            //     receiver:props.data.vendor.id_user,
            //     subject:subjectRef.current || (`${props.data.subject} ${props.data.product ? `: {[(link:${props.data.link}:::${(props.data.product.name || props.data.product.service_name || "")})]}` : ""}`),
            //     message:textRef.current+"{[(date)]}"+`${file ? "{[(image)]}" : ""}`,
            //     image:file
            // }),config)
            setLoading(false)
            dispatch(uiActions.change_action({ open: false, action: "" }))
        } catch (e) { alert(e) }
        setLoading(false)
    }

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <h3 css="text-align:center;">{t("messages.new_message")}</h3>
            <div css="height:2px;background:teal;margin:7px 0;"></div>
            <div css="display:flex;align-items:center;justify-content:space-between;margin:10px auto;">
                <span>{t("messages.to")}:</span>
                <div css="margin:0 10px;display:flex;border-radius:5px;background:#e9f1f2;padding:5px;">
                    <img src={`${url_environment}${props.data.vendor.business_picture || props.data.vendor.freelancer_picture || props.data.vendor.customer_picture}`} alt="" css="width:60px;height:60px;object-fit:cover;object-position:center;margin-right:5px;border-radius:100px;" />
                    <div css="display:flex;flex-direction:column;justify-content:space-around;">
                        <p>{props.data.vendor.business_name || props.data.vendor.name_of_business || props.data.vendor.freelancers_name || props.data.vendor.freelancer_name || props.data.vendor.store_name || props.data.vendor.name}</p>
                        <p>{props.data.vendor.category_name || ""}</p>
                    </div>
                </div>
                <span></span>
            </div>
            <div css="display:flex;align-items:center;justify-content:space-between;margin:10px auto;">
                <span>{t("messages.subject")}:</span>
                {!props.data.editable_subject && <Input value={`${cleanSubject(props.data.subject)} ${props.data.product ? ": " + (props.data.product.name || props.data.product.service_name || "") : ""}`} placeholder={t("messages.write_subject")} css="margin:0 10px;max-width:300px;width:100%;" />}
                {props.data.editable_subject && <Input onChange={(e) => subjectRef.current = e.target.value} defaultValue={`${cleanSubject(props.data.subject)} ${props.data.product ? ": " + (props.data.product.name || props.data.product.service_name || "") : ""}`} placeholder={t("messages.write_subject")} css="margin:0 10px;max-width:300px;width:100%;" />}
                <span css="color:transparent;">{t("messages.subject")}:</span>
            </div>
            <div css="display:flex;justify-content:space-between;margin:10px 0;">
                <div css="margin:0 10px;display:flex;border-radius:5px;padding:5px;">
                    <img src={`${url_environment}/${userInfo.image}`} alt="" css="width:60px;height:60px;object-fit:cover;object-position:center;margin-right:5px;border-radius:100px;" />
                    <div css="display:flex;flex-direction:column;justify-content:space-around;">
                        <p>{userInfo.store_name || userInfo.name}</p>
                    </div>
                </div>
                {props.data.product && <div css="margin:0 10px;display:flex;border-radius:5px;padding:5px;">
                    <img src={`${getImage(props.data.product.images[0])}`} alt="" css="width:60px;height:60px;object-fit:cover;object-position:center;margin-right:5px;border-radius:100px;" />{/**${url_environment}${props.data.product.images[0]} */}
                    <div css="display:flex;flex-direction:column;justify-content:space-around;">
                        <p>{props.data.product.name || props.data.product.service_name}</p>
                        <p>{props.data.product.category_name}</p>
                    </div>
                </div>}
            </div>
            {file ? (<div style={{ marginBottom: "10px" }}><b>{t("messages.image_attached") + ":"}</b><span>{file.name}</span></div>) : ""}
            <div css="display:flex;align-items:flex-end;">
                {/* <Icon size="large" css="cursor:pointer;color:rgb(0,128,129) !important;" name="plus circle" /> */}
                <label htmlFor="custom_button_upload_image" title={t("messages.add_picture")}>
                    <i style={{ color: "rgb(0,128,129)", cursor: "pointer" }} className={`large ${file ? "file image" : "plus circle"} icon`}></i>
                    <input
                        id="custom_button_upload_image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => { e.target.files[0] && setFile(e.target.files[0]) }}
                        css="display:none;"
                    />
                </label>
                <div style={{ flexGrow: "2", margin: "0 7px" }} className="ui form">
                    <TextArea rows={10} onChange={(e) => textRef.current = (e.target.value || "")} />
                </div>
                <Icon onClick={handleSendMessage} title={t("messages.send_message")} size="large" css="cursor:pointer;color:rgb(0,128,129) !important;" name="send" />
            </div>
        </>
    )
}

export default NewMessage