export default [{ key: "AF", text: "Afghanistan", value: "AF" },
{ key: "AX", text: "Aland Islands", value: "AX" },
{ key: "AL", text: "Albania", value: "AL" },
{ key: "DZ", text: "Algeria", value: "DZ" },
{ key: "AS", text: "American Samoa", value: "AS" },
{ key: "AD", text: "Andorra", value: "AD" },
{ key: "AO", text: "Angola", value: "AO" },
{ key: "AI", text: "Anguilla", value: "AI" },
{ key: "AQ", text: "Antarctica", value: "AQ" },
{ key: "AG", text: "Antigua and Barbuda", value: "AG" },
{ key: "AR", text: "Argentina", value: "AR" },
{ key: "AM", text: "Armenia", value: "AM" },
{ key: "AW", text: "Aruba", value: "AW" },
{ key: "AU", text: "Australia", value: "AU" },
{ key: "AT", text: "Austria", value: "AT" },
{ key: "AZ", text: "Azerbaijan", value: "AZ" },
{ key: "A2", text: "Azores", value: "A2" },
{ key: "BS", text: "Bahamas", value: "BS" },
{ key: "BH", text: "Bahrain", value: "BH" },
{ key: "BD", text: "Bangladesh", value: "BD" },
{ key: "BB", text: "Barbados", value: "BB" },
{ key: "BY", text: "Belarus", value: "BY" },
{ key: "BE", text: "Belgium", value: "BE" },
{ key: "BZ", text: "Belize", value: "BZ" },
{ key: "BJ", text: "Benin", value: "BJ" },
{ key: "BM", text: "Bermuda", value: "BM" },
{ key: "BT", text: "Bhutan", value: "BT" },
{ key: "BO", text: "Bolivia", value: "BO" },
{ key: "BQ", text: "Bonaire, St. Eustatius, Saba", value: "BQ" },
{ key: "BA", text: "Bosnia and Herzegovina", value: "BA" },
{ key: "BW", text: "Botswana", value: "BW" },
{ key: "BV", text: "Bouvet Island", value: "BV" },
{ key: "BR", text: "Brazil", value: "BR" },
{ key: "IO", text: "British Indian Ocean Territory", value: "IO" },
{ key: "BN", text: "Brunei Darussalam", value: "BN" },
{ key: "BG", text: "Bulgaria", value: "BG" },
{ key: "BF", text: "Burkina Faso", value: "BF" },
{ key: "BI", text: "Burundi", value: "BI" },
{ key: "KH", text: "Cambodia", value: "KH" },
{ key: "CM", text: "Cameroon", value: "CM" },
{ key: "CA", text: "Canada", value: "CA" },
{ key: "IC", text: "Canary Islands", value: "IC" },
{ key: "CV", text: "Cabo Verde", value: "CV" },
{ key: "KY", text: "Cayman Islands", value: "KY" },
{ key: "CF", text: "Central African Republic", value: "CF" },
{ key: "XC", text: "Ceuta", value: "XC" },
{ key: "TD", text: "Chad", value: "TD" },
{ key: "CL", text: "Chile", value: "CL" },
{ key: "CN", text: "China Mainland", value: "CN" },
{ key: "CX", text: "Christmas Island", value: "CX" },
{ key: "CC", text: "Cocos Islands", value: "CC" },
{ key: "CO", text: "Colombia", value: "CO" },
{ key: "KM", text: "Comoros", value: "KM" },
{ key: "CG", text: "Congo", value: "CG" },
{ key: "CD", text: "Congo, The Democratic Republic of", value: "CD" },
{ key: "CK", text: "Cook Islands", value: "CK" },
{ key: "CR", text: "Costa Rica", value: "CR" },
{ key: "CI", text: "Cote d' Ivoire (Ivory Coast)", value: "CI" },
{ key: "HR", text: "Croatia", value: "HR" },
{ key: "CU", text: "Cuba", value: "CU" },
{ key: "CW", text: "Curacao", value: "CW" },
{ key: "CY", text: "Cyprus", value: "CY" },
{ key: "CZ", text: "Czech Republic", value: "CZ" },
{ key: "DK", text: "Denmark", value: "DK" },
{ key: "DJ", text: "Djibouti", value: "DJ" },
{ key: "DM", text: "Dominica", value: "DM" },
{ key: "DO", text: "Dominican Republic", value: "DO" },
{ key: "EC", text: "Ecuador", value: "EC" },
{ key: "EG", text: "Egypt", value: "EG" },
{ key: "SV", text: "El Salvador", value: "SV" },
{ key: "EN", text: "England", value: "EN" },
{ key: "GQ", text: "Equatorial Guinea", value: "GQ" },
{ key: "ER", text: "Eritrea", value: "ER" },
{ key: "EE", text: "Estonia", value: "EE" },
{ key: "ET", text: "Ethiopia", value: "ET" },
{ key: "FK", text: "Falkland Islands", value: "FK" },
{ key: "FO", text: "Faroe Islands", value: "FO" },
{ key: "FJ", text: "Fiji", value: "FJ" },
{ key: "FI", text: "Finland", value: "FI" },
{ key: "FR", text: "France", value: "FR" },
{ key: "GF", text: "French Guiana", value: "GF" },
{ key: "PF", text: "French Polynesia", value: "PF" },
{ key: "TF", text: "French Southern Territories", value: "TF" },
{ key: "GA", text: "Gabon", value: "GA" },
{ key: "GM", text: "Gambia", value: "GM" },
{ key: "GE", text: "Georgia", value: "GE" },
{ key: "DE", text: "Germany", value: "DE" },
{ key: "GH", text: "Ghana", value: "GH" },
{ key: "GI", text: "Gibraltar", value: "GI" },
{ key: "GR", text: "Greece", value: "GR" },
{ key: "GL", text: "Greenland", value: "GL" },
{ key: "GD", text: "Grenada", value: "GD" },
{ key: "GP", text: "Guadeloupe", value: "GP" },
{ key: "GU", text: "Guam", value: "GU" },
{ key: "GT", text: "Guatemala", value: "GT" },
{ key: "GG", text: "Guernsey", value: "GG" },
{ key: "GN", text: "Guinea", value: "GN" },
{ key: "GW", text: "Guinea-Bissau", value: "GW" },
{ key: "GY", text: "Guyana", value: "GY" },
{ key: "HT", text: "Haiti", value: "HT" },
{ key: "HM", text: "Heard Island and McDonald Islands", value: "HM" },
{ key: "HO", text: "Holland", value: "HO" },
{ key: "HN", text: "Honduras", value: "HN" },
{ key: "HK", text: "Hong Kong SAR, China", value: "HK" },
{ key: "HU", text: "Hungary", value: "HU" },
{ key: "IS", text: "Iceland", value: "IS" },
{ key: "IN", text: "India", value: "IN" },
{ key: "ID", text: "Indonesia", value: "ID" },
{ key: "IR", text: "Iran", value: "IR" },
{ key: "IQ", text: "Iraq", value: "IQ" },
{ key: "IE", text: "Ireland", value: "IE" },
{ key: "IM", text: "Isle of Man", value: "IM" },
{ key: "IL", text: "Israel", value: "IL" },
{ key: "IT", text: "Italy", value: "IT" },
{ key: "JM", text: "Jamaica", value: "JM" },
{ key: "JP", text: "Japan", value: "JP" },
{ key: "JE", text: "Jersey", value: "JE" },
{ key: "JO", text: "Jordan", value: "JO" },
{ key: "KZ", text: "Kazakhstan", value: "KZ" },
{ key: "KE", text: "Kenya", value: "KE" },
{ key: "KI", text: "Kiribati", value: "KI" },
{ key: "KR", text: "Korea, South", value: "KR" },
{ key: "KV", text: "Kosovo", value: "KV" },
{ key: "KO", text: "Kosrae", value: "KO" },
{ key: "KW", text: "Kuwait", value: "KW" },
{ key: "KG", text: "Kyrgyzstan", value: "KG" },
{ key: "LV", text: "Latvia", value: "LV" },
{ key: "LB", text: "Lebanon", value: "LB" },
{ key: "LS", text: "Lesotho", value: "LS" },
{ key: "LR", text: "Liberia", value: "LR" },
{ key: "LY", text: "Libya", value: "LY" },
{ key: "LI", text: "Liechtenstein", value: "LI" },
{ key: "LT", text: "Lithuania", value: "LT" },
{ key: "LU", text: "Luxembourg", value: "LU" },
{ key: "MO", text: "Macau SAR, China", value: "MO" },
{ key: "MK", text: "Macedonia", value: "MK" },
{ key: "MG", text: "Madagascar", value: "MG" },
{ key: "A3", text: "Madeira", value: "A3" },
{ key: "MW", text: "Malawi", value: "MW" },
{ key: "MY", text: "Malaysia", value: "MY" },
{ key: "MV", text: "Maldives", value: "MV" },
{ key: "ML", text: "Mali", value: "ML" },
{ key: "MT", text: "Malta", value: "MT" },
{ key: "MH", text: "Marshall Islands", value: "MH" },
{ key: "MQ", text: "Martinique", value: "MQ" },
{ key: "MR", text: "Mauritania", value: "MR" },
{ key: "MU", text: "Mauritius", value: "MU" },
{ key: "YT", text: "Mayotte", value: "YT" },
{ key: "XL", text: "Melilla", value: "XL" },
{ key: "MX", text: "Mexico", value: "MX" },
{ key: "FM", text: "Micronesia", value: "FM" },
{ key: "MD", text: "Moldova", value: "MD" },
{ key: "MC", text: "Monaco", value: "MC" },
{ key: "MN", text: "Mongolia", value: "MN" },
{ key: "ME", text: "Montenegro", value: "ME" },
{ key: "MS", text: "Montserrat", value: "MS" },
{ key: "MA", text: "Morocco", value: "MA" },
{ key: "MZ", text: "Mozambique", value: "MZ" },
{ key: "MM", text: "Myanmar", value: "MM" },
{ key: "NA", text: "Namibia", value: "NA" },
{ key: "NR", text: "Nauru", value: "NR" },
{ key: "NP", text: "Nepal", value: "NP" },
{ key: "NL", text: "Netherlands", value: "NL" },
{ key: "NC", text: "New Caledonia", value: "NC" },
{ key: "NZ", text: "New Zealand", value: "NZ" },
{ key: "NI", text: "Nicaragua", value: "NI" },
{ key: "NE", text: "Niger", value: "NE" },
{ key: "NG", text: "Nigeria", value: "NG" },
{ key: "NF", text: "Norfolk Island", value: "NF" },
{ key: "NB", text: "Northern Ireland", value: "NB" },
{ key: "MP", text: "Northern Mariana Islands", value: "MP" },
{ key: "NO", text: "Norway", value: "NO" },
{ key: "OM", text: "Oman", value: "OM" },
{ key: "PK", text: "Pakistan", value: "PK" },
{ key: "PW", text: "Palau", value: "PW" },
{ key: "PS", text: "Palestine", value: "PS" },
{ key: "PA", text: "Panama", value: "PA" },
{ key: "PG", text: "Papua New Guinea", value: "PG" },
{ key: "PY", text: "Paraguay", value: "PY" },
{ key: "PE", text: "Peru", value: "PE" },
{ key: "PH", text: "Philippines", value: "PH" },
{ key: "PN", text: "Pitcairn", value: "PN" },
{ key: "PL", text: "Poland", value: "PL" },
{ key: "PO", text: "Ponape", value: "PO" },
{ key: "PT", text: "Portugal", value: "PT" },
{ key: "PR", text: "Puerto Rico", value: "PR" },
{ key: "QA", text: "Qatar", value: "QA" },
{ key: "RE", text: "Reunion", value: "RE" },
{ key: "RO", text: "Romania", value: "RO" },
{ key: "RU", text: "Russia", value: "RU" },
{ key: "RW", text: "Rwanda", value: "RW" },
{ key: "BL", text: "Saint Barthelemy", value: "BL" },
{ key: "SW", text: "Saint Christopher", value: "SW" },
{ key: "C3", text: "Saint Croix", value: "C3" },
{ key: "UV", text: "Saint John", value: "UV" },
{ key: "KN", text: "Saint Kitts and Nevis", value: "KN" },
{ key: "LC", text: "Saint Lucia", value: "LC" },
{ key: "SX", text: "Saint Maarten and St. Martin", value: "SX" },
{ key: "VL", text: "Saint Thomas", value: "VL" },
{ key: "VC", text: "Saint Vincent and the Grenadines", value: "VC" },
{ key: "SP", text: "Saipan", value: "SP" },
{ key: "WS", text: "Samoa", value: "WS" },
{ key: "SM", text: "San Marino", value: "SM" },
{ key: "ST", text: "Sao Tome and Principe", value: "ST" },
{ key: "SA", text: "Saudi Arabia", value: "SA" },
{ key: "SF", text: "Scotland", value: "SF" },
{ key: "SN", text: "Senegal", value: "SN" },
{ key: "RS", text: "Serbia", value: "RS" },
{ key: "SC", text: "Seychelles", value: "SC" },
{ key: "SL", text: "Sierra Leone", value: "SL" },
{ key: "SG", text: "Singapore", value: "SG" },
{ key: "SK", text: "Slovakia", value: "SK" },
{ key: "SI", text: "Slovenia", value: "SI" },
{ key: "SB", text: "Solomon Islands", value: "SB" },
{ key: "ZA", text: "South Africa", value: "ZA" },
{ key: "ES", text: "Spain", value: "ES" },
{ key: "LK", text: "Sri Lanka", value: "LK" },
{ key: "SR", text: "Suriname", value: "SR" },
{ key: "SZ", text: "Swaziland", value: "SZ" },
{ key: "SE", text: "Sweden", value: "SE" },
{ key: "CH", text: "Switzerland", value: "CH" },
{ key: "TA", text: "Tahiti", value: "TA" },
{ key: "TW", text: "Taiwan, China", value: "TW" },
{ key: "TJ", text: "Tajikistan", value: "TJ" },
{ key: "TZ", text: "Tanzania", value: "TZ" },
{ key: "TH", text: "Thailand", value: "TH" },
{ key: "TL", text: "Timor-Leste", value: "TL" },
{ key: "TI", text: "Tinian", value: "TI" },
{ key: "TG", text: "Togo", value: "TG" },
{ key: "TO", text: "Tonga", value: "TO" },
{ key: "ZZ", text: "Tortola", value: "ZZ" },
{ key: "TT", text: "Trinidad and Tobago", value: "TT" },
{ key: "TU", text: "Truk", value: "TU" },
{ key: "TN", text: "Tunisia", value: "TN" },
{ key: "TR", text: "Turkey", value: "TR" },
{ key: "TM", text: "Turkmenistan", value: "TM" },
{ key: "TC", text: "Turks and Caicos Islands", value: "TC" },
{ key: "TV", text: "Tuvalu", value: "TV" },
{ key: "UG", text: "Uganda", value: "UG" },
{ key: "UA", text: "Ukraine", value: "UA" },
{ key: "UI", text: "Union Island", value: "UI" },
{ key: "AE", text: "United Arab Emirates", value: "AE" },
{ key: "GB", text: "United Kingdom", value: "GB" },
{ key: "US", text: "United States", value: "US" },
{ key: "UY", text: "Uruguay", value: "UY" },
{ key: "UZ", text: "Uzbekistan", value: "UZ" },
{ key: "VU", text: "Vanuatu", value: "VU" },
{ key: "VA", text: "Vatican City State", value: "VA" },
{ key: "VE", text: "Venezuela", value: "VE" },
{ key: "VN", text: "Vietnam", value: "VN" },
{ key: "VG", text: "Virgin Islands, British", value: "VG" },
{ key: "VI", text: "Virgin Islands, US", value: "VI" },
{ key: "WL", text: "Wales", value: "WL" },
{ key: "WF", text: "Wallis and Futuna Islands", value: "WF" },
{ key: "YA", text: "Yap", value: "YA" },
{ key: "YE", text: "Yemen", value: "YE" },
{ key: "ZM", text: "Zambia", value: "ZM" }]

export const uspscountry = [{ key: "AF", text: "Afghanistan", value: "Afghanistan" },
{ key: "AX", text: "Aland Islands", value: "Aland Islands" },
{ key: "AL", text: "Albania", value: "Albania" },
{ key: "DZ", text: "Algeria", value: "Algeria" },
{ key: "AS", text: "American Samoa", value: "American Samoa" },
{ key: "AD", text: "Andorra", value: "Andorra" },
{ key: "AO", text: "Angola", value: "Angola" },
{ key: "AI", text: "Anguilla", value: "Anguilla" },
{ key: "AQ", text: "Antarctica", value: "Antarctica" },
{ key: "AG", text: "Antigua and Barbuda", value: "Antigua and Barbuda" },
{ key: "AR", text: "Argentina", value: "Argentina" },
{ key: "AM", text: "Armenia", value: "Armenia" },
{ key: "AW", text: "Aruba", value: "Aruba" },
{ key: "AU", text: "Australia", value: "Australia" },
{ key: "AT", text: "Austria", value: "Austria" },
{ key: "AZ", text: "Azerbaijan", value: "Azerbaijan" },
{ key: "A2", text: "Azores", value: "Azores" },
{ key: "BS", text: "Bahamas", value: "Bahamas" },
{ key: "BH", text: "Bahrain", value: "Bahrain" },
{ key: "BD", text: "Bangladesh", value: "Bangladesh" },
{ key: "BB", text: "Barbados", value: "Barbados" },
{ key: "BY", text: "Belarus", value: "Belarus" },
{ key: "BE", text: "Belgium", value: "Belgium" },
{ key: "BZ", text: "Belize", value: "Belize" },
{ key: "BJ", text: "Benin", value: "Benin" },
{ key: "BM", text: "Bermuda", value: "Bermuda" },
{ key: "BT", text: "Bhutan", value: "Bhutan" },
{ key: "BO", text: "Bolivia", value: "Bolivia" },
{ key: "BQ", text: "Bonaire, St. Eustatius, Saba", value: "Bonaire, St. Eustatius, Saba" },
{ key: "BA", text: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
{ key: "BW", text: "Botswana", value: "Botswana" },
{ key: "BV", text: "Bouvet Island", value: "Bouvet Island" },
{ key: "BR", text: "Brazil", value: "Brazil" },
{ key: "IO", text: "British Indian Ocean Territory", value: "British Indian Ocean Territory" },
{ key: "BN", text: "Brunei Darussalam", value: "Brunei Darussalam" },
{ key: "BG", text: "Bulgaria", value: "Bulgaria" },
{ key: "BF", text: "Burkina Faso", value: "Burkina Faso" },
{ key: "BI", text: "Burundi", value: "Burundi" },
{ key: "KH", text: "Cambodia", value: "Cambodia" },
{ key: "CM", text: "Cameroon", value: "Cameroon" },
{ key: "CA", text: "Canada", value: "Canada" },
{ key: "IC", text: "Canary Islands", value: "Canary Islands" },
{ key: "CV", text: "Cabo Verde", value: "Cabo Verde" },
{ key: "KY", text: "Cayman Islands", value: "Cayman Islands" },
{ key: "CF", text: "Central African Republic", value: "Central African Republic" },
{ key: "XC", text: "Ceuta", value: "Ceuta" },
{ key: "TD", text: "Chad", value: "Chad" },
{ key: "CL", text: "Chile", value: "Chile" },
{ key: "CN", text: "China Mainland", value: "China Mainland" },
{ key: "CX", text: "Christmas Island", value: "Christmas Island" },
{ key: "CC", text: "Cocos Islands", value: "Cocos Islands" },
{ key: "CO", text: "Colombia", value: "Colombia" },
{ key: "KM", text: "Comoros", value: "Comoros" },
{ key: "CG", text: "Congo", value: "Congo" },
{ key: "CD", text: "Congo, The Democratic Republic of", value: "Congo, The Democratic Republic of" },
{ key: "CK", text: "Cook Islands", value: "Cook Islands" },
{ key: "CR", text: "Costa Rica", value: "Costa Rica" },
{ key: "CI", text: "Cote d' Ivoire (Ivory Coast)", value: "Cote d' Ivoire (Ivory Coast" },
{ key: "HR", text: "Croatia", value: "Croatia" },
{ key: "CU", text: "Cuba", value: "Cuba" },
{ key: "CW", text: "Curacao", value: "Curacao" },
{ key: "CY", text: "Cyprus", value: "Cyprus" },
{ key: "CZ", text: "Czech Republic", value: "Czech Republic" },
{ key: "DK", text: "Denmark", value: "Denmark" },
{ key: "DJ", text: "Djibouti", value: "Djibouti" },
{ key: "DM", text: "Dominica", value: "Dominica" },
{ key: "DO", text: "Dominican Republic", value: "Dominican Republic" },
{ key: "EC", text: "Ecuador", value: "Ecuador" },
{ key: "EG", text: "Egypt", value: "Egypt" },
{ key: "SV", text: "El Salvador", value: "El Salvador" },
{ key: "EN", text: "England", value: "England" },
{ key: "GQ", text: "Equatorial Guinea", value: "Equatorial Guinea" },
{ key: "ER", text: "Eritrea", value: "Eritrea" },
{ key: "EE", text: "Estonia", value: "Estonia" },
{ key: "ET", text: "Ethiopia", value: "Ethiopia" },
{ key: "FK", text: "Falkland Islands", value: "Falkland Islands" },
{ key: "FO", text: "Faroe Islands", value: "Faroe Islands" },
{ key: "FJ", text: "Fiji", value: "Fiji" },
{ key: "FI", text: "Finland", value: "Finland" },
{ key: "FR", text: "France", value: "France" },
{ key: "GF", text: "French Guiana", value: "French Guiana" },
{ key: "PF", text: "French Polynesia", value: "French Polynesia" },
{ key: "TF", text: "French Southern Territories", value: "French Southern Territories" },
{ key: "GA", text: "Gabon", value: "Gabon" },
{ key: "GM", text: "Gambia", value: "Gambia" },
{ key: "GE", text: "Georgia", value: "Georgia" },
{ key: "DE", text: "Germany", value: "Germany" },
{ key: "GH", text: "Ghana", value: "Ghana" },
{ key: "GI", text: "Gibraltar", value: "Gibraltar" },
{ key: "GR", text: "Greece", value: "Greece" },
{ key: "GL", text: "Greenland", value: "Greenland" },
{ key: "GD", text: "Grenada", value: "Grenada" },
{ key: "GP", text: "Guadeloupe", value: "Guadeloupe" },
{ key: "GU", text: "Guam", value: "Guam" },
{ key: "GT", text: "Guatemala", value: "Guatemala" },
{ key: "GG", text: "Guernsey", value: "Guernsey" },
{ key: "GN", text: "Guinea", value: "Guinea" },
{ key: "GW", text: "Guinea-Bissau", value: "Guinea-Bissau" },
{ key: "GY", text: "Guyana", value: "Guyana" },
{ key: "HT", text: "Haiti", value: "Haiti" },
{ key: "HM", text: "Heard Island and McDonald Islands", value: "Heard Island and McDonald Islands" },
{ key: "HO", text: "Holland", value: "Holland" },
{ key: "HN", text: "Honduras", value: "Honduras" },
{ key: "HK", text: "Hong Kong SAR, China", value: "Hong Kong SAR, China" },
{ key: "HU", text: "Hungary", value: "Hungary" },
{ key: "IS", text: "Iceland", value: "Iceland" },
{ key: "IN", text: "India", value: "India" },
{ key: "ID", text: "Indonesia", value: "Indonesia" },
{ key: "IR", text: "Iran", value: "Iran" },
{ key: "IQ", text: "Iraq", value: "Iraq" },
{ key: "IE", text: "Ireland", value: "Ireland" },
{ key: "IM", text: "Isle of Man", value: "Isle of Man" },
{ key: "IL", text: "Israel", value: "Israel" },
{ key: "IT", text: "Italy", value: "Italy" },
{ key: "JM", text: "Jamaica", value: "Jamaica" },
{ key: "JP", text: "Japan", value: "Japan" },
{ key: "JE", text: "Jersey", value: "Jersey" },
{ key: "JO", text: "Jordan", value: "Jordan" },
{ key: "KZ", text: "Kazakhstan", value: "Kazakhstan" },
{ key: "KE", text: "Kenya", value: "Kenya" },
{ key: "KI", text: "Kiribati", value: "Kiribati" },
{ key: "KR", text: "Korea, South", value: "Korea, South" },
{ key: "KV", text: "Kosovo", value: "Kosovo" },
{ key: "KO", text: "Kosrae", value: "Kosrae" },
{ key: "KW", text: "Kuwait", value: "Kuwait" },
{ key: "KG", text: "Kyrgyzstan", value: "Kyrgyzstan" },
{ key: "LV", text: "Latvia", value: "Latvia" },
{ key: "LB", text: "Lebanon", value: "Lebanon" },
{ key: "LS", text: "Lesotho", value: "Lesotho" },
{ key: "LR", text: "Liberia", value: "Liberia" },
{ key: "LY", text: "Libya", value: "Libya" },
{ key: "LI", text: "Liechtenstein", value: "Liechtenstein" },
{ key: "LT", text: "Lithuania", value: "Lithuania" },
{ key: "LU", text: "Luxembourg", value: "Luxembourg" },
{ key: "MO", text: "Macau SAR, China", value: "Macau SAR, China" },
{ key: "MK", text: "Macedonia", value: "Macedonia" },
{ key: "MG", text: "Madagascar", value: "Madagascar" },
{ key: "A3", text: "Madeira", value: "Madeira" },
{ key: "MW", text: "Malawi", value: "Malawi" },
{ key: "MY", text: "Malaysia", value: "Malaysia" },
{ key: "MV", text: "Maldives", value: "Maldives" },
{ key: "ML", text: "Mali", value: "Mali" },
{ key: "MT", text: "Malta", value: "Malta" },
{ key: "MH", text: "Marshall Islands", value: "Marshall Islands" },
{ key: "MQ", text: "Martinique", value: "Martinique" },
{ key: "MR", text: "Mauritania", value: "Mauritania" },
{ key: "MU", text: "Mauritius", value: "Mauritius" },
{ key: "YT", text: "Mayotte", value: "Mayotte" },
{ key: "XL", text: "Melilla", value: "Melilla" },
{ key: "MX", text: "Mexico", value: "Mexico" },
{ key: "FM", text: "Micronesia", value: "Micronesia" },
{ key: "MD", text: "Moldova", value: "Moldova" },
{ key: "MC", text: "Monaco", value: "Monaco" },
{ key: "MN", text: "Mongolia", value: "Mongolia" },
{ key: "ME", text: "Montenegro", value: "Montenegro" },
{ key: "MS", text: "Montserrat", value: "Montserrat" },
{ key: "MA", text: "Morocco", value: "Morocco" },
{ key: "MZ", text: "Mozambique", value: "Mozambique" },
{ key: "MM", text: "Myanmar", value: "Myanmar" },
{ key: "NA", text: "Namibia", value: "Namibia" },
{ key: "NR", text: "Nauru", value: "Nauru" },
{ key: "NP", text: "Nepal", value: "Nepal" },
{ key: "NL", text: "Netherlands", value: "Netherlands" },
{ key: "NC", text: "New Caledonia", value: "New Caledonia" },
{ key: "NZ", text: "New Zealand", value: "New Zealand" },
{ key: "NI", text: "Nicaragua", value: "Nicaragua" },
{ key: "NE", text: "Niger", value: "Niger" },
{ key: "NG", text: "Nigeria", value: "Nigeria" },
{ key: "NF", text: "Norfolk Island", value: "Norfolk Island" },
{ key: "NB", text: "Northern Ireland", value: "Northern Ireland" },
{ key: "MP", text: "Northern Mariana Islands", value: "Northern Mariana Islands" },
{ key: "NO", text: "Norway", value: "Norway" },
{ key: "OM", text: "Oman", value: "Oman" },
{ key: "PK", text: "Pakistan", value: "Pakistan" },
{ key: "PW", text: "Palau", value: "Palau" },
{ key: "PS", text: "Palestine", value: "Palestine" },
{ key: "PA", text: "Panama", value: "Panama" },
{ key: "PG", text: "Papua New Guinea", value: "Papua New Guinea" },
{ key: "PY", text: "Paraguay", value: "Paraguay" },
{ key: "PE", text: "Peru", value: "Peru" },
{ key: "PH", text: "Philippines", value: "Philippines" },
{ key: "PN", text: "Pitcairn", value: "Pitcairn" },
{ key: "PL", text: "Poland", value: "Poland" },
{ key: "PO", text: "Ponape", value: "Ponape" },
{ key: "PT", text: "Portugal", value: "Portugal" },
{ key: "PR", text: "Puerto Rico", value: "Puerto Rico" },
{ key: "QA", text: "Qatar", value: "Qatar" },
{ key: "RE", text: "Reunion", value: "Reunion" },
{ key: "RO", text: "Romania", value: "Romania" },
{ key: "RU", text: "Russia", value: "Russia" },
{ key: "RW", text: "Rwanda", value: "Rwanda" },
{ key: "BL", text: "Saint Barthelemy", value: "Saint Barthelemy" },
{ key: "SW", text: "Saint Christopher", value: "Saint Christopher" },
{ key: "C3", text: "Saint Croix", value: "Saint Croix" },
{ key: "UV", text: "Saint John", value: "Saint John" },
{ key: "KN", text: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
{ key: "LC", text: "Saint Lucia", value: "Saint Lucia" },
{ key: "SX", text: "Saint Maarten and St. Martin", value: "Saint Maarten and St. Martin" },
{ key: "VL", text: "Saint Thomas", value: "Saint Thomas" },
{ key: "VC", text: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
{ key: "SP", text: "Saipan", value: "Saipan" },
{ key: "WS", text: "Samoa", value: "Samoa" },
{ key: "SM", text: "San Marino", value: "San Marino" },
{ key: "ST", text: "Sao Tome and Principe", value: "Sao Tome and Principe" },
{ key: "SA", text: "Saudi Arabia", value: "Saudi Arabia" },
{ key: "SF", text: "Scotland", value: "Scotland" },
{ key: "SN", text: "Senegal", value: "Senegal" },
{ key: "RS", text: "Serbia", value: "Serbia" },
{ key: "SC", text: "Seychelles", value: "Seychelles" },
{ key: "SL", text: "Sierra Leone", value: "Sierra Leone" },
{ key: "SG", text: "Singapore", value: "Singapore" },
{ key: "SK", text: "Slovakia", value: "Slovakia" },
{ key: "SI", text: "Slovenia", value: "Slovenia" },
{ key: "SB", text: "Solomon Islands", value: "Solomon Islands" },
{ key: "ZA", text: "South Africa", value: "South Africa" },
{ key: "ES", text: "Spain", value: "Spain" },
{ key: "LK", text: "Sri Lanka", value: "Sri Lanka" },
{ key: "SR", text: "Suriname", value: "Suriname" },
{ key: "SZ", text: "Swaziland", value: "Swaziland" },
{ key: "SE", text: "Sweden", value: "Sweden" },
{ key: "CH", text: "Switzerland", value: "Switzerland" },
{ key: "TA", text: "Tahiti", value: "Tahiti" },
{ key: "TW", text: "Taiwan, China", value: "Taiwan, China" },
{ key: "TJ", text: "Tajikistan", value: "Tajikistan" },
{ key: "TZ", text: "Tanzania", value: "Tanzania" },
{ key: "TH", text: "Thailand", value: "Thailand" },
{ key: "TL", text: "Timor-Leste", value: "Timor-Leste" },
{ key: "TI", text: "Tinian", value: "Tinian" },
{ key: "TG", text: "Togo", value: "Togo" },
{ key: "TO", text: "Tonga", value: "Tonga" },
{ key: "ZZ", text: "Tortola", value: "Tortola" },
{ key: "TT", text: "Trinidad and Tobago", value: "Trinidad and Tobago" },
{ key: "TU", text: "Truk", value: "Truk" },
{ key: "TN", text: "Tunisia", value: "Tunisia" },
{ key: "TR", text: "Turkey", value: "Turkey" },
{ key: "TM", text: "Turkmenistan", value: "Turkmenistan" },
{ key: "TC", text: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
{ key: "TV", text: "Tuvalu", value: "Tuvalu" },
{ key: "UG", text: "Uganda", value: "Uganda" },
{ key: "UA", text: "Ukraine", value: "Ukraine" },
{ key: "UI", text: "Union Island", value: "Union Island" },
{ key: "AE", text: "United Arab Emirates", value: "United Arab Emirates" },
{ key: "GB", text: "United Kingdom", value: "United Kingdom" },
{ key: "US", text: "United States", value: "United States" },
{ key: "UY", text: "Uruguay", value: "Uruguay" },
{ key: "UZ", text: "Uzbekistan", value: "Uzbekistan" },
{ key: "VU", text: "Vanuatu", value: "Vanuatu" },
{ key: "VA", text: "Vatican City State", value: "Vatican City State" },
{ key: "VE", text: "Venezuela", value: "Venezuela" },
{ key: "VN", text: "Vietnam", value: "Vietnam" },
{ key: "VG", text: "Virgin Islands, British", value: "Virgin Islands, British" },
{ key: "VI", text: "Virgin Islands, US", value: "Virgin Islands, US" },
{ key: "WL", text: "Wales", value: "Wales" },
{ key: "WF", text: "Wallis and Futuna Islands", value: "Wallis and Futuna Islands" },
{ key: "YA", text: "Yap", value: "Yap" },
{ key: "YE", text: "Yemen", value: "Yemen" },
{ key: "ZM", text: "Zambia", value: "Zambia" }]

export const uspscountry1 = [{ key: "US", text: "United States", value: "US" }, { key: "Abu Dhabi (United Arab Emirates)", text: "Abu Dhabi (United Arab Emirates)", value: "Abu Dhabi (United Arab Emirates)" },
{ key: "Admiralty Islands (Papua New Guinea)", text: "Admiralty Islands (Papua New Guinea)", value: "Admiralty Islands (Papua New Guinea)" },
{ key: "Afghanistan", text: "Afghanistan", value: "Afghanistan" },
{ key: "Aitutaki, Cook Islands (New Zealand)", text: "Aitutaki, Cook Islands (New Zealand)", value: "Aitutaki, Cook Islands (New Zealand)" },
{ key: "Ajman (United Arab Emirates)", text: "Ajman (United Arab Emirates)", value: "Ajman (United Arab Emirates)" },
{ key: "Aland Island (Finland)", text: "Aland Island (Finland)", value: "Aland Island (Finland)" },
{ key: "Albania", text: "Albania", value: "Albania" },
{ key: "Alberta (Canada)", text: "Alberta (Canada)", value: "Alberta (Canada)" },
{ key: "Alderney (Channel Islands) (United Kingdom)", text: "Alderney (Channel Islands) (United Kingdom)", value: "Alderney (Channel Islands) (United Kingdom)" },
{ key: "Algeria", text: "Algeria", value: "Algeria" },
{ key: "Alhucemas (Spain)", text: "Alhucemas (Spain)", value: "Alhucemas (Spain)" },
{ key: "Alofi Island (New Caledonia)", text: "Alofi Island (New Caledonia)", value: "Alofi Island (New Caledonia)" },
{ key: "Andaman Islands (India)", text: "Andaman Islands (India)", value: "Andaman Islands (India)" },
{ key: "Andorra", text: "Andorra", value: "Andorra" },
{ key: "Angola", text: "Angola", value: "Angola" },
{ key: "Anguilla", text: "Anguilla", value: "Anguilla" },
{ key: "Anjouan (Comoros)", text: "Anjouan (Comoros)", value: "Anjouan (Comoros)" },
{ key: "Annobon Island (Equatorial Guinea)", text: "Annobon Island (Equatorial Guinea)", value: "Annobon Island (Equatorial Guinea)" },
{ key: "Antigua (Antigua and Barbuda)", text: "Antigua (Antigua and Barbuda)", value: "Antigua (Antigua and Barbuda)" },
{ key: "Antigua and Barbuda", text: "Antigua and Barbuda", value: "Antigua and Barbuda" },
{ key: "Argentina", text: "Argentina", value: "Argentina" },
{ key: "Armenia", text: "Armenia", value: "Armenia" },
{ key: "Aruba", text: "Aruba", value: "Aruba" },
{ key: "Ascension", text: "Ascension", value: "Ascension" },
{ key: "Astypalaia (Greece)", text: "Astypalaia (Greece)", value: "Astypalaia (Greece)" },
{ key: "Atafu (Samoa)", text: "Atafu (Samoa)", value: "Atafu (Samoa)" },
{ key: "Atiu, Cook Islands (New Zealand)", text: "Atiu, Cook Islands (New Zealand)", value: "Atiu, Cook Islands (New Zealand)" },
{ key: "Australia", text: "Australia", value: "Australia" },
{ key: "Austria", text: "Austria", value: "Austria" },
{ key: "Avarua (New Zealand)", text: "Avarua (New Zealand)", value: "Avarua (New Zealand)" },
{ key: "Azerbaijan", text: "Azerbaijan", value: "Azerbaijan" },
{ key: "Azores (Portugal)", text: "Azores (Portugal)", value: "Azores (Portugal)" },
{ key: "Bahamas", text: "Bahamas", value: "Bahamas" },
{ key: "Bahrain", text: "Bahrain", value: "Bahrain" },
{ key: "Balearic Islands (Spain)", text: "Balearic Islands (Spain)", value: "Balearic Islands (Spain)" },
{ key: "Balochistan (Pakistan)", text: "Balochistan (Pakistan)", value: "Balochistan (Pakistan)" },
{ key: "Bangladesh", text: "Bangladesh", value: "Bangladesh" },
{ key: "Banks Island (Vanuatu)", text: "Banks Island (Vanuatu)", value: "Banks Island (Vanuatu)" },
{ key: "Barbados", text: "Barbados", value: "Barbados" },
{ key: "Barbuda (Antigua and Barbuda)", text: "Barbuda (Antigua and Barbuda)", value: "Barbuda (Antigua and Barbuda)" },
{ key: "Barthelemy (Guadeloupe)", text: "Barthelemy (Guadeloupe)", value: "Barthelemy (Guadeloupe)" },
{ key: "Belarus", text: "Belarus", value: "Belarus" },
{ key: "Belgium", text: "Belgium", value: "Belgium" },
{ key: "Belize", text: "Belize", value: "Belize" },
{ key: "Benin", text: "Benin", value: "Benin" },
{ key: "Bermuda", text: "Bermuda", value: "Bermuda" },
{ key: "Bhutan", text: "Bhutan", value: "Bhutan" },
{ key: "Bismark Archipelago (Papua New Guinea)", text: "Bismark Archipelago (Papua New Guinea)", value: "Bismark Archipelago (Papua New Guinea)" },
{ key: "Bolivia", text: "Bolivia", value: "Bolivia" },
{ key: "Bonaire, Sint Eustatius, and Saba", text: "Bonaire, Sint Eustatius, and Saba", value: "Bonaire, Sint Eustatius, and Saba" },
{ key: "Bora Bora (French Polynesia)", text: "Bora Bora (French Polynesia)", value: "Bora Bora (French Polynesia)" },
{ key: "Borneo (Indonesia)", text: "Borneo (Indonesia)", value: "Borneo (Indonesia)" },
{ key: "Bosnia-Herzegovina", text: "Bosnia-Herzegovina", value: "Bosnia-Herzegovina" },
{ key: "Botswana", text: "Botswana", value: "Botswana" },
{ key: "Bougainville (Papua New Guinea)", text: "Bougainville (Papua New Guinea)", value: "Bougainville (Papua New Guinea)" },
{ key: "Bourbon (Reunion)", text: "Bourbon (Reunion)", value: "Bourbon (Reunion)" },
{ key: "Brazil", text: "Brazil", value: "Brazil" },
{ key: "British Columbia (Canada)", text: "British Columbia (Canada)", value: "British Columbia (Canada)" },
{ key: "British Guiana (Guyana)", text: "British Guiana (Guyana)", value: "British Guiana (Guyana)" },
{ key: "British Virgin Islands", text: "British Virgin Islands", value: "British Virgin Islands" },
{ key: "Brunei Darussalam", text: "Brunei Darussalam", value: "Brunei Darussalam" },
{ key: "Buka (Papua New Guinea)", text: "Buka (Papua New Guinea)", value: "Buka (Papua New Guinea)" },
{ key: "Bulgaria", text: "Bulgaria", value: "Bulgaria" },
{ key: "Burkina Faso", text: "Burkina Faso", value: "Burkina Faso" },
{ key: "Burma", text: "Burma", value: "Burma" },
{ key: "Burundi", text: "Burundi", value: "Burundi" },
{ key: "Caicos Islands (Turks and Caicos Islands)", text: "Caicos Islands (Turks and Caicos Islands)", value: "Caicos Islands (Turks and Caicos Islands)" },
{ key: "Cambodia", text: "Cambodia", value: "Cambodia" },
{ key: "Cameroon", text: "Cameroon", value: "Cameroon" },
{ key: "Canada", text: "Canada", value: "Canada" },
{ key: "Canary Islands (Spain)", text: "Canary Islands (Spain)", value: "Canary Islands (Spain)" },
{ key: "Canton Island (Kiribati)", text: "Canton Island (Kiribati)", value: "Canton Island (Kiribati)" },
{ key: "Cape Verde", text: "Cape Verde", value: "Cape Verde" },
{ key: "Cayman Islands", text: "Cayman Islands", value: "Cayman Islands" },
{ key: "Central African Republic", text: "Central African Republic", value: "Central African Republic" },
{ key: "Ceuta (Spain)", text: "Ceuta (Spain)", value: "Ceuta (Spain)" },
{ key: "Ceylon (Sri Lanka)", text: "Ceylon (Sri Lanka)", value: "Ceylon (Sri Lanka)" },
{ key: "Chad", text: "Chad", value: "Chad" },
{ key: "Chaferinas Islands (Spain)", text: "Chaferinas Islands (Spain)", value: "Chaferinas Islands (Spain)" },
{ key: "Chalki (Greece)", text: "Chalki (Greece)", value: "Chalki (Greece)" },
{ key: "Chile", text: "Chile", value: "Chile" },
{ key: "China", text: "China", value: "China" },
{ key: "Christmas Island (Australia)", text: "Christmas Island (Australia)", value: "Christmas Island (Australia)" },
{ key: "Christmas Island (Kiribati)", text: "Christmas Island (Kiribati)", value: "Christmas Island (Kiribati)" },
{ key: "Cocos Island (Australia)", text: "Cocos Island (Australia)", value: "Cocos Island (Australia)" },
{ key: "Colombia", text: "Colombia", value: "Colombia" },
{ key: "Comoros", text: "Comoros", value: "Comoros" },
{ key: "Congo, Democratic Republic of the", text: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
{ key: "Congo, Republic of the", text: "Congo, Republic of the", value: "Congo, Republic of the" },
{ key: "Cook Islands (New Zealand)", text: "Cook Islands (New Zealand)", value: "Cook Islands (New Zealand)" },
{ key: "Corisco Island (Equatorial Guinea)", text: "Corisco Island (Equatorial Guinea)", value: "Corisco Island (Equatorial Guinea)" },
{ key: "Corsica (France)", text: "Corsica (France)", value: "Corsica (France)" },
{ key: "Costa Rica", text: "Costa Rica", value: "Costa Rica" },
{ key: "Cote d’Ivoire", text: "Cote d’Ivoire", value: "Cote d’Ivoire" },
{ key: "Crete (Greece)", text: "Crete (Greece)", value: "Crete (Greece)" },
{ key: "Croatia", text: "Croatia", value: "Croatia" },
{ key: "Cuba", text: "Cuba", value: "Cuba" },
{ key: "Cumino Island (Malta)", text: "Cumino Island (Malta)", value: "Cumino Island (Malta)" },
{ key: "Curacao", text: "Curacao", value: "Curacao" },
{ key: "Cyjrenaica (Libya)", text: "Cyjrenaica (Libya)", value: "Cyjrenaica (Libya)" },
{ key: "Cyprus", text: "Cyprus", value: "Cyprus" },
{ key: "Czech Republic", text: "Czech Republic", value: "Czech Republic" },
{ key: "Dahomey (Benin)", text: "Dahomey (Benin)", value: "Dahomey (Benin)" },
{ key: "Damao (India)", text: "Damao (India)", value: "Damao (India)" },
{ key: "Danger Islands (New Zealand)", text: "Danger Islands (New Zealand)", value: "Danger Islands (New Zealand)" },
{ key: "Denmark", text: "Denmark", value: "Denmark" },
{ key: "Desirade Island (Guadeloupe)", text: "Desirade Island (Guadeloupe)", value: "Desirade Island (Guadeloupe)" },
{ key: "Diu (India)", text: "Diu (India)", value: "Diu (India)" },
{ key: "Djibouti", text: "Djibouti", value: "Djibouti" },
{ key: "Dodecanese Islands (Greece)", text: "Dodecanese Islands (Greece)", value: "Dodecanese Islands (Greece)" },
{ key: "Doha (Qatar)", text: "Doha (Qatar)", value: "Doha (Qatar)" },
{ key: "Dominica", text: "Dominica", value: "Dominica" },
{ key: "Dominican Republic", text: "Dominican Republic", value: "Dominican Republic" },
{ key: "Dubai (United Arab Emirates)", text: "Dubai (United Arab Emirates)", value: "Dubai (United Arab Emirates)" },
{ key: "East Timor (Timor-Leste)", text: "East Timor (Timor-Leste)", value: "East Timor (Timor-Leste)" },
{ key: "Ecuador", text: "Ecuador", value: "Ecuador" },
{ key: "Egypt", text: "Egypt", value: "Egypt" },
{ key: "Eire (Ireland)", text: "Eire (Ireland)", value: "Eire (Ireland)" },
{ key: "El Salvador", text: "El Salvador", value: "El Salvador" },
{ key: "Ellice Islands (Tuvalu)", text: "Ellice Islands (Tuvalu)", value: "Ellice Islands (Tuvalu)" },
{ key: "Elobey Islands (Equatorial Guinea)", text: "Elobey Islands (Equatorial Guinea)", value: "Elobey Islands (Equatorial Guinea)" },
{ key: "Enderbury Island (Kiribati)", text: "Enderbury Island (Kiribati)", value: "Enderbury Island (Kiribati)" },
{ key: "England (United Kingdom)", text: "England (United Kingdom)", value: "England (United Kingdom)" },
{ key: "Equatorial Guinea", text: "Equatorial Guinea", value: "Equatorial Guinea" },
{ key: "Eritrea", text: "Eritrea", value: "Eritrea" },
{ key: "Estonia", text: "Estonia", value: "Estonia" },
{ key: "Eswatini", text: "Eswatini", value: "Eswatini" },
{ key: "Ethiopia", text: "Ethiopia", value: "Ethiopia" },
{ key: "Fakaofo (Samoa)", text: "Fakaofo (Samoa)", value: "Fakaofo (Samoa)" },
{ key: "Falkland Islands", text: "Falkland Islands", value: "Falkland Islands" },
{ key: "Fanning Island (Kiribati)", text: "Fanning Island (Kiribati)", value: "Fanning Island (Kiribati)" },
{ key: "Faroe Islands", text: "Faroe Islands", value: "Faroe Islands" },
{ key: "Fernando Po (Equatorial Guinea)", text: "Fernando Po (Equatorial Guinea)", value: "Fernando Po (Equatorial Guinea)" },
{ key: "Fezzan (Libya)", text: "Fezzan (Libya)", value: "Fezzan (Libya)" },
{ key: "Fiji", text: "Fiji", value: "Fiji" },
{ key: "Finland", text: "Finland", value: "Finland" },
{ key: "Formosa (Taiwan)", text: "Formosa (Taiwan)", value: "Formosa (Taiwan)" },
{ key: "France", text: "France", value: "France" },
{ key: "French Guiana", text: "French Guiana", value: "French Guiana" },
{ key: "French Oceania (French Polynesia)", text: "French Oceania (French Polynesia)", value: "French Oceania (French Polynesia)" },
{ key: "French Polynesia", text: "French Polynesia", value: "French Polynesia" },
{ key: "French West Indies (Guadeloupe)", text: "French West Indies (Guadeloupe)", value: "French West Indies (Guadeloupe)" },
{ key: "French West Indies (Martinique)", text: "French West Indies (Martinique)", value: "French West Indies (Martinique)" },
{ key: "Friendly Islands (Tonga)", text: "Friendly Islands (Tonga)", value: "Friendly Islands (Tonga)" },
{ key: "Fujairah (United Arab Emirates)", text: "Fujairah (United Arab Emirates)", value: "Fujairah (United Arab Emirates)" },
{ key: "Futuna (Wallis and Futuna Islands)", text: "Futuna (Wallis and Futuna Islands)", value: "Futuna (Wallis and Futuna Islands)" },
{ key: "Gabon", text: "Gabon", value: "Gabon" },
{ key: "Gambia", text: "Gambia", value: "Gambia" },
{ key: "Gambier (French Polynesia)", text: "Gambier (French Polynesia)", value: "Gambier (French Polynesia)" },
{ key: "Georgia, Republic of", text: "Georgia, Republic of", value: "Georgia, Republic of" },
{ key: "Germany", text: "Germany", value: "Germany" },
{ key: "Ghana", text: "Ghana", value: "Ghana" },
{ key: "Gibraltar", text: "Gibraltar", value: "Gibraltar" },
{ key: "Gilbert Islands (Kiribati)", text: "Gilbert Islands (Kiribati)", value: "Gilbert Islands (Kiribati)" },
{ key: "Goa (India)", text: "Goa (India)", value: "Goa (India)" },
{ key: "Gozo Island (Malta)", text: "Gozo Island (Malta)", value: "Gozo Island (Malta)" },
{ key: "Grand Comoro (Comoros)", text: "Grand Comoro (Comoros)", value: "Grand Comoro (Comoros)" },
{ key: "Great Britain (United Kingdom)", text: "Great Britain (United Kingdom)", value: "Great Britain (United Kingdom)" },
{ key: "Greece", text: "Greece", value: "Greece" },
{ key: "Greenland", text: "Greenland", value: "Greenland" },
{ key: "Grenada", text: "Grenada", value: "Grenada" },
{ key: "Grenadines (Saint Vincent and the Grenadines)", text: "Grenadines (Saint Vincent and the Grenadines)", value: "Grenadines (Saint Vincent and the Grenadines)" },
{ key: "Guadeloupe", text: "Guadeloupe", value: "Guadeloupe" },
{ key: "Guatemala", text: "Guatemala", value: "Guatemala" },
{ key: "Guernsey (Channel Islands) (United Kingdom)", text: "Guernsey (Channel Islands) (United Kingdom)", value: "Guernsey (Channel Islands) (United Kingdom)" },
{ key: "Guinea", text: "Guinea", value: "Guinea" },
{ key: "Guinea–Bissau", text: "Guinea–Bissau", value: "Guinea–Bissau" },
{ key: "Guyana", text: "Guyana", value: "Guyana" },
{ key: "Hainan Island (China)", text: "Hainan Island (China)", value: "Hainan Island (China)" },
{ key: "Haiti", text: "Haiti", value: "Haiti" },
{ key: "Hashemite Kingdom (Jordan)", text: "Hashemite Kingdom (Jordan)", value: "Hashemite Kingdom (Jordan)" },
{ key: "Hervey, Cook Islands (New Zealand)", text: "Hervey, Cook Islands (New Zealand)", value: "Hervey, Cook Islands (New Zealand)" },
{ key: "Hivaoa (French Polynesia)", text: "Hivaoa (French Polynesia)", value: "Hivaoa (French Polynesia)" },
{ key: "Holland (Netherlands)", text: "Holland (Netherlands)", value: "Holland (Netherlands)" },
{ key: "Honduras", text: "Honduras", value: "Honduras" },
{ key: "Hong Kong", text: "Hong Kong", value: "Hong Kong" },
{ key: "Huahine (French Polynesia)", text: "Huahine (French Polynesia)", value: "Huahine (French Polynesia)" },
{ key: "Huan Island (New Caledonia)", text: "Huan Island (New Caledonia)", value: "Huan Island (New Caledonia)" },
{ key: "Hungary", text: "Hungary", value: "Hungary" },
{ key: "Iceland", text: "Iceland", value: "Iceland" },
{ key: "India", text: "India", value: "India" },
{ key: "Indonesia", text: "Indonesia", value: "Indonesia" },
{ key: "Iran", text: "Iran", value: "Iran" },
{ key: "Iraq", text: "Iraq", value: "Iraq" },
{ key: "Ireland", text: "Ireland", value: "Ireland" },
{ key: "Irian Barat (Indonesia)", text: "Irian Barat (Indonesia)", value: "Irian Barat (Indonesia)" },
{ key: "Isle of Man (United Kingdom)", text: "Isle of Man (United Kingdom)", value: "Isle of Man (United Kingdom)" },
{ key: "Isle of Pines (New Caledonia)", text: "Isle of Pines (New Caledonia)", value: "Isle of Pines (New Caledonia)" },
{ key: "Isle of Pines, West Indies (Cuba)", text: "Isle of Pines, West Indies (Cuba)", value: "Isle of Pines, West Indies (Cuba)" },
{ key: "Israel", text: "Israel", value: "Israel" },
{ key: "Issas (Djibouti)", text: "Issas (Djibouti)", value: "Issas (Djibouti)" },
{ key: "Italy", text: "Italy", value: "Italy" },
{ key: "Ivory Coast (Cote d’Ivoire)", text: "Ivory Coast (Cote d’Ivoire)", value: "Ivory Coast (Cote d’Ivoire)" },
{ key: "Jamaica", text: "Jamaica", value: "Jamaica" },
{ key: "Japan", text: "Japan", value: "Japan" },
{ key: "Jersey (Channel Islands) (United Kingdom)", text: "Jersey (Channel Islands) (United Kingdom)", value: "Jersey (Channel Islands) (United Kingdom)" },
{ key: "Johore (Malaysia)", text: "Johore (Malaysia)", value: "Johore (Malaysia)" },
{ key: "Jordan", text: "Jordan", value: "Jordan" },
{ key: "Kalymnos (Greece)", text: "Kalymnos (Greece)", value: "Kalymnos (Greece)" },
{ key: "Kampuchea (Cambodia)", text: "Kampuchea (Cambodia)", value: "Kampuchea (Cambodia)" },
{ key: "Karpathos (Greece)", text: "Karpathos (Greece)", value: "Karpathos (Greece)" },
{ key: "Kassos (Greece)", text: "Kassos (Greece)", value: "Kassos (Greece)" },
{ key: "Kastellorizon (Greece)", text: "Kastellorizon (Greece)", value: "Kastellorizon (Greece)" },
{ key: "Kazakhstan", text: "Kazakhstan", value: "Kazakhstan" },
{ key: "Kedah (Malaysia)", text: "Kedah (Malaysia)", value: "Kedah (Malaysia)" },
{ key: "Keeling Islands (Australia)", text: "Keeling Islands (Australia)", value: "Keeling Islands (Australia)" },
{ key: "Kelantan (Malaysia)", text: "Kelantan (Malaysia)", value: "Kelantan (Malaysia)" },
{ key: "Kenya", text: "Kenya", value: "Kenya" },
{ key: "Kiribati", text: "Kiribati", value: "Kiribati" },
{ key: "Korea, Republic of (South Korea)", text: "Korea, Republic of (South Korea)", value: "Korea, Republic of (South Korea)" },
{ key: "Kos (Greece)", text: "Kos (Greece)", value: "Kos (Greece)" },
{ key: "Kosovo, Republic of", text: "Kosovo, Republic of", value: "Kosovo, Republic of" },
{ key: "Kowloon (Hong Kong)", text: "Kowloon (Hong Kong)", value: "Kowloon (Hong Kong)" },
{ key: "Kuwait", text: "Kuwait", value: "Kuwait" },
{ key: "Kyrgyzstan", text: "Kyrgyzstan", value: "Kyrgyzstan" },
{ key: "Labrador (Canada)", text: "Labrador (Canada)", value: "Labrador (Canada)" },
{ key: "Labuan (Malaysia)", text: "Labuan (Malaysia)", value: "Labuan (Malaysia)" },
{ key: "Laos", text: "Laos", value: "Laos" },
{ key: "Latvia", text: "Latvia", value: "Latvia" },
{ key: "Lebanon", text: "Lebanon", value: "Lebanon" },
{ key: "Leipsos (Greece)", text: "Leipsos (Greece)", value: "Leipsos (Greece)" },
{ key: "Leros (Greece)", text: "Leros (Greece)", value: "Leros (Greece)" },
{ key: "Les Saints Island (Guadeloupe)", text: "Les Saints Island (Guadeloupe)", value: "Les Saints Island (Guadeloupe)" },
{ key: "Lesotho", text: "Lesotho", value: "Lesotho" },
{ key: "Liberia", text: "Liberia", value: "Liberia" },
{ key: "Libya", text: "Libya", value: "Libya" },
{ key: "Liechtenstein", text: "Liechtenstein", value: "Liechtenstein" },
{ key: "Lithuania", text: "Lithuania", value: "Lithuania" },
{ key: "Lord Howe Island (Australia)", text: "Lord Howe Island (Australia)", value: "Lord Howe Island (Australia)" },
{ key: "Loyalty Islands (New Caledonia)", text: "Loyalty Islands (New Caledonia)", value: "Loyalty Islands (New Caledonia)" },
{ key: "Luxembourg", text: "Luxembourg", value: "Luxembourg" },
{ key: "Macao", text: "Macao", value: "Macao" },
{ key: "Macau (Macao)", text: "Macau (Macao)", value: "Macau (Macao)" },
{ key: "Madagascar", text: "Madagascar", value: "Madagascar" },
{ key: "Madeira Islands (Portugal)", text: "Madeira Islands (Portugal)", value: "Madeira Islands (Portugal)" },
{ key: "Malacca (Malaysia)", text: "Malacca (Malaysia)", value: "Malacca (Malaysia)" },
{ key: "Malawi", text: "Malawi", value: "Malawi" },
{ key: "Malaysia", text: "Malaysia", value: "Malaysia" },
{ key: "Maldives", text: "Maldives", value: "Maldives" },
{ key: "Mali", text: "Mali", value: "Mali" },
{ key: "Malta", text: "Malta", value: "Malta" },
{ key: "Manahiki (New Zealand)", text: "Manahiki (New Zealand)", value: "Manahiki (New Zealand)" },
{ key: "Manchuria (China)", text: "Manchuria (China)", value: "Manchuria (China)" },
{ key: "Manitoba (Canada)", text: "Manitoba (Canada)", value: "Manitoba (Canada)" },
{ key: "Marie Galante (Guadeloupe)", text: "Marie Galante (Guadeloupe)", value: "Marie Galante (Guadeloupe)" },
{ key: "Marquesas Islands (French Polynesia)", text: "Marquesas Islands (French Polynesia)", value: "Marquesas Islands (French Polynesia)" },
{ key: "Martinique", text: "Martinique", value: "Martinique" },
{ key: "Mauritania", text: "Mauritania", value: "Mauritania" },
{ key: "Mauritius", text: "Mauritius", value: "Mauritius" },
{ key: "Mayotte (France)", text: "Mayotte (France)", value: "Mayotte (France)" },
{ key: "Melilla (Spain)", text: "Melilla (Spain)", value: "Melilla (Spain)" },
{ key: "Mexico", text: "Mexico", value: "Mexico" },
{ key: "Miquelon (Saint Pierre and Miquelon)", text: "Miquelon (Saint Pierre and Miquelon)", value: "Miquelon (Saint Pierre and Miquelon)" },
{ key: "Moheli (Comoros)", text: "Moheli (Comoros)", value: "Moheli (Comoros)" },
{ key: "Moldova", text: "Moldova", value: "Moldova" },
{ key: "Monaco (France)", text: "Monaco (France)", value: "Monaco (France)" },
{ key: "Mongolia", text: "Mongolia", value: "Mongolia" },
{ key: "Montenegro", text: "Montenegro", value: "Montenegro" },
{ key: "Montserrat", text: "Montserrat", value: "Montserrat" },
{ key: "Moorea (French Polynesia)", text: "Moorea (French Polynesia)", value: "Moorea (French Polynesia)" },
{ key: "Morocco", text: "Morocco", value: "Morocco" },
{ key: "Mozambique", text: "Mozambique", value: "Mozambique" },
{ key: "Muscat (Oman)", text: "Muscat (Oman)", value: "Muscat (Oman)" },
{ key: "Myanmar (Burma)", text: "Myanmar (Burma)", value: "Myanmar (Burma)" },
{ key: "Namibia", text: "Namibia", value: "Namibia" },
{ key: "Nansil Islands (Japan)", text: "Nansil Islands (Japan)", value: "Nansil Islands (Japan)" },
{ key: "Nauru", text: "Nauru", value: "Nauru" },
{ key: "Negri Sembilan (Malaysia)", text: "Negri Sembilan (Malaysia)", value: "Negri Sembilan (Malaysia)" },
{ key: "Nepal", text: "Nepal", value: "Nepal" },
{ key: "Netherlands", text: "Netherlands", value: "Netherlands" },
{ key: "Nevis (Saint Kitts and Nevis)", text: "Nevis (Saint Kitts and Nevis)", value: "Nevis (Saint Kitts and Nevis)" },
{ key: "New Britain (Papua New Guinea)", text: "New Britain (Papua New Guinea)", value: "New Britain (Papua New Guinea)" },
{ key: "New Brunswick (Canada)", text: "New Brunswick (Canada)", value: "New Brunswick (Canada)" },
{ key: "New Caledonia", text: "New Caledonia", value: "New Caledonia" },
{ key: "New Hanover (Papua New Guinea)", text: "New Hanover (Papua New Guinea)", value: "New Hanover (Papua New Guinea)" },
{ key: "New Hebrides (Vanuatu)", text: "New Hebrides (Vanuatu)", value: "New Hebrides (Vanuatu)" },
{ key: "New Ireland (Papua New Guinea)", text: "New Ireland (Papua New Guinea)", value: "New Ireland (Papua New Guinea)" },
{ key: "New South Wales (Australia)", text: "New South Wales (Australia)", value: "New South Wales (Australia)" },
{ key: "New Zealand", text: "New Zealand", value: "New Zealand" },
{ key: "Newfoundland (Canada)", text: "Newfoundland (Canada)", value: "Newfoundland (Canada)" },
{ key: "Nicaragua", text: "Nicaragua", value: "Nicaragua" },
{ key: "Niger", text: "Niger", value: "Niger" },
{ key: "Nigeria", text: "Nigeria", value: "Nigeria" },
{ key: "Nissiros (Greece)", text: "Nissiros (Greece)", value: "Nissiros (Greece)" },
{ key: "Niue (New Zealand)", text: "Niue (New Zealand)", value: "Niue (New Zealand)" },
{ key: "Norfolk Island (Australia)", text: "Norfolk Island (Australia)", value: "Norfolk Island (Australia)" },
{ key: "North Borneo (Malaysia)", text: "North Borneo (Malaysia)", value: "North Borneo (Malaysia)" },
{ key: "North Macedonia, Republic of", text: "North Macedonia, Republic of", value: "North Macedonia, Republic of" },
{ key: "Northern Ireland (United Kingdom)", text: "Northern Ireland (United Kingdom)", value: "Northern Ireland (United Kingdom)" },
{ key: "Northwest Territory (Canada)", text: "Northwest Territory (Canada)", value: "Northwest Territory (Canada)" },
{ key: "Norway", text: "Norway", value: "Norway" },
{ key: "Nova Scotia (Canada)", text: "Nova Scotia (Canada)", value: "Nova Scotia (Canada)" },
{ key: "Nukahiva (French Polynesia)", text: "Nukahiva (French Polynesia)", value: "Nukahiva (French Polynesia)" },
{ key: "Nukunonu (Samoa)", text: "Nukunonu (Samoa)", value: "Nukunonu (Samoa)" },
{ key: "Ocean Island (Kiribati)", text: "Ocean Island (Kiribati)", value: "Ocean Island (Kiribati)" },
{ key: "Okinawa (Japan)", text: "Okinawa (Japan)", value: "Okinawa (Japan)" },
{ key: "Oman", text: "Oman", value: "Oman" },
{ key: "Ontario (Canada)", text: "Ontario (Canada)", value: "Ontario (Canada)" },
{ key: "Pahang (Malaysia)", text: "Pahang (Malaysia)", value: "Pahang (Malaysia)" },
{ key: "Pakistan", text: "Pakistan", value: "Pakistan" },
{ key: "Palmerston, Avarua (New Zealand)", text: "Palmerston, Avarua (New Zealand)", value: "Palmerston, Avarua (New Zealand)" },
{ key: "Panama", text: "Panama", value: "Panama" },
{ key: "Papua New Guinea", text: "Papua New Guinea", value: "Papua New Guinea" },
{ key: "Paraguay", text: "Paraguay", value: "Paraguay" },
{ key: "Parry, Cook Islands (New Zealand)", text: "Parry, Cook Islands (New Zealand)", value: "Parry, Cook Islands (New Zealand)" },
{ key: "Patmos (Greece)", text: "Patmos (Greece)", value: "Patmos (Greece)" },
{ key: "Pemba (Tanzania)", text: "Pemba (Tanzania)", value: "Pemba (Tanzania)" },
{ key: "Penang (Malaysia)", text: "Penang (Malaysia)", value: "Penang (Malaysia)" },
{ key: "Penghu Islands (Taiwan)", text: "Penghu Islands (Taiwan)", value: "Penghu Islands (Taiwan)" },
{ key: "Penon de Velez de la Gomera (Spain)", text: "Penon de Velez de la Gomera (Spain)", value: "Penon de Velez de la Gomera (Spain)" },
{ key: "Penrhyn, Tongareva (New Zealand)", text: "Penrhyn, Tongareva (New Zealand)", value: "Penrhyn, Tongareva (New Zealand)" },
{ key: "Perak (Malaysia)", text: "Perak (Malaysia)", value: "Perak (Malaysia)" },
{ key: "Perlis (Malaysia)", text: "Perlis (Malaysia)", value: "Perlis (Malaysia)" },
{ key: "Persia (Iran)", text: "Persia (Iran)", value: "Persia (Iran)" },
{ key: "Peru", text: "Peru", value: "Peru" },
{ key: "Pescadores Islands (Taiwan)", text: "Pescadores Islands (Taiwan)", value: "Pescadores Islands (Taiwan)" },
{ key: "Petite Terre (Guadeloupe)", text: "Petite Terre (Guadeloupe)", value: "Petite Terre (Guadeloupe)" },
{ key: "Philippines", text: "Philippines", value: "Philippines" },
{ key: "Pitcairn Island", text: "Pitcairn Island", value: "Pitcairn Island" },
{ key: "Poland", text: "Poland", value: "Poland" },
{ key: "Portugal", text: "Portugal", value: "Portugal" },
{ key: "Prince Edward Island (Canada)", text: "Prince Edward Island (Canada)", value: "Prince Edward Island (Canada)" },
{ key: "Pukapuka (New Zealand)", text: "Pukapuka (New Zealand)", value: "Pukapuka (New Zealand)" },
{ key: "Qatar", text: "Qatar", value: "Qatar" },
{ key: "Quebec (Canada)", text: "Quebec (Canada)", value: "Quebec (Canada)" },
{ key: "Queensland (Australia)", text: "Queensland (Australia)", value: "Queensland (Australia)" },
{ key: "Quemoy (Taiwan)", text: "Quemoy (Taiwan)", value: "Quemoy (Taiwan)" },
{ key: "Raiatea (French Polynesia)", text: "Raiatea (French Polynesia)", value: "Raiatea (French Polynesia)" },
{ key: "Rakaanga (New Zealand)", text: "Rakaanga (New Zealand)", value: "Rakaanga (New Zealand)" },
{ key: "Rapa (French Polynesia)", text: "Rapa (French Polynesia)", value: "Rapa (French Polynesia)" },
{ key: "Rarotonga, Cook Islands (New Zealand)", text: "Rarotonga, Cook Islands (New Zealand)", value: "Rarotonga, Cook Islands (New Zealand)" },
{ key: "Ras al Kaimah (United Arab Emirates)", text: "Ras al Kaimah (United Arab Emirates)", value: "Ras al Kaimah (United Arab Emirates)" },
{ key: "Redonda (Antigua and Barbuda)", text: "Redonda (Antigua and Barbuda)", value: "Redonda (Antigua and Barbuda)" },
{ key: "Reunion", text: "Reunion", value: "Reunion" },
{ key: "Rio Muni (Equatorial Guinea)", text: "Rio Muni (Equatorial Guinea)", value: "Rio Muni (Equatorial Guinea)" },
{ key: "Rodos (Greece)", text: "Rodos (Greece)", value: "Rodos (Greece)" },
{ key: "Rodrigues (Mauritius)", text: "Rodrigues (Mauritius)", value: "Rodrigues (Mauritius)" },
{ key: "Romania", text: "Romania", value: "Romania" },
{ key: "Russia", text: "Russia", value: "Russia" },
{ key: "Rwanda", text: "Rwanda", value: "Rwanda" },
{ key: "Saba (Bonaire, Sint Eustatius, and Saba)", text: "Saba (Bonaire, Sint Eustatius, and Saba)", value: "Saba (Bonaire, Sint Eustatius, and Saba)" },
{ key: "Sabah (Malaysia)", text: "Sabah (Malaysia)", value: "Sabah (Malaysia)" },
{ key: "Saint Barthelemy (Guadeloupe)", text: "Saint Barthelemy (Guadeloupe)", value: "Saint Barthelemy (Guadeloupe)" },
{ key: "Saint Bartholomew (Guadeloupe)", text: "Saint Bartholomew (Guadeloupe)", value: "Saint Bartholomew (Guadeloupe)" },
{ key: "Saint Helena", text: "Saint Helena", value: "Saint Helena" },
{ key: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
{ key: "Saint Lucia", text: "Saint Lucia", value: "Saint Lucia" },
{ key: "Saint Martin (French) (Guadeloupe)", text: "Saint Martin (French) (Guadeloupe)", value: "Saint Martin (French) (Guadeloupe)" },
{ key: "Saint Pierre and Miquelon", text: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
{ key: "Saint Vincent and the Grenadines", text: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
{ key: "Sainte Marie de Madagascar (Madagascar)", text: "Sainte Marie de Madagascar (Madagascar)", value: "Sainte Marie de Madagascar (Madagascar)" },
{ key: "Salvador (El Salvador)", text: "Salvador (El Salvador)", value: "Salvador (El Salvador)" },
{ key: "Samoa", text: "Samoa", value: "Samoa" },
{ key: "San Marino", text: "San Marino", value: "San Marino" },
{ key: "Santa Cruz Islands (Solomon Islands)", text: "Santa Cruz Islands (Solomon Islands)", value: "Santa Cruz Islands (Solomon Islands)" },
{ key: "Sao Tome and Principe", text: "Sao Tome and Principe", value: "Sao Tome and Principe" },
{ key: "Sarawak (Malaysia)", text: "Sarawak (Malaysia)", value: "Sarawak (Malaysia)" },
{ key: "Sark (Channel Islands) (United Kingdom)", text: "Sark (Channel Islands) (United Kingdom)", value: "Sark (Channel Islands) (United Kingdom)" },
{ key: "Saskatchewan (Canada)", text: "Saskatchewan (Canada)", value: "Saskatchewan (Canada)" },
{ key: "Saudi Arabia", text: "Saudi Arabia", value: "Saudi Arabia" },
{ key: "Savage Island, Niue (New Zealand)", text: "Savage Island, Niue (New Zealand)", value: "Savage Island, Niue (New Zealand)" },
{ key: "Savaii Island (Samoa)", text: "Savaii Island (Samoa)", value: "Savaii Island (Samoa)" },
{ key: "Scotland (United Kingdom)", text: "Scotland (United Kingdom)", value: "Scotland (United Kingdom)" },
{ key: "Seberang Perai (Malaysia)", text: "Seberang Perai (Malaysia)", value: "Seberang Perai (Malaysia)" },
{ key: "Selangor (Malaysia)", text: "Selangor (Malaysia)", value: "Selangor (Malaysia)" },
{ key: "Senegal", text: "Senegal", value: "Senegal" },
{ key: "Serbia, Republic of", text: "Serbia, Republic of", value: "Serbia, Republic of" },
{ key: "Seychelles", text: "Seychelles", value: "Seychelles" },
{ key: "Sharja (United Arab Emirates)", text: "Sharja (United Arab Emirates)", value: "Sharja (United Arab Emirates)" },
{ key: "Shikoku (Japan)", text: "Shikoku (Japan)", value: "Shikoku (Japan)" },
{ key: "Sierra Leone", text: "Sierra Leone", value: "Sierra Leone" },
{ key: "Sikkim (India)", text: "Sikkim (India)", value: "Sikkim (India)" },
{ key: "Singapore", text: "Singapore", value: "Singapore" },
{ key: "Sint Eustatius (Bonaire, Sint Eustatius, and Saba)", text: "Sint Eustatius (Bonaire, Sint Eustatius, and Saba)", value: "Sint Eustatius (Bonaire, Sint Eustatius, and Saba)" },
{ key: "Sint Maarten (Dutch)", text: "Sint Maarten (Dutch)", value: "Sint Maarten (Dutch)" },
{ key: "Slovak Republic (Slovakia)", text: "Slovak Republic (Slovakia)", value: "Slovak Republic (Slovakia)" },
{ key: "Slovenia", text: "Slovenia", value: "Slovenia" },
{ key: "Society Islands (French Polynesia)", text: "Society Islands (French Polynesia)", value: "Society Islands (French Polynesia)" },
{ key: "Solomon Islands", text: "Solomon Islands", value: "Solomon Islands" },
{ key: "Somali Democratic Republic (Somalia)", text: "Somali Democratic Republic (Somalia)", value: "Somali Democratic Republic (Somalia)" },
{ key: "Somalia", text: "Somalia", value: "Somalia" },
{ key: "Somaliland (Somalia)", text: "Somaliland (Somalia)", value: "Somaliland (Somalia)" },
{ key: "South Africa", text: "South Africa", value: "South Africa" },
{ key: "South Australia (Australia)", text: "South Australia (Australia)", value: "South Australia (Australia)" },
{ key: "South Georgia (Falkland Islands)", text: "South Georgia (Falkland Islands)", value: "South Georgia (Falkland Islands)" },
{ key: "South Korea (Korea, Republic of)", text: "South Korea (Korea, Republic of)", value: "South Korea (Korea, Republic of)" },
{ key: "South Sudan, Republic of", text: "South Sudan, Republic of", value: "South Sudan, Republic of" },
{ key: "Spain", text: "Spain", value: "Spain" },
{ key: "Spitzbergen (Norway)", text: "Spitzbergen (Norway)", value: "Spitzbergen (Norway)" },
{ key: "Sri Lanka", text: "Sri Lanka", value: "Sri Lanka" },
{ key: "Sudan", text: "Sudan", value: "Sudan" },
{ key: "Suriname", text: "Suriname", value: "Suriname" },
{ key: "Suwarrow Islands (New Zealand)", text: "Suwarrow Islands (New Zealand)", value: "Suwarrow Islands (New Zealand)" },
{ key: "Swan Islands (Honduras)", text: "Swan Islands (Honduras)", value: "Swan Islands (Honduras)" },
{ key: "Swaziland (Eswatini)", text: "Swaziland (Eswatini)", value: "Swaziland (Eswatini)" },
{ key: "Sweden", text: "Sweden", value: "Sweden" },
{ key: "Switzerland", text: "Switzerland", value: "Switzerland" },
{ key: "Symi (Greece)", text: "Symi (Greece)", value: "Symi (Greece)" },
{ key: "Syrian Arab Republic (Syria)", text: "Syrian Arab Republic (Syria)", value: "Syrian Arab Republic (Syria)" },
{ key: "Tahaa (French Polynesia)", text: "Tahaa (French Polynesia)", value: "Tahaa (French Polynesia)" },
{ key: "Tahiti (French Polynesia)", text: "Tahiti (French Polynesia)", value: "Tahiti (French Polynesia)" },
{ key: "Taiwan", text: "Taiwan", value: "Taiwan" },
{ key: "Tajikistan", text: "Tajikistan", value: "Tajikistan" },
{ key: "Tanzania", text: "Tanzania", value: "Tanzania" },
{ key: "Tasmania (Australia)", text: "Tasmania (Australia)", value: "Tasmania (Australia)" },
{ key: "Tchad (Chad)", text: "Tchad (Chad)", value: "Tchad (Chad)" },
{ key: "Thailand", text: "Thailand", value: "Thailand" },
{ key: "Thursday Island (Australia)", text: "Thursday Island (Australia)", value: "Thursday Island (Australia)" },
{ key: "Tibet (China)", text: "Tibet (China)", value: "Tibet (China)" },
{ key: "Tilos (Greece)", text: "Tilos (Greece)", value: "Tilos (Greece)" },
{ key: "Timor (Indonesia)", text: "Timor (Indonesia)", value: "Timor (Indonesia)" },
{ key: "Timor-Leste, Democratic Republic of", text: "Timor-Leste, Democratic Republic of", value: "Timor-Leste, Democratic Republic of" },
{ key: "Tobago (Trinidad and Tobago)", text: "Tobago (Trinidad and Tobago)", value: "Tobago (Trinidad and Tobago)" },
{ key: "Togo", text: "Togo", value: "Togo" },
{ key: "Tokelau (New Zealand)", text: "Tokelau (New Zealand)", value: "Tokelau (New Zealand)" },
{ key: "Tonga", text: "Tonga", value: "Tonga" },
{ key: "Tongareva (New Zealand)", text: "Tongareva (New Zealand)", value: "Tongareva (New Zealand)" },
{ key: "Tori Shima (Japan)", text: "Tori Shima (Japan)", value: "Tori Shima (Japan)" },
{ key: "Torres Island (Vanuatu)", text: "Torres Island (Vanuatu)", value: "Torres Island (Vanuatu)" },
{ key: "Trans-Jordan, Hashemite Kingdom (Jordan)", text: "Trans-Jordan, Hashemite Kingdom (Jordan)", value: "Trans-Jordan, Hashemite Kingdom (Jordan)" },
{ key: "Transkei (South Africa)", text: "Transkei (South Africa)", value: "Transkei (South Africa)" },
{ key: "Trengganu (Malaysia)", text: "Trengganu (Malaysia)", value: "Trengganu (Malaysia)" },
{ key: "Trinidad and Tobago", text: "Trinidad and Tobago", value: "Trinidad and Tobago" },
{ key: "Tripolitania (Libya)", text: "Tripolitania (Libya)", value: "Tripolitania (Libya)" },
{ key: "Tristan da Cunha", text: "Tristan da Cunha", value: "Tristan da Cunha" },
{ key: "Trucial States (United Arab Emirates)", text: "Trucial States (United Arab Emirates)", value: "Trucial States (United Arab Emirates)" },
{ key: "Tuamotou (French Polynesia)", text: "Tuamotou (French Polynesia)", value: "Tuamotou (French Polynesia)" },
{ key: "Tubuai (French Polynesia)", text: "Tubuai (French Polynesia)", value: "Tubuai (French Polynesia)" },
{ key: "Tunisia", text: "Tunisia", value: "Tunisia" },
{ key: "Turkey", text: "Turkey", value: "Turkey" },
{ key: "Turkmenistan", text: "Turkmenistan", value: "Turkmenistan" },
{ key: "Turks and Caicos Islands", text: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
{ key: "Tuvalu", text: "Tuvalu", value: "Tuvalu" },
{ key: "Uganda", text: "Uganda", value: "Uganda" },
{ key: "Ukraine", text: "Ukraine", value: "Ukraine" },
{ key: "Umm al Quaiwain (United Arab Emirates)", text: "Umm al Quaiwain (United Arab Emirates)", value: "Umm al Quaiwain (United Arab Emirates)" },
{ key: "Umm Said (Qatar)", text: "Umm Said (Qatar)", value: "Umm Said (Qatar)" },
{ key: "United Arab Emirates", text: "United Arab Emirates", value: "United Arab Emirates" },
{ key: "United Kingdom of Great Britain and Northern Ireland", text: "United Kingdom of Great Britain and Northern Ireland", value: "United Kingdom of Great Britain and Northern Ireland" },
{ key: "Upolu Island (Samoa)", text: "Upolu Island (Samoa)", value: "Upolu Island (Samoa)" },
{ key: "Uruguay", text: "Uruguay", value: "Uruguay" },
{ key: "Uzbekistan", text: "Uzbekistan", value: "Uzbekistan" },
{ key: "Vanuatu", text: "Vanuatu", value: "Vanuatu" },
{ key: "Vatican City", text: "Vatican City", value: "Vatican City" },
{ key: "Venezuela", text: "Venezuela", value: "Venezuela" },
{ key: "Victoria (Australia)", text: "Victoria (Australia)", value: "Victoria (Australia)" },
{ key: "Vietnam", text: "Vietnam", value: "Vietnam" },
{ key: "Virgin Islands (British)", text: "Virgin Islands (British)", value: "Virgin Islands (British)" },
{ key: "Wales (United Kingdom)", text: "Wales (United Kingdom)", value: "Wales (United Kingdom)" },
{ key: "Wallis and Futuna Islands", text: "Wallis and Futuna Islands", value: "Wallis and Futuna Islands" },
{ key: "Wellesley, Province (Malaysia)", text: "Wellesley, Province (Malaysia)", value: "Wellesley, Province (Malaysia)" },
{ key: "West New Guinea (Indonesia)", text: "West New Guinea (Indonesia)", value: "West New Guinea (Indonesia)" },
{ key: "Western Australia (Australia)", text: "Western Australia (Australia)", value: "Western Australia (Australia)" },
{ key: "Yemen", text: "Yemen", value: "Yemen" },
{ key: "Yukon Territory (Canada)", text: "Yukon Territory (Canada)", value: "Yukon Territory (Canada)" },
{ key: "Zafarani Islands (Spain)", text: "Zafarani Islands (Spain)", value: "Zafarani Islands (Spain)" },
{ key: "Zambia", text: "Zambia", value: "Zambia" },
{ key: "Zanzibar (Tanzania)", text: "Zanzibar (Tanzania)", value: "Zanzibar (Tanzania)" },
{ key: "Zimbabwe", text: "Zimbabwe", value: "Zimbabwe" }]





export const parseXML = (c, res) => {
    let data = res.data;
    let arr = []
    let err = false
    let p = new DOMParser()
    let doc = p.parseFromString(data, "text/xml")
    if (unitedState(c)) {
        let postage = doc.getElementsByTagName("Postage")
        for (let i = 0; i < postage.length; i++) {
            const code = postage[i].getAttribute("CLASSID")
            let service = postage[i].getElementsByTagName("MailService")[0]
            let rate = postage[i].getElementsByTagName("Rate")[0]
            let name = service.textContent.split("&")[0]
            name += service.textContent.split(";")[service.textContent.split(";").length - 1]
            arr.push({ code, name: name, total: "USD " + rate.textContent })
        }
    } else {
        let postage = doc.getElementsByTagName("Service")
        for (let i = 0; i < postage.length; i++) {
            const code = postage[i].getAttribute("CLASSID")
            let service = postage[i].getElementsByTagName("SvcDescription")[0]
            let rate = postage[i].getElementsByTagName("Postage")[0]
            arr.push({ code, name: service.textContent.split("&")[0], total: "USD " + rate.textContent })
        }
    }
    let error = doc.getElementsByTagName("Error")
    for (let i = 0; i < error.length; i++) {
        let description = error[i].getElementsByTagName("Description")[0]
        err = description.textContent
        break
    }
    /*let arr2=[],arr3=[]
    for(let i=0;i<arr.length;i++){
        if(!arr2.includes(arr[i].name)){
            arr2.push(arr[i].name)
            arr3.push(arr[i])
        }
    }*/
    //return arr
    //console.log(arr)

    return [err, arr]
}

const unitedState = (v) => {
    const c = ["American Samoa", "US Virgin Islands", "Micronesia", "Marshall Islands", "Palau", "Northern Mariana Islands", "Puerto Rico", "United States"]
    const i = c.findIndex((x) => { return x.toLowerCase() == v.toLowerCase() })
    return i > -1
}


export const timezones = [
    { key: "(GMT -12:00) Eniwetok, Kwajalein", text: "(GMT -12:00) Eniwetok, Kwajalein", value: "(GMT -12:00) Eniwetok, Kwajalein" },
    { key: "(GMT -11:00) Midway Island, Samoa", text: "(GMT -11:00) Midway Island, Samoa", value: "(GMT -11:00) Midway Island, Samoa" },
    { key: "(GMT -10:00) Hawaii", text: "(GMT -10:00) Hawaii", value: "(GMT -10:00) Hawaii" }, 
    { key: "(GMT -9:30) Taiohae", text: "(GMT -9:30) Taiohae", value: "(GMT -9:30) Taiohae" },
    { key: "(GMT -9:00) Alaska", text: "(GMT -9:00) Alaska", value: "(GMT -9:00) Alaska" },
    { key: "(GMT -8:00) Pacific Time (US & Canada)", text: "(GMT -8:00) Pacific Time (US & Canada)", value: "(GMT -8:00) Pacific Time (US & Canada)" },
    { key: "(GMT -7:00) Mountain Time (US & Canada)", text: "(GMT -7:00) Mountain Time (US & Canada)", value: "(GMT -7:00) Mountain Time (US & Canada)" },
    { key: "(GMT -6:00) Central Time (US & Canada), Mexico City", text: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "(GMT -6:00) Central Time (US & Canada), Mexico City" },
    { key: "(GMT -6:00) Central America", text: "(GMT -6:00) Central America", value: "(GMT -6:00) Central America" },
    { key: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", text: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima" },
    { key: "(GMT -4:30) Caracas", text: "(GMT -4:30) Caracas", value: "(GMT -4:30) Caracas" },
    { key: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", text: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
    { key: "(GMT -3:30) Newfoundland", text: "(GMT -3:30) Newfoundland", value: "(GMT -3:30) Newfoundland" },
    { key: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", text: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
    { key: "(GMT -2:00) Mid-Atlantic", text: "(GMT -2:00) Mid-Atlantic", value: "(GMT -2:00) Mid-Atlantic" },
    { key: "(GMT -1:00) Azores, Cape Verde Islands", text: "(GMT -1:00) Azores, Cape Verde Islands", value: "(GMT -1:00) Azores, Cape Verde Islands" },
    { key: "(GMT) Western Europe Time, London, Lisbon, Casablanca", text: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
    { key: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", text: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris" },
    { key: "(GMT +2:00) Kaliningrad, South Africa", text: "(GMT +2:00) Kaliningrad, South Africa", value: "(GMT +2:00) Kaliningrad, South Africa" },
    { key: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", text: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
    { key: "(GMT +3:30) Tehran", text: "(GMT +3:30) Tehran", value: "(GMT +3:30) Tehran" },
    { key: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", text: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
    { key: "(GMT +4:30) Kabul", text: "(GMT +4:30) Kabul", value: "(GMT +4:30) Kabul" },
    { key: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", text: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
    { key: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", text: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
    { key: "(GMT +5:45) Kathmandu, Pokhara", text: "(GMT +5:45) Kathmandu, Pokhara", value: "(GMT +5:45) Kathmandu, Pokhara" },
    { key: "(GMT +6:00) Almaty, Dhaka, Colombo", text: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "(GMT +6:00) Almaty, Dhaka, Colombo" },
    { key: "(GMT +6:30) Yangon, Mandalay", text: "(GMT +6:30) Yangon, Mandalay", value: "(GMT +6:30) Yangon, Mandalay" },
    { key: "(GMT +7:00) Bangkok, Hanoi, Jakarta", text: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
    { key: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", text: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
    { key: "(GMT +8:45) Eucla", text: "(GMT +8:45) Eucla", value: "(GMT +8:45) Eucla" },
    { key: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", text: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
    { key: "(GMT +9:30) Adelaide, Darwin", text: "(GMT +9:30) Adelaide, Darwin", value: "(GMT +9:30) Adelaide, Darwin" },
    { key: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", text: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
    { key: "(GMT +10:30) Lord Howe Island", text: "(GMT +10:30) Lord Howe Island", value: "(GMT +10:30) Lord Howe Island" },
    { key: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", text: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
    { key: "(GMT +11:30) Norfolk Island", text: "(GMT +11:30) Norfolk Island", value: "(GMT +11:30) Norfolk Island" },
    { key: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", text: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
    { key: "(GMT +12:45) Chatham Islands", text: "(GMT +12:45) Chatham Islands", value: "(GMT +12:45) Chatham Islands" },
    { key: "(GMT +13:00) Apia, Nukualofa", text: "(GMT +13:00) Apia, Nukualofa", value: "(GMT +13:00) Apia, Nukualofa" },
    { key: "(GMT +14:00) Line Islands, Tokelau", text: "(GMT +14:00) Line Islands, Tokelau", value: "(GMT +14:00) Line Islands, Tokelau" }
]
export const timezones2 = [
    { key: "-12:00", text: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
    { key: "-11:00", text: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
    { key: "-10:00", text: "(GMT -10:00) Hawaii", value: "-10:00" },
    { key: "-09:30", text: "(GMT -9:30) Taiohae", value: "-09:30" },
    { key: "-09:00", text: "(GMT -9:00) Alaska", value: "-09:00" },
    { key: "-08:00", text: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
    { key: "-07:00", text: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00" },
    { key: "-06:00", text: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06:00" },
    { key: "-06:00", text: "(GMT -6:00) Central America", value: "-06:00" },
    { key: "-05:00", text: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05:00" },
    { key: "-04:30", text: "(GMT -4:30) Caracas", value: "-04:30" },
    { key: "-04:00", text: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00" },
    { key: "-03:30", text: "(GMT -3:30) Newfoundland", value: "-03:30" },
    { key: "-03:00", text: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
    { key: "-02:00", text: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
    { key: "-01:00", text: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
    { key: "+00:00", text: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00" },
    { key: "+01:00", text: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
    { key: "+02:00", text: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
    { key: "+03:00", text: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00" },
    { key: "+03:30", text: "(GMT +3:30) Tehran", value: "+03:30" },
    { key: "+04:00", text: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
    { key: "+04:30", text: "(GMT +4:30) Kabul", value: "+04:30" },
    { key: "+05:00", text: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00" },
    { key: "+05:30", text: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:30" },
    { key: "+05:75", text: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
    { key: "+06:00", text: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
    { key: "+06:30", text: "(GMT +6:30) Yangon, Mandalay", value: "+06:30" },
    { key: "+07:00", text: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
    { key: "+08:00", text: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00" },
    { key: "+08:75", text: "(GMT +8:45) Eucla", value: "+08:75" },
    { key: "+09:00", text: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
    { key: "+09:30", text: "(GMT +9:30) Adelaide, Darwin", value: "+09:30" },
    { key: "+10:00", text: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00" },
    { key: "+10:30", text: "(GMT +10:30) Lord Howe Island", value: "+10:30" },
    { key: "+11:00", text: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00" },
    { key: "+11:30", text: "(GMT +11:30) Norfolk Island", value: "+11:30" },
    { key: "+12:00", text: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00" },
    { key: "+12:75", text: "(GMT +12:45) Chatham Islands", value: "+12:75" },
    { key: "+13:00", text: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
    { key: "+14:00", text: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" }
]

export const languages=[
    {
        key:"English",
        text:"English"
    },
    {
        key:"Spanish",
        text:"Spanish"
    }
]