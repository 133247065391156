import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Segment, Tab } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { uiActions } from '../store/ui-slice'
import { formData } from '../shared/formDataCreator'
import { PayPalButton } from 'react-paypal-button-v2'
import { getTimez, url_environment } from '../config'
import env from '../config.env'
const {paypal_client_id}=env

const ViewTimesheetAdmin = (props) => {
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [taxes, setTaxes] = useState({})
    const [filesData, setFilesData] = useState([])
    const fileRef = useRef(undefined)
    const handleAddFileClick = useCallback(() => {
        fileRef.current.click()
    }, [])
    const { userInfo } = useSelector(state => state.user)
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    // const [values, setValues] = useState({

    // })
    const [values, setValues] = useState({})
    const dispatch = useDispatch();
    const data = props.data
    const loadData = async () => {
        setLoading(true)
        try {
            console.log("props contra:", props.contra)
            console.log("props:", props)
            setFilesData(data.files.map(x => { const ss = x.substring(x.lastIndexOf(".") + 1); const t = ss != "pdf" ? "image" : "pdf"; return { name: x.substring(x.lastIndexOf("/") + 1), type: t, image_link: url_environment + x } }))
            const { data: taxes } = await axios.get(`/api/product/taxes?service=${props.contract.service}&amount=${(parseFloat(getTimez(data.time_from, data.time_to)) * props.price).toFixed(2)}`, config)
            setTaxes(taxes)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const total_amount = (parseFloat(getTimez(data.time_from, data.time_to)) * props.price)
    const total_payment = (total_amount + taxes.totalTax).toFixed(2)
    useEffect(() => {
        if (!props.open) return;
        loadData()
    }, [props.open])
    if (!props.open)
        return <></>

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{t("contractmgmt.view_timesheet")}</Modal.Header>
                <Modal.Content >
                    <div className="ui form">
                        <div className="ui field">
                            <label>{t("contractmgmt.date")}</label>
                            <Segment>{`${new Date(data.date).toLocaleDateString()}`}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.total_time")}</label>
                            <Segment>{getTimez(data.time_from, data.time_to) + " " + t("contractmgmt.hours")}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.total_taxes")}</label>
                            <Segment>USD ${taxes.totalTax}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.total_payment")}</label>
                            <Segment>USD ${total_payment}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.time")}</label>
                            <Segment>{`${data.time_from} ${t("contractmgmt.to")} ${data.time_to}`}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.text")}</label>
                            {/* <textarea name="text" onChange={handleInputChange} style={{ width: "100%" }} rows="10"></textarea> */}
                            <Segment>{data.text}</Segment>
                        </div>
                    </div>
                    <h5 css="text-align:center;">{t("contractmgmt.screenshots_files")}</h5>

                    <div>
                        <div css="display:flex;align-items:center;justify-content:center;">
                            <div>
                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                    {filesData.map((item) => {
                                        return <>
                                            <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                    <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                        {!item.type.startsWith("image") && <a target="_blank" href={item.image_link} /*href={`https://interpowered.com:3001/uploads/${item.name}`}*/ css="color:black; &:hover{color:black;}display:flex;flex-direction:column;width:100%;" >
                                                            <div>
                                                                <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                            </div>
                                                            <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                        </a>}
                                                        {item.type.startsWith("image") && <a target="_blank" href={item.image_link} /*href={`https://interpowered.com:3001/uploads/${item.name}`}*/ css="color:black; &:hover{color:black;}display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                            <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                        </a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ViewTimesheetAdmin