import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { divImage, profimage1, profimage2 } from '../styles'
import { url_environment } from '../../config'

const TabCV = ({ active, values, freelancersImage1, freelancersImage2, freelancersImage3, imageLink1, imageLink2, imageLink3 }) => {
    const [t, i18n] = useTranslation("global")

    const getPDFName = (s) => {
        let fin = s.lastIndexOf("_")
        let ini = s.lastIndexOf("/") + 1
        return s.substring(ini, fin) + ".pdf"
    }
    return (
        <>
            <div className="ui grid container ">

                <div className="ten wide column">
                    <label css="font-weight:700;" >{t("profile.Freelancer_Category")}: </label>
                    <span>{values.category_name}</span>
                </div>

                <div className="six wide column">
                    <label css="font-weight:700;">{t("profile.Career")}: </label>
                    <span>{values.career}</span>

                </div>

                <div className="ten wide column">
                    <label css="font-weight:700;">{t("profile.Certifications")}: </label>
                    <span>{values.certifications}</span>


                </div>
                <div className="six wide column">
                    <label css="font-weight:700;">{t("profile.Degrees")}: </label>
                    <span>{values.degrees}</span>

                </div>

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Bio")}: </label>
                    {/* <span>{values.bio}</span> */}

                </div>

                <label>{values.bio}</label>


                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Services")}: </label>

                </div>

                {values.products_or_services && values.products_or_services.split(",").map((se) => {
                    return <>

                        <div style={{marginBottom:"10px"}} class="ui large teal label">{se.trim()}</div>
                    </>
                })}

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Skills")}: </label>

                </div>

                {values.skills && values.skills.split(",").map((s) => {
                    return <>

                        <div style={{marginBottom:"10px"}} class="ui large teal label">{s.trim()}</div>
                    </>
                })}


                <div className="sixteen wide column">
                    <label css="font-weight:700;" >{t("profile.Experience2")}: </label>

                </div>

                {values.experience && values.experience.split(",").map((e) => {
                    return <>

                        <div style={{marginBottom:"10px"}} class="ui large teal label">{e.trim()}</div>
                    </>
                })}


                <div className="sixteen wide column">
                    <label css="font-weight:700;" >{t("profile.Languages")}: </label>

                </div>

                {values.languagues && values.languagues.split(",").map((lenguagues) => {
                    return <>

                        <div style={{marginBottom:"10px"}} class="ui large teal label" >{lenguagues.trim()}</div>
                    </>
                })}


                <div className="sixteen wide column">
                    <label css="font-weight:700;" >{t("profile.Duties_and_titles")}: </label>

                </div>



                <div className="sixteen wide column">
                    <div css="white-space:pre-wrap;">{values.duties_and_titles}</div>
                </div>

                <div className="ten wide column">

                    <br />
                    <br /> 

                    <div className="">
                        <label css="font-weight:700;">{t("profile.Link_to_cv")}: </label>
                        <span>{values.link_to_cv}</span>

                    </div>

                    {/* <label>{values.link_to_cv}</label> */}

                    <br /> 

                    <div className="">
                        <label css="font-weight:700;">{t("profile.Link_to_portfolio")}: </label>
                        <span>{values.link_to_portfolio}</span>

                    </div>

                    {/* <label>{values.link_to_portfolio}</label> */}

                </div>





                <div css="@media(max-width:950px){display:none !important;}" className="six wide column">
                    <label css="font-weight:700;text-align:center;">{t("profile.All_work_samples")}</label>
                    <div css={divImage}>
                    <a css="color:black;&:hover{color:black;}" target="_blank" href={`${url_environment}${values.images[0]}`}><div tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.Photo")}</span>
                            <i  style={{visibility:"hidden"}} className=" huge camera icon"></i>
                            {
                                false ? (
                                    <div css={profimage1(imageLink1)}>
                                    </div>
                                )
                                    : values.images && values.images[0] && (
                                        <div css={profimage2(values.images[0] && (values.images[0].indexOf("uploads") != -1 ? values.images[0].split("/uploads/")[1] : values.images[0]))}>
                                        </div>
                                    )

                            }
                        </div></a>

                        <div css="margin-right:1rem"></div>
                        <a css="color:black;&:hover{color:black;}" target="_blank" href={`${url_environment}${values.images[1]}`}><div tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.File")}</span>
                            <i  style={{visibility:"hidden"}} className="  huge upload icon"></i>

                            {
                                false ? (
                                    <div css={profimage1(imageLink2)}>
                                    </div>
                                )
                                    : values.images && values.images[1] && (
                                        <div css={profimage2(values.images[1] && (values.images[1].indexOf("uploads") != -1 ? values.images[1].split("/uploads/")[1] : values.images[1]))}>
                                        </div>
                                    )

                            }
                        </div></a>

                        <div css="margin-right:1rem"></div>
                        <a css="color:black;&:hover{color:black;}" target="_blank" href={`${url_environment}${values.images[2]}`}><div tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.Photo")}</span>
                            <i  style={{visibility:"hidden"}} className=" huge camera icon" ></i>

                            {
                                false ? (
                                    <div css={profimage1(imageLink3)}>
                                    </div>
                                )
                                    : values.images && values.images[2] && (
                                        <div css={profimage2(values.images[2] && (values.images[2].indexOf("uploads") != -1 ? values.images[2].split("/uploads/")[1] : values.images[2]))}>
                                        </div>
                                    )

                            }
                        </div></a>

                        <div css="margin-right:1rem"></div>
                        <a css="color:black;&:hover{color:black;}" target="_blank" href={`${url_environment}${values.images[3]}`}><div tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                            <span  style={{visibility:"hidden"}} >{t("profile.File")}</span>
                            <i style={{visibility:"hidden"}} className=" huge upload icon"></i>
                            {values.images && values.images[3] && values.images[3]!="/uploads/" && (
                                                <div css={"position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;"}>
                                                     {/* {values.images[3] && getPDFName(values.images[3] && (values.images[3].indexOf("uploads")!=-1 ? values.images[3].split("/uploads/")[1] : values.images[3]))} */}
                                                     <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{values.images[3] && getPDFName(values.images[3] && (values.images[3].indexOf("uploads")!=-1 ? values.images[3].split("/uploads/")[1] : values.images[3]))}</span>

                                                </div>
                                            )}
                        </div></a>

                    </div>

                </div>

                <div className="sixteen wide column">
                    <label css="font-weight:700;">{t("profile.Additional_info")}: </label>

                </div>

                <div css="white-space:pre-wrap;">{values.additional_info}</div>

                <br /> 

                <div css="margin-top:20px;display:none !important; @media(max-width:950px){display:block !important;width:100% !important;}">
                    <label css="font-weight:700;text-align:center;">{t("profile.All_work_samples")}</label>
                    <div css="display:flex;flex-wrap:wrap;justify-content:center;">
                        <div tabIndex="0" className="ui segment" css="margin:7px !important;display:flex;align-items:center;flex-direction:column;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.Photo")}</span>
                            <i  style={{visibility:"hidden"}} className=" huge camera icon"></i>
                            {
                                false ? (
                                    <div css={profimage1(imageLink1)}>
                                    </div>
                                )
                                    : values.images && values.images[0] && (
                                        <div css={profimage2(values.images[0] && (values.images[0].indexOf("uploads") != -1 ? values.images[0].split("/uploads/")[1] : values.images[0]))}>
                                        </div>
                                    )

                            }
                        </div>

                        <div tabIndex="0" className="ui segment" css="margin:7px !important;display:flex;align-items:center;flex-direction:column;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.File")}</span>
                            <i  style={{visibility:"hidden"}} className="  huge upload icon"></i>

                            {
                                false ? (
                                    <div css={profimage1(imageLink2)}>
                                    </div>
                                )
                                    : values.images && values.images[1] && (
                                        <div css={profimage2(values.images[1] && (values.images[1].indexOf("uploads") != -1 ? values.images[1].split("/uploads/")[1] : values.images[1]))}>
                                        </div>
                                    )

                            }
                        </div>

                        <div tabIndex="0" className="ui segment" css="margin:7px !important;display:flex;align-items:center;flex-direction:column;cursor:pointer;position:relative;">
                            <span style={{visibility:"hidden"}} >{t("profile.Photo")}</span>
                            <i  style={{visibility:"hidden"}} className=" huge camera icon" ></i>

                            {
                                false ? (
                                    <div css={profimage1(imageLink3)}>
                                    </div>
                                )
                                    : values.images && values.images[2] && (
                                        <div css={profimage2(values.images[2] && (values.images[2].indexOf("uploads") != -1 ? values.images[2].split("/uploads/")[1] : values.images[2]))}>
                                        </div>
                                    )

                            }
                        </div>

                        <div tabIndex="0" className="ui segment" css="margin:7px !important;display:flex;align-items:center;flex-direction:column;cursor:pointer;position:relative;">
                            <span  style={{visibility:"hidden"}} >{t("profile.File")}</span>
                            <i style={{visibility:"hidden"}} className=" huge upload icon"></i>
                            {values.images && values.images[3] && values.images[3]!="/uploads/" && (
                                                <div css={"position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;"}>
                                                     <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{values.images[3] && getPDFName(values.images[3] && (values.images[3].indexOf("uploads")!=-1 ? values.images[3].split("/uploads/")[1] : values.images[3]))}</span>
                                                </div>
                                            )}
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default TabCV




