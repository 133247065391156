import placeholder from './img/photo_placeholder.svg'


export const googleClientID = "642479871199-4nhoqbcopaguq9qes36pjs6o0slblvop.apps.googleusercontent.com"

export const emailRegex = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/

export const getTimes = (str, opc) => {
    const date = new Date(str)
    const now = new Date()
    let mili;
    if (opc === true) {
        mili = date.getTime() - now.getTime()
    } else {
        mili = now.getTime() - date.getTime()
    }
    if (mili >= 1000 && mili < 60000) {
        return (mili / 1000).toFixed(0) + "s"
    }
    if (mili >= 60000 && mili < 3600000) {
        return (mili / 60000).toFixed(0) + "m"
    }
    if (mili >= 3600000 && mili < 86400000) {
        return (mili / 3600000).toFixed(0) + "h"
    }
    if (mili >= 86400000 && mili < 604800000) {
        return (mili / 86400000).toFixed(0) + "d"
    }
    if (mili >= 604800000 /*&& mili < 2419200000*/) {
        return (mili / 604800000).toFixed(0) + "w"
    }
    /*if(mili>=2419200000&&mili<29030400000){
        return (mili/2419200000).toFixed(0)+"month"
    }*/
}
export const getTimel = (str, opc, lang) => {
    const date = new Date(str)
    const now = new Date()
    let mili;
    if (opc === true) {
        mili = date.getTime() - now.getTime()
    } else {
        mili = now.getTime() - date.getTime()
    }
    if (mili >= 1000 && mili < 60000) {
        return (mili / 1000).toFixed(0) + " " + (lang == "en" ? ((mili / 1000).toFixed(0) > 1 ? "seconds" : "second") : ((mili / 1000).toFixed(0) > 1 ? "segundos" : "segundo"))
    }
    if (mili >= 60000 && mili < 3600000) {
        return (mili / 60000).toFixed(0) + " " + (lang == "en" ? ((mili / 60000).toFixed(0) > 1 ? "minutes" : "minute") : ((mili / 60000).toFixed(0) > 1 ? "minutos" : "minuto"))
    }
    if (mili >= 3600000 && mili < 86400000) {
        return (mili / 3600000).toFixed(0) + " " + (lang == "en" ? ((mili / 3600000).toFixed(0) > 1 ? "hours" : "hour") : ((mili / 3600000).toFixed(0) > 1 ? "horas" : "hora"))
    }
    if (mili >= 86400000 && mili < 604800000) {
        return (mili / 86400000).toFixed(0) + " " + (lang == "en" ? ((mili / 86400000).toFixed(0) > 1 ? "days" : "day") : ((mili / 86400000).toFixed(0) > 1 ? "dias" : "dia"))
    }
    if (mili >= 604800000 && mili < 2419200000) {
        return (mili / 604800000).toFixed(0) + " " + (lang == "en" ? ((mili / 604800000).toFixed(0) > 1 ? "weeks" : "week") : ((mili / 604800000).toFixed(0) > 1 ? "semanas" : "semana"))
    }
    if (mili >= 2419200000 && mili < 29030400000) {
        return (mili / 2419200000).toFixed(0) + " " + (lang == "en" ? ((mili / 2419200000).toFixed(0) > 1 ? "months" : "month") : ((mili / 2419200000).toFixed(0) > 1 ? "meses" : "mes"))
    }

}

export const getTimex = (str, opc) => {
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    const formatDayWeek = (date) => {
        switch (date.getDay()) {
            case 0: return "Sun";
            case 1: return "Mon";
            case 2: return "Tue";
            case 3: return "Wed";
            case 4: return "Thu";
            case 5: return "Fri";
            case 6: return "Sat";
        }
    }
    const formatDayMonth = (date) => {
        return (date.getMonth() + 1) + "/" + date.getDate()
    }
    const d1 = new Date(str)
    const d2 = new Date(str)
    const now1 = new Date()
    const now2 = new Date()
    now1.setHours(0, 0, 0, 0);
    d1.setHours(0, 0, 0, 0);
    const t1 = now1.toISOString()
    const t2 = d1.toISOString()
    if (opc) {
        return formatDayWeek(d2) + " " + formatDayMonth(d2) + ", " + formatAMPM(d2)
    }
    if (t1 == t2) {
        return formatAMPM(d2)
    }
    now1.setDate(now1.getDate() - now1.getDay())
    if (t1 != t2 && (d1.getTime() >= now1.getTime())) {
        return formatDayWeek(d2) + " " + formatAMPM(d2)
    }
    if (t1 != t2 && (d1.getTime() < now1.getTime())) {
        return formatDayWeek(d2) + " " + formatDayMonth(d2)
    }
}

export const cleanSubject = (str) => {
    let s = str;
    let ini = s.indexOf("{[(link:")
    let fin = s.indexOf(":::")
    if (ini == -1 && fin == -1) {
        return s
    }
    fin += 3
    let s2 = s.substring(ini, fin)
    s = s.replace(s2, "")
    s = s.replace(")]}", "")
    return s
}

export const formatSubject = (str) => {
    let s = str;
    let ini = s.indexOf("{[(link:")
    let fin = s.indexOf(":::")
    if (ini == -1 && fin == -1) {
        return s
    }
    fin += 3
    let s2_ = s.substring(ini, fin)
    ini += 8
    fin -= 3
    let s3 = s.substring(ini, fin)
    ini = s.indexOf(":::")
    fin = s.indexOf(")]}")
    ini += 3
    fin += 3
    let s4_ = s.substring(ini, fin)
    console.log("s4_: ", s4_)
    fin -= 3
    let s5 = s.substring(ini, fin)
    console.log("s5: ", s5)
    s = s.replace(s2_, "[[{")
    s = s.replace(s4_, "}]]")
    const sa = s.split("[[{}]]")
    // s = s.replace(s2_, `<a target="_blank" href="https://interpowered.com${s3}">`)
    // s = s.replace(s4_, `${s5}</a>`)
    // s=s.replace(/&lt;script|<script/gi, "")
    return <>{sa[0]}<a target="_blank" href={`https://interpowered.com${s3}`}>{s5}</a>{sa[1]}</>
}

export const getTimez = (time_from, time_to) => {
    const m = (new Date("01/01/2007 " + time_to).getTime() - new Date("01/01/2007 " + time_from).getTime())
    return (m / 3600000).toFixed("2")
}

let urlEnvironment;
let hostname = window.location.hostname
if (hostname === "interpowered.com")
    urlEnvironment = "https://interpowered.com:3001"
if (hostname === "test.interpowered.com")
    urlEnvironment = "https://test.interpowered.com:4101"
if (hostname === "testing.interpowered.com")
    urlEnvironment = "https://testing.interpowered.com:4001"
if (hostname === "localhost")
    urlEnvironment = "http://localhost:5000"

export const url_environment = urlEnvironment

let urlEnvironment2;
let hostname2 = window.location.hostname
if (hostname2 === "interpowered.com")
    urlEnvironment2 = "https://interpowered.com"
if (hostname2 === "test.interpowered.com")
    urlEnvironment2 = "https://test.interpowered.com"
if (hostname2 === "testing.interpowered.com")
    urlEnvironment2 = "https://testing.interpowered.com"
if (hostname2 === "localhost")
    urlEnvironment2 = "http://127.0.0.1:3000"

export const url_environment_2 = urlEnvironment2

export const getImage1 = (img) => {
    if (img.includes("uploads/")) {
        return url_environment + img
    } else {
        return img
    }
}

export const getImage2 = (img) => {
    if (img) {
        if (img.includes("uploads/")) {
            return url_environment + img
        } else {
            return img
        }
    } else {
        return placeholder
    }
}

export const getAllImages1 = (p) => {
    if (p.images[0]) {
        if (p.images[0].includes("uploads/")) {
            return url_environment + p.images[0]
        } else {
            return p.images[0]
        }
    } else if (p.business_picture) {
        if (p.business_picture.includes("uploads/")) {
            return url_environment + p.business_picture
        } else {
            return p.business_picture
        }
    } else if (p.freelancer_picture) {
        if (p.freelancer_picture.includes("uploads/")) {
            return url_environment + p.freelancer_picture
        } else {
            return p.freelancer_picture
        }
    } else {
        return placeholder
    }
}