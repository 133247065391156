
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Dimmer, Loader, Tab, Rating, Image, Label } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { footButtons } from '../styles'
import TabCV from './business-tab-about'
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import TabCata from './business-tab-catalog'
import ReviewsTab from './reviews.tab'
import { useDispatch } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { url_environment } from '../../config'
import countries, { languages } from '../countries'

const BusinessProfile = () => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(true)
    const [tabimage, settabimage] = useState([])
    const [values, setValues] = useState({
        name_of_business: "",
        business_sector: "",
        business_description: "",
        link_to_website: "",
        business_category: "",
        service_offered: "",
        products_for_sale: "",
        category_name: "",
        bio: "",
        address: ""
    })

    const [t, i18n] = useTranslation("global")



    useEffect(async () => {
        try {
            setLoading(true)
            let { data: businessData } = await axios.get("/api/business/" + id)
            let tabimages = businessData.productImages.map(x => {
                return {

                    original: url_environment + x,
                    thumbnail: url_environment + x,
                    originalHeight: "",
                    originalWidth: "",
                    thumbnailHeight: "90px",
                    thumbnailWidth: "700px"
                }

            })
            settabimage(tabimages)
            setValues(prev => {
                return {
                    ...prev,
                    name_of_business: businessData && businessData.business_name,
                    business_description: businessData && businessData.business_description,
                    link_to_website: businessData && businessData.url_website,
                    service_offered: businessData && businessData.service_offered,
                    business_sector: businessData && businessData.business_sector,
                    products_for_sale: businessData && businessData.products_for_sale,
                    product_categories: businessData && businessData.product_categories,
                    category_name: businessData && businessData.category_name,
                    address: businessData && businessData.address,
                    avg_reviews: businessData.avg_reviews,
                    business_picture: businessData && businessData.business_picture,
                    id_user: businessData.id_user,
                    languages:businessData.languages,
                    locations:businessData.locations
                }
            })
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }, [])

    const handleMessageSeller = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...values }, subject: "About products", receiver_type: "business" } }))
    }

    const history = useHistory()

    const vendor_languages=useMemo(()=>{
        const languages_array=languages
        return values?.languages?.map((language)=>{
            const language_name=languages_array.find(l=>l.key==language)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {t("profile."+language_name.toLowerCase())}
            </Label>
        </div>
        })
    },[values])

    const vendor_locations=useMemo(()=>{
        const locations_array=[{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
        return values?.locations?.map((location)=>{
            const location_name=locations_array.find(l=>l.key==location)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {location_name.trim()}
            </Label>
        </div>
        })
    },[values])


    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    const panes = [
        {
            menuItem: t("profile.About"), render: () =>
                <Tab.Pane> <TabCV values={values} images={tabimage} />
                </Tab.Pane>,
        },
        {
            menuItem: t("profile.Catalog"),
            render: () => <Tab.Pane>
                <TabCata values={values} id={id} />
            </Tab.Pane>,
        },
        {
            menuItem: t("profile.Reviews"),
            render: () => <Tab.Pane >
                <ReviewsTab id={id} />
            </Tab.Pane>,
        },
    ]


    return (
        <>

            <Navbar />
            <main>

                <div className="ui grid container" >

                    <div className="sixteen wide mobile six wide tablet three wide computer column">
                        <img src={`${url_environment}${values.business_picture}`} css="width: 150px;height: 150px;border-radius: 50%;object-fit: cover;object-position: center;display: block;" alt="" />
                    </div>

                    <div className="ten wide mobile nine wide computer column">

                        <div>
                            <label css="font-weight:700;">{t("profile.Business's_name")}: </label>
                            <span>{values.name_of_business}</span>
                        </div>

                        <div>
                            <label css="font-weight:700;">{t("profile.Business_Category")}: </label>
                            <span>{values.category_name}</span>
                            {/* , {values.product_categories.map(x => x.name).join(", ")} */}
                        </div>

                        <div>
                            <label css="font-weight:700;">{t("profile.Product/services")}: </label>
                            {values.service_offered && values.service_offered.split(",").map((se) => {
                                return <>

                                    <span className="ui basic label">{se.trim()}</span>
                                </>
                            })}

                        </div>


                        <div>
                            <label css="font-weight:700;" >{t("profile.Languages")}: </label>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_languages}
                            </div>
                        </div>
                        <div>
                            <label css="font-weight:700;" >{vendor_locations?.length>1?t("profile.Locations"):t("profile.Location")}: </label>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_locations}
                            </div>
                        </div>

                        <br />

                        <div>
                            <Rating maxRating={5} defaultRating={values.avg_reviews} disabled size='large' />


                        </div>

                    </div>

                    <div className=" ten wide mobile four wide computer  column" >
                        {/* <div className=" two wide column" css="align-items:center">
                        <Icon rotated='' name='huge star outline sign-out icon' />
                        <Icon rotated='counterclockwise' name='large sign-out icon' />
                    </div> */}

                        <div> <button onClick={handleMessageSeller} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                            {t("profile.Direct_Message")}</button>
                        </div>

                    </div>


                    <div className="sixteen wide column" ><Tab panes={panes} defaultActiveIndex={0} /></div>

                </div>
            </main>

            <Footer />
        </>
    )
}

export default BusinessProfile

