import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react'
import axios from '../axios';
import 'styled-components/macro'
import { userActions } from '../store/user-slice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const error_message = (e) => {
    if (e.response)
        return e.response.data.message
    else
        return e.message
}

const PaypalVerify2 = () => {
    const [t,]=useTranslation("global")
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState(false)
    const dispatch=useDispatch()
    let query = useQuery()
    const { userInfo } = useSelector(state => state.user)

    let history = useHistory()
    const settingLink=(()=>{
        if(userInfo){
            if(userInfo.id_role=="2"){
                return ("/business-settings")
            }
            if(userInfo.id_role=="3"){
                return ("/freelancer-settings")
            }
            if(userInfo.id_role=="1"){
                return ("/customer-settings")
            }
        }
        return "/"
    })()

    const loadData = async () => {
        if(!query.get("code")){
            if(userInfo){
                if(userInfo.id_role=="2"){
                    history.push("/business-settings")
                }
                if(userInfo.id_role=="3"){
                    history.push("/freelancer-settings")
                }
                if(userInfo.id_role=="1"){
                    history.push("/customer-settings")
                }
            }
        }
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.post("/api/paypal/userInfo", {
                code: query.get("code")
            }, config)
            if (data.status === "ok") {
                setMessage(("paypal_account_verified"))
                dispatch(userActions.updateValues({name:"paypal_email",value:data.email}))
            }else{
                setMessage(("paypal_account_not_verified"))
            }
        } catch (e) {setMessage(t(error_message(e))) }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading) {
        return <Dimmer inverted active>
            <Loader />
        </Dimmer>
    }

    return (
        <>
            {
                message && <div css="display:flex;align-items:center;justfy-content:center;height:100vh;">
                    <Header as='h2' icon textAlign="center">
                        <Icon name={message==="paypal_account_verified" ? "check circle outline" : "close"} />
                        {t(message)}
                        {message==="paypal_account_verified"&&<div style={{textAlign:"center", marginTop:"1rem"}}>
                            <Link className="ui button primary" to={settingLink}>{t("go_to_profile")}</Link>
                        </div>}
                    </Header>
                </div>
            }
        </>
    )
}

export default PaypalVerify2
