import React, { useEffect, useRef, useState } from 'react'
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProductModal from './ProductModal'
import { useHistory } from 'react-router'
import ProgressBar from './progressbar'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { addProductDiv, addProductWrap, footButtons, productWrap, showProduct, showProductCard, showProductDiv, showProductOver, showProductWrap } from '../styles'
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import { useSelector } from 'react-redux'
import { useQuery } from '../../Home/search'
import { useGetLocationName, useGetProductLanguages } from '../../shared/custom_hooks'

const BusinessProfile = () => {
    const [Loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const [ShowProductModal, setShowProductModal] = useState(false)
    const [Products, setProducts] = useState([])
    const query = useQuery()
    const [t, i18n] = useTranslation("global")
    const history = useHistory()

    const getLocationName=useGetLocationName()
    const getProductLanguages=useGetProductLanguages()

    useEffect(() => {
        loadData()
    }, [])
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const loadData = async () => {
        try {
            setLoading(true)
            let res = await axios.get("/api/product/readAll", config)
            console.log(res)
            setProducts(res.data)
            if (query.get("id") != null) {
                const product=res.data.find(x => x._id == query.get("id"))
                if(!product){
                    // history.push("/current-contracts?id="+query.get("id"))
                    return
                }
                setShowProductModal(product)
                query.delete("id")
                history.replace({
                    search: query.toString(),
                })
            }
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }
    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }



    return (
        <>
            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={2} />
                </div>
                <h2 css="text-align:center;">{t("profile.Add_Products")}</h2>
                <div className="ui five column grid container">
                    <div className="sixteen wide mobile five wide tablet three wide computer column">
                        <div onClick={() => { setShowProductModal(true) }} tabIndex="-1" css={productWrap}>
                            <div css={addProductWrap}>
                                <div css={addProductDiv}>
                                    <i class="big plus icon"></i>
                                    <span css="font-size:1.3rem;">{t("profile.Add_a_product")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {Products.map((item) => {
                        return (
                            <div className="sixteen wide mobile five wide tablet three wide computer column">
                                <div onClick={() => { setShowProductModal(item) }} tabIndex="-1" css={showProduct}>
                                    <div css={showProductWrap}>
                                        <div css={showProductDiv}>
                                            <div css={showProductCard(item.images[0])}>

                                                {item.images[0] && <div css={showProductOver}></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div css="font-size:16px;font-weight:bold;">{item.name}</div>
                                <div >{userInfo.seller.business_name}</div>
                                <div >USD ${item.sale_price}</div>
                                {item.location && <div>{getLocationName(item.location)}</div>}
                                {item.languages?.length>0 && <div>{getProductLanguages(item.languages)}</div>}
                                {!item.publish && !item.publish_request && !item.rejected && <div style={{ color: "red", fontWeight: "bold" }}>{t("product.not_published")}</div>}
                                {item.publish_request && <div style={{ color: "red", fontWeight: "bold" }}>{t("product.pending_approval")}</div>}
                                {item.publish && <div style={{ color: "green", fontWeight: "bold" }}>{t("product.published")}</div>}
                                {item.rejected && <div style={{ color: "red", fontWeight: "bold" }}>{t("product.rejected")}</div>}
                            </div>
                        )
                    })}
                </div>
                <div css={footButtons} style={{ marginBottom: "10px" }}>
                    <button onClick={() => history.push("/business-profile")} className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Back")}</button>
                    <button onClick={() => history.push("/business-orders")} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</button>
                </div>
            </main>

            <Footer />
            {
                ShowProductModal && <ProductModal data={ShowProductModal} testv={[]} update={() => { loadData() }} onClose={() => setShowProductModal(false)} />
            }
        </>
    )
}

export default BusinessProfile