export const containerStyle = {
    paddingTop: "100px"
}

export const stConStyNo = {
    color: "red"
}
export const stConStyYes = {
    color: "green"
}

export const emailTableStyle = {
    color: "blue"
}

export const thSty = `
    background:rgb(82,219,212)!important;
    color:#fff !important;
`


export const style1 = `
    cursor:pointer;
    &:hover{
        background:red;
    }
`

export const menuStyles= `background:#108081 !important;margin-top:0 !important;`

export const logoStyle=`max-width:100%;height:auto;display:block;`

export const containerDivStyles="display:flex;align-items:flex-end;justify-content:space-between;"

export const categoryButtonStyles="border-radius:100px !important;background:#108081 !important;"

export const inputFilterStyles="display:flex;align-items:center;justify-content:space-between;padding:10px 0;"

export const tabDropStyles={ background: "rgb(2,183,178)", color: "#fff", borderRadius:"100px" }

export const catLoadinStyles=`position:fixed;top:0;left:0;width:100%;height:100%;z-index:999999;`
