import {useState,useEffect,useRef} from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import axios from '../../axios';

export default ()=>{
    const [purchases, setPurchases] = useState([])
    const [pages, setPages] = useState({})
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [t, i18n] = useTranslation("global")
    const loadData = async (cp = 1) => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: { purchases, page, pages } } = await axios.get(`/api/user/purchases?pageNumber=${cp}&fdp=${deactivated_purchases.current || ""}&fpt=${RfilterType.current=="all" ? "" : RfilterType.current}&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}`, config)//&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}
            setPages({ current: page, total: pages })
            setPurchases(purchases)
            // const success = orders.filter(x => x.order_pass)
            // const failed = orders.filter(x => !x.order_pass)
            // dispatch(ordersActions.addOrders({ success, failed }))
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const deactivated_purchases=useRef(false)
    const RfilterDateS = useRef("")
    const RfilterDateE = useRef("")
    const RfilterDateSv = useRef("")
    const RfilterDateEv = useRef("")
    const RfilterType = useRef("all")
    const handleDateFromChange = (e) => {
        const date=new Date(e.target.value)
        date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 );
        RfilterDateS.current = date.toISOString()
        RfilterDateSv.current = e.target.value
    }
    const handleDateToChange = (e) => {
        const date=new Date(e.target.value)
        date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 );
        RfilterDateE.current = date.toISOString()
        RfilterDateEv.current = e.target.value
    }
    const handleTypeChange=(e,{value})=>{
        RfilterType.current=value
    }
    const handleDeactivatedPurchasesChange=()=>{
        // setTrackO(p => !p)
        deactivated_purchases.current=!deactivated_purchases.current
        loadData()
    }

    const handlePageClick = async (page) => {
        loadData(page)
    }

    useEffect(() => {
        loadData()
    }, [])
    return {loading,deactivated_purchases,handleDeactivatedPurchasesChange,t,RfilterDateSv,handleDateFromChange,RfilterDateEv,handleDateToChange,RfilterType,handleTypeChange,RfilterDateS,RfilterDateE,loadData,purchases,pages,handlePageClick}
}

//const {loading,deactivated_purchases,handleDeactivatedPurchasesChange,t,RfilterDateSv,handleDateFromChange,RfilterDateEv,handleDateToChange,RfilterType,handleTypeChange} =useMyHook()



