import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dimmer, Icon, Image, List, Loader, Popup } from 'semantic-ui-react'
import "styled-components/macro"
import axios from '../../axios'
import { getTimes, url_environment } from '../../config'

const NotificationsPopup = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [list5, setList5] = useState([])
    const [listCount, setListCount] = useState(0)
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const handleMoreClick=()=>{
        setList5(list)
    }
    const handleOpen=async()=>{
        try{
            if(listCount==0) return
            await axios.put("/api/user/notification/reset_view",{},config)
            setListCount(0)
        }catch(e){alert(e)}
    }
    const loadData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get("/api/user/notification", config)
            console.log(data)
            setList(data.notifications)
            setList5(data.notifications.slice(0,5))
            setListCount(data.count)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <Popup
            on="click"
            trigger={<button title={t("navbar.view_notifications")} css={`i{margin:0 !important;} position:relative;cursor:pointer;background:#f4f4f4;border:0;width:50px;height:50px;border-radius:50%;margin-right:10px;`}>
                <Icon name="bell outline" size="big" />
                {listCount>0 && <div css="position:absolute;top:0;right:0;padding:0px 6px;border-radius:100px;background-color:#db2828;color:white;font-size:13px;"><span>{listCount>9 ? "9+" : listCount}</span></div>}
            </button>}
            pinned
            position="bottom right"
            offset={[1,0]}
            flowing
            basic
            onOpen={handleOpen}
            style={{ fontSize: "16px", borderRadius: "20px", padding: "30px 20px" }}
        //style={{borderRadius:"20px"}}
        >
            {loading && (
                <Loader active />
            )}

            {!loading && (
                <>
                    {/*  */}
                    <div css="max-width:300px;max-height:55vh;overflow-y:scroll;">
                        <div>
                            {list5.map(x => {
                                let link_image
                                let text;
                                switch (x.type) {
                                    case "new-product":
                                        text = <span><b>{x.vendor_name}</b> {t("navbar.has_a_new_product")}.</span>
                                        link_image = `/product/${x.product_id}`
                                        break;
                                    case "product-on-sale":
                                        text = <span><b>{x.product_name}</b> {t("navbar.is_now_on_sale")}.</span>
                                        link_image = `/product/${x.product_id}`
                                        break;
                                    case "store-wide-sale":
                                        text = <span><b>{x.vendor_name}</b> {t("navbar.is_having_a_sale")}.</span>
                                        link_image = `/${x.vendor_type === "business" ? "business" : "freelancer-cv2"}/${x.vendor_id}`
                                        break;
                                    case "new-service":
                                        text = <span><b>{x.vendor_name}</b> {t("navbar.has_a_new_service")}.</span>
                                        link_image = `/service/${x.product_id}`
                                        break;
                                    case "freelancer-proposal":
                                        text = <span> {t("navbar.has_made_proposal")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/received-proposals?id=${x.custom_value}`
                                        break;
                                    case "timesheet-proposal":
                                        text = <span> {t("navbar.has_made_timesheet")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/timesheets?id=${x.custom_value}`
                                        break;
                                    case "customer-order":
                                        text = <span> {t("navbar.one_order_was_placed")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/business-orders?id=${x.custom_value}`
                                        break;
                                    case "customer-payout":
                                        text = <span> {t("navbar.received_timesheet_payout")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/freelancer-orders?id=${x.custom_value}`
                                        break;

                                    case "approved-product":
                                        text = <span> {t("navbar.received_approved_product")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/business-profile-2`
                                        break;
                                    case "approved-service":
                                        text = <span> {t("navbar.received_approved_service")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/freelancer-product`
                                        break;
                                    case "approved-course":
                                        text = <span> {t("navbar.received_approved_course")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/business-courses`
                                        break;
                                    case "approved-contract":
                                        text = <span> {t("navbar.received_approved_contract")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/current-contracts?approved_contract=${x.custom_value}`
                                        break;
                                    case "rejected-product":
                                        text = <span> {t("navbar.received_rejected_product")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/business-profile-2`
                                        break;
                                    case "rejected-service":
                                        text = <span> {t("navbar.received_rejected_service")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/freelancer-product`
                                        break;
                                    case "rejected-course":
                                        text = <span> {t("navbar.received_rejected_course")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/business-courses`
                                        break;
                                    case "rejected-contract":
                                        text = <span> {t("navbar.received_rejected_contract")} <b>{x.vendor_name}</b>.</span>
                                        link_image = userInfo.id_role!="3"?`/sent-proposals`:"received-proposals"
                                        break;
                                        
                                    case "approvals-product":
                                        text = <span> {t("navbar.received_approvals_product")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/approvals`///current-contracts?id=${x.custom_value}
                                        break;
                                    case "approvals-service":
                                        text = <span> {t("navbar.received_approvals_service")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/approvals`///current-contracts?id=${x.custom_value}
                                        break;
                                    case "approvals-course":
                                        text = <span> {t("navbar.received_approvals_course")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/approvals`///current-contracts?id=${x.custom_value}
                                        break;
                                    case "approvals-contract":
                                        text = <span><b>{x.vendor_name}</b> {t("navbar.proposal_sent_admin_by")}.</span>
                                        // text = <span> {t("navbar.received_approvals_contract")} <b>{x.vendor_name}</b>.</span>
                                        link_image = `/approvals`///current-contracts?id=${x.custom_value}
                                        break;
                                    case "proposal-change":
                                        text = <span> {t("navbar.proposal_modified_by")} <b>{x.vendor_name}</b>.</span>
                                        link_image = (userInfo.id_role!="3"?`/sent-proposals`:"received-proposals")+`?id=${x.custom_value}`
                                        break;
                                    case "proposal-approvals":
                                        text = <span><b>{x.vendor_name}</b> {t("navbar.proposal_sent_admin_by")}.</span>
                                        link_image = (userInfo.id_role!="3"?`/sent-proposals`:"received-proposals")+`?id=${x.custom_value}`
                                        break;
                                }
                                return <div css="padding-bottom:5px;border-bottom:1px solid #dadada;">
                                    <div css="display:flex;margin:15px 0;">
                                        <img css="flex:0 0 auto;width:50px;height:50px;border-radius:3px;object-fit:cover;object-position:center;" src={`${url_environment}${x.vendor_image}`} />
                                        <div css="flex-grow:2;margin-left:5px;margin-right:10px;">{text}</div>
                                        <div css="flex-grow:1;text-align:right;">
                                            <span>{getTimes(x.created_at)}</span>
                                        </div>
                                    </div>
                                    <Link to={link_image}>
                                        <img css="width:120px;height:120px;object-fit:cover;object-position:center;border-radius:6px;" src={`${url_environment}${x.product_image || x.vendor_image}`} alt="" />
                                    </Link>
                                </div>
                            })}
                            {(list5.length===5 && list.length>5) && <div css="text-align:center;margin-top:15px;"><Button onClick={handleMoreClick} circular color="teal">{t("navbar.show_more")}</Button></div>}
                        </div>

                    </div>
                </>
            )}

        </Popup>
    )
}

export default NotificationsPopup