import { useCallback } from "react"
import countries from "../Profile/countries"
import { useTranslation } from "react-i18next"

export const useGetLocationName=()=>{
    const [t,] = useTranslation("global")
    return useCallback((location)=>{
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries].find(c=>c.key===location)?.text
    },[])
}

export const useGetProductLanguages=()=>{
    const [t,] = useTranslation("global")
    return useCallback((languages)=>{
        return languages?.map(l=>t("profile."+l.toLowerCase()))?.join(", ")
    },[])
}