export default `
    max-width:1200px;margin:0 auto; padding:0 36px;

    .dailyWrapper{
        display:flex;
        flex-wrap:wrap;
        justify-content:flex-start;

        .imgWrapper{
            flex:0 0 auto;
            width:16.66666%;
            padding:9px;
            position:relative;
            &:hover{
                &  .heartBtn{
                    display:flex;
                }
            }

            .heartFillClass{
                position:absolute;
                top:15px;
                right:15px;
                z-index:999;
                width:30px;
                height:30px;display:flex;
                align-items:center;
                justify-content:center;
                border-radius:100%;
                background:#fff;
            }

            .heartOutlineClass{
                position:absolute;
                top:15px;
                right:15px;
                z-index:999;
                width:30px;
                height:30px;
                display:none;
                align-items:center;
                justify-content:center;
                border-radius:100%;
                background:#fff;
            }

            @media(max-width:960px){
                width:25%;
                &  .heartBtn{
                    display:flex;
                }
            } 
            @media(max-width:700px){
                width:33.33333%;
            }
            @media(max-width:520px){
                width:50%;
            }
            @media(max-width:320px){
                width:100%;
            }
            .bigImg{
                border-radius:8px;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit:cover;
                object-position:center;
            }

            .h3Text{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 0;
                margin:0;
            }
        }
        .imgStyles{
            border-radius:5px;
            width:160px;
            height:160px;
            object-fit:cover;
            object-position:center;
            background-color:rgb(179,248,245);
        }
    }

`