import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Segment } from 'semantic-ui-react'
import 'styled-components/macro'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import SignatureModal from '../Profile/signatureModal'
import { timezones } from '../Profile/countries'
import axios from '../axios'
import { useDispatch, useSelector } from 'react-redux'
import { formData } from '../shared/formDataCreator'
import { uiActions } from '../store/ui-slice'
import { url_environment } from '../config'
import { payment_date_options } from '../Profile/proposal-freelancer'

const ProposalModalView = (props) => {
    const [t, i18n] = useTranslation("global")
    // const [values, setValues] = useState({ business_name: "", link_to_website: "", searching_for: "", job_description: "", proposed_hourly_rate: "", requirements: "", timeline: "", })
    const location = useLocation()
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [sigImage2, setSigImage2] = useState(undefined)
    const [sigImageLink2, setSigImageLink2] = useState(undefined)
    const [showSignatureModal, setShowSignatureModal] = useState(false)
    const [filesData, setFilesData] = useState([])
    const data = props.data
    const { userInfo } = useSelector(state => state.user)
    const fileRef = useRef(undefined)
    const history = useHistory()
    const changeTracker = useRef([])
    const dispatch = useDispatch()
    const acceptCounter = useRef(0)
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const pass = useCallback((data) => {
        if (
            data.searching_for == "" ||
            data.job_scope_work == "" ||
            data.select_payment_schedule == "" ||
            data.proposed_hourly_rate == "" ||
            data.requirements == "" ||
            data.timeline == "" ||
            data.timezone == "" ||
            data.requested_availability.length == 0 ||
            data.requested_available_days.length == 0 ||
            data.requested_available_times.length == 0 ||
            data.additional_information == "" ||
            !data.sigImageLink
        ) return false
        return true
    }, [])
    const handleAcceptChange = (e, a) => {
        const { name, checked, value } = a
        if (checked) {
            acceptCounter.current++
        } else {
            acceptCounter.current--
        }
    }
    const handleSaveClick = async (submit) => {
        const num_accept = values.select_payment_schedule == "monthly" ? 10 : 9
        if (submit) {
            if (!sigImageLink2 || acceptCounter.current < num_accept ||
                !values.freelancer_requirements ||
                !values.freelancer_timezone ||
                !values.freelancer_additional_information) { alert(t("contractmgmt.please_accept_all_terms_and")); return; }
        }
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            setLoading(true)
            const temp = { ...values }
            temp.business_options = undefined
            temp.sent = false
            temp.counter_requested_availability = JSON.stringify(temp.counter_requested_availability)
            temp.counter_requested_available_days = JSON.stringify(temp.counter_requested_available_days)
            temp.counter_requested_available_times = JSON.stringify(temp.counter_requested_available_times)
            let changeTrack = [...changeTracker.current]
            changeTracker.current = []
            changeTrack = changeTrack.filter(function (item, pos) {
                return changeTrack.indexOf(item) == pos;
            })
            const temp2 = {}
            changeTrack.forEach(x => {
                temp2[x] = temp[x]
            })
            temp2.sigImage2 = sigImage2
            if (!temp2.sigImage2) delete temp2.sigImage2;
            if (acceptCounter.current > 7 && (temp.signature_receiver || sigImage2 || sigImageLink2) && submit) temp2.contract = true
            // console.log("temp2: ",temp2)
            // return 
            await axios.put("/api/proposal/update", formData({
                ...temp2,
                proposal_id: data._id
            }), config)
            props.update()
            props.close()
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const handleCheckboxChange = useCallback((e, a) => {
        const { name, checked, value } = a
        changeTracker.current.push(name)
        // debugger;
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, [name]: [...prev[name], value] }
            } else {
                arr = prev[name].filter((i) => { return i !== value });
                return { ...prev, [name]: arr }
            }
        })
    }, [])
    const handleSignatureSubmit = useCallback((image) => {
        setSigImage2(image)
        setSigImageLink2(image)
    }, [])
    const handleSignatureModalClose = useCallback(() => {
        setShowSignatureModal(false)
    }, [])
    const handleSignatureClick = useCallback(() => {
        setShowSignatureModal(true)
    }, [])
    const handleSelectChange = useCallback((e, a) => {
        const { name, value } = a
        setValues(p => { return { ...p, [name]: value } })
        changeTracker.current.push(name)
    }, [])
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleFileChange = useCallback((e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])

    }, [])
    const [values, setValues] = useState({
        counter_requested_availability: [],
        counter_requested_available_days: [],
        counter_requested_available_times: [],
        freelancer_additional_information: "", freelancer_timezone: "", counter_timeline: "", freelancer_requirements: "", counter_requirements: "", counter_offer_hourly_rate: "", counter_response_to_job: "", business_name: "", link_to_website: "", searching_for: "", requested_availability: [], requested_available_days: [], requested_available_times: [], job_description: "", counter_payment_date: "", counter_payment_schedule: "", payment_date: "", select_payment_schedule: "hourly", proposed_hourly_rate: "", requirements: "", timeline: ""
    })
    const handleInputChange = useCallback((e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
        changeTracker.current.push(e.target.name)
    }, [])
    const handleRadioChange = useCallback((e, a) => {
        const { name, checked, value } = a
        // debugger;
        changeTracker.current.push(name)
        setValues(prev => {
            return { ...prev, [name]: value }
        })
    }, [])
    const loadData = async () => {
        setLoading(true)
        let client_data = {}
        try {
            const { data: datax } = await axios.get("/api/user/info/" + data.sender)
            client_data = datax
        } catch (e) { alert(e) }
        setValues({
            link_to_website: data.link_to_website,
            searching_for: data.service_name,
            job_scope_work: data.job_scope_work,
            counter_payment_date: data.counter_payment_date,
            payment_date: data.payment_date,
            counter_payment_schedule: data.counter_payment_schedule,
            select_payment_schedule: data.payment_schedule,
            proposed_hourly_rate: data.proposed_hourly_rate,
            requirements: data.requirements,
            timeline: data.timeline,
            meeting_schedule: data.meeting_schedule,
            timezone: data.timezone,
            requested_availability: data.requested_availability,
            requested_available_days: data.requested_available_days,
            requested_available_times: data.requested_available_times,
            additional_information: data.additional_information,
            client: { ...client_data },
            counter_meeting_schedule: data.counter_meeting_schedule,
            counter_response_to_job: data.counter_response_to_job,
            counter_offer_hourly_rate: data.counter_offer_hourly_rate,
            counter_requirements: data.counter_requirements,
            freelancer_requirements: data.freelancer_requirements,
            counter_timeline: data.counter_timeline,
            freelancer_timezone: data.freelancer_timezone,
            freelancer_additional_information: data.freelancer_additional_information,
            counter_requested_availability: data.counter_requested_availability,
            counter_requested_available_days: data.counter_requested_available_days,
            counter_requested_available_times: data.counter_requested_available_times,
            signature_sender: data.signature_sender,
            signature_receiver: data.signature_receiver
        })
        setFilesData(data.files.map(x => { const ss = x.substring(x.lastIndexOf(".") + 1); const t = ss != "pdf" ? "image" : "pdf"; return { name: x.substring(x.lastIndexOf("/") + 1), type: t, image_link: url_environment + x } }))
        if (data.signature_receiver) { setSigImageLink2(url_environment + data.signature_receiver) } else { setSigImageLink2(undefined) }
        setLoading(false)
    }
    useEffect(() => {
        if (!props.open) return;
        if (Object.keys(props.data).length == 0) return
        loadData()
    }, [props.open])
    const cleanState = () => {
        setValues({})
        setFilesData([])
    }
    const handleMessageClick = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...values.client }, product: { service_name: data.service_name, images: [data.service_image] }, subject: "About service", link: `/service/${data.service}` } }))
    }
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    if (Object.keys(props.data).length == 0) return <></>
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{data._id}</Modal.Header>
                <Modal.Content >
                    <div css="display:flex;justify-content:space-between;@media(max-width:660px){flex-direction:column;align-items:center;}">
                        <div css="display:flex;">
                            <div css="font-size:25px;font-weight:700;display:flex;align-items:center;color:teal;margin-right:35px;@media(max-width:500px){display:none;}">{t("contractmgmt.from")}:</div>
                            <div css="display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                <img css="width:150px;height:150px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src={`${url_environment}${values?.client?.customer_picture}`} alt="" />
                                <div>
                                    <div css="margin:10px 0;">{values?.client?.store_name || values?.client?.name}</div>
                                </div>
                            </div>
                        </div>
                        <div css="display:flex;align-items:flex-end;">
                            <Button onClick={handleMessageClick} css="margin-top:20px !important;" circular color="teal">{t("contractmgmt.direct_message")}</Button>
                        </div>
                    </div>
                    <div css="height:2px;background-color:rgb(2,183,178);margin:20px 0;"></div>
                    <h2 css="text-align:center;color:rgb(2,183,178);margin:2rem 0;">{t("contractmgmt.proposal")}: #{data._id}</h2>
                    <p css="margin-top:0.7rem;margin-bottom:2rem;font-weight:bold;color:#800000;">
                        {t("contractmgmt.please_note_that")}
                    </p>
                    <p css="margin-top:0.7rem;margin-bottom:2rem;font-weight:bold;">
                        {t("contractmgmt.important_carefully_read_para")}
                    </p>
                    <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                        <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-right:50px;@media(max-width:768px){width:100%;padding:0;}">
                            <div className="ui form">

                                <div className="ui field">
                                    <label>{t("contractmgmt.link_to_website")}</label>
                                    <Segment>{values.link_to_website}</Segment>
                                </div>

                                <div className="ui  field">
                                    <label>{t("contractmgmt.searching_for")}</label>
                                    <Segment>{values.searching_for}</Segment>
                                </div>

                                <div className="ui  field">
                                    <label>{t("contractmgmt.job_scope_work")}</label>
                                    {/* <Segment>{values.job_scope_work}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.job_scope_work}</textarea>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <Form.TextArea
                                    label={t("contractmgmt.counter_response_to_job")}
                                    value={values.counter_response_to_job}
                                    name="counter_response_to_job"
                                    onChange={handleInputChange}
                                    rows="10"
                                />

                                <div className="ui field">
                                    <label>{t("contractmgmt.sample_desired_work")}</label>
                                    <div css="display:flex;align-items:center;justify-content:flex-start;">
                                        <div>
                                            <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                                {filesData.map((item) => {
                                                    return <>
                                                        <a href={item.image_link} target="_blank" css="display:block;flex:0 0 auto;width:112px;height:104px;padding:6px;">
                                                            <div css="height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                                <div css="width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                                    {!item.type.startsWith("image") && <div css="display:flex;flex-direction:column;width:100%;">
                                                                        <div>
                                                                            <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                                        </div>
                                                                        <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                                    </div>}
                                                                    {item.type.startsWith("image") && <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                                        <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.proposed_payment_schedule")}</label>
                                    <Segment>{t("contractmgmt." + values.select_payment_schedule)}</Segment>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui field">
                                    <label>{t("contractmgmt.counter_payment_schedule")}</label>
                                    <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox radio checked={values.counter_payment_schedule === "hourly"} value="hourly" name="counter_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.hourly")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox radio checked={values.counter_payment_schedule === "weekly"} value="weekly" name="counter_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.weekly")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox radio checked={values.counter_payment_schedule === "monthly"} value="monthly" name="counter_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.monthly")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox radio checked={values.counter_payment_schedule === "one_time"} value="one_time" name="counter_payment_schedule" onChange={handleRadioChange} label={t("contractmgmt.one_time")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.proposed_" + values.select_payment_schedule + (values.select_payment_schedule == "one_time" ? "_payment_amount" : "_rate"))}</label>
                                    <Segment>USD ${values.proposed_hourly_rate}</Segment>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <Form.Input

                                    icon="dollar sign"
                                    iconPosition="left"
                                    type="number"
                                    label={t("contractmgmt.counter_offer_" + values.select_payment_schedule + (values.select_payment_schedule == "one_time" ? "_payment_amount" : "_rate"))}
                                    name="counter_offer_hourly_rate"
                                    value={values.counter_offer_hourly_rate}
                                    onChange={handleInputChange}
                                />
                                {values.select_payment_schedule == "monthly" && <>
                                    <div className="ui field">
                                        <label>{t("contractmgmt.proposed_payment_date")}</label>
                                        <Segment>{values.payment_date}</Segment>
                                    </div>
                                    <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                    <div className="ui field">
                                        <label>{t("contractmgmt.counter_payment_date")}</label>
                                        <Form.Select
                                            fluid
                                            options={payment_date_options}
                                            onChange={handleSelectChange}
                                            name="counter_payment_date"
                                            value={values.counter_payment_date}
                                        />
                                    </div>
                                </>}
                                <div className="ui field">
                                    <label>{t("contractmgmt.requirements")}</label>
                                    {/* <Segment>{values.requirements}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.requirements}</textarea>

                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <Form.TextArea
                                    label={t("contractmgmt.counter_requirements")}
                                    name="counter_requirements"
                                    value={values.counter_requirements}
                                    onChange={handleInputChange}
                                    rows="10"
                                />

                                <div className="ui  field">
                                    <label>{t("contractmgmt.freelancer_requirements")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                    <label>{t("contractmgmt.what_do_you_require_freelancer_para")}</label>
                                    <textarea
                                        label={t("contractmgmt.freelancer_requirements")}
                                        name="freelancer_requirements"
                                        value={values.freelancer_requirements}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.timeline")}</label>
                                    <label>{t("contractmgmt.meeting_schedule_paragraph")}</label>
                                    <textarea cols="30" rows="10" readOnly>{values.timeline}</textarea>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui field">
                                    <label>{t("contractmgmt.counter_timeline")}</label>
                                    <textarea
                                        name="counter_timeline"
                                        value={values.counter_timeline}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.business_signature")}</label>
                                    <div className="ui field">
                                        <div css="padding-bottom:75%;position:relative;">
                                            <img css="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dededf;border-radius:.28571429rem;" src={url_environment + values.signature_sender} alt="" onLoad={handleImageLoad} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-left:50px;@media(max-width:768px){width:100%;padding:0;}">
                            <div className="ui form">


                                <div className="ui  field">
                                    <label>{t("contractmgmt.meeting_schedule")}</label>
                                    {/* <label>{t("contractmgmt.meeting_schedule_paragraph")}</label> */}
                                    {/* <Segment>{values.meeting_schedule}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.meeting_schedule}</textarea>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui field">
                                    <label>{t("contractmgmt.counter_meeting_schedule")}</label>
                                    <textarea
                                        name="counter_meeting_schedule"
                                        value={values.counter_meeting_schedule}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="ui field">
                                    <label>{t("contractmgmt.timezone")}</label>
                                    <Segment>{values.timezone}</Segment>
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.timezone")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                    <label>{t("contractmgmt.input_your_timezone")}</label>
                                    <Form.Select
                                        fluid
                                        options={timezones}
                                        onChange={handleSelectChange}
                                        name="freelancer_timezone"
                                        value={values.freelancer_timezone}
                                    />
                                </div>

                                <div className="ui  field">
                                    <label>{t("contractmgmt.requested_availability")}</label>
                                    <Segment>{values.requested_availability.join(", ")}</Segment>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui  field">
                                    <label>{t("contractmgmt.counter_requested_availability")}</label>
                                    <div css="display:flex;flex-wrap:wrap;">
                                        <div css="padding:5px 0;width:100%;">
                                            <Checkbox label={t("contractmgmt.full_time")} checked={values.counter_requested_availability.indexOf("Full time") !== -1} value="Full time" name="counter_requested_availability" onChange={handleCheckboxChange} />
                                        </div>
                                        <div css="padding:5px 0;width:100%;">
                                            <Checkbox label={t("contractmgmt.part_time")} checked={values.counter_requested_availability.indexOf("Part time") !== -1} value="Part time" name="counter_requested_availability" onChange={handleCheckboxChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="ui  field">
                                    <label>{t("contractmgmt.requested_available_days")}</label>
                                    <Segment>{values.requested_available_days.join(", ")}</Segment>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui  field">
                                    <label>{t("contractmgmt.counter_requested_available_days")}</label>
                                    <label>{t("contractmgmt.what_days_and_times")}</label>
                                    <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Monday") !== -1} value="Monday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.monday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Tuesday") !== -1} value="Tuesday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.tuesday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Wednesday") !== -1} value="Wednesday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.wednesday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Thursday") !== -1} value="Thursday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.thursday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Friday") !== -1} value="Friday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.friday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Saturday") !== -1} value="Saturday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.saturday")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_days.indexOf("Sunday") !== -1} value="Sunday" name="counter_requested_available_days" onChange={handleCheckboxChange} label={t("contractmgmt.sunday")} />
                                        </div>
                                    </div>
                                </div>

                                <div className="ui  field">
                                    <label>{t("contractmgmt.requested_available_times")}</label>
                                    <Segment>{values.requested_available_times.join(", ")}</Segment>
                                </div>
                                <Checkbox name="counter_accept" onChange={handleAcceptChange} label={t("contractmgmt.accept")} /><br />{t("contractmgmt.or")}
                                <div className="ui  field">
                                    <label>{t("contractmgmt.counter_requested_available_times")}</label>
                                    <label>{t("contractmgmt.what_days_and_times")}</label>
                                    <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_times.indexOf("Early") !== -1} value="Early" name="counter_requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.early_morning")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_times.indexOf("Morning") !== -1} value="Morning" name="counter_requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.morning")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_times.indexOf("Afternoon") !== -1} value="Afternoon" name="counter_requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.afternoon")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_times.indexOf("Evening") !== -1} value="Evening" name="counter_requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.evening")} />
                                        </div>
                                        <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                            <Checkbox checked={values.counter_requested_available_times.indexOf("Night") !== -1} value="Night" name="counter_requested_available_times" onChange={handleCheckboxChange} label={t("contractmgmt.night")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="ui field">
                                    <label>{t("contractmgmt.additional_information")}</label>
                                    {/* <Segment>{values.additional_information}</Segment> */}
                                    <textarea cols="30" rows="10" readOnly>{values.additional_information}</textarea>
                                </div>
                                <Form.TextArea

                                    label={<label>{t("contractmgmt.additional_information")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>}
                                    name="freelancer_additional_information"
                                    value={values.freelancer_additional_information}
                                    onChange={handleInputChange}
                                    rows={10}
                                />
                                <div className="ui  field">
                                    <label>{t("contractmgmt.digital_signature")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                    <input type="text" onClick={handleSignatureClick} />
                                </div>
                                {sigImageLink2 && <div className="ui field">
                                    <div css="padding-bottom:75%;position:relative;">
                                        <img css="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dededf;border-radius:.28571429rem;" src={sigImageLink2} alt="" onLoad={handleImageLoad} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {props.data.reject_reason && <div className="ui form">
                        <div className="ui field">
                            <label htmlFor="">{t("product.reject_reason")}</label>
                            <Segment>
                                {props.data.reject_reason}
                            </Segment>
                        </div>
                    </div>}
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button disabled={props.data.approve_status == "pending"} css="background:rgb(82,219,212) !important;" color='teal' circular onClick={() => handleSaveClick(true)}>
                        {t("contractmgmt.accept_contract")}
                    </Button>
                    <Button disabled={props.data.approve_status == "pending"} css="background:rgb(2,183,178) !important;" color='teal' circular onClick={() => handleSaveClick()}>
                        {t("contractmgmt.submit")}
                    </Button>
                    {/* <Button color='teal' circular onClick={() => handleSaveClick(true)}>
                        {t("contractmgmt.submit")}
                    </Button> */}
                    <Button css="background:rgb(0,128,129) !important;" color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>

                </Modal.Actions>
            </Modal>
            <SignatureModal open={showSignatureModal} close={handleSignatureModalClose} submit={handleSignatureSubmit} />
        </>
    )
}

export default ProposalModalView