import { createSlice } from '@reduxjs/toolkit'

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : []
    },
    reducers: {
        addItem(state, action) {
            const item = action.payload
            const exist = state.cartItems.findIndex((x) => { return x._id === item._id })
            if (exist > -1) {
                state.cartItems[exist].cartQuantity++;
                state.cartItems[exist].totalCartPrice = state.cartItems[exist].sale_price * state.cartItems[exist].cartQuantity;
            }
            else
                state.cartItems.push({ ...item, cartQuantity: 1, totalCartPrice: item.sale_price })
            const index = state.cartItems.findIndex(x => x._id == item._id)
            const qty = state.cartItems[index].cartQuantity
            const discount = item.volume_pricing.find(pricing => (qty >= pricing[0] && qty <= pricing[1]))
            if (discount) {
                // state.cartItems[index].sale_price -= ((parseFloat(discount[2]) * parseFloat(state.cartItems[index].sale_price))/100)
                // state.cartItems[index].totalCartPrice = state.cartItems[index].sale_price * state.cartItems[index].cartQuantity;
                state.cartItems[index].discount = ((parseFloat(discount[2]) * parseFloat(state.cartItems[index].sale_price)) / 100)
            } else { state.cartItems[index].discount = 0 }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        resetCart(state) {
            state.cartItems = []
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        deleteFromCart(state, action) {
            const product_id = action.payload
            const idx = state.cartItems.findIndex(x => { return x._id == product_id })
            console.log(idx, state.cartItems)
            if (state.cartItems[idx].cartQuantity > 1) {
                state.cartItems[idx].cartQuantity--
                state.cartItems[idx].totalCartPrice = state.cartItems[idx].sale_price * state.cartItems[idx].cartQuantity;

            } else {
                state.cartItems = state.cartItems.filter(x => { return x._id != product_id })
            }
            if (state.cartItems[idx]) {
                const index = idx
                const qty = state.cartItems[index].cartQuantity
                const discount = state.cartItems[index].volume_pricing.find(pricing => (qty >= pricing[0] && qty <= pricing[1]))
                if (discount) {
                    // state.cartItems[index].sale_price -= ((parseFloat(discount[2]) * parseFloat(state.cartItems[index].sale_price))/100)
                    // state.cartItems[index].totalCartPrice = state.cartItems[index].sale_price * state.cartItems[index].cartQuantity;
                    state.cartItems[index].discount = ((parseFloat(discount[2]) * parseFloat(state.cartItems[index].sale_price)) / 100)
                } else { state.cartItems[index].discount = 0 }
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        postShippingOptions(state, action) {
            const index = action.payload.index
            const options = action.payload.options;
            if (typeof options === "string") {
                state.cartItems[index].shippingOptions = options
                state.cartItems[index].shippingOptionsValue = -1
            } else if (!options || (Array.isArray(options) && options.length == 0))
                state.cartItems[index].shippingOptions = []

            else {
                state.cartItems[index].shippingOptions = options
                state.cartItems[index].shippingOptionsValue = options[0].value
                state.cartItems[index].shippingOptionsValueIndex = 0
            }
        },
        postTaxes(state, action) {
            const index = action.payload.index
            const tax = action.payload.tax;
            state.cartItems[index].tax = tax
        },
        updateSelectedOption(state, action) {
            const index = action.payload.index
            const selection_index = action.payload.selection_index
            const value = action.payload.value
            state.cartItems[index].shippingOptionsValue = value//options[0].value
            state.cartItems[index].shippingOptionsValueIndex = selection_index//options[0].value

        },
        setOutOfStock(state, action) {
            const arr = action.payload
            const length = state.cartItems.length
            for (let i = 0; i < length; i++) {
                const idx = arr.findIndex(x => { return x === state.cartItems[i]._id })
                if (idx > -1) {
                    state.cartItems[i].outOfStock = true
                } else {
                    state.cartItems[i].outOfStock = false
                }
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        }
    }
})

export const cartActions = cartSlice.actions

export const addToCart = (product) => {
    return async (dispatch) => {
        try {

            dispatch(cartActions.addItem(product))


        } catch (err) {
            console.log(err.response.data.message)
        }

    }

}

export const resetCart = () => {
    return async (dispatch) => {
        dispatch(cartActions.resetCart())
    }
}

export default cartSlice