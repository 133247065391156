import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProductModal from '../business/ProductModal'
import { useHistory } from 'react-router'
import ProgressBar from './progressbar-customer'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'

import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { Link } from 'react-router-dom'
import { uiActions } from '../../store/ui-slice'





const LanguageOptions = [
    { key: "en", value: "en", text: "English" },
    { key: "es", value: "es", text: "Español" }
]


const CustomerSettings = () => {

    const { userInfo } = useSelector(state => state.user)
    const [Loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [btnLoading2, setBtnLoading2] = useState(false)
    const [QRCode, setQRCode] = useState(false)
    const [t, i18n] = useTranslation("global")
    const [Language, setLanguage] = useState(i18n.language)
    const dispatch = useDispatch()


    const handleLanguageChange = (e, { value }) => {
        setLanguage(value)
    }

    const history = useHistory()
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: qrcode } = await axios.post("/api/user/qrcode", {}, config)
            setQRCode(qrcode)
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleGenerateToken = async () => {
        try {
            setBtnLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let { data } = await axios.post("/api/user/generateToken", {}, config)
            setBtnLoading(false)
            setQRCode(data)
        } catch (e) {
            alert(e)
            setBtnLoading(false)
        }
    }
    const handleSave = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/language", {
                id_language: Language,
                language: Language == "en" ? "English" : "Español"
            }, config)
            i18n.changeLanguage(Language);
            localStorage.setItem("languageValue", Language)
            dispatch(userActions.updateValues({ name: "id_language", value: Language }))
        } catch (e) { alert(e) }
    }

    const handleDeleteToken = async () => {
        try {
            setBtnLoading2(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let { data } = await axios.post("/api/user/deleteToken", {}, config)
            setBtnLoading2(false)
            setQRCode("no secret")
        } catch (e) {
            alert(e)
            setBtnLoading2(false)
        }
    }
    const handleReportProblemClick = () => {
        dispatch(uiActions.change_action({ open: true, action: "report a problem" }))
    }

    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={3} />
                </div>
                <h2 css="text-align:center;">{t("profile.Settings")}</h2>
                <div className="ui grid container">
                    <div css="margin:1rem;"><p css="font-size:0.75rem;color:#db2828;font-weight:bold;">{t("warning_tfa_2.1")}<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Google Authenticator</a>{t("warning_tfa_2.2")}</p></div>
                    <div css="display:flex;flex-wrap:wrap;max-width:850px;width:100%;justify-content:center;">
                        <div css="flex:0 0 auto;max-width:210px;width:25%;padding:10px;@media(max-width:550px){width:100%;}">
                            <form className="ui form">

                                <div className="field">
                                    <label>{t("profile.Language")}</label>
                                    <Select placeholder={t("profile.Language")} defaultValue={i18n.language} options={LanguageOptions} onChange={handleLanguageChange} style={{ minWidth: "100%" }} />
                                </div>
                            </form>
                        </div>
                        <div css="flex:0 0 auto;width:25%;padding:10px;@media(max-width:550px){width:100%;}">
                            {QRCode != "no secret" && <div css="display:flex;flex-direction:column;align-items:center">
                                <div css="margin-top:23px;">
                                    <Button circular css="background:rgb(2,183,178) !important;color:#fff !important;" loading={btnLoading2} onClick={handleDeleteToken}>{t('profile.delete_token')}</Button>
                                </div>
                                <img src={QRCode.qrcode} alt="" />
                                <p css="font-size:0.75rem;color:#db2828;font-weight:bold;">{t("warning_tfa")}</p>
                            </div>}
                            {QRCode == "no secret" && <div css="display:flex;flex-direction:column;align-items:center;">
                                <div css="margin-top:23px;">
                                    <Button circular css="background:rgb(2,183,178) !important;color:#fff !important;" loading={btnLoading} onClick={handleGenerateToken}>{t('profile.generate_token')}</Button>
                                </div>
                            </div>}
                        </div>
                        <div css="flex:0 0 auto;width:25%;padding:10px;@media(max-width:550px){width:100%;}">
                            <div css="display:flex;flex-direction:column;align-items:center">
                                <div css="margin-top:23px;">
                                    <Button onClick={handleReportProblemClick} circular css="background:rgb(2,183,178) !important;color:#fff !important;">{t('profile.report_problem')}</Button>
                                </div>
                            </div>
                        </div>
                        <div css="flex:0 0 auto;width:25%;padding:10px;@media(max-width:550px){width:100%;}">
                            <div css="display:flex;flex-direction:column;align-items:center">
                                <div css="margin-top:23px;">
                                    <Link to="/sent-proposals" className="ui circular teal button" style={{ background: "rgb(2,183,178)", whiteSpace: "nowrap" }}>{t('profile.contract_management')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div css="display:flex;align-items:center;justify-content:space-between;max-width:1127px;margin:0 auto;margin-top:2rem;">
                    <Link to="/customer-orders" className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Back")}</Link>
                    <button onClick={handleSave} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Save")}</button>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default CustomerSettings

/**
 *
 * rgb(0,128,129)
rgb(2,183,178)
rgb(82,219,212)
 */