import React, { useState } from 'react'
import onRouteChangedHOC from 'react-onroutechanged'
 
const MyComponent = ({children,hideLanguage}) => {
 
  MyComponent.handleRouteChanged = (prevLocation, nextLocation) => {
    if(nextLocation.pathname.includes("/freelancer-cv2") || nextLocation.pathname.includes("/search") || nextLocation.pathname==="/home" || nextLocation.pathname==="/login-info" || nextLocation.pathname==="/business-profile" || nextLocation.pathname==="/business-profile-2" || nextLocation.pathname==="/business-settings"|| nextLocation.pathname==="/freelancer-profile" || nextLocation.pathname==="/freelancer-cv" ||  nextLocation.pathname==="/freelancer-product" ||  nextLocation.pathname==="/freelancer-settings" || nextLocation.pathname.includes("/")){
        hideLanguage(false)
    }else{
        hideLanguage(true)
    }
  }

  return (
      <>
        {children}
      </>
  )
}
 
const onRoutedChangedConfig = {
  mounted: true,
  onlyPathname: false
}
 
export default onRouteChangedHOC(MyComponent, onRoutedChangedConfig)