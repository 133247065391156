import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Input, Modal, Table } from 'semantic-ui-react'
import axios from '../../axios'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const FreelancerOrderDetails = (props) => {
    const [t, i18n] = useTranslation("global")
    const order = props.data
    // const [loadingNotification, setLoadingNotification] = useState(false)
    const [trackLoading, setTrackLoading] = useState(false)
    const { userInfo } = useSelector(state => state.user)
    // const trackingNumbers=useRef({})
    const [trackingNumbers, setTrackingNumbers] = useState({})
    const [same, setSame] = useState(false)
    const order_date = (new Date(order.order_date)).toLocaleString()
    const handleTrackingChange = (e) => {
        // trackingNumbers.current={
        //     ...trackingNumbers.current,
        //     [e.target.name]: e.target.value
        // }
        if (same) {
            const newo = {}
            const n = order.order_items.length
            for (let i = 0; i < n; i++) {
                newo[`input-${i}`] = e.target.value
            }

            setTrackingNumbers(newo)
        } else {
            setTrackingNumbers(p => {
                return {
                    ...p,
                    [e.target.name]: e.target.value
                }
            })
        }

    }
    const handleCheckChange = (e) => {
        setSame(p => !p)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setTrackLoading(true)
        try {
            const tracks = []
            for (let i = 0; i < order.order_items.length; i++) {
                const temp = {}
                temp.product_id = order.order_items[i].product
                temp.trackingNumber = trackingNumbers[`input-${i}`]
                tracks.push(temp)
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/business/order/tracking", {
                tracks,
                order_id: order._id
            }, config)
            props.update()
            props.setClose()
        } catch (e) { alert(e) }
        setTrackLoading(false)
        console.log(trackingNumbers)
    }
    // const handleSendNotification = async () => {
    //     setLoadingNotification(true)
    //     try {
    //         const config = {
    //             headers: {
    //                 Authorization: `Bearer ${userInfo.token}`,
    //             },
    //         }
    //         const { data } = await axios.post("/api/business/order/notification", {
    //             order_id: order._id
    //         }, config)
    //     } catch (e) { alert(e) }
    //     setLoadingNotification(false)
    // }

    const loadData = () => {
        const temp = {}
        order.order_items.forEach((x, c) => {
            temp[`input-${c}`] = x.trackingNumber || ""
        })
        setTrackingNumbers(temp)
    }
    useEffect(() => { if (props.open) { clearState(); loadData() } }, [props.open])

    if (Object.keys(order).length == 0) {
        return <></>
    }

    const clearState = () => {
        setTrackingNumbers({})
        setSame(false)
    }


    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={() => props.setClose()}
                open={props.open}
                size="large"
            >
                <Modal.Header>{t("orders.order")} {order.order_id}</Modal.Header>
                <Modal.Content>
                    <div><b>{t("orders.paypal_order_id")}:</b> {order.order_id}</div>
                    <div><b>{t("orders.user_id")}:</b> {order.id_user._id}</div>
                    <div><b>{t("orders.user_name")}:</b> {order.id_user.name}</div>
                    <div><b>{t("orders.paypal_email")}:</b> {order.payer_email}</div>
                    <div><b>{t("orders.paypal_payer_name")}:</b> {order.payer_name}</div>
                    <div><b>{t("orders.total")}:</b>USD ${order.order_items.reduce((a, x) => { return a + x.total_price }, 0).toFixed(2)}</div>
                    <div><b>{t("orders.date")}:</b> {order_date}</div>
                    <form onSubmit={handleSubmit}>
                        <Table celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("orders.product_name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.contract_id")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.price")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.discount")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.taxes")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.shipping")}</Table.HeaderCell>
                                    {/* <Table.HeaderCell><span css="">{t("orders.shipping_carrier")}</span></Table.HeaderCell> */}
                                    <Table.HeaderCell>{t("orders.quantity")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("orders.total")}</Table.HeaderCell>
                                    {/* <Table.HeaderCell><div>{t("orders.trackingn")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></div><div><Checkbox checked={same} onChange={handleCheckChange} label={t("orders.same_for_all")} /></div></Table.HeaderCell> */}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {order.order_items.map((x, c) => {
                                    return (
                                        <Table.Row key={x._id}>
                                            <Table.Cell>{x.product_name}</Table.Cell>
                                            <Table.Cell>{x.contract_number}</Table.Cell>
                                            <Table.Cell>USD ${parseFloat(x.current_price).toFixed(2)}</Table.Cell>
                                            <Table.Cell>USD ${parseFloat(x.discount).toFixed(2)}</Table.Cell>
                                            <Table.Cell collapsing>USD ${parseFloat(x.total_tax).toFixed(2)}</Table.Cell>
                                            <Table.Cell collapsing>USD ${parseFloat(x.shipping).toFixed(2)}</Table.Cell>
                                            {/* <Table.Cell collapsing>
                                                {x.carrier=="notapply" && t("profile.notapply")}
                                                {x.carrier=="ups" && "UPS"}
                                                {x.carrier=="usps" && "USPS"}
                                            </Table.Cell> */}
                                            <Table.Cell>{x.quantity}</Table.Cell>
                                            <Table.Cell>USD ${parseFloat(x.total_price).toFixed(2)}</Table.Cell>
                                            {/* <Table.Cell collapsing><Input required value={trackingNumbers[`input-${c}`] || ""} onChange={handleTrackingChange} name={`input-${c}`}></Input></Table.Cell> */}
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                        {/* <div css="margin:10px 0;display:flex;justify-content:space-between;">
                            <Button loading={loadingNotification} onClick={handleSendNotification} disabled={order.order_items[0].trackingNumber == ""} type="button" color="teal" circular>{t("orders.send_tracking_notification")}</Button>
                            <Button loading={trackLoading} type="submit" color="teal" circular>Save traking numbers</Button>
                        </div> */}
                    </form>
                    <div><span></span></div>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default FreelancerOrderDetails