import React, { useEffect, useState } from 'react'
import Navbar from '../shared/navbar/navbar'
import { searchStyles } from './search.styles'
import 'styled-components/macro'
import axios from '../axios'
import { Button, Dimmer, Icon, Loader, Rating } from 'semantic-ui-react'

import placeholder from '../img/photo_placeholder.svg'
import FiltersModal from './filtersModal'
import { useHistory, useLocation } from 'react-router'
import Footer from '../shared/footer/footer'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../store/user-slice'
import { useTranslation } from 'react-i18next'
import { url_environment } from '../config'
import { useGetLocationName, useGetProductLanguages } from '../shared/custom_hooks'

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Search = ({ match }) => {
    const term = match.params.term
    let query = useQuery()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [lowPrice, setLowPrice] = useState("")
    const [highPrice, setHighPrice] = useState("")
    const { userInfo } = useSelector(state => state.user)
    const qp = useLocation().search;
    const dispatch = useDispatch()
    const [t,] = useTranslation("global")
    const getLocationName = useGetLocationName()
    const getProductLanguages = useGetProductLanguages()

    useEffect(() => {
        search()
        console.log("loc q:", qp)
    }, [])

    const search = async (category, price, vendor_type, vendor) => {
        try {
            setLoading(true)
            let prices=query.get("price")?.split("-");
            if(prices) if(prices[0]==""||prices[1]=="") prices=undefined;
            const res = await axios.post("/api/product/filter", {
                term,
                category: query.get("cat")||undefined,
                price: prices,//query.get("price")?query.get("price")?.split("-"):undefined,
                vendor_type: query.get("vdr")||undefined,//!=="All" ? query.get("vdr") : ""
                location:query.get("location")||undefined,
                languages:query.get("languages")?query.get("languages")?.split(","):undefined
            })
            setProducts(res.data)
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleFavoriteClick = async (e) => {
        const id = e.target.getAttribute("data-product-id")
        const type = e.target.getAttribute("data-type")
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (id.endsWith(" remove")) {
                const rid = id.split(" ")[0]
                await axios.delete("/api/user/favorite/" + rid, config)
            } else {
                await axios.post("/api/user/favorite", { id, type }, config)
            }
            dispatch(userActions.updateFavorites({ id, type }))
        } catch (e) { alert(e) }
    }
    const getImage = (product) => {
        if (product.business_picture) {
            if (product.business_picture.includes("uploads/")) {
                return url_environment + product.business_picture
            } else {
                return product.business_picture
            }
        }
        else if (product.freelancer_picture) {
            if (product.freelancer_picture.includes("uploads/")) {
                return url_environment + product.freelancer_picture
            } else {
                return product.freelancer_picture
            }
        }
        else if (product.images && product.images[0]) {
            if (product.images[0].includes("uploads/")) {
                return url_environment + product.images[0]
            } else {
                return product.images[0]
            }
        }
        else{
            return placeholder
        }
    }

    console.log("location: ", useLocation().search)

    if (loading)
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>

    return <>
        <Navbar qp={qp} term={term} />
        <main>

            <div css="max-width:1400px;margin:0 auto; padding-left:36px;padding-right:36px;" css={searchStyles}>
                <FiltersModal term={term || ""} data={categories, lowPrice, highPrice} onFilter={search} />
                <div css="display:flex;justify-content:flex-start;flex-wrap:wrap;margin-top:20px;">
                    {
                        products.map((product) => {
                            const type = (product.service_name || product.name) ? "product" : (product.freelancer_picture ? "freelancer" : (product.business_picture ? "business" : "error"))
                            return <>
                                <div css="position:relative;&:hover{&  .heartBtn{display:flex;}}flex:0 0 auto;width:16.66666%;padding:9px;@media(max-width:960px){&  .heartBtn{display:flex;} width:25%} @media(max-width:700px){width:33.33333%}@media(max-width:520px){width:50%}">
                                    {
                                        (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == product._id) != -1) ? (
                                            <div css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type={type} data-product-id={product._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                            </div>
                                        ) : (userInfo &&
                                            <div className="heartBtn" css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:${"none"};align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type={type} data-product-id={product._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                            </div>
                                        )
                                    }
                                    <a css="color:#000; &:hover{color:#000;}" href="#" {...(product.freelancer_picture ? { onClick: (e) => { e.preventDefault(); history.push("/freelancer-cv2/" + product._id) } } : product.service_name ? { onClick: (e) => { e.preventDefault(); history.push("/service/" + product._id) } } : (product.name ? { onClick: (e) => { e.preventDefault(); history.push("/product/" + product._id) } } : (product.business_picture ? { onClick: (e) => { e.preventDefault(); history.push("/business/" + product._id) } } : {})))}  >
                                        <div css="padding-bottom:80%;position:relative;">
                                            <img css="border-radius:8px;position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" src={getImage(product)} alt="" />
                                            {/* <img css="border-radius:8px;position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" src={((product.business_picture ? url_environment + product.business_picture : "") || (product.freelancer_picture ? url_environment + product.freelancer_picture : "")) || ((product.images && product.images[0]) ? (url_environment + (product.images[0] || "")) : (placeholder))} alt="" /> */}
                                        </div>
                                        <div>
                                            <h3 css="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;min-width: 0;margin:0;">{product.service_name || product.name || product.freelancer_name || product.business_name}</h3>
                                            <p css="margin:0;">{product.vendor_name || product.freelancer_name}</p>
                                            <Rating size='mini' disabled defaultRating={product.avg_reviews} maxRating={5} />
                                            <p css="margin:0;">{t("homeScreen.times_purchased")}: {product.total_sales}</p>
                                            {!(product.freelancer_picture || product.business_picture) && <p>USD ${product.sale_price || product.hourly_rate || "0"} {product.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(product.volume_pricing.length < 2 ? product.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + product.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>}</p>}
                                            {product.location && <p css="margin:0;">{getLocationName(product.location)}</p>}
                                            {product.languages?.length > 0 && <p css="margin:0;">{getProductLanguages(product.languages)}</p>}
                                        </div>
                                    </a>
                                </div>
                            </>
                        })
                    }
                </div>
            </div>
        </main>

        <Footer />


    </>
}

export default Search