export const searchStyles = `
    padding: 0 40px;

    .filterWrapper{
        display:flex;
        justify-content:flex-start;
        flex-wrap:wrap;
        

        .imgStyles{
            border-radius:5px;
            width:160px;
            height:160px;
            object-fit:cover;
            object-position:center;
            background-color:rgb(179,248,245);
        }
    }
`