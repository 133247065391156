import React, { useState,useEffect } from 'react'
import { Button, Popup,Icon,Dimmer,Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import 'styled-components/macro'

import axios from '../../axios'
import { useTranslation } from 'react-i18next';

const CategoriesPopup=(props)=>{
	const [open, setOpen] = useState(false)
	const [categories,setCategories]=useState([])
	const [loading,setLoading]=useState(false)
	const [t,]=useTranslation("global")

	const load=async()=>{
		setLoading(true);
		try {
            const { data: categoryData } = await axios.get("/api/category/marketing")
			const services=categoryData[0].sort((a,b)=>a.name.localeCompare(b.name))
			const products=categoryData[1].sort((a,b)=>a.name.localeCompare(b.name))
            setCategories([services,products])
        } catch (e) {
            alert(e)
            
        }
        setLoading(false)
	}

	useEffect(()=>{
		load()
	},[])

	const handleLinkClick=()=>{
		setOpen(false)
	}

	const serviceList=categories[0]?.map((p)=>(<li css="margin-bottom:1.2rem;">
		<Link onClick={handleLinkClick} css="white-space:pre-wrap;color:black;&:hover{color:black;}" to={`/category-products?cat_name=${p.name}&id_cat=${p._id}&vendor_type=${p.vendor_type}`}>{p.name}</Link>
	</li>))

	const productList=categories[1]?.map((p)=>(<li css="margin-bottom:1.2rem;">
		<Link onClick={handleLinkClick} css="white-space:pre-wrap;color:black;&:hover{color:black;}" to={`/category-products?cat_name=${p.name}&id_cat=${p._id}&vendor_type=${p.vendor_type}`}>{p.name}</Link>
	</li>))

	if(loading){
		return <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
	}

	return <>
		<Popup 
		on="click"
		flowing
		trigger={<button css="color:black;border:0;background:none;display:flex;align-items:center;cursor:pointer;margin-left:0.4rem;margin-right:0.4rem;font-size:1.3rem;">
			<Icon name="content" style={{marginBottom:"2px"}}/>
			<span>{t("navbar.categories")}</span>
		</button>} 
		offset={[0, 6]} 
		open={open}
	    onClose={() => setOpen(false)}
	    onOpen={() => setOpen(true)}
	    position='bottom center'
		>
		    <div css="display:flex;flex-wrap:wrap;width:400px !important; height: 76vh; overflow-y: auto;
						@media (max-width: 450px) {
							width:330px !important;
						}
						@media (max-width: 380px) {
							width:280px !important;
						}
			">
		    	<div css="flex:0 0 auto; width:50%;padding-right:1rem;">
		    		<h3>{t("navbar.services")}</h3>
		    		<ul css="padding: 0;list-style: none;">
		    			{serviceList}
		    		</ul>
		    	</div>
		    	<div css="flex:0 0 auto; width:50%;padding-left:1rem;">
		    		<h3>{t("navbar.products")}</h3>
		    		<ul css="padding: 0;list-style: none;">
		    			{productList}
		    		</ul>
		    	</div>
		    </div>
		  </Popup>
	</>
}

export default CategoriesPopup