import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Dimmer, Dropdown, Form, Icon, Loader, Message, Modal, Popup, Segment, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import ConfirmDelete from '../confirmDelete'
import ConfirmPublish from '../confirmPublish'
import { useTranslation } from 'react-i18next'
import { timezones } from '../countries'
import countries from '../countries'
import { useSelector } from 'react-redux'
import { url_environment } from '../../config'
import { Link } from 'react-router-dom'

export const getPDFName = (s) => {
    let ini = s.lastIndexOf("/") + 1
    while (s[ini] != "_") ini++; ini++;
    return s.substring(ini)
}

const ProductModal = (props) => {
    const [t, i18n] = useTranslation("global")

    const [taxcodes, settaxcodes] = useState([])
    const [taxLoading, setTaxLoading] = useState(false)
    const taxSubCategories = useMemo(() => {
        return [
            { key: 99, value: 99, text: t("avalara_subcategories.Services ") },
            { key: 100, value: 100, text: t("avalara_subcategories.Accounting") },
            { key: 101, value: 101, text: t("avalara_subcategories.Advertising services - no transfer of tpp") },
            { key: 102, value: 102, text: t("avalara_subcategories.Pet services") },
            { key: 103, value: 103, text: t("avalara_subcategories.Automotive") },
            { key: 104, value: 104, text: t("avalara_subcategories.Bank and financial institution") },
            { key: 105, value: 105, text: t("avalara_subcategories.Boat") },
            { key: 106, value: 106, text: t("avalara_subcategories.Computer software implementation (implementation, installation and training) - associated with the sale of software") },
            { key: 107, value: 107, text: t("avalara_subcategories.Computer software implementation - associated with the sale of software-custom software") },
            { key: 108, value: 108, text: t("avalara_subcategories.Computer software implementation-training only") },
            { key: 109, value: 109, text: t("avalara_subcategories.Computer software implementation (implementation, installation and training) - not associated with the sale of software") },
            { key: 110, value: 110, text: t("avalara_subcategories.Computer software implementation - not associated with the sale of software-custom software") },
            { key: 111, value: 111, text: t("avalara_subcategories.Computer software implementation - not associated with the sale of software-training only") },
            { key: 112, value: 112, text: t("avalara_subcategories.Computer software maintenance contracts/agreements (custom software)") },
            { key: 113, value: 113, text: t("avalara_subcategories.Computer software maintenance (custom software)-mandatory (electronically downloaded)") },
            { key: 114, value: 114, text: t("avalara_subcategories.Computer software maintenance (custom software)-optional (load and leave)") },
            { key: 115, value: 115, text: t("avalara_subcategories.Computer software maintenance (custom software)-mandatory (load and leave)") },
            { key: 116, value: 116, text: t("avalara_subcategories.Computer software maintenance (custom software)-optional (physical media)") },
            { key: 117, value: 117, text: t("avalara_subcategories.Computer software maintenance (custom software)-mandatory (physical media)") },
            { key: 118, value: 118, text: t("avalara_subcategories.Computer software maintenance contracts/agreements (prewritten software)") },
            { key: 119, value: 119, text: t("avalara_subcategories.Computer software maintenance (prewritten software)-mandatory (electronically downloaded)") },
            { key: 120, value: 120, text: t("avalara_subcategories.Computer software maintenance (prewritten software)-optional (load and leave)") },
            { key: 121, value: 121, text: t("avalara_subcategories.Computer software maintenance (prewritten software)-mandatory (load and leave)") },
            { key: 122, value: 122, text: t("avalara_subcategories.Computer software maintenance (prewritten software)-computer optional (physical media)") },
            { key: 123, value: 123, text: t("avalara_subcategories.Computer software maintenance (prewritten software)-mandatory (physical media)") },
            { key: 124, value: 124, text: t("avalara_subcategories.Computer software technical services (custom software)") },
            { key: 125, value: 125, text: t("avalara_subcategories.Computer software technical services (custom software)-mandatory (electronically downloaded)") },
            { key: 126, value: 126, text: t("avalara_subcategories.Computer software technical services (custom software)-optional (load and leave)") },
            { key: 127, value: 127, text: t("avalara_subcategories.Computer software technical services (custom software)-mandatory (load and leave)") },
            { key: 128, value: 128, text: t("avalara_subcategories.Computer software technical services (custom software)-optional (physical media)") },
            { key: 129, value: 129, text: t("avalara_subcategories.Computer software technical services (custom software)-mandatory (physical media)") },
            { key: 130, value: 130, text: t("avalara_subcategories.Computer software technical services (prewritten software)") },
            { key: 131, value: 131, text: t("avalara_subcategories.Computer software technical services (prewritten software)-mandatory (electronically downloaded)") },
            { key: 132, value: 132, text: t("avalara_subcategories.Computer software technical services (prewritten software)-optional (load and leave)") },
            { key: 133, value: 133, text: t("avalara_subcategories.Computer software technical services (prewritten software)-mandatory (load and leave)") },
            { key: 134, value: 134, text: t("avalara_subcategories.Computer software technical services (prewritten software)-optional (physical media)") },
            { key: 135, value: 135, text: t("avalara_subcategories.Computer software technical services (prewritten software)-mandatory (physical media)") },
            { key: 136, value: 136, text: t("avalara_subcategories.Construction services") },
            { key: 137, value: 137, text: t("avalara_subcategories.Construction services relating to real property (existing construction, commercial)") },
            { key: 138, value: 138, text: t("avalara_subcategories.Construction services/material relating to real property (existing construction, commercial)") },
            { key: 139, value: 139, text: t("avalara_subcategories.Childcare services") },
            { key: 140, value: 140, text: t("avalara_subcategories.Data services") },
            { key: 141, value: 141, text: t("avalara_subcategories.Food services") },
            { key: 142, value: 142, text: t("avalara_subcategories.Fur services") },
            { key: 143, value: 143, text: t("avalara_subcategories.Guide services") },
            { key: 144, value: 144, text: t("avalara_subcategories.Installation") },
            { key: 145, value: 145, text: t("avalara_subcategories.Motorcycle / atv services") },
            { key: 146, value: 146, text: t("avalara_subcategories.Repair (other)") },
            { key: 147, value: 147, text: t("avalara_subcategories.Security") },
            { key: 148, value: 148, text: t("avalara_subcategories.Transportation") },
            { key: 149, value: 149, text: t("avalara_subcategories.Watercraft (other)") },
            { key: 150, value: 150, text: t("avalara_subcategories.Travel agency") },
        ]
    })

    const { userInfo } = useSelector(state => state.user)

    const [values, setValues] = useState({
        service_name: "",
        service_description: "",
        service_category: "",
        id_category: "",
        category_name: "",
        hourly_rate: "",
        requirements: "",
        about_service: "",
        timezone: "+00:00",
        location:"",
        languages: [],
        availability: [],
        available_days: [],
        available_times: [],
        tax_category: "",
        taxcode: ""

    })
    let userData = JSON.parse(localStorage.getItem("loginState"))
    const handleTaxCategoryChange = async (e, { value }) => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setTaxLoading(true)
        try {
            const { data } = await axios.get("/api/user/taxcodes?parent=" + value, config)
            settaxcodes([{ key: "P0000000", value: "P0000000", text: t("profile.other_service") }, ...data.map(x => ({ key: x.taxCode, text: i18n.language == "en" ? x.description : x.description_es, value: x.taxCode }))])
        } catch (e) { alert(e) }
        setTaxLoading(false)
        handleInputChange({ target: { name: "tax_category", value } })
        handleInputChange({ target: { name: "taxcode", value:"" } })
    }

    const fileref1 = useRef()
    const fileref2 = useRef()
    const fileref3 = useRef()
    const fileref4 = useRef()
    const fileref5 = useRef()
    const fileref6 = useRef()

    const [ShowConfirmDelete, setShowConfirmDelete] = useState(false)
    const [ShowConfirmPublish, setShowConfirmPublish] = useState(false)
    const [Loading, setLoading] = useState(true)
    const [CategoryLoading, setCategoryLoading] = useState(false)
    const [CategoryData, setCategoryData] = useState([])
    const [ProductImage1, setProductImage1] = useState(false)
    const [ProductImage2, setProductImage2] = useState(false)
    const [ProductImage3, setProductImage3] = useState(false)
    const [ProductImage4, setProductImage4] = useState(false)
    const [ProductImage5, setProductImage5] = useState(false)
    const [ProductImage6, setProductImage6] = useState(false)

    const [fileInfo1, setFileInfo1] = useState({ name: "", size: "", mimetype: "" })
    const [fileInfo2, setFileInfo2] = useState({ name: "", size: "", mimetype: "" })
    const [fileInfo3, setFileInfo3] = useState({ name: "", size: "", mimetype: "" })
    const [fileInfo4, setFileInfo4] = useState({ name: "", size: "", mimetype: "" })
    const [fileInfo5, setFileInfo5] = useState({ name: "", size: "", mimetype: "" })
    const [fileInfo6, setFileInfo6] = useState({ name: "", size: "", mimetype: "" })

    const theCat = CategoryData.find(c => c.value._id === values.id_category)
    const priceWithCommision = (+values.hourly_rate) - ((+values.hourly_rate) * ((theCat ? parseInt(theCat.value.rate) : 1) / 100))
    const handleInputChange = (e) => {
        setValues(prev => { return { ...prev, [e.target.name]: e.target.value } })

    }

    const handleCategoryChange = (e, { value }) => {
        setValues(prev => { return { ...prev, category_name: value.name, id_category: value._id } })
    }
    //useEffect(()=>console.log(values),[values])
    const getFileByIndex = (index) => {
        switch (index) {
            case 0: return ProductImage1;
            case 1: return ProductImage2;
            case 2: return ProductImage3;
            case 3: return ProductImage4;
            case 4: return ProductImage5;
            case 5: return ProductImage6;
            default: throw new Error("file index error")
        }
    }

    const handleSubmit = async () => {
        if (!pass()) { alert("Please fill all required fields"); return }
        if (!pass2()) { alert(t("product.the_final_price")); return }
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append("service_name", values.service_name)
            formData.append("service_description", values.service_description)
            formData.append("category_name", values.category_name)
            formData.append("category_id", values.id_category)
            formData.append("hourly_rate", values.hourly_rate)
            formData.append("requirements", values.requirements)
            formData.append("about_service", values.about_service)
            formData.append("timezone", values.timezone)
            formData.append("tax_category", values.tax_category)
            formData.append("taxcode", values.taxcode)
            formData.append("location", values.location)
            formData.append("languages", JSON.stringify(values.languages))
            formData.append("availability", JSON.stringify(values.availability))
            formData.append("available_days", JSON.stringify(values.available_days))
            formData.append("available_times", JSON.stringify(values.available_times))
            formData.append("vendor_name", userInfo.seller.freelancer_name)
            formData.append("vendor_type", "Freelancer")
            // formData.append("file1", ProductImage1);
            // formData.append("file2", ProductImage2);
            // formData.append("file3", ProductImage3);
            // formData.append("file4", ProductImage4);
            // formData.append("file5", ProductImage5);
            // formData.append("file6", ProductImage6);

            formData.append("file_name_1", fileInfo1.name);
            formData.append("file_size_1", fileInfo1.size);
            formData.append("file_name_2", fileInfo2.name);
            formData.append("file_size_2", fileInfo2.size);
            formData.append("file_name_3", fileInfo3.name);
            formData.append("file_size_3", fileInfo3.size);
            formData.append("file_name_4", fileInfo4.name);
            formData.append("file_size_4", fileInfo4.size);
            formData.append("file_name_5", fileInfo5.name);
            formData.append("file_size_5", fileInfo5.size);
            formData.append("file_name_6", fileInfo6.name);
            formData.append("file_size_6", fileInfo6.size);

            formData.append("file_type_1", fileInfo1.type);
            formData.append("file_type_2", fileInfo2.type);
            formData.append("file_type_3", fileInfo3.type);
            formData.append("file_type_4", fileInfo4.type);
            formData.append("file_type_5", fileInfo5.type);
            formData.append("file_type_6", fileInfo6.type);

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let { data: uploadArr } = await axios.post("/api/product/createService", formData, config)
            for (let url of uploadArr) {
                const data = new FormData();
                for (const field in url.fields) {
                    data.append(field, url.fields[field])
                }
                const f = getFileByIndex(url.file_index)
                data.append("file", f)
                // const config2 = {
                //     onUploadProgress: function (progressEvent) {
                //         const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //         setProgress(percentCompleted)
                //     }
                // };
                try {
                    await axios.post(url.url, data)
                } catch (e) { console.log("video error:", e) }
            }
            setLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleAddCategory = async (e, { value }) => {
        try {
            setCategoryLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.post("/api/product/category/add", {
                name: value
            }, config)
            setCategoryLoading(false)
            console.log(res)
            setCategoryData(prev => [...prev, { key: res.data._id, text: res.data.name, value: res.data }])
        } catch (e) {
            alert(e)
            setCategoryLoading(false)
        }
    }

    const pass = () => {
        if (values.service_name == "") return false
        if (values.service_description == "") return false
        if (values.category_name == "") return false
        if (values.id_category == ("" || "undefined")) return false
        if (values.hourly_rate == "") return false
        if (values.requirements == "") return false
        if (values.about_service == "") return false
        if (values.taxcode == "") return false;
        if (values.location == "") return false;
        if (values.languages.length < 1) return false
        if (values.availability.length < 1) return false
        if (values.available_days.length < 1) return false
        if (values.available_times.length < 1) return false
        return true
    }
    const pass2 = () => {
        let price = (priceWithCommision - 1.40)
        if (price <= 0) {
            return false
        }
        return true
    }

    const handleSubmitUp = async () => {
        if (!pass()) { alert("Please fill all required fields"); return }
        if (!pass2()) { alert(t("product.the_final_price")); return }
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append("service_name", values.service_name)
            formData.append("service_description", values.service_description)
            formData.append("category_name", values.category_name)
            formData.append("category_id", values.id_category)
            formData.append("hourly_rate", values.hourly_rate)
            formData.append("requirements", values.requirements)
            formData.append("about_service", values.about_service)
            formData.append("timezone", values.timezone)
            formData.append("tax_category", values.tax_category)
            formData.append("taxcode", values.taxcode)
            formData.append("location", values.location)
            formData.append("languages", JSON.stringify(values.languages))
            formData.append("availability", JSON.stringify(values.availability))
            formData.append("available_days", JSON.stringify(values.available_days))
            formData.append("available_times", JSON.stringify(values.available_times))
            formData.append("vendor_id", userData.seller._id)
            formData.append("vendor_name", userData.name)
            formData.append("vendor_type", "Freelancer")
            // formData.append("file1", ProductImage1 || props.data.images[0]);
            // formData.append("file2", ProductImage2 || props.data.images[1]);
            // formData.append("file3", ProductImage3 || props.data.images[2]);
            // formData.append("file4", ProductImage4 || props.data.images[3]);
            // formData.append("file5", ProductImage5 || props.data.images[4]);
            // formData.append("file6", ProductImage6 || props.data.images[5]);
            formData.append("file_name_1", fileInfo1.name);
            formData.append("file_size_1", fileInfo1.size);
            formData.append("file_name_2", fileInfo2.name);
            formData.append("file_size_2", fileInfo2.size);
            formData.append("file_name_3", fileInfo3.name);
            formData.append("file_size_3", fileInfo3.size);
            formData.append("file_name_4", fileInfo4.name);
            formData.append("file_size_4", fileInfo4.size);
            formData.append("file_name_5", fileInfo5.name);
            formData.append("file_size_5", fileInfo5.size);
            formData.append("file_name_6", fileInfo6.name);
            formData.append("file_size_6", fileInfo6.size);

            formData.append("file_type_1", fileInfo1.type);
            formData.append("file_type_2", fileInfo2.type);
            formData.append("file_type_3", fileInfo3.type);
            formData.append("file_type_4", fileInfo4.type);
            formData.append("file_type_5", fileInfo5.type);
            formData.append("file_type_6", fileInfo6.type);

            formData.append("id", props.data._id)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let { data: uploadArr } = await axios.put("/api/product/updateService", formData, config)
            for (let url of uploadArr) {
                const data = new FormData();
                for (const field in url.fields) {
                    data.append(field, url.fields[field])
                }
                const f = getFileByIndex(url.file_index)
                data.append("file", f)
                // const config2 = {
                //     onUploadProgress: function (progressEvent) {
                //         const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //         setProgress(percentCompleted)
                //     }
                // };
                try {
                    await axios.post(url.url, data)
                } catch (e) { console.log("video error:", e) }
            }
            setLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }
    useEffect(() => {
        loadData()
    }, [])
    const isImage = (name) => {
        const arrext = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp"]
        let extension = name.split(".")
        extension = extension[extension.length - 1]
        return arrext.includes(extension)
    }
    const loadData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.get("/api/category/freelancer", config)
            setCategoryData(res.data.map(item => { return { key: item._id, text: item.name, value: item } }).sort((a, b) => (a.text[0] > b.text[0] ? 1 : -1)))
            const defcat = res.data.filter((i) => { return i._id == props.data.category_id })[0]
            if (props.data !== true) {
                setValues({
                    service_name: props.data.service_name,
                    service_description: props.data.service_description,
                    service_category: props.data.category_name,
                    id_category: props.data.category_id,
                    category_name: defcat?.name,
                    default_category: defcat,
                    hourly_rate: props.data.hourly_rate,
                    requirements: props.data.requirements,
                    about_service: props.data.about_service,
                    timezone: props.data.timezone,
                    tax_category: parseInt(props.data.tax_category),
                    taxcode: props.data.tax_code,
                    location: props.data.location  || "",
                    languages: props.data.languages || [],
                    availability: props.data.availability,
                    available_days: props.data.available_days,
                    available_times: props.data.available_times
                })
                if(props.data.tax_category){
                    const config = {
                        headers: {
                            Authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                    setTaxLoading(true)
                    try {
                        const { data } = await axios.get("/api/user/taxcodes?parent=" + props.data.tax_category, config)
                        settaxcodes([{ key: "P0000000", value: "P0000000", text: t("profile.other_service") }, ...data.map(x => ({ key: x.taxCode, text: i18n.language == "en" ? x.description : x.description_es, value: x.taxCode }))])
                    } catch (e) { alert(e) }
                    setTaxLoading(false)
                }
            }
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }

    

    const handleCheckboxChange = (name, checked, value) => {
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, [name]: [...prev[name], value] }
            } else {
                arr = prev[name].filter((i) => { return i !== value });
                return { ...prev, [name]: arr }
            }
        })
    }

    const locations =useMemo(()=>{
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
    },[])

    const categories_data_remap=useMemo(()=>{
        return CategoryData?.map?.(c=>({key:c.key,text:c.text,content:<span css="white-space:pre-wrap;">{c.text}</span>,value:c.value}))
    },[CategoryData])

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="large"
            >
                <Modal.Header>{t("profile.Add_new_service")}</Modal.Header>
                <Modal.Content>
                    {
                        Loading ? <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                            : (<>
                                <p>{t("profile.you_cannot_publish_freelancer")}<Link target={"_blank"} to="/wiki?opt=new_service">{t("here")}</Link>.</p>
                                <p>{t("after_connected_paypal")}</p>
                                <div className="ui grid container" css="justify-content:space-between">
                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                        <form className="ui form">
                                            <div className="field">
                                                <label>{t("profile.Service_name")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <input value={values.service_name} onChange={handleInputChange} type="text" name="service_name" />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Service_description")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <textarea value={values.service_description} onChange={handleInputChange} name="service_description" placeholder={t("describe_scope_work")}></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Service_category")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <Dropdown
                                                    selection
                                                    search
                                                    fluid
                                                    closeOnChange
                                                    loading={CategoryLoading}
                                                    options={categories_data_remap}
                                                    defaultValue={values.default_category}
                                                    onChange={handleCategoryChange}
                                                    selectOnBlur={false}
                                                />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Work_samples")}</label>
                                                <p>{t("profile.since_users_able")}</p>
                                                <input ref={fileref1} type="file" hidden onChange={(e) => { setFileInfo1({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage1(e.target.files[0] || false); props.testv[0] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <input ref={fileref2} type="file" hidden onChange={(e) => { setFileInfo2({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage2(e.target.files[0] || false); props.testv[1] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <input ref={fileref3} type="file" hidden onChange={(e) => { setFileInfo3({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage3(e.target.files[0] || false); props.testv[2] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <input ref={fileref4} type="file" hidden onChange={(e) => { setFileInfo4({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage4(e.target.files[0] || false); props.testv[3] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <input ref={fileref5} type="file" hidden onChange={(e) => { setFileInfo5({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage5(e.target.files[0] || false); props.testv[4] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <input ref={fileref6} type="file" hidden onChange={(e) => { setFileInfo6({ name: e.target.files[0]?.name || "", size: e.target.files[0]?.size || "", type: e.target.files[0]?.type || "" }); setProductImage6(e.target.files[0] || false); props.testv[5] = e.target.files[0] ? (e.target.files[0].type.includes("image") ? (URL.createObjectURL(e.target.files[0])) : e.target.files[0].name) : "" }} /*accept="image/png, image/gif, image/jpeg, application/pdf"*/ />
                                                <div className="ui three column grid">
                                                    <div className="column">
                                                        <div onClick={() => fileref1.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage1 ? (
                                                                    ProductImage1.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[0]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[0]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[0] && props.data.images[0] !== "" && (isImage(props.data.images[0]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[0]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>{/**${url_environment}/${encodeURIComponent(props.data.images[0].split("/uploads/")[1])} */}

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[0])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref2.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage2 ? (
                                                                    ProductImage2.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[1]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[1]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[1] && props.data.images[1] !== "" && (isImage(props.data.images[1]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[1]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[1])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref3.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage3 ? (
                                                                    ProductImage3.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[2]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[2]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[2] && props.data.images[2] !== "" && (isImage(props.data.images[2]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[2]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[2])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref4.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage4 ? (
                                                                    ProductImage4.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[3]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[3]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[3] && props.data.images[3] !== "" && (isImage(props.data.images[3]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[3]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[3])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref5.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage5 ? (
                                                                    ProductImage5.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[4]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[4]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[4] && props.data.images[4] !== "" && (isImage(props.data.images[4]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[4]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[4])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div onClick={() => fileref6.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                            <span>{t("profile.Add_a_file")}</span>
                                                            <i className="huge file icon"></i>
                                                            {
                                                                ProductImage6 ? (
                                                                    ProductImage6.type.includes("image") ? <div css={`position:absolute;top:0;left:0;background:url('${props.testv[5]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div> :
                                                                        <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                            <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>
                                                                                {props.testv[5]}
                                                                            </div>
                                                                        </div>
                                                                ) : props.data.images && props.data.images[5] && props.data.images[5] !== "" && (isImage(props.data.images[5]) ? (
                                                                    <div css={`position:absolute;top:0;left:0;background:url('${props.data.images[5]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                    </div>
                                                                ) : (
                                                                    <div css={`position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;`}>
                                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(props.data.images[5])}</span>
                                                                    </div>
                                                                )
                                                                )

                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Hourly_rate")} ({t("profile.Total_with_commission")}: {"$" + parseFloat(priceWithCommision - 1.40).toFixed(2)}) <Popup basic content={t("profile.modal_verbatin", { fee: theCat?.value?.rate })} trigger={<Icon disabled name='question circle outline' />} /> <span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <div class="ui left labeled input">
                                                    <div class="ui basic label">
                                                        $
                                                    </div>
                                                    <input value={values.hourly_rate} onChange={handleInputChange} type="number" name="hourly_rate" />
                                                </div>
                                                {(parseFloat(priceWithCommision - 1.40)) <= 0 &&
                                                    <p style={{ marginTop: "7px", color: "#db2828" }}>{t("profile.price_not_zero")}</p>
                                                }
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Requirements")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <textarea value={values.requirements} onChange={handleInputChange} name="requirements" placeholder={t("profile.what_need_for_client")}></textarea>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                                        <form className="ui form">
                                            <div className="field">
                                                <label>{t("profile.About_service_offered")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <textarea value={values.about_service} onChange={handleInputChange} name="about_service" placeholder={t("profile.explain_what_services")}></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Timezone")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <label>{t("profile.What_time_zone")}</label>
                                                <Dropdown
                                                selectOnBlur={false}
                                                    selection
                                                    fluid
                                                    options={timezones}
                                                    defaultValue={values.timezone}
                                                    onChange={(e, { value }) => { handleInputChange({ target: { name: "timezone", value } }) }}
                                                />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Location")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <Dropdown
                                                selectOnBlur={false}
                                                    selection
                                                    fluid
                                                    options={locations}
                                                    defaultValue={values.location}
                                                    onChange={(e, { value }) => { handleInputChange({ target: { name: "location", value } }) }}
                                                />
                                            </div>
                                            <div className="field" css="margin:20px 0;">
                                                <label>{t("profile.Languages")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <div css="display:flex;align-items:center;margin-top:20px;">
                                                    <input checked={values.languages.indexOf("English") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="English" type="checkbox" name="languages" id="english-language-radio" /><label css="margin-left:5px;" htmlFor="english-language-radio">{t("profile.english")}</label>
                                                </div>
                                                <div css="display:flex;align-items:center;">
                                                    <input checked={values.languages.indexOf("Spanish") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Spanish" type="checkbox" name="languages" id="spanish-language-radio" /><label css="margin-left:5px;" htmlFor="spanish-language-radio">{t("profile.spanish")}</label>
                                                </div>
                                            </div>
                                            <div className="field" css="margin:20px 0;">
                                                <label>{t("profile.Availability")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <div css="display:flex;align-items:center;margin-top:20px;">
                                                    <input checked={values.availability.indexOf("Full time") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Full time" type="checkbox" name="availability" id="Full-time-radio" /><label css="margin-left:5px;" htmlFor="Full-time-radio">{t("profile.Full_time")}</label>
                                                </div>
                                                <div css="display:flex;align-items:center;">
                                                    <input checked={values.availability.indexOf("Part time") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Part time" type="checkbox" name="availability" id="Part-time-radio" /><label css="margin-left:5px;" htmlFor="Part-time-radio">{t("profile.Part_time")}</label>
                                                </div>
                                            </div>
                                            <div className="field" css="margin:20px 0;">
                                                <label>{t("profile.Available_days")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <label>{t("profile.What_days")}</label>
                                                <div css="display:flex;flex-wrap:wrap;margin-top:20px;margin-left:-20px;">
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Monday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Monday" type="checkbox" name="available_days" id="Monday-radio" /><label css="margin-left:5px;" htmlFor="Monday-radio">{t("profile.Monday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Tuesday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Tuesday" type="checkbox" name="available_days" id="Tuesday-radio" /><label css="margin-left:5px;" htmlFor="Tuesday-radio">{t("profile.Tuesday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Wednesday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Wednesday" type="checkbox" name="available_days" id="Wednesday-radio" /><label css="margin-left:5px;" htmlFor="Wednesday-radio">{t("profile.Wednesday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Thursday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Thursday" type="checkbox" name="available_days" id="Thursday-radio" /><label css="margin-left:5px;" htmlFor="Thursday-radio">{t("profile.Thursday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Friday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Friday" type="checkbox" name="available_days" id="Friday-radio" /><label css="margin-left:5px;" htmlFor="Friday-radio">{t("profile.Friday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Saturday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Saturday" type="checkbox" name="available_days" id="Saturday-radio" /><label css="margin-left:5px;" htmlFor="Saturday-radio">{t("profile.Saturday")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_days.indexOf("Sunday") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Sunday" type="checkbox" name="available_days" id="Sunday-radio" /><label css="margin-left:5px;" htmlFor="Sunday-radio">{t("profile.Sunday")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field" css="margin:20px 0;">
                                                <label>{t("profile.Available_times")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <label>{t("profile.What_days")}</label>
                                                <div css="display:flex;flex-wrap:wrap;margin-top:20px;margin-left:-20px;">
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_times.indexOf("Early") !== -1} value="Early" onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} type="checkbox" name="available_times" id="Early-radio" /><label css="margin-left:5px;" htmlFor="Early-radio">{t("profile.Early_morning")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_times.indexOf("Morning") !== -1} value="Morning" onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} type="checkbox" name="available_times" id="Morning-radio" /><label css="margin-left:5px;" htmlFor="Morning-radio">{t("profile.Morning")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_times.indexOf("Afternoon") !== -1} value="Afternoon" onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} type="checkbox" name="available_times" id="Afternoon-radio" /><label css="margin-left:5px;" htmlFor="Afternoon-radio">{t("profile.Afternoon")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_times.indexOf("Evening") !== -1} value="Evening" onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} type="checkbox" name="available_times" id="Evening-radio" /><label css="margin-left:5px;" htmlFor="Evening-radio">{t("profile.Evening")}</label>
                                                    </div>
                                                    <div css="display:flex;align-items:center;margin-left:20px;">
                                                        <input checked={values.available_times.indexOf("Night") !== -1} value="Night" onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} type="checkbox" name="available_times" id="Night-radio" /><label css="margin-left:5px;" htmlFor="Night-radio">{t("profile.Night")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Message
                                                    attached
                                                    header={t("profile.select_tax_code")}
                                                    content={t("profile.select_correct_tax_code_ser")}
                                                />
                                                <div className='ui form attached fluid segment'>
                                                    <Form.Select
                                                        search
                                                        label={t("profile.category")}
                                                        options={taxSubCategories}
                                                        onChange={handleTaxCategoryChange}
                                                        value={values.tax_category}
                                                    />
                                                    <Form.Select
                                                        required
                                                        search
                                                        label={t("profile.service")}
                                                        options={taxcodes}
                                                        loading={taxLoading}
                                                        onChange={(e, { value }) => handleInputChange({ target: { name: "taxcode", value } })}
                                                        value={values.taxcode}
                                                    />
                                                    <div className="ui field">
                                                        <label >{t("profile.tax_code")}</label>
                                                        <div>{values.taxcode}</div>
                                                    </div>
                                                </div>
                                                <Message attached='bottom' warning>
                                                    <Icon name='help' />
                                                    Already signed up?&nbsp;<a href='#'>Login here</a>&nbsp;instead.
                                                </Message>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </>
                            )
                    }
                    {props.data.reject_reason && <div className="ui form">
                        <div className="ui field">
                            <label htmlFor="">{t("product.reject_reason")}</label>
                            <Segment>
                                {props.data.reject_reason}
                            </Segment>
                        </div>
                    </div>}
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;">
                        {
                            props.data !== true && <Button circular color="red" onClick={() => setShowConfirmDelete(true)}>
                                {t("profile.Delete")}
                            </Button>
                        }
                        <Button css="background:rgb(2,183,178) !important;" circular color="teal" onClick={props.data !== true ? handleSubmitUp : handleSubmit}>
                            {t("profile.Save")}
                        </Button>
                        {(userInfo.paypal_email && userInfo.publish_vendor && props.data !== true) && !props.data.publish_request && !props.data.publish && <Button css="background:rgb(0,128,129) !important;" circular color="teal" onClick={() => { setShowConfirmPublish(true) }}>
                            {t("profile.Publish")}
                        </Button>}
                    </div>

                </Modal.Actions>
                {
                    ShowConfirmDelete && <ConfirmDelete id={props.data._id} update={props.update} onClose={() => { setShowConfirmDelete(false); props.onClose() }} />
                }
                {
                    ShowConfirmPublish && <ConfirmPublish save={handleSubmitUp} update={() => props.update()} id={props.data._id} onClose={() => { setShowConfirmPublish(false); props.onClose() }} />
                }
            </Modal>
        </>
    )
}

export default ProductModal


//(Total:{(+values.hourly_rate)*(parseFloat("1."+(userData.seller.category_rate+"").length==1 ? "0"+userData.seller.category_rate : userData.seller.category_rate))})