export const navbarStyles=`
@media print {
    display:none;
 }

    max-width:1400px;
    margin:0 auto;
    margin-bottom:30px;
    width:100%;
    padding:0 36px;
    .headerContainer{
        display:flex;align-items:center;width:100%;
        @media(max-width:660px){
            flex-wrap:wrap;
            justify-content:space-between;
            padding-bottom:1rem;
        }
        .logoWrapper{
            max-width:200px;
            order:0;
            @media(max-width:600px){
                max-width:50px;
            }
            .logo1{
                max-width:100%;
                padding-top: 18px;
                @media(max-width:600px){
                    display:none;
                }
            }
            .logo2{
                max-width:100%;
                padding:5px 0;
                display:none;
                @media(max-width:600px){
                    display:block;
                }
            }
        }
    }
    .searchStyles{
        width:100%; 
        margin:0 0.5rem;
        position:relative;
        @media(max-width:660px){
            order:10;
            margin:0;
        }
    }
    .rightButtonsWrapper{
        display:flex;
        align-items:center;
        flex-shrink:0;
        @media(max-width:660px){
            order:1;
        }
        .cartImage{
            width:48px;
            height:48px;
            object-fit:cover;
            object-position:center;
        }
    }
`









