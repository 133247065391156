import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProgressBar from './progressbar-freelancer'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { btnContainer, btnPop, divImage, homeDivStyles, imageStyles, profimage1, profimage2, footButtons } from '../styles'
import countries from '../../shared/countries'
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import { useDispatch, useSelector } from 'react-redux'
import { formData } from '../../shared/formDataCreator'
import { userActions } from '../../store/user-slice'
import { url_environment } from '../../config'
import UpdateEmail from '../../shared/signInModal/updateEmail'

const FreelancerProfile = () => {

    const [Loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        link_to_website: "",
        city: "",
        country: "",
        id_country: "",
        bio: "",
        freelancer_image: "",
        freelancer_image_link: "",
        areyoucheck: [],
        additional_info:""
    })
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global")
    const handleInputChange = (e) => {
        setValues((prev) => { return { ...prev, [e.target.name]: e.target.value } })
    }
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            const temp = { ...values }
            temp.areyoucheck = JSON.stringify(temp.areyoucheck)
            delete temp.freelancer_image_link
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.put("/api/freelancer/profile", formData(temp), config)
            handleInputChange({ target: { name: "freelancer_image_link", value: url_environment + data.freelancer_picture } })
            dispatch(userActions.updateImageProfile(data.freelancer_picture.substring(1)))
            dispatch(userActions.updateValues({ name: "name", value: temp.name }))
            dispatch(userActions.updateValues({ name: "id_country", value: temp.id_country }))
            dispatch(userActions.updateValues({ name: "country", value: temp.country }))
            dispatch(userActions.updateValues({ name: "email", value: temp.email }))
            dispatch(userActions.updateValues({ name: "city", value: temp.city }))
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }

    const loadData = async () => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.get("/api/freelancer/profile", config)
            setValues(prev => {
                return {
                    ...prev,
                    name: data.id_user.name,
                    email: data.id_user.email,
                    password: "",
                    link_to_website: data.url_website,
                    city: data.id_user.city,
                    country: data.id_user.country,
                    id_country: data.id_user.id_country,
                    freelancer_image_link: url_environment + data.freelancer_picture,
                    bio: data.bio,
                    areyoucheck: data.areyoucheck,
                    additional_info:data.additional_info
                }
            })
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])
    useEffect(() => { console.log(values) }, [values])
    const history = useHistory()
    const submitButton = useRef()
    const imageRef = useRef()
    const handleImageChange = (e) => {
        setValues(p => { return { ...p, freelancer_image: e.target.files[0] || "" } })
        setValues(p => { return { ...p, freelancer_image_link: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" } })
    }
    const handleImageButtonClick = () => imageRef.current.click();
    const handleImageLoad = () => URL.revokeObjectURL(values.freelancer_image_link)

    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    const handleCountryChange = (e, { value }) => {
        setValues(p => {
            return {
                ...p,
                id_country: value,
                country: countries.find(x => x.value == value).name
            }
        })
    }

    const handleCheckboxChange = (checked, value) => {
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, areyoucheck: [...prev.areyoucheck, value] }
            } else {
                arr = prev.areyoucheck.filter((i) => { return i !== value });
                return { ...prev, areyoucheck: arr }
            }
        })
    }

    return (
        <>

            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={1} />
                </div>

                <h2 css="text-align:center;">{t("profile.Freelancer_Profile")}</h2>


                <form onSubmit={handleSubmit} className="ui grid container" css="justify-content:space-between">
                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                        <div className="ui form">
                            <div className="field">
                                <label>{t("profile.Name")}</label>
                                <input required value={values.name} onChange={handleInputChange} type="text" name="name" />
                            </div>
                            {/* <div className="field">
                                <label>{t("profile.Email")}</label>
                                <input required value={values.email} onChange={handleInputChange} type="text" name="email" />
                            </div> */}
                            <div className="field">
                                <label>{t("profile.Password")}</label>
                                <input value={values.password} onChange={handleInputChange} type="password" name="password" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Link_to website")}</label>
                                <input required value={values.link_to_website} onChange={handleInputChange} type="text" name="link_to_website" />
                            </div>
                            <div className="field">
                                <label>{t("profile.City")}</label>
                                <input required value={values.city} onChange={handleInputChange} type="text" name="city" />
                            </div>
                            {/*<div className="field">
                            <label>{t("profile.Country")}</label>
                            <input value={values.country} onChange={handleInputChange} type="text" name="country" />
                            </div>*/}
                            <Form.Select
                                search
                                fluid
                                label={t('profile.Country')}
                                options={countries}
                                defaultValue={values.id_country}
                                onChange={handleCountryChange}
                            />
                            <div className="field">
                                <label>{t("profile.Additional_info")}</label>
                                <textarea value={values.additional_info} onChange={handleInputChange} name="additional_info"></textarea>
                            </div>
                            <UpdateEmail />
                        </div>
                    </div>
                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                        <div className="ui form">
                            <div css="display:flex;align-items:center;justify-content:flex-end;">
                                <input hidden type="file" ref={imageRef} onChange={handleImageChange} accept="image/jpeg, image/png" />
                                <p>{t('profile.Profile photo')}</p>
                                <div action="button" onClick={handleImageButtonClick} css="border:0;background:#fff;cursor:pointer;padding:0;width:120px;height:120px;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);margin-left:3.5rem;">
                                    <div css="width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:relative;">
                                        {values.freelancer_image_link === "" && <div css="display:flex;flex-direction:column;align-items:center;">
                                            <p css="text-align:center;margin:0;margin-bottom:5px;">{t('profile.Add_a_photo')}</p>
                                            <i className="ui big camera icon"></i>
                                        </div>}
                                        {values.freelancer_image_link !== "" && <img src={values.freelancer_image_link} css="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" onLoad={handleImageLoad} alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label>{t("profile.Bio")}</label>
                                <textarea placeholder={t("profile.freelancer_bio_placeholder")} value={values.bio} onChange={handleInputChange} name="bio"></textarea>
                            </div>
                            <div className="field">
                                <div class="ui form">
                                    <div class="grouped fields">
                                        <label>{t('profile.Check_all_that_apply')}</label>
                                        <div class="field">
                                            <div class="ui checkbox">

                                                <input checked={values.areyoucheck.indexOf("business_owner") !== -1} onChange={(e) => { handleCheckboxChange(e.target.checked, e.target.value) }} value="business_owner" type="checkbox" name="areyoucheck" />
                                                <label htmlFor="areyoucheck">
                                                    {t("profile.Are you a business owner?")}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui checkbox">

                                                <input checked={values.areyoucheck.indexOf("freelancer") !== -1} onChange={(e) => { handleCheckboxChange(e.target.checked, e.target.value) }} value="freelancer" type="checkbox" name="areyoucheck" />
                                                <label htmlFor="areyoucheck">
                                                    {t("profile.Are you a freelancer?")}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui checkbox">

                                                <input checked={values.areyoucheck.indexOf("instructor") !== -1} onChange={(e) => { handleCheckboxChange(e.target.checked, e.target.value) }} value="instructor" type="checkbox" name="areyoucheck" />
                                                <label htmlFor="areyoucheck">
                                                    {t("profile.Are you an instructor?")}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui checkbox">
                                                <input checked={values.areyoucheck.indexOf("video_games") !== -1} onChange={(e) => { handleCheckboxChange(e.target.checked, e.target.value) }} value="video_games" type="checkbox" name="areyoucheck" />
                                                <label htmlFor="areyoucheck">{t("profile.Can you develop video games?")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" hidden ref={submitButton}></button>
                </form>

                <div css={footButtons}>
                    <button onClick={() => submitButton.current.click()} className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Save")}</button>
                    <button onClick={() => history.push("/freelancer-cv")} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</button>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default FreelancerProfile