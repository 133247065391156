import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Comment, Dimmer, Form, Loader, Rating } from 'semantic-ui-react'
import axios from '../../axios'
import "styled-components/macro"
import { useTranslation } from 'react-i18next'
import { formData } from '../../shared/formDataCreator'
import ImageGallery from './imageGallery'
import { url_environment } from '../../config'
const ReviewsTab = (props) => {
    const [loading, setLoading] = useState(false)
    const [rating, setRating] = useState(false)
    const [allow_review, setAllow_review] = useState(false)
    const [reviews, setReviews] = useState([])
    const [files, setFiles] = useState([])
    const [filesData, setFilesData] = useState([])
    const [text, setText] = useState("")
    const { userInfo } = useSelector(state => state.user)
    const [photo, setPhoto] = useState(undefined)
    const [t, i18n] = useTranslation("global")
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    let loadData = () => { }
    useEffect(() => {
        loadData()
    }, [])
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    // if (!userInfo) {
    //     return <>
    //         <h2 css="margin-top:10px;text-align:center;">{t("profile.you_have_to_reviews")}</h2>
    //     </>
    // }
    loadData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setLoading(true)
        try {
            const { data } = await axios.get(`/api/product/review/get/${props.id}`)
            setReviews(data)
            if(userInfo){
                const { data: allow } = await axios.get("/api/product/review/allow/" + props.id, config)
                setAllow_review(allow === "ok")
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleRate = (e, { rating }) => {
        setRating(rating)
    }
    const handleTextChange = (e) => {
        setText(e.target.value)
    }
    const handleAddPhoto = (e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])
    }
    
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleSubmit = async (e) => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        setLoading(true)
        e.preventDefault()
        try {
            await axios.post("/api/product/review/create", formData({
                product_id: props.id,
                text,
                rating,
                ...getFilesObject(files),
            }), config)
            await loadData()
            setAllow_review(false)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            {reviews.length === 0 && (
                <h2 style={{ textAlign: "center", margin: "20px 0" }}>{t("product.no_reviews")}</h2>
            )}
            <Comment.Group>
                {reviews.map((x) => {
                    return (
                        <>
                            <Comment>
                                {/* <Comment.Avatar as='a' src={`https://interpowered.com:3001${x.user.customer_picture}`} /> */}
                                <span style={{ marginRight: "10px" }} className="avatar" /*css="display: block;width:50px;height:auto;float:left;margin-top: .2em;margin-right:10px !important;"*/>
                                    <img css="margin-right:10px;width:50px !important;height:50px !important;object-fit:cover;object-position:center;" src={`${url_environment}${x.user.customer_picture}`} alt="" />
                                </span>
                                <Comment.Content style={{ paddingLeft: "10px" }}>
                                    <Comment.Author>{x.user.name}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{new Date(x.created_at).toLocaleDateString()}</div>
                                    </Comment.Metadata>
                                    <div css="margin-top:10px;"><Rating maxRating={5} rating={x.rating} /></div>
                                    <Comment.Text>
                                        {x.text}
                                    </Comment.Text>
                                    <ImageGallery images={x.images}/>
                                </Comment.Content>
                            </Comment>
                        </>
                    )
                })}
                {/* <hr /> */}
                {allow_review && <Form style={{ paddingTop: "10px", borderTop: "1px solid #dfdfdf" }} onSubmit={handleSubmit} reply>
                    <div className="ui field">
                        <label>Rating: </label><Rating defaultRating={0} maxRating={5} onRate={handleRate} />
                    </div>
                    <Form.TextArea label="Text" required onChange={handleTextChange} />
                    <Button color="teal" circular content={t('product.add_review')} labelPosition='left' icon='edit' />
                    <label style={{ marginBottom: "20px" }} class="ui labeled circular teal icon button" htmlFor="custom_button_upload_image">
                        <i class="image icon"></i>
                        <input
                            id="custom_button_upload_image"
                            type="file"
                            accept="image/*"
                            onChange={handleAddPhoto}
                            css="display:none;"
                        />
                        {t('product.add_photo')}
                    </label>
                    {/* <Button color="teal" circular content={t('product.add_photo')} labelPosition='left' icon='picture' /> */}
                    {/* {photo && <span><b>Photo: </b>{photo.name}</span>} */}
                    <div css="display:flex;align-items:center;justify-content:flex-start;">
                        {filesData.map((item) => {
                            return <>
                                <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                    <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                        <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                            <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>

                </Form>}
            </Comment.Group>
        </>
    )
}

export default ReviewsTab