import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { uiActions } from '../../store/ui-slice';
// all available config props
const config = {
  headerTitle: 'Chat Interpowered'
};
// all available theme props
const theme = {
  background: '#f5f8fb',
  // fontFamily: 'Helvetica Neue',
  headerBgColor: '#108081',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#108081',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};
// class SimpleForm extends Component {
//     render() {
const SimpleForm = () => {
  const { userInfo } = useSelector(state => state.user)
  const orders_link = userInfo ? (userInfo.id_role == "1" ? "/customer-orders" : "business-orders") : "/"
  const dispatch=useDispatch()
  const handleViewMessages=(e)=>{
    e.preventDefault()
    dispatch(uiActions.change_action({ open: true, action: "messages" }))
  }
  const handleReportProblemClick=(e)=>{
    e.preventDefault();
    dispatch(uiActions.change_action({ open: true, action: "report a problem" }))
  }

  const custom_shipping=(userInfo && userInfo.id_role=="2" ? [{ value: 'custom_shipping', label: 'Apply custom shipping', trigger: '15' }] : [])
  const check_messages=userInfo ? [{ value: 'check_messages', label: 'How do I check my messages?', trigger: '16' }] : []
  const check_favorites=userInfo ? [{ value: 'check_favorites', label: 'How do I view my favorites?', trigger: '17' }] : []
  return (
    <ThemeProvider theme={theme}>
      <ChatBot

        steps={[
          {
            id: '1',
            message: 'Hello! How can I help you?',
            trigger: '2',
          },
          {
            id: '2',
            options: [
              { value: 'shipments', label: 'Shipments', trigger: '3' },
              { value: 'purchases', label: 'Purchases', trigger: '5' },
              { value: 'contact', label: 'Contact Us', trigger: '6' },
              ...check_messages,
              ...check_favorites,
              ...custom_shipping
            ],
          },
          {
            id: '3',
            message: 'Do you need the shipping information for a product you just bought? You may message the seller and request a tracking number as well as ask any other questions pertaining to the item you purchased from them. You may also view your order information under the following link.',
            trigger: 4,
            delay: 10,
          },
          {
            id: '4',
            component: (<Link to={orders_link}>Orders</Link>),
            trigger: 10,
            delay: 10
          },
          {
            id: '5',
            message: 'Are you looking for the order information of a product you just bought? Navigate to the following link.',
            trigger: 4,
            delay: 10,
          },
          {
            id: '6',
            message: 'Did you have an issue with the application, a seller, a freelancer, or a customer? Or do you simply have an idea for how to make the application even better? Go to the following link to select the issue category, explain the problem, and attach any relevant screenshots or documents.',
            trigger: 11,
            delay:10
          },
          {
            id: '7',
            message: 'I hope I have been helpful! Do you have any question?',
            trigger: 'question',
          },
          {
            id: 'question',
            user: true,
            trigger: '8',
            validator: (value) => {
              if (value.length < 0) {
                return 'Please write a question';
              } else if (value.includes("carriers")) {
                return true
              } else if (value.includes("Carriers")) {
                return true
              }
              
              return 'Wrong Question';
            },
          },
          {
            id: '8',
            message: 'DHL , USPS, UPS',
            trigger: '9',
          },
          {
            id: '9',
            message: 'I hope I have been helpful!',
            trigger: '1',
          },
          {
            id: '10',
            message: ' If at any time you have a question related to this order, you may message the seller for more information.',
            trigger: 7
          },
          {
            id: '11',
            component: (<a href="#" onClick={handleReportProblemClick}>Report problem</a>),
            trigger: 12,
            delay: 10
          },
          {
            id:'12',
            message:' We will review your message and get back to you as soon as possible. We appreciate your patience as we work to improve. Our goal is to be your favorite one-stop-shop for all your shopping and/or entrepreneurial needs.',
            trigger:7,
            delay:10
          },
          {
            id: '13',
            component: (<a href="#" onClick={handleViewMessages}>Click here to view your messages</a>),
            trigger: 7,
            delay: 10
          },
          {
            id: '14',
            component: (<Link to="/favorites">Click here to view your favorites</Link>),
            trigger: 7,
            delay: 10
          },
          {
            id: '15',
            message:"The store can define the shipping price as long as it uses DHL, additionally it could include the shipping price in the product price and leave the shipping price at $ 00.00",
            trigger: 7,
            delay: 10
          },
          {
            id: '16',
            message:"You can find your messages in the link bellow",
            trigger: 13,
            delay: 10
          },
          {
            id: '17',
            message:"You can find your favorites in the link bellow",
            trigger: 14,
            delay: 10
          },
        ]}
        {...config}
      />
    </ThemeProvider>
  );
}

export default SimpleForm;