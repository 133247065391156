import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Button, Dimmer, Dropdown, Form, Icon, Loader, Modal } from 'semantic-ui-react'
import { categoryButtonStyles } from '../Administration/admin_panel/styles'
import axios from '../axios'
import { useTranslation } from 'react-i18next'
import countries from '../Profile/countries'
import 'styled-components/macro'
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const FiltersModal = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const [applyLoading, setApplyLoading] = useState(false)
    const [category, setCategory] = useState("")
    const [categories, setCategories] = useState([])
    const [vendor, setVendor] = useState("All")
    const history = useHistory()
    const [lowPrice, setLowPrice] = useState("")
    const [highPrice, setHighPrice] = useState("")
    const [languageFilter, setLanguageFilter] = useState([])
    const [locationFilter, setLocationFilter] = useState("")
    const lr = useRef()
    const hr = useRef()
    const query = useQuery()
    const [t, i18n] = useTranslation("global")
    const getCategories = async () => {
        try {
            setLoading(true)
            const { data } = await axios.get("/api/category/")
            setCategories([data[0].map((i) => { return { key: i._id, text: i.name, value: i.id_cat/*{id:i.id_cat,vendor_type:i.vendor_type}*/ } }), data[1].map((i) => { return { key: i._id, text: i.name, value: i.id_cat /*{id:i.id_cat,vendor_type:i.vendor_type}*/ } })])
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const effect = () => {
        let price = query.get("price")
        let vendor = query.get("vdr")
        let category = query.get("cat")
        let location = query.get("location")
        let languages = query.get("languages")
        if (price) {
            setLowPrice(price.split("-")[0]);
            setHighPrice(price.split("-")[1]);
        }
        if (vendor) {
            setVendor(vendor)
        }
        if (category) {
            setCategory(category)
        }
        if (location) {
            setLocationFilter(location)
        }
        if (languages) {
            setLanguageFilter(languages.split(","))
        }
    }

    const handleApply = async () => {
        const price = [lowPrice, highPrice]
        //const price=[lr.current.value,hr.current.value]
        //props.onFilter(category.id,price,category.vendor_type,vendor)
        const qparams = new URLSearchParams({ price: `${lowPrice}-${highPrice}`, vdr: vendor !== "All" ? vendor : "", cat: category, location:locationFilter,languages:languageFilter.join(",") }).toString()
        history.push(`/search/${props.term}?${qparams}`)
    }
    useEffect(() => { if (vendor === "All") { setCategory("") } }, [vendor])
    useEffect(() => { getCategories(); effect() }, [])
    const handleClear = () => {
        history.push(`/search/${props.term}`)
    }


    const handleCheckboxChange = (name, checked, value) => {
        setLanguageFilter(prev => {
            if (checked) {
                return [...prev, value]
            } else {
                return prev.filter(i => i !== value)
            }
        })
    }

    const locations = useMemo(() => {
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" }, ...countries]
    }, [])

    return <>
        {/* <Modal
closeIcon
closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => { setOpen(true); getCategories();effect() }}
            open={open}
            trigger={<Button circular basic color="black" style={{marginLeft:"10px"}}><Icon name='filter' /> All filters</Button>}
            size="tiny"
            style={{alignSelf:"flex-start"}}
        >
            <Modal.Header>Filters</Modal.Header>
            <Modal.Content>
                {
                    loading ? <>
                        <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                    </> : <>
                        <div className="ui form">
                            <Form.Select
                                fluid
                                label='Filter by vendor'
                                options={[{key:0,text:"All",value:"All"},{key:1,text:"Freelancer",value:"Freelancer"},{key:2,text:"Business",value:"Business"}]}
                                value={vendor}
                                onChange={(e,{value})=>{setVendor(value)}}
                            />
                            <Form.Select
                                fluid
                                disabled={vendor==="All"}
                                label='Filter by Category'
                                options={vendor==="Freelancer" ? categories[0] : categories[1]}
                                //{...(categories && (categories[0] || categories[1]) && {options:(vendor==="Freelancer" ? [{key:"null",text:"None",value:""},...categories[0]] : [{key:"null",text:"None",value:""},...categories[1]])})}//options={vendor==="Freelancer" ? [{key:"null",text:"None",value:""},...categories[0]] : [{key:"null",text:"None",value:""},...categories[1]]}
                                value={category}
                                onChange={(e,{value})=>{setCategory(value)}}
                            />
                            <div className="field">
                                <label>Filter by price</label>
                                <div className="inline fields">
                                    <div className="field">
                                        <input value={lowPrice} onChange={(e)=>{setLowPrice(e.target.value)}} type="text" placeholder="Low" />
                                    </div>
                                    <div className="field">
                                        <label>to</label>
                                    </div>
                                    <div className="field">
                                        <input value={highPrice} onChange={(e)=>{setHighPrice(e.target.value)}} type="text" placeholder="High" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </Modal.Content>
            <Modal.Actions>
                <Button basic color='black' onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button loading={applyLoading} onClick={handleApply} color="black">Apply</Button>
            </Modal.Actions>
        </Modal> */}

        {
            loading ? <>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
            </> : <>
                <div className="ui form">
                    <div style={{ justifyContent: "flex-start", display: "flex", flexWrap: "wrap" }}>
                        <div style={{ margin: "0 1em", zIndex: "999" }}>
                            <Form.Select
                                label='Filter by vendor'
                                options={[{ key: 0, text: "All", value: "All" }, { key: 1, text: "Freelancer", value: "Freelancer" }, { key: 2, text: "Business", value: "Business" }]}
                                value={vendor}
                                onChange={(e, { value }) => { setVendor(value) }}
                            />
                        </div>
                        <div style={{ margin: "0 1em", zIndex: "9999" }}>
                            <Form.Select
                                disabled={vendor === "All"}
                                label='Filter by Category'
                                options={vendor === "Freelancer" ? categories[0] : categories[1]}
                                //{...(categories && (categories[0] || categories[1]) && {options:(vendor==="Freelancer" ? [{key:"null",text:"None",value:""},...categories[0]] : [{key:"null",text:"None",value:""},...categories[1]])})}//options={vendor==="Freelancer" ? [{key:"null",text:"None",value:""},...categories[0]] : [{key:"null",text:"None",value:""},...categories[1]]}
                                value={category}
                                onChange={(e, { value }) => { setCategory(value) }}
                            />
                        </div>
                        <div style={{ margin: "0 1em" }} className="field">
                            <label>{t("navbar.filter_by_price")}</label>
                            <div className="inline fields">
                                <div className="field">
                                    <input value={lowPrice} onChange={(e) => { setLowPrice(e.target.value) }} type="text" placeholder={t("product.low")} />
                                </div>
                                <div className="field">
                                    <label>to</label>
                                </div>
                                <div className="field">
                                    <input value={highPrice} onChange={(e) => { setHighPrice(e.target.value) }} type="text" placeholder={t("product.high")} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ justifyContent: "flex-start", display: "flex", flexWrap: "wrap" }}>
                        <div style={{ margin: "0 1em" }} className="field">
                            <label>{t("navbar.filter_by_language")}</label>
                            <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                <div css="flex:0 0 auto;padding:8px;display:flex;align-items:center;">
                                    <input checked={languageFilter.indexOf("English") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="English" type="checkbox" name="languages" id="english-language-radio" /><label css="margin-left:5px;" htmlFor="english-language-radio">{t("profile.english")}</label>
                                </div>
                                <div css="flex:0 0 auto;padding:8px;display:flex;align-items:center;">
                                    <input checked={languageFilter.indexOf("Spanish") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Spanish" type="checkbox" name="languages" id="spanish-language-radio" /><label css="margin-left:5px;" htmlFor="spanish-language-radio">{t("profile.spanish")}</label>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: "0 1em" }} className="field">
                            <label>{t("navbar.filter_by_location")}</label>
                            <div className="inline fields">
                                <Dropdown
                                    style={{ width: "200px" }}
                                    selectOnBlur={false}
                                    selection
                                    fluid
                                    options={locations}
                                    value={locationFilter}
                                    onChange={(e, { value }) => { setLocationFilter(value) }}
                                />
                            </div>
                        </div>
                        <div style={{ margin: "0 1em" }} className="field" style={{ display: "flex", alignItems: "flex-end" }}>
                            <Button style={{ marginBottom: "1em" }} content={t("navbar.apply")} loading={applyLoading} onClick={handleApply} color="black" />
                            <Button style={{ marginBottom: "1em", marginLeft: "1em" }} content={t("navbar.clear")} loading={applyLoading} onClick={handleClear} color="black" />
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}

export default FiltersModal