
import React, { useCallback, useEffect, useState } from 'react'
import { Dimmer, Icon, Loader, Rating } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


import Footer from '../shared/footer/footer'
import placeholder from '../img/photo_placeholder.svg'
import Navbar from '../shared/navbar/navbar'
import axios from '../axios'
import { homeStyles } from './home.styles'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../store/user-slice';
import { getImage2, url_environment } from '../config';
import ProductCategories from "./product-categories"
import countries from '../shared/countries';
import { useGetLocationName, useGetProductLanguages } from '../shared/custom_hooks';


const Home = () => {
    const [loading, setLoading] = useState(true)
    const [publishedProducts, setPublishedProducts] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [productCategories, setProductCategories] = useState([])
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const size = useWindowSize();
    const dispatch = useDispatch()

    const getLocationName=useGetLocationName()
    const getProductLanguages=useGetProductLanguages()
    

    function chunk(items, size) {
        const chunks = []
        items = [].concat(...items)

        while (items.length) {
            chunks.push(
                items.splice(0, size)
            )
        }

        return chunks
    }
    const handleFavoriteClick = async (e) => {
        const id = e.target.getAttribute("data-product-id")
        const type = e.target.getAttribute("data-type")
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (id.endsWith(" remove")) {
                const rid = id.split(" ")[0]
                await axios.delete("/api/user/favorite/" + rid, config)
            } else {
                await axios.post("/api/user/favorite", { id, type }, config)
            }
            dispatch(userActions.updateFavorites({ id, type }))
        } catch (e) { alert(e) }
    }

    const handleCategoryClick = (id) => {

    }

    const chunk2 = (items, size) => {
        let newA = chunk(items, size)
        let a = newA[newA.length - 1].length;
        a = size - a;
        for (let i = 0; i < a; i++) {
            newA[newA.length - 1].push({ id: "hidden", id_cat: "hidden", image: "hidden", rate: "hidden", name: "hidden", hidden: true })
        }
        return newA
    }

    useEffect(() => {
        const fn = async () => {
            try {
                const { data: productData } = await axios.get("/api/paypal/marketinghomepage")
                setPublishedProducts(productData)
                const { data: productCategories } = await axios.get("/api/category/product-categories")
                setProductCategories(productCategories)
                const { data: categoryData } = await axios.get("/api/category/marketing")
                setCategoryData([...categoryData[1], ...categoryData[0]].sort((a, b) => (a.name.trim()[0] > b.name.trim()[0]) ? 1 : -1))
                setLoading(false)
            } catch (e) {
                alert(e)
                setLoading(false)
            }
        }
        fn()
    }, [])

  

    if (loading) return <>
        <Dimmer active inverted>
            <Loader />
        </Dimmer>
    </>

    return (
        <>
            <Navbar/>
            <main>

                <div css={homeStyles} className="main_sticky">
                    {/*<button onClick={()=>{dispatch(changeName("hola"))}}>click name</button>*/}
                    <div className="findSection">
                        <div className="sectionStyles">
                            <h2 className="h2FindStyles">{t("homeScreen.Find what you're looking for")}</h2>
                            <Carousel showIndicators={false} showStatus={false} showThumbs={false}>
                                {categoryData.length > 0 &&
                                    (() => {
                                        if (size === "lg") {
                                            return chunk2(categoryData, 6).map((imgWrapper) => {
                                                return <>
                                                    <div className="imgWrapper" style={{alignItems:"start"}} >
                                                        {imgWrapper.map((productSection) => {
                                                            return <Link to={`/category-products?cat_name=${productSection.name}&id_cat=${productSection._id}&vendor_type=${productSection.vendor_type}`}><div data-id-cat={productSection._id} key={productSection._id} className="productSection-lg" style={{ cursor: "pointer", visibility: (productSection.hidden ? "hidden" : "") }}>
                                                                <img className="productImg" src={productSection.image ? url_environment + productSection.image : placeholder} alt="" />
                                                                <span css="color:#fff;white-space: pre-wrap;">{productSection.name}</span>
                                                            </div></Link>
                                                        })}
                                                    </div>
                                                </>
                                            })
                                        } else if (size === "md") {
                                            return chunk2(categoryData, 3).map((imgWrapper) => {
                                                return <>
                                                    <div className="imgWrapper" style={{alignItems:"start"}} >
                                                        {imgWrapper.map((productSection) => {
                                                            return <Link to={`/category-products?cat_name=${productSection.name}&id_cat=${productSection._id}&vendor_type=${productSection.vendor_type}`}><div key={productSection._id} className="productSection-lg" style={{ visibility: (productSection.hidden ? "hidden" : "") }}>
                                                                <img className="productImg" src={productSection.image ? url_environment + productSection.image : placeholder} alt="" />
                                                                <span css="color:#fff;white-space: pre-wrap;">{productSection.name}</span>
                                                            </div></Link>
                                                        })}
                                                    </div>
                                                </>
                                            })
                                        } else if (size === "sm") {
                                            return chunk2(categoryData, 1).map((imgWrapper) => {
                                                return <>
                                                    <div className="imgWrapper" style={{alignItems:"start"}} >
                                                        {imgWrapper.map((productSection) => {
                                                            return <Link to={`/category-products?cat_name=${productSection.name}&id_cat=${productSection._id}&vendor_type=${productSection.vendor_type}`}><div key={productSection._id} className="productSection-lg" style={{ visibility: (productSection.hidden ? "hidden" : "") }}>
                                                                <img className="productImg" src={productSection.image ? url_environment + productSection.image : placeholder} alt="" />
                                                                <span css="color:#fff;white-space: pre-wrap;">{productSection.name}</span>
                                                            </div></Link>
                                                        })}
                                                    </div>
                                                </>
                                            })
                                        }
                                    })()
                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="dailySection">
                        <h2 css="text-align:center;">{t("homeScreen.Daily Product Showcase")}</h2>
                        <div className="dailyWrapper">
                            {
                                publishedProducts.length > 0 && publishedProducts.map((i) => {
                                    return <div key={i._id} className="imgWrapper">
                                        {
                                            (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                                <div css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                                </div>
                                            ) : (userInfo &&
                                                <div className="heartBtn" css={`position:absolute;top:15px;right:15px;z-index:999;width:30px;height:30px;display:${"none"};align-items:center;justify-content:center;border-radius:100%;background:#fff;`}>
                                                    <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                                </div>
                                            )
                                        }
                                        <Link to={`${i.service_name ? "/service/" + i._id : "/product/" + i._id}`} css="color:#000; &:hover{color:#000;}" href="">
                                            <div css="padding-bottom:80%;position:relative;">
                                                <img className="bigImg" src={getImage2(i.images[0])/*;i.images[0] ? (url_environment + (i.images[0] || "")) : (placeholder)*/} alt="" />
                                            </div>
                                            <div>
                                                <h3 className="h3Text">{i.service_name || i.name}</h3>
                                                <p css="margin:0;">{i.vendor_name}</p>
                                                <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                                <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                                <p css="margin:0;">USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(i.volume_pricing.length < 2 ? i.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + i.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>} </p>
                                                {i.location && <p css="margin:0;">{getLocationName(i.location)}</p>}
                                                {i.languages?.length>0 && <p css="margin:0;">{getProductLanguages(i.languages)}</p>}
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                        <ProductCategories t={t} handleFavoriteClick={handleFavoriteClick} getImage2={getImage2} categories={categoryData} data={productCategories} />
                    </div>
                </div>
            </main>

            <Footer />
            {/* <TopMessage /> */}
        </>
    )
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            if (window.innerWidth > 990)
                setWindowSize("lg");
            if (window.innerWidth <= 990)
                setWindowSize("md");
            if (window.innerWidth <= 500)
                setWindowSize("sm");
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default Home


const TopMessage = () => {
    const [t,] = useTranslation("global")
    const [open, setOpen] = useState(true)
    return <>
        {
            open &&
            <div css="background-color:#02b7b2;color:white;position:fixed;top:0;left:0;width:100%;">
                <div css="max-width:1200px;margin:0 auto;padding:14px;display:flex;align-items:center;">

                    <div css="flex-grow:1;">
                        {t("homeScreen.thank_you_visiting")}
                    </div>
                    <div css="flex-grow:0;margin-left:15px;cursor:pointer;font-size:20px" onClick={() => { setOpen(false) }}>
                        X
                    </div>
                </div>
            </div>
        }
    </>
}






 