import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import 'styled-components/macro'
import ApprovalsProducts from './approvals_products'
import ApprovalsServices from './approvals_services'
import ApprovalsCourses from './approvals_courses'
import ApprovalsContracts from './approvals_contracts'

const Approvals = () => {
    return <>
        <Navbar />
        <main css="width:100%;max-width:1200px;margin:0 auto; padding:0 36px;">
            <ApprovalsProducts/>
            <div css="margin:20px 0;"></div>            
            <ApprovalsServices/>
            <div css="margin:20px 0;"></div>            
            <ApprovalsCourses/>
            <div css="margin:20px 0;"></div>            
            <ApprovalsContracts/>

        </main>
        <Footer />
    </>
}

export default Approvals