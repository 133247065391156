import React, { useState } from 'react';
import SimpleForm from '../chatbot/SimpleForm';
import 'styled-components/macro'
import { footerStyles } from './footer.styles.js'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import SignOutModal from './signoutModal';

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

const Footer = () => {
    const [t, i18n] = useTranslation("global")
    let [showChat, setShowChat] = useState(false);
    let [signoutOpen, setSignoutOpen] = useState(false);
    let [copiedOpen, setCopiedOpen] = useState(false);
    let date = new Date();
    let y = date.getFullYear();
    const { userInfo } = useSelector(state => state.user)
    const startChat = () => { setShowChat(true); }
    const hideChat = () => { setShowChat(false); }
    const history = useHistory()
    const dispatch = useDispatch()
    const handleSellOnInterClick = async (e) => {
        e.preventDefault()
        if (userInfo.id_role == "1") {
            setSignoutOpen(true)
            return;
        }
        if (userInfo.id_role == "2") {
            history.push("/business-profile-2")
            return;
        }
        if (userInfo.id_role == "3") {
            history.push("/freelancer-product")
            return;
        }
        dispatch(uiActions.change_action_sign_in({ open: true, action: "register" }))
    }

    const handleShareLinkClick = async (e) => {
        e.preventDefault()
        try {
            await copyTextToClipboard("https://interpowered.com")
            setCopiedOpen(true)
        } catch (e) { alert(e) }
    }
    const handleContractManagementClick=(e)=>{
        e.preventDefault();
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }));
            return;
        }
        if(userInfo.id_role=="3"){
            history.push("/received-proposals")
        }else{
            history.push("/sent-proposals")
        }
    }
    const handleTimesheetsClick=(e)=>{
        e.preventDefault();
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }));
            return;
        }
        history.push("/timesheets")
    }
    const handleViewMessagesClick = (e) => {
        e.preventDefault()
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }));
            return;
        }
        dispatch(uiActions.change_action({ open: true, action: "messages" }))
    }
    const handleShopClick=(e)=>{
        e.preventDefault()
        if(userInfo) { history.push("/"); return;}
        dispatch(uiActions.change_action_sign_in({ open: true, action: "register" }));
    }
    return (
        <>
            <footer className="sticky_footer" css={footerStyles}>
                <div className="links">
                    <div className="linksWrapper">
                        <div css="display: flex;flex-wrap: wrap;" >
                            <div className="linkList" >
                                <h3 css="margin-bottom:1rem;">{t('footer.shop')}</h3>
                                <ul>
                                    <li className="mb-2"><a href="#" onClick={handleShopClick}>{t('footer.shop_on_interpowered')}</a></li>
                                    {userInfo && userInfo.id_role != "1" && <li className="mb-2"><Link to="/marketing-packages">{t('footer.buy_marketing_packages')}</Link></li>}
                                    {userInfo && <li className="mb-2"><Link to="/wishlist">{t('footer.view_your_wishlist')}</Link></li>}
                                </ul>
                            </div>
                            <div className="linkList">
                                <h3 css="margin-bottom:1rem;">{t('footer.sell')}</h3>
                                <ul>
                                    <li className="mb-2"><a href="#" onClick={handleSellOnInterClick}>{t('footer.sell_on_interpowered')}</a></li>
                                </ul>
                            </div>
                            <div className="linkList">
                                <h3 css="margin-bottom:1rem;">{t('footer.build_your_business')}</h3>
                                <ul>
                                    <li className="mb-2"><i>{t('footer.build_your_business_para1')}</i></li>
                                    <li className="mb-2"><a href="#" onClick={handleContractManagementClick}>{t('footer.contract_management')}</a></li>
                                    <li className="mb-2"><a href="#" onClick={handleTimesheetsClick}>{t('footer.timesheets')}</a></li>
                                </ul>
                            </div>
                            <div className="linkList">
                                <h3 css="margin-bottom:1rem;">{t('footer.support')}</h3>
                                <ul>
                                    <li className="mb-2" style={{fontStyle:"italic"}}>{t('footer.support_para1')}</li>
                                    <li className="mb-2"><a href="#" onClick={handleShareLinkClick}>{t('footer.share_our_link')} </a></li>
                                    <li className="mb-2"><Link to="/wiki">{t('footer.wiki')}</Link></li>
                                </ul>
                            </div>
                            <div className="linkList">
                                <h3 css="margin-bottom:1rem;">{t('footer.connect')}</h3>
                                <ul>
                                    <li className="mb-2"><a href="#" onClick={handleViewMessagesClick}>{t('footer.view_your_messages')}</a></li>
                                    {userInfo && <li className="mb-2"><Link to="/favorites">{t('footer.view_your_favorites')}</Link></li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="foot">
                    <div className="footWrapper">
                        <div className="footDiv">
                            <button className="btnNat">
                                <i className="large globe icon"></i>
                                <span>{/*"Nicaragua &nbsp; | &nbsp; "*/}{i18n.language=="en"?"English":"Español"} &nbsp; | &nbsp; $ (USD)</span>
                            </button>
                            <div className="legal">
                                <span css="margin:0 5px;">© { y } Interpowered, LLC</span>
                                <Link to="/terms-of-service" css="text-decoration:underline;margin:0 5px;">{t('footer.terms_of_service')}</Link>
                                <Link to="/privacy-policy" css="text-decoration:underline;margin:0 5px;">{t('footer.privacy')}</Link>
                                {/* <a href="#" onClick={(e)=>{e.preventDefault()}} css="text-decoration:underline;margin:0 5px;">{t('footer.interest_based_ads')}</a> */}
                                {/* <Link to="/interest-based-ads" css="text-decoration:underline;margin:0 5px;">{t('footer.interest_based_ads')}</Link> */}
                            </div>
                            <div className="bot">
                                <div style={{ display: showChat ? "" : "none" }}>
                                    <SimpleForm></SimpleForm>
                                </div>
                                {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
                                <div css="float:right;">
                                    {!showChat
                                        ? <button title={t("footer.chatbot")} css="background:white !important;border: 4px solid teal !important;" className="btn" className="circular huge ui icon button" onClick={() => startChat()}><i className="icon wechat"></i></button>
                                        : <button title={t("footer.chatbot")} css="background:white !important;border: 4px solid teal !important;" className="btn" className="circular huge ui icon button" onClick={() => hideChat()}><i className="icon wechat"></i></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <SignOutModal open={signoutOpen} setClose={() => setSignoutOpen(false)} />
            <SignOutModal text={t("footer.link_copied_para")} open={copiedOpen} setClose={() => setCopiedOpen(false)} />
        </>
    )
}

export default Footer