import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: "ui",
    initialState:{
        uiMessagesModal:{
            open:false,
            action:""
        },
        uiSignInModal:{
            open:false,
            action:""
        }
    },
    reducers: {
        change_action(state, action) {
            state.uiMessagesModal.open=action.payload.open
            state.uiMessagesModal.action=action.payload.action
            state.uiMessagesModal.receiver=action.payload.receiver
            state.uiMessagesModal.view_message_data=action.payload.view_message_data
            state.uiMessagesModal.return=action.payload.return
            state.uiMessagesModal.link=action.payload.link
        },
        change_action_sign_in(state, action) {
            state.uiSignInModal.open=action.payload.open
            state.uiSignInModal.action=action.payload.action
            state.uiSignInModal.google_data=action.payload.google_data || false
        }
    }
})

export const uiActions = uiSlice.actions

export default uiSlice