import React, { useState } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import 'styled-components/macro'

const Proposal = () => {
    const [values, setValues] = useState({ business_name: "", link_to_website: "", searching_for: "", job_description: "", proposed_hourly_rate: "", requirements: "", timeline: "", })

    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1400px;margin:0 auto;padding:0 36px;">
                    <div css="max-width:1000px;margin:0 auto;">
                        <div css="display:flex;justify-content:space-between;@media(max-width:660px){flex-direction:column;align-items:center;}">
                            <div css="display:flex;">
                                <div css="font-size:25px;font-weight:700;display:flex;align-items:center;color:teal;margin-right:35px;@media(max-width:500px){display:none;}">To:</div>
                                <div css="display:flex;align-items:center;@media(max-width:500px){flex-direction:column;align-items:center;}">
                                    <img css="width:150px;height:150px;border-radius:100%;object-fit:cover;object-position:center;margin-right:15px;" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" alt="" />
                                    <div>
                                        <div css="margin:10px 0;">Business's Name</div>
                                        <div css="margin:10px 0;">Business Category</div>
                                        <div css="margin:10px 0;">Product/ services Offered</div>
                                        <div css="margin:10px 0;">Location</div>
                                    </div>
                                </div>
                            </div>
                            <div css="display:flex;align-items:flex-end;">
                                <Button css="margin-top:20px !important;" circular color="teal">Direct Message</Button>
                            </div>
                        </div>
                        <div css="height:2px;background-color:rgb(2,183,178);margin:20px 0;"></div>
                        <h2 css="text-align:center;color:rgb(2,183,178);margin:2rem 0;">Proposal</h2>
                        <div css="display:flex;flex-wrap:wrap;max-width:1000px; margin:0 auto;">
                            <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-right:50px;@media(max-width:768px){width:100%;padding:0;}">
                                <div className="ui form">
                                    <Form.Field>
                                        <label>Business Name</label>
                                        <label>(Business Name and link to their Interpowered Profile)</label>
                                        <Form.Select
                                            fluid
                                            options={[
                                                { key: "option 1", text: "option 1", value: "option 1" },
                                                { key: "option 2", text: "option 2", value: "option 2" },
                                                { key: "option 3", text: "option 3", value: "option 3" }
                                            ]}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Link to website</label>
                                        <input type="text" />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Searching for</label>
                                        <label>Write the job title you are looking to hire for.</label>
                                        <label>Example: Python Developer, UI/UX Designer, etc.</label>
                                        <input type="text" />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Job description / scope of work.</label>
                                        <textarea ></textarea>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Samples of desired work</label>
                                        <div css="display:flex;align-items:center;justify-content:center;">
                                            <div>
                                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file</p>
                                                                    <i className="ui big file icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file</p>
                                                                    <i className="ui big file icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file Add a file Add a file</p>
                                                                    <i className="ui big file icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;">
                                                                <div css="display:flex;flex-direction:column;align-items:center;">
                                                                    <p css="text-align:center;margin:0;margin-bottom:5px;">Add a file</p>
                                                                    <i className="ui big file icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Proposed hourly rate or total payment</label>
                                        <input type="text" />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Requirements</label>
                                        <label>What do you require from the freelancer for succesful completion of this job?</label>
                                        <label>Example: weekly demos, daily timelogs describing work completed, etc.</label>
                                        <textarea ></textarea>
                                    </Form.Field>
                                </div>
                            </div>
                            <div css="flex:0 0 auto;width:50%;margin-bottom:1em;padding-left:50px;@media(max-width:768px){width:100%;padding:0;}">
                                <div className="ui form">
                                    <Form.Field>
                                        <label>Timeline / Schedule</label>
                                        <textarea ></textarea>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Timezone</label>
                                        <label>What timezone are you currently in?</label>
                                        <Form.Select
                                            fluid
                                            options={[
                                                { key: "option 1", text: "option 1", value: "option 1" },
                                                { key: "option 2", text: "option 2", value: "option 2" },
                                                { key: "option 3", text: "option 3", value: "option 3" }
                                            ]}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Requested Availability (Check all that apply)</label>
                                        <div css="display:flex;flex-wrap:wrap;">
                                            <div css="padding:5px 0;width:100%;">
                                                <Checkbox label='Full time' />
                                            </div>
                                            <div css="padding:5px 0;width:100%;">
                                                <Checkbox label='Part time' />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Requested available days (check all that apply)</label>
                                        <label>What days and times are currently available?</label>
                                        <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Monday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Tuesday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Wednesday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Thursday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Friday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Saturday' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Sunday' />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Requested available times (check all that apply)</label>
                                        <label>What days and times are currently available?</label>
                                        <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Early morning' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Morning' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Afternoon' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Evening' />
                                            </div>
                                            <div css="flex:0 0 auto;width:120px;padding:5px 2px;">
                                                <Checkbox label='Night' />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Additional Information</label>
                                        <textarea></textarea>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Digital signature</label>
                                        <input type="text" />
                                    </Form.Field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Proposal