import React from 'react'
import { useTranslation } from 'react-i18next';
import "styled-components/macro"
import { url_environment } from '../../config';
import { getPDFName } from '../freelancer/ProductModalF';
import { getImage, isImage } from './service.page';
const PortfolioTab = (props) => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <div css="display:flex;flex-wrap:wrap;">
                {props.files.map(f => ({ url: f, type: (isImage(f) ? "image" : "pdf") })).map((f) => (
                    <div css="padding:8px;width:33.333%;@media (max-width: 992px) {width:50%} @media (max-width: 768px) {width:100%}">
                        <a css="color:#000; &:hover{color:#000;}" target="_blank" href={`${getImage(f.url)}`} >{/**${url_environment}${f.url} */}
                            {f.type == "image" && <div css="padding-bottom:80%;position:relative;">
                                <img css="border-radius:8px;position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" src={`${getImage(f.url)}`} alt="" />
                            </div>}
                            {f.type == "pdf" && <div css="padding-bottom:80%;position:relative;">
                                <div css="display:flex;align-items:center;justify-content:center;position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid #dadada; border-radius:9px;">
                                    <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(f.url)}</span>
                                </div>
                            </div>}
                        </a>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PortfolioTab