export const getServiceName = (num) => {
    if (num === "01") return "UPS Next Day Air"
    if (num === "02") return "UPS 2nd Day Air"
    if (num === "03") return "UPS Ground"
    if (num === "07") return "UPS Worldwide Express"
    if (num === "08") return "UPS Worldwide Expedited"
    if (num === "11") return "UPS Standard"
    if (num === "12") return "UPS 3 Day Select"
    if (num === "13") return "UPS Next Day Air Saver"
    if (num === "14") return "UPS Next Day Air Early AM"
    if (num === "54") return "UPS Worldwide Express Plus"
    if (num === "59") return "UPS Second Day Air AM"
    if (num === "65") return "UPS Worldwide Saver"
    if (num === "M2") return "UPS First-Class Mail"
    if (num === "M3") return "UPS Priority Mail"
    if (num === "M4") return "UPS Expedited Mail Innovations"
    if (num === "M5") return "UPS Priority Mail Innovations"
    if (num === "M6") return "UPS Economy Mail Innovations"
    return "Unknown"
}