import React, { useEffect, useState } from 'react'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import 'styled-components/macro'
import { useHistory, useLocation } from 'react-router';
import { Dimmer, Item, Loader } from 'semantic-ui-react';
import axios from '../axios';
import { useSelector } from 'react-redux';
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const OrderConfirm = () => {
    
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
      );
    
      return <Editor editorState={editorState} onChange={setEditorState} />;
}

export default OrderConfirm