import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Dimmer, Header, Icon, Loader } from "semantic-ui-react"
import axios from "../axios"
import 'styled-components/macro'
import { useDispatch } from "react-redux"
import { logout } from "../store/user-slice"
import { resetCart } from "../store/cart-slice"

export const error_message = (e) => {
    if (e.response)
        return e.response.data.message
    else
        return e.message
}


const VerifyNewEmail = () => {
    const [t,]=useTranslation("global")
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState("")
    const history=useHistory()
    const query=useQuery()
    const dispatch=useDispatch()
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const load = async () => {
        dispatch(logout()); dispatch(resetCart());
        try {
            const { data } = await axios.post("/api/user/verify-new-email", {
                token: query.get("token")
            })
            if (data === "ok") {
                setMessage(("email_verified"))
            }else{
                setMessage(("email_not_verified"))
            }
        } catch (e) { setMessage(t(error_message(e))) }
        setLoading(false)
    }
    useEffect(() => {
        load()
    }, [])

    if (loading) {
        return <Dimmer inverted active>
            <Loader />
        </Dimmer>
    }

    return (
        <>
            <div css="display:flex;align-items:center;justfy-content:center;height:100vh;">
                <Header as='h2' icon textAlign="center">
                    <Icon color="teal" name={message === "email_verified" ? "check circle outline" : "close"} />
                    {t(message)}
                    {message=="email_verified"&&<div><Button color="teal" circular primary style={{marginTop:"1rem"}} onClick={()=>{history.push("/")}}>{t("home_page")}</Button></div>}
                </Header>
            </div>
        </>
    )
}

export default VerifyNewEmail