import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Dimmer, Dropdown, Form, Icon, Input, Label, Loader, Message, Modal, Popup, Segment, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import ConfirmDelete from '../confirmDelete'
import ConfirmPublish from '../confirmPublish'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import CreateCourseContent from './createCourseContent'
import { url_environment } from '../../config'
import { Link } from 'react-router-dom'
import countries from '../countries'



const CoursesModalEdit = (props) => {
    const [t, i18n] = useTranslation("global")
    const { userInfo } = useSelector(state => state.user)
    const [values, setValues] = useState({
        product_name: "",
        product_description: "",
        price: "",
        instructions: "",
        tags: "",
        images: [],
        taxcode: "",
        location:"",
        languages:[]
    })
    const fileref1 = useRef()
    const fileref2 = useRef()
    const fileref3 = useRef()
    const fileref4 = useRef()
    const fileref5 = useRef()
    const fileref6 = useRef()

    const [ShowConfirmDelete, setShowConfirmDelete] = useState(false)
    const [ShowConfirmPublish, setShowConfirmPublish] = useState(false)
    const [Loading, setLoading] = useState(true)
    const [ProductImage1, setProductImage1] = useState(false)
    const [ProductImage2, setProductImage2] = useState(false)
    const [ProductImage3, setProductImage3] = useState(false)
    const [ProductImage4, setProductImage4] = useState(false)
    const [ProductImage5, setProductImage5] = useState(false)
    const [ProductImage6, setProductImage6] = useState(false)

    const handleInputChange = (e) => {
        setValues(prev => { return { ...prev, [e.target.name]: e.target.value } })
    }

    const pass = () => {
        if (values.product_name == "") return false;
        if (values.product_description == "") return false;
        if (values.instructions == "") return false;
        if (values.tags == "") return false;
        if (values.price == "") return false;
        if (values.taxcode == "") return false;
        if (values.location=="") return false
        if (values.languages.length < 1) return false
        return true
    }
    const pass2 = () => {
        let price = (parseFloat((+values.price) - ((+values.price) * (userInfo.seller.category_rate / 100))) - 1.40)
        if (price <= 0) {
            return false
        }
        return true
    }

    const handleSubmitUp = async () => {
        if (!pass()) { alert("Please fill all required fields"); return }
        if (!pass2()) { alert(t("product.the_final_price")); return }
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append("name", values.product_name)
            formData.append("description", values.product_description)
            formData.append("sale_price", values.price)
            formData.append("instructions", values.instructions)
            formData.append("tags", values.tags)
            formData.append("category_name", "Courses")
            formData.append("category_id", "60a672fa4efcc56a787c7f22")
            formData.append("taxcode", values.taxcode)
            formData.append("location", values.location)
            formData.append("languages", JSON.stringify(values.languages))
            formData.append("file1", ProductImage1 || props.data.images[0]);
            formData.append("file2", ProductImage2 || props.data.images[1]);
            formData.append("file3", ProductImage3 || props.data.images[2]);
            formData.append("file4", ProductImage4 || props.data.images[3]);
            formData.append("file5", ProductImage5 || props.data.images[4]);
            formData.append("file6", ProductImage6 || props.data.images[5]);
            formData.append("id", props.data._id)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.put("/api/product", formData, config)
            setLoading(false)
            props.update()
            props.onClose()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        try {
            if (props.data !== true) {
                setValues(p => ({
                    ...p,
                    product_name: props.data.name,
                    product_description: props.data.description,
                    product_category: props.data.category_name,
                    id_category: props.data.category_id,
                    price: props.data.sale_price,
                    quantity: props.data.stock,
                    instructions: props.data.instructions,
                    shop_policies: props.data.shop_policies,
                    tags: props.data.tags,
                    materials: props.data.materials,
                    width: props.data.width || "0",
                    height: props.data.height || "0",
                    length: props.data.length || "0",
                    weight: props.data.weight || "0",
                    images: props.data.images,
                    countryfrom: "US",
                    countryto: "US",
                    countrytousps: "US",
                    weightdimension: props.data.weight_dimension || "LBS/IN",
                    ounces: "0",
                    date: "",
                    volume_pricing: props.data.volume_pricing,
                    taxcode: props.data.tax_code || "",
                    location:props.data.location || "",
                    languages:props.data.languages || []
                }))
            }


            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }

    }

    const handleCheckboxChange = (name, checked, value) => {
        setValues(prev => {
            let arr = []
            if (checked) {
                return { ...prev, [name]: [...prev[name], value] }
            } else {
                arr = prev[name].filter((i) => { return i !== value });
                return { ...prev, [name]: arr }
            }
        })
    }

    const locations =useMemo(()=>{
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
    },[])

    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.onClose}
                open={true}
                size="large"
            >
                <Modal.Header>{t("profile.edit_course")}</Modal.Header>
                <Modal.Content>
                    {
                        Loading ? <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                            : (<>
                                <p>{t("profile.you_cannot_publish")}<Link to="/wiki?opt=new_course" target="_blank">here</Link>.</p>
                                <div className="ui container" css="justify-content:space-between">
                                    <div className="ui form">
                                        <div className="two fields">
                                            <div className="field">
                                                <p>&nbsp;</p>
                                                <label>{t("profile.name")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <input value={values.product_name} onChange={handleInputChange} type="text" name="product_name" />
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Tags")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <p>{t("words_separated")}</p>
                                                <input value={values.tags} onChange={handleInputChange} type="text" name="tags" />
                                            </div>
                                        </div>
                                        <div className="two fields">
                                            <div className="field">
                                                <label>{t("profile.description")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <textarea value={values.product_description} onChange={handleInputChange} name="product_description"></textarea>
                                            </div>
                                            <div className="field">
                                                <label>{t("profile.Instructions")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <textarea value={values.instructions} onChange={handleInputChange} name="instructions"></textarea>
                                            </div>
                                        </div>
                                        <div className="field" style={{ maxWidth: "500px", margin: "10px auto" }}>
                                            <label>{t("profile.images")}</label>
                                            <input accept="image/jpeg, image/png" ref={fileref1} type="file" hidden onChange={(e) => { setProductImage1(e.target.files[0] || false); props.testv[0] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <input accept="image/jpeg, image/png" ref={fileref2} type="file" hidden onChange={(e) => { setProductImage2(e.target.files[0] || false); props.testv[1] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <input accept="image/jpeg, image/png" ref={fileref3} type="file" hidden onChange={(e) => { setProductImage3(e.target.files[0] || false); props.testv[2] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <input accept="image/jpeg, image/png" ref={fileref4} type="file" hidden onChange={(e) => { setProductImage4(e.target.files[0] || false); props.testv[3] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <input accept="image/jpeg, image/png" ref={fileref5} type="file" hidden onChange={(e) => { setProductImage5(e.target.files[0] || false); props.testv[4] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <input accept="image/jpeg, image/png" ref={fileref6} type="file" hidden onChange={(e) => { setProductImage6(e.target.files[0] || false); props.testv[5] = e.target.files[0] ? (URL.createObjectURL(e.target.files[0])) : "" }} />
                                            <div className="ui three column grid">
                                                <div className="column">
                                                    <div onClick={() => fileref1.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage1 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[0]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[0] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[0].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div onClick={() => fileref2.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage2 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[1]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[1] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[1].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div onClick={() => fileref3.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage3 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[2]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[2] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[2].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div onClick={() => fileref4.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage4 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[3]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[3] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[3].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div onClick={() => fileref5.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage5 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[4]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[4] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[4].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div onClick={() => fileref6.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;">
                                                        <span>{t("profile.Add_a_photo")}</span>
                                                        <i className="huge camera icon"></i>
                                                        {
                                                            ProductImage6 ? (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${props.testv[5]}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            ) : props.data.images && props.data.images[5] && (
                                                                <div css={`position:absolute;top:0;left:0;background:url('${url_environment}/${encodeURIComponent(props.data.images[5].split("/uploads/")[1])}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`}>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="field">
                                            <label>{t("profile.Price")} <span css="font-size:10px;color:#db2828;margin:0 6px;">({t("profile.required")})</span> ({t("profile.Total_with_commission")}: {"$" + (parseFloat((+values.price) - ((+values.price) * (userInfo.seller.category_rate / 100))) - 1.40).toFixed(2)}) <Popup basic content={t("profile.modal_verbatin", { fee: userInfo.seller.category_rate })} trigger={<Icon disabled name='question circle outline' />} /> </label>
                                            <div class="ui left labeled input">
                                                <div class="ui basic label">
                                                    $
                                                </div>
                                                <input value={values.price} onChange={handleInputChange} type="number" name="price" />
                                            </div>
                                            {(parseFloat((+values.price) - ((+values.price) * (userInfo.seller.category_rate / 100))) - 1.40) <= 0 &&
                                                <p style={{ marginTop: "7px", color: "#db2828" }}>{t("profile.price_not_zero")}</p>
                                            }
                                        </div>
                                        <div className="field">
                                                <label>{t("profile.Location")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                                <Dropdown
                                                selectOnBlur={false}
                                                    selection
                                                    fluid
                                                    options={locations}
                                                    defaultValue={values.location}
                                                    onChange={(e, { value }) => { handleInputChange({ target: { name: "location", value } }) }}
                                                />
                                        </div>
                                        <div className="field">
                                            <label>{t("profile.Languages")}<span css="font-size:10px;color:#db2828;margin-left:6px;">({t("profile.required")})</span></label>
                                            <div css="display:flex;align-items:center;margin-top:20px;">
                                                <input checked={values.languages.indexOf("English") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="English" type="checkbox" name="languages" id="english-language-radio" /><label css="margin-left:5px;" htmlFor="english-language-radio">{t("profile.english")}</label>
                                            </div>
                                            <div css="display:flex;align-items:center;">
                                                <input checked={values.languages.indexOf("Spanish") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Spanish" type="checkbox" name="languages" id="spanish-language-radio" /><label css="margin-left:5px;" htmlFor="spanish-language-radio">{t("profile.spanish")}</label>
                                            </div>
                                        </div>
                                        <CreateCourseContent course_id={props.data._id} />
                                        {props.data.first_time && <Message color="green">{t("profile.now_create_content")}</Message>}
                                    </div>
                                </div>

                            </>
                            )
                    }
                    {props.data.reject_reason && <div className="ui form">
                        <div className="ui field">
                            <label htmlFor="">{t("product.reject_reason")}</label>
                            <Segment>
                                {props.data.reject_reason}
                            </Segment>
                        </div>
                    </div>}
                </Modal.Content>
                <Modal.Actions>
                    <div css="display:flex;align-items:center;justify-content:space-between;">
                        <Button circular color="red" onClick={() => setShowConfirmDelete(true)}>
                            {t("profile.Delete")}
                        </Button>
                        <Button css="background:rgb(2,183,178) !important;" circular color="teal" onClick={handleSubmitUp}>
                            {t("profile.Save")}
                        </Button>
                        {(userInfo.paypal_email && userInfo.publish_vendor && props.data !== true) && <Button css="background:rgb(0,128,129) !important;" circular color="teal" onClick={() => { setShowConfirmPublish(true) }}>
                            {t("profile.Publish")}
                        </Button>}
                    </div>

                </Modal.Actions>
                {
                    ShowConfirmDelete && <ConfirmDelete id={props.data._id} update={props.update} onClose={() => { setShowConfirmDelete(false); props.onClose() }} />
                }
                {
                    ShowConfirmPublish && <ConfirmPublish save={handleSubmitUp} update={() => props.update()} id={props.data._id} onClose={() => { setShowConfirmPublish(false); props.onClose() }} />
                }
            </Modal>
        </>
    )
}

export default CoursesModalEdit
