import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import { Button, Checkbox, Dimmer, Icon, Item, Label, Loader, Radio, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import ShippingModal from './shippingModal'
import axios from '../../axios'
import { useHistory } from 'react-router'
import { cartActions } from '../../store/cart-slice'
import { getServiceName } from './utils'
import { parseXML } from '../../Profile/countries';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import env from '../../config.env'
import { url_environment } from '../../config';
const { paypal_client_id } = env

//const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
export const error_message = (e) => {
    if (e.response)
        return e.response.data.message
    else
        return e.message
}
const Cart = () => {
    const [loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const { cartItems } = useSelector(state => state.cart)
    const [client_country, set_client_country] = useState(userInfo?.country)
    const [client_id_country, set_client_id_country] = useState(userInfo?.id_country)
    const [client_address, set_client_address] = useState(userInfo?.address)
    const [client_zip, set_client_zip] = useState(userInfo?.zip)
    const [taxes, setTaxes] = useState({ totalTax: 0, lines: [] })
    const [exclude, setExclude] = useState([])
    const [openShippingModal, setOpenShippingModal] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global")
    const addNumberRef = useRef()

    const handleShippingUpdate = async (o) => {
        set_client_country(o.country)
        set_client_id_country(o.id_country)
        set_client_zip(o.zip)
        set_client_address(o.address)
        console.log(
            o.country,
            o.id_country,
            o.zip
        )
        await loadData(
            o.country,
            o.id_country,
            o.zip,
            o.address
        )
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleAddOneMore = async (e, product_id) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/cart/addFromCart", {
                product_id
            }, config)
            loadData()
        } catch (e) {
            alert(e)
        }
    }
    const handleAddMore = async (e, product_id, addNumber) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/cart/addFromCartMore", {
                product_id,
                numberc: addNumber
            }, config)
            loadData()
        } catch (e) { alert(t("cart.only_in_stock", { qty: error_message(e) })); setLoading(false); }
    }

    const loadData = async (cc, cid, cz, ca) => {
        try {
            setLoading(true)

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            dispatch(cartActions.resetCart())
            const { data: cartData } = await axios.get("/api/user/cart", config)
            for (let i = 0; i < cartData.cart.length; i++) {
                for (let j = 0; j < cartData.cart[i].quantity; j++) {
                    dispatch(cartActions.addItem(cartData.cart[i].product))
                }
            }

            const { data: stockResult } = await axios.get("/api/user/cart/stock", config)
            if (Array.isArray(stockResult)) {
                dispatch(cartActions.setOutOfStock(stockResult))
            }
            const cartItems = JSON.parse(localStorage.getItem("cartItems"))
            const client_countryx = cc || client_country
            const client_id_countryx = cid || client_id_country
            const client_zipx = cz || client_zip
            const avarr = []
            for (let i = 0; i < cartItems.length; i++) {
                const x = cartItems[i]
                const o = {
                    width: x.width,
                    height: x.height,
                    length: x.length,
                    weight: x.weight,
                    price: x.sale_price - x.discount,
                    shipper: x.shipper,
                    weight_dimension: x.weight_dimension,
                    quantity: x.cartQuantity,
                    dhl: x.dhl,
                    other: x.other,
                    vendor_country: x.vendor_id_2.country,
                    vendor_id_country: x.vendor_id_2.id_country,
                    vendor_address: x.vendor_id_2.address,
                    vendor_city: x.vendor_id_2.city,
                    vendor_zip: x.vendor_id_2.zip,
                    client_country: client_countryx,
                    client_id_country: client_id_countryx,
                    client_zip: client_zipx,
                }
                let r
                if (x.outOfStock) {
                    r = undefined
                } else {
                    r = await getShippingOptions(o)
                }
                if (x.outOfStock || (typeof r === "string")) {
                    avarr.push(x._id)
                }
                console.log("shipping options:", r)
                dispatch(cartActions.postShippingOptions({ index: i, options: r }))
                // dispatch(cartActions.postTaxes({ index: i, tax: "" }))
            }
            setExclude(avarr)
            const { data: taxes } = await axios.post(`/api/product/taxes`, {
                exclude: avarr,
                co: cid || client_id_country,
                ad: ca || client_address,
                zip: cz || client_zip
            }, config)//?co=${}&ad=${}&zip=${}`,config)
            avarr.forEach(i => {
                const index = cartItems.findIndex(item => item._id == i)
                taxes.lines.splice(index, 0, 0.0);
            })
            setTaxes({ totalTax: taxes.totalTax.toFixed(2), lines: taxes.lines.map(i => i.toFixed(2)) })
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }


    const getShippingOptions = async (obj) => {
        try {
            let resp;
            if (obj.shipper === "ups") {
                let res;
                try {
                    res = await axios.post("https://interpowered.com:3001/calculator", {
                        "mvalue": obj.price.toString(),
                        "addressto": "",
                        "addressfrom": obj.vendor_address,
                        "cityfrom": obj.vendor_city,
                        "dimensionMeasurement": obj.weight_dimension.split("/")[1],
                        "weightMeasurement": obj.weight_dimension.split("/")[0],
                        "zipfrom": obj.vendor_zip,
                        "zipto": obj.client_zip,
                        "countryfrom": obj.vendor_id_country,
                        "countryto": obj.client_id_country,
                        "width": obj.width.toString(),
                        "height": obj.height.toString(),
                        "length": obj.length.toString(),
                        "weight": obj.weight.toString()
                    })
                    res = res.data
                    let resArr = Array.isArray(res.RateResponse.RatedShipment) ? res.RateResponse.RatedShipment.map((i) => { return { code: i.Service.Code, name: getServiceName(i.Service.Code), total: `${i.TotalCharges.CurrencyCode} ${i.TotalCharges.MonetaryValue}` } }) : [{ code: res.RateResponse.RatedShipment.Service.Code, name: getServiceName(res.RateResponse.RatedShipment.Service.Code), total: `${res.RateResponse.RatedShipment.TotalCharges.CurrencyCode} ${res.RateResponse.RatedShipment.TotalCharges.MonetaryValue}` }]
                    resArr = resArr.map((x, c) => {
                        return { code: c.code, key: c, text: `${x.name} ${x.total}`, value: x.total.split("USD ")[1] }
                        // return { key: c, text: `${x.name} ${x.total}`, value: {amount:x.total.split("USD ")[1],code:} }
                    })
                    resp = [...resArr]
                } catch (e) {
                    if (typeof e.response.data === "string") {
                        resp = (e.response.data)
                    } else {
                        resp = (e.response.data[0].message)
                    }
                }
                //console.log(`number: ${i}: `, resArr)
            } else if (obj.shipper === "usps") {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                try {
                    const res = await axios.post("/api/calculator/usps", {
                        "price": obj.price,
                        "zipfrom": obj.vendor_zip,
                        "zipto": obj.client_zip,
                        "width": obj.width,
                        "height": obj.height,
                        "length": obj.length,
                        "pounds": obj.weight,
                        "ounces": "0",
                        "countrytousps": obj.client_country,
                    }, config)
                    let [error, resArr] = parseXML(obj.client_country, res)
                    if (error) {
                        //resp = error
                        return error
                    }
                    resArr = resArr.map((x, c) => {
                        return { code: c.code, key: c, text: `${x.name} ${x.total}`, value: x.total.split("USD ")[1] }
                    })
                    resp = [...resArr]
                } catch (e) { alert(e) }


                //console.log(`number: ${i}: `, res)
            } else if (obj.shipper === "dhl") {
                const exist = obj.dhl.find(x => {
                    return obj.client_id_country == x.country
                })
                if (exist) {
                    //sdebugger
                    if (obj.quantity > 1) {
                        const q = obj.quantity - 1;
                        let total = parseFloat(exist.one_item);
                        total += (parseFloat(exist.additional_item) * q)
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: "DHL", value: total }]//`DHL $${total}`
                    } else {
                        let total = parseFloat(exist.one_item);
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: "DHL", value: total }]//`DHL $${total}`
                    }
                } else {
                    resp = `DHL: ${t("cart.unsupported_country")}`
                }
            } else if (obj.shipper === "other") {
                const exist = obj.other.find(x => {
                    return obj.client_id_country == x.country
                })
                if (exist) {
                    //sdebugger
                    if (obj.quantity > 1) {
                        const q = obj.quantity - 1;
                        let total = parseFloat(exist.one_item);
                        total += (parseFloat(exist.additional_item) * q)
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: exist.shipping_company, value: total }]//`DHL $${total}`
                    } else {
                        let total = parseFloat(exist.one_item);
                        total = total.toFixed(2)
                        resp = [{ key: "1", text: exist.shipping_company, value: total }]//`DHL $${total}`
                    }
                } else {
                    resp = `Other: ${t("cart.unsupported_country")}`
                }
            }
            else if (obj.shipper === "notapply") {
                resp = [{ key: "1", text: "", value: "00.00" }]
            }
            return resp


        } catch (e) {
            alert(e)
        }
    }
    const handleShippingClick = () => { setOpenShippingModal(true) }

    const handleShippingOptionChange = (c, value, selection_index) => {
        dispatch(cartActions.updateSelectedOption({ index: c, value, selection_index }))
    }
    console.log(cartItems)
    const subTotalPrice = parseFloat(cartItems.reduce((acc, curr) => { return acc + ((curr.outOfStock || curr.shippingOptionsValue === -1) ? 0 : (curr.sale_price * curr.cartQuantity)) }, 0)).toFixed(2)
    // const subTotalPriceDiscount = parseFloat(cartItems.reduce((acc, curr) => { return acc + ((curr.outOfStock || curr.shippingOptionsValue === -1) ? 0 : ((curr.sale_price - curr.discount) * curr.cartQuantity)) }, 0)).toFixed(2)
    const totalShipping = cartItems.filter(x => (!x.outOfStock && x.shippingOptionsValue > -1)).reduce((a, x) => { return a + ((x.shipper === "dhl" || x.shipper === "other") ? parseFloat(x.shippingOptionsValue) : (x.shippingOptionsValue * parseInt(x.cartQuantity))) }, 0).toFixed(2)
    const discount = parseFloat(cartItems.reduce((acc, curr) => { return acc + ((curr.outOfStock || curr.shippingOptionsValue === -1) ? 0 : (curr.discount * curr.cartQuantity)) }, 0)).toFixed(2)
    console.log("total shipping: ", totalShipping)
    //const totalShipping = parseFloat(cartItems.reduce((acc, curr) => { return acc + ((curr.outOfStock || curr.shippingOptionsValue === -1) ? 0 : (curr.shipper=="dhl" ? (curr.shippingOptionsValue) : (parseFloat(curr.shippingOptionsValue) * parseFloat(curr.cartQuantity)).toFixed(2))) }, 0)).toFixed(2)
    const totalPrice = (parseFloat(subTotalPrice) + parseFloat(totalShipping)/*+parseFloat(taxes.totalTax)*/).toFixed(2)
    // const totalPriceDiscount = (parseFloat(subTotalPriceDiscount) + parseFloat(totalShipping)/*+parseFloat(taxes.totalTax)*/).toFixed(2)
    const totalItems = cartItems.reduce((a, x) => { return a + ((x.outOfStock || x.shippingOptionsValue === -1) ? 0 : x.cartQuantity) }, 0)
    // const finalPrice = (parseFloat(totalPrice) + parseFloat(taxes.totalTax) - parseFloat(discount)).toFixed(2)
    // const finalPrice = (parseFloat(totalPriceDiscount) + parseFloat(taxes.totalTax)).toFixed(2)
    const finalPrice2 = cartItems.filter((curr) => !(curr.outOfStock || curr.shippingOptionsValue === -1)).reduce((acc, item) => {
        let sum = 0;
        sum += parseFloat(item.sale_price)
        sum -= parseFloat(item.discount)
        let ship = ((item.shipper === "dhl" || item.shipper === "other") ? parseFloat(item.shippingOptionsValue / item.cartQuantity) : parseFloat(item.shippingOptionsValue))
        sum += ship
        sum = sum.toFixed(2)
        sum *= parseInt(item.cartQuantity)
        return acc + sum
        // acc+(
        //     (
        //         item.sale_price-item.discount+
        //         (item.shipper === "dhl" ? parseFloat(item.shippingOptionsValue/item.cartQuantity) : (item.shippingOptionsValue)))*item.cartQuantity)
    }, 0)
    const finalPrice = (finalPrice2 + parseFloat(taxes.totalTax)).toFixed(2)
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Navbar />
            <div>

                <main css="max-width:1400px;margin:0 auto;padding:0 36px;display:flex;align-items:start;flex-wrap:wrap;">
                    <div css="flex:0 0 auto;width:70%;padding:10px;@media(max-width:768px){width:100%;}">
                        <Item.Group divided>
                            {cartItems.length === 0 && <div css="display:flex;align-items:center;justify-content:center;margin-top:4rem;font-size:25px;font-weight:700;">Your cart is empty</div>}
                            {cartItems.map((x, c) => {
                                const price = parseFloat(((x.sale_price) * x.cartQuantity) /*+ (x.shipper == "dhl" ? (parseFloat(x.shippingOptionsValue)) : (parseFloat(x.shippingOptionsValue) * x.cartQuantity))*/)//.toFixed(2) 
                                return (
                                    <>
                                        <Item key={x._id}>
                                            <Item.Image as={Link} to={`/product/${x.id_product}`} src={url_environment + x.images[0]} />

                                            <Item.Content>
                                                <Item.Header css="width:100%;">
                                                    <div css="display:flex;justify-content:space-between;">
                                                        <span>
                                                            <Link to={`/product/${x.id_product}`}>{x.name}</Link>
                                                        </span>
                                                        <span>
                                                            {x.outOfStock && "Out of stock"}
                                                            {(!x.outOfStock && x.shippingOptionsValue > -1) && `USD $${price.toFixed(2)}`}
                                                            {x.shippingOptionsValue === -1 && "USD $00.00"}
                                                        </span>
                                                    </div>
                                                </Item.Header>
                                                <Item.Meta>
                                                    <div css="margin:10px 0;" className='cinema'>{t("cart.quantity")}: {x.cartQuantity}</div>
                                                    {!x.outOfStock && <div css="margin:10px 0;" className='cinema'>
                                                        {t("cart.shipping")}: {(Array.isArray(x.shippingOptions) && x.shippingOptions.length > 1) ? <Select onChange={(e, a) => { handleShippingOptionChange(c, a.value, a.options.findIndex(opt => opt.value == a.value)) }} defaultValue={x.shippingOptionsValue} options={x.shippingOptions} /> : ((Array.isArray(x.shippingOptions) && x.shippingOptions.length == 1) ? x.shippingOptions.map(x => { return `${x.text} USD $${x.value}` }).join("") : x.shippingOptions)}
                                                    </div>}
                                                    <div css="margin:10px 0;" className='cinema'>{t("cart.taxes")} :USD ${taxes.lines[c] || "00.00"}</div>
                                                    <div css="margin:10px 0;" className='cinema'>{t("cart.discount")} :USD ${parseFloat(x.discount).toFixed(2) || "00.00"}</div>
                                                    <div css="margin:10px 0;" className='cinema'>{t("cart.unit_price")} :USD ${x.sale_price || "00.00"}</div>
                                                </Item.Meta>
                                                <Item.Description>
                                                    <div css="margin:10px 0;" className='cinema'>{t("cart.description")} :{x.description}</div>
                                                    {x.stock == x.cartQuantity && <Label color="red">{t("cart.only_in_stock", { qty: x.stock })}</Label>}
                                                </Item.Description>
                                                <Item.Extra>
                                                    <div css="display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap;">
                                                        {
                                                            (x.category_id != "60b5264c40b9d41dae531f96" && x.category_id != "60a672fa4efcc56a787c7f1e" && x.category_id != "6182f72014e83501939ba8ca" && x.category_id != "60a672fa4efcc56a787c7f22")
                                                            && <NumberForm defaultValue={x.cartQuantity} product_id={x.id_product} onSubmit={handleAddMore} />}
                                                        {/* &&<button css="background:white;border:0;color:#4183c4;font-weight:bold;&:hover{cursor:pointer;color:#1e70bf;}" onClick={(e) => handleAddOneMore(e, x.id_product)}>{t("cart.add_one_more")}</button>} */}
                                                        <CustomDeleteButton loadData={loadData} datax={{ exclude, setTaxes, client_country, client_id_country, client_zip, client_address, getShippingOptions }} id={x._id} />
                                                    </div>

                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>
                                    </>
                                )
                            })}


                        </Item.Group>
                    </div>
                    <div css="flex:0 0 auto;width:30%;padding:10px;@media(max-width:768px){width:100%;}">
                        <div className="ui form">
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <span>{t("cart.items_total")}</span>
                                    <span>USD ${subTotalPrice}</span>
                                </label>
                            </div>
                            {/* <div class="field">
                            <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                <span>Shop discount</span>
                                <span>USD $00.00</span>
                            </label>
                        </div> */}
                            <hr />
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <span>Subtotal</span>
                                    <span>USD ${subTotalPrice}</span>
                                </label>
                            </div>
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <span>{t("cart.taxes")}</span>
                                    <span>USD ${taxes.totalTax}</span>
                                </label>
                            </div>
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <span>{t("cart.discount")}</span>
                                    <span>USD ${discount}</span>
                                </label>
                            </div>
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <div>
                                        <Button css="padding:8px 16px !important;" circular color="teal" onClick={handleShippingClick}>{t("cart.shipping")}</Button>
                                        <div css="font-size:12px;">({t("cart.click_select_shipping_address")})</div>
                                    </div>
                                    <span>USD ${totalShipping}</span>
                                    {/*<span>USD {parseFloat(shippingPrice).toFixed(2)}</span>*/}
                                </label>
                            </div>
                            <hr />
                            <div class="field">
                                <label css="display:flex !important;align-items:center !important;justify-content:space-between !important;">
                                    <span>Total ({totalItems} items)</span>
                                    <span>USD ${finalPrice}</span>
                                </label>
                            </div>


                            {parseFloat(finalPrice) > 0 && <PayPalButton
                                // style={display:}
                                onInit={(data, actions) => { if (cartItems.filter(x => !x.outOfStock).length < 1) { actions.disable() } }}
                                createOrder={async (data, actions) => {

                                    const purchaseItems = cartItems.map((x, c) => {
                                        let unit_amount_value = ((parseFloat(x.sale_price) - parseFloat(x.discount)) + ((x.shipper == "dhl" || x.shipper == "other") ? (parseFloat(x.shippingOptionsValue) / parseInt(x.cartQuantity)) : (parseFloat(x.shippingOptionsValue))) + parseFloat(taxes.lines[c] / x.cartQuantity)).toFixed(2)
                                        if (x.outOfStock || x.shippingOptionsValue === -1)
                                            return false
                                        else
                                            return {
                                                name: x._id,
                                                unit_amount: {
                                                    currency_code: "USD",
                                                    value: unit_amount_value,
                                                }, //x.sale_price,
                                                /*tax: {
                                                    currency_code: "USD",
                                                    value: parseFloat(taxes.lines[c] / x.cartQuantity).toFixed(2),
                                                }, //x.sale_price,*/
                                                quantity: x.cartQuantity
                                            }
                                    }).filter(x => x)
                                    console.log("finalPrice", finalPrice)
                                    console.log("finalPrice2", finalPrice2)
                                    console.log("final price 2", (parseFloat(totalPrice) - parseFloat(discount)).toFixed(2))
                                    const config = {
                                        headers: {
                                            Authorization: `Bearer ${userInfo.token}`,
                                        },
                                    }
                                    const { data: oid } = await axios.post("/api/paypal/get_order", {
                                        exclude,
                                        cio: client_id_country,
                                        co: client_country,
                                        ad: client_address,
                                        zip: client_zip,
                                        shipping_indexes: cartItems.filter((curr) => !(curr.outOfStock || curr.shippingOptionsValue === -1)).map(x => ({ id: x._id, index: x.shippingOptionsValueIndex }))
                                    }, config)
                                    if (oid === "reload") {
                                        await new Promise(() => window.location.reload())
                                    }
                                    return oid
                                }}
                                onApprove={async (data, actions) => {
                                    try {
                                        const config = {
                                            headers: {
                                                Authorization: `Bearer ${userInfo.token}`,
                                            },
                                        }
                                        const { data: { order, details } } = await axios.post("/api/paypal", {
                                            orderID: data.orderID,
                                        }, config)
                                        await axios.get("/api/user/cart/reset", config)
                                        dispatch(cartActions.resetCart())
                                        history.push({
                                            pathname: '/order-details',
                                            state: {
                                                details,
                                                data,
                                                items: order.order_items,
                                                order_taxes: order.order_taxes,
                                                discounts: cartItems.map(item => ({ id: item._id, discount: item.discount }))
                                                //purchaseItems:purchaseItems
                                            }
                                        })
                                        return order
                                    } catch (e) {
                                        console.log(e)
                                        alert("There was an error, please contact the administrator. PAYPAL_ORDER_ID:" + data.orderID)
                                    }
                                }}
                                // onApprove={(data,actions)=>{
                                //     const cap=actions.order.capture()
                                //     console.log(cap)
                                //     return 12
                                // }}
                                //onSuccess={()=>alert("")}
                                onError={(e) => alert(e)}
                                onClick={(data, actions) => {
                                    try {
                                        return actions.resolve()
                                    } catch (e) {
                                        alert(e);
                                        return actions.reject();
                                    }

                                }}
                                options={{
                                    clientId: paypal_client_id
                                }}
                            />}
                        </div>
                    </div>
                </main>
            </div>

            <Footer />
            <ShippingModal data={{
                client_country,
                client_id_country,
                client_address,
                client_zip
            }} update={handleShippingUpdate} setClose={() => setOpenShippingModal(false)} open={openShippingModal} />
        </>
    )
}

export default Cart

const CustomDeleteButton = (props) => {
    const { userInfo } = useSelector(state => state.user)
    const { cartItems } = useSelector(state => state.cart)
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const handleDeleteItem = async (e) => {
        try {
            const client_countryx = props.datax.client_country
            const client_id_countryx = props.datax.client_id_country
            const client_zipx = props.datax.client_zip
            const getShippingOptions = props.datax.getShippingOptions
            e.target.disabled = true
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: resp } = await axios.post("/api/user/cart/delete", {
                //user_id: userInfo.id,
                product_id: props.id
            }, config)
            props.loadData()
            // const { data: taxes } = await axios.get(`/api/product/taxes?co=${props.datax.client_id_country}&ad=${props.datax.client_address}&zip=${props.datax.client_zip}`, config)
            // props.datax.setTaxes({ totalTax: taxes.totalTax.toFixed(2), lines: taxes.lines.map(i => i.toFixed(2)) })
            // const { data: taxes } = await axios.post(`/api/product/taxes`, {
            //     co: props.datax.client_id_country,
            //     ad: props.datax.client_address,
            //     zip: props.datax.client_zip,
            //     exclude: props.datax.exclude
            // }, config)
            // props.datax.setTaxes({ totalTax: taxes.totalTax.toFixed(2), lines: taxes.lines.map(i => i.toFixed(2)) })
            // dispatch(cartActions.deleteFromCart(props.id))
            // const x = cartItems.find(x => { return x._id == props.id })
            // const i = cartItems.findIndex(x => { return x._id == props.id })
            // const o = {
            //     width: x.width,
            //     height: x.height,
            //     length: x.length,
            //     weight: x.weight,
            //     price: x.sale_price,
            //     shipper: x.shipper,
            //     weight_dimension: x.weight_dimension,
            //     quantity: x.cartQuantity - 1,
            //     dhl: x.dhl,
            //     vendor_country: x.vendor_id_2.country,
            //     vendor_id_country: x.vendor_id_2.id_country,
            //     vendor_address: x.vendor_id_2.address,
            //     vendor_city: x.vendor_id_2.city,
            //     vendor_zip: x.vendor_id_2.zip,
            //     client_country: client_countryx,
            //     client_id_country: client_id_countryx,
            //     client_zip: client_zipx,
            // }
            // //debugger
            // if (o.quantity == 0)
            //     return
            // let r
            // if (x.outOfStock) {
            //     r = undefined
            // } else {
            //     r = await getShippingOptions(o)
            // }
            // dispatch(cartActions.postShippingOptions({ index: i, options: r }))
            // e.target.disabled = false
            // setLoading(false)
        } catch (e) {
            alert(e)
        }
    }

    return (
        <>
            <Button css="padding:8px 16px !important;" circular loading={loading} onClick={handleDeleteItem} color="red">
                {t("cart.delete")}
            </Button>
        </>
    )
}

const NumberForm = (props) => {
    const [t,] = useTranslation("global")
    const [state, setState] = useState(props.defaultValue)
    return <>
        <div className="ui form">
            <input name="numberc" type="number" value={state} onChange={(e) => { setState(e.target.value) }} />
            <button onClick={(e) => { props.onSubmit(e, props.product_id, state) }} css="background:white;border:0;color:#4183c4;font-weight:bold;&:hover{cursor:pointer;color:#1e70bf;}" >{t("cart.add")}</button>
        </div>
    </>
}