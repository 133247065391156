

    export const astyles=`
    background-image: -webkit-linear-gradient(#0070BA 20%, #0070BA) !important;
    background-image: linear-gradient(#0070BA 20%, #0070BA) !important;
    background-repeat: no-repeat !important;
    border-color: #0070BA;
    margin: 0;
    position: relative;
    min-width: 4rem; 
    white-space: nowrap;
    line-height: 1.5;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    color:#fff;
    display:flex;
    align-items:center;
    padding:10px 20px;
    text-decoration:none;
    font-size:20px;
    &:hover{
        color:#fff;
    }
    `



    export const payDivStyles={
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        height:"100vh"
    }