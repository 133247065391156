import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Form } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { login } from '../../store/user-slice'
import countries from '../countries'
import { formData } from '../formDataCreator'

const RegisterAsBusiness = () => {
    const [imageName, setImageName] = useState(undefined)
    const [t, i18n] = useTranslation("global")
    const [formLoading, setFormLoading] = useState(false)
    const [older, setOlder] = useState(false)
    const [TP, setTP] = useState(false)
    const { registerInfo } = useSelector(state => state.user)
    const [categories, setCategories] = useState([])
    const [emailMessage, setEmailMessage] = useState(false)
    const [values, setValues] = useState({
        id_country: "US",
        country: "United States",
        city: "",
        address: "",
        zip: "",
        phone: "",
        freelancer_name: "",
        website: "",
        image: "",
        business_address: "",
        description: "",
        service_offered: "",
        id_product_category: "",
        product_category: "",
        selected_category: ""
    })
    const dispatch = useDispatch()
    const handleSelectChange = (e, { value }) => {
        setValues(p => {
            return {
                ...p,
                id_country: value,
                country: countries.find(x => x.value === value).text
            }
        })
    }
    const handleChange = (e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }
    const handleCategoryChange = (e, { value }) => {
        setValues(p => {
            return {
                ...p,
                id_product_category: value._id,
                product_category: value.name,
                selected_category: value
            }
        })
    }
    const loadData = async () => {
        setFormLoading(true)
        try {
            const { data } = await axios.get("/api/category/business")
            setCategories(data)
            setValues(p => {
                return {
                    ...p,
                    selected_category: data[0],
                    id_product_category: data[0]._id,
                    product_category: data[0].name
                }
            })
        } catch (e) { alert(e) }
        setFormLoading(false)
    }
    const handleSubmit = async () => {
        setFormLoading(true)
        const temp = { ...values, ...registerInfo }
        temp.id_language = i18n.language
        temp.image = imageName
        if (!temp.image) delete temp.image
        delete temp.selected_category
        try {
            await axios.post("/api/business/register", formData(temp))
            setEmailMessage(true)
            // dispatch(login(registerInfo.email, registerInfo.password, ""));
        } catch (e) { alert(e) }
        setFormLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])

    if (emailMessage) {
        return <>
            <h3>{t("email_verification_sent_1")}</h3>
            <p>{t("email_verification_sent_2")}<b>{registerInfo.email}</b></p>
            <p>{t("email_verification_sent_3")}</p>
        </>
    }


    return (
        <>
            <Form loading={formLoading} onSubmit={handleSubmit}>
                <Form.Select
                    search
                    fluid
                    label={t('navbar.country')}
                    options={countries}
                    onChange={handleSelectChange}
                    defaultValue={values.id_country}
                    selectOnBlur={false}
                />
                <Form.Input
                    required onChange={handleChange} name="city" fluid label={t('navbar.city')} type="text" />
                {/* <Form.Input
                    required onChange={handleChange} name="address" fluid label={t('navbar.address')} type="text" /> */}
                    
                <div className="ui required field">
                    <label htmlFor="">{t('navbar.address')}</label>
                    <input required onChange={handleChange} name="address" type="text" />
                    <p >{t("navbar.please_enter_address")}</p>
                </div>
                <div className="ui required field">
                    <label htmlFor="">{t('navbar.zip')}</label>
                    <input required onChange={handleChange} name="zip" type="text" />
                    <p >{t("navbar.please_enter_zip")}</p>
                </div>
                {/* <Form.Input
                    required onChange={handleChange} name="zip" fluid label={t('navbar.zip')} type="text" /> */}
                <Form.Input onChange={handleChange} name="phone" required fluid label={t('navbar.phone')} type="text" />
                <Form.Input
                    required onChange={handleChange} name="business_name" fluid label={t('navbar.bus_name')} type="text" />
                <Form.Input
                    onChange={handleChange} name="website" fluid label={t('navbar.website')} type="text" />
                <label style={{ marginBottom: "20px" }} class="ui labeled icon button" htmlFor="custom_button_upload_image">
                    <i class="image icon"></i>
                    <input
                        id="custom_button_upload_image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => { e.target.files[0] && setImageName(e.target.files[0]) }}
                        css="display:none;"
                    />
                    {t('navbar.bus_image')}{imageName && ":"} {imageName && imageName.name}
                </label>
                <Form.TextArea
                    required fluid onChange={handleChange} name="description" label={t('navbar.description')} type="text" />
                <Form.Input
                    fluid onChange={handleChange} name="business_address" label={t('navbar.bus_address')} type="text" />
                <div className='required field'>
                    <label>{t('navbar.product_off')}</label>
                    <p>{t("separate_products_commas")}</p>
                    <Form.TextArea
                        required fluid onChange={handleChange} name="service_offered"  type="text" />
                </div>
                <Form.Select
                    fluid
                    label={t('navbar.product_cat')}
                    value={values.selected_category}
                    options={categories.map((i, c) => { return { text: i.name, value: { ...i }, key: i._id } })}
                    onChange={handleCategoryChange}
                    selectOnBlur={false}
                />
                <div className="ui field">
                    <Checkbox
                        label={t("navbar.users_older_account")}
                        onChange={(e, { checked }) => { setOlder(checked) }}
                    />
                </div>
                <div className="ui field">
                    {i18n.language === "en" && <>
                        <Checkbox
                            label={<label>I accept the <a target="_blank" href="/terms-of-service">terms and conditions</a> and the <a target="_blank" href="/privacy-policy">privacy policy</a></label>}
                            onChange={(e, { checked }) => { setTP(checked) }}
                        />
                    </>}
                    {i18n.language === "es" && <>
                        <Checkbox
                            label={<label>Acepto los <a target="_blank" href="/terms-of-service">términos y condiciones</a> y la <a target="_blank" href="/privacy-policy">política de privacidad</a></label>}
                            onChange={(e, { checked }) => { setTP(checked) }}
                        />
                    </>}
                </div>
                <Form.Button disabled={!older || !TP} style={{ borderRadius: "200px", background: "rgb(0,128,129)" }} fluid type="submit" secondary content={t('navbar.continue')} />
            </Form>
        </>
    )
}

export default RegisterAsBusiness