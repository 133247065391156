import 'styled-components/macro'

const PrivacyPolicySpanish = () => {
    return <>
        <div css="font-size:16px;">
            <div>
                <p className="c12 c8"><span className="c6"></span></p>
            </div><a id="id.gjdgxs"></a>
            <h1 css="text-align:center; font-weight:bold;"><p className="c9"><span className="c13">www.interpowered.com Política de Privacidad</span></p></h1>
            <p className="c2"><span className="c0">Última modificación: 26/11/2021</span></p><a id="id.30j0zll"></a>
            <p className="c2"><span css="font-weight:bold;text-decoration:underline;" className="c10">Introducción</span></p>
            <p className="c2"><span className="c0">Interpowered, LLC</span><span css="font-weight:bold;" className="c4">&nbsp;("Compañía”
            o</span><span className="c0">&nbsp;</span><span css="font-weight:bold;" className="c4">“Nosotros") </span><span className="c0">respeta su privacidad y se compromete a protegerla a través de nuestro cumplimiento de
            esta política.</span></p>
            <p className="c2"><span className="c0">Esta política describe los tipos de información que podemos recopilar
            de usted o que puede proporcionar cuando visita el Sitio Web Interpowered.com (nuestro “Sitio
            web") y nuestras prácticas para recopilar, usar, mantener, proteger y divulgar esa
            información. </span></p>
            <p className="c2"><span className="c0">Esta política se aplica a la información que recopilamos: &nbsp;</span>
            </p>
            <p className="c2 c8"><span className="c0"></span></p>
            <p className="c2 c8"><span className="c0"></span></p>
            <ul className="c5 lst-kix_list_19-0 start">
                <li className="c3 li-bullet-0"><span className="c0">En este Sitio Web.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">En correo electrónico, texto y otros mensajes
                electrónicos entre usted y este Sitio Web.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Cuando interactúa con nuestra publicidad y aplicaciones en
                Sitios Web y servicios de terceros, si esas aplicaciones o publicidad incluyen enlaces a esta
                política.</span></li>
            </ul>
            <p className="c2"><span className="c0">No se aplica a la información recopilada por:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Nosotros fuera de línea o a través de cualquier otro
                medio, incluso en cualquier otro Sitio Web operado por la Compañía o cualquier tercero
                (incluidas nuestras filiales y subsidiarias); o</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Cualquier tercero (incluidos nuestros afiliados y subsidiarias),
                incluso a través de cualquier aplicación o contenido (incluida la publicidad) que pueda
                vincularse o ser accesible desde o en el Sitio Web.</span></li>
            </ul>
            <p className="c2"><span className="c0">Lea esta política detenidamente para comprender nuestras políticas y
            prácticas con respecto a su información y cómo la trataremos. &nbsp; Si no está
            de acuerdo con nuestras políticas y prácticas, su elección es no utilizar nuestro sitio
            web. Al acceder o utilizar este Sitio Web, usted acepta esta política de privacidad. Esta
            política puede cambiar de vez en cuando (consulte &nbsp;</span><span className="c0"><a className="c1" href="#id.3rdcrjn">Cambios a nuestra Política de privacidad).</a></span><span className="c0">&nbsp;Su
                uso continuado de este Sitio Web después de que realicemos cambios se considera una aceptación
                de dichos cambios, por lo que debe consultar la política periódicamente para obtener
            actualizaciones.</span></p><a id="id.1fob9te"></a>
            <p className="c2"><span css="font-weight:bold;text-decoration:underline;" className="c10">Niños Menores de 18 años</span></p>
            <p className="c2"><span className="c0">Nuestro Sitio Web no está destinado a niños menores de los 18
            años. Nadie menor de 18 años puede proporcionar información personal a o en el sitio
            web. No recopilamos a sabiendas información personal de niños menores de 18 años. Si
            usted es menor de 1a 18 años, no use ni proporcione ninguna información en este Sitio Web ni
            se registre en el Sitio Web, realice compras a través del Sitio Web ni nos proporcione ninguna
            información sobre usted, incluido su nombre, dirección, número de teléfono,
            dirección de correo electrónico o cualquier nombre de pantalla o nombre de usuario que pueda
            usar. Si nos enteramos &nbsp;de &nbsp;que hemos recopilado o recibido información personal de un
            niño menor de 18 años &nbsp;sin la verificación del consentimiento de los padres,
            &nbsp; eliminaremos esa información. Si cree que podemos tener alguna información de o sobre
            un niño menor de 18 años, contáctenos en </span><span css="background-color:#c0c0c0;" className="c0 c15">legal@Interpowered.com</span><span className="c0">.</span></p><a id="id.3znysh7"></a>
            <p className="c2 c8"><span className="c10"></span></p>
            <p className="c2"><span css="font-weight:bold;text-decoration:underline;" className="c10">Información que Recopilamos Sobre Usted y Cómo la Recopilamos
        </span><span className="c4">&nbsp;</span></p>
            <p className="c2"><span className="c0">Recopilamos varios tipos de información de y sobre los usuarios de nuestro
            sitio web, incluida la información:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Por el cual usted puede ser identificado personalmente, como nombre,
                dirección postal, dirección de correo electrónico, número de
                teléfono, ("</span><span css="font-weight:bold;" className="c4">información personal</span><span className="c0">");</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Eso es sobre usted, pero individualmente no lo identifica, como
                nombres de tiendas, artículos a la venta o reseñas publicadas en el sitio con respecto a
                artículos comprados anteriormente o consultas sobre artículos actualmente a la venta;
                y/o</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Acerca de su conexión a Internet, el equipo que utiliza para
                acceder a nuestro sitio web y los detalles de uso.</span></li>
            </ul>
            <p className="c2"><span className="c0">Recopilamos esta información:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Directamente de usted cuando nos lo proporciona.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Automáticamente a medida que navega por el sitio. La
                información recopilada automáticamente puede incluir detalles de uso, direcciones IP e
                información recopilada a través de cookies.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">De terceros, por ejemplo, nuestros socios comerciales.</span></li>
            </ul><a id="id.2et92p0"></a>
            <p className="c2"><span css="font-style:italic;" className="c11">Información Que Usted Nos Proporciona</span></p>
            <p className="c2"><span className="c0">La información que recopilamos en o a través de nuestro Sitio Web puede
            incluir:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Información que usted proporciona al completar formularios en
                nuestro Sitio Web. Esto incluye la información proporcionada en el momento de registrarse para
                usar nuestro Sitio Web, suscribirse a nuestro servicio, publicar material o solicitar más
                servicios. También podemos solicitarle información cuando participa en un concurso o
                promoción patrocinada por nosotros, y cuando informa un problema con nuestro sitio web.</span>
                </li>
                <li className="c3 li-bullet-0"><span className="c0">Registros y copias de su correspondencia (incluidas las direcciones
                de correo electrónico), si se comunica con nosotros.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Sus respuestas a las encuestas que podríamos pedirle que
                complete.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Detalles de las transacciones que realiza a través de nuestro
                Sitio Web y del cumplimiento de sus pedidos. Es posible que se le solicite que proporcione
                información financiera antes de realizar un pedido a través de nuestro sitio web.</span>
                </li>
                <li className="c3 li-bullet-0"><span className="c0">Sus consultas de búsqueda en el Sitio Web.</span></li>
            </ul>
            <p className="c2 c8"><span className="c0"></span></p>
            <p className="c2"><span className="c0">&nbsp;También puede proporcionar información para ser publicada o
            mostrada (en adelante, "</span><span css="font-weight:bold;" className="c4">publicada</span><span className="c0">") en
                áreas públicas del Sitio Web, o transmitida a otros usuarios del Sitio Web o a terceros
            (colectivamente, "Contribuciones del </span><span css="font-weight:bold;" className="c4">Usuario</span><span className="c0">").
                Sus Contribuciones de Usuario se publican y transmiten a otros bajo su propio riesgo. Aunque limitamos el
                acceso a ciertas páginas puede establecer ciertas configuraciones de privacidad para dicha
                información iniciando sesión en el perfil de su cuenta, tenga en cuenta que ninguna medida de
                seguridad es perfecta o impenetrable. Además, no podemos controlar las acciones de otros usuarios del
                Sitio Web con los que puede optar por compartir sus Contribuciones de Usuario. Por lo tanto, no podemos y no
                garantizamos que sus Contribuciones de Usuario no serán vistas por personas no autorizadas.
            &nbsp;</span></p><a id="id.tyjcwt"></a>
            <p css="font-style:italic;" className="c2"><span className="c16">Información Que Recopilamos </span><span className="c17">A</span><span className="c16">&nbsp;Través De Tecnologías De Recopilación Automática de
            Datos</span></p>
            <p className="c2"><span className="c0">A medida que navega e interactúa con nuestro Sitio Web, podemos utilizar
            tecnologías de recopilación automática de datos para recopilar cierta
            información sobre su equipo, acciones de navegación y patrones, que incluyen:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Detalles de sus visitas a nuestro Sitio Web, incluidos datos de
                tráfico, datos de ubicación, registros y otros datos de comunicación y los recursos
                a los que accede y utiliza en el Sitio Web.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Información sobre su computadora y conexión a
                Internet, incluida su dirección IP, sistema operativo y tipo de navegador.</span></li>
            </ul>
            <p className="c2"><span className="c0">La información que recopilamos automáticamente puede incluir
            información personal, o podemos mantenerla o asociarla con información personal que
            recopilamos de otras maneras o recibimos de terceros. Nos ayuda a mejorar nuestro Sitio Web y a ofrecer un
            servicio mejor y más personalizado, incluso permitiéndonos:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Estimar el tamaño de nuestra audiencia y los patrones de
                uso.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Almacenar información sobre sus preferencias, lo que nos
                permite personalizar nuestro Sitio Web de acuerdo con sus intereses individuales.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Acelera tus búsquedas.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Reconocerlo cuando regrese a nuestro Sitio Web.</span></li>
            </ul>
            <p className="c2"><span className="c0">Las tecnologías que utilizamos para esta recopilación automática
            de datos pueden incluir:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span css="font-weight:bold;" className="c4">Cookies (o cookies del navegador). </span><span className="c0">&nbsp;Una
                cookie es un pequeño archivo colocado en el disco duro de su ordenador. Puede negarse a aceptar
                las cookies del navegador activando la configuración adecuada en su navegador. Sin embargo, si
                selecciona esta configuración, es posible que no pueda acceder a ciertas partes de nuestro Sitio
                Web. A menos que haya ajustado la configuración de su navegador para que rechace las cookies,
                nuestro sistema emitirá cookies cuando dirija su navegador a nuestro sitio web. </span></li>
                <li className="c3 li-bullet-0"><span css="font-weight:bold;" className="c4">Cookies Flash. </span><span className="c0">Ciertas
                características de nuestro Sitio Web pueden utilizar objetos almacenados localmente (o cookies
                Flash) para recopilar y almacenar información sobre sus preferencias y navegación hacia,
                desde y en nuestro Sitio Web. Las cookies flash no se gestionan con la misma configuración del
                navegador que se utiliza para las cookies del navegador. Para obtener información sobre
                cómo administrar su configuración de privacidad y seguridad para las cookies flash,
                consulte </span><span className="c0"><a className="c1" href="#id.lnxbz9">Opciones sobre
                    cómo</a></span><span className="c0">&nbsp;</span><span className="c0"><a className="c1" href="#id.lnxbz9">&nbsp;usamos </a></span><span className="c0">&nbsp;</span><span className="c0"><a className="c1" href="#id.lnxbz9">y divulgamos su información.</a></span></li>
                <li className="c3 li-bullet-0"><span css="font-weight:bold;" className="c4">Balizas web. </span><span className="c0">&nbsp;Las páginas de
                nuestro Sitio Web y nuestros correos electrónicos pueden contener pequeños archivos
                electrónicos conocidos como balizas web (también conocidos como gifs transparentes,
                etiquetas de píxeles y gifs de un solo píxel) que permiten a la Compañía,
                por ejemplo, contar los usuarios que han visitado esas páginas o abierto un correo
                electrónico y para otras estadísticas relacionadas con el Sitio Web (por ejemplo,
                registrar la popularidad de cierto contenido del sitio web y verificar la integridad del sistema y del
                servidor). </span></li>
            </ul><a id="id.3dy6vkm"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Uso de Cookies y &nbsp;Otras Tecnologías de Seguimiento por Parte de
            Terceros</span></p>
            <p className="c2"><span className="c0">Algunos contenidos o aplicaciones, incluidos los anuncios, en el Sitio Web son
            servidos por terceros, incluidos anunciantes, redes publicitarias y servidores, proveedores de contenido y
            proveedores de aplicaciones. Estos terceros pueden usar cookies solos o junto con balizas web u otras
            tecnologías de seguimiento para recopilar información sobre usted cuando utiliza nuestro Sitio
            Web. La información que recopilan puede estar asociada con su información personal o pueden
            recopilar información, incluida información personal, sobre sus actividades en línea a
            lo largo del tiempo y en diferentes Sitios Web y otros servicios en línea. Pueden usar esta
            información para proporcionarle publicidad basada en intereses (conductual) u otro contenido
            dirigido. </span></p>
            <p className="c2"><span className="c0">No controlamos las tecnologías de seguimiento de estos terceros ni cómo
            se pueden utilizar. Si tiene alguna pregunta sobre un anuncio u otro contenido dirigido, debe comunicarse
            directamente con el proveedor responsable. Para obtener información sobre cómo puede optar por
            no recibir publicidad dirigida de muchos proveedores, consulte </span><span className="c0"><a className="c1" href="#id.lnxbz9">opciones sobre cómo</a></span><span className="c0">&nbsp;</span><span className="c0"><a className="c1" href="#id.lnxbz9">&nbsp;usamos </a></span><span className="c0">y su información.</span>
            </p><a id="id.1t3h5sf"></a>
            <p id="id.lnxbz9" css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Cómo Usamos Su Información</span></p>
            <p className="c2"><span className="c0">Utilizamos la información que recopilamos sobre usted o que usted nos
            proporciona, incluida cualquier información personal:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Para presentarle nuestro sitio web y sus contenidos.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para proporcionarle información, productos o servicios que
                nos solicite.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para cumplir con cualquier otro propósito para el que lo
                proporcione.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para proporcionarle avisos sobre su cuenta, incluidos los avisos de
                vencimiento y renovación.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para llevar a cabo nuestras obligaciones y hacer valer nuestros
                derechos derivados de cualquier contrato celebrado entre usted y nosotros, incluso para la
                facturación y el cobro.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para notificarle sobre cambios en nuestro sitio web o cualquier
                producto o servicio que ofrezcamos o proporcionemos a través de él. &nbsp;</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para permitirle participar en funciones interactivas en nuestro
                Sitio Web.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">De cualquier otra manera podemos describir cuando usted proporciona
                la información.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para cualquier otro propósito con su consentimiento.</span>
                </li>
            </ul><a id="id.4d34og8"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Divulgación de Su Información</span></p>
            <p className="c2"><span className="c0">Podemos divulgar información agregada sobre nuestros usuarios e
            información que no identifique a ningún individuo, sin restricción. </span></p>
            <p className="c2"><span className="c0">Podemos divulgar información personal que recopilamos o que usted proporciona
            como se describe en esta política de privacidad:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">A nuestras subsidiarias y afiliadas.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">A contratistas, proveedores de servicios y otros terceros que
                utilizamos para respaldar nuestro negocio y que están obligados por obligaciones contractuales a
                mantener la confidencialidad de la información personal y usarla solo para los fines para los que
                se la revelamos. &nbsp;</span></li>
                <li className="c3 li-bullet-0"><span className="c0">A un comprador u otro sucesor en caso de fusión,
                desinversión, reestructuración, reorganización, disolución u otra venta o
                transferencia de algunos o todos los activos de la Compañía, ya sea como una empresa en
                marcha o como parte de una bancarrota, liquidación o procedimiento similar, o desinversión
                en la que la información personal en poder de la Compañía sobre los usuarios de
                nuestro sitio web se encuentra entre los activos transferidos.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para cumplir con el propósito para el cual usted lo
                proporciona. Por ejemplo, si nos proporciona una dirección de correo electrónico para
                utilizar la función "enviar un correo electrónico a un amigo" de nuestro sitio
                web, transmitiremos el contenido de ese correo electrónico y su dirección de correo
                electrónico a los destinatarios.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para cualquier otro propósito divulgado por nosotros cuando
                usted proporciona la información.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Con su consentimiento.</span></li>
            </ul>
            <p className="c2"><span className="c0">También podemos divulgar su información personal:</span></p>
            <ul className="c5 lst-kix_list_19-0">
                <li className="c3 li-bullet-0"><span className="c0">Para cumplir con cualquier orden judicial, ley o proceso legal,
                incluso para responder a cualquier solicitud gubernamental o regulatoria.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Para hacer cumplir o aplicar nuestros Términos de Uso y otros
                acuerdos, incluso para fines de facturación y cobro.</span></li>
                <li className="c3 li-bullet-0"><span className="c0">Si creemos que la divulgación es necesaria o apropiada para
                proteger los derechos, la propiedad o la seguridad de la Compañía, nuestros clientes o
                otros. Esto incluye el intercambio de información con otras empresas y organizaciones con el fin
                de proteger contra el fraude y reducir el riesgo de crédito.</span></li>
            </ul>
            <p className="c2"><span className="c0">No controlamos la recopilación o el uso de su información por parte de
            terceros para servir publicidad basada en intereses. Sin embargo, estos terceros pueden proporcionarle
            formas de elegir que su información no se recopile o use de esta manera. Puede optar por no recibir
            anuncios dirigidos de los miembros de la Network Advertising Initiative (“Iniciativa de Publicidad de
            Red”) ("</span><span css="font-weight:bold;" className="c4">NAI</span><span className="c0">") en el Sitio Web de NAI
            .</span></p><a id="id.2s8eyo1"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Acceso y Corrección de su Información</span></p>
            <p className="c2"><span className="c0">Puede revisar y cambiar su información personal iniciando sesión en el
            sitio web y visitando la página de perfil de su cuenta.</span></p>
            <p className="c2"><span className="c0">Si elimina sus Contribuciones de Usuario del Sitio Web, es posible que otras personas
            hayan copiado o almacenado copias de sus Contribuciones de usuario en páginas almacenadas en
            caché y archivadas o que otros usuarios del sitio web las hayan copiado o almacenado. </span></p><a id="id.17dp8vu"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Seguridad de los Datos</span></p>
            <p className="c2"><span className="c0">Hemos implementado medidas diseñadas para proteger su información
            personal de la pérdida accidental y del acceso, uso, alteración y divulgación no
            autorizados. Cualquier transacción de pago se asegurará a través de PayPal. Para
            obtener más información, consulte </span><span className="c0"><a className="c1" href="https://www.google.com/url?q=https://www.paypal.com/re/webapps/mpp/paypal-safety-and-security&amp;sa=D&amp;source=editors&amp;ust=1639771379993000&amp;usg=AOvVaw3fPk9qScMxdyYt3oIQznT-">el
                Sitio Web de seguridad y protección de PayPal.</a></span></p>
            <p className="c2"><span className="c0">La seguridad de su información también depende de usted. Cuando le
            hayamos dado (o cuando haya elegido) una contraseña para acceder a ciertas partes de nuestro Sitio
            Web, usted es responsable de mantener esta contraseña confidencial. Le pedimos que no comparta su
            contraseña con nadie. Le instamos a que tenga cuidado al dar información en áreas
            públicas del sitio web, como tableros de mensajes. La información que comparte en áreas
            públicas puede ser vista por cualquier usuario del Sitio Web.</span></p>
            <p className="c2"><span className="c0">Desafortunadamente, la transmisión de información a través de
            Internet no es completamente segura. Aunque hacemos todo lo posible para proteger su información
            personal, no podemos garantizar la seguridad de su información personal transmitida a nuestro sitio
            web. Cualquier transmisión de información personal es bajo su propio riesgo. No somos
            responsables de la elusión de cualquier configuración de privacidad o medidas de seguridad
            contenidas en el Sitio Web.</span></p><a id="id.3rdcrjn"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Cambios en Nuestra Política de Privacidad</span></p>
            <p className="c2"><span className="c0">Es nuestra política publicar cualquier cambio que hagamos a nuestra
            política de privacidad en esta página. Si realizamos cambios sustanciales en la forma en que
            tratamos la información personal de nuestros usuarios, se lo notificaremos por correo
            electrónico a la dirección de correo electrónico principal especificada en su cuenta o
            a través de un aviso en la página de inicio de la Sitio Web. La fecha en que se revisó
            por última vez la política de privacidad se identifica en la parte superior de la
            página. Usted es responsable de garantizar que tengamos una dirección de correo
            electrónico activa y entregable actualizada para usted, y de visitar periódicamente nuestro
            sitio web y esta política de privacidad para verificar cualquier cambio.</span></p><a id="id.26in1rg"></a>
            <p css="font-weight:bold;text-decoration:underline;" className="c2"><span className="c10">Información de Contacto</span></p>
            <p className="c2"><span className="c0">Para hacer preguntas o comentarios sobre esta política de privacidad y
            nuestras prácticas de privacidad, contáctenos en: </span><span css="background-color:#c0c0c0;" className="c0 c15">info@Interpowered.com</span><span className="c0">.</span></p>
            <div>
                <p className="c8 c18"><span className="c6"></span></p>
                <p className="c12"><span className="c6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </p>
            </div>
        </div>
    </>
}

export default PrivacyPolicySpanish