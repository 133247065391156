import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
window.videojs = videojs;
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");

const Player = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, signedString } = props;
  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        if (player.tech()) {
          player.tech().vhs.xhr.beforeRequest = function (options) {
            options.uri = options.uri + signedString;
            console.log(options.uri);
            return options;
          };
        }
      }));
      player.hlsQualitySelector({
        displayCurrentQuality: true
      });
    } else {
        const player = playerRef.current;
        player.src(options.sources);
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, signedString]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default Player;
