import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../navbar/navbar'
import Footer from '../footer/footer'
import { Button, Card, Dimmer, Form, Input, Loader } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import axios from '../../axios'
import 'styled-components/macro'
import { Link, useLocation } from 'react-router-dom'
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import env from '../../config.env'
const {paypal_client_id}=env


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SocialMedia = () => {
    const [loading, setLoading] = useState(true)
    const [paypal, setPaypal] = useState(false)
    const [successful, setSuccessful] = useState(false)
    const [platform, setPlatform] = useState("facebook")
    const [dates, setDates] = useState([])
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState("")
    const [calVal, setCalVal] = useState(new Date())
    const { userInfo } = useSelector(state => state.user)
    let query = useQuery()
    const platforms = useRef([{ key: 1, text: "Facebook", value: "facebook" }, { key: 2, text: "Instagram", value: "instagram" }])
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    const today2 = useRef((() => { const d = new Date(); d.setHours(0, 0, 0, 0); return d })())
    const getDate = (str) => {
        const a = [];
        str.forEach((x) => {
            const d2 = new Date(x);
            d2.setTime(d2.getTime() + d2.getTimezoneOffset() * 60 * 1000);
            a.push(d2.toISOString());
        });
        return a;
    };
    const handleSubmitClick = () => {
        if (selectedProduct == "") {
            alert("Please fill all fields!");
            return
        }
        const daf = calVal.getFullYear() + '-' + pad(calVal.getMonth() + 1) + '-' + pad(calVal.getDate())
        if (dates.map(x => x.substring(0, 10)).includes(daf)) {
            alert("Busy day selected!")
            return
        }
        console.log("dates: ", dates, "---daf: ", daf)
        setPaypal(true)
    }
    const handlePlatformChange = async (e, { value }) => {
        setLoading(true)
        try {
            setPlatform(value)
            const { data } = await axios.post("/api/paypal/socialmediacheck/", {
                offset: (new Date()).getTimezoneOffset(),
                platform: value
            }, config)
            setDates(getDate(data.map(x => x.date)))
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const loadData = async () => {
        const prod = query.get("product")
        const plat = query.get("platform")
        const pl = plat ? plat : "facebook"
        setLoading(true)
        try {
            let { data: busy_days } = await axios.post("/api/paypal/socialmediacheck/", {
                offset: (new Date()).getTimezoneOffset(),
                platform: pl
            }, config)
            setDates(getDate(busy_days.map(x => x.date)))
            console.log(getDate(busy_days.map(x => x.date)))
            let { data: products } = await axios.get("/api/product/my", config)
            products = products.map((x) => { return { key: x._id, text: (x.name || x.service_name), value: x._id } })
            if (prod) { setSelectedProduct(prod) }
            if (plat) { setPlatform(pl) }
            setProducts(products)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1200px;margin:0 auto; padding:0 36px;">
                    <h2 css="text-align:center;">Social media package</h2>
                    {!paypal && <div css="margin:0 auto;padding:10px;max-width:600px;">
                        <div className="ui form">
                            <Form.Select
                                label="Product"
                                options={products}
                                value={selectedProduct}
                                onChange={(e, { value }) => setSelectedProduct(value)}
                            />
                            <Form.Select
                                label="Social media platform"
                                options={platforms.current}
                                value={platform}
                                onChange={handlePlatformChange}
                            />
                            <div css="display:flex;justify-content:center;">
                                <Calendar
                                    onChange={setCalVal}
                                    value={calVal}
                                    tileDisabled={({ activeStartDate, date, view }) => (dates.includes(date.toISOString()) || date.toISOString() < today2.current.toISOString())}
                                />
                            </div>
                            <div css="margin-top:10px;">
                                <Button circular color="teal" onClick={handleSubmitClick}>Submit</Button>
                            </div>
                        </div>
                    </div>}
                    {paypal && !successful && <div css="max-width:300px;margin:20px auto;">
                        <PayPalButton
                            createOrder={async (data, actions) => {
                                const { data: oid } = await axios.get(`/api/paypal/socialmedia/create_order?selectedProduct=${selectedProduct}&platform=${platform}&day=${calVal.toISOString()}`, config)
                                return oid
                            }}
                            onApprove={async (data, actions) => {
                                try {
                                    await axios.post("/api/paypal/socialmedia/capture", {
                                        orderID: data.orderID
                                    }, config)
                                    setSuccessful(true)
                                    return
                                } catch (e) {
                                    console.log(e)
                                    alert("There was an error, please contact the administrator. PAYPAL_ORDER_ID:" + data.orderID)
                                }
                            }}
                            onError={(e) => alert(e)}
                            options={{
                                clientId: paypal_client_id
                              }}
                        />
                        {/* <PayPalButton
                        amount={"2.99"}
                        onSuccess={async (details, data) => {
                            const { data: result } = await axios.post("/api/paypal/socialmedia", { order_id: data.orderID, selectedProduct, platform, day: calVal.toISOString() }, config)
                            setSuccessful(true)
                        }}
                    /> */}
                    </div>}
                    {successful &&
                        <div css="max-width:300px;margin:20px auto;">
                            <h3 css="text-align:center;">Transaction completed</h3>
                            <Link to="/" className="ui teal circular fluid button">Return to home page</Link>
                        </div>
                    }
                </div>
            </main>

            <Footer />
        </>
    )
}

export default SocialMedia