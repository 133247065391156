const development = {
    paypalRedirectURL: "https://www.sandbox.paypal.com/connect/?flowEntry=static&client_id=Ad5apGlktUVvcW5NvkfhQYj8QdPGB6J9IXRHfzS1fLcuXQwVp0NUvbjxIcJdDpoRamyDQUdVXMNF5tx-&scope=openid%20email%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=",
    paypal_client_id: "Ad5apGlktUVvcW5NvkfhQYj8QdPGB6J9IXRHfzS1fLcuXQwVp0NUvbjxIcJdDpoRamyDQUdVXMNF5tx-"
    // paypalRedirectURL: "https://www.sandbox.paypal.com/connect/?flowEntry=static&client_id=AXWdSNazXfjcjDzSJiqzPrMhQwM0kJ78Hj0QaRctzhzmClMlNOXymFj8cJWPm2VzX_ZowdiVxYGz7498&scope=openid%20email%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=",
    // paypal_client_id: "AXWdSNazXfjcjDzSJiqzPrMhQwM0kJ78Hj0QaRctzhzmClMlNOXymFj8cJWPm2VzX_ZowdiVxYGz7498"
}
const production = {
    paypalRedirectURL: "https://www.paypal.com/connect/?flowEntry=static&client_id=AbXTORXRVhWCwzoGPMf0A07auy9YUO_S_kTpHnvD8Nds8_CCwW4MYNp-wfPI6ssTVS9G2CyFZfAEdDcm&scope=openid%20email%20https%3A%2F%2Furi.paypal.com%2Fservices%2Fpaypalattributes&redirect_uri=",
    paypal_client_id: "AbXTORXRVhWCwzoGPMf0A07auy9YUO_S_kTpHnvD8Nds8_CCwW4MYNp-wfPI6ssTVS9G2CyFZfAEdDcm"
}


export default window.location.hostname == "interpowered.com" ? production : development