import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ProgressBar=({active})=>{
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <div css="height:5px;background:teal;display:flex;align-items:center;justify-content:space-between;margin-bottom:70px;margin-top:30px;">
                <Link to="customer-profile" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:"+(active>=1 ? "teal" : "white")+";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Customer_profile")}</div>
                </Link>
                <Link to="customer-orders" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:"+(active>=2 ? "teal" : "white")+";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Orders")}</div>
                </Link>
                <Link to="customer-settings" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:"+(active>=3 ? "teal" : "white")+";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Settings")}</div>
                </Link>
               
            </div>
        </>
    )
}

export default ProgressBar