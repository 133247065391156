import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Message } from 'semantic-ui-react'
import 'styled-components/macro'
import PasswordChecklist from "react-password-checklist"
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../store/user-slice'
import axios from '../../axios'
import { emailRegex } from '../../config'
import { uiActions } from '../../store/ui-slice'


const RegisterForm = (props) => {
    const dispatch = useDispatch()
    const [error, setError] = useState("")
    const { uiSignInModal } = useSelector(state => state.ui)
    const [isValid, setIsValid] = useState(false)
    const handleFormSubmit = async (path) => {
        // e.preventDefault()
        if (!isValid) return;
        if (values.email == "" || values.name == "" || values.password == "") {
            setError("Fill all fields")
            return
        }
        if (!(new RegExp(emailRegex, "gi").test(values.email))) {
            setError("Enter a valid email")
            return
        }
        try {
            setFormLoading(true)
            await axios.post("/api/user/checkemail", {
                email: values.email
            })
            // const path = e.nativeEvent.submitter.name
            dispatch(userActions.saveRegisterInfo(values))
            dispatch(uiActions.change_action_sign_in({ open: true, action: undefined}))
            props.changeAction(path)
            setFormLoading(false)
        } catch (err) {
            setFormLoading(false)
            setError(err.response.data.message)
        }

    }
    const [formLoading, setFormLoading] = useState(false)
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({ email: uiSignInModal?.google_data?.email || "", name: uiSignInModal?.google_data?.name || "", password: "" })
    const handleInputChange = (e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }
    return (
        <>
            <div>
                <div css="display:flex;justify-content:space-between; margin-bottom:15px;">
                    <div>
                        <h2 css="margin-bottom:0;">{t('navbar.create_your_account')}</h2>
                        <a css="margin-top:0;" target="_blank" href="https://interpowered.com/wiki?opt=how_to_register">{t("navbar.more_info")}</a>
                    </div>
                    <Button type="button" circular color="teal" basic className="dark-outline-teal" onClick={() => props.changeAction("login")}>{t('navbar.login')}</Button>
                </div>
                <Form loading={formLoading}>
                    <Form.Input icon='mail' iconPosition='left' onChange={handleInputChange} defaultValue={uiSignInModal?.google_data?.email || ""} required fluid label={t('navbar.email')} type="email" name="email" placeholder='user@example.com' />
                    <Form.Input icon='user' iconPosition='left' onChange={handleInputChange} defaultValue={uiSignInModal?.google_data?.name || ""} required fluid label={t('navbar.name')} type="text" name="name" placeholder='' />
                    <Form.Input icon='lock' iconPosition='left' onChange={handleInputChange} required fluid label={t('navbar.password')} name="password" type="password" />
                    <PasswordChecklist
                        style={{ marginTop: "-10px", marginBottom: "20px" }}
                        rules={["length", "number", "capital"]}
                        minLength={8}
                        value={values.password}
                        onChange={(isValid) => { /*setPassCheck(isValid);*/ setIsValid(isValid) }}
                        messages={{
                            length: t('navbar.pass_char'),
                            number: t('navbar.pass_num'),
                            capital: t('navbar.pass_cap')
                        }}
                    />
                    {error && (
                        <Message color="red">
                            {error}
                        </Message>

                    )}
                </Form>
                <Button onClick={() => handleFormSubmit("register_as_customer")} type="button" style={{ border: "3px solid rgb(0,128,129)", marginBottom: "10px" }} fluid basic circular color="teal" className="shadow-none" ><span style={{ color: "#008081", fontWeight: "bold" }}>{t("navbar.register_as_customer")}</span></Button>
                {/* {<Button onClick={()=>handleFormSubmit("register_as_customer")} name="register_as_customer" type="button" css="margin-bottom:10px !important; background-color: !important;" circular fluid color="teal">{t('navbar.register_as_customer')}</Button>} */}
                <Button onClick={() => handleFormSubmit("register_as_freelancer")} name="register_as_freelancer" type="button" css="margin-bottom:10px !important; background-color:rgb(0,128,129) !important;" style={{ border: "2px solid rgb(0,128,129)" }} circular fluid color="teal">{t('navbar.register_as_freelancer')}</Button>
                <Button onClick={() => handleFormSubmit("register_as_business")} name="register_as_business" type="button" css="margin-bottom:10px !important; background-color:rgb(2,183,178) !important;" style={{ border: "2px solid rgb(2,183,178)" }} circular fluid color="teal">{t('navbar.register_as_business')}</Button>


            </div>
        </>
    )
}

export default RegisterForm


/**

 props.changeAction("customer")
props.changeAction("register_as_freelancer")
props.changeAction("register_as_business")

 */