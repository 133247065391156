import { createSlice } from '@reduxjs/toolkit'
import axios from '../axios';
import { cartActions } from './cart-slice';

//const local=JSON.parse(localStorage.getItem("userInfo"))

const initialState = localStorage.getItem("userInfo") ? { loading: false, userInfo: JSON.parse(localStorage.getItem("userInfo")), error: false } : { loading: false, userInfo: false, error: false, hasToken: false }
console.log("initialstate:", initialState)
const userSlice = createSlice({
    name: "user",
    initialState,//:{loading:false,userInfo:false,error:false},
    reducers: {
        login_reducer(state, action) {
            state.loading = action.payload.loading
            state.userInfo = action.payload.userInfo
            state.error = action.payload.error
            state.hasToken = action.payload.hasToken
        },
        logout_reducer(state) {
            state.loading = false
            state.userInfo = false
            state.error = false
        },
        clearError(state) {
            state.error = false
            state.hasToken = false
        },
        updateImageProfile(state, action) {
            const imageLink = action.payload
            state.userInfo.image = imageLink
            localStorage.setItem("loginState", JSON.stringify(state.userInfo))
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        },
        saveRegisterInfo(state,action){
            state.registerInfo=action.payload
        },
        updateValues(state,action){
            let name=action.payload.name
            const value=action.payload.value
            if(name.includes(".")){
                name=name.split(".")
                state.userInfo[name[0]][name[1]]=value
            }else{
                state.userInfo[name]=value
            }
            localStorage.setItem("loginState", JSON.stringify(state.userInfo))
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        },
        updateFavorites(state,action){
            const id=action.payload.id;
            const type=action.payload.type;
            if(id.endsWith(" remove")){
                const rid=id.split(" ")[0]
                state.userInfo.favorites=state.userInfo.favorites.filter(x=>x.id!=rid)
            }else{
                state.userInfo.favorites.push({id,type})
            }
            localStorage.setItem("loginState", JSON.stringify(state.userInfo))
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        }
    }
})

export const userActions = userSlice.actions

export const login = (email, password, token) => {
    return async (dispatch) => {
        try {
            dispatch(userActions.login_reducer({ loading: true }))
            const { data } = await axios.post("/api/user/login", { email, password, token });
            console.log(data)
            if (data == "token") {
                dispatch(userActions.login_reducer({ loading: false, userInfo:false, hasToken: true }))
                return
            } else {
                const config = {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                    },
                }
                const { data: cartData } = await axios.get("/api/user/cart", config)
                for (let i = 0; i < cartData.cart.length; i++) {
                    for (let j = 0; j < cartData.cart[i].quantity; j++) {
                        dispatch(cartActions.addItem(cartData.cart[i].product))
                    }
                }
                localStorage.setItem("loginState", JSON.stringify(data))
                localStorage.setItem("userInfo", JSON.stringify(data))
                window.location.reload()
                await new Promise((resolve)=>{})
                // dispatch(userActions.login_reducer({ loading: false, userInfo: data }))
            }
        } catch (err) {console.log(err)
            console.log(err?.response?.data?.message)
            dispatch(userActions.login_reducer({ loading: false, error: err?.response?.data?.message }))
        }

    }

}

export const loginWithToken = (email, password, token) => {
    return async (dispatch) => {
        try {
            dispatch(userActions.login_reducer({ loading: true }))
            const { data } = await axios.post("/api/user/login", { email, password, token });
            console.log(data)
            if (data == "token") {
                dispatch(userActions.login_reducer({ loading: false, hasToken: true }))
                return
            } else {
                dispatch(userActions.login_reducer({ loading: false, userInfo: data }))
                localStorage.setItem("loginState", JSON.stringify(data))
                localStorage.setItem("userInfo", JSON.stringify(data))
            }
        } catch (err) {
            console.log(err.response.data.message)
            dispatch(userActions.login_reducer({ loading: false, error: err.response.data.message }))
        }

    }

}

export const logout = () => {
    return async (dispatch) => {
        try {
            dispatch(userActions.logout_reducer())
            localStorage.clear()
        } catch (err) {
            console.log(err.response.data.message)
            alert(err)
        }

    }

}

export default userSlice