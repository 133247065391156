export const footerStyles = `
/*margin-left:-36px;margin-right:-36px;*/
padding-top:30px;

@media print {
    display:none;
 }

    .links{
        background-color:rgb(2,183,178);
        color:#ffffffdd;

        .linksWrapper{
            max-width:1400px;
            margin:0 auto;
            padding:50px 36px;


            .linkList{
                flex: 0 0 auto;
                max-width:100%;
                 padding:4px;
                 width:25%;
                 @media(max-width:700px){
                     flex: 0 0 auto;
                     width: 50%;
                     margin:10px 0;
                }
                @media(max-width:500px){
                    flex: 0 0 auto;
                    width: 100%;
                    margin:10px 0;
                }

                .mb-2{
                    margin-bottom:0.5rem;
                }
            }
        }
    }

    .foot{
        background-color:rgb(0,128,129);

        .footWrapper{
            max-width:1400px;
            margin:0 auto;
            padding:1.5rem 36px;

            .footDiv{
                display:flex;
                align-items:center;
                justify-content:space-between;
                flex-wrap:wrap;

                .btnNat{
                    display:flex;
                    align-items:center;
                    color:#ffffffdd !important;
                    background:transparent;
                    border:none;
                    padding:7px 14px;
                    
                    @media(max-width:910px){
                        width:100%;
                        justify-content:center;
                        margin-bottom:1rem;
                    }
                    @media(max-width:320px){
                        font-size:13px;
                    }
                }
                .legal{
                    max-width:600px;
                    width:100%;
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    color:#ffffffdd;
                    @media(max-width:910px){
                        margin:0 auto;
                    }
                    @media(max-width:320px){
                        flex-direction:column;
                    }
                }
            }

        }
    }

    ul{
        margin:0;
        padding:0;
        list-style:none;
    }
    a{
        color:inherit;
        &:hover{
            text-decoration:underline;
        }
    }

    .socialicons{
        a{
            &:hover{
                text-decoration:none;
                color:#fff;
            }
        }
    }

    .downloadButton{
        border:3px solid #ffffffdd;
        font-weight:bold;
        border-radius:100px;
        padding:5px 10px;
        font-size:16px;
        display:block;
        text-align:center;
        &:hover{
            text-decoration:none;
        }
    }
`