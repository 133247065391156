import { printIntrospectionSchema } from 'graphql'
import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const SignOutModal = (props) => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                closeIcon
                open={props.open}
                onClose={() => props.setClose()}
                size="tiny"
            >
                {!props.text && <Header icon='sign out' content={t("footer.sign_out")} />}
                <Modal.Content>
                    <p>
                        {props.text || t("footer.sign_out_para")}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='teal' circular onClick={() => props.setClose()}>
                        {/* <Icon name='checkmark' /> */}
                        {t("footer.close")}
                    </Button>
                    {/* <Button color='teal' basic circular onClick={() => { }}>
                        <Icon name='remove' /> {t("footer.cancel")}
                    </Button> */}
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default SignOutModal