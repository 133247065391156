import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Header, Icon, Input, Label, Loader, Menu, Select, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next'
import Navbar from '../../../shared/navbar/navbar';
import Footer from '../../../shared/footer/footer';
import { useSelector } from 'react-redux';
import axios from '../../../axios';
import 'styled-components/macro'
import { Link } from 'react-router-dom';

const MarketingPackagesAdmin = () => {
    const [purchases, setPurchases] = useState([])
    const [pages, setPages] = useState({})
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [t, i18n] = useTranslation("global")
    const loadData = async (cp = 1) => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: { purchases, page, pages } } = await axios.get(`/api/user/purchases_admin?pageNumber=${cp}&fdp=${deactivated_purchases.current || ""}&fpt=${RfilterType.current == "all" ? "" : RfilterType.current}&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}&fname=${RfilterName.current}`, config)//&fsd=${RfilterDateS.current}&fed=${RfilterDateE.current}
            setPages({ current: page, total: pages })
            setPurchases(purchases)
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }

    const deactivated_purchases = useRef(false)
    const RfilterDateS = useRef("")
    const RfilterDateE = useRef("")
    const RfilterDateSv = useRef("")
    const RfilterDateEv = useRef("")
    const RfilterType = useRef("all")
    const RfilterName = useRef("")
    const handleNameChange = (e) => {
        RfilterName.current = e.target.value
    }
    const handleDateFromChange = (e) => {
        const date = new Date(e.target.value)
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        RfilterDateS.current = date.toISOString()
        RfilterDateSv.current = e.target.value
    }
    const handleDateToChange = (e) => {
        const date = new Date(e.target.value)
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        RfilterDateE.current = date.toISOString()
        RfilterDateEv.current = e.target.value
    }
    const handleTypeChange = (e, { value }) => {
        RfilterType.current = value
    }
    const handleDeactivatedPurchasesChange = () => {
        // setTrackO(p => !p)
        deactivated_purchases.current = !deactivated_purchases.current
        loadData()
    }
    const handlePageClick = async (page) => {
        loadData(page)
    }

    useEffect(() => {
        loadData()
    }, [])

    if (loading)
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>

    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1200px;margin:0 auto;padding:0 36px;">
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='9'>
                                    <div css="display:flex;align-items:center;justify-content:space-between;">
                                        <Checkbox checked={deactivated_purchases.current} onChange={handleDeactivatedPurchasesChange} label={t("marketing_packages.deactivated_purchases")} />
                                        <div>
                                            <Input defaultValue={RfilterDateS.current} type="date" onChange={handleDateFromChange} />
                                            <span css="margin: 0 1rem;">-</span>
                                            <Input defaultValue={RfilterDateE.current} type="date" onChange={handleDateToChange} />
                                        </div>
                                        <Select
                                            options={[{ key: 0, text: "All", value: "all" }, { key: 1, text: "In-app notifications", value: "inappnotifications" }, { key: 2, text: "Social media", value: "socialmedia" }, { key: 3, text: "Marketing homepage", value: "marketinghomepage" }]}
                                            defaultValue={RfilterType.current}
                                            onChange={handleTypeChange}
                                        />
                                        <Button onClick={() => { loadData() }}>{t("marketing_packages.apply_filter")}</Button>
                                        <Button onClick={() => {
                                            RfilterType.current = "all"
                                            RfilterDateS.current = "";
                                            RfilterDateE.current = "";
                                            RfilterDateSv.current = "";
                                            RfilterDateEv.current = "";
                                            loadData()
                                        }}>Clear filter</Button>
                                    </div>
                                    <div css="display:flex;align-items:center;justify-content:space-between;">
                                        <Input placeholder={t("marketing_packages.vendor_name")} defaultValue={RfilterName.current} onChange={handleNameChange} />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>{t("marketing_packages.purchase_type")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("marketing_packages.product")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("marketing_packages.vendor_name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("marketing_packages.start_date")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("marketing_packages.end_date")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("marketing_packages.notifications")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("marketing_packages.days_remaining")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("marketing_packages.active")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {purchases.length > 0 && purchases.map((x, c) => {
                                const one_day = 1000 * 60 * 60 * 24
                                const drem = (x.start_date && x.end_date) ? ((new Date(x.start_date).getTime() > new Date().getTime()) ? ((((Math.round((new Date(x.end_date).getTime()) - (new Date(x.start_date)).getTime()) / one_day) + 1).toFixed(0))) : (((Math.round((new Date(x.end_date).getTime()) - (new Date()).getTime()) / one_day) + 1).toFixed(0))) : undefined
                                return (
                                    <Table.Row key={x.order_id}>
                                        <Table.Cell>{x.purchase_type + " " +(x.purchase_type==="Social media"?x.platform:"")}</Table.Cell>
                                        <Table.Cell>{x.product_id ? (<MyLink data={x}/>) : "-"}</Table.Cell>
                                        <Table.Cell>{x.user_id ? (x.user_id.name) : "-"}</Table.Cell>
                                        <Table.Cell>{x.start_date ? (new Date(x.start_date)).toLocaleDateString() : ""}</Table.Cell>
                                        <Table.Cell>{x.end_date ? (new Date(x.end_date)).toLocaleDateString() : "-"}</Table.Cell>
                                        <Table.Cell>{x.notifications || "-"}</Table.Cell>
                                        <Table.Cell>{drem || "-"}</Table.Cell>
                                        <Table.Cell collapsing>
                                            {x.active && <Label color="green">YES</Label>}
                                            {!x.active && <Label color="red">NO</Label>}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}

                            {
                                purchases.length < 1 &&
                                (
                                    <Table.Row>
                                        <Table.Cell colSpan='9'>
                                            <div css="display:flex;align-items:center;justify-content:center;">
                                                <Header as='h2' icon>
                                                    <Icon name='ban' />
                                                    {t("marketing_packages.nothing")}
                                                </Header>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='9'>
                                    <div css="display:flex;align-items:center;justify-content:flex-end;">
                                        {pages.total > 1 && <Menu pagination>

                                            {[...Array(pages.total).keys()].map((x, c) => {
                                                return <Menu.Item key={c} active={pages.current == c + 1} onClick={() => handlePageClick(c + 1)} as='a'>{c + 1}</Menu.Item>
                                            })}
                                        </Menu>}
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            </main>

            <Footer />

        </>
    )
}

export default MarketingPackagesAdmin

const MyLink=({data})=>{
    let link
    if(data.product_id.vendor_type==="Business"){
        link="/product/"+data.product_id._id
    }
    if(data.product_id.vendor_type==="Freelancer"){
        link="/service/"+data.product_id._id
    }
    return <>
        <Link target="_blank" rel="noopener noreferrer" to={link}>{data.product_id.name}</Link>
    </>
}