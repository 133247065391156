import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProgressBar from './progressbar'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { btnContainer, btnPop, divImage, homeDivStyles, imageStyles, profimage1, profimage2, footButtons } from '../styles'
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch, useSelector } from 'react-redux'
import htmlToDraft from 'html-to-draftjs';
import { formData } from '../../shared/formDataCreator'
import { userActions } from '../../store/user-slice'
import { url_environment } from '../../config'
import UpdateEmail from '../../shared/signInModal/updateEmail'

const BusinessProfile = () => {

    const [Categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const [values, setValues] = useState({
        name_of_business: "",
        business_sector: "",
        business_description: "",
        link_to_website: "",
        business_category: "",
        service_offered: "",
        products_for_sale: "",
        shipping_policy: "",
        business_image: "",
        business_image_link: "",
        returns_policy:"",
        estimated_shipping_times:""
    })
    const dispatch = useDispatch()
    // const [editorState_returns_policy, setEditorState_returns_policy] = useState(() =>
    //     EditorState.createEmpty()
    // );
    // const [editorState_estimated_shipping_times, setEditorState_estimated_shipping_times] = useState(() =>
    //     EditorState.createEmpty()
    // );
    const loadData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data: { profile: data, categories: categoryData } } = await axios.get("/api/business/profile", config)
            console.log(data);
            setCategories(categoryData)
            setValues(p => {
                return {
                    ...p,
                    name: data.id_user.name,
                    email: data.id_user.email,
                    name_of_business: data.business_name,
                    business_sector: data.business_sector,
                    business_description: data.business_description,
                    business_image_link: data.business_picture ? url_environment + data.business_picture : "",
                    link_to_website: data.url_website,
                    business_category: categoryData.filter((i) => { return i.id_cat == data.id_service_category })[0],
                    service_offered: data.service_offered,
                    products_for_sale: data.products_for_sale,
                    shipping_policy: data.shipping_policy,
                    returns_policy:data.returns_policy,
                    estimated_shipping_times:data.estimated_shipping_times,
                }
            })

            // setEditorState_returns_policy(getDraftFromHtml(data.returns_policy))
            // setEditorState_estimated_shipping_times(getDraftFromHtml(data.estimated_shipping_times))
            setLoading(false)
        } catch (e) {
            alert(e)
        }
    }

    const getDraftFromHtml = (data) => {
        const blocksFromHtml = htmlToDraft(data);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }
    useEffect(() => {
        loadData()
    }, [])
    const [t, i18n] = useTranslation("global")
    const handleInputChange = (e) => {
        setValues((prev) => { return { ...prev, [e.target.name]: e.target.value } })
    }
    const handleSubmit = async (e) => {
        //console.log(values.business_category)
        e.preventDefault()
        try {
            setLoading(true)
            const temp = { ...values }
            temp.category_name = values.business_category.name
            temp.id_service_category = values.business_category._id
            // temp.returns_policy = draftToHtml(convertToRaw(editorState_returns_policy.getCurrentContent()))
            // temp.estimated_shipping_times = draftToHtml(convertToRaw(editorState_estimated_shipping_times.getCurrentContent()))
            delete temp.business_image_link
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.put("/api/business/profile", formData(temp), config)
            console.log("typeof: ", typeof data.email_error)
            if (typeof data.email_error === "string" && data.email_error === "EMAIL_ERROR") {
                const em = values.email
                setValues(p => { return { ...p, email: userInfo.email } })
                alert(`Email "${em}" already in use!`)
            } else {
                dispatch(userActions.updateValues({ name: "email", value: temp.email }))
            }
            handleInputChange({ target: { name: "business_image_link", value: url_environment + data.business_picture } })
            dispatch(userActions.updateImageProfile(data.business_picture.substring(1)))
            if (data.category_rate)
                dispatch(userActions.updateValues({ name: "seller.category_rate", value: data.category_rate }))
            dispatch(userActions.updateValues({ name: "name", value: temp.name }))
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const handleImageChange = (e) => {
        if (!e.target.files[0]) return
        setValues(p => { return { ...p, business_image: e.target.files[0] || "" } })
        setValues(p => { return { ...p, business_image_link: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" } })
    }
    const handleImageButtonClick = () => imageRef.current.click();
    const handleImageLoad = () => URL.revokeObjectURL(values.business_image_link)

    const history = useHistory()

    const submitButton = useRef()

    const imageRef = useRef()

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <main>


                <div className="ui container">
                    <ProgressBar active={1} />
                </div>

                <h2 css="text-align:center;">{t("profile.Business_Profile")}</h2>


                <form onSubmit={handleSubmit} className="ui grid container" css="justify-content:space-between">
                    <div className="sixteen wide mobile seven wide computer column">
                        <div className="ui form">
                            <div className="field">
                                <label>{t("profile.Name")}</label>
                                <input required value={values.name} onChange={handleInputChange} type="text" name="name" />
                            </div>
                            {/* <div className="field">
                                <label>{t("profile.Email")}</label>
                                <input required value={values.email} onChange={handleInputChange} type="text" name="email" />
                            </div> */}
                            <div className="field">
                                <label>{t("profile.Name_of_Business")}</label>
                                <input required value={values.name_of_business} onChange={handleInputChange} type="text" name="name_of_business" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Business_sector")}</label>
                                <input value={values.business_sector} onChange={handleInputChange} type="text" name="business_sector" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Business_description")}</label>
                                <textarea required value={values.business_description} onChange={handleInputChange} name="business_description"></textarea>
                            </div>
                            <div className="field">
                                <label>{t("profile.Link_to website")}</label>
                                <input required value={values.link_to_website} onChange={handleInputChange} type="text" name="link_to_website" />
                            </div>
                            <UpdateEmail />
                        </div>
                    </div>
                    <div className="sixteen wide mobile seven wide computer column">
                        <div className="ui form">
                            <div css="display:flex;align-items:center;justify-content:flex-end;">
                                <input hidden type="file" ref={imageRef} onChange={handleImageChange} accept="image/jpeg, image/png" />
                                <p>{t('profile.Business_Cover_Photo')}</p>
                                <div action="button" onClick={handleImageButtonClick} css="border:0;background:#fff;cursor:pointer;padding:0;width:120px;height:120px;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);margin-left:3.5rem;">
                                    <div css="width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:relative;">
                                        {values.business_image_link === "" && <div css="display:flex;flex-direction:column;align-items:center;">
                                            <p css="text-align:center;margin:0;margin-bottom:5px;">{t('profile.Add_a_photo')}</p>
                                            <i className="ui big camera icon"></i>
                                        </div>}
                                        {values.business_image_link !== "" && <img src={values.business_image_link} css="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;" onLoad={handleImageLoad} alt="" />}
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label>{t("profile.Business_Category")}</label>
                                <Form.Select
                                    fluid
                                    options={Categories.map((i, c) => { return { text: i.name, value: { ...i }, key: c } })}
                                    defaultValue={values.business_category}
                                    onChange={(e, { value }) => { handleInputChange({ target: { name: "business_category", value } }) }}
                                />
                            </div>
                            <div className="field">
                                <label>{t("navbar.product_off")}</label>
                                <p>{t("separate_products_listed")}</p>
                                <input value={values.service_offered} onChange={handleInputChange} type="text" name="service_offered" />
                            </div>
                            {/* <div className="field">
                                <label>{t("profile.Products_for_sale")}</label>
                                <input value={values.products_for_sale} onChange={handleInputChange} type="text" name="products_for_sale" />
                            </div> */}
                            <div className="field">
                                <label>{t("profile.shipping_policy")}</label>
                                <textarea value={values.shipping_policy} onChange={handleInputChange} type="text" name="shipping_policy" ></textarea>
                            </div>
                            <div className="field">
                                <label>{t("profile.returns_policy")}</label>
                                <p>{t("interpowered_not_refund")}</p>
                                <textarea value={values.returns_policy} onChange={handleInputChange} type="text" name="returns_policy" ></textarea>
                                {/* <Editor
                                    toolbar={{ options: ['inline', 'fontSize', 'fontFamily', 'list', 'blockType'] }}
                                    editorState={editorState_returns_policy}
                                    onEditorStateChange={setEditorState_returns_policy}
                                /> */}
                            </div>
                            <div className="field">
                                <label>{t("profile.estimated_shipping_times")}</label>
                                <textarea value={values.estimated_shipping_times} onChange={handleInputChange} type="text" name="estimated_shipping_times" ></textarea>
                                {/* <Editor
                                    toolbar={{ options: ['inline', 'fontSize', 'fontFamily', 'list', 'blockType'] }}
                                    editorState={editorState_estimated_shipping_times}
                                    onEditorStateChange={setEditorState_estimated_shipping_times}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <button type="submit" hidden ref={submitButton}></button>
                </form>

                <div css={footButtons} style={{ marginBottom: "10px" }}>
                    <button onClick={() => submitButton.current.click()} className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Save")}</button>
                    <button onClick={() => history.push("/business-profile-2")} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</button>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default BusinessProfile