import React, { useEffect, useState } from 'react';
import './App.css';
import {  
  BrowserRouter as Router,
  Switch, 
  Route, 
  useHistory,
  useLocation, 
} from "react-router-dom";
// import Login from './Login/login'
// import Register from './Register/register'
// import Client from './Register/client'
// import SellerFreelancer from './Register/seller-freelancer'
// import SellerBusiness from './Register/seller-business'
// import SellerOptional from './Register/sellerOptional'
// import RegisterInfo from './Register/register-info'
// import LoginInfo from './Login/login-info'
// import GoogleRegister from './Register/google-register'
import ForgotPassword from './Paypal/forgot-password'
import { useTranslation } from 'react-i18next'
import { Select } from 'semantic-ui-react';
import TokenValidation from './Paypal/token-validation';
import Administration from './Administration/admin_panel/administration';
import PaypalVerify from './Paypal/paypal-verify'
import PaypalVerify2 from './Paypal/paypal-verify-2'
import BusinessProfile from './Profile/business/business-profile'
import BusinessProfile2 from './Profile/business/business-products'
import BusinessSettings from './Profile/business/business-settings'
import FreelancerProfile from './Profile/freelancer/freelancer-profile'
import FreelancerCv from './Profile/freelancer/freelancer-cv'
import FreelancerPage from './Profile/freelancer page/freelancer-cv2'
import FreelancerProduct from './Profile/freelancer/freelancer-product'
import FreelancerSettings from './Profile/freelancer/freelancer-settings'
import ServicePage from './Profile/service page/service.page'
import BusinessPage from './Profile/business page/business2'
import ProductPage from './Profile/product page/product.page'
import Home from './Home/home'
import Search from './Home/search'
import RouterWrapper from './routerWrapper'
import ProposalFreelancer from './Profile/proposal-freelancer';
import ProposalBusiness from './Profile/proposal-business';
import SentProposal from './contract-management/sent-proposals';
// import ProfileBusiness from './Profile/profile-business';
// import ProductBusiness from './Profile/product-business';
import Cart from './cart/cart/cart';
import OrderDetails from './cart/orderDetails';
// import ProfileFreelancer from './Profile/profile-freelancer';
import Orders from './Administration/orders/orders';
import CustomerProfile from './Profile/customer/customer-profile';
import CustomerSettings from './Profile/customer/customer-settings';
import OrderConfirm from './cart/order-confirm';
import BusinessOrders from './Profile/business/business-orders';
import MarketingPackages from './shared/marketing-packages/marketing-packages';
import InAppNotification from './shared/marketing-packages/in-app-notification';
import SocialMedia from './shared/marketing-packages/social-media';
import Homepage from './shared/marketing-packages/homepage';
import CategoryProducts from './Home/category-products';
import MyPurchases from './shared/marketing-packages/my-purchases';
import MarketingPackagesAdmin from './Administration/admin_panel/marketing_packages/marketing_packages';
import NotificationForm from './shared/marketing-packages/notification-form';
import { useSelector } from 'react-redux';
import MessageModal from './shared/messages/messagesModal';
import CustomerOrders from './Profile/customer/customer-orders';
import ViewFavorites from './Home/view-favorites';
import ReceivedProposals from './contract-management/received-proposals';
import Timesheets from './contract-management/timesheets';
import ClosedContracts from './contract-management/closed-contracts';
import CurrentContracts from './contract-management/current-contracts';
import FreelancerOrders from './Profile/freelancer/freelancer-orders';
import WishList from './Profile/wishlist';
import SharedWishList from './Profile/shared_wishlist';
import InterestBasedAdds from './shared/interest-based-adds';
import Wiki from './shared/wiki';
import BusinessPurchasedOrders from './Profile/business/business-purchased-orders';
import FreelancerPurchasedOrders from './Profile/freelancer/freelancer-purchased-orders';
import Courses from './shared/courses';
import Course from './shared/course';
import BusinessCourses from './Profile/business/business-courses';
import GetFile from './shared/get_file';
import TermsOfService from './shared/terms-of-service';
import PrivacyPolicy from './shared/privacy-policy';
import Approvals from './shared/approvals';
import Maintenance from './shared/maintenance';
import Verify from './shared/verify';
import VerifyNewEmail from './shared/verify-new-email';

export const changeDis = () => {
  cdis()
}
let cdis = () => { };
function App() {
  const [t, i18n] = useTranslation("global")
  const [val, setval] = useState("es")
  const [dis, setDis] = useState(true)
  const { userInfo } = useSelector(state => state.user)

  const LanguageOptions = [
    { key: "en", value: "en", text: "English" },
    { key: "es", value: "es", text: "Español" }
  ]

  const handleLanguageChange = (e, { value }) => {
    i18n.changeLanguage(value)
    localStorage.setItem("languageValue", value)
  }

  cdis = () => {
    setDis(prev => !prev)
  }

  useEffect(() => {
    if(!userInfo){
      i18n.changeLanguage(window.navigator.language.substring(0,2)=="es"?"es":"en")  
    }else if(userInfo){
      i18n.changeLanguage(userInfo.id_language)  
    }
    // i18n.changeLanguage(userInfo ? userInfo.id_language : (window.navigation ? window.navigator.language.substring(0,2) : "en"))
  }, [])
  return (
    <>
      <Router>
        <RouterWrapper hideLanguage={(v) => { setDis(v) }}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/token-validation/:token">
              <TokenValidation />
            </Route>
            <Route path="/paypal-verify">
              <PaypalVerify />
            </Route>
            <Route path="/paypal-verify-2">
              <PaypalVerify2 />
            </Route>
            <Route path="/business-profile">
              <BusinessProfile />
            </Route>
            <Route path="/business-profile-2">
              <BusinessProfile2 />
            </Route>
            <Route path="/business-settings">
              <BusinessSettings />
            </Route>
            <Route path="/freelancer-profile">
              <FreelancerProfile />
            </Route>
            <Route path="/freelancer-cv">
              <FreelancerCv />
            </Route>
            <Route path="/freelancer-cv2/:id?" render={(props) => <FreelancerPage key={props.match.params.id} {...props} />} />
            <Route path="/freelancer-product">
              <FreelancerProduct />
            </Route>
            <Route path="/freelancer-settings">
              <FreelancerSettings />
            </Route>
            <Route path="/service/:id?" render={(props) => <ServicePage key={props.match.params.id} {...props} />} />
            <Route path="/product/:id?" render={(props) => <ProductPage key={props.match.params.id} {...props} />} />
            <Route path="/business/:id?" render={(props) => <BusinessPage key={props.match.params.id} {...props} />} />
            <Route path="/customer-profile" component={CustomerProfile} />
            <Route path="/customer-settings" component={CustomerSettings} />
            <Route path="/proposal-business" component={ProposalBusiness} />
            <Route path="/proposal-freelancer" component={ProposalFreelancer} />
            <Route path="/sent-proposals" component={SentProposal} />
            <Route path="/received-proposals" component={ReceivedProposals} />
            <Route path="/current-contracts" component={CurrentContracts} />
            <Route path="/timesheets" component={Timesheets} />
            <Route path="/closed-contracts" component={ClosedContracts} />
            <Route path="/cart" component={Cart} />
            <Route path="/order-details" component={OrderDetails} />
            <Route path="/orders" component={Orders} />
            <Route path="/order-confirm" component={OrderConfirm} />
            <Route path="/business-orders" component={BusinessOrders} />
            <Route path="/business-purchased-orders" component={BusinessPurchasedOrders} />
            <Route path="/freelancer-orders" component={FreelancerOrders} />
            <Route path="/freelancer-purchased-orders" component={FreelancerPurchasedOrders} />
            <Route path="/marketing-packages/in-app-notifications" component={InAppNotification} />
            <Route path="/marketing-packages/social-media" component={SocialMedia} />
            <Route path="/marketing-packages/homepage" component={Homepage} />
            <Route path="/marketing-packages/mypurchases" component={MyPurchases} />
            <Route path="/marketing-packages" component={MarketingPackages} />
            <Route path="/administration/marketing-packages" exact component={MarketingPackagesAdmin} />
            <Route path="/administration" exact component={Administration} />
            <Route path="/category-products" component={CategoryProducts} />
            <Route path="/notification-form" component={NotificationForm} />
            <Route path="/customer-orders" component={CustomerOrders} />
            <Route path="/favorites" component={ViewFavorites} />
            <Route path="/wishlist" component={WishList} />
            <Route path="/shared_wishlist" component={SharedWishList} />
            <Route path="/interest-based-ads" component={InterestBasedAdds} />
            <Route path="/wiki" component={Wiki} />
            <Route path="/courses" component={Courses} />
            <Route path="/course" component={Course} />
            <Route path="/business-courses" component={BusinessCourses} />
            <Route path="/getFile" component={GetFile} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/approvals" component={Approvals} />
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/verify" component={Verify} />
            <Route path="/verify-new-email" component={VerifyNewEmail} />
            <Route path="/search/:term?" render={(props) => <Search {...props} key={Date.now()} />} /*component={Search}*/ />
          </Switch>
        </RouterWrapper>
      </Router>
      <MessageModal />
    </>
  );
}

 
export default App;
