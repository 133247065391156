import React, { useEffect, useMemo, useState } from 'react'
import Navbar from '../../shared/navbar/navbar'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import 'styled-components/macro'
import { Button, Dimmer, Label, Loader, Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DetailsTab from './details.tab';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import axios from '../../axios';
import Footer from '../../shared/footer/footer';
import ReviewsTab from './reviews.tab'
import { useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { Link } from 'react-router-dom';
import PortfolioTab from './portfolio.tab'
import { url_environment } from '../../config';
import countries, { languages } from '../countries';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const isImage = (name) => {
    const arrext = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp"]
    let extension = name.split(".")
    extension = extension[extension.length - 1]
    return arrext.includes(extension)
}
export const getImage = (product) => {
    if (product.includes("uploads/")) {
        return url_environment + product
    } else {
        return product
    }

}

const ServicePage = () => {
    const match = useRouteMatch()
    const serviceId = match.params.id
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({locations:[],languages:[]})
    const [images, setImages] = useState([])
    const dispatch = useDispatch()
    const query = useQuery()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const panes = [
        {
            menuItem: t("profile.Details"), render: () => <Tab.Pane>
                <DetailsTab data={values} />
            </Tab.Pane>
        },
        {
            menuItem: t("profile.Reviews"), render: () => <Tab.Pane>
                <ReviewsTab id={serviceId} />
            </Tab.Pane>
        },
        {
            menuItem: t("profile.Portfolio"), render: () => <Tab.Pane >
                <PortfolioTab files={values.product.images.filter(x => x)} />
            </Tab.Pane>
        },
    ]

    useEffect(() => {
        loadData()
    }, [])

    const handleProposalClick = () => {
        history.push({
            pathname: "/proposal-freelancer",
            state: {
                vendor: values.vendor,
                service: values.product,
                create: true
            }
        })
    }
    
    const loadData = async () => {
        try {

            const { data } = await axios.post(`/api/product/select/${serviceId}`, { type: "freelancer" })
            setValues(data)
            let imgs = data.product.images.filter(x => {
                return isImage(x)
            }).filter(x => x)
            imgs = imgs.map(x => { return { original: getImage(x), originalClass: "customReactGalleryClass", thumbnail: getImage(x), thumbnailClass: "customThumbnailReactGalleryClass" } })
            setImages(imgs)
            setLoading(false)
        } catch (e) {
            alert(e)
        }
    }

    const handleMessageSeller = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { ...values, subject: "About service", receiver_type: "freelancer", link: `/service/${serviceId}` } }))
    }

    const vendor_languages=useMemo(()=>{
        const languages_array=languages
        return values?.languages?.map((language)=>{
            const language_name=languages_array.find(l=>l.key==language)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {t("profile."+language_name.toLowerCase())}
            </Label>
        </div>
        })
    },[values])

    const vendor_locations=useMemo(()=>{
        const locations_array=[{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
        return values?.locations?.map((location)=>{
            const location_name=locations_array.find(l=>l.key==location)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {location_name.trim()}
            </Label>
        </div>
        })
    },[values])

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }


    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1400px;padding-left:36px;padding-right:36px;margin-left:auto;margin-right:auto">
                    <div css="display:flex;flex-wrap:wrap;">
                        <div css="padding-right:20px;flex-shrink:0;flex:0 0 auto;width:65%;@media(max-width:768px){width:100%;}">
                            {images.length > 0 && <ImageGallery showPlayButton={false} showBullets={false} autoPlay={false} showFullscreenButton={true} thumbnailPosition="left" items={images} />}
                            <Tab css="margin-top:2rem;" panes={panes} defaultActiveIndex={query.get("review") == "true" ? 1 : 0} />
                        </div>
                        <div css="padding-left:20px;flex-shrink:0;flex:0 0 auto;width:35%;@media(max-width:768px){width:100%;margin-top:20px;}">
                            <Link to={`/freelancer-cv2/${values.vendor._id}`}><img src={`${url_environment}${values.vendor.freelancer_picture}`} css="margin-bottom:1.5rem;width:150px;height:150px;border-radius:100px;display:block;object-fit:cover;object-position:center;" alt="Profile Image" /></Link>
                            <p><b>{t('profile.Name')}: <Link style={{ textDecoration: "underline", color: "rgba(0,0,0,.87)" }} to={`/freelancer-cv2/${values.vendor._id}`}>{values.vendor.freelancer_name}</Link></b></p>
                            <p><b>{t('profile.Category')}: <span css="white-space:pre-wrap;">{values.product.category_name}</span></b></p>
                            <p><b>{t('profile.Services')}: </b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                {values.vendor.products_or_services.split(",").map(x => {
                                    return <div css="padding:0.3rem;">
                                        <Label circular color="teal">
                                            {x.trim()}
                                        </Label>
                                    </div>
                                })}
                            </div>
                            <p><b></b></p>
                            <p><b>{t('profile.Rate/Hr')}</b></p>
                            <p><b>{t("product.languages")}:</b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_languages}
                            </div>
                            <p><b>{vendor_locations?.length>1?t("product.locations"):t("product.location")}: </b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_locations}
                            </div>
                            <h3><b>{t('profile.Service_Name')}: {values.product.service_name}</b></h3>
                            <p><b>{t('profile.Hourly_Rate')}: USD ${values.product.hourly_rate}</b></p>
                            <p><b>{t('profile.Service_Category')} : {values.product.category_name}</b></p>
                            <div css="display:flex;flex-direction:column;justify-content:space-between;height:80px;max-width:240px;margin-top:1rem;margin-bottom:1rem;">
                                <Button onClick={handleProposalClick} circular color="teal" css="background:#008081 !important;">{t('profile.Submit_a_proposal')}</Button>
                                <Button onClick={handleMessageSeller} circular color="teal">{t('profile.Message_Freelancer')}</Button>
                            </div>
                            <p><b>{t('profile.Timezone')}: {values.product.timezone}</b></p>
                            <p><b>{t('profile.Availability_2')}:</b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.availability.includes("Full time")} type="checkbox" id="cb-Full" /><label htmlFor="cb-Full" css="margin-left:0.3rem;">{t('profile.Full_time')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.availability.includes("Part time")} type="checkbox" id="cb-Part" /><label htmlFor="cb-Part" css="margin-left:0.3rem;">{t('profile.Part_time')}</label>
                                </div>
                            </div>

                            <p><b>{t('profile.Available_Days_2')}:</b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Monday")} type="checkbox" id="cb-Monday" /><label htmlFor="cb-Monday" css="margin-left:0.3rem;">{t('profile.Monday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Tuesday")} type="checkbox" id="cb-Tuesday" /><label htmlFor="cb-Tuesday" css="margin-left:0.3rem;">{t('profile.Tuesday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Wednesday")} type="checkbox" id="cb-Wednesday" /><label htmlFor="cb-Wednesday" css="margin-left:0.3rem;">{t('profile.Wednesday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Thursday")} type="checkbox" id="cb-Thrusday" /><label htmlFor="cb-Thrusday" css="margin-left:0.3rem;">{t('profile.Thursday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Friday")} type="checkbox" id="cb-Friday" /><label htmlFor="cb-Friday" css="margin-left:0.3rem;">{t('profile.Friday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Saturday")} type="checkbox" id="cb-Saturday" /><label htmlFor="cb-Saturday" css="margin-left:0.3rem;">{t('profile.Saturday')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_days.includes("Sunday")} type="checkbox" id="cb-Sunday" /><label htmlFor="cb-Sunday" css="margin-left:0.3rem;">{t('profile.Sunday')}</label>
                                </div>
                            </div>

                            <p><b>{t('profile.Available_Times_2')}:</b></p>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_times.includes("Early")} type="checkbox" id="cb-Early" /><label htmlFor="cb-Early" css="margin-left:0.3rem;">{t('profile.Early_morning')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_times.includes("Morning")} type="checkbox" id="cb-Morning" /><label htmlFor="cb-Morning" css="margin-left:0.3rem;">{t('profile.Morning')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_times.includes("Afternoon")} type="checkbox" id="cb-Afternoon" /><label htmlFor="cb-Afternoon" css="margin-left:0.3rem;">{t('profile.Afternoon')}</label>
                                </div>
                                <div css="padding:0.3rem;display:flex;align-items:center">
                                    <input checked={values.product.available_times.includes("Evening")} type="checkbox" id="cb-Evening" /><label htmlFor="cb-Evening" css="margin-left:0.3rem;">{t('profile.Evening')}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default ServicePage