import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../navbar/navbar'
import Footer from '../footer/footer'
import { Button, Card, Dimmer, Form, Input, Loader } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import axios from '../../axios'
import 'styled-components/macro'
import { useLocation } from 'react-router'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import env from '../../config.env'
const {paypal_client_id}=env

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Homepage = () => {
    const [loading, setLoading] = useState(true)
    const [paypal, setPaypal] = useState(false)
    const [successful, setSuccessful] = useState(false)
    const [day, setDay] = useState("")
    const [date, setDate] = useState("")
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState()
    const { userInfo } = useSelector(state => state.user)
    let query = useQuery()
    const type = query.get("type")
    const opt = query.get("opt")
    const price = useRef(0)
    const platforms = useRef([{ key: 1, text: "Facebook", value: "facebook" }, { key: 2, text: "Instagram", value: "instagram" }])
    const handleProductChange = (e, { value }) => {
        setSelectedProduct(value)
    }
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const loadData = async () => {
        const prod = query.get("product")
        setLoading(true)
        try {
            let { data: products } = await axios.get("/api/product/my", config)
            products = products.map((x) => { return { key: x._id, text: (x.name || x.service_name), value: x._id } })
            if (prod) { setSelectedProduct(prod) }
            setProducts(products)
            if (type == "day") {
                if (opt == "1") {
                    price.current = 1.99
                } else if (opt == "2") {
                    price.current = 2.99
                }
            } else if (type == "week") {
                if (opt == "1") {
                    price.current = 4.99
                } else if (opt == "2") {
                    price.current = 7.99
                }
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    const today = useRef((() => {
        const d = new Date()
        d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
        return d.toISOString().slice(0, 10)
    })())
    const handleDayChange = (e) => {
        let dat = e.target.value
        let d = "";
        const da = new Date()
        const daf = da.getFullYear() + '-' + pad(da.getMonth() + 1) + '-' + pad(da.getDate())
        if (dat === daf) {
            //alert("today!")
            d = da.toISOString()
        } else {
            const date = new Date(dat)
            date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            d = date.toISOString()
        }
        const value = dat
        const date = new Date(value).toISOString().slice(0, 10)
        if (date < today.current) {
            alert("error day");
            return
        }
        console.log(d)
        setDay(e.target.value)
        setDate(d)
    }
    const handleSubmitClick = () => {
        if (selectedProduct == "" || day == "") {
            alert("Please fill all fields!")
            return
        }
        setPaypal(true)
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>
            <Navbar />
            <main>

                <div css="max-width:1200px;margin:0 auto; padding:0 36px;">
                    <h2 css="text-align:center;">Interpowered homepage package</h2>
                    {!paypal && <div css="margin:0 auto;padding:10px;max-width:600px;">
                        <div className="ui form">
                            <Form.Select
                                label="Product"
                                options={products}
                                onChange={handleProductChange}
                                value={selectedProduct}
                            />
                            <div>
                                <Form.Input
                                    type="date"
                                    label="Select day"
                                    onChange={handleDayChange}
                                    value={day}
                                />
                            </div>
                            <div css="margin-top:10px;">
                                <Button circular color="teal" onClick={handleSubmitClick}>Submit</Button>
                            </div>
                        </div>
                    </div>}
                    {paypal && !successful && <div css="max-width:300px;margin:20px auto;">
                        <PayPalButton
                            createOrder={async (data, actions) => {
                                const { data: oid } = await axios.post(`/api/paypal/marketinghomepage/create_order`,
                                    {
                                        selectedProduct,
                                        day: date,
                                        type,
                                        opt
                                    }
                                    , config)
                                return oid
                            }}
                            onApprove={async (data, actions) => {
                                try {
                                    await axios.post("/api/paypal/marketinghomepage/capture", {
                                        orderID: data.orderID,
                                    }, config)
                                    setSuccessful(true)
                                    return
                                } catch (e) {
                                    console.log(e)
                                    alert("There was an error, please contact the administrator. PAYPAL_ORDER_ID:" + data.orderID)
                                }
                            }}
                            onError={(e) => alert(e)}
                            options={{
                                clientId: paypal_client_id
                              }}
                        />
                        {/* <PayPalButton
                        amount={price.current}
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={async (details, data) => {
                            const { data: result } = await axios.post("/api/paypal/marketinghomepage", { order_id: data.orderID, opt, type, selectedProduct, day:date }, config)
                            setSuccessful(true)
                        }}
                    /> */}
                    </div>}
                    {successful &&
                        <div css="max-width:300px;margin:20px auto;">
                            <h3 css="text-align:center;">Transaction completed</h3>
                            <Link to="/" className="ui teal circular fluid button">Return to home page</Link>
                        </div>
                    }
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Homepage