import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../axios'
import { useTranslation } from 'react-i18next';
export default () => {
    const [loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.user)
    const [count, setCount] = useState(0)
    const [t, i18n] = useTranslation("global")

    const loadData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.get("/api/user/notification/count", config)
            setCount(data.notifications)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])
    return {t,count,loading}

}