
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Dimmer, Form, Loader, Select, Tab, List, Label } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import TabCV from './tabcv'
import TabCatalog from './tabCatalog'
import Navbar from '../../shared/navbar/navbar'
import Footer from '../../shared/footer/footer'
import ReviewsTab from './reviews.tab'
import { uiActions } from '../../store/ui-slice'
import { useDispatch } from 'react-redux'
import { url_environment } from '../../config'
import countries, { languages } from '../countries'



const Freelancecv = () => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(true)
    const [ratecount, setratecount] = useState(false)
    const [values, setValues] = useState({
        freelancers_name: "",
        category_name: "",
        bio: "",
        address: "",
        hourly_rate: "",
        freelancer_category: "",
        career: "",
        products_or_services: "",
        experience: "",
        skills: "",
        languagues: "",
        duties_and_titles: "",
        certifications: "",
        degrees: "",
        link_to_cv: "",
        link_to_portfolio: "",
        additional_info: "",
        image: [],
        locations:[]

    })



    const [t, i18n] = useTranslation("global")
    const loadData = async () => {
        try {
            setLoading(true)
            if (!id) { history.push("/"); return }
            let { data: freelancerData } = await axios.get("/api/freelancer/" + id)
            setratecount(freelancerData.avgrate)
            setValues(prev => {
                return {
                    ...prev,

                    freelancers_name: freelancerData.freelancer_name,
                    category_name: freelancerData.category_name,
                    bio: freelancerData.bio,
                    address: freelancerData.address,
                    career: freelancerData.career,
                    products_or_services: freelancerData.products_or_services,
                    product_categories: freelancerData.product_categories,
                    experience: freelancerData.experience,
                    skills: freelancerData.skills,
                    languagues: freelancerData.languagues,
                    duties_and_titles: freelancerData.duties_and_titles,
                    certifications: freelancerData.certifications,
                    degrees: freelancerData.degrees,
                    link_to_cv: freelancerData.url_cv,
                    link_to_portfolio: freelancerData.url_portfolio,
                    additional_info: freelancerData.additional_info,
                    images: freelancerData.images,
                    freelancer_picture: freelancerData.freelancer_picture,
                    id_user: freelancerData.id_user,
                    languages:freelancerData.languages,
                    locations:freelancerData.locations

                }
            })
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }
    const handleMessageSeller = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...values }, subject: "Request a service", receiver_type: "freelancer" } }))
    }

    useEffect(() => {
        loadData()
    }, [])
    const history = useHistory()
    
    const panes = [
        {
            menuItem: 'CV', render: () => <Tab.Pane>
                <TabCV values={values} />
            </Tab.Pane>
        },
        {
            menuItem: t("profile.Catalog"), render: () => <Tab.Pane >
                <TabCatalog freelancer={{ freelancerId: id, ...values }} />
            </Tab.Pane>
        },
        {
            menuItem: t("profile.Reviews"), render: () => <Tab.Pane>
                <ReviewsTab id={id} />
            </Tab.Pane>
        },
    ]

    const vendor_languages=useMemo(()=>{
        const languages_array=languages
        return values?.languages?.map((language)=>{
            const language_name=languages_array.find(l=>l.key==language)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {t("profile."+language_name.toLowerCase())}
            </Label>
        </div>
        })
    },[values])

    const vendor_locations=useMemo(()=>{
        const locations_array=[{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" },...countries]
        return values?.locations?.map((location)=>{
            const location_name=locations_array.find(l=>l.key==location)?.text||""
            return <div css="padding:0.3rem;">
            <Label as="span" circular css="background:teal !important;padding:0.25rem 0.5rem !important; border-radius:100px !important;color:white;display:flex;">
                {location_name.trim()}
            </Label>
        </div>
        })
    },[values])

    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }


    return (
        <>
            <Navbar />
            <main>

                <div className="ui grid container" style={{ marginTop: "15px" }}>
                    <div className="sixteen wide mobile six wide tablet three wide computer column" >
                        <div>
                            <img css="width:150px;height:150px;border-radius:50%;object-fit:cover;object-position:center;display:block;" src={url_environment + "/" + (values.freelancer_picture && (values.freelancer_picture.indexOf("uploads") != -1 ? values.freelancer_picture.split("/uploads/")[1] : values.freelancer_picture))} alt="" />
                        </div>
                    </div>
                    <div className="sixteen wide mobile nine wide computer column">
                        <div>
                            <label css="font-weight:700;">{t("profile.Freelancer's_name")}: </label>
                            <span>{values.freelancers_name}</span>
                        </div>
                        <div>
                            <label css="font-weight:700;">{t("profile.Freelancer_Category")}: </label>
                            <span>{values.category_name} ,{values.product_categories.map(x => x.name).join(", ")}</span>
                        </div>
                        <div>
                            <label css="font-weight:700;">{t("profile.Product/services")}: </label>
                            {values.products_or_services && values.products_or_services.split(",").map((se) => {
                                return <>
                                    <span style={{ marginBottom: "10px" }} className="ui basic label">{se.trim()}</span>
                                </>
                            })}

                        </div>
                        <div>
                            <label css="font-weight:700;">{t("profile.Rate/hr")}: </label>
                            <span>USD ${parseFloat(ratecount).toFixed(2)}</span>
                        </div>
                        <div>
                            <label css="font-weight:700;" >{t("profile.Languages")}: </label>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_languages}
                            </div>
                        </div>
                        <div>
                            <label css="font-weight:700;" >{vendor_locations?.length>1?t("profile.Locations"):t("profile.Location")}: </label>
                            <div css="display:flex;flex-wrap:wrap;margin-left:-0.3rem;margin-right:-0.3rem;">
                            {vendor_locations}
                            </div>
                        </div>
                    </div>
                    <div className=" ten wide mobile four wide computer  column" >

                        <div> <button onClick={handleMessageSeller} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                            {t("profile.Direct_Message")}</button>
                        </div>

                    </div>

                    <div className="sixteen wide column" ><Tab panes={panes} defaultActiveIndex={0} /></div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Freelancecv

