import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Image, List, Loader, Search } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { cleanSubject, getTimes, getTimex, url_environment } from '../../config'
import axios from '../../axios'
import { uiActions } from '../../store/ui-slice'
const UnreadMessages = () => {
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const [sendActive, setSendActive] = useState(false)
    const [messages, setMessages] = useState([])
    const [fmessages, setFMessages] = useState([])
    const { userInfo } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const loadData = async () => {
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            const { data } = await axios.get("/api/user/messages/unread", config)
            setMessages(data)
            setFMessages(data)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])
    const handleMessageClick = async (x) => {
        try {
            if (sendActive) {
                setLoading(true)
                // let {data:vendor}=await axios.get("/api/user/getStoreInfo/"+x.sender._id)
                dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { vendor: { ...x.sender }, subject: x.subject,editable_subject:true } }))
                return
            }
            dispatch(uiActions.change_action({ open: true, action: "view message", view_message_data: { ...x, return: "unread" } }))
        } catch (e) { alert(e) }
        setLoading(false)
    }
    // const loadData=async ()=>{
    //     try{

    //     }catch(e){alert(e)}
    // }
    const handleFilterChange = (e) => {
        let value = e.target.value
        value = value.toLowerCase()
        const fm = messages.filter(x => {
            let name = (x.sender.id_role == "1" || x.sender.id_role == "4") ? x.sender.name : x.sender.store_name
            name = name.toLowerCase()
            let subject = x.subject
            subject = subject.toLowerCase()
            return name.includes(value) || subject.includes(value)
        })
        setFMessages(fm)
    }
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    if (messages.length === 0) {
        return <>
            <h2 css="margin:20px auto;text-align:center;">{t("messages.no_messages")}</h2>
        </>
    }
    return (
        <>
            <Search
                fluid
                className="customSearchInputClass"
                showNoResults={false}
                placeholder={t("messages.search_messages") + "..."}
                onSearchChange={handleFilterChange}
            />
            <div css="margin:10px 0;text-align:center;">
                <Button active={sendActive} onClick={() => { setSendActive(p => !p) }} toggle color="teal" circular>{t("messages.compose_message")}</Button>
            </div>
            {sendActive && <p>{t("messages.select_send_message")}</p>}
            <List divided selection relaxed>
                {fmessages.map(x => {
                    return (
                        <List.Item onClick={() => handleMessageClick(x)}>
                            <Image avatar css="object-fit:cover !important;object-position:center !important;" src={`${url_environment}${x.sender.customer_picture}`} />
                            <List.Content>
                                <List.Header css="color:#777 !important;">{(x.sender.id_role == "1" || x.sender.id_role == "4") ? x.sender.name : x.sender.store_name}</List.Header>
                                <List.Description css="color:rgb(2,183,178) !important;">{cleanSubject(x.subject)}</List.Description>
                            </List.Content>
                            <span css="float:right;">{getTimex(x.created_at)}</span>
                        </List.Item>
                    )
                })}
            </List>
        </>
    )
}

export default UnreadMessages