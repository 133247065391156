import React, { useEffect, useRef, useState } from 'react'
import { Button, Divider, Form, Icon, Message, Modal } from 'semantic-ui-react'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import userSlice, { login, logout, userActions,loginWithToken } from '../../store/user-slice'
import Messages from './messages'
import NewMessage from './newMessage'
import ReportProblem from './report_problem'
import { uiActions } from '../../store/ui-slice'
import ViewMessage from './viewMessage'


const MessageModal = () => {
    const dispatch = useDispatch()
    const {uiMessagesModal}=useSelector(state=>state.ui)
    console.log("uiMessagesModal: ", uiMessagesModal)
    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={() => {dispatch(uiActions.change_action({open:false,action:""}))}}
                open={uiMessagesModal.open}
                size="tiny"
                className="customMessageModalClass"
                closeIcon

            >
                <Modal.Content>

                    {
                        (() => {
                            switch (uiMessagesModal.action) {
                                case "new message":
                                    return <NewMessage data={uiMessagesModal.receiver}/>
                                case "report a problem":
                                    return <ReportProblem/>
                                case "messages":
                                    return <Messages return={uiMessagesModal.return}/>
                                case "view message":
                                    return <ViewMessage data={uiMessagesModal.view_message_data}/>
                            }
                        })()
                    }
                </Modal.Content>
            </Modal>
        </>
    )
}


export default MessageModal