import React, { useRef } from 'react'
import { Button, Modal, Table } from 'semantic-ui-react'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OrderDetails = (props) => {
    const order = props.data
    const order_date = (new Date(order.order_date)).toLocaleString()
    const br = useRef()
    const [t, i18n] = useTranslation("global")
    if (Object.keys(order).length == 0) {
        return <></>
    }

    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={() => props.setClose()}
                open={props.open}
                size="large"
            >
                <Modal.Header>{t("orders.order")} {order.order_id}</Modal.Header>
                <Modal.Content>
                    <div><b>{t("orders.paypal_order_id")}:</b> {order.order_id}</div>
                    <div><b>{t("orders.user_id")}:</b> {order.id_user._id}</div>
                    <div><b>{t("orders.user_name")}:</b> {order.id_user.name}</div>
                    <div><b>{t("orders.paypal_email")}:</b> {order.payer_email}</div>
                    <div><b>{t("orders.paypal_payer_name")}:</b> {order.payer_name}</div>
                    <div><b>{t("orders.total")}:</b>USD ${order.order_amount}</div>
                    <div><b>{t("orders.date")}:</b> {order_date}</div>
                    
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("orders.product_name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.contract_id")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.vendor_name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.price")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.discount")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.taxes")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.shipping")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.quantity")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("orders.total")}</Table.HeaderCell>
                                {/* <Table.HeaderCell collapsing>{t("orders.tracking_number")}</Table.HeaderCell> */}
                                <Table.HeaderCell collapsing></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {order.order_items.map((x, c) => {
                                const product_link=`/${x.vendor_type=="Business" ? "product" : "service"}/${x.product}`
                                return (
                                    <Table.Row key={x._id}>
                                        <Table.Cell><Link to={product_link}>{x.product_name}</Link></Table.Cell>
                                        <Table.Cell>{x.contract_number || "-"}</Table.Cell>
                                        <Table.Cell>{x.vendor_name}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.current_price).toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.discount).toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.total_tax).toFixed(2)}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.shipping).toFixed(2)}</Table.Cell>
                                        <Table.Cell>{x.quantity}</Table.Cell>
                                        <Table.Cell>USD ${parseFloat(x.total_price).toFixed(2)}</Table.Cell>
                                        {/* <Table.Cell>{x.trackingNumber || "-"}</Table.Cell> */}
                                        <Table.Cell><Link to={product_link+"?review=true"}>Review</Link></Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                    <div><span></span></div>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default OrderDetails