import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Dimmer, Dropdown, Icon, Loader, Rating } from 'semantic-ui-react'
import 'styled-components/macro'
import Footer from '../shared/footer/footer'
import Navbar from '../shared/navbar/navbar'
import category_products_styles from './category-products.style'
import placeholder from '../img/photo_placeholder.svg'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { userActions } from '../store/user-slice'
import { Trans, useTranslation } from 'react-i18next'
import { url_environment } from '../config'
import { uiActions } from '../store/ui-slice'
import { useGetLocationName, useGetProductLanguages } from '../shared/custom_hooks'
import countries from '../Profile/countries'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const getImage = (product) => {
    if (product) {
        if (product.includes("uploads/")) {
            return url_environment + product
        } else {
            return product
        }
    } else {
        return placeholder
    }
    if (product.business_picture) {
        if (product.business_picture.includes("uploads/")) {
            return url_environment + product.business_picture
        } else {
            return product.business_picture
        }
    }
    else if (product.freelancer_picture) {
        if (product.freelancer_picture.includes("uploads/")) {
            return url_environment + product.freelancer_picture
        } else {
            return product.freelancer_picture
        }
    }
    else if (product.images && product.images[0]) {
        if (product.images[0].includes("uploads/")) {
            return url_environment + product.images[0]
        } else {
            return product.images[0]
        }
    }
    else {
        return placeholder
    }
}

const CategoryProducts = () => {
    const [t, i18n] = useTranslation("global")
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [fProducts, setFProducts] = useState([])
    const [fProductsBackup, setFProductsBackup] = useState([])
    const [products, setProducts] = useState([])
    const [productsBackup, setProductsBackup] = useState([])
    const [categoryData, setCategoryData] = useState({ name: "" })
    const [lowPrice, setLowPrice] = useState("")
    const [highPrice, setHighPrice] = useState("")
    const [languageFilter, setLanguageFilter] = useState([])
    const [locationFilter, setLocationFilter] = useState("")

    const getLocationName = useGetLocationName()
    const getProductLanguages = useGetProductLanguages()

    let query = useQuery()
    const cat_name = query.get("cat_name")
    const vendor_type=query.get("vendor_type")
    const dispatch = useDispatch()
    const loadData = async () => {
        handleClear()
        setLoading(true)
        try {
            const { data: { first, second, category_data } } = await axios.post("/api/paypal/marketinghomepage-category", {
                id_cat: query.get("id_cat"),
                vendor_type: query.get("vendor_type")
            })
            setFProducts(first)
            setFProductsBackup(first)
            setProducts(second)
            setProductsBackup(second)
            if (category_data)
                setCategoryData(category_data)
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    const handleFavoriteClick = async (e) => {
        const id = e.target.getAttribute("data-product-id")
        const type = e.target.getAttribute("data-type")
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            if (id.endsWith(" remove")) {
                const rid = id.split(" ")[0]
                await axios.delete("/api/user/favorite/" + rid, config)
            } else {
                await axios.post("/api/user/favorite", { id, type }, config)
            }
            dispatch(userActions.updateFavorites({ id, type }))
        } catch (e) { alert(e) }
    }
    useEffect(() => {
        console.log("render")
        loadData()
    }, [query.get("id_cat")])

    const handleApply = () => {
        const pi = parseInt
        const low_price = pi(lowPrice)
        const high_price = pi(highPrice)
        let ffiltered=fProductsBackup,filtered=productsBackup;
        if (((lowPrice == "") && (highPrice == "")) || (low_price > high_price)) {
        } else{
            ffiltered = ffiltered.filter((product) => {
                const price = product.sale_price || product.hourly_rate
                if (price >= low_price && price <= high_price) {
                    return true
                } else {
                    return false
                }
            })
            filtered = filtered.filter((product) => {
                const price = product.sale_price || product.hourly_rate
                if (price >= low_price && price <= high_price) {
                    return true
                } else {
                    return false
                }
            })
        }
        if (languageFilter.length > 0){
            ffiltered = ffiltered.filter((product) => {
                const product_languages = product.languages || []
                if (product_languages.find(language => languageFilter.includes(language)))
                return true;
                else return false;
            })
            filtered = filtered.filter((product) => {
                const product_languages = product.languages || []
                if (product_languages.find(language => languageFilter.includes(language)))
                return true;
                else return false;
            })
        }
        if (locationFilter !== ""){
            ffiltered = ffiltered.filter((product) => {
                return product.location === locationFilter
            })
            filtered = filtered.filter((product) => {
                return product.location === locationFilter
            })
        }
        setFProducts(ffiltered)
        setProducts(filtered)
    }
    const handleClear = () => {
        setFProducts(fProductsBackup)
        setProducts(productsBackup)
        setLowPrice("")
        setHighPrice("")
        setLanguageFilter([])
        setLocationFilter("")
    }

    const handleRegisterClick = (e) => {
        e.preventDefault()
        if (userInfo.id_role == "1") {
            alert(t("navbar.already_registered"));
            return;
        }
        if (userInfo.id_role == "2") {
            alert(t("navbar.already_registered"));
            return;
        }
        if (userInfo.id_role == "3") {
            alert(t("navbar.already_registered"));
            return;
        }
        dispatch(uiActions.change_action_sign_in({ open: true, action: "register" }))
    }

    const handleCheckboxChange = (name, checked, value) => {
        setLanguageFilter(prev => {
            if (checked) {
                return [...prev, value]
            } else {
                return prev.filter(i => i !== value)
            }
        })
    }

    const locations = useMemo(() => {
        return [{ key: "RW", text: t("profile.remote_worldwide"), value: "RW" }, ...countries]
    }, [])

    if (loading) return <>
        <Dimmer active inverted>
            <Loader />
        </Dimmer>
    </>
    //{t("product.category")}: 
    return (
        <>
            <Navbar />
            <main>

                <div css={category_products_styles}>
                    <h2><span css="white-space:pre-wrap;">{categoryData.name}</span></h2>
                    <div className="ui form">
                        <div style={{ justifyContent: "flex-start", display: "flex", flexWrap: "wrap" }}>
                            <div style={{ margin: "0 1em" }} className="field">
                                <label>{t("navbar.filter_by_price")}</label>
                                <div className="inline fields">
                                    <div className="field">
                                        <input value={lowPrice} onChange={(e) => { setLowPrice(e.target.value) }} type="text" placeholder={t("product.low")} />
                                    </div>
                                    <div className="field">
                                        <label>to</label>
                                    </div>
                                    <div className="field">
                                        <input value={highPrice} onChange={(e) => { setHighPrice(e.target.value) }} type="text" placeholder={t("product.high")} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ justifyContent: "flex-start", display: "flex", flexWrap: "wrap" }}>
                            <div style={{ margin: "0 1em" }} className="field">
                                <label>{t("navbar.filter_by_language")}</label>
                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;margin-left:-2px;margin-right:-2px;">
                                    <div css="flex:0 0 auto;padding:8px;display:flex;align-items:center;">
                                        <input checked={languageFilter.indexOf("English") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="English" type="checkbox" name="languages" id="english-language-radio" /><label css="margin-left:5px;" htmlFor="english-language-radio">{t("profile.english")}</label>
                                    </div>
                                    <div css="flex:0 0 auto;padding:8px;display:flex;align-items:center;">
                                        <input checked={languageFilter.indexOf("Spanish") !== -1} onChange={(e) => { handleCheckboxChange(e.target.name, e.target.checked, e.target.value) }} value="Spanish" type="checkbox" name="languages" id="spanish-language-radio" /><label css="margin-left:5px;" htmlFor="spanish-language-radio">{t("profile.spanish")}</label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0 1em" }} className="field">
                                <label>{t("navbar.filter_by_location")}</label>
                                <div className="inline fields">
                                    <Dropdown
                                        style={{ width: "200px" }}
                                        selectOnBlur={false}
                                        selection
                                        fluid
                                        options={locations}
                                        value={locationFilter}
                                        onChange={(e, { value }) => { setLocationFilter(value) }}
                                    />
                                </div>
                            </div>
                            <div style={{ margin: "0 1em" }} className="field" style={{ display: "flex", alignItems: "flex-end" }}>
                                <Button style={{ marginBottom: "1em" }} content={t("navbar.apply")} onClick={handleApply} color="black" />
                                <Button style={{ marginBottom: "1em", marginLeft: "1em" }} content={t("navbar.clear")} onClick={handleClear} color="black" />
                            </div>
                        </div>
                    </div>
                    {fProducts.length > 0 && <div className="dailyWrapper" style={{ paddingBottom: "10px", borderBottom: "1px solid #dfdfdf", marginBottom: "10px" }}>
                        {
                            fProducts && fProducts.map((i) => {
                                return <div key={i._id} className="imgWrapper">
                                    {
                                        (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                            <div className="heartFillClass">
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                            </div>
                                        ) : (userInfo &&
                                            <div className="heartOutlineClass heartBtn">
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                            </div>
                                        )
                                    }
                                    <Link to={`${i.service_name ? "/service/" + i._id : "/product/" + i._id}`} css="color:#000; &:hover{color:#000;}" href="">
                                        <div css="padding-bottom:80%;position:relative;">
                                            <img className="bigImg" src={getImage(i.images[0])} alt="" />
                                        </div>
                                        <div>
                                            <h3 className="h3Text">{i.service_name || i.name}</h3>
                                            <p css="margin:0;">{i.vendor_name}</p>
                                            <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                            <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                            <p css="margin:0;">USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(i.volume_pricing.length < 2 ? i.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + i.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>}</p>
                                            {i.location && <p css="margin:0;">{getLocationName(i.location)}</p>}
                                            {i.languages?.length > 0 && <p css="margin:0;">{getProductLanguages(i.languages)}</p>}
                                        </div>
                                    </Link>
                                </div>
                            })
                        }
                    </div>}
                    <div className="dailyWrapper">
                        {
                            products && products.map((i) => {
                                return <div key={i._id} className="imgWrapper">
                                    {
                                        (userInfo && userInfo.favorites && userInfo.favorites.findIndex(x => x.id == i._id) != -1) ? (
                                            <div className="heartFillClass">
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id + " remove"} onClick={handleFavoriteClick} color="red" name="heart" />
                                            </div>
                                        ) : (userInfo &&
                                            <div className="heartOutlineClass heartBtn">
                                                <Icon css="margin:0 !important;cursor:pointer;" data-type="product" data-product-id={i._id} onClick={handleFavoriteClick} color="red" name="heart outline" />
                                            </div>
                                        )
                                    }
                                    <Link to={`${i.service_name ? "/service/" + i._id : "/product/" + i._id}`} css="color:#000; &:hover{color:#000;}" href="">
                                        <div css="padding-bottom:80%;position:relative;">
                                            <img className="bigImg" src={getImage(i.images[0])} alt="" />
                                        </div>
                                        <div>
                                            <h3 className="h3Text">{i.service_name || i.name}</h3>
                                            <p css="margin:0;">{i.vendor_name}</p>
                                            <Rating size='mini' disabled defaultRating={i.avg_reviews} maxRating={5} />
                                            <p css="margin:0;">{t("homeScreen.times_purchased")}: {i.total_sales}</p>
                                            <p css="margin:0;">USD ${i.sale_price || i.hourly_rate} {i.volume_pricing?.length > 0 && <><br />({t("cart.discount")}: {(i.volume_pricing.length < 2 ? i.volume_pricing[0][2] + "%" : t("homeScreen.up_to") + " " + i.volume_pricing.reduce((acc, curr) => { return curr[2] > acc ? curr[2] : acc }, 0) + "%")})</>}</p>
                                            {i.location && <p css="margin:0;">{getLocationName(i.location)}</p>}
                                            {i.languages?.length > 0 && <p css="margin:0;">{getProductLanguages(i.languages)}</p>}
                                        </div>
                                    </Link>
                                </div>
                            })
                        }
                    </div>
                    {(fProducts?.length === 0 && products?.length === 0) && <>
                        <div css="text-align:center;font-size:1.5rem;padding:1rem 0;">
                            <p>{vendor_type=="business"?t("homeScreen.empty_category_product_1"):t("homeScreen.empty_category_service_1")}</p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="homeScreen.empty_category_2"
                                    components={{
                                        link1: <a href='#' target='_blank' onClick={handleRegisterClick} />,
                                        link2: <a href='/wiki?opt=how_to_register' target='_blank' />
                                    }}
                                />
                            </p>
                        </div>
                    </>}
                </div>
            </main>

            <Footer />
        </>
    )
}

export default CategoryProducts