import { Link, useHistory } from "react-router-dom"
import { Button, Dimmer, Icon, Item, List, Loader, Message, Modal } from "semantic-ui-react"
import Footer, { copyTextToClipboard } from "../shared/footer/footer"
import Navbar from "../shared/navbar/navbar"
import "styled-components/macro"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "../axios"
import { addToCart } from "../store/cart-slice"
import { useQuery } from "../Home/search"
import { uiActions } from "../store/ui-slice"
import { url_environment } from "../config"
const SharedWishList = () => {
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(true)
    const [wishlistLoading, setWishlistLoading] = useState(false)
    const [generateLinkLoading, setGenerateLinkLoading] = useState(false)
    const [trashLoading, setTrashLoading] = useState(false)
    const [selectedWishlist, setSelectedWishlist] = useState("")
    const [products, setProducts] = useState([])
    const [wishlist, setWishlist] = useState({})
    const [wishlist_name, set_wishlist_name] = useState("")
    const [showNewWishlist, setShowNewWishlist] = useState(false)
    const [showShareWishlist, setShowShareWishlist] = useState(false)
    const query = useQuery()
    const history = useHistory()
    const dispatch = useDispatch()
    const handleAddToCart = async (product) => {
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }))
            return;
        }
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/cart", {
                product
            }, config)
            dispatch(addToCart(product))
            setLoading(false)
        } catch (e) { alert(e) }
    }

    const loadData = async () => {
        if (!query.get("id")) { history.goBack(); return; }
        setLoading(true)
        try {
            const { data } = await axios.get("/api/user/shared_wishlist?id=" + query.get("id"))
            setWishlist(data)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return <>
        <Navbar />
        <main>

            <div css="max-width:1100px;margin:0 auto;padding:0 36px;align-items:start;flex-wrap:wrap;">

                {wishlistLoading && <Dimmer active inverted>
                    <Loader />
                </Dimmer>}
                {!wishlistLoading &&
                    <>
                        <Message
                            attached
                            header={<div css="display:flex;justify-content:space-between;">
                                <div css="font-size:18px;font-weight:bold;">{wishlist.name} ({wishlist.user.name})</div>
                            </div>}
                        />
                        <Item.Group divided>
                            {wishlist.products.length === 0 && <div css="display:flex;align-items:center;justify-content:center;margin-top:4rem;font-size:25px;font-weight:700;">{t("product.your_wishlist_empty")}</div>}
                            {wishlist.products.map((x, c) => {
                                return (
                                    <>
                                        <Item key={x._id}>
                                            <Item.Image as={Link} to={`/product/${x.id_product}`} src={url_environment + x.images[0]} />

                                            <Item.Content>
                                                <Item.Header css="width:100%;">
                                                    <div css="display:flex;justify-content:space-between;">
                                                        <span>
                                                            <Link to={`/product/${x.id_product}`}>{x.name}</Link>
                                                        </span>
                                                        <span>
                                                            USD ${x.sale_price}
                                                        </span>
                                                    </div>
                                                </Item.Header>
                                                {/* <Item.Meta>
                                                <div css="margin:10px 0;" className='cinema'>cinema meta</div>
                                            </Item.Meta> */}
                                                <Item.Description>
                                                    {x.description}
                                                </Item.Description>
                                                <Item.Extra>
                                                    <div css="float:right;">
                                                        <Button disabled={x.stock < 1} css="display:block !important;background: rgb(2,183,178) !important;margin-bottom:10px !important;" circular color="teal" onClick={() => handleAddToCart(x)}>{x.stock < 1 ? t("product.out_of_stock") : t("product.add_to_cart")}</Button>
                                                    </div>
                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>
                                    </>
                                )
                            })}


                        </Item.Group>
                    </>
                }

            </div>
        </main>

        <Footer />
    </>
}

export default SharedWishList