import { url_environment } from "../config"

export const imageStyles = {
    display: "block",
    width: "100%",
    height: "auto",
    maxWidth: "150px",
    borderRadius: "100px"
}

export const btnContainer = `
display:flex;
align-items:center;
justify-content:space-between;
margin:0 auto;
background:white;

box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
border-radius:100px;
padding:9px;
cursor:pointer;
position:relative;
&:hover .btnpop{
    display:flex;
  }
  & span {
      margin-right:40px;
  }

  & a{
    display:block;
    padding:10px 0;
    text-decoration:none;
    color:black;
  }
  & p{
      margin:1px 0;
  }

  & a{
      padding:15px;
      border-radius:100px;
      border: 1px solid #aaa;
      line-height:0;
      margin:10px 0;
  }
`
export const btnImg = `
max-width:50px;
height:auto;
display:block;
border-radius:100px;
`
export const btnPop = `
position:absolute;
top:100%;
left:50%;
z-index:999;
transform:translateX(-50%);
display:none;
background:white;
box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
border-radius:10px;
padding:20px;
font-size:16px;
align-items:center;
justify-content:space-around;
flex-direction:column;
&:before{
    content:'';
    width:100%;
    height:50px;
    background:transparent;
    position:absolute;
    top:-50px;
}

a{
    white-space:nowrap;
  }
`
export const LanguageOptions = [
    { key: "en", value: "en", text: "English" },
    { key: "es", value: "es", text: "Español" }
]

export const homeDivStyles = `display:flex;align-items:center;max-width:1127px;margin:7px auto;`

export const productWrap = "border:1px solid #E1E3DF; border-radius:7px;color:#fff; cursor:pointer;"

export const addProductWrap = "padding-bottom:80%; background:rgb(2,183,178) !important; margin:2px;border-radius:7px;position:relative;"

export const addProductDiv = "position:absolute;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;"

export const showProduct = "border:1px solid #E1E3DF; border-radius:7px;color:#fff;cursor:pointer;"

export const showProductWrap = "padding-bottom:80%; background:rgb(82,219,212); margin:2px;border-radius:7px;position:relative;"

export const showProductDiv = "position:absolute;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;"

export const showProductCard = (img) => `width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:relative;background-position:center;background-size:cover;background-repeat:no-repeat;background-image:url('${img.includes("/uploads/") ? (url_environment + img) : (img)}');`

export const showProductOver = "position:absolute;top:0;left:0;width:100%;height:100%;background:#000;opacity:0.4;"

export const footButtons = "display:flex;align-items:center;justify-content:space-between;max-width:1127px;margin:0 auto;margin-top:2rem;"

export const divImage = "display:flex;align-items:center;justify-content:flex-end;"
export const divImage2 = "display:flex;flex-wrap:wrap;align-items:center;justify-content:flex-start;"

export const profimage1 = (imageLink) => { return (`position:absolute;top:0;left:0;background:url('${imageLink}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`) }
export const profimage2 = (imageLink) => { return (`position:absolute;top:0;left:0;background:url('${url_environment}/${imageLink}');width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;`) }
