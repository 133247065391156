import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Rating, Segment, Tab } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { uiActions } from '../store/ui-slice'
import { formData } from '../shared/formDataCreator'

const CloseContract = (props) => {
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const fileRef = useRef(undefined)
    const values = useRef({ text: false, rating: false })
    const [files, setFiles] = useState([])
    const [filesData, setFilesData] = useState([])
    const dispatch = useDispatch();
    const data = props.data
    const { userInfo } = useSelector(state => state.user)
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const loadData = async () => {
        setLoading(true)
        try {
            values.current = { text: false, rating: false }

        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleAddPhoto = (e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])
    }
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleCloseout = async () => {
        let review = {product_id:data.service,text:"null",rating:"null"}
        if (values.current.text || values.current.rating) {
            if (!values.current.text || !values.current.rating) {
                alert("Please fill all fields!");
                return;
            } else {
                review = {
                    product_id: data.service,
                    text: values.current.text,
                    rating: values.current.rating
                }
            }
        }
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { product_id, text, rating } = review
            await axios.post("/api/proposal/close", formData({
                id: props.id,
                product_id,
                text,
                rating,
                vendor_id:data.receiver,
                ...getFilesObject(files),
            }), config)
            props.close_update()
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        values.current = { ...values.current, [name]: value }
    }
    useEffect(() => {
        if (!props.open) return;
        loadData()
    }, [props.open])
    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{t("contractmgmt.contract_closeout")}</Modal.Header>
                <Modal.Content >
                    <h3 css="text-align:center;color:#db2828;">{t("contractmgmt.contract_closeout")}</h3>
                    <div className="ui form">
                        <div className="ui field">
                            <label>{t("contractmgmt.how_was_you_experience_para")}</label>
                            <textarea onChange={handleInputChange} name="text" style={{ width: "100%" }} rows="10"></textarea>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.rating")}</label>
                            <div className="ui field">
                                <Rating maxRating={5} onRate={(e, a) => handleInputChange({ target: { name: "rating", value: a.rating } })} />
                            </div>
                        </div>
                        <div css="text-align:center;">
                            <Button loading={loading} onClick={handleCloseout} circular color="red">{t("contractmgmt.closeout_contract")}</Button>
                            <label class="ui labeled circular teal icon button" htmlFor="custom_button_upload_image">
                                <i class="image icon"></i>
                                <input
                                    id="custom_button_upload_image"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleAddPhoto}
                                    css="display:none;"
                                />
                                {t('product.add_photo')}
                            </label>
                        </div>
                        <div css="display:flex;align-items:center;justify-content:flex-start;">
                            {filesData.map((item) => {
                                return <>
                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                    <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </Modal.Content>
                {/* <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    <Button loading={loading} onClick={handleSubmit} color='teal' circular>
                        {t("contractmgmt.submit")}
                    </Button>
                    <Button color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions> */}
            </Modal>
        </>
    )
}

export default CloseContract