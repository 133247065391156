import { React, useState } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from '../axios'

const ForgotPassword = () => {
    const [t, i18n] = useTranslation("global")
    const [Email, setEmail] = useState("")
    const [Wrong, setWrong] = useState("")
    const [message, setMessage] = useState(false)


    const handleSubmit = () => {
        axios.post("/api/user/forgotPassword", {
            email: Email,
            lenguage: i18n.languages[0].toString()
        }).then((res) => {
            if (typeof res.data == "object" && "error" in res.data) {
                alert(res.data.error)
            } else {
                //setWrong(false)
                setEmail("")
                setMessage("Instructions sent to your email")

            }
        }).catch((e) => {
            if (e?.response?.data?.message)
                alert(e?.response?.data?.message)
            else
                alert("Server error:" + e)
        })
    }
    return (
        <>
            <Grid style={{ height: '100vh', background: "#f9f9f9" }} centered verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Segment raised padded style={{ borderRadius: "20px" }}>
                        <Header style={{ textAlign: "center" }}>
                            {t('forgot.header')}
                        </Header>
                        <Form onSubmit={handleSubmit}>
                            <Form.Input value={Email} onChange={(e) => { setEmail(e.target.value) }} required fluid label={t('forgot.email')} type="text" />
                            <Form.Button style={{ borderRadius: "200px", background: "rgb(0,128,129)" }} fluid type="submit" secondary content={t('forgot.send')} />
                            {
                                Wrong && (
                                    <Message visible>
                                        {Wrong}
                                    </Message>
                                )
                            }

                            {
                                message && (
                                    <Message visible>
                                        {message}
                                    </Message>
                                )
                            }

                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    )
}

export default ForgotPassword