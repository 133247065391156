import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dimmer, Icon, Input, Loader, TextArea } from 'semantic-ui-react'
import "styled-components/macro"
import axios from '../../axios'
import { uiActions } from '../../store/ui-slice'
import { formData } from '../formDataCreator'

const ReportProblem = (props) => {
    const [t, i18n] = useTranslation("global")
    const [subject, setSubject] = useState("Issue with my payment")
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(undefined)
    const { userInfo } = useSelector(state => state.user)
    const textRef = useRef("")
    const dispatch = useDispatch()
    const handleSendMessage = async () => {
        if (textRef.current.trim() == "") {
            alert("Please write a message!")
            return
        }
        setLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            await axios.post("/api/user/message",formData({
                receiver: "60abe4fd1346af761a736830",
                subject,
                message: textRef.current,//+`${file ? "{[(image)]}" : ""}`,
                image: file
            }), config)
            setLoading(false)
            dispatch(uiActions.change_action({ open: false, action: "" }))
        } catch (e) { alert(e) }
        setLoading(false)
    }

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }

    return (
        <>
            <h3 css="text-align:center;">{t("messages.report_problem")}</h3>
            <div css="height:2px;background:teal;margin:7px 0;"></div>
            <p css="text-align:center;margin:15px 0;color:teal;">{t("messages.what_type_problem")}</p>
            <p css="text-align:center;margin:15px 0;color:teal;">{t("messages.attach_screen_info")}.</p>
            <p css="text-align:center;margin:15px 0;color:teal;">
                {t("messages.if_reporting_para")}
            </p>
            <Button onClick={() => setSubject("Issue with my payment")} circular color="teal" style={{ margin: "10px auto", display: "block", width: "300px", textAlign: "center" }}>{t("messages.issue_payment")}</Button>
            <Button onClick={() => setSubject("Problem with my service seller")} circular color="teal" style={{ margin: "10px auto", display: "block", width: "300px", textAlign: "center" }}>{t("messages.problem_service_seller")}</Button>
            <Button onClick={() => setSubject("Problem with my product seller")} circular color="teal" style={{ margin: "10px auto", display: "block", width: "300px", textAlign: "center" }}>{t("messages.problem_product_seller")}</Button>
            <Button onClick={() => setSubject("Issue with my client")} circular color="teal" style={{ margin: "10px auto", display: "block", width: "300px", textAlign: "center" }}>{t("messages.issue_client")}</Button>
            <Button onClick={() => setSubject("Issue with the application")} circular color="teal" style={{ margin: "10px auto", display: "block", width: "300px", textAlign: "center" }}>{t("messages.issue_application")}</Button>
            <p css="text-align:center;margin:15px 0;color:teal;">
                {t("messages.we_appreciate")}
            </p>
            <div css="display:flex;align-items:center;justify-content:space-between;margin:10px auto;">
                <span>{t("messages.subject")}:</span>
                <Input value={subject} placeholder={t("messages.write_subject")} css="margin:0 10px;max-width:300px;width:100%;text-align:center;" />
                <span css="color:transparent;">{t("messages.subject")}:</span>
            </div>
            {file ? (<div style={{marginBottom:"10px"}}><b>{t("messages.image_attached")+":"}</b><span>{file.name}</span></div>) : ""}
            <div css="display:flex;align-items:flex-end;">
                <label htmlFor="custom_button_upload_image" title={t("messages.add_picture")} >
                    <i style={{ color: "rgb(0,128,129)", cursor: "pointer" }} className={`large ${file ? "file image" : "plus circle"} icon`}></i>
                    <input
                        id="custom_button_upload_image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => { e.target.files[0] && setFile(e.target.files[0]) }}
                        css="display:none;"
                    />
                </label>
                <div style={{ flexGrow: "2", margin: "0 7px" }} className="ui form">
                    <TextArea rows={10} onChange={(e) => { textRef.current = e.target.value }} />
                </div>
                <Icon onClick={handleSendMessage} title={t("messages.send_message")} size="large" css="cursor:pointer;color:rgb(0,128,129) !important;" name="send" />
            </div>
        </>
    )
}

export default ReportProblem