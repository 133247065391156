import { Link } from "react-router-dom"
import { Button, Dimmer, Icon, Item, List, Loader, Message, Modal } from "semantic-ui-react"
import Footer, { copyTextToClipboard } from "../shared/footer/footer"
import Navbar from "../shared/navbar/navbar"
import "styled-components/macro"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "../axios"
import { addToCart } from "../store/cart-slice"
import { uiActions } from "../store/ui-slice"
import { url_environment } from "../config"
const WishList = () => {
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const [wishlistLoading, setWishlistLoading] = useState(false)
    const [generateLinkLoading, setGenerateLinkLoading] = useState(false)
    const [trashLoading, setTrashLoading] = useState(false)
    const [selectedWishlist, setSelectedWishlist] = useState("")
    const [products, setProducts] = useState([])
    const [wishlist, setWishlist] = useState([])
    const [wishlist_name, set_wishlist_name] = useState("")
    const [showNewWishlist, setShowNewWishlist] = useState(false)
    const [showEditWishlist, setShowEditWishlist] = useState(false)
    const [showShareWishlist, setShowShareWishlist] = useState(false)
    const dispatch = useDispatch()
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const handleCopyLink = async (link) => {
        try {
            await copyTextToClipboard(link)
        } catch (e) { alert(e) }
    }
    const handleWishlistSelect = async (list) => {
        try {
            setWishlistLoading(true)
            const { data } = await axios.get("/api/user/wishlist/products/" + list._id, config)
            setProducts(data.reverse())
            setSelectedWishlist(list)
        } catch (e) { alert(e) }
        setWishlistLoading(false)
    }
    const handleGenerateLink = async (e) => {
        e.target.disabled = true
        setGenerateLinkLoading(true)
        try {
            const { data } = await axios.get(`/api/user/wishlist/link?wishlist=${selectedWishlist._id}&delete=${selectedWishlist.shared_link ? "true" : "false"}`, config)
            setSelectedWishlist(p => { return { ...p, shared_link: data.link } })
        } catch (e) { alert(e) }
        e.target.disabled = false
        setGenerateLinkLoading(false)
    }
    const handleAddToCart = async (product) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            await axios.post("/api/user/cart", {
                product
            }, config)
            dispatch(addToCart(product))
            setLoading(false)
        } catch (e) { console.log(e) }
    }
    const handleCreateWishlist = async () => {
        if (wishlist_name == "") return;
        setLoading(true)
        try {
            const { data } = await axios.post("/api/user/wishlist/new", {
                name: wishlist_name
            }, config)
            setWishlist(p => [...p, data])
            set_wishlist_name("")
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleRemoveFromWishlist = async (id) => {
        setLoading(true)
        try {
            await axios.post("/api/user/wishlist/product/delete", {
                wishlist: selectedWishlist._id,
                product: id
            }, config)
            setProducts(p => p.filter((product) => product._id != id))
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const handleDeleteWishlist = async (id) => {
        setLoading(true)
        try {
            await axios.post("/api/user/wishlist/delete", {
                id
            }, config)
            const idx = wishlist.findIndex(x => x._id == id)
            setWishlist(p => p.filter((list) => list._id != id))
            if (idx == 0) {
                await loadData()
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }

    const handleEditWishlist = async () => {
        if (wishlist_name == "") return;
        setLoading(true)
        try {
            const { data } = await axios.put("/api/user/wishlist/edit", {
                name: wishlist_name,
                id: selectedWishlist._id
            }, config)
            setWishlist(p => {
                const i = p.findIndex(w => w._id == selectedWishlist._id)
                const cp = [...p]
                cp[i].name = wishlist_name
                return [...cp]
            })
            set_wishlist_name("")
        } catch (e) { alert(e) }
        setLoading(false)
    }

    const loadData = async () => {
        if (!userInfo) {
            dispatch(uiActions.change_action_sign_in({ open: true, action: "login" }))
            return;
        }
        setLoading(true)
        try {
            const { data } = await axios.get("/api/user/wishlist", config)
            setWishlist(data)
            if (data.length > 0) {
                await handleWishlistSelect(data[0])
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }
    useEffect(() => { loadData() }, [])
    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    if (!userInfo) {
        return <Navbar />
    }
    return <>
        <Navbar />
        <main>

            <div css="max-width:1400px;margin:0 auto;padding:0 36px;display:flex;align-items:start;flex-wrap:wrap;">
                <div css="flex:0 0 auto;width:25%;padding:10px;@media(max-width:768px){width:100%;}">
                    <div>
                        {wishlist.map(list => {
                            return <div tabIndex={-1} onClick={() => handleWishlistSelect(list)} css={`display:flex;justify-content:space-between;cursor:pointer;padding:10px 15px;background-color:${list._id == selectedWishlist._id ? "#dadada" : "#eee"};margin:5px 0;border-radius:4px;font-weight:700;&:hover{background:#dadada;}`}>
                                <div>{list.name}</div>
                                <div><Icon name="trash" title={t("product.delete_item")} loading={trashLoading} circular onClick={(e) => { e.stopPropagation(); handleDeleteWishlist(list._id) }} /></div>
                            </div>
                        })}
                        <Button circular color="teal" fluid onClick={() => setShowNewWishlist(true)}>{t("product.create_new_wishlist")}</Button>

                    </div>
                </div>
                <div css="flex:0 0 auto;width:75%;padding:10px;@media(max-width:768px){width:100%;}">
                    {wishlistLoading && <Dimmer active inverted>
                        <Loader />
                    </Dimmer>}
                    {!wishlistLoading &&
                        <>
                            <Message
                                attached
                                header={<div css="display:flex;justify-content:space-between;align-items:center;">
                                    <div css="font-size:18px;font-weight:bold;">{selectedWishlist.name}</div>

                                    <div css="display:flex;flex-wrap:wrap;">
                                        <div css="margin-right:0.4rem;"><a href="#" onClick={(e) => { e.preventDefault(); set_wishlist_name(selectedWishlist.name); setShowEditWishlist(true); }}><Icon name="edit alternate" /> {t("product.edit_wishlist")}</a></div>
                                        <div><a href="#" onClick={(e) => { e.preventDefault(); setShowShareWishlist(true) }}><Icon name="share alternate" /> {t("product.share_wishlist")}</a></div>
                                    </div>
                                </div>}
                            />
                            <Item.Group divided>
                                {products.length === 0 && <div css="display:flex;align-items:center;justify-content:center;margin-top:4rem;font-size:25px;font-weight:700;">{t("product.your_wishlist_empty")}</div>}
                                {products.map((x, c) => {
                                    return (
                                        <>
                                            <Item key={x._id}>
                                                <Item.Image as={Link} to={`/product/${x.id_product}`} src={url_environment + x.images[0]} />

                                                <Item.Content>
                                                    <Item.Header css="width:100%;">
                                                        <div css="display:flex;justify-content:space-between;">
                                                            <span>
                                                                <Link to={`/product/${x.id_product}`}>{x.name}</Link>
                                                            </span>
                                                            <span>
                                                                USD ${x.sale_price}
                                                            </span>
                                                        </div>
                                                    </Item.Header>
                                                    {/* <Item.Meta>
                                                <div css="margin:10px 0;" className='cinema'>cinema meta</div>
                                            </Item.Meta> */}
                                                    <Item.Description>
                                                        {x.description}
                                                    </Item.Description>
                                                    <Item.Extra>
                                                        <div css="float:right;">

                                                            <Button disabled={x.stock < 1} css="display:block !important;background: rgb(2,183,178) !important;margin-bottom:10px !important;" circular color="teal" onClick={() => handleAddToCart(x)}>{x.stock < 1 ? t("product.out_of_stock") : t("product.add_to_cart")}</Button>
                                                            <Button /*css="display:block !important;background: rgb(2,183,178) !important;margin-bottom:10px !important;"*/ circular color="red" onClick={() => handleRemoveFromWishlist(x._id)}>{t("product.remove_from_wishlist")}</Button>
                                                        </div>
                                                    </Item.Extra>
                                                </Item.Content>
                                            </Item>
                                        </>
                                    )
                                })}


                            </Item.Group>
                        </>
                    }
                </div>
            </div>
        </main>

        <Footer />
        <Modal
closeIcon
closeOnDimmerClick={false}
            open={showNewWishlist}
            onClose={() => setShowNewWishlist(false)}
            size="tiny"
            header={t("product.create_new_wishlist")}
            content={<div style={{ padding: "10px" }}>
                <form className="ui form">
                    <div className="ui field">
                        <label>{t("product.wishlist_name")}</label>
                        <input value={wishlist_name} onChange={(e) => set_wishlist_name(e.target.value)} />
                    </div>
                </form>
            </div>}
            actions={[{ key: "close", content: t("product.close"), color: "black", circular: true }, { key: 'done', content: t("product.save"), color: "teal", circular: true, onClick: handleCreateWishlist }]}
        />
        <Modal
closeIcon
closeOnDimmerClick={false}
            open={showEditWishlist}
            onClose={() => setShowEditWishlist(false)}
            size="tiny"
            header={t("product.edit_wishlist")}
            content={<div style={{ padding: "10px" }}>
                <form className="ui form">
                    <div className="ui field">
                        <label>{t("product.wishlist_name")}</label>
                        <input value={wishlist_name} onChange={(e) => set_wishlist_name(e.target.value)} />
                    </div>
                </form>
            </div>}
            actions={[{ key: "close", content: t("product.close"), color: "black", circular: true }, { key: 'done', content: t("product.save"), color: "teal", circular: true, onClick: handleEditWishlist }]}
        />
        <Modal
closeIcon
closeOnDimmerClick={false}
            open={showShareWishlist}
            onClose={() => setShowShareWishlist(false)}
            size="tiny"
            header={t("product.share_wishlist")}
            content={<div style={{ padding: "10px" }}>
                <div className="ui form">
                    <div className="ui field">
                        <Button loading={generateLinkLoading} onClick={handleGenerateLink}>{selectedWishlist.shared_link ? t("product.delete_link") : t("product.generate_link")}</Button>{selectedWishlist.shared_link && (<><Button onClick={() => handleCopyLink("https://interpowered.com/shared_wishlist?id=" + selectedWishlist.shared_link)}>{t("product.copy_link")}</Button><Button onClick={() => window.location = `mailto:?subject=${userInfo.name} ${t("product.has_send_email_wishlist")}&body=${encodeURIComponent(`https://interpowered.com/shared_wishlist?id=${selectedWishlist.shared_link}`)}`}>{t("product.send_email")}</Button></>)}
                        {selectedWishlist.shared_link && <a css="display:block;margin-top:0.5rem;" target="_blank" href={"https://interpowered.com/shared_wishlist?id=" + selectedWishlist.shared_link}>{"https://interpowered.com/shared_wishlist?id=" + selectedWishlist.shared_link}</a>}
                    </div>
                </div>
            </div>}
            actions={[{ key: "close", content: t("product.close"), color: "black", circular: true }]}
        />
    </>
}

export default WishList