import React, { memo, useCallback, useRef } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import 'styled-components/macro'

const SignatureModal = memo((props) => {
    const [t, i18n] = useTranslation("global")
    const handleClose = useCallback(() => {
        props.close()
    }, [])
    const handleSubmit = useCallback(async () => {
        const image=sigCanvas.current.toDataURL("image/png");
        console.log(image)
        props.submit(image)
        props.close()
    }, [])
    const sigCanvas=useRef()
    return <>
        <Modal
closeIcon
closeOnDimmerClick={false}
            onClose={handleClose}
            open={props.open}
        >
            <Modal.Header>{t("contractmgmt.digital_signature")}</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <div css="display:flex;justify-content:center;& .sigCanvas{border:1px solid #dededf !important;}">
                        <SignatureCanvas ref={(ref) => { sigCanvas.current = ref }} penColor='black' canvasProps={{ width: 400, height: 300, className: 'sigCanvas' }} />
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button circular color='teal' onClick={handleSubmit}>
                    {t("contractmgmt.submit")}
                </Button>
                <Button circular color='black' onClick={handleClose}>
                    {t("contractmgmt.close")}
                </Button>

            </Modal.Actions>
        </Modal>
    </>
})

export default SignatureModal