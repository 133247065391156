import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Dropdown, Input, Loader, Modal, Table, TextArea,Form,CardContent, Card,FormField } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import axios from '../../axios'
import { catLoadinStyles } from './styles'
import { formData } from '../../shared/formDataCreator'
import placeholder from '../../img/photo_placeholder.svg'

import 'styled-components/macro'
import { useSelector } from 'react-redux'
import { url_environment } from '../../config'

const Categories = (props) => {
    const [Loading, setLoading] = useState(true)
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [openDataModal, setOpenDataModal] = useState({})
    const [CategoryData, setCategoryData] = useState([])
    const [BusinessData, setBusinessData] = useState([])
    const [CategoryName, setCategoryName] = useState("")
    const [CategoryRate, setCategoryRate] = useState("")
    const [UpdateCategoryName, setUpdateCategoryName] = useState("")
    const [UpdateCategoryRate, setUpdateCategoryRate] = useState("")
    const [imageFile, setImageFile] = useState(undefined)
    const [imageFile2, setImageFile2] = useState(undefined)
    const [imgUrl, setImgUrl] = useState(false)
    const { userInfo } = useSelector(state => state.user)
    const imageRef = useRef()
    const imageRef2 = useRef()

    const [t, i18n] = useTranslation("global")
    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async () => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.get("/api/category/business")
            setCategoryData(res.data?.sort((a,b)=>a.name.localeCompare(b.name)))
            res = await axios.get("/api/business/readAll", config);
            setBusinessData(res.data)
            setLoading(false)
        } catch (e) {
            alert(e)
        }
    }

    const categoryUsed = (id_cat) => {
        for (let i = 0; i < BusinessData.length; i++) {
            if (BusinessData[i].id_service_category == id_cat) {
                return true
            }
        }
        return false
    }

    const handleCatUpdate = async (name, rate, id) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.put("/api/category/business/" + id, formData({
                name,
                rate: +rate,
                image: imageFile2
            }), config)
            setLoading(false)
            setOpenUpdateModal(false)
            handleUpdateClose()
            getCategories()
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const handleCatDelete = async (id) => {
        try {
            setLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.delete("/api/category/business/" + id, config)
            getCategories()
        } catch (e) {
            let msg="error"
            if(e.response){
                msg=e.response.data?.message
            }
            alert(msg)
            setLoading(false)
        }
    }

    const handleAddCategory = async () => {
        if (CategoryName != "")
            try {
                setLoading(true)
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                let res = await axios.post("/api/category/business", formData({ name: CategoryName, rate: +CategoryRate, image: imageFile }), config)
                setCategoryName("")
                setCategoryRate("")
                setImageFile(undefined)
                getCategories()
            } catch (e) {
                alert(e)
                setLoading(false)
            }
    }
    const handleUpdateClose = () => {
        setOpenUpdateModal(false);
        setImageFile2(undefined);
        setImgUrl(false)
    }

    return (
        <>
            <Modal
closeIcon
closeOnDimmerClick={false}
                dimmer="inverted"
                open={true}
                onClose={props.onClose}
            >
                <Modal.Header>Product Categories</Modal.Header>
                <Modal.Content>
{/*
                <Card>
               
                 <CardContent >
                     <Form>
                    <FormField>
                      <label>Add Category</label>
                      <textarea rows="3" value={CategoryName} onChange={(e) => setCategoryName(e.target.value)} placeholder='Add Category'  style={{ width: "250px" }}/>
                    </FormField>
                    <FormField>
                      <label>Add Rate</label>
                      <input type="number" value={CategoryRate} onChange={(e) => setCategoryRate(e.target.value)} placeholder='Add Rate' style={{ width: "125px" }} />
                    </FormField>
                    <FormField>
                      <Button onClick={() => imageRef.current.click()}>Upload File{imageFile && `:${imageFile.name}`}</Button>
                    </FormField>
                    <FormField>
                      <Button type="button" onClick={handleAddCategory}>Add Category</Button>
                    </FormField>
                    
                    </Form>
                    </CardContent >
                  </Card>
*/}
                

                    <div style={{ width: "200px",display:"inline-block", verticalAlign: "top" }}  className="ui form">
                        <textarea rows="3" value={CategoryName} onChange={(e) => setCategoryName(e.target.value)} placeholder='Add Category' />
                    </div>
                    <Input type="number" value={CategoryRate} onChange={(e) => setCategoryRate(e.target.value)} placeholder='Add Rate' style={{ width: "125px" }} />
                    <input onChange={(e) => { e.target.files[0] && setImageFile(e.target.files[0]) }} type="file" accept="image/png, image/gif, image/jpeg" ref={imageRef} hidden />
                    <Button onClick={() => imageRef.current.click()}>Upload File{imageFile && `:${imageFile.name}`}</Button>
                    <Button onClick={handleAddCategory}>Add</Button>

                    <Table singleLine striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('administration.table.name')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.rate')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('administration.table.actions')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {
                            Loading && (
                                <div css={catLoadinStyles}>
                                    <Dimmer active inverted>
                                        <Loader />
                                    </Dimmer>
                                </div>
                            )
                        }

                        <Table.Body>
                            {
                                CategoryData.map((i, c) => {
                                    return (
                                        <Table.Row key={c}>
                                            <Table.Cell css="white-space:pre-wrap;">{i.name}</Table.Cell>
                                            <Table.Cell>{i.rate}</Table.Cell>
                                            <Table.Cell>
                                                <Dropdown
                                                    text={t("administration.table.actions")}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                    style={{ background: "rgb(2,183,178)", color: "#fff" }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item icon='edit' onClick={() => { setOpenUpdateModal(true); setOpenDataModal(i); setUpdateCategoryName(i.name); setUpdateCategoryRate(i.rate) }} text={t('administration.update')} />
                                                        {!categoryUsed(i.id_cat) && <Dropdown.Item icon='delete' onClick={() => handleCatDelete(i.id_cat)} text={t('administration.delete')} />}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }

                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={props.onClose} negative>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
closeIcon
closeOnDimmerClick={false}
                dimmer="inverted"
                open={openUpdateModal}
                onClose={handleUpdateClose}
            >
                <Modal.Header>Update</Modal.Header>
                <Modal.Content>
                    <div css="display:flex;justify-content:space-between;">
                        <div>
                            <div style={{ width: "200px",display:"inline-block", verticalAlign: "top" }} className="ui form">
                                <textarea rows="3" value={UpdateCategoryName} onChange={(e) => setUpdateCategoryName(e.target.value)} />
                            </div>
                            <Input type="number" value={UpdateCategoryRate} onChange={(e) => setUpdateCategoryRate(e.target.value)} style={{ width: "125px" }} />
                            <Button onClick={() => handleCatUpdate(UpdateCategoryName, UpdateCategoryRate, openDataModal.id_cat)}>Update</Button>
                            <input onChange={(e) => { e.target.files[0] && setImageFile2(e.target.files[0]); e.target.files[0] && setImgUrl(URL.createObjectURL(e.target.files[0])) }} type="file" ref={imageRef2} hidden accept="image/png, image/gif, image/jpeg" />
                        </div>
                        <div css="display:flex;flex-direction:column;">
                            <img css="width:200px;height:200px;object-position:center;object-fit:cover;" src={imgUrl ? imgUrl : (openDataModal.image ? url_environment + openDataModal.image : placeholder)} alt="" />
                            <Button css="margin:5px 0 0 0 !important;" onClick={() => { imageRef2.current.click() }}>Update Image</Button>
                            <label></label>
                        </div>
                    </div>
                    {
                        Loading && (
                            <div css={catLoadinStyles}>
                                <Dimmer active inverted>
                                    <Loader />
                                </Dimmer>
                            </div>
                        )
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleUpdateClose} negative>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}


export default Categories