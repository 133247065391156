import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Button, Dimmer, Form, List, Loader, Rating, Segment, Sidebar } from 'semantic-ui-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomListCourse } from './customList'
import { useQuery } from '../Home/search'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { useHistory } from 'react-router'
import { formData } from './formDataCreator'
import { uiActions } from '../store/ui-slice';
import Player from "./player";

const Course = () => {
    const [t, i18n] = useTranslation("global")
    const [visible, setVisible] = useState(false)
    const query = useQuery()
    const [loading, setLoading] = useState(false)
    const { userInfo } = useSelector(state => state.user)
    const [lectureData, setLectureData] = useState({ vimeoId: "", title: "", description: "" })
    const [activeLecture, setActiveLecture] = useState("")
    const [activeSection, setActiveSection] = useState("")
    const [text, setText] = useState("")
    const [rating, setRating] = useState(0)
    const [lectureIndex, setLectureIndex] = useState(0)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [secLoading, setSecLoading] = useState(false)
    const [allowReview, setAllowReview] = useState(false)
    const [lectureIndexData, setLectureIndexData] = useState([])
    const [courseData, setCourseData] = useState([])
    const [courseInfo, setCourseInfo] = useState({})
    const [files, setFiles] = useState([])
    const [filesData, setFilesData] = useState([])
    const signed_data = useRef("")
    const dispatch = useDispatch()
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const history = useHistory()
    const loadData = async () => {
        try {
            if (!query.get("id")) {
                history.goBack()
                return
            }
            const { data: allow } = await axios.get("/api/product/review/allow/" + query.get("id"), config)
            setAllowReview(allow === "ok")
            const { data } = await axios.get(`/api/user/course/data?id=${query.get("id")}`, config)
            setCourseData(data.course_content)
            setCourseInfo(data)

            setLectureIndexData(data.course_content.reduce((acc, curr) => { return [...acc, ...curr.lectures] }, []))
            if (data.course_content.length > 0 && data.course_content[0].lectures.length > 0) {
                const lec = data.course_content[0].lectures[0]
                if (lec.file_type === "video") { await getSignedString(lec.video_uuid); }
                setLectureData(lec)//setLectureData({ vimeoId: lec.video_url.split("https://vimeo.com/")[1], description: lec.description, title: lec.title, _id: lec._id, file_type: lec.file_type })
                setActiveSection(data.course_content[0]._id)
                setActiveLecture(data.course_content[0].lectures[0]._id)
            }
            console.log(data)
        } catch (e) { alert(e) }
    }
    const getLectureIndex = useCallback((id) => { return lectureIndexData.findIndex(l => l._id == id) }, [lectureIndexData])
    const handleBack = async () => {
        if ((lectureIndex - 1) == -1)
            return;
        setLectureIndex(p => p - 1)
        const lec = lectureIndexData[lectureIndex - 1]
        // setLectureData({ vimeoId: lec.video_url.split("https://vimeo.com/")[1], description: lec.description, title: lec.title, _id: lec._id, file_type: lec.file_type })
        if (lec.file_type === "video") { await getSignedString(lec.video_uuid); }
        setLectureData(lec)
        setActiveSection(courseData.find(cd => cd.lectures.findIndex(l => l._id == lec._id) > -1)._id)
        setActiveLecture(lec._id)
    }
    const handleNext = async () => {
        if (lectureIndexData.length == (lectureIndex + 1))
            return;
        setLectureIndex(p => p + 1)
        const lec = lectureIndexData[lectureIndex + 1]
        setLectureData({ vimeoId: lec.video_url.split("https://vimeo.com/")[1], description: lec.description, title: lec.title, _id: lec._id, file_type: lec.file_type })
        if (lec.file_type === "video") { await getSignedString(lec.video_uuid); }
        setLectureData(lec)
        setActiveSection(courseData.find(cd => cd.lectures.findIndex(l => l._id == lec._id) > -1)._id)
        setActiveLecture(lec._id)
    }
    const handleDownload = async (lecture_id) => {
        setLoadingDownload(true)
        try {
            const { data: url } = await axios.get(`/api/user/course/data/download?course_id=${query.get("id")}&lecture_id=${lecture_id}`, config)
            window.location = url
        } catch (e) {
            alert(e)
        }
        setLoadingDownload(false)
    }
    const handleLeaveReview = () => {
        setActiveSection("review_section")
        setLectureData({ file_type: "review" })
    }
    const handleMessageSeller = () => {
        dispatch(uiActions.change_action({ open: true, action: "new message", receiver: { ...courseInfo, subject: "About product", receiver_type: "business", link: `/product/${query.get("id")}` } }))
    }
    const handleAddPhoto = (e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])
    }
    const handleReviewSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            await axios.post("/api/product/review/create", formData({
                product_id: query.get("id"),
                text,
                rating,
                ...getFilesObject(files),
            }), config)
            await loadData()
            setAllowReview(false)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const getSignedString = async (uuid) => {
        setSecLoading(true)
        try {
            const { data: signed } = await axios.get(`api/product/course/signed_string?course_id=${query.get("id")}&video_uuid=${uuid}`, config)
            signed_data.current = (`&Policy=${signed["CloudFront-Policy"]}&Signature=${signed["CloudFront-Signature"]}&Key-Pair-Id=${signed["CloudFront-Key-Pair-Id"]}`)
        } catch (e) { alert(e) }
        setSecLoading(false)
    }
    useEffect(() => { loadData() }, [])
    return <>

        <Navbar />
        <main>

            <div css="max-width:1300px; padding:0 36px; margin:0 auto;">
                <p style={{ textAlign: "right" }}>
                    <Button onClick={handleMessageSeller} circular color="teal">{t("product.message_seller")}</Button>
                </p>
                <Sidebar.Pushable>
                    <Sidebar
                        // as={Segment}
                        animation='overlay'
                        icon='labeled'
                        onHide={() => setVisible(false)}
                        vertical
                        visible={visible}
                        width='wide'
                        style={{ background: "white", padding: "1.5rem" }}
                    >
                        <List selection verticalAlign='middle'>
                            {courseData.map((x, c) => {
                                return !x.children ? <List.Item onClick={() => { }}>
                                    <List.Content>
                                        <List.Header>{x.title}</List.Header>
                                    </List.Content>
                                </List.Item> : <CustomListCourse data={x} onChildrenClick={(item) => { }} />
                            })}
                        </List>
                    </Sidebar>

                    <Sidebar.Pusher>
                        <div css="display:flex;flex-wrap:wrap;">
                            <div css="width:25%;flex:0 0 auto;overflow-y:scroll;@media(max-width:700px){width:100%; padding-bottom:1rem; margin-bottom:1rem; border-bottom:2px solid #dadada;}">
                                <List selection verticalAlign='middle'>
                                    {courseData.map((x, c) => {
                                        return !x.lectures ? <List.Item onClick={() => { }}>
                                            <List.Content>
                                                <List.Header>{x.title}</List.Header>
                                            </List.Content>
                                        </List.Item> : <CustomListCourse sectionOpen={x._id == activeSection} setActiveSection={setActiveSection} activeLecture={activeLecture} data={x} onChildrenClick={async (item) => { if (item.file_type === "video") { await getSignedString(item.video_uuid); } setLectureIndex(getLectureIndex(item._id)); setActiveLecture(item._id); setLectureData(item); }} />//{ vimeoId: url[url.length - 1], title: item.title, description: item.description, _id: item._id, file_type: item.file_type }
                                    })}
                                    {allowReview && <List.Item onClick={handleLeaveReview} active={activeSection == "review_section"}>
                                        <List.Content>
                                            <List.Header>{t("product.leave_a_review")}</List.Header>
                                        </List.Content>
                                    </List.Item>}
                                </List>
                            </div>
                            <div css="width:75%;flex:0 0 auto;padding-left:1rem;@media(max-width:500px){width:100%;}">
                                <h2>{lectureData.title}</h2>
                                <p  css="white-space:pre-wrap;">{lectureData.description}</p>
                                {
                                    secLoading ? (
                                        <Dimmer active inverted>
                                            <Loader />
                                        </Dimmer>
                                    ) : (

                                        <>
                                            {/* {lectureData.vimeoId != "" &&
                                    (
                                        <> */}
                                            {lectureData.file_type == "video" &&
                                                // <Player
                                                //     src={`https://dmvz0cr0zvd1h.cloudfront.net/${lectureData.video_uuid}/AppleHLS1/${lectureData.video_url}`}//4c471aaf-4443-4fd2-9c1e-49cdc4b8af7a/AppleHLS1/4c471aaf-4443-4fd2-9c1e-49cdc4b8af7a__61d0a82b61560b7cfd9cff66__video%20(1).m3u8"
                                                //     autoPlay={false}
                                                //     controls={true}
                                                //     width="100%"
                                                //     height="auto"
                                                //     hlsConfig={{
                                                //         xhrSetup: function (xhr, url) {
                                                //             xhr.open('GET', url + signed_data.current, true);
                                                //         },
                                                //     }}
                                                // />
                                                <Player
                                                    options={{
                                                        autoplay: true,
                                                        controls: true,
                                                        responsive: true,
                                                        fluid: true,
                                                        sources: [
                                                            {
                                                                src:`https://dmvz0cr0zvd1h.cloudfront.net/${lectureData.video_uuid}/AppleHLS1/${lectureData.video_url}?a=b${signed_data.current}`
                                                            }
                                                        ]
                                                    }}
                                                    signedString={"?a=b"+signed_data.current}
                                                />
                                            }
                                            {lectureData.file_type == "others" &&
                                                <div style={{ marginTop: "20px", textAlign: "center" }}>
                                                    <Button circular color="teal" loading={loadingDownload} onClick={() => handleDownload(lectureData._id)}>{t("profile.download_file")}</Button>
                                                </div>
                                            }
                                            {lectureData.file_type == "review" &&
                                                <>
                                                    {allowReview && <Form loading={loading} style={{ paddingTop: "10px", width: "100%", maxWidth: "500px", margin: "0 auto" }} onSubmit={handleReviewSubmit} reply>
                                                        <div className="ui field">
                                                            <label>Rating: </label><Rating defaultRating={0} maxRating={5} onRate={(e, { rating }) => { setRating(rating) }} />
                                                        </div>
                                                        <Form.TextArea label="Text" required onChange={(e) => { setText(e.target.value) }} />
                                                        <Button color="teal" circular content={t('product.add_review')} labelPosition='left' icon='edit' />
                                                        <label style={{ marginBottom: "20px" }} class="ui labeled circular teal icon button" htmlFor="custom_button_upload_image">
                                                            <i class="image icon"></i>
                                                            <input
                                                                id="custom_button_upload_image"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleAddPhoto}
                                                                css="display:none;"
                                                            />
                                                            {t('product.add_photo')}
                                                        </label>
                                                        {/* <Button color="teal" circular content={t('product.add_photo')} labelPosition='left' icon='picture' /> */}
                                                        {/* {photo && <span><b>Photo: </b>{photo.name}</span>} */}
                                                        <div css="display:flex;align-items:center;justify-content:flex-start;">
                                                            {filesData.map((item) => {
                                                                return <>
                                                                    <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                                        <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                                            <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                                                <div css="display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                                                    <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={(e) => URL.revokeObjectURL(e.target.src)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            })}
                                                        </div>

                                                    </Form>}
                                                </>
                                            }
                                        </>

                                    )
                                }
                                {/* </>
                                    )
                                } */}
                                <div css="display:flex;align-items:center;justify-content:space-between;margin-top:1.4rem;">
                                    <div>
                                        <Button circular color="teal" onClick={handleBack}>{t("product.back")}</Button>
                                    </div>
                                    <div>
                                        <Button circular color="teal" onClick={handleNext}>{t("product.next")}</Button>
                                    </div>
                                </div>
                                <div css="margin:20px 0;">
                                    <h3>{courseInfo.name}</h3>
                                    <p css="white-space:pre-wrap;">{courseInfo.description}</p>
                                </div>
                            </div>
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
        </main>

        <Footer />
    </>
}

export default Course