import {configureStore} from '@reduxjs/toolkit'
import uiSlice from './ui-slice'
import userSlice from './user-slice'
import cartSlice from './cart-slice'
import ordersSlice from './orders-slice'

const store=configureStore({
    reducer:{
        ui:uiSlice.reducer,
        user:userSlice.reducer,
        cart:cartSlice.reducer,
        orders:ordersSlice.reducer
    }
})

export default store