import { useState } from "react"
import { Icon, List } from "semantic-ui-react"

const CustomList = (props) => {
    const [open, setOpen] = useState(false)
    return <List.Item onClick={() => setOpen(p => !p)}>
        <Icon name={`${open?"down":"right"} triangle`} />
        <List.Content>
            <List.Header>{props.data.title}</List.Header>
            {open && <List.List selection >
                {props.data.children.map(item => {
                    return <List.Item onClick={(e) => { e.stopPropagation(); props.onChildrenClick(item) }}>
                        <List.Content>
                            <List.Header>{item.title}</List.Header>
                        </List.Content>
                    </List.Item>
                })}
            </List.List>}

        </List.Content>
    </List.Item>
}


export const CustomListCourse = (props) => {
    const [open, setOpen] = useState(false)
    // return <List.Item onClick={() => setOpen(p => !p)}>
    return <List.Item onClick={() => props.setActiveSection(props.data._id)}>
        <Icon name={`${props.sectionOpen?"down":"right"} triangle`} />
        <List.Content>
            <List.Header>{props.data.title}</List.Header>
            {props.sectionOpen && <List.List selection >
                {props.data.lectures.map(item => {
                    return <List.Item active={props.activeLecture==item._id} onClick={(e) => { e.stopPropagation(); props.onChildrenClick(item) }}>
                        <List.Content>
                            <List.Header>{item.title}</List.Header>
                        </List.Content>
                    </List.Item>
                })}
            </List.List>}

        </List.Content>
    </List.Item>
}
export default CustomList