import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import styles from '../progressbar.styles'
import { Link } from 'react-router-dom'

const ProgressBar = ({ active }) => {
    const [t, i18n] = useTranslation("global")

    /*return (
        <>
            <div css="display:flex;align-items:center;justify-content:space-between;position:relative;">
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="display:flex;flex-direction:column;align-items:center;">
                    <div css="border:3px solid black;border-radius:100%;width:10px;height:10px;"></div>
                    <p>user profile</p>
                </div>
                <div css="position:absolute;width:100%;height:5px;top:50%;left:0;background:black;"></div>
            </div>
        </>
    )*/


    return (
        <>
            <div css="height:5px;background:teal;display:flex;align-items:center;justify-content:space-between;margin-bottom:70px;margin-top:30px;">
                <Link to="business-profile" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 1 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Business_profile")}</div>
                </Link>
                <Link to="business-profile-2" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 2 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Add_products")}</div>
                </Link>
                <Link to="business-courses" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 3 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Add_course")}</div>
                </Link>
                <Link to="business-purchased-orders" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 4 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.purchased_orders")}</div>
                </Link>
                <Link to="business-orders" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 5 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.orders")}</div>
                </Link>
                <Link to="business-settings" css={"color:#000;&:hover{color:#444;}width:30px;height:30px;border-radius:100px;background:" + (active >= 6 ? "teal" : "white") + ";border:5px solid teal;position:relative;"}>
                    <div css="position:absolute;top:calc(100% + 5px);left:50%;transform:translateX(-50%);text-align:center;font-weight:bold;font-size:1.1rem;width:150px;">{t("profile.Settings")}</div>
                </Link>
            </div>
            {/*<div css={styles}>
                <div className="stepper-wrapper">
                    <div className={`stepper-item ${active>=1 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">First first first first</div>
                    </div>
                    <div className={`stepper-item ${active>=2 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">Second</div>
                    </div>
                    <div className={`stepper-item ${active>=3 && "completed"}`}>
                        <div className="step-counter"></div>
                        <div className="step-name">Third</div>
                    </div>
                </div>
            </div>*/}
        </>
    )
}

export default ProgressBar