import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Message } from 'semantic-ui-react'
import { login } from '../../store/user-slice'
import 'styled-components/macro'

const TokenForm = (props) => {
    const [t, i18n] = useTranslation("global")
    const { loading, userInfo, error } = useSelector(state => state.user)
    const [values, setValues] = useState({ token: "" })
    const dispatch=useDispatch()
    const handleInputChange = (e) => {
        setValues(p => { return { ...p, [e.target.name]: e.target.value } })
    }
    return (
        <>
            <Form loading={loading} onSubmit={() => { dispatch(login(props.state.email,props.state.password,values.token)) }}>
                <div css="display:flex;justify-content:space-between;">
                    <h2>{t('navbar.enter_token')}</h2>
                </div>
                <Form.Input onChange={handleInputChange} icon='key' iconPosition='left' name="token" fluid label={t('navbar.authentication_token')} type="text" />
                <Button fluid circular color="teal" style={{ background: "rgb(0,128,129)" }} /*onClick={dispatch(login())}*/>{t('navbar.continue')}</Button>
                {
                    error && (
                        <Message color="red">
                            {error}
                        </Message>

                    )
                    }
            </Form>
        </>
    )
}

export default TokenForm