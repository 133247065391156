
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Dimmer, Loader, Message, Modal, Progress } from 'semantic-ui-react'
import 'styled-components/macro'
import axios from '../../axios'
import Player from "../../shared/player";

const CreateCourseContentAdmin = (props) => {
    const { userInfo } = useSelector(state => state.user)
    const [t, i18n] = useTranslation("global")
    const [openSectionModal, setOpenSectionModal] = useState(false)
    const courseContent = useRef([])
    const [loading, setLoading] = useState(false)
    const [sections, setSections] = useState([])
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState({ open: false, data: () => { } })
    const [editSectionData, setEditSectionData] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [data, setData] = useState([])

    const handleAddSection = (obj) => {
        setData(p => [...p, { obj }])
    }
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const loadData = async (b) => {
        setLoading(true)
        try {

            // const { data } = await axios.get("/api/business/courses/section?course_id=" + props.course_id, config)
            setSections(props.sections)
            if (b) {
                setLoading(false)
                return data[data.length - 1]
            }
        } catch (e) { alert(e) }
        setLoading(false)
    }

    const handleDelete = async (id) => {
        setDeleteLoading(id)
        try {
            await axios.delete(`/api/business/courses/section?course_id=${props.course_id}&section_id=${id}`, config)
            setSections(p => p.filter(s => s._id != id))
        } catch (e) { alert(e) }
        setDeleteLoading(false)
    }
    const handleEdit = (section) => {
        setEditSectionData(section)
        setOpenSectionModal(true)
    }

    useEffect(() => { loadData() }, [])
    if (loading)
        return <div css="display:flex;align-items:center;justify-content:center;padding:20px;position:relative;font-size:20px;">
            <div>Loading...</div>
        </div>

    return <>
        <div style={{ margin: "10px 0" }}>
            <Message
                attached
                header={t("profile.create_your_content")}
            // content={t("profile.create_your_content_description")}
            />
            {sections.map(section => <>
                <div key={section._id} css="margin:8px 0;padding:8px;border:1px solid #ddd;border-radius:5px;display:flex;justify-content:space-between;">
                    <div>
                        <div>{t("profile.title")}:{section.title}</div>
                        <div>{t("profile.goals")}:{section.goals}</div>
                    </div>
                    <div css="display:flex;flex-direction:column;">
                        <Button style={{ marginBottom: "8px" }} onClick={() => handleEdit(section)} circular color="teal">View</Button>
                    </div>
                </div>
            </>)}

        </div>
        <Modal
            open={openDeleteConfirm.open}
            header={t("profile.delete")}
            content={t("profile.sure_delete_lecture")}
            actions={[{ key: "cancel", onClick: () => { setOpenDeleteConfirm({ open: false, data: () => { } }) }, content: t("profile.cancel") }, { key: 'done', onClick: () => { openDeleteConfirm.data(); setOpenDeleteConfirm({ open: false, data: () => { } }); }, content: t("profile.delete"), color: "red" }]}
        />
        <CreateContentModal sections={sections} editSection={editSectionData} openEditMode={(section) => { handleEdit(section) }} update={async (b) => { return await loadData(b) }} course_id={props.course_id} open={openSectionModal} close={() => { setOpenSectionModal(false); setEditSectionData(false); }} />
    </>
}

const CreateContentModal = (props) => {
    const [t, i18n] = useTranslation("global")
    const [openLectureModal, setOpenLectureModal] = useState(false)
    const [lectures, setLectures] = useState([])
    const [values, setValues] = useState({ title: "", goals: "" })
    const [loadingButton, setLoadingButton] = useState(false)
    const [loadingDeleteButton, setLoadingDeleteButton] = useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState({ open: false, data: () => { } })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [editLectureData, setEditLectureData] = useState({})
    const { userInfo } = useSelector(state => state.user)
    const handleTextChange = (e) => {
        const { name, value } = e.target
        setValues(prev => ({ ...prev, [name]: value }))
    }
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const handleSectionSave = async (data) => {
        let { title, goals } = values
        title = title.trim()
        goals = goals.trim();
        if (title == "" || goals == "") {
            setError(true)
            return
        }
        setError(false)
        setLoadingButton(true)
        try {
            if (!props.editSection) {
                const res = await axios.post("/api/business/courses/section", {
                    course_id: props.course_id,
                    title: values.title,
                    goals: values.goals
                }, config)
                const res2 = await props.update(true);
                props.close()
                props.openEditMode(res2)
            }
            if (props.editSection) {
                const res = await axios.put("/api/business/courses/section", {
                    course_id: props.course_id,
                    section_id: props.editSection._id,
                    title: values.title,
                    goals: values.goals
                }, config)
                props.close()
                await props.update();
            }
        } catch (e) { alert(e) }
        setLoadingButton(false)
    }
    const handleLectureDelete = async (index, id) => {
        setLoadingDeleteButton(id)
        try {
            await axios.delete(`/api/business/courses/section/lecture?lecture_id=${id}&section_id=${props.editSection._id}&course_id=${props.course_id}`, config)
        } catch (e) { alert(e) }
        setLectures(p => {
            const n = p.filter(x => true)
            n.splice(index, 1)
            return n
        })
        setLoadingDeleteButton(false)
    }
    const loadData = async () => {
        setLoading(true)
        try {
            // const { data } = await axios.get(`/api/business/courses/section/lecture?course_id=${props.course_id}&section_index=${props.sections.findIndex(x => x._id == props.editSection._id)}`, config)
            setLectures(props.editSection.lectures)
        } catch (e) { alert(e) }
        setLoading(false)
    }

    useEffect(() => {
        if (!props.open) {
            setValues({ title: "", goals: "" })
        }
        if (props.open && props.editSection) {
            setValues({
                title: props.editSection.title,
                goals: props.editSection.goals,
            })
            loadData()
        }
    }, [props.open])

    return <>
        <Modal
            closeIcon
            closeOnDimmerClick={false}
            onClose={() => props.close()}
            open={props.open}
        >
            <Modal.Header>{t("profile.add_section")}</Modal.Header>
            <Modal.Content>
                {loading && <div css="display:flex;align-items:center;justify-content:center;padding:20px;position:relative;font-size:20px;">
                    <div>Loading...</div>
                </div>}
                {!loading && <div className="ui form">
                    <div className="two fields">
                        <div className="ui field">
                            <label htmlFor="">
                                {t("profile.title")}
                            </label>
                            <input readOnly value={values.title} name="title" onChange={handleTextChange} type="text" />
                        </div>
                        <div className="ui field">
                            <label htmlFor="">
                                {t("profile.section_goals")}
                            </label>
                            <textarea readonly value={values.goals} name="goals" onChange={handleTextChange} />
                        </div>
                    </div>
                    {props.editSection && <>
                        <Message
                            attached
                            header={t("profile.lectures")}
                        // content={t("profile.create_your_content_description")}
                        />
                        <div className='ui form attached fluid segment'>
                            {lectures.map((l, c) => <>
                                <div key={l._id} style={{ margin: "8px 0", padding: "8px", border: "1px solid black", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                                    <div css="flex-grow:2;width:100%;max-width:85%;">
                                        <span css="font-weight:bold;display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{l.title}</span>
                                        <span css="display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{l.description}</span>
                                        <span css="display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{l.video_name}</span>
                                    </div>
                                    <div css="display:flex;flex-direction:column;">
                                        <Button onClick={() => { setEditLectureData(l); setOpenLectureModal(true) }} style={{ marginBottom: "8px" }} circular color="teal">View</Button>
                                        {/* <Button loading={loadingDeleteButton == l._id} onClick={() => { setOpenDeleteConfirm({ open: true, data: () => handleLectureDelete(c, l._id) }) }} circular color="red">{t("profile.Delete")}</Button> */}
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </>}
                    {error && <Message color="red">{t("profile.fill_required_fields")}</Message>}
                </div>}
            </Modal.Content>
            {/* <Modal.Actions>
                <Button circular color='red' onClick={() => props.close()}>
                    {t("profile.cancel")}
                </Button>
                <Button circular disabled={loadingButton} loading={loadingButton} color='teal' onClick={handleSectionSave}>
                    {t("profile.save")}
                </Button>

            </Modal.Actions> */}
        </Modal>
        <Modal
            open={openDeleteConfirm.open}
            header={t("profile.delete")}
            content={t("profile.sure_delete_lecture")}
            actions={[{ key: "cancel", onClick: () => { setOpenDeleteConfirm({ open: false, data: () => { } }) }, content: t("profile.cancel") }, { key: 'done', onClick: () => { openDeleteConfirm.data(); setOpenDeleteConfirm({ open: false, data: () => { } }); }, content: t("profile.delete"), color: "red" }]}
        />
        <CreateLectureModal sections={props.sections} update={loadData} course_id={props.course_id} section_id={props.editSection._id} data={editLectureData} open={openLectureModal} close={() => { setOpenLectureModal(false); setEditLectureData({}) }} />
    </>
}

const CreateLectureModal = (props) => {
    const [t, i18n] = useTranslation("global")
    const [values, setValues] = useState({ title: "", description: "", video: null, video_name: "", video_url: "" })
    const [error, setError] = useState(false)
    const [progress, setProgress] = useState(0)
    const [selectedTypeFile, setSelectedTypeFile] = useState("video")
    const [loadingButton, setLoadingButton] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const { userInfo } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const signed_data = useRef("")
    console.log(props.data)
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    const handleSave = async () => {
        let { title, description, video, video_name } = values
        title = title.trim()
        const file_type = selectedTypeFile
        description = description.trim();
        video_name = video_name.trim();
        if (title == "" || description == "" || video_name == "") {
            setError(true)
            return
        }
        setError(false)
        setLoadingButton(true)
        try {
            const { data: url } = await axios.post("/api/business/courses/section/lecture", {
                section_index: props.sections.findIndex(x => x._id == props.section_id),
                course_id: props.course_id,
                section_id: props.section_id,
                title: values.title,
                description: values.description,
                video_size: video.size,
                video_name: video.name,
                file_type
            }, config)
            if (file_type == "video") {
                const data = new FormData();
                data.append('file_data', video);
                const config2 = {
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted)
                    }
                };
                try {
                    await axios.post(url, data, config2)
                } catch (e) { console.log("vimeo error:", e) }
            } else if (file_type == "others") {
                const array = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const arrayBuffer = this.result
                        resolve(arrayBuffer)
                    }
                    reader.readAsArrayBuffer(video);
                })
                let blobData = new Blob([new Uint8Array(array)])
                const result = await fetch(url.uploadURL, {
                    method: 'PUT',
                    body: blobData
                })
            }
            handleClose()
            props.update()
        } catch (e) { alert(e) }
        setLoadingButton(false)
    }
    const handleUpdate = async () => {
        try {
            let { title, description, video, video_name } = values
            title = title.trim()
            const file_type = selectedTypeFile
            description = description.trim();
            video_name = video_name.trim();
            if (title == "" || description == "" || video_name == "") {
                setError(true)
                return
            }
            setError(false)
            setLoadingButton(true)
            const video_info = {}
            if (video) {
                video_info.video_size = video.size
                video_info.video_name = video.name
                video_info.file_type = file_type
            }
            const { data: url } = await axios.put("/api/business/courses/section/lecture", {
                section_index: props.sections.findIndex(x => x._id == props.section_id),
                course_id: props.course_id,
                section_id: props.section_id,
                lecture_id: props.data._id,
                title: values.title,
                description: values.description,
                ...video_info
            }, config)
            if (video) {
                if (file_type == "video") {

                    const data = new FormData();
                    data.append('file_data', video);
                    const config2 = {
                        onUploadProgress: function (progressEvent) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setProgress(percentCompleted)
                        }
                    };
                    try {
                        await axios.post(url, data, config2)
                    } catch (e) { console.log("vimeo error:", e) }
                }
                else if (file_type == "others") {
                    const array = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const arrayBuffer = this.result
                            resolve(arrayBuffer)
                        }
                        reader.readAsArrayBuffer(video);
                    })
                    let blobData = new Blob([new Uint8Array(array)])
                    const result = await fetch(url, {
                        method: 'PUT',
                        body: blobData
                    })
                }

            }
            handleClose()
            props.update()
        } catch (e) {
            alert(e)
        }
        setLoadingButton(false)
    }
    const handleTextChange = (e) => {
        const { name, value } = e.target
        setValues(p => ({ ...p, [name]: value }))
    }
    const handleClose = () => {
        if (loadingButton) return;
        props.close()
        setValues({ title: "", description: "", video: null, video_name: "" })
        setProgress(0)
        setError(false)
    }
    const handleVideoSelect = (e) => {
        // values.current = { ...values.current, video: e.target.files[0],video_name:e.target.files[0].name }
        setValues(p => ({ ...p, video: e.target.files[0], video_name: e.target.files[0].name }))
    }
    const handleDeleteVideo = () => {
        setValues(p => ({ ...p, video_name: "", video_url: "" }))
    }
    const handleFileTypeChange = (e, { value }) => {
        setSelectedTypeFile(value)
    }
    const handleDownload = async (lecture_id) => {
        setLoadingDownload(true)
        try {
            const { data: url } = await axios.get(`/api/user/course/data/download/admin?course_id=${props.course_id}&lecture_id=${props.data._id}`, config)
            window.location = url
        } catch (e) {
            alert(e)
        }
        setLoadingDownload(false)
    }
    useEffect(() => {
        (async () => {
            if (props.open) {
                if (Object.keys(props.data).length > 0) {
                    setValues({ ...props.data })
                    console.log("props data:", props.data)
                    setSelectedTypeFile(props.data.file_type == "others" ? "others" : "video")
                    setLoading(true)
                    if (props.data.file_type == "video") {
                        try {
                            const { data: signed } = await axios.get(`api/product/course/signed_string_admin?course_id=${props.course_id}&video_uuid=${props.data.video_uuid}`, config)
                            signed_data.current = (`&Policy=${signed["CloudFront-Policy"]}&Signature=${signed["CloudFront-Signature"]}&Key-Pair-Id=${signed["CloudFront-Key-Pair-Id"]}`)
                        } catch (e) { alert(e) }
                    }
                    setLoading(false)
                }
            }
        })()
    }, [props.open])

    return <>
        {
            loading && <Dimmer active inverted>
                <Loader />
            </Dimmer>
        }
        <Modal
            closeIcon
            closeOnDimmerClick={false}
            onClose={handleClose}
            open={props.open}
        >
            <Modal.Header>{t("profile.add_lecture")}</Modal.Header>
            <Modal.Content>
                <div className="ui form">
                    <div className="two fields">
                        <div className="ui required field">
                            <label htmlFor="">
                                {t("profile.title")}
                            </label>
                            <input value={values.title} name="title" onChange={handleTextChange} type="text" />
                        </div>
                        <div className="ui required field">
                            <label htmlFor="">
                                {t("profile.description")}
                            </label>
                            <textarea value={values.description} name="description" onChange={handleTextChange} cols="30" rows="10" />
                            {/* <input value={values.description} name="description" onChange={handleTextChange} type="text" /> */}
                        </div>
                    </div>
                    {/* <div className="ui field">
                        <label>{t("profile.upload_file")}</label>
                    </div>
                    <div className="ui field">
                        <Radio
                            label={t("profile.videos")}
                            name='file_type_select'
                            value='video'
                            checked={selectedTypeFile === 'video'}
                            onChange={handleFileTypeChange}
                        />
                    </div>
                    <div className="ui field">
                        <Radio
                            label={t("profile.others")}
                            name='file_type_select'
                            value='others'
                            checked={selectedTypeFile === 'others'}
                            onChange={handleFileTypeChange}
                        />
                    </div> */}
                    {/* {selectedTypeFile == "video" && <div className="ui required field">
                        <label style={{ marginBottom: "20px", display: "inline" }} class="ui labeled icon button" htmlFor="custom_button_upload_image">
                            <i class="image icon"></i>
                            <input
                                id="custom_button_upload_image"
                                type="file"
                                accept="video/*"
                                onChange={(e) => { e.target.files[0] && handleVideoSelect(e) }}
                                css="display:none;"
                            />
                            <span>{t('profile.video_file')}{values.video_name && ":"} {values.video_name && values.video_name}</span>
                        </label>
                    </div>}
                    {selectedTypeFile == "others" && <> <div className="ui required field">
                        <label style={{ marginBottom: "20px", display: "inline" }} class="ui labeled icon button" htmlFor="custom_button_upload_image">
                            <i class="image icon"></i>
                            <input
                                id="custom_button_upload_image"
                                type="file"
                                accept="image/*,application/pdf,.doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => { e.target.files[0] && handleVideoSelect(e) }}
                                css="display:none;"
                            />
                            <span>{t('profile.files')}{values.video_name && ":"} {values.video_name && values.video_name}</span>
                        </label>
                    </div>
                        <div>
                            {t("profile.supported_files_lecture")}
                        </div>
                    </>} */}
                    <p css="display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">File name: {values.video_name}</p>
                    {progress > 0 && <div css="margin:10px 0;">
                        <Progress percent={progress} size="small" color='teal' />
                    </div>}
                </div>
                <div>
                    {
                        (selectedTypeFile == "video" && !loading) ? <>
                            <Player
                                options={{
                                    autoplay: true,
                                    controls: true,
                                    responsive: true,
                                    fluid: true,
                                    sources: [
                                        {
                                            src: `https://dmvz0cr0zvd1h.cloudfront.net/${values.video_uuid}/AppleHLS1/${values.video_url}?a=b${signed_data.current}`
                                        }
                                    ]
                                }}
                                signedString={"?a=b" + signed_data.current}
                            />
                        </> : <>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button circular color="teal" loading={loadingDownload} onClick={() => handleDownload(props.data._id)}>{t("profile.download_file")}</Button>
                            </div>
                        </>
                    }
                </div>
                {error && <Message color="red">{t("profile.fill_required_fields")}</Message>}
            </Modal.Content>
            {/* <Modal.Actions>
                <Button circular color='red' onClick={handleClose}>
                    {t("profile.cancel")}
                </Button>
                <Button disabled={loadingButton} loading={loadingButton} circular color='teal' onClick={Object.keys(props.data).length > 0 ? handleUpdate : handleSave}>
                    {t("profile.save")}
                </Button>

            </Modal.Actions> */}
        </Modal>

    </>
}

export default CreateCourseContentAdmin
