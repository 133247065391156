import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Dimmer, Form, Loader, Modal, Segment, Tab } from 'semantic-ui-react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { timezones } from '../Profile/countries'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { uiActions } from '../store/ui-slice'
import { formData } from '../shared/formDataCreator'
import { PayPalButton } from 'react-paypal-button-v2'
import { getTimez, url_environment } from '../config'
import env from '../config.env'
const { paypal_client_id } = env

const ViewTimesheet = (props) => {
    const [t, i18n] = useTranslation("global")
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [taxes, setTaxes] = useState({})
    const [filesData, setFilesData] = useState([])
    const fileRef = useRef(undefined)
    const handleAddFileClick = useCallback(() => {
        fileRef.current.click()
    }, [])
    const { userInfo } = useSelector(state => state.user)
    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    // const [values, setValues] = useState({

    // })
    const [values, setValues] = useState({})
    const dispatch = useDispatch();
    const data = props.data;
    const showTime = props.contract.payment_schedule == "hourly"
    let total_amount
    switch (props.contract.payment_schedule) {
        case "hourly":
            total_amount = (parseFloat(getTimez(data.time_from, data.time_to)) * props.price)
            break;
        case "weekly":
        case "monthly":
        case "one_time":
            total_amount = parseFloat(props.price)
            break;
    }
    const loadData = async () => {
        setLoading(true)
        try {
            console.log("props contra:", props.contra)
            console.log("props:", props)
            setFilesData(data.files.map(x => { const ss = x.substring(x.lastIndexOf(".") + 1); const t = ss != "pdf" ? "image" : "pdf"; return { name: x.substring(x.lastIndexOf("/") + 1), type: t, image_link: url_environment + x } }))
            const { data: taxes } = await axios.get(`/api/product/taxes?service=${props.contract.service}&amount=${total_amount.toFixed(2)}`, config)
            setTaxes(taxes)
        } catch (e) { alert(e) }
        setLoading(false)
    }
    const getFilesObject = useCallback((array) => {
        const obj = {}
        const n = array.length
        for (let i = 0; i < n; i++) {
            obj[`file-${(i + 1)}`] = array[i]
        }
        return obj
    }, [])
    const handleImageLoad = (e) => URL.revokeObjectURL(e.target.src)
    const handleFileChange = useCallback((e) => {
        const f = e.target.files[0]
        if (!f) return;
        setFiles(p => [...p, f])
        setFilesData(p => [
            ...p,
            {
                name: f.name,
                type: f.type,
                image_link: f.type.startsWith("image") ? URL.createObjectURL(f) : undefined
            }
        ])

    }, [])
    const handleSubmit = async (e) => {
        const { text, time1, time2 } = values.current
        if (!text || !time1 || !time2) { alert("Please fill all fields!"); return; }
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        e.target.disabled = true
        setLoading(true)
        try {
            await axios.post("/api/proposal/timesheet", formData({
                id: props.id,
                time: values.current.time1 + "--" + values.current.time2,
                text: values.current.text,
                ...getFilesObject(files),
            }), config)
        } catch (e) { alert(e) }
        e.target.disabled = false
        setLoading(false)
        props.close()
        props.update()
    }
    const handleReject = useCallback(async () => {
        setBtnLoading(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        // debugger;
        try {
            await axios.post("/api/proposal/timesheet/reject", {
                id: data._id
            }, config)
        } catch (e) { alert(e) }
        setBtnLoading(false)
        props.close()
        props.update()
    }, [data])
    const handlePay = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        try {
            await axios.post("/api/proposal/timesheet/pay", {
                id: data._id
            }, config)
        } catch (e) { alert(e) }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        values.current = { ...values.current, [name]: value }
    }

    const total_payment = (total_amount + taxes.totalTax).toFixed(2)
    useEffect(() => {
        if (!props.open) return;
        loadData()
    }, [props.open])
    if (!props.open)
        return <></>

    if (loading) {
        return <Dimmer active inverted>
            <Loader />
        </Dimmer>
    }
    return (
        <>
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                onClose={props.close}
                open={props.open}
            >
                <Modal.Header>{t("contractmgmt.view_timesheet")}</Modal.Header>
                <Modal.Content >
                    {props.clientx && <h5 css="text-align:center;">{t("contractmgmt.see_freelancer_progress_para")}</h5>}
                    {!props.paid && !data.rejected && props.clientx && <Button loading={btnLoading} circular negative onClick={handleReject}>{t("contractmgmt.reject_timesheet")}</Button>}
                    <div className="ui form">
                        <div className="ui field">
                            <label>{t("contractmgmt.date")}</label>
                            <Segment>{props.contract.payment_schedule == "monthly" ? `${new Date(data.date).getMonth() + 1}/${new Date(data.date).getFullYear()}` : `${new Date(data.date).toLocaleDateString()}`}</Segment>
                        </div>
                        {showTime && <div className="ui field">
                            <label>{t("contractmgmt.total_time")}</label>
                            <Segment>{getTimez(data.time_from, data.time_to) + " " + t("contractmgmt.hours")}</Segment>
                        </div>}
                        <div className="ui field">
                            <label>{t("contractmgmt.total_taxes")}</label>
                            <Segment>USD ${taxes.totalTax}</Segment>
                        </div>
                        <div className="ui field">
                            <label>{t("contractmgmt.total_payment")}</label>
                            <Segment>USD ${total_payment}</Segment>
                        </div>
                        {showTime && <div className="ui field">
                            <label>{t("contractmgmt.time")}</label>
                            <Segment>{`${data.time_from} ${t("contractmgmt.to")} ${data.time_to}`}</Segment>
                        </div>}
                        <div className="ui field">
                            <label>{t("contractmgmt.text")}</label>
                            {/* <textarea name="text" onChange={handleInputChange} style={{ width: "100%" }} rows="10"></textarea> */}
                            <Segment>{data.text}</Segment>
                        </div>
                    </div>
                    <h5 css="text-align:center;">{t("contractmgmt.screenshots_files")}</h5>

                    <div>
                        <div css="display:flex;align-items:center;justify-content:center;">
                            <div>
                                <div css="display:flex;flex-wrap:wrap;justify-content:flex-start;">
                                    {filesData.map((item) => {
                                        return <>
                                            <div css="flex:0 0 auto;/*width:25%;*/width:112px;height:104px;padding:6px;/*@media(max-width:1000px){width:33.333333%;}@media(max-width:800px){width:50%;}*/">
                                                <div css="/*padding-bottom:90%;*/height:100%;position:relative;box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);border: 1px solid rgba(34,36,38,.15);">
                                                    <div css="/*position:absolute;*/width:100%;height:100%;display:flex;align-items:center;justify-content:center;width: 100%;">
                                                        {!item.type.startsWith("image") && <a target="_blank" href={item.image_link} /*href={`https://interpowered.com:3001/uploads/${item.name}`}*/ css="color:black; &:hover{color:black;}display:flex;flex-direction:column;width:100%;" >
                                                            <div>
                                                                <p css="text-align:center;margin:0;margin-bottom:5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{item.name}</p>
                                                            </div>
                                                            <div css="text-align:center;"><i className="ui big file pdf icon"></i></div>
                                                        </a>}
                                                        {item.type.startsWith("image") && <a target="_blank" href={item.image_link} /*href={`https://interpowered.com:3001/uploads/${item.name}`}*/ css="color:black; &:hover{color:black;}display:flex;flex-direction:column;align-items:center;width: 100%;height: 100%;">
                                                            <img src={item.image_link} css="width:100%;height:100%;object-fit:cover;object-position:center;" alt="" onLoad={handleImageLoad} />
                                                        </a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!props.paid && props.clientx && props?.contra?.paypal_email && !data.rejected && <div css="display:flex;justify-content:center;margin-top:20px;">
                        <PayPalButton
                            createOrder={async (datax, actions) => {
                                try {
                                    const { data: oid } = await axios.post(`/api/proposal/timesheet/create_order`,
                                        {
                                            id: data._id
                                        }, config)
                                    return oid
                                } catch (e) { alert(e) }
                            }}
                            onApprove={async (data, actions) => {
                                try {
                                    await axios.post("/api/proposal/timesheet/capture", {
                                        orderID: data.orderID,
                                    }, config)
                                    props.close()
                                    props.update()
                                    return
                                } catch (e) {
                                    console.log(e)
                                    alert("There was an error, please contact the administrator. PAYPAL_ORDER_ID:" + data.orderID)
                                }
                            }}
                            onError={(e) => alert(e)}
                            options={{
                                clientId: paypal_client_id
                            }}
                        />
                        {/* <PayPalButton
                            createOrder={(datax, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            currency_code: "USD",
                                            value: total_payment//(parseFloat(getTimez(data.time_from,data.time_to))*props.price).toFixed(2),
                                        }
                                    }],
                                });
                            }}
                            onSuccess={async (details, datax) => {
                                const address = {
                                    client_country: userInfo.country,
                                    client_id_country: userInfo.id_country,
                                    client_address: userInfo.address,
                                    client_zip: userInfo.zip
                                }
                                try {
                                    const config = {
                                        headers: {
                                            Authorization: `Bearer ${userInfo.token}`,
                                        },
                                    }
                                    await axios.post("/api/proposal/timesheet/pay", {
                                        id: data._id,
                                        order_id: datax.orderID,
                                        service: props.contract.service,
                                        price: total_payment,//(parseFloat(getTimez(data.time_from,data.time_to))*props.price).toFixed(2),
                                        total_amount,
                                        address,
                                        freelancer: props.contra._id,
                                        freelancer_email: props.contra.email,
                                        freelancer_paypal_email: props.contra.paypal_email,
                                        service_name: props.contract.service_name,
                                        contract_number: props.contract._id
                                    }, config)
                                    props.close()
                                    props.update()
                                } catch (e) { alert(e) }
                            }}
                        /> */}
                    </div>}
                </Modal.Content>
                <Modal.Actions css="display:flex;justify-content:space-evenly;">
                    {/* {props.clientx && <Button loading={loading} onClick={handlePay} color='teal' circular>
                        {t("contractmgmt.pay")}
                    </Button>} */}
                    <Button color='teal' circular onClick={props.close}>
                        {t("contractmgmt.close")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default ViewTimesheet