import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react'
import 'styled-components/macro'
import ProgressBar from './progressbar-freelancer'
import axios from '../../axios'
import { useTranslation } from 'react-i18next'
import { btnContainer, btnPop, divImage2, homeDivStyles, imageStyles, profimage1, profimage2, footButtons } from '../styles'
import Footer from '../../shared/footer/footer'
import Navbar from '../../shared/navbar/navbar'
import { useSelector } from 'react-redux'

const Freelancecv = () => {
    const { userInfo } = useSelector(state => state.user)
    const [Categories, setCategories] = useState([])
    const [Loading, setLoading] = useState(true)
    const [freelancersImage1, setFreelancersImage1] = useState(false)
    const [freelancersImage2, setFreelancersImage2] = useState(false)
    const [freelancersImage3, setFreelancersImage3] = useState(false)
    const [freelancersImage4, setFreelancersImage4] = useState(false)
    const [imageLink1, setImageLink1] = useState("")
    const [imageLink2, setImageLink2] = useState("")
    const [imageLink3, setImageLink3] = useState("")
    const [imageLink4, setImageLink4] = useState("")
    const [values, setValues] = useState({
        freelancer_category: "",
        career: "",
        products_or_services: "",
        experience: "",
        skills: "",
        languagues: "",
        duties_and_titles: "",
        certifications: "",
        degrees: "",
        link_to_cv: "",
        link_to_portfolio: "",
        image_link_1: "",
        image_link_2: "",
        image_link_3: "",
        image_link_4: "",
        image: [],

    })
    const btnSubmit = useRef()
    const [t, i18n] = useTranslation("global")
    const handleInputChange = (e) => {
        setValues((prev) => { return { ...prev, [e.target.name]: e.target.value } })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append("category_name", values.freelancer_category.name,)
            formData.append("id_service_category", values.freelancer_category.id_cat,)
            formData.append("career", values.career,)
            formData.append("products_or_services", values.products_or_services,)
            formData.append("experience", values.experience,)
            formData.append("skills", values.skills,)
            formData.append("languagues", values.languagues,)
            formData.append("duties_and_titles", values.duties_and_titles,)
            formData.append("certifications", values.certifications,)
            formData.append("degrees", values.degrees,)
            formData.append("url_cv", values.link_to_cv,)
            formData.append("url_portfolio", values.link_to_portfolio,)
            formData.append("file1", freelancersImage1)
            formData.append("file2", freelancersImage2)
            formData.append("file3", freelancersImage3)
            formData.append("file4", freelancersImage4)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let res = await axios.put("/api/freelancer/cv", formData, config)
            setLoading(false)
        } catch (e) {
            alert(e)
            setLoading(false)
        }
    }

    const getPDFName = (s) => {
        let fin = s.lastIndexOf("_")
        let ini = s.lastIndexOf("/") + 1
        return s.substring(ini, fin) + ".pdf"
    }
    const loadData = async () => {
        setLoading(true)
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let { data: { freelancer, categories } } = await axios.get("/api/freelancer/cv", config)
            setCategories(categories)
            console.log("id cat: ", freelancer.id_service_category)
            console.log("cats: ", categories.map(x => x._id))
            console.log("la categoria: ", categories.filter((i) => { return i.id_cat == freelancer.id_service_category })[0])
            setValues(prev => {
                return {
                    ...prev,
                    freelancer_category: categories.filter((i) => { return i.id_cat == freelancer.id_service_category })[0],
                    career: freelancer.career,
                    products_or_services: freelancer.products_or_services,
                    experience: freelancer.experience,
                    skills: freelancer.skills,
                    languagues: freelancer.languagues,
                    duties_and_titles: freelancer.duties_and_titles,
                    certifications: freelancer.certifications,
                    degrees: freelancer.degrees,
                    link_to_cv: freelancer.url_cv,
                    link_to_portfolio: freelancer.url_portfolio,
                    additional_info: freelancer.additional_info,
                    image_link_1: freelancer.images[0] || "",
                    image_link_2: freelancer.images[1] || "",
                    image_link_3: freelancer.images[2] || "",
                    image_link_4: freelancer.images[3] || "",
                }
            })
        } catch (e) {
            alert(e)
        }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])
    const history = useHistory()
    const fileref1 = useRef()
    const fileref2 = useRef()
    const fileref3 = useRef()
    const fileref4 = useRef()

    if (Loading) {
        return (
            <Dimmer active inverted>
                <Loader />
            </Dimmer>
        )
    }

    return (
        <>

            <Navbar />
            <main>

                <div className="ui container">
                    <ProgressBar active={2} />
                </div>

                <h2 css="text-align:center;">{t("profile.Curriculum_Vitae_C")}</h2>


                <form onSubmit={handleSubmit} className="ui grid container" css="justify-content:space-between">
                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                        <div className="ui form">
                            <div className="field">
                                <label>{t("profile.Freelancer_Category")}</label>
                                <Form.Select
                                    fluid
                                    options={Categories.map((i, c) => { return { text: i.name, value: { ...i }, key: c } })}
                                    defaultValue={values.freelancer_category}
                                    onChange={(e, { value }) => { handleInputChange({ target: { name: "freelancer_category", value } }) }}
                                />
                            </div>
                            <p>{t("input_words_commas")}</p>
                            <div className="field">
                                <label>{t("profile.Career")}</label>
                                <input value={values.career} onChange={handleInputChange} type="text" name="career" placeholder={t("it_project_management")}/>
                            </div>
                            <div className="field">
                                <label>{t("profile.Products_or_services")}</label>
                                <input value={values.products_or_services} onChange={handleInputChange} type="text" name="products_or_services" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Experience")}</label>
                                <input value={values.experience} onChange={handleInputChange} type="text" name="experience" placeholder={t("experience_in")} />
                            </div>

                            <div className="field">
                                <label>{t("profile.Skills")}</label>
                                <input value={values.skills} onChange={handleInputChange} type="text" name="skills" placeholder={t("skill_project_management")} />
                            </div>
                            <div className="field">
                                <label>{t("profile.Languages")}</label>
                                <input value={values.languagues} onChange={handleInputChange} type="text" name="languagues" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Duties_and_titles")}</label>
                                <textarea value={values.duties_and_titles} onChange={handleInputChange} name="duties_and_titles" ></textarea>
                            </div>

                        </div>
                    </div>
                    <div className="sixteen wide mobile seven wide tablet seven wide computer column">
                        <div className="ui form">
                            <div className="field">
                                <label>{t("profile.Certifications")}</label>
                                <input value={values.certifications} onChange={handleInputChange} type="text" name="certifications" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Degrees")}</label>
                                <input value={values.degrees} onChange={handleInputChange} type="text" name="degrees" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Link_to_cv")}</label>
                                <input value={values.link_to_cv} onChange={handleInputChange} type="text" name="link_to_cv" />
                            </div>
                            <div className="field">
                                <label>{t("profile.Link_to_portfolio")}</label>
                                <input value={values.link_to_portfolio} onChange={handleInputChange} type="text" name="link_to_portfolio" />
                            </div>
                            <div className="field">
                                <label>{t("profile.All_work_samples")}</label>
                                <div css={divImage2} >
                                    <input ref={fileref1} accept="image/jpeg, image/png" type="file" hidden onChange={(e) => { setFreelancersImage1(e.target.files[0] || false); setImageLink1(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "") }} />
                                    <div onClick={() => fileref1.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;width: 27%;margin: 3px !important;">
                                        <span>{t("profile.Upload_a_file")}</span>
                                        <i className="huge upload icon"></i>

                                        {
                                            freelancersImage1 ? (
                                                <div css={profimage1(imageLink1)}>
                                                </div>
                                            )
                                                : values.image_link_1 != "" && (
                                                    <div css={profimage2(values.image_link_1.substring(1))}>
                                                    </div>
                                                )

                                        }

                                    </div>
                                    <input ref={fileref2} accept="image/jpeg, image/png" type="file" hidden onChange={(e) => { setFreelancersImage2(e.target.files[0] || false); setImageLink2(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "") }} />
                                    <div onClick={() => fileref2.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;width: 27%;margin: 3px !important;">
                                        <span>{t("profile.Upload_a_file")}</span>
                                        <i className="huge upload icon"></i>

                                        {
                                            freelancersImage2 ? (
                                                <div css={profimage1(imageLink2)}>
                                                </div>
                                            )
                                                : values.image_link_2 != "" && (
                                                    <div css={profimage2(values.image_link_2.substring(1))}>
                                                    </div>
                                                )

                                        }


                                    </div>

                                    <input ref={fileref3} accept="image/jpeg, image/png" type="file" hidden onChange={(e) => { setFreelancersImage3(e.target.files[0] || false); setImageLink3(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "") }} />
                                    <div onClick={() => fileref3.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;width: 27%;margin: 3px !important;">
                                        <span>{t("profile.Upload_a_file")}</span>
                                        <i className="huge upload icon" ></i>

                                        {
                                            freelancersImage3 ? (
                                                <div css={profimage1(imageLink3)}>
                                                </div>
                                            )
                                                : values.image_link_3 != "" && (
                                                    <div css={profimage2(values.image_link_3.substring(1))}>
                                                    </div>
                                                )

                                        }
                                    </div>
                                    <input ref={fileref4} accept="application/pdf" type="file" hidden onChange={(e) => { setFreelancersImage4(e.target.files[0] || false); setImageLink4(e.target.files[0] ? e.target.files[0].name : "") }} />
                                    <div onClick={() => fileref4.current.click()} tabIndex="0" className="ui segment" css="display:flex;align-items:center;flex-direction:column;margin:0 !important;cursor:pointer;position:relative;width: 27%;margin: 3px !important;">
                                        <span>{t("profile.Upload_a_pdf")}</span>
                                        <i className="huge pdf file icon" ></i>

                                        {
                                            freelancersImage4 ? (
                                                <div css={"position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;"}>
                                                    {imageLink4}
                                                </div>
                                            )
                                                : values.image_link_4 && (
                                                    <div css={"position:absolute;top:0;left:0;background-color:white;width:100%;height:100%;display:flex;align-items:center;justify-content:center;"}>
                                                        <span css="padding:3px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{getPDFName(values.image_link_4)}</span>
                                                    </div>
                                                )

                                        }
                                    </div>
                                </div>

                            </div>

                            {/* <div className="field">
                                <label>{t("profile.Additional_info")}</label>
                                <textarea value={values.additional_info} onChange={handleInputChange} name="additional_info"></textarea>
                            </div> */}

                        </div>
                    </div>
                    <button hidden type="submit" ref={btnSubmit}></button>
                </form>

                <div css={footButtons}>
                    <button onClick={() => history.push("/freelancer-profile")} className="ui circular primary button" css="background:rgb(82,219,212) !important;">
                        {t("profile.Back")}</button>
                    <button onClick={() => btnSubmit.current.click()} className="ui circular primary button" css="background:rgb(2,183,178) !important;">
                        {t("profile.Save")}</button>
                    <button onClick={() => history.push("/freelancer-product")} className="ui circular primary button" css="background:rgb(0,128,129) !important;">
                        {t("profile.Next")}</button>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Freelancecv